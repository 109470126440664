
import { IInput } from "constants/signupFormNew";

import React, { useCallback, useEffect, useState } from "react";

import { Switch, Typography } from "antd";
import "styles/toggleQuestion.less";
import foreignIncomeImage from "assets/images/foreign-income-popup.png";
import PromptModal from "components/pages/NewQuestionnaire/components/Shared/PromptModal";

type UkToggleQuestionProps = {
    data: IInput;
    onAnswer: (_answer: string) => void;
    value?: string;
};

const UkToggleQuestion: React.FC<UkToggleQuestionProps> = ({
    data,
    onAnswer,
    value,
}) => {
    const [error, setError] = useState<string>("");
    const [skipValidation, setSkipValidation] = useState<boolean>(true);
    const [showHelperModal, setShowHelperModal] = useState<boolean>(false);
    const [promptTitle, setPromptTitle] = useState<string>("");
    const [promptText, setPromptText] = useState<string>("");
    const [promptImageUrl, setPromptImageUrl] = useState<string>("");
    const [promptActionButtonText, setPromptActionButtonText] = useState<string>("");
    const [promptActionButtonUrl, setPromptActionButtonUrl] = useState<string>("");

    // On first render, pass default "YES" ("yes") if value is undefined
    useEffect(() => {
        if (value === undefined) {
            onAnswer("yes");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Validation logic: show error if the field is required and the value is undefined
        if (data.required && value === undefined && !skipValidation) {
            setError("This field is required");
        } else {
            setError("");
        }
    }, [value, skipValidation, data.required]);

    const handleToggleChange = (checked: boolean) => {
        // Convert the boolean value (yes/no) to string and pass it to onAnswer
        onAnswer(checked ? "yes" : "no");
        setSkipValidation(false); // Enable validation after first interaction
        if (!checked) {
            setPromptTitle("Traditional Accounting");
            setPromptText("It looks like you use traditional accounting rather than cash. While our service currently only supports cash base accounting we may still be able to help!\n\nTo ensure you get the best possible support, we recommend booking an appointment with one of our expert accountants. They’ll guide you through the process and make sure your tax return is submitted accurately.");
            setPromptImageUrl(foreignIncomeImage);
            setShowHelperModal(!showHelperModal);
            setPromptActionButtonText("Book an appointment");
            setPromptActionButtonUrl("https://taxzapuk.setmore.com/");
        }
    };

    const renderModal = useCallback(() => (
        <PromptModal
            isOpen={showHelperModal}
            closeModal={() => setShowHelperModal(false)}
            data={{
                title: promptTitle,
                text: promptText,
                image: promptImageUrl,
                promptActionButtonText,
                promptActionButtonUrl,
            }}
            handlePrev={() => {}}
        />
    ), [showHelperModal, promptTitle, promptText, promptImageUrl, promptActionButtonText, promptActionButtonUrl]);

    return (
        <div className="builder__toggle-question">
            <span>{renderModal()}</span>
            <div className="space-between">
                <div className="flex-column">
                    <Typography.Text className="builder__form-question">
                        {data.name}
                    </Typography.Text>
                    <Typography.Link underline onClick={() => {
                        setPromptTitle("");
                        setPromptText("<div style='font-weight: 600; color: #000; font-size: 18px; text-align: center'>Cash Basis Accounting</div>Focuses on cash flow (money in and out). Suitable for small businesses of income up to £150,000 per year.\n\n<div style='font-weight: 600; color: #000; font-size: 18px; text-align: center'>Traditional Accounting</div>Tracks income and expenses as they occur. More detailed and suitable for larger businesses or those with complex finances.");
                        setPromptImageUrl("");
                        setPromptActionButtonText("");
                        setPromptActionButtonUrl("");
                        setShowHelperModal(!showHelperModal);
                    }}>
                        Learn More
                    </Typography.Link>
                    {error && (
                        <Typography.Text className="builder__form-error">
                            {error}
                        </Typography.Text>
                    )}

                </div>

                <div className="builder__toggle-question__switch">
                    <Switch
                        checked={value === "yes" || value === undefined} // Checked if value is yes or undefined (initial state)
                        onChange={handleToggleChange}
                        checkedChildren="YES"
                        unCheckedChildren="NO"
                    />
                </div>
            </div>

        </div>
    );
};

export default UkToggleQuestion;
