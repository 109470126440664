import { useContext } from "react";

import { Typography } from "antd";
import { AppContext } from "App";
import noAnswers from "assets/images/profile/no-answers.png";
import ProfileButtonFooter from "components/basic/Footer/ProfileButtonFooter";
import { Link } from "react-router-dom";

export default function NoCurrentReturn() {
    const { isMobile } = useContext(AppContext);

    return (
        <div className="profile__status-content">
            <img
                width={isMobile ? 200 : 337}
                src={noAnswers}
                alt="No Returns"
                style={{
                    display: "block",
                    margin: "20px auto",
                }}
            />
            <Typography
                className="profile__content-title"
                style={{ padding: "10px" }}
            >
                You don’t have any active submission ... yet!
            </Typography>
            <Typography
                className="profile__content-text"
                style={{
                    margin: "10px 0px",
                    padding: "10px",
                }}
            >
                To start a new submission and claim your refund, please click the button below.
            </Typography>
            {isMobile
                ? <ProfileButtonFooter>
                    <Link
                        to={"/questionnaire"}
                        className="profile__select-tab-button"
                    >
                        Start New Submission
                    </Link>
                </ProfileButtonFooter>
                : <Link
                    to={"/questionnaire"}
                    data-testid={"start-new-submission-button"}
                    className="profile__new-submission-button"
                >
                    Start New Submission
                </Link>
            }
        </div>
    );
}
