import { useContext } from "react";

import { AppContext } from "App";
import connect from "assets/images/home/connect.svg";
import openbanking from "assets/images/home/openbanking.png";

const OpenBankingSlider = () => {
    const {
        maxWidth,
        isMobile,
    } = useContext(AppContext);

    return <div className={"uk-how-it-works-block horizontal-center"} style={{ marginTop: -4 }}>
        <div style={{ maxWidth: isMobile ? maxWidth : "1000px", padding: isMobile ? "24px" : "64px 100px" }}>
            <div className={"uk-how-it-works-block horizontal-center"} >
                <div>
                    <img className={"uk-connect-image"} src={connect} alt={"connect"} />
                </div>
                <div className={"uk-sub-slogan-bank"}>
                Connect your bank account to catch every expense.
                </div>
            </div>
            <div>
                <img src={openbanking} alt={"openbanking"} className={"uk-openbanking-image"} style={{ width: isMobile ? "100%" : "100%" }}/>
            </div>
        </div>

    </div>;
};

export default OpenBankingSlider;
