import "../../../../../styles/questionnaire/documentParsePrompt.less";
import { FC } from "react";

import { Modal, Upload, UploadProps } from "antd";
import greenBulletPoint from "assets/images/new-questionnaire/documentParse/green_bullet_point.svg";
import uploadPurple from "assets/images/new-questionnaire/documentParse/upload_purple.svg";
import { IFile } from "types/files";
import { logError } from "utils/sentry";

import { Receipt } from "./DocumentParseQuestion";
import DocumentUploadList from "./DocumentUploadList";

export interface UploadDesktopViewProps {
    toggleUpload: () => void;
    isUploadOpen: boolean;
    uploadQFile: (_file: IFile) => Promise<boolean>;
    uploadedCategoryReceipts: Receipt[];
    handleContinue?: () => void;
    getFileIcon: (_fileName: string) => string;
    uploadErrorMessage: string;
    setUploadErrorMessage: (_message: string) => void;
}

const UploadDesktopView: FC<UploadDesktopViewProps> = ({
    toggleUpload,
    isUploadOpen,
    uploadQFile,
    uploadedCategoryReceipts,
    getFileIcon,
    uploadErrorMessage,
    setUploadErrorMessage }) => {
    const customRequest = async (data: any) => {
        const result = await uploadQFile(data.file);
        if (!result) {
            logError("File was not uploaded!");
        }
    };

    const handleCloseModal = () => {
        setUploadErrorMessage("");
        toggleUpload();
    };

    const props: UploadProps = {
        customRequest,
        fileList: uploadedCategoryReceipts?.map(file => ({
            uid: file._id!,
            name: file.originalName,
            status: "done",
            url: file.url,
        })),
        showUploadList: false,
    };

    return (
        <div>
            <Modal
                onCancel={handleCloseModal}
                open={isUploadOpen}
                footer={<div className="document-parse-prompt-footer">
                    <div onClick={handleCloseModal} className="document-parse-prompt__array-add-button-grey">
                Cancel
                    </div>
                    <div className="document-parse-prompt__array-add-button">
                        <span onClick={handleCloseModal} style={{ color: "white" }}> Continue </span>
                    </div>
                </div>}>
                <div className={"p10 horizontal-center"}>
                    <div>
                        <div className={"mb20 fs20 bold"}>
                                        Upload Document
                        </div>
                        <div className="document-parse-prompt-bullet-point"><img src={greenBulletPoint} alt="green bullet point"/>You can upload weekly, monthly or yearly statements</div>
                        <div className="document-parse-prompt-bullet-point"><img src={greenBulletPoint} alt="green bullet point"/>We’ll automatically extract your payment and deduction details</div>
                        <div className="document-parse-prompt-bullet-point"><img src={greenBulletPoint} alt="green bullet point"/>Review and edit if any amounts are incorrect</div>
                        <div className="document-parse-prompt-bullet-point"><img src={greenBulletPoint} alt="green bullet point"/>Submit your final income details. Make sure everything is accurate to avoid delays in receiving your refund</div>
                        <div className="document-parse-prompt__array-box">
                            <div className={"horizontal-center"}>
                                <img
                                    src={uploadPurple}
                                    alt="upload purple"
                                />
                            </div>
                            <div className="horizontal-center">Drag and Drop Files here or</div>
                            <div className="document-parse-prompt__array-add-button-light horizontal-center">
                                <Upload {...props} accept=".png, .pdf, .jpeg, .jpg, .doc, .docx">
                            Browse Files
                                </Upload>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <DocumentUploadList
                        uploadedCategoryReceipts={uploadedCategoryReceipts}
                        getFileIcon={getFileIcon}
                        uploadErrorMessage={uploadErrorMessage}
                    />
                </div>
            </Modal>

        </div>
    );
};

export default UploadDesktopView;
