import IBAN from "iban";
import { isValidNumberForRegion } from "libphonenumber-js";

export const isPhoneValid = (number = "") => {
    return (number === "" || isValidNumberForRegion(number, "IE") || isValidNumberForRegion(number, "GB"));
};

export const isIbanValid = (iban = "") => {
    return IBAN.isValid(iban);
};

export function isBicValid(bic = "") {
    const bicPattern = /^[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/;
    return bicPattern.test(bic);
}

export function isEmailValid(email = "") {
    const emailPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailPattern.test(email);
}

export function isProbableEmailDomainMisspelling(email = "") {
    if (!email || !email.includes("@")) {
        return false;
    }

    // List of common top-level domains (TLDs) misspellings
    const misspelledTLDs = [".con", ".cmo", ".cm", ".co", ".nte", ".nett", ".ne", ".ten", ".ku", ".for", ".ed",
        ".coom", ".uj", ".ul", ".uu", ".ck", ".moc"];

    const domain = email.split("@")[1];
    const domainTLD = domain.split(".").pop();
    // Check if the extracted TLD is in the list of common TLDs
    return misspelledTLDs.includes(`.${domainTLD}`);
}

export function isNINValid(nin = "") {
    // EXAMPLES AB123456C, CD987654A, EF345678B, GH876543D
    const ukNINPattern = /^[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z]\d{6}[A-D]$/i;
    return ukNINPattern.test(nin);
}

export const normalizeUTR = (utr?: string | null) => {
    if (!utr) return undefined;

    return utr
        .replace(/[^a-z0-9]/gi, "")
        .toLowerCase();
};

export function isPostcodeValid(postcode = "") {
    //ReqExp from https://ideal-postcodes.co.uk/guides/postcode-validation
    const postcodePattern = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;
    return postcodePattern.test(postcode);
}

export function isUTRValid(inputtedUtr = "") {
    // EXAMPLES: 3456789012; 7654321098; 1234567890123
    // OR 10/13 numbers that starts or ends with "k": k1234567890; 1234567890k; k1234567890123; 1234567890123k
    if (!inputtedUtr) return false;

    const regex = new RegExp("[0-9]{10}");

    let utr = inputtedUtr.replace(/\s/g, "");

    //UTR's can have a K at the end, we should handle this gracefully
    if (utr[0].toUpperCase() === "K") {
        utr = utr.substring(1, utr.length);
    }

    if (utr[utr.length - 1].toUpperCase() === "K") {
        utr = utr.substring(0, utr.length - 1);
    }

    if (utr.length === 13) {
        utr = utr.substring(0, 10);
    }

    if (utr.length !== 10) {
        return false;
    }

    if (!regex.test(utr)) {
        return false;
    }

    const mod = 11;
    const weights = [0, 6, 7, 8, 9, 10, 5, 4, 3, 2]; //weights found online https://github.com/parkerr/UTRValidate/blob/master/app.js
    let total = 0;

    for (let i = 0; i < 10; i++) {
        total += parseInt(utr[i]) * weights[i];
    }

    const remainder = total % mod;

    const checkDigits = [2, 1, 9, 8, 7, 6, 5, 4, 3, 2, 1];//digits found online https://github.com/parkerr/UTRValidate/blob/master/app.js

    return checkDigits[remainder] === parseInt(utr[0]);
}

export function isPPSnBlock(ppsn = "") {
    const blockedPPSn: Array<string> = ["7022413k", "4259605q", "9436347b", "3695623t", "7892582q", "8076573v", "8196019o", "9559564ga", "5959161r", "9839818ua", "2447075s"];
    return !blockedPPSn.includes(ppsn.toLowerCase());
}

export function isNameValid(name = "") {
    const apostrophePattern = /['‘’′‛`"]/;
    return !apostrophePattern.test(name);
}

export function isPasswordLengthValid(password = "", min = 8) {
    return password.length >= min;
}

export const normalizeNIN = (nin?: string | null) => {
    if (!nin) return undefined;

    return nin
        .replace(/[^a-z0-9]/gi, "")
        .toLowerCase();
};
