import React, { useEffect, useState, useCallback, useRef } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import useDebounce from "hooks/helpers/useDebounce";

type Props = {
    onApplyFiltersSortSearch: (_filters: Record<string, any>) => void;
};

const TransactionsSearch: React.FC<Props> = ({ onApplyFiltersSortSearch }) => {
    const [searchQuery, setSearchQuery] = useState<string>("");

    // Debounce the search query directly with a 1-second delay
    const debouncedSearchQuery = useDebounce(searchQuery, 1000);
    const prevDebouncedSearchQuery = useRef<string>("");

    const handleApplyFilters = useCallback(
        (filters: Record<string, any>) => {
            onApplyFiltersSortSearch(filters);
        },
        [onApplyFiltersSortSearch]
    );

    useEffect(() => {
        // Only call handleApplyFilters if the debounced search query has actually changed
        if (debouncedSearchQuery !== prevDebouncedSearchQuery.current) {
            handleApplyFilters({ searchQuery: debouncedSearchQuery });
            prevDebouncedSearchQuery.current = debouncedSearchQuery;
        }
    }, [debouncedSearchQuery, handleApplyFilters]);

    return (
        <Input
            type="default"
            placeholder="Search transactions..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            allowClear
            prefix={<SearchOutlined />}
            className="transactionSearch"
            onPressEnter={() => handleApplyFilters({ searchQuery })}
            style={{ borderRadius: "50px" }}
        />
    );
};

export default TransactionsSearch;
