import "styles/buttonFooter.less";
import { FC, useContext } from "react";

import { Button } from "antd";
import { AppContext } from "App";
import { useNavigate } from "react-router-dom";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

import LoginButton from "../LogInButton";
import StartButtonNew from "../StartButtonNew";

interface ButtonFooterProps {
    showButtonFooter: boolean;
}

const ButtonFooterNew: FC<ButtonFooterProps> = ({ showButtonFooter = true }) => {
    const navigate = useNavigate();
    const { isMobile, isAuth } = useContext(AppContext);
    const isKeyboardOpen = useDetectKeyboardOpen();

    if (!isMobile || isKeyboardOpen) return null;

    return (
        <div className={`button-footer ${showButtonFooter ? "visible" : ""}`}>
            {isAuth
                ? <div className="ml20">
                    <LoginButton label={"Profile"}/>
                </div>
                : <>
                    <div className="">
                        <StartButtonNew />
                    </div>
                    <div className="button-footer-or">OR</div>
                    <Button
                        size={"large"}
                        shape={"round"}
                        type={"link"}
                        className={"uk-link-button"}
                        onClick={() => {
                            navigate("/auth");
                        }}
                    >
                        <span className={"uk-link-button-label-new"}>Login</span>
                    </Button>
                </>
            }
        </div>
    );
};

export default ButtonFooterNew;
