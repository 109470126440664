import "styles/common/alignment.less";

import { useMemo } from "react";

import { autoPlacement } from "@floating-ui/react";
import { parse } from "date-fns";
import DatePicker from "react-datepicker";
import Select from "react-select";

const monthOptions = Array.from({ length: 12 }, (_, i) => ({
    value: i,
    label: new Date(0, i).toLocaleString("default", { month: "long" }),
}));

const createYearOptions = (start: number, end: number) =>
    Array.from({ length: end - start + 1 }, (_, i) => ({
        value: start + i,
        label: String(start + i),
    }));

const DatePickerCustomHeader = ({ date, changeYear, changeMonth }: { date: Date | null, changeYear: any, changeMonth: any }) => {
    const yearOptions = useMemo(() => createYearOptions(1900, 2100), []);

    return (
        <div className="display-flex space-between gap10">
            <Select
                onChange={e => changeMonth(e?.value)}
                options={monthOptions}
                className="custom-date-picker__select-container"
                classNamePrefix="react-select"
                placeholder={null}
                defaultValue={monthOptions.find(({ value }) => value === (date ? date.getMonth() : new Date().getMonth()))}
            />
            <Select
                onChange={e => changeYear(e?.value)}
                options={yearOptions}
                className="custom-date-picker__select-container"
                classNamePrefix="react-select"
                placeholder={null}
                defaultValue={yearOptions.find(({ value }) => value === (date ? date.getFullYear() : new Date().getFullYear()))}
            />
        </div>
    );
};

type DatePickerDesktopProps = {
    selectedDate: Date | null;
    handleDateChange: (_date: Date | null) => void;
    dateFormat: string;
    minDate?: string;
    maxDate?: string;
    className: string;
    calendarClassName: string;
    placeholderText?: string;
    portalId?: string;
}

export default function DatePickerDesktop(props: DatePickerDesktopProps) {
    const { selectedDate, handleDateChange, dateFormat, minDate, maxDate, className, calendarClassName, placeholderText, portalId } = props;

    return (
        <DatePicker
            portalId={portalId ? portalId : undefined}
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat={dateFormat}
            minDate={minDate ? parse(minDate, dateFormat, new Date()) : undefined}
            maxDate={maxDate ? parse(maxDate, dateFormat, new Date()) : undefined}
            showMonthDropdown
            showYearDropdown
            popperPlacement="bottom-start"
            dropdownMode="scroll"
            popperModifiers={[
                autoPlacement({
                    allowedPlacements: ["bottom"],
                }),
            ]}
            scrollableYearDropdown
            renderCustomHeader={({ changeYear, changeMonth }) => (
                <DatePickerCustomHeader date={selectedDate} changeYear={changeYear} changeMonth={changeMonth} />
            )}
            className={className}
            calendarClassName={calendarClassName}
            placeholderText={placeholderText ? placeholderText : ""}
        />);
}
