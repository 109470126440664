import { Dispatch, SetStateAction } from "react";

import { Form, Input } from "antd";
import { useAppDispatch } from "redux/hooks";
import { checkClientEmailAvailability } from "services/client";
import { isEmailValid } from "utils/validation";

import Error from "../Labels/Error";
import Label from "../Labels/Label";

const EmailInput = ({
    required = false,
    autoFocus = false,
    disabled = false,
    labelDisabled = false,
    checkAvailability = false,
    checkExists,
    name = "email",
    setAllowed,
    setLoginErrorStatus,
}: {
    required?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    labelDisabled?: boolean;
    checkAvailability?: boolean;
    checkExists?: boolean;
    name?: string;
    setAllowed?: Dispatch<SetStateAction<boolean>>
    setLoginErrorStatus?: Dispatch<SetStateAction<number | null>>
}) => {
    const dispatch = useAppDispatch();

    const handleEmailChange = (async (value: string) => {
        const result = await dispatch(checkClientEmailAvailability.initiate({
            email: value,
        })).unwrap();

        return result?.available;
    });

    return <div>
        <Form.Item
            hasFeedback
            name={name}
            label={labelDisabled ? null : <Label text={"Email"}/>}
            required={required}
            labelAlign={"left"}
            validateFirst
            rules={[{
                required,
                message: <Error text={"Please enter your email address"}/>,
            }, {
                validator: async (_, value) => {
                    setLoginErrorStatus && setLoginErrorStatus(null);
                    const trimmedEmail = value.trim();
                    const isValid = isEmailValid(trimmedEmail);

                    if (setAllowed && !isValid) {
                        setAllowed(isValid);
                    }

                    if (!isValid) {
                        return Promise.reject(<Error text={"Please enter a valid email address"}/>);
                    }

                    if (checkAvailability) {
                        const available = await handleEmailChange(trimmedEmail);

                        if (!available)
                            return Promise.reject(<Error text={"Email already exists"}/>);
                    }

                    if (checkExists && setLoginErrorStatus) {
                        const available = await handleEmailChange(trimmedEmail);

                        if (available) {
                            setLoginErrorStatus(404);
                            return Promise.reject(<Error text={""}/>);
                        } else {
                            setLoginErrorStatus(null);
                            return Promise.resolve();
                        }
                    }

                    return Promise.resolve();
                },
            }]}>
            <Input
                autoFocus={autoFocus}
                placeholder="Enter your email"
                data-testid={name}
                size="large"
                style={{
                    height: "48px",
                    borderRadius: "4px",
                }}
                disabled={disabled}
                type="email"
            />
        </Form.Item>

    </div>;
};

export default EmailInput;
