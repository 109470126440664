import "styles/profile/paymentDetails.less";
import "styles/profile/progressBar.less";
import "swiper/css";

import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";

import { Typography, message } from "antd";
import { AppContext } from "App";
import bankIcon from "assets/images/profile/payment/bank.svg";
import rocketIcon from "assets/images/profile/payment/rocket.svg";
import LottieAnimation from "components/basic/Spinner/Lottie";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getCurrentUser } from "redux/reducers/auth";
import { setCountryRegions, setOpenBankingError } from "redux/reducers/countries";
import { useConfirmDocPaymentPlanMutation, useUpdateUserPaymentDetailsMutation } from "services/auth";
import { useGetBanksMutation } from "services/connectBankAccount";
import { IUpdateUserPaymentDetailsRequestBody, IUser } from "types/auth";
import { checkCurrentReturn, getCurrentReturn } from "utils/returns";

import BasicPlanModal from "./BasicPlanModal";
import NoCurrentPayment from "./NoCurrentPayment";
import PaymentDetails from "./PaymentDetails";
import PaymentInProgress from "./PaymentInProgress";
import { PaymentPlan } from "./PaymentPlan";
import { PlanConfirmed } from "./PlanConfirmed";
import { ProgressBar } from "./ProgressBar";
import { RootState } from "../../../../../redux/store";
import AddPaymentDetails from "../Homepage/AddPaymentDetail";
import StartPage from "../IDPal/StartPage";

type PaymentPlansProps = {
    user: IUser;
    setSelectedTab: (_tab: string) => void;
    setPageLoading: Dispatch<SetStateAction<boolean>>;
};

export type PaymentOption = {
    id: string;
    title: string;
    subtitle: string;
    processingTime: string;
    additionalInfo: string;
    feeInfo: string;
    fee: {
        rate: number;
        fixedCost: number;
        minimum: number;
    };
    mostPopular?: boolean;
    vat: number;
    icon?: string;
    employmentType: string;
    days: number;
};

export const paymentOptions: PaymentOption[] = [
    {
        id: "express-cis",
        title: "EXPRESS",
        subtitle: "Fastest Option",
        processingTime: "1-2 days processing",
        additionalInfo: "Faster for Revolut",
        feeInfo: "Standard fee + £24.99",
        employmentType: "cis", // This needs review as there could be multiple
        days: 2,
        fee: {
            rate: 0,
            fixedCost: 20,
            minimum: 249,
        },
        vat: 0.2,
        icon: rocketIcon,
    },
    {
        id: "standard-cis",
        employmentType: "cis", // This needs review as there could be multiple
        title: "STANDARD",
        subtitle: "Standard Option",
        processingTime: "3-5 days processing",
        additionalInfo: "Faster for Revolut",
        feeInfo: "Standard fee",
        days: 7,
        fee: {
            rate: 0,
            fixedCost: 0,
            minimum: 249,
        },
        vat: 0.2,
        icon: bankIcon,
        mostPopular: true,
    },
];

export default function Payment({
    user,
    setSelectedTab,
    setPageLoading,
}: Readonly<PaymentPlansProps>) {
    const dispatch = useAppDispatch();
    const { isMobile } = useContext(AppContext);
    const { isOpened, open, close } = useModalOpener();

    const [confirmDocPaymentPlan, { isLoading: confirmPaymentPlanLoading }] =
        useConfirmDocPaymentPlanMutation();
    const [getBanksMutation] = useGetBanksMutation();
    const [updateUserPaymentDetails, { isLoading: updatePaymentDetailsLoading }] =
        useUpdateUserPaymentDetailsMutation();

    const [selectedPaymentOption, setSelectedPaymentOption] =
        useState<PaymentOption | null>(null);
    const goCardlessError = useAppSelector((state: RootState) => state.bank.openBankingError);
    const [showPaymentDetails, setShowPaymentDetails] = useState(goCardlessError);

    const idpalComplete = localStorage.getItem("idpalComplete") === "true";
    const verificationOn = localStorage.getItem("verificationOn") === "true";

    const currentDocument = useMemo(() => {
        return getCurrentReturn(user.documents);
    }, [user.documents]);

    const [confirmationProgress, setConfirmationProgress] = useState<string>((
        currentDocument?.status.id === "bank-details-requested" ||
        currentDocument?.status.id === "awaiting-bank-details-confirm") &&
        currentDocument?.confirmed
        ? "Bank Details"
        : "Payment Plan"
    );

    const hasCurrentReturn = useMemo(
        () => checkCurrentReturn(currentDocument),
        [currentDocument]
    );

    const paymentConfirmed = useMemo(() => {
        return !!currentDocument?.confirmed && !!user.paymentDetails?.confirmed;
    }, [currentDocument?.confirmed, user.paymentDetails?.confirmed]);

    const refund = currentDocument?.refund;
    const plan = currentDocument?.plan;

    const skippedDetails = useMemo(() => {
        if (selectedPaymentOption?.id) {
            if (
                selectedPaymentOption?.id === "basic" ||
                selectedPaymentOption?.id === "one4all" ||
                (user.paymentDetails?.iban &&
                    user.paymentDetails?.bic &&
                    user.paymentDetails?.fullName)
            ) {
                return true;
            }
        }

        if (plan) {
            return plan === "basic" || plan === "one4all";
        }

        return false;
    }, [plan, selectedPaymentOption?.id, user.paymentDetails]);

    const amount = useMemo(() => {
        return (refund?.received || 0) - (refund?.paid || 0);
    }, [refund]);

    const allowedPaymentOptions = useMemo(() => {
        return paymentOptions.filter(option => {
            return refund?.availablePlans.includes(option.id);
        });
    }, [refund]);

    const handlePlanConfirm = () => {
        confirmDocPaymentPlan({
            documentId: currentDocument!._id,
            plan: selectedPaymentOption!.id,
            shouldUpdateStatus: false,
        })
            .unwrap()
            .then(async () => {
                setConfirmationProgress("Bank Details");
                dispatch(getCurrentUser());
                setSelectedPaymentOption(null);
                await message.success("Payment Plan confirmed successfully");
            })
            .catch(async error => {
                await message.error(error.data.message);
            });
    };

    const handleDetailsConfirm = (
        values: IUpdateUserPaymentDetailsRequestBody
    ) => {
        updateUserPaymentDetails({
            ...values,
            address:
                user.address.address1 +
                ", " +
                user.address.address2 +
                ", " +
                user.address.city +
                ", " +
                user.address.postcode,
        })
            .unwrap()
            .then(async () => {
                dispatch(getCurrentUser());
                await message.success("Payment details confirmed successfully");
                setConfirmationProgress("Success");
            })
            .catch(async error => {
                await message.error(error.data.message);
            });
    };

    const handleBasicPlanConfirm = () => {
        close();
        handlePlanConfirm();
        updateUserPaymentDetails({
            fullName: user.info.firstName + ", " + user.info.lastName,
            address:
                user.address.address1 +
                ", " +
                user.address.address2 +
                ", " +
                user.address.city +
                ", " +
                user.address.postcode,
        })
            .unwrap()
            .then(async () => {
                dispatch(getCurrentUser());
                await message.success("Payment details confirmed successfully");
            })
            .catch(async error => {
                await message.error(error.data.message);
            });
    };

    const handleBankPlanConfirmWithOldDetails = () => {
        close();
        handlePlanConfirm();
        updateUserPaymentDetails({
            fullName: user.paymentDetails?.fullName!,
            address: user.paymentDetails?.address!,
            iban: user.paymentDetails?.iban,
            bic: user.paymentDetails?.bic,
        })
            .unwrap()
            .then(async () => {
                dispatch(getCurrentUser());
                await message.success("Payment details confirmed successfully");
            })
            .catch(async error => {
                await message.error(error.data.message);
            });
    };

    const handleConfirm = () => {
        if (selectedPaymentOption?.id === "basic") {
            open();
        } else if (selectedPaymentOption?.id === "one4all") {
            handleBasicPlanConfirm();
        } else if (
            user.paymentDetails?.iban &&
            user.paymentDetails?.bic &&
            user.paymentDetails?.fullName
        ) {
            handleBankPlanConfirmWithOldDetails();
        } else {
            handlePlanConfirm();
        }
    };

    const handleGoToPaymentDetails = () => {
        setShowPaymentDetails(false);
    };

    const handleAuto = () => {
        setShowPaymentDetails(true);
    };

    function handleCountries() {
        setPageLoading(true);
        getBanksMutation({ countryCode: "GB" })
            .unwrap()
            .then(data => {
                if (data) {
                    dispatch(setCountryRegions(data?.institutions));
                }
            })
            .catch(error => {
                console.error("Error fetching banks:", error);
                dispatch(setOpenBankingError(true));
            })
            .finally(() => {
                setPageLoading(false);
                setSelectedTab("Search your Bank");
            });
    }

    useEffect(() => {
        if (
            !idpalComplete &&
            verificationOn &&
            currentDocument?.status.id !== "bank-details-requested"
        ) {
            console.log("ID Verification");
            setConfirmationProgress("ID Verification");
        } else if (verificationOn) {
            console.log("Success page as paymentConfirmed && idpalComplete && verificationOn ");
            paymentConfirmed && idpalComplete && setConfirmationProgress("Success");
        } else {
            console.log("Else Success");
            paymentConfirmed && setConfirmationProgress("Success");
        }
    }, [paymentConfirmed, verificationOn, idpalComplete, currentDocument]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setPageLoading(false);
        }, 300);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="profile__content">
                <LottieAnimation
                    isLoading={confirmPaymentPlanLoading || updatePaymentDetailsLoading}
                />
                {!isMobile && (
                    <div className="profile__content-header">
                        <Typography className="profile__content-heading">
                            Payment
                        </Typography>
                    </div>
                )}

                {

                    (currentDocument?.status.id === "bank-details-requested" ||
                        currentDocument?.status.id === "awaiting-bank-details-confirm" ||
                        currentDocument?.status.id === "below-min-cheque-fee" ||
                        currentDocument?.status.id === "awaiting-id-verification"

                    ) ? <div className="payment-plans__content" style={{ paddingTop: 0 }}>
                            <ProgressBar
                                confirmationProgress={confirmationProgress}
                                skippedDetails={skippedDetails}
                                planConfirmed={!!currentDocument?.confirmed}
                                user={user}
                            />
                            {!showPaymentDetails && confirmationProgress === "Bank Details" &&
                            <PaymentDetails
                                user={user}
                                handleFinish={handleDetailsConfirm}
                                planConfirmed={!!currentDocument?.confirmed}
                                showPaymentDetails={handleAuto}
                                updateUserPaymentDetails={updateUserPaymentDetails}
                                setPageLoading={setPageLoading}
                                title={null}
                                isError={goCardlessError}
                                handleDetailsConfirm={handleDetailsConfirm}
                            />
                            }

                            {showPaymentDetails && confirmationProgress === "Bank Details" &&
                            <AddPaymentDetails
                                handleFinish={handleGoToPaymentDetails}
                                handleCountries={handleCountries}
                            />
                            }

                            {confirmationProgress === "Payment Plan" && currentDocument &&
                            <PaymentPlan
                                allowedPaymentOptions={allowedPaymentOptions}
                                shownAmount={amount}
                                currentDocument={currentDocument}
                                selectedPaymentOption={selectedPaymentOption}
                                setSelectedPaymentOption={setSelectedPaymentOption}
                                handlePlanConfirm={handleConfirm}
                                refund={currentDocument?.refund}
                            />}

                            {confirmationProgress === "ID Verification" &&
                            <StartPage
                                setSelectedTab={setSelectedTab}
                            />}
                            {confirmationProgress === "Success" &&
                            <PlanConfirmed
                                setSelectedTab={setSelectedTab}
                                plan={plan}
                                refund={refund}
                            />}
                            { }

                        </div>
                        : <div className="payment-plans__content">
                            {hasCurrentReturn && currentDocument?.status.document !== "done"
                                ? confirmationProgress === "Success"
                                    ? <PlanConfirmed
                                        setSelectedTab={setSelectedTab}
                                        plan={plan}
                                        refund={refund}
                                    />
                                    : <PaymentInProgress setPageLoading={setPageLoading} />
                                : <NoCurrentPayment hideButton={hasCurrentReturn} />
                            }
                        </div>

                }
            </div>

            <BasicPlanModal
                isOpened={isOpened}
                close={close}
                onConfirm={handleBasicPlanConfirm}
            />
        </>
    );
}
