import "styles/profile/idpal.less";

import { useEffect, useState, useContext, useRef } from "react";

import { AppContext } from "App";
import errorIcon from "assets/images/error_icon.png";
import LottieAnimation from "components/basic/Spinner/Lottie";
import { useUser } from "hooks/common/useUser";
import { useGetUuidMutation } from "services/idpal";

type IdPalComponentProps = {
    setSelectedTab: (_tab: string) => void;
};

function IdPalComponent({ setSelectedTab }: IdPalComponentProps) {
    const [uuid, setUuid] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const { user } = useUser();
    const { isMobile } = useContext(AppContext);
    // eslint-disable-next-line no-undef
    const [getUuid] = useGetUuidMutation();

    if (!user) {
        throw new Error("User was not found");
    }
    const clientId = user.id;

    useEffect(() => {
        getUuid(clientId)
            .unwrap()
            .then(data => {
                if (data) {
                    setUuid(data.data.result);
                }
                setLoading(false);
            });
    }, [clientId, getUuid]);

    const isMounted = useRef(true);

    useEffect(() => {
        const pollInterval = 5000; // 5 seconds
        const maxPollAttempts = 60; // Up to 5 minutes (60 * 5 seconds)

        let pollAttempts = 0;

        const pollIdpalStatus = () => {
            if (!isMounted.current) return;

            console.log("Polling attempt:", pollAttempts + 1);
            fetch(`${process.env.REACT_APP_API_URL}idpal/checkSubmissionCompleted?client_id=${clientId}`)
                .then(response => response.json())
                .then(data => {
                    console.log("Response from checkSubmissionCompleted:", data);

                    const isIdpalCompleteOrTimeoutResponse = data.idpalComplete;

                    if (isIdpalCompleteOrTimeoutResponse) {
                        setSelectedTab("ThankyouPage");
                        localStorage.setItem(
                            "idpalComplete",
                            isIdpalCompleteOrTimeoutResponse
                        );
                    } else if (pollAttempts < maxPollAttempts) {
                        pollAttempts++;
                        if (pollAttempts === 2) {
                            setTimeout(pollIdpalStatus, 50000);
                        } else {
                            setTimeout(pollIdpalStatus, pollInterval);
                        }
                    } else {
                        // Maximum polling attempts reached
                        console.log("Max poll attempts reached");
                    }
                })
                .catch(error => {
                    console.error("Error polling:", error);
                    // Handle errors if necessary
                });
        };

        // Start the polling process when the component mounts
        pollIdpalStatus();
        return () => {
            console.log("Unmounting");
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId, setSelectedTab]);

    return (
        <div className="profile__content">
            <LottieAnimation isLoading={loading} />
            {uuid ? (
                <iframe
                    title="IdPal Integration"
                    style={
                        isMobile
                            ? { width: "335px", height: "600px" }
                            : { width: "100%", height: "100%" }
                    }
                    src={`https://websubmission.client.id-pal.com/?lang=en&uuid=${uuid}`}
                    allowFullScreen
                ></iframe>
            ) : (
                !loading && (
                    <div style={{ marginTop: "150px" }}>
                        <img
                            className="error__icon"
                            alt="error icon"
                            src={errorIcon}
                        />
                        <div
                            className="idpal__content-title"
                            style={{ fontSize: "19px", marginBottom: " 12px" }}
                        >
                            Something went wrong
                        </div>
                        <div
                            className="idpal__content-text"
                            style={{ padding: "0 30px 30px 30px" }}
                        >
                            Something went wrong with ID-Pal. Please try again.
                        </div>
                    </div>
                )
            )}
        </div>
    );
}

export default IdPalComponent;
