import "styles/floatInput.less";

import { Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from "react";

import { AppContext } from "App";
import checkMark from "assets/images/checkmark.svg";
import warning from "assets/images/warning.svg";
import { EnableValidationT } from "components/basic/SignUpForm/SignUpFormNew";
import { format, parse } from "date-fns";
import { PickerValue } from "react-mobile-picker";

import DatePickerDesktop from "../../../pages/NewQuestionnaire/components/CustomDatePicker/DatePickerDesktop";
import DatePickerMobile from "../../../pages/NewQuestionnaire/components/CustomDatePicker/DatePickerMobile";

interface FloatDatePickerProps {
    label: string;
    inputId: string;
    value?: string;
    maxDate: string;
    error?: string;
    onChange: (_date: string) => void;
    setEnableValidation?: Dispatch<SetStateAction<EnableValidationT>>;
    portalId: string
}

const UkFloatDatePicker = ({
    label,
    inputId,
    value,
    maxDate,
    error,
    onChange,
    setEnableValidation,
    portalId,
}: FloatDatePickerProps) => {
    const { isMobile } = useContext(AppContext);

    const [selectedDate, setSelectedDate] = useState<Date | null>(
        value ? parse(value, "yyyy/MM/dd", new Date()) : null
    );
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [focus, setFocus] = useState(false);

    const labelClass = useMemo(() => {
        const baseClass = "uk-label";
        const isLongLabel = label.length > 40;
        const isVeryLongLabel = label.length > 65;

        const isOccupied = focus || value;

        let computedLabelClass = isOccupied
            ? `${baseClass} uk-as-label`
            : `${baseClass} as-placeholder`;

        if (isOccupied && error) {
            computedLabelClass += " text-error";
        }

        if (isMobile) {
            if (!isOccupied && (isLongLabel || isVeryLongLabel)) {
                computedLabelClass += " two-line-placeholder";
            }

            if (isOccupied && (isLongLabel || isVeryLongLabel)) {
                computedLabelClass += " two-line-label";
            }
        } else {
            if (!isOccupied && isVeryLongLabel) {
                computedLabelClass += " two-line-placeholder";
            }

            if (isOccupied && isVeryLongLabel) {
                computedLabelClass += " two-line-label";
            }
        }
        return computedLabelClass;
    }, [label.length, focus, value, error, isMobile]);

    const handleMobilePickerChange = useCallback((newValue: PickerValue) => {
        if (newValue.year && newValue.month && newValue.day) {
            const newDate = new Date(
                parseInt(String(newValue.year), 10),
                parseInt(String(newValue.month), 10) - 1,
                parseInt(String(newValue.day), 10)
            );
            setSelectedDate(newDate);
            onChange(format(newDate, "yyyy/MM/dd"));
        }
    }, [setSelectedDate, onChange]);

    const handleChange = (date: Date | null) => {
        if (date) {
            setSelectedDate(date);
            onChange(format(date, "yyyy/MM/dd"));
        }
    };

    const handleBlur = () => {
        setEnableValidation && setEnableValidation((prev: EnableValidationT) => ({
            ...prev,
            [inputId]: true,
        }));
        setFocus(false);
    };

    const handleFocus = () => {
        setFocus(true);
    };

    return (
        <div
            className="uk-float-label"
            onBlur={handleBlur}
            onFocus={handleFocus}
            onClick={() => {
                if (!isDatePickerOpen) {
                    setIsDatePickerOpen(true);
                }
            }}
        >
            {isMobile
                ? <div className="custom-date-picker__mobile fs11">
                    <div className="uk-input-field">{selectedDate ? format(selectedDate, "dd/MM/yyyy") : ""}</div>
                    <DatePickerMobile isOpen={isDatePickerOpen} onClose={() => setIsDatePickerOpen(false)}
                        maxDate={maxDate}
                        handlePickerChange={handleMobilePickerChange}
                        selectedDate={selectedDate}
                        pickerTitle="Select Date of Birth"/>
                </div>
                : <DatePickerDesktop
                    selectedDate={selectedDate}
                    handleDateChange={handleChange}
                    dateFormat="dd/MM/yyyy"
                    maxDate={maxDate}
                    className="uk-float-date-picker__calendar-input"
                    calendarClassName="uk-float-date-picker__calendar"
                    portalId={portalId}
                />
            }
            <label onClick={handleFocus} className={labelClass}>{label}</label>
            {(value || error) && <img className="status-icon mr10" src={error ? warning : checkMark} alt="status icon"/>}
        </div>
    );
};

export default UkFloatDatePicker;
