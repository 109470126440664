import { useLocalStorage } from "hooks/helpers/useLocalStorage";
import { useAppDispatch } from "redux/hooks";
import { resetAnswers } from "redux/reducers/answers";
import { logout } from "redux/reducers/auth";
import { resetDocument, setExpensesExplainerShown } from "redux/reducers/document";
import { resetNavigation } from "redux/reducers/navigation";
import { resetTaxProfile } from "redux/reducers/taxProfile";
import { IClientState } from "types/client";
import { ProgressT } from "types/questionnaire";

const Logout = () => {
    const dispatch = useAppDispatch();

    const [, setLocalClientData] = useLocalStorage<IClientState | null>("clientData", null);
    const [, setTotalProgress] = useLocalStorage<ProgressT>("new_questionnaire_total_progress", {});
    const [, setSavedProgress] = useLocalStorage<ProgressT>("savedProgress", {});
    const [, setCurrentSubTab] = useLocalStorage<string>("currentSubTab", "");
    const [, setCurrentGroupId] = useLocalStorage<string>("currentGroupId", "");

    return (
        <div>
            <div
                onClick={() => {
                    setLocalClientData(null);
                    setTotalProgress({});
                    setSavedProgress({});
                    setCurrentSubTab("");
                    setCurrentGroupId("");
                    dispatch(resetAnswers());
                    dispatch(resetTaxProfile());
                    dispatch(resetNavigation());
                    dispatch(resetDocument());
                    dispatch(setExpensesExplainerShown(false));
                    dispatch(logout());
                }}
                className={"pointer"}
                data-testid={"logout"}
            >
                <span>Sign out</span>
            </div>
        </div>
    );
};

export default Logout;
