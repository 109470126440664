import { useContext, useState } from "react";

import { Col, Row } from "antd";
import { AppContext } from "App";
import googleIcon from "assets/images/google.svg";
import image1 from "assets/images/home/image1.png";
import image2 from "assets/images/home/image2.png";
import image3 from "assets/images/home/image3.png";
import image4 from "assets/images/home/image4.png";
import ButtonFooterMarketing from "components/basic/Footer/ButtonFooterMarketing";
import HeaderNew from "components/basic/Header/HeaderNew";

import { getStars } from "../HomeNew/components/getStars";
import HowItWorks from "../HomeNew/components/HowItWorks";
import MainNew from "../HomeNew/components/MainNew";
import WeHelped from "../HomeNew/components/WeHelped";
import WhatCustomersSayingNew from "../Reviews/ReviewsNew";

const Marketing = () => {
    const { isMobile, maxWidth } = useContext(AppContext);
    const [, setShowButtonFooter] = useState(false);

    return (
        <>
            {isMobile
                ? <div id="marketingStartButton" className={"uk-main-block-new horizontal-center" } style={{ padding: isMobile ? "24px" : "64px 100px", overflow: "hidden" }}>
                    <div id="marketingStartButton">
                        <div className={"mt40"}>
                            <div className="center">
                                <video controls autoPlay muted loop playsInline className={"uk-marketing-video mb20"} style={{ width: "100%" }}>
                                    <source src={"https://taxzap-videos.s3.eu-west-1.amazonaws.com/taxzap_marketing_video.mp4"} type="video/mp4"/>
                            Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                        <Row>
                            <div>
                                <div className={"marketing-sub-slogan"}>
                                    <span>
                                        <span
                                            className={"uk-sub-slogan-purple"}
                                        > Swipe{" "}</span>
                                       & submit your tax{" "}
                                        <span
                                            className={"uk-sub-slogan-green"}>
                                         return   </span>
                                         instantly.
                                    </span>
                                </div>
                                <div className={"uk-slogan-description-block"}>
                                    <span className={"uk-slogan-description"}>
                                        File your self-assessment tax return for only
                                        <span className="bold"> £179. </span>
                                        We'll help you uncover your allowable expenses and submit your return directly to HMRC — fast, accurate, and stress-free.
                                    </span>
                                </div>
                                <div className={isMobile ? "mt20" : "mt40"} style={{ overflow: "hidden" }}>
                                    <Row gutter={[32, 16]}>
                                        {[
                                            {
                                                id: "google",
                                                label: "Google Reviews",
                                                score: 4.7,
                                                icon: googleIcon,
                                            },
                                        ].map(item => {
                                            return (
                                                <Col
                                                    key={item.id}
                                                    sm={24}
                                                    xs={24}
                                                    md={24}
                                                    lg={24}
                                                    xl={9}
                                                >
                                                    <div className={"horizontal-left"}>
                                                        <div>
                                                            <img
                                                                src={item.icon}
                                                                width={36}
                                                                height={36}
                                                                alt={item.id}
                                                            />
                                                        </div>
                                                        <div className={"ml10"}>
                                                            <div className={"grey-7-color bold fs12"}>
                                                                {item.label}
                                                            </div>
                                                            <div className={"horizontal-left"}>
                                                                <div className={"fs18 bold"}>
                                                                    {item.score}
                                                                </div>
                                                                <div className={"ml10"}>
                                                                    {getStars(5, 22)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </div>
                                <div className="container mt30" style={{ gap: "21px, mar" }}>
                                    <div className="text-container">
                                        <span className={"uk-sub-slogan-purple"} style={{
                                            fontSize: isMobile ? "24px" : "48px",
                                            fontWeight: "500",
                                            lineHeight: "40px",
                                        }}>125K+</span>
                                        <p className={"mt20 grey-6-color"}>Tax returns filed for our clients.</p>
                                    </div>
                                    <div className="image-container">
                                        <img src={image1} alt="Person 1" className="overlay-image image1"/>
                                        <img src={image2} alt="Person 2" className="overlay-image image2"/>
                                        <img src={image3} alt="Person 3" className="overlay-image image3"/>
                                        <img src={image4} alt="125K+" className="overlay-image symbol"/>
                                    </div>
                                </div>
                            </div>
                            <Col sm={24} xs={24} md={24} lg={24} xl={10}>
                                <div></div>
                            </Col>
                        </Row>
                        <div>
                            <div className={"marketing-sub-slogan mt40"} style={{ fontSize: "26px", lineHeight: "34px" }}>
                        Real stories from happy customers...
                            </div>
                            <div className={"mt20 self-employed__subtitle"} style={{ width: isMobile ? "" : "1312px" }}>
                        We have already collected over £15 million for our customers!
                            </div>
                        </div>
                        <div>
                            <WhatCustomersSayingNew/>
                        </div>
                        <Row style={{ alignItems: "baseline", justifyContent: "center" }}>
                            <div className={"mt20"}>
                                <ButtonFooterMarketing/>
                            </div>
                        </Row>
                    </div>
                </div> : <div >
                    <div>
                        <HeaderNew/>
                    </div>
                    <div>
                        <MainNew setShowButtonFooter={setShowButtonFooter}/>
                    </div>
                    <div className={"horizontal-center"} id="marketingStartButton" style={{ padding: "64px 100px", overflow: "hidden" }}>
                        <div id="marketingStartButton">
                            <div className={"mt40"}>
                                <div>
                                    <video controls autoPlay muted loop playsInline className={"uk-marketing-video mb20"} style={{ width: maxWidth }}>
                                        <source src={"https://taxzap-videos.s3.eu-west-1.amazonaws.com/taxzap_marketing_video.mp4"} type="video/mp4"/>
                            Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={"how-it-works"} style={{ scrollMarginTop: "50px" }}>
                        <HowItWorks/>
                    </div>
                    <div>
                        <WeHelped/>
                    </div>
                    <div>
                        <WhatCustomersSayingNew/>
                    </div>
                </div>
            }
        </>
    );
};

export default Marketing;
