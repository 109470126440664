import { CSSProperties, Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useState } from "react";

import { AutoComplete, Typography } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { AppContext } from "App";
import { FaRegEdit } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { PiMapPinFill } from "react-icons/pi";
import { emboldenOverlappingText } from "utils/embolden-overlapping-text";
import { generateSessionToken, getPlaceDetails, placesAutocomplete } from "utils/google-autocomplete";

import { EnableValidationT } from "./SignUpFormNew";
import { useLocalStorage } from "../../../hooks/helpers/useLocalStorage";
import { GoogleSessionToken } from "../../../types/client";

export type FormDataState = {
    [key: string]: any;
};

type AutoCompleteProps = {
    label: string;
    placeholder?: string;
    error?: string;
    index?: number;
    size?: SizeType;
    value?: string;
    dataTestId?: string;
    style?: CSSProperties;
    formData: FormDataState;
    onChange: (_newValue: string, _object?: {[key: string]: string}) => void;
    setEnableValidation?: Dispatch<SetStateAction<EnableValidationT>>
    setSimpleAddressMode: Dispatch<SetStateAction<boolean>>;
    addressChange: (
        // eslint-disable-next-line no-unused-vars
        address: {
            [name: string]: string
        },
        // eslint-disable-next-line no-unused-vars
        inputValue?: string) => void;
    handleKeyDown?: (_event: any) => void;
}

type Option = {
    value: string;
    label: string;
}

type Address = {
    address1: string;
    address2: string;
    city: string;
    country: string;
    postcode: string;
}

interface AutoCompleteItemProps extends Option {
    inputValue: string;
    index: number;
}

const AutoCompleteItem = ({ inputValue, value, label, index }: AutoCompleteItemProps) => {
    if (index === 0) {
        return ({
            value,
            label: (<span style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <FiPlus style={{ marginRight: "2px", minWidth: "20px" }} size={20} fill='grey'/>
                <span>{label}</span>
            </span>),
        });
    }
    return ({
        value,
        label: (<span className='fs13' style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <PiMapPinFill style={{ marginRight: "2px", minWidth: "20px" }} size={20} fill='grey'/>
            {emboldenOverlappingText(label, inputValue)}
        </span>),
    });
};

const UkAutoCompleteAddressInput: FC<AutoCompleteProps> = ({
    size,
    value,
    dataTestId,
    style,
    label,
    error,
    index,
    placeholder,
    formData,
    onChange,
    setSimpleAddressMode,
    setEnableValidation,
    addressChange,
    handleKeyDown,
}) => {
    const initialData = {
        address1: formData["address1"],
        address2: formData["address2"],
        city: formData["city"],
        country: formData["country"],
        postcode: formData["postcode"],
    };

    const { isMobile } = useContext(AppContext);

    const [focus, setFocus] = useState(false);
    const [options, setOptions] = useState<Option[]>([]);
    const [address, setAddress] = useState<Address | undefined>(initialData);
    const [storedTokenData, setTokenData] = useLocalStorage<GoogleSessionToken| null>("sessionTokenData", null);
    const isOccupied = focus || value;

    const labelClass = useMemo(() => {
        const baseClass = "label";
        const isLongLabel = label.length > 40;
        const isVeryLongLabel = label.length > 65;

        let computedLabelClass = isOccupied
            ? `${baseClass} as-label`
            : `${baseClass} as-placeholder`;

        if (isOccupied && error) {
            computedLabelClass += " text-error";
        }

        if (isMobile) {
            if (!isOccupied && (isLongLabel || isVeryLongLabel)) {
                computedLabelClass += " two-line-placeholder";
            }

            if (isOccupied && (isLongLabel || isVeryLongLabel)) {
                computedLabelClass += " two-line-label";
            }
        } else {
            if (!isOccupied && isVeryLongLabel) {
                computedLabelClass += " two-line-placeholder";
            }

            if (isOccupied && isVeryLongLabel) {
                computedLabelClass += " two-line-label";
            }
        }

        return computedLabelClass;
    }, [isOccupied, error, label.length, isMobile]);

    const handleBlur = () => {
        setFocus(false);
    };

    const handleFocus = () => {
        setFocus(true);
    };

    const manualToggleOption = {
        value: "manual",
        label: "Enter address manually",
    };

    const handleSelect = (optionValue: string) => {
        if (optionValue === manualToggleOption.value) {
            setEnableValidation && setEnableValidation((prev: EnableValidationT) => ({
                ...prev,
                "address1": true,
                "address2": true,
                "city": true,
                "country": true,
                "postcode": true,
            }));
            setSimpleAddressMode(false);
            return;
        }
        onChange(options.find(option => option.value === optionValue)?.label || "");
        getPlaceDetails(optionValue, setAddress, true);
        setTokenData(null);
    };

    const handleConfirm = () => {
        if (!address) return;
        const addressValues = {
            "address1": address.address1?.trim(),
            "address2": address.address2?.trim(),
            "city": address.city?.trim(),
            "country": address.country?.trim(),
            "postcode": address.postcode?.trim(),
        };
        addressChange(addressValues, value);
        if (!address.address1 || !address.address2 || !address.city || !address.country || !address.postcode) {
            setEnableValidation && setEnableValidation((prev: EnableValidationT) => ({
                ...prev,
                "address1": true,
                "address2": true,
                "city": true,
                "country": true,
                "postcode": true,
            }));
            setSimpleAddressMode(false);
        }
    };

    const resetAddressObject = {
        "address1": "",
        "address2": "",
        "city": "",
        "country": "",
        "postcode": "",
    };

    useEffect(() => {
        if (address?.address1 || address?.address2 || address?.city || address?.country || address?.postcode) {
            handleConfirm();
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [address]);

    const getSessionToken = async () => {
        let sessionToken;
        const currentTime = new Date().getTime();
        if (storedTokenData && (currentTime - storedTokenData.timestamp) < 3 * 60 * 1000) {
            sessionToken = storedTokenData.token;
        } else {
            sessionToken = generateSessionToken();
            setTokenData({ token: sessionToken, timestamp: currentTime });
        }
        return sessionToken;
    };

    const handleChange = async (newValue?: string) => {
        if (newValue === manualToggleOption.value) return;
        onChange(newValue || "", resetAddressObject);
        if (!newValue) {
            setOptions([]);
            return;
        }
        const autoComplete = await placesAutocomplete(newValue, await getSessionToken());
        setOptions(autoComplete || []);
    };

    return (
        <div
            className="float-label"
            onBlur={handleBlur}
            onFocus={handleFocus}
            style={style}
        >
            <AutoComplete
                data-testid={dataTestId}
                placeholder={isOccupied ? placeholder : ""}
                className="input-field"
                size={size}
                value={value}
                onSearch={handleChange}
                onSelect={handleSelect}
                options={[manualToggleOption, ...options].map((option, index) => AutoCompleteItem({ inputValue: value || "", index, ...option }))}
                autoFocus={index === 0}
                onKeyDown={handleKeyDown}
            />
            <label className={labelClass}>{label}</label>
            {(address?.address1 || address?.address2 || address?.city || address?.country || address?.postcode) && (
                <div className='column-left'>
                    <span style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 10,
                        marginBottom: 10,
                        justifyContent: "space-between",
                        width: "100%",
                    }}>
                        <span style={{ display: "flex", alignItems: "center", gap: 3 }}>
                            <PiMapPinFill size={20} fill={"#2ebb49"}/>
                            <Typography.Title className='primary-color' style={{ margin: 0 }} level={5}>Address</Typography.Title>
                        </span>
                        <FaRegEdit style={{ marginLeft: "auto", padding: "10px", cursor: "pointer" }} size={24} fill={"#1353fa"}
                            onClick={() => setSimpleAddressMode(false)}/>
                    </span>
                    <Typography.Text className="column-left fs16" style={{ color: "gray" }}>
                        <span>
                            {address?.address1}
                        </span>
                        <span>
                            {address?.address2}
                        </span>
                        <span>
                            {address?.city}
                        </span>
                        <span>
                            {address?.country}
                        </span>
                        <span>
                            {address?.postcode}
                        </span>
                    </Typography.Text>
                </div>)
            }
        </div>
    );
};

export default UkAutoCompleteAddressInput;
