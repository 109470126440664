import { FC, useCallback, useEffect, useState, useMemo, useContext } from "react";

import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { AppContext } from "App";
import deleteIcon from "assets/images/new-questionnaire/employment/employment-delete.svg";
import editPencil from "assets/images/new-questionnaire/employment/employment-edit-pencil.svg";
import QuestionnaireButtonFooterV2 from "components/basic/Footer/QuestionnaireButtonFooterV2";
import ButtonLoading from "components/basic/Spinner/ButtonLoading";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { selectArrayQuestionState, setInnerArrayItemAnswers, setInnerArrayItemQuestions, setIsEditingInnerArrayItem } from "redux/reducers/arrayQuestion";
import { selectCurrentNavigation, setCurrentInnerArrayQuestionIndex, setStepLoading } from "redux/reducers/navigation";
import { IUser } from "types/auth";
import { ArrayAnswersType } from "types/questionnaire";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { findAndSetInnerArrayNext } from "utils/navigation";
import { getNameFromQuestionAnswer, getOrdinalSuffix } from "utils/new-questionnaire";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { Question } from "../../constants/questions";
import { QuestionType } from "../../enums/enum";
import AmountQuestion from "../AmountQuestion/AmountQuestion";
import DateQuestion from "../DateQuestion/DateQuestion";
import PercentageQuestion from "../PercentageQuestion/PercentageQuestion";
import SelectOneOptionQuestion from "../SelectOneOptionQuestion/SelectOneOptionQuestion";
import SelectOneOptionQuestionV2 from "../SelectOneOptionQuestionV2/SelectOneOptionQuestionV2";
import DeleteModal from "../Shared/DeleteModal";
import TextQuestion from "../TextQuestion/TextQuestion";

type ArrayQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: any) => void;
    initialValue?: any;
    user: IUser;
    setHideProfileContinue?: (_value: boolean) => void;
    setContinueDisabled: (_value: boolean) => void;
    isProfileQuestion?: boolean;
};

const ArrayQuestion: FC<ArrayQuestionProps> = (
    {
        data,
        onAnswer,
        initialValue = [],
        user,
        setHideProfileContinue,
        setContinueDisabled,
        isProfileQuestion = false,
    }) => {
    const dispatch = useAppDispatch();

    const { isMobile } = useContext(AppContext);

    const isKeyboardOpen = useDetectKeyboardOpen();

    const [subTitle, setSubTitle] = useState("");
    const [editingIndex, setEditingIndex] = useState<number>(0);
    const [currentQuestion, setCurrentQuestion] = useState<Question>();
    const [currentItemName, setCurrentItemName] = useState<string>("");
    const [arrayItemsNames, setItemsNames] = useState<string[]>([]);
    const [deletionIndex, setDeletionIndex] = useState<number | null>(null);

    const { currentInnerArrayQuestionIndex, stepLoading } = useAppSelector(selectCurrentNavigation);
    const { isEditingInnerArrayItem, innerArrayAnswers } = useAppSelector(selectArrayQuestionState);

    const currentQuestionAnswered = useMemo(() => {
        if (!currentQuestion) return false;
        const { question, answerRequired, isItemName } = currentQuestion;
        if (isItemName) {
            const isDuplicate = arrayItemsNames
                .filter(name => name !== currentItemName)
                .some(name => name.trim().toLowerCase() === currentItemName.trim().toLowerCase());
            if (isDuplicate) return false;
        }
        if (answerRequired) {
            const answer = innerArrayAnswers.find(q => q.question === question)?.answer;
            return !!answer;
        }
        if (!answerRequired) return true;
    }, [currentQuestion, innerArrayAnswers, arrayItemsNames, currentItemName]);

    const {
        isOpened: isDeletionModalOpened,
        open: handleDeletionModalOpen,
        close: handleDeletionModalClose,
    } = useModalOpener();

    const handleAddButtonClick = useCallback(async () => {
        setEditingIndex(initialValue.length ? initialValue.length : 0);
        setCurrentItemName("");
        dispatch(setIsEditingInnerArrayItem(true));
        dispatch(setCurrentInnerArrayQuestionIndex(0));
    }, [initialValue.length, dispatch]);

    const handleEditButtonClick = useCallback((index: number) => {
        const itemToEdit = initialValue[index];
        dispatch(setInnerArrayItemAnswers(itemToEdit.answers));
        setEditingIndex(index);
        setCurrentItemName(arrayItemsNames[index] || itemToEdit.item);
        dispatch(setIsEditingInnerArrayItem(true));
    }, [arrayItemsNames, dispatch, initialValue]);

    const updateAnswers = useCallback(
        (editIndex: number, arrayAns: ArrayAnswersType["answers"]) => {
            const isEdit = initialValue.length > 0 && editIndex < initialValue.length;

            const itemName = isEdit
                ? initialValue[editIndex]?.item || `Item ${editIndex + 1}`
                : `${data.itemName} ${initialValue.length + 1}`;

            const existingItem = isEdit ? initialValue[editIndex] : undefined;

            const updatedItem = {
                item: itemName,
                answers: arrayAns,
                isCompleted: existingItem?.isCompleted ?? false,
            };

            const updatedArray = isEdit
                ? initialValue.map((item: ArrayAnswersType, index: number) =>
                    (index === editIndex ? updatedItem : item)
                )
                : [...initialValue, updatedItem];

            setItemsNames(prevNames => {
                const newNames = [...prevNames];
                const name = getNameFromQuestionAnswer(
                    arrayAns,
                    data.questions,
                    isEdit ? editIndex : initialValue.length,
                    data.itemName
                );
                if (isEdit) {
                    newNames[editIndex] = name;
                } else {
                    newNames.push(name);
                }
                return newNames;
            });

            onAnswer(data.question, updatedArray);
        },
        [data, initialValue, onAnswer]
    );

    const handleArrayQuestionAnswer = useCallback((question: string, answer: any, questionType?: string) => {
        const updatedAnswers = [...innerArrayAnswers];

        const existingIndex = updatedAnswers.findIndex(q => q.question === question);
        const updatedAnswer = { question, answer, type: questionType || currentQuestion?.type || "", id: currentQuestion?.id || "" };

        if (existingIndex > -1) {
            updatedAnswers[existingIndex] = updatedAnswer;
        } else {
            updatedAnswers.push(updatedAnswer);
        }

        if (currentQuestion?.isItemName) {
            setCurrentItemName(answer);
        }

        if (currentQuestion?.id) {
            updateAnswers(editingIndex, updatedAnswers);
        }

        dispatch(setInnerArrayItemAnswers(updatedAnswers));
    }, [innerArrayAnswers, currentQuestion, dispatch, updateAnswers, editingIndex]);

    const handleArrayQuestionsContinue = useCallback(async (answers?: { question: string, answer: string, type: string }[]) => {
        dispatch(setStepLoading(true));
        const timerValue = 800;

        const timer = setTimeout(async () => {
            if (data.questions) {
                updateAnswers(editingIndex, innerArrayAnswers);

                const { isArrayEnd } = findAndSetInnerArrayNext(
                    currentInnerArrayQuestionIndex,
                    data.questions,
                    answers ? [...answers, ...innerArrayAnswers] : innerArrayAnswers,
                    handleArrayQuestionAnswer,
                    dispatch
                );

                if (isArrayEnd) {
                    const updatedArray = initialValue.map((item: ArrayAnswersType, index: number) =>
                        (index === editingIndex
                            ? { ...item, isCompleted: true }
                            : item)
                    );

                    onAnswer(data.question, updatedArray);
                }
            }

            dispatch(setStepLoading(false));
        }, timerValue);

        return () => clearTimeout(timer);
    }, [dispatch, data.questions, data.question, updateAnswers, editingIndex, innerArrayAnswers, currentInnerArrayQuestionIndex, handleArrayQuestionAnswer, initialValue, onAnswer]);

    const handleDeleteButtonClick = useCallback((index: number) => {
        setDeletionIndex(index);
        handleDeletionModalOpen();
    }, [handleDeletionModalOpen]);

    const handleDeletionCancel = useCallback(() => {
        setDeletionIndex(null);
        handleDeletionModalClose();
    }, [handleDeletionModalClose]);

    const confirmDeleteClick = useCallback(() => {
        if (deletionIndex !== null) {
            const updatedArray = initialValue.filter((_item: ArrayAnswersType, i: number) => i !== deletionIndex);
            const updatedNames = arrayItemsNames.filter((_item: string, i: number) => i !== deletionIndex);

            setItemsNames(updatedNames);
            onAnswer(data.question, updatedArray.length === 0 ? undefined : updatedArray);
            handleDeletionModalClose();
        }
    }, [deletionIndex, initialValue, handleDeletionModalClose, arrayItemsNames, onAnswer, data.question]);

    useEffect(() => {
        dispatch(setInnerArrayItemQuestions(data?.questions || []));
        data.questions && setCurrentQuestion(data.questions[currentInnerArrayQuestionIndex]);
    }, [currentInnerArrayQuestionIndex, data.questions, dispatch]);

    useEffect(() => {
        if (isEditingInnerArrayItem) {
            setHideProfileContinue?.(true);

            if (data.itemTitle) {
                const itemIndex = (editingIndex ?? initialValue.length) + 1;
                const formattedItemTitle = currentItemName || `${getOrdinalSuffix(itemIndex)} ${data.itemTitle}`;
                setSubTitle(formattedItemTitle);
            } else {
                setSubTitle("");
            }
        } else {
            setHideProfileContinue?.(false);
            setSubTitle("");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentItemName, isEditingInnerArrayItem, isProfileQuestion]);

    useEffect(() => {
        if (initialValue?.length > 0 && arrayItemsNames.length === 0) {
            const initialNames = initialValue.map((item: ArrayAnswersType, index: number) =>
                getNameFromQuestionAnswer(
                    item.answers,
                    data.questions,
                    index,
                    data.itemName
                )
            );

            if (initialNames !== arrayItemsNames) {
                setItemsNames(initialNames);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useEffect(() => {
        if (initialValue) {
            const arraysIncompleteItems = initialValue.some((item: ArrayAnswersType) => !item.isCompleted);
            setContinueDisabled(arraysIncompleteItems);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditingInnerArrayItem]);

    useEffect(() => {
        return () => {
            setContinueDisabled(false);
            dispatch(setIsEditingInnerArrayItem(false));
            dispatch(setCurrentInnerArrayQuestionIndex(0));
            dispatch(setInnerArrayItemAnswers([]));
            dispatch(setInnerArrayItemQuestions([]));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={"new-questionnaire__array-v2 mt10"}>
            {subTitle && (
                <div className={`new-questionnaire__profile-name mt0 ${isMobile ? "mb16" : ""}`}>{subTitle}</div>
            )}
            {!isEditingInnerArrayItem && (<>
                <div className="new-questionnaire__question">
                    {data.questionTitle && (
                        <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
                    )}
                    <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                        {data.question}
                    </div>
                </div>
                <div
                    className={"new-questionnaire__array-v2-add-button mt12"}
                    onClick={handleAddButtonClick}
                    role="button"
                >
                    {data.addButtonText}
                </div>
                <div className="new-questionnaire__array-v2-items">
                    {initialValue?.map((item: ArrayAnswersType, index: number) => {
                        const isIncomplete = !item.isCompleted;
                        return (
                            <div className="new-questionnaire__array-v2-item-wrapper" key={index}>
                                <div
                                    key={index}
                                    className={"new-questionnaire__array-v2-item"}
                                >
                                    {isIncomplete ? (
                                        <Tooltip title={`${data.itemName} is incomplete`}>
                                            <ExclamationCircleOutlined className="mr10" style={{ color: "orange" }} />
                                        </Tooltip>
                                    ) : (
                                        <CheckCircleOutlined className="primary-color mr10" />
                                    )}
                                    {arrayItemsNames[index]}
                                    <span style={{ padding: "10px" }} onClick={() => handleEditButtonClick(index)} className="pointer">
                                        <img width={24} src={editPencil} alt="edit" style={{ display: "block" }}/>
                                    </span>
                                </div>
                                <div style={{ padding: "10px" }} onClick={() => handleDeleteButtonClick(index)} className="pointer">
                                    <img width={24} src={deleteIcon} alt="delete" style={{ display: "block" }}/>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>)}
            {isEditingInnerArrayItem && currentQuestion && (
                <div>
                    {currentQuestion.type === QuestionType.dateQuestion && (
                        <DateQuestion
                            data={currentQuestion}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={innerArrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                        />
                    )}
                    {currentQuestion.type === QuestionType.amountQuestion && (
                        <AmountQuestion
                            data={currentQuestion}
                            handlePrev={() => {}}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={innerArrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                            arrayAnswers={innerArrayAnswers}
                        />
                    )}
                    {currentQuestion.type === QuestionType.percentageQuestion && (
                        <PercentageQuestion
                            data={currentQuestion}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={innerArrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                        />
                    )}
                    {currentQuestion.type === QuestionType.textQuestion && (
                        <TextQuestion
                            data={currentQuestion}
                            onAnswer={handleArrayQuestionAnswer}
                            arrayItemsNames={arrayItemsNames}
                            initialValue={innerArrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                        />
                    )}
                    {currentQuestion.type === QuestionType.selectOneOptionQuestion && (
                        <SelectOneOptionQuestion
                            user={user}
                            data={currentQuestion}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={innerArrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                            handleContinue={handleArrayQuestionsContinue}
                        />
                    )}
                    {currentQuestion.type === QuestionType.selectOneOptionQuestionV2 && (
                        <SelectOneOptionQuestionV2
                            data={currentQuestion}
                            handlePrev={() => {}}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={innerArrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                            handleContinue={handleArrayQuestionsContinue}
                        />
                    )}
                    <div className={`${isKeyboardOpen ? "" : "mt120"}`}>
                        <QuestionnaireButtonFooterV2>
                            <div role="button"
                                className={`new-questionnaire__form-continue ${currentQuestionAnswered && !stepLoading ? "" : "disabled"}`}
                                onClick={() => {
                                    if (currentQuestionAnswered && !stepLoading) {
                                        handleArrayQuestionsContinue();
                                    }
                                }}
                            >
                                {stepLoading ? <ButtonLoading/>
                                    : <span>{"Continue"}</span>}
                            </div>
                        </QuestionnaireButtonFooterV2>
                    </div>
                </div>
            )}
            {isDeletionModalOpened && (
                <DeleteModal
                    isOpen={isDeletionModalOpened}
                    onCancel={handleDeletionCancel}
                    onConfirm={confirmDeleteClick}
                    closeable
                    data={{
                        title: `Deleting this ${data.itemName}?`,
                        text: `Are you sure you want to delete this ${data.itemName}? This action cannot be undone.`,
                        confirmButtonText: `Yes, delete this ${data.itemName}`,
                    }}
                />
            )}
        </div>
    );
};

export default ArrayQuestion;
