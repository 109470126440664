import { FC, useCallback, useEffect, useState, useMemo } from "react";

import { ExclamationCircleOutlined, WarningFilled, CheckCircleOutlined } from "@ant-design/icons";
import { message as antdMessage, Tooltip } from "antd";
import deleteIcon from "assets/images/new-questionnaire/employment/employment-delete.svg";
import editPencil from "assets/images/new-questionnaire/employment/employment-edit-pencil.svg";
import questionIcon from "assets/images/new-questionnaire/question_icon.svg";
import QuestionnaireButtonFooterV2 from "components/basic/Footer/QuestionnaireButtonFooterV2";
import ButtonLoading from "components/basic/Spinner/ButtonLoading";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import usePersistentReduxListener from "hooks/helpers/usePersistentReduxListener";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { next, selectCurrentNavigation, setStepLoading } from "redux/reducers/navigation";
import store, { RootState } from "redux/store";
import { useCreateClientTaxProfileMutation, useDeleteClientTaxProfileMutation, useUpdateClientTaxProfileMutation } from "services/client";
import { IUser } from "types/auth";
import { AccountDetails } from "types/bankAccounts";
import { ICreateClientTaxProfileBody, TaxProfile } from "types/client";
import { ArrayAnswersType } from "types/questionnaire";
import { findAndSetProfileNext } from "utils/navigation";
import { checkDepends, getNameFromQuestionAnswer, getOrdinalSuffix, parseAmount } from "utils/new-questionnaire";
import { logError } from "utils/sentry";

import { setCurrentProfile, setCurrentProfileAnswers, setIsEditingProfile } from "../../../../../redux/reducers/taxProfile";
import { Question, Step } from "../../constants/questions";
import { ParentProfileType, ProfileType, PromptModalType, QuestionSubType, QuestionType } from "../../enums/enum";
import AmountQuestion from "../AmountQuestion/AmountQuestion";
import ArrayQuestion from "../ArrayQuestion/ArrayQuestion";
import CardQuestion from "../CardArrayQuestion/CardArrayQuestion";
import DateQuestion from "../DateQuestion/DateQuestion";
import DocumentParseQuestion from "../DocumentParse/DocumentParseQuestion";
import ProfileExpensesOBNew from "../Expenses/ProfileExpensesOBNew";
import ProfileExpensesSummary from "../Expenses/ProfileExpensesSummary";
import ExpensesListQuestion from "../ExpensesListQuestion/ExpensesListQuestion";
import ProfileIncomeSummary from "../Income/ProfileIncomeSummary";
import ObjectQuestion from "../ObjectQuestion/ObjectQuestion";
import PercentageQuestion from "../PercentageQuestion/PercentageQuestion";
import QuestionsList from "../QuestionsList/QuestionsList";
import SelectManyOptionQuestion from "../SelectManyOptionQuestion/SelectManyOptionQuestion";
import SelectOneIconQuestion from "../SelectOneIconQuestion/SelectOneIconQuestion";
import SelectOneOptionQuestion from "../SelectOneOptionQuestion/SelectOneOptionQuestion";
import SelectOneOptionQuestionV2 from "../SelectOneOptionQuestionV2/SelectOneOptionQuestionV2";
import SelectOneTilesQuestion from "../SelectOneTilesQuestion/SelectOneTilesQuestion";
import SelectProfileTypeQuestion from "../SelectProfileTypeQuestion/SelectProfileTypeQuestion";
import DeleteModal from "../Shared/DeleteModal";
import PromptModal from "../Shared/PromptModal";
import TextQuestion from "../TextQuestion/TextQuestion";
import UploadFileQuestion from "../UploadFileQuestion/UploadFileQuestion";

type ProfileQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: any) => void;
    initialValue?: any;
    setHideContinue: (_value: boolean) => void;
    setHideTitle: (_value: boolean) => void;
    user: IUser;
    refetchTaxProfiles?: () => void;
    currentStepData: Step;
    connectedBankAccounts: AccountDetails[];
    refetchConnectedBankAccounts?: () => void;
};

const ProfileQuestion: FC<ProfileQuestionProps> = (
    {
        data,
        onAnswer,
        initialValue = [],
        setHideContinue,
        setHideTitle,
        user,
        refetchTaxProfiles,
        currentStepData,
        connectedBankAccounts,
        refetchConnectedBankAccounts,
    }) => {
    const dispatch = useDispatch();

    const { isNewParserEnabled } = useFlags();
    const [arrayItemsNames, setItemsNames] = useState<string[]>([]);
    const [currentItemName, setCurrentItemName] = useState<string>("");
    const [continueButtonText, setContinueButtonText] = useState<string>("Continue");
    const [subTitle, setSubTitle] = useState("");
    const [editingIndex, setEditingIndex] = useState<number>(0);
    const [deletionIndex, setDeletionIndex] = useState<number | null>(null);
    const [currentQuestionData, setCurrentQuestionData] = useState<Question>();
    const [hideProfileContinue, setHideProfileContinue] = useState(false);
    const [profileContinueDisabled, setProfileContinueDisabled] = useState(false);

    const [createClientTaxProfile] = useCreateClientTaxProfileMutation();
    const [updateClientTaxProfile] = useUpdateClientTaxProfileMutation();
    const [deleteClientTaxProfile] = useDeleteClientTaxProfileMutation();

    const { currentDocument } = useAppSelector(
        (state: RootState) => ({
            currentDocument: state.document.currentDocument,
        })
    );
    const { currentProfile, isEditingProfile, currentProfileAnswers, incompleteProfiles, taxProfiles } = useAppSelector(
        (state: RootState) => ({
            currentProfile: state.taxProfile.currentProfile,
            isEditingProfile: state.taxProfile.isEditingProfile,
            currentProfileAnswers: state.taxProfile.currentProfileAnswers,
            incompleteProfiles: state.taxProfile.incompleteProfiles,
            taxProfiles: state.taxProfile.taxProfiles,
        })
    );
    const { currentCategory, currentStep, currentQuestion, currentProfileQuestion, stepLoading } = useAppSelector(selectCurrentNavigation);

    const {
        isOpened: isDeletionModalOpened,
        open: handleDeletionModalOpen,
        close: handleDeletionModalClose,
    } = useModalOpener();

    const {
        isOpened: isQuestionHelpModalOpened,
        open: handleQuestionHelpOpenModal,
        close: handleQuestionHelpCloseModal,
    } = useModalOpener();

    const promptModalData = useMemo(() => ({
        type: data.promptModal?.type || PromptModalType.info,
        title: data.promptModal?.title || "",
        text: data.promptModal?.text || "",
        image: data.promptModal?.image || "",
    }), [data.promptModal]);

    const formatAnswers = (answers: { question: string, answer: any }[]) => {
        return answers.reduce((acc, curr) => {
            acc[curr.question] = curr.answer;
            return acc;
        }, {} as Record<string, any>);
    };

    usePersistentReduxListener(state => {
        if (!state.taxProfile.isEditingProfile || !state.taxProfile.currentProfile) {
            setHideContinue(false);
            setHideTitle(false);
            setSubTitle("");
        }
    });

    const connectedBankAccountsIds = useMemo(() => {
        const filteredAccounts = connectedBankAccounts.filter(account => {
            const connectionStatus = account.connectionStatus ?? null;
            const noEnrichedTransactions = account.noEnrichedTransactions ?? null;
            return !(connectionStatus === "HISTORICAL_TRANSACTIONS_FETCHED" && noEnrichedTransactions === 0);
        }); //TODO remove this when we allow users to select bank account upon profile creation
        return filteredAccounts.map(account => account._id);
    }, [connectedBankAccounts]);

    const taxProfilesIds = useMemo(() => {
        if (!taxProfiles?.length) return [];
        return taxProfiles.map(profile => profile._id) || [];
    }, [taxProfiles]);

    const profileAnswers = useMemo(() => {
        return initialValue?.filter((answer: {item: string, itemId: string, answer: any[] }) => taxProfilesIds?.includes(answer.itemId)) || [];
    }, [initialValue, taxProfilesIds]);

    const currentQuestionAnswered = useMemo(() => {
        if (!currentQuestionData) return false;
        const { type, questions, question, answerRequired, isItemName } = currentQuestionData;
        if (type === QuestionType.questionsList) {
            const categoryAnswers = formatAnswers(currentProfileAnswers);

            const everySubQuestionAnswered = questions?.every(subQuestion => {
                if (subQuestion.depend?.length) {
                    const dependSatisfied = checkDepends(subQuestion.depend, categoryAnswers);

                    if (!dependSatisfied) {
                        // ✅ If dependencies are NOT met, consider it answered (skipped)
                        return true;
                    }

                    // ✅ If dependencies ARE met, check if it's answered
                    return categoryAnswers[subQuestion.question] !== undefined;
                }

                // ✅ If no dependencies, just check if the answer exists
                return currentProfileAnswers.some(answer => answer.question === subQuestion.question);
            });

            return everySubQuestionAnswered;
        }

        if (isItemName) {
            const isDuplicate = arrayItemsNames
                .filter(name => name !== currentProfile?.name)
                .some(
                    name => name.trim().toLowerCase() === currentItemName.trim().toLowerCase()
                );
            if (isDuplicate) return false;
        }
        if (type === QuestionType.expensesList) return true;
        if (type === QuestionType.uploadFileQuestion) return true;
        if (type === QuestionType.documentParseQuestion) return true;
        if (!question) return false;
        if (answerRequired) {
            if (type === QuestionType.objectQuestion) {
                const objectAnswers = currentProfileAnswers.filter(q => q.question === question);
                if (!objectAnswers.length) return false;
                return Object.values(objectAnswers[0].answer).some(a => a !== null);
            }
            const answer = currentProfileAnswers.find(q => q.question === question)?.answer;
            return !!answer?.length;
        }
        if (answerRequired === false) return true;
        return !!currentProfileAnswers?.find(q => q.question === question);
    }, [currentQuestionData, currentProfileAnswers, currentProfile, arrayItemsNames, currentItemName]);

    const updateAnswers = useCallback(
        (editIndex: number, arrayAns: ArrayAnswersType["answers"]) => {
            const isEdit = profileAnswers.length > 0 && editIndex < profileAnswers.length;

            const itemName = isEdit
                ? profileAnswers[editIndex]?.item || `Item ${editIndex + 1}`
                : `${data.itemName} ${profileAnswers.length + 1}`;

            const updatedItem = {
                item: itemName,
                itemId: currentProfile?._id,
                answers: arrayAns,
            };

            // Create updatedArray based on whether it's an edit or new addition
            const updatedArray = isEdit
                ? profileAnswers.map((item: ArrayAnswersType, index: number) =>
                    (index === editIndex ? updatedItem : item)
                )
                : [...profileAnswers, updatedItem];

            // Update item names
            setItemsNames(prevNames => {
                const newNames = [...prevNames];
                if (isEdit) {
                    newNames[editIndex] = getNameFromQuestionAnswer(
                        arrayAns,
                        data.questions,
                        editIndex,
                        data.itemName
                    );
                } else {
                    newNames.push(
                        getNameFromQuestionAnswer(
                            arrayAns,
                            data.questions,
                            profileAnswers.length, // Use the current length for the new item
                            data.itemName
                        )
                    );
                }
                return newNames;
            });

            // Call onAnswer with the updated array
            onAnswer(data.question, updatedArray);
        },
        [data, profileAnswers, onAnswer, currentProfile]
    );

    const handleAddButtonClick = useCallback(async () => {
        if (currentStepData.parentProfileType) {
            const createBody: ICreateClientTaxProfileBody = {
                client: user.id,
                parentType: currentStepData.parentProfileType as ParentProfileType,
                type: currentStepData.profileType ? currentStepData.profileType as ProfileType : ProfileType.none,
                document: currentDocument._id,
                bankAccounts: connectedBankAccountsIds || [],
            };
            await createClientTaxProfile(createBody)
                .unwrap()
                .then(response => {
                    dispatch(setCurrentProfileAnswers([]));
                    dispatch(setIsEditingProfile(true));
                    dispatch(setCurrentProfile(response));
                    setHideContinue(true);
                    setEditingIndex(profileAnswers.length ? profileAnswers.length : 0);
                    setCurrentItemName("");
                    refetchTaxProfiles && refetchTaxProfiles();
                })
                .catch(async (e: any) => {
                    antdMessage.error({
                        content: "Error creating tax profile. Try again later.",
                        duration: 5,
                        icon: <WarningFilled style={{ color: "#e7c223" }} />,
                    });
                    const contextInfo = {
                        message: "Failed to create profile",
                        functionName: "createClientTaxProfile",
                        clientId: user.id,
                        currentDocumentId: currentDocument?._id,
                    };
                    logError(e, contextInfo);
                });
        }
    }, [currentDocument, createClientTaxProfile, currentStepData, profileAnswers.length, setHideContinue, dispatch, setCurrentItemName, user.id, refetchTaxProfiles, connectedBankAccountsIds]);

    const handleEditButtonClick = useCallback((index: number) => {
        dispatch(setIsEditingProfile(true));
        const itemToEdit = profileAnswers[index];
        const currentProfile = taxProfiles?.find(profile => profile._id === itemToEdit.itemId);
        if (!currentProfile) {
            antdMessage.error("Failed to edit profile. Try again later.");
            return;
        }
        dispatch(setCurrentProfile(currentProfile));
        dispatch(setCurrentProfileAnswers(itemToEdit.answers));

        const incompleteProfile = incompleteProfiles.find(p => p.itemId === currentProfile._id);
        if (incompleteProfile) {
            const incompleteQuestionId = incompleteProfile.missingQuestions[0];
            const foundQuestionIndex = data?.questions?.findIndex(q => q.id === incompleteQuestionId);
            const foundCardArrayIndex = data?.questions?.findIndex(q => q.type === QuestionType.cardArrayQuestion && q.cards?.some(c => c.id === incompleteQuestionId));
            const incompleteProfileQuestionIndex =
            foundQuestionIndex !== -1 ? foundQuestionIndex
                : foundCardArrayIndex !== -1 ? foundCardArrayIndex
                    : 0;

            dispatch(next({
                nextCategory: currentCategory,
                nextStep: currentStep,
                nextQuestion: currentQuestion,
                nextProfileQuestion: incompleteProfileQuestionIndex,
            }));
        }
        setEditingIndex(index);
        setHideContinue(true);
        setCurrentItemName(arrayItemsNames[index] || itemToEdit.item);
    }, [arrayItemsNames, profileAnswers, setHideContinue, taxProfiles, dispatch, currentCategory, currentStep, currentQuestion, incompleteProfiles, data.questions]);

    const confirmDeleteClick = useCallback(async () => {
        if (deletionIndex !== null) {
            const itemToEdit = profileAnswers[deletionIndex];
            const profile = taxProfiles?.find(profile => profile._id === itemToEdit.itemId);

            if (profile) {
                await deleteClientTaxProfile({
                    client: user.id,
                    taxProfileId: profile._id,
                })
                    .unwrap()
                    .then(() => {
                        const updatedArray = profileAnswers.filter((_item: ArrayAnswersType, i: number) => i !== deletionIndex);
                        const updatedNames = arrayItemsNames.filter((_item: string, i: number) => i !== deletionIndex);

                        setItemsNames(updatedNames);
                        onAnswer(data.question, updatedArray.length === 0 ? undefined : updatedArray);
                        refetchTaxProfiles && refetchTaxProfiles();
                        dispatch(next({ nextCategory: currentCategory, nextStep: currentStep, nextQuestion: currentQuestion, nextProfileQuestion: 0 }));
                    });
            }
            handleDeletionModalClose();
        }
    }, [deletionIndex, profileAnswers, taxProfiles, handleDeletionModalClose, deleteClientTaxProfile, user.id, arrayItemsNames, onAnswer, data.question, refetchTaxProfiles, dispatch, currentCategory, currentStep, currentQuestion]);

    const handleDeleteButtonClick = useCallback((index: number) => {
        setDeletionIndex(index);
        handleDeletionModalOpen();
    }, [handleDeletionModalOpen]);

    const handleDeletionCancel = useCallback(() => {
        setDeletionIndex(null);
        handleDeletionModalClose();
    }, [handleDeletionModalClose]);

    const handleArrayQuestionAnswer = useCallback((question: string, answer: any, questionType?: string) => {
        const updatedAnswers = currentProfileAnswers.map(q => ({ ...q })); // ✅ Deep copy objects

        if (currentQuestionData?.type === QuestionType.cardArrayQuestion) {
            answer.forEach((newAnswer: { question: string; answer: any; type: string; id: string }) => {
                const index = updatedAnswers.findIndex(q => q.question === newAnswer.question);

                if (index === -1) {
                    updatedAnswers.push(newAnswer);
                } else {
                    updatedAnswers[index] = { ...updatedAnswers[index], answer: newAnswer.answer };
                    const formattedProfileAnswers = formatAnswers(updatedAnswers);
                    data.questions?.forEach((question: Question) => {
                        // If any previously answered profile questions depends on the new answer, check if the dependencies are met. If not, remove the question
                        if (question.depend && question.depend.some((depend: any) => depend.question === newAnswer.question) && !checkDepends(question.depend, formattedProfileAnswers)) {
                            const itemToRemove = updatedAnswers.findIndex(q => q.question === question.question);
                            if (itemToRemove !== -1) {
                                updatedAnswers.splice(itemToRemove, 1);
                            }
                        }
                    });
                }
            });
        } else {
            const existingIndex = updatedAnswers.findIndex(q => q.question === question);
            let id = currentQuestionData?.id;
            if (currentQuestionData?.question !== question) {
                const realRealCurrentQuestion = currentQuestionData?.questions?.find(it => it.question === question);
                id = realRealCurrentQuestion?.id;
            }
            const updatedAnswer = { question, answer, type: questionType || currentQuestionData?.type || "", id: id || "" };
            if (existingIndex > -1) {
                updatedAnswers[existingIndex] = updatedAnswer;
            } else {
                updatedAnswers.push(updatedAnswer);
            }
        }

        if (currentQuestionData?.isItemName) {
            setCurrentItemName(answer);
        }

        dispatch(setCurrentProfileAnswers(updatedAnswers));
        if (currentQuestionData?.type === QuestionType.cardArrayQuestion ||
            currentQuestionData?.type === QuestionType.arrayQuestion) {
            updateAnswers(editingIndex, updatedAnswers);
        }
        setContinueButtonText("Continue");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProfileAnswers, currentQuestionData?.type, currentQuestionData?.isItemName, currentQuestionData?.id, dispatch, updateAnswers, editingIndex]);

    const handleUpdateProfile = useCallback(async (currQuestion: Question) => {
        if (!currentProfile) {
            antdMessage.error("Failed to update profile. Try again soon.");
            return;
        }
        const answer = currentProfileAnswers?.find(q => q.question === currQuestion?.question)?.answer || "0.00";
        const updateBody: any = {
            taxProfileId: currentProfile._id,
            client: currentProfile.client,
        };

        // If the current question is an item name, only update the name
        if (currQuestion.isItemName && currentItemName) {
            updateBody.name = currentItemName;
        }

        // If the question is an amountQuestion & confirmedTotalGross, update the revenue object
        if (currQuestion.type === QuestionType.amountQuestion && currQuestion.subtype === QuestionSubType.confirmedTotalGross) {
            updateBody.revenue = {
                ...currentProfile.revenue,
                confirmedTotalGross: parseAmount(answer),
            };
        }

        // If the question is an amountQuestion & taxAlreadyPaidOnRevenue, update the revenue object
        if (currQuestion.type === QuestionType.amountQuestion && currQuestion.subtype === QuestionSubType.taxAlreadyPaidOnRevenue) {
            updateBody.revenue = {
                ...currentProfile.revenue,
                taxAlreadyPaidOnRevenue: parseAmount(answer),
            };
        }

        const updatedProfile = await updateClientTaxProfile(updateBody).unwrap()
            .then()
            .catch(error => {
                logError(error);
                antdMessage.error("Failed to update profile. Try again soon.");
            });
        dispatch(setCurrentProfile(updatedProfile));
    }, [currentProfileAnswers, currentProfile, updateClientTaxProfile, currentItemName, dispatch]);

    const handleArrayQuestionsContinueV2 = useCallback(async () => {
        if (currentQuestionData?.type !== QuestionType.cardArrayQuestion) {
            updateAnswers(editingIndex, currentProfileAnswers);
        }

        // ✅ Handle profile updates (BEFORE navigation)
        const currentQ = data?.questions?.[currentProfileQuestion];
        if (currentQ?.shouldUpdateProfile && (currentQ.type === QuestionType.amountQuestion || currentQ.isItemName)) {
            await handleUpdateProfile(currentQ);
        }

        dispatch(setStepLoading(true));

        const currQuestion = data.questions?.[currentProfileQuestion];
        const timerValue = currQuestion?.type === QuestionType.cardArrayQuestion ? 1 : 800;

        const timer = setTimeout(async () => {
            findAndSetProfileNext(store.getState(), dispatch);
            dispatch(dispatch(setStepLoading(false)));
        }, timerValue);

        return () => clearTimeout(timer);
    }, [updateAnswers, editingIndex, currentProfileAnswers, data?.questions, currentProfileQuestion, handleUpdateProfile, dispatch, currentQuestionData?.type]);

    useEffect(() => {
        if (data?.questions?.[currentProfileQuestion]) {
            data.questions && setCurrentQuestionData(data?.questions?.[currentProfileQuestion]);
        }
    }, [currentProfileQuestion, data, currentProfileAnswers]);

    useEffect(() => {
        if (profileAnswers?.length > 0) {
            const updatedItemNames = profileAnswers.map((item: ArrayAnswersType, index: number) =>
                getNameFromQuestionAnswer(item.answers, data.questions, index, data.itemName)
            );
            setItemsNames(updatedItemNames);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileAnswers]);

    useEffect(() => {
        if (isEditingProfile && currentProfile) {
            let index = profileAnswers.length + 1;

            const editingItem = profileAnswers?.find((item: ArrayAnswersType) => item.itemId === currentProfile._id);
            if (editingItem) {
                index = profileAnswers.findIndex((item: ArrayAnswersType) => item.itemId === currentProfile._id);
                setEditingIndex(index);
                dispatch(setCurrentProfileAnswers(editingItem.answers));
            }
            const formattedItemTitle = currentProfile.name || `${getOrdinalSuffix(index)} ${data.itemTitle}`;
            setSubTitle(formattedItemTitle);
            setHideTitle(true);
            setHideContinue(true);
        } else {
            setSubTitle("");
            setHideTitle(false);
            setHideContinue(false);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProfile, isEditingProfile]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (refetchConnectedBankAccounts) {
                refetchConnectedBankAccounts();
            }
            if (refetchTaxProfiles) {
                refetchTaxProfiles();
            }
        }, 300);

        return () => clearTimeout(timer);
    }, [refetchConnectedBankAccounts, refetchTaxProfiles]);

    return (
        <div className={"new-questionnaire__array-v2 mt10"}>
            {subTitle && (
                <div className={"new-questionnaire__profile-name mt0"}>{subTitle}</div>
            )}
            {!isEditingProfile && (
                <>
                    <div className="new-questionnaire__array-v2-question-text">
                        <span>{data.question}</span>
                        {(data.promptModal?.showModalOpener) && (
                            <span className="new-questionnaire__question-icon" onClick={handleQuestionHelpOpenModal}>
                                <img src={questionIcon} width={20} alt="help"/>
                            </span>
                        )}
                    </div>
                    {data.infoText && (
                        <div className="new-questionnaire__array-v2-info-text">
                            {data.infoText}
                        </div>
                    )}
                    <div
                        className={"new-questionnaire__array-v2-add-button mt12"}
                        onClick={handleAddButtonClick}
                        role="button"
                    >
                        {data.addButtonText}
                    </div>
                    <div className="new-questionnaire__array-v2-items">
                        {profileAnswers?.map((_item: ArrayAnswersType, index: number) => {
                            const profileItemId = _item.itemId;
                            const isIncomplete = incompleteProfiles.some(p => p.itemId === profileItemId);

                            return (
                                <div className="new-questionnaire__array-v2-item-wrapper" key={index}>
                                    <div className={"new-questionnaire__array-v2-item pointer"} onClick={() => handleEditButtonClick(index)}>
                                        <div>
                                            {isIncomplete ? (
                                                <Tooltip title="Profile is incomplete">
                                                    <ExclamationCircleOutlined className="mr10" style={{ color: "orange" }} />
                                                </Tooltip>
                                            ) : (
                                                <CheckCircleOutlined className="primary-color mr10" />
                                            )}
                                            {arrayItemsNames[index]}</div>

                                        {/* Show red warning if incomplete, green check if complete */}

                                        <span className="p10">
                                            <img width={24} src={editPencil} alt="edit" className="dblock"/>
                                        </span>
                                    </div>
                                    <div onClick={() => handleDeleteButtonClick(index)} className="pointer p10">
                                        <img width={24} src={deleteIcon} alt="delete" className="dblock"/>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}

            {isEditingProfile && currentProfile && currentQuestionData && (
                <div className="w100">
                    {currentQuestionData.type === QuestionType.dateQuestion && (
                        <DateQuestion
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                        />
                    )}
                    {currentQuestionData.type === QuestionType.amountQuestion && (
                        <AmountQuestion
                            data={currentQuestionData}
                            handlePrev={() => {}}
                            onAnswer={handleArrayQuestionAnswer}
                            setContinueButtonText={setContinueButtonText}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                            arrayAnswers={currentProfileAnswers}
                            taxProfile={currentProfile}
                        />
                    )}
                    {currentQuestionData.type === QuestionType.percentageQuestion && (
                        <PercentageQuestion
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                        />
                    )}
                    {currentQuestionData.type === QuestionType.textQuestion && (
                        <TextQuestion
                            data={currentQuestionData}
                            arrayItemsNames={arrayItemsNames}
                            taxProfile={currentProfile}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                        />
                    )}
                    {currentQuestionData.type === QuestionType.selectOneOptionQuestion && (
                        <SelectOneOptionQuestion
                            user={user}
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                            handleContinue={handleArrayQuestionsContinueV2}
                        />
                    )}
                    {(currentQuestionData.type === QuestionType.selectProfileTypeQuestion && currentProfile) && (
                        <SelectProfileTypeQuestion
                            user={user}
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            handleContinue={handleArrayQuestionsContinueV2}
                            arrayAnswers={currentProfileAnswers}
                            taxProfile={currentProfile}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                        />
                    )}
                    {currentQuestionData.type === QuestionType.selectOneOptionQuestionV2 && (
                        <SelectOneOptionQuestionV2
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                            handlePrev={() => {}}
                            arrayAnswers={currentProfileAnswers}
                            handleContinue={handleArrayQuestionsContinueV2}
                        />
                    )}
                    {currentQuestionData.type === QuestionType.selectOneIconQuestion && (
                        <SelectOneIconQuestion
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                            handlePrev={() => {}}
                            setHideContinue={setHideProfileContinue}
                            handleContinue={handleArrayQuestionsContinueV2}
                        />
                    )}
                    {currentQuestionData.type === QuestionType.selectManyQuestion && (
                        <SelectManyOptionQuestion
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                            categoryAnswers={formatAnswers(currentProfileAnswers)}
                            handlePrev={() => {}}
                            setContinueButtonText={setContinueButtonText}
                        />
                    )}
                    {currentQuestionData.type === QuestionType.selectOneTilesQuestion && (
                        <SelectOneTilesQuestion
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                            categoryAnswers={formatAnswers(currentProfileAnswers)}
                            handlePrev={() => {}}
                        />
                    )}
                    {currentQuestionData.type === QuestionType.objectQuestion && (
                        <ObjectQuestion
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            handlePrev={() => {}}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                            categoryAnswers={formatAnswers(currentProfileAnswers)}
                        />
                    )}
                    {currentQuestionData.type === QuestionType.uploadFileQuestion && (
                        <UploadFileQuestion
                            user={user}
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                            refetchTaxProfiles={refetchTaxProfiles}
                            currentStepData={currentStepData}
                            categoryAnswers={currentProfileAnswers}
                            handlePrev={() => {}}
                            currentProfile={currentProfile}
                        />
                    )}
                    {currentQuestionData.type === QuestionType.documentParseQuestion && isNewParserEnabled && (
                        <DocumentParseQuestion
                            user={user}
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                            arrayAnswers={currentProfileAnswers}
                            currentProfile={currentProfile}
                            handleContinue={handleArrayQuestionsContinueV2}
                        />
                    )}
                    {currentQuestionData.type === QuestionType.documentParseQuestion && !isNewParserEnabled && (
                        <UploadFileQuestion
                            user={user}
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                            refetchTaxProfiles={refetchTaxProfiles}
                            currentStepData={currentStepData}
                            categoryAnswers={currentProfileAnswers}
                            handlePrev={() => {}}
                            currentProfile={currentProfile}
                        />
                    )}
                    {currentQuestionData.type === QuestionType.cardArrayQuestion && (
                        <CardQuestion
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                            handleContinue={handleArrayQuestionsContinueV2}
                            arrayAnswers={currentProfileAnswers}
                            handlePrev={() => {}}
                            insertItemName={!!data.itemNameFromQuestionAnswer}
                            itemName={subTitle}
                        />
                    )}
                    {currentQuestionData.type === QuestionType.arrayQuestion && (
                        <ArrayQuestion
                            user={user}
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={currentProfileAnswers.find(q => q.question === currentQuestionData.question)?.answer}
                            setHideProfileContinue={setHideProfileContinue}
                            isProfileQuestion={true}
                            setContinueDisabled={setProfileContinueDisabled}
                        />
                    )}

                    {currentQuestionData.type === QuestionType.profileIncomeSummary && currentProfile && <>
                        <ProfileIncomeSummary
                            user={user}
                            currentStepData={currentStepData}
                            data={currentQuestionData}
                            taxProfileType={currentProfile.type as ProfileType}
                            handleContinue={handleArrayQuestionsContinueV2}
                            handlePrev={() => {}}
                            taxProfile={currentProfile as TaxProfile}
                            connectedBankAccounts={connectedBankAccounts}
                        />
                    </>}

                    {currentQuestionData.type === QuestionType.profileExpensesNew && currentProfile && <>
                        <ProfileExpensesOBNew
                            user={user}
                            data={currentQuestionData}
                            currentStepData={currentStepData}
                            taxProfileType={currentProfile.type as ProfileType}
                            handleContinue={handleArrayQuestionsContinueV2}
                            taxProfile={currentProfile as TaxProfile}
                            connectedBankAccounts={connectedBankAccounts}
                        />
                    </>}

                    {currentQuestionData.type === QuestionType.profileExpensesSummary && currentProfile && <>
                        <ProfileExpensesSummary
                            user={user}
                            currentStepData={currentStepData}
                            data={currentQuestionData}
                            taxProfileType={currentProfile.type as ProfileType}
                            taxParentProfileType={currentProfile.parentType}
                            handleContinue={handleArrayQuestionsContinueV2}
                            handlePrev={() => {}}
                            taxProfile={currentProfile as TaxProfile}
                            connectedBankAccounts={connectedBankAccounts}
                        />
                    </>}

                    {currentQuestionData.type === QuestionType.questionsList && (
                        <QuestionsList
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            answers={currentProfileAnswers}
                            categoryAnswers={formatAnswers(currentProfileAnswers)}
                        />
                    )}

                    {currentQuestionData.type === QuestionType.expensesList && (
                        <ExpensesListQuestion
                            data={currentQuestionData}
                            onAnswer={handleArrayQuestionAnswer}
                            arrayAnswers={currentProfileAnswers}
                            setHideProfileContinue={setHideProfileContinue}
                        />
                    )}

                    {(
                        currentQuestionData.type !== QuestionType.cardArrayQuestion &&
                        currentQuestionData.type !== QuestionType.profileExpensesNew &&
                        currentQuestionData.type !== QuestionType.profileExpensesSummary &&
                        currentQuestionData.type !== QuestionType.profileIncomeSummary &&
                        currentQuestionData.type !== QuestionType.selectProfileTypeQuestion &&
                        currentQuestionData.type !== QuestionType.documentParseQuestion &&
                        !hideProfileContinue) && <>
                        <div className={"mt120"}>
                            <QuestionnaireButtonFooterV2>
                                <div
                                    className={`new-questionnaire__form-continue ${currentQuestionAnswered && !stepLoading && !profileContinueDisabled ? "" : "disabled"} mt25`}
                                    role="button"
                                    onClick={(e: any) => {
                                        if (!currentQuestionAnswered || stepLoading || profileContinueDisabled) {
                                            e.preventDefault();
                                            return;
                                        }
                                        setContinueButtonText("Continue");
                                        handleArrayQuestionsContinueV2();
                                    }}
                                >
                                    {stepLoading ? <ButtonLoading/>
                                        : <span>{continueButtonText}</span>}
                                </div>
                            </QuestionnaireButtonFooterV2>
                        </div>
                    </>}
                </div>
            )}
            {isDeletionModalOpened && (
                <DeleteModal
                    isOpen={isDeletionModalOpened}
                    onCancel={handleDeletionCancel}
                    onConfirm={confirmDeleteClick}
                    closeable
                    data={{
                        title: `Deleting this ${data.itemName}?`,
                        text: `Are you sure you want to delete this ${data.itemName}? This action cannot be undone.`,
                        confirmButtonText: `Yes, delete this ${data.itemName}`,
                    }}
                />
            )}
            {isQuestionHelpModalOpened && (
                <PromptModal
                    isOpen={isQuestionHelpModalOpened}
                    closeModal={handleQuestionHelpCloseModal}
                    data={promptModalData}
                    handlePrev={() => {}}
                />
            )}
        </div>
    );
};

export default ProfileQuestion;
