import { useEffect, useRef } from "react";

import { store } from "../../redux/store";

const usePersistentReduxListener = (callback: (_state: any) => void) => {
    const unsubscribeRef = useRef<(() => void) | null>(null);

    useEffect(() => {
        unsubscribeRef.current = store.subscribe(() => {
            const state = store.getState();
            callback(state);
        });

        return () => {
            if (unsubscribeRef.current) {
                unsubscribeRef.current();
            }
        };
    }, [callback]);
};

export default usePersistentReduxListener;
