import { useContext } from "react";

import { Col, Row } from "antd";
import { AppContext } from "App";
import wtImage from "assets/images/home/uk-wt-image.png";
import wtVideo from "assets/videos/taxzap_uk_social_video.mp4";

const WeHelped = () => {
    const {
        isMobile,
        maxWidth,
    } = useContext(AppContext);

    return <div className={"uk-how-it-works-block horizontal-center"} style={{ marginTop: -4 }}>
        <div style={{ maxWidth: maxWidth, padding: isMobile ? "24px" : "64px 100px", overflow: "hidden" }}>
            <div className={"uk-sub-slogan"}>
            Real stories from happy customers.
            </div>

            <div className={"mt20 self-employed__subtitle"} style={{ width: isMobile ? "" : "1312px" }}>
                We have already collected over £15 million for our customers!
            </div>

            <div className={"mt40"} style={{ overflow: "hidden" }}>
                <Row gutter={[64, 32]}>
                    <Col sm={24} xs={24} md={24} lg={24} xl={8}>
                        <div className="center">
                            <video controls autoPlay muted loop playsInline className={"uk-wt-video-new"}>
                                <source src={wtVideo} type="video/mp4"/>
                            Your browser does not support the video tag.
                            </video>
                        </div>
                    </Col>
                    <Col sm={24} xs={24} md={24} lg={24} xl={16}>
                        <img
                            src={wtImage}
                            alt={"wt"}
                            className={"uk-wt-image-new"}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    </div>;
};

export default WeHelped;
