import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { message, Button, Typography } from "antd";
import Dragger from "antd/lib/upload/Dragger";
// import addPhoto from "assets/images/new-questionnaire/camera.svg";
import deleteIcon from "assets/images/new-questionnaire/delete.svg";
import fileIcon from "assets/images/new-questionnaire/file.png";
import questionIcon from "assets/images/new-questionnaire/question_icon.svg";
import uploadIcon from "assets/images/new-questionnaire/upload.svg";
import Spinner from "components/basic/Spinner/Spinner";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { useUpdateClientTaxProfileMutation } from "services/client";
import { useDeleteFileMutation, useUploadAndParseFileMutation, useUploadAndParseCisFileMutation, useUploadFileMutation } from "services/files";
import { IUser } from "types/auth";
import { TaxProfile } from "types/client";
import { IFile } from "types/files";
import { checkDepends } from "utils/new-questionnaire";
import { v4 as uuidv4 } from "uuid";

import { logError } from "../../../../../utils/sentry";
import { Depend, Question, Step } from "../../constants/questions";
import { PromptModalType, UploadType } from "../../enums/enum";
import PromptModal from "../Shared/PromptModal";

type RunningTotalP60 = {
    parsedP60Gross: number;
    parsedP60TaxDeduction: number;
}

export type Receipt = {
    originalName: string;
    url: string;
    name: string;
    _id: string;
    warning: string;
    metaData?: any;
};

const getRunningTotalP60 = (uploadedFiles: Receipt[]):RunningTotalP60 => {
    let totalGrossIncome = 0;
    let totalTaxDeduction = 0;

    uploadedFiles.forEach(file => {
        const { GROSS_INCOME_AMOUNT, TAX_DEDUCTED_AMOUNT } = file.metaData;

        totalGrossIncome += Number(GROSS_INCOME_AMOUNT) || 0;
        totalTaxDeduction += Number(TAX_DEDUCTED_AMOUNT) || 0;
    });

    return {
        parsedP60Gross: totalGrossIncome,
        parsedP60TaxDeduction: totalTaxDeduction,
    };
};

type UploadFileQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: {category: string, docs: Receipt[]} | null) => void;
    handlePrev: () => void;
    initialValue?: {
        category: string;
        docs: Receipt[];
    }
    user: IUser;
    refetchTaxProfiles?: () => void;
    currentStepData: Step;
    categoryAnswers?: Record<string, any>;
    currentProfile?: TaxProfile;
};

const UploadFileQuestion: FC<UploadFileQuestionProps> = ({ data, onAnswer, initialValue, user, refetchTaxProfiles, categoryAnswers, handlePrev, currentProfile }) => {
    const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation();
    const [deleteFile, { isLoading: isDeleting }] = useDeleteFileMutation();
    const [uploadAndParseFile, { isLoading: isParsing }] = useUploadAndParseFileMutation();
    const [uploadAndParseCisFile, { isLoading: isParsingCis }] = useUploadAndParseCisFileMutation();
    const [updateClientTaxProfile] = useUpdateClientTaxProfileMutation();

    const {
        isOpened: isQuestionHelpModalOpened,
        open: handleQuestionHelpOpenModal,
        close: handleQuestionHelpCloseModal,
    } = useModalOpener();

    const [uploadedCategoryReceipts, setUploadedCategoryReceipts] = useState<Receipt[]>(initialValue?.docs || []);
    const [showModalOpener, setShowModalOpener] = useState(false);

    const clientId = useMemo(() => (user?.id || ""), [user]);

    const promptModalData = useMemo(() => ({
        type: data.promptModal?.type || PromptModalType.info,
        title: data.promptModal?.title || "",
        text: data.promptModal?.text || "",
        image: data.promptModal?.image || "",
    }), [data.promptModal]);

    const document = useMemo(() => {
        if (!user.documents || user.documents.length === 0) return null;
        return user.documents
            .slice()
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())[0];
    }, [user.documents]);

    const updateTaxReport = useCallback(async (uploadedFiles: Receipt[]) => {
        if (currentProfile) {
            const { parsedP60Gross, parsedP60TaxDeduction } = getRunningTotalP60(uploadedFiles);

            try {
                await updateClientTaxProfile({
                    taxProfileId: currentProfile._id,
                    client: currentProfile.client,
                    revenue: {
                        ...currentProfile.revenue,
                        parsedP60GrossPay: parsedP60Gross,
                        parsedP60TaxDeducted: parsedP60TaxDeduction,
                    },
                }).unwrap();
            } catch (error: any) {
                const contextInfo = {
                    client: currentProfile.client,
                    taxProfileId: currentProfile._id,
                    function: "updateTaxReport",
                    error: error.message,
                };
                logError(error, contextInfo);
            }
        }
    }, [currentProfile, updateClientTaxProfile]);

    const uploadQFile = useCallback(async (file: IFile) => {
        if (!document || !data.uploadType) return false;
        let uploadedFileResponse;
        let metaData = undefined;
        if ([UploadType.p60EndOfYearCertificate].includes(data.uploadType)) {
            uploadedFileResponse = await uploadAndParseFile({
                file: file,
                clientId: clientId,
                type: "p60",
            }).unwrap();
            metaData = uploadedFileResponse?.data?.parsedData || undefined;
        } else if ([UploadType.cisDeductionStatement].includes(data.uploadType)) {
            uploadedFileResponse = await uploadAndParseCisFile({
                file: file,
                clientId: clientId,
                type: UploadType.cisDeductionStatement,
            }).unwrap();
            metaData = uploadedFileResponse?.data?.parsedData || undefined;
        } else {
            uploadedFileResponse = await uploadFile({
                file: file,
                path: `clients/${clientId}/receipts`,
                type: data.uploadType!,
            }).unwrap();
        }

        if (!uploadedFileResponse?.data?.url) return false;

        const receipt: Receipt = {
            originalName: file.name,
            url: uploadedFileResponse.data.url,
            name: data.uploadType!,
            _id: uuidv4(),
            warning: uploadedFileResponse.data.warning,
            metaData: metaData,
        };

        const uploadedFiles = [...uploadedCategoryReceipts, receipt];

        setUploadedCategoryReceipts(uploadedFiles);

        if (["P60 End of Year Certificate"].includes(data.uploadType)) {
            await updateTaxReport(uploadedFiles);
        }

        onAnswer(
            data.question,
            {
                category: data.uploadType!,
                docs: uploadedFiles,
            }
        );

        return true;
    }, [document, data.uploadType, data.question, uploadedCategoryReceipts, onAnswer, uploadAndParseFile, clientId, uploadFile, updateTaxReport, uploadAndParseCisFile]);

    const deleteReceipt = useCallback(async (id: string, uploadType: string | undefined) => {
        if (!uploadType) return false;

        const url = uploadedCategoryReceipts?.find(receipt => receipt?._id === id)?.url;
        if (!url) return false;

        const deleteFileResponse = await deleteFile({ url }).unwrap();
        if (!deleteFileResponse?.success) return false;

        const successfulReceiptUploads = uploadedCategoryReceipts.filter(receipt => receipt._id !== id);
        if (successfulReceiptUploads.length === 0) {
            if (currentProfile && data.uploadType && ["P60 End of Year Certificate"].includes(data.uploadType)) {
                try {
                    await updateClientTaxProfile({
                        taxProfileId: currentProfile._id,
                        client: currentProfile.client,
                        revenue: {
                            ...currentProfile.revenue,
                            parsedP60GrossPay: 0,
                            parsedP60TaxDeducted: 0,
                        },
                    }).unwrap();
                } catch (error: any) {
                    const contextInfo = {
                        client: currentProfile.client,
                        taxProfileId: currentProfile._id,
                        function: "updateTaxReport",
                        error: error.message,
                    };
                    logError(error, contextInfo);
                }
            }

            onAnswer(
                data.question,
                null // This ensures the question is removed from newAnswers when there are no receipts
            );
        } else {
            // Otherwise, update with the remaining receipts
            if (data.uploadType && [UploadType.p60EndOfYearCertificate].includes(data.uploadType)) {
                await updateTaxReport(successfulReceiptUploads);
            }
            onAnswer(
                data.question,
                {
                    category: uploadType,
                    docs: successfulReceiptUploads,
                }
            );
        }
        setUploadedCategoryReceipts(successfulReceiptUploads);

        return true;
    }, [currentProfile, data.question, data.uploadType, deleteFile, onAnswer, updateClientTaxProfile, updateTaxReport, uploadedCategoryReceipts]);

    const checkModalDepends = useCallback((depend: Depend[], categoryAnswers?: Record<string, any>) => {
        if (categoryAnswers) {
            return checkDepends(depend, categoryAnswers);
        } else {
            return false;
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            refetchTaxProfiles?.();
        }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data?.promptModal?.depend && categoryAnswers) {
            const shouldModalShow = checkModalDepends(data.promptModal?.depend, categoryAnswers);
            setShowModalOpener(shouldModalShow);
            if (shouldModalShow && !data.promptModal?.manualOpen) {
                handleQuestionHelpOpenModal();
            }
        } else {
            setShowModalOpener(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div className="new-questionnaire__question">
            <div className="new-questionnaire__question-title mt10">
                {"Upload Documents"}
            </div>
            <div className="horizontal-center mt20">
                {data.questionTitle && (
                    <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
                )}
                <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : { marginTop: "0px" }}>
                    {data.question}
                </div>
                {showModalOpener && (
                    <span className="new-questionnaire__question-icon" onClick={handleQuestionHelpOpenModal}>
                        <img src={questionIcon} width={20} alt="help"/>
                    </span>
                )}
            </div>
            <div className="new-questionnaire__upload-files__question">
                <Spinner isLoading={isUploading || isDeleting || isParsing || isParsingCis }>
                    <Dragger
                        name={"file"}
                        multiple={false}
                        showUploadList={false}
                        className="new-questionnaire__upload-files__question-dragger mt20 mb20"
                        customRequest={async data => {
                            const result = await uploadQFile?.(data.file as unknown as IFile);
                            if (!result) {
                                await message.error("File was not uploaded!");
                            }
                            return true;
                        }}
                    >
                        <img src={uploadIcon} alt="upload icon"/>
                        <div className="new-questionnaire__upload-files__question-dragger-text">Select a file</div>
                    </Dragger>

                </Spinner>

            </div>

            <div className="new-questionnaire__upload-files__question-files">
                {uploadedCategoryReceipts.map((receipt, index) => (

                    <div key={index} className="new-questionnaire__upload-files__question-file mb10">
                        <div className="new-questionnaire__upload-files__question-info">
                            <div className="new-questionnaire__upload-files__question-info-container">
                                <img src={fileIcon} alt="file icon" height={30} className="mr10"/>
                                <Typography.Text ellipsis className="new-questionnaire__upload-files__question-file-name">{receipt.originalName}</Typography.Text>
                            </div>
                            <div>
                                <Button ghost icon={<img src={deleteIcon} alt="delete icon" height={20} onClick={() => deleteReceipt(receipt._id, data?.uploadType)}/>} shape="circle">
                                </Button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {isQuestionHelpModalOpened && (
                <PromptModal
                    isOpen={isQuestionHelpModalOpened}
                    closeModal={handleQuestionHelpCloseModal}
                    data={promptModalData}
                    handlePrev={handlePrev}
                />
            )}
        </div>
    );
};

export default UploadFileQuestion;
