import { FC, useCallback, useEffect } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { usePlaidLink } from "react-plaid-link";

interface ConnectPlaidModalProps {
    linkToken: string
    onSuccess?: (_metadata: any) => void;
    onExit?: (_error: any, _metadata: any) => void;
}

const ConnectPlaidModal: FC<ConnectPlaidModalProps> = ({ onSuccess, onExit, linkToken }) => {
    const { isPlaidEnabled } = useFlags();

    const onSuccessCallback = useCallback(
        async (metadata: any) => {
            if (onSuccess) {
                onSuccess(metadata);
            }
        },
        [onSuccess]
    );

    // Callback for handling link exit
    const onExitCallback = useCallback(
        (error: any, metadata: any) => {
            if (onExit) {
                onExit(error, metadata);
            }
        },
        [onExit]
    );

    // Initialize the Plaid Link hook only when linkToken is available
    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: onSuccessCallback,
        onExit: onExitCallback,
    });

    // Automatically open the Plaid modal when linkToken is ready
    useEffect(() => {
        if (linkToken && ready && isPlaidEnabled) {
            open();
        }
    }, [linkToken, ready, open, isPlaidEnabled]);

    return null;
};

export default ConnectPlaidModal;
