import "styles/booleanQuestion.less";

import { IInput } from "constants/signupFormNew";

import React, { useEffect, useState } from "react";

import { Button, Typography } from "antd";

// import { useBottomScroller } from "hooks/helpers/useBottomScroller";

type UkBooleanQuestionProps = {
    data: IInput;
    onAnswer: (_answer: string) => void;
    value?: string;
};

const UkBooleanQuestion: React.FC<UkBooleanQuestionProps> = ({
    data,
    onAnswer,
    value,
}) => {
    const [error, setError] = useState<string>("");
    const [skipValidation, setSkipValidation] = useState<boolean>(true);

    useEffect(() => {
        if (data.required && value === undefined && !skipValidation) {
            setError("This field is required");
        } else {
            setError("");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, skipValidation]);

    const handleButtonClick = (value: string) => {
        onAnswer(value);
    };

    // useBottomScroller();

    return (
        <div className="builder__boolean-question">
            <Typography.Text className="builder__form-question">
                {data.name}
            </Typography.Text>
            <div className="builder__boolean-question__buttons">
                <Button
                    className={`builder__boolean-button ${
                        value === "true" ? "builder__boolean-button-selected" : ""
                    }`}
                    onClick={() => {
                        handleButtonClick("true");
                        setSkipValidation(false);
                    }}
                >
                    Yes
                </Button>
                <Button
                    className={`builder__boolean-button ${
                        value === "false"
                            ? "builder__boolean-button-selected"
                            : ""
                    }`}
                    onClick={() => {
                        handleButtonClick("false");
                        setSkipValidation(false);
                    }}
                >
                    No
                </Button>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: "5px",
                }}
            >
                {error && (
                    <Typography.Text className="builder__form-error">
                        {error}
                    </Typography.Text>
                )}
            </div>
        </div>
    );
};

export default UkBooleanQuestion;
