import taxZapLoadingAnimation from "assets/animation/loading-animation.json";
import Lottie from "lottie-react";

const LottieAnimation = ({
    animationJson,
    isLoading,
    backgroundColor,
    margin,
}: {
    animationJson?: any;
    isLoading: boolean;
    backgroundColor?: string;
    margin?: string;
}) => {
    return isLoading ? (
        <div
            className="lottie-container"
            style={{
                backgroundColor: backgroundColor || undefined,
                margin: margin || undefined,
            }}
        >
            <Lottie
                animationData={animationJson || taxZapLoadingAnimation}
                loop={true}
                style={{
                    width: "300px",
                    height: "300px",
                    margin: "0 auto",
                }}
            />
        </div>
    ) : null;
};
export default LottieAnimation;
