import { FC, useCallback, useState } from "react";

import { Modal, List, Divider, Row, Col, Image, Typography } from "antd";
import connectionAnimation from "assets/animation/open-banking-connection.json";
import boltWhiteImg from "assets/images/new-questionnaire/onboarding/bolt-white.svg";
import bankFrame from "assets/images/payment/bank_frame.svg";
import bankLogo from "assets/images/payment/bank_logo.svg";
import padLock from "assets/images/payment/padlock .svg";
import privacyLogo from "assets/images/payment/privacy_policy.svg";
import Lottie from "lottie-react";
import { useAppDispatch } from "redux/hooks";
import { setCountryRegions, setOpenBankingError } from "redux/reducers/countries";
import { useGetBanksMutation } from "services/connectBankAccount";

import { SelectBankAccount } from "./SelectBankAccount";

type ConnectGCModalProps = {
    isOpen: boolean;
    closeConnectModal: () => void;
    openConnectModal: () => void;
};

const data = [
    {
        title: "Secure",
        logo: padLock,
        description: "Full protection with an encrypted end-to-end connection.",
    },
    {
        title: "Private",
        logo: privacyLogo,
        description: "Your credentials will never be made accessible to anyone.",
    },
];

const ConnectGCModal: FC<ConnectGCModalProps> = ({ isOpen, closeConnectModal, openConnectModal }) => {
    const dispatch = useAppDispatch();
    const [step, setStep] = useState(0);
    const [pageLoading, setPageLoading] = useState(false);
    const [getBanksMutation] = useGetBanksMutation();

    const handleCountries = useCallback(() => {
        setPageLoading(true);
        getBanksMutation({ countryCode: "GB" })
            .unwrap()
            .then(data => {
                if (data) {
                    dispatch(setCountryRegions(data?.institutions));
                }
            })
            .catch(error => {
                console.error("Error fetching banks:", error);
                dispatch(setOpenBankingError(true));
            })
            .finally(() => {
                setPageLoading(false);
                setStep(1);
            });
    }, [dispatch, getBanksMutation]);

    return (
        <Modal
            open={isOpen}
            onCancel={closeConnectModal}
            closable={false}
            footer={null}
            className="new-questionnaire__connect-modal"

            centered
        >
            <div className="flex-column center">
                {pageLoading
                    ? <Lottie
                        animationData={connectionAnimation}
                    />
                    : <>
                        {step === 0 && (
                            <div className="new-questionnaire__connect-modal__prompt-container">
                                <Typography
                                    className="new-questionnaire__form-title"
                                >
                                    Connect your bank accounts
                                </Typography>
                                <Row className="center mt20 mb10">
                                    <Col span={8} className="center">
                                        <Image
                                            src={bankLogo}
                                            width={50}
                                            preview={false}
                                        />
                                    </Col>
                                    <Col span={8} className="center">
                                        <Lottie
                                            className="w50px h50px"
                                            animationData={connectionAnimation}

                                        />
                                    </Col>
                                    <Col span={8} className="center">
                                        <Image
                                            src={bankFrame}
                                            width={50}
                                            preview={false}
                                        />
                                    </Col>
                                </Row>

                                <List
                                    className="w100"
                                    itemLayout="vertical"
                                    dataSource={data}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            className="flex-column center"
                                        >
                                            <List.Item.Meta
                                                avatar={<img src={item.logo} alt={item.title} />}
                                                title={item.title}
                                                description={
                                                    <div className="profile_description">
                                                        {item.description}
                                                    </div>
                                                }
                                            />
                                            {index === 0 && <Divider className="divider_line" />}
                                        </List.Item>
                                    )}
                                />
                                <div className="new-questionnaire__onboarding-welcome-button" onClick={() => {
                                    handleCountries();
                                }}>
                                    <img src={boltWhiteImg} alt="Connect Bank Account"/>
                                    <div className="new-questionnaire__onboarding-welcome-button-text ml6">
                                        Continue
                                    </div>
                                </div>
                            </div>
                        )}

                        {step === 1 && (
                            <SelectBankAccount closeConnectModal={closeConnectModal} openConnectModal={openConnectModal} />
                        )}
                    </>
                }
            </div>
        </Modal>
    );
};

export default ConnectGCModal;
