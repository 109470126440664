import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const featureFlags = [
    "isNewParserEnabled",
    "isPlaidEnabled",
];

interface FeatureFlagsState {
    isNewParserEnabled: boolean;
    isPlaidEnabled: boolean;
}

const initialState: FeatureFlagsState = {
    isNewParserEnabled: false,
    isPlaidEnabled: false,
};

const featureFlagsSlice = createSlice({
    name: "featureFlags",
    initialState,
    reducers: {
        setFeatureFlags: (state, action: PayloadAction<FeatureFlagsState>) => {
            return action.payload; // ✅ Store flags in Redux
        },
    },
});

export const { setFeatureFlags } = featureFlagsSlice.actions;
export default featureFlagsSlice.reducer;
