import { FC, useEffect, useMemo, useState } from "react";

import { Modal, Image } from "antd";
import book from "assets/images/explainer/book.png";
import explainer1 from "assets/videos/explainer/explainer-1.mp4";
import explainer2 from "assets/videos/explainer/explainer-2.mp4";
import explainer3 from "assets/videos/explainer/explainer-3.mp4";
import explainer4 from "assets/videos/explainer/explainer-4.mp4";
import explainerManual1 from "assets/videos/explainer/explainer-manual-1.mp4";
import explainerManual2 from "assets/videos/explainer/explainer-manual-2.mp4";
import explainerManual3 from "assets/videos/explainer/explainer-manual-3.mp4";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectDocument, setExpensesExplainerShown } from "redux/reducers/document";

interface Step {
    title: string;
    subTitle?: boolean;
    text: string;
    continueButtonText: string;
    continueButtonFunction: "next" | "close";
    hasBack: boolean;
    image?: string;
    video?: string;
    dot?: number;
    dots?: number;
    header?: {
        type: "text" | "button";
        text: string;
    };
}

type Steps = Record<number, Step>;

const connectSteps: Steps = {
    0: {
        title: "How to Claim Business Expenses for",
        subTitle: true,
        text: "We've listed all your bank transactions. Now, it's time to claim the ones that are business expenses. Let's learn how to do it!",
        continueButtonText: "Get Started",
        continueButtonFunction: "next",
        hasBack: false,
        image: book,
        header: { type: "text", text: "1 min read" },
    },
    1: {
        title: "Sort Your Expenses",
        text: "Quickly classify transactions as Business or Personal to keep your records accurate.",
        continueButtonText: "Next",
        continueButtonFunction: "next",
        hasBack: true,
        video: explainer1,
        dot: 1,
        dots: 4,
    },
    2: {
        title: "Save Time with Multi Select",
        text: "Select multiple transactions at once to speed up classification.",
        continueButtonText: "Next",
        continueButtonFunction: "next",
        hasBack: true,
        video: explainer2,
        dot: 2,
        dots: 4,
    },
    3: {
        title: "Select/Change Category",
        text: "We've categorised your expenses. Update if anything's missing or miscategorised.",
        continueButtonText: "Next",
        continueButtonFunction: "next",
        hasBack: true,
        video: explainer3,
        dot: 3,
        dots: 4,
    },
    4: {
        title: "Pay Less Taxes",
        text: "Business expenses lower your taxes. Let's find them all.",
        continueButtonText: "Let's Go!",
        continueButtonFunction: "close",
        hasBack: true,
        video: explainer4,
        dot: 4,
        dots: 4,
        header: { type: "button", text: "close" },
    },
};

const manualSteps: Steps = {
    0: {
        title: "How to Claim Business Expenses for",
        subTitle: true,
        text: "Business expenses help you to pay less in taxes. Your job here is simple - mark down how much you have spent for business purposes in each of the categories. Let's learn how to do it!",
        continueButtonText: "Get Started",
        continueButtonFunction: "next",
        hasBack: false,
        image: book,
        header: { type: "text", text: "1 min read" },
    },
    1: {
        title: "Enter Business Expenses by Category",
        text: "Add the amount you've spent for your business in each expense category.",
        continueButtonText: "Next",
        continueButtonFunction: "next",
        hasBack: true,
        video: explainerManual1,
        dot: 1,
        dots: 3,
    },
    2: {
        title: "Check out Eligibility Criterias",
        text: "Learn what counts as a business expense in each category.",
        continueButtonText: "Next",
        continueButtonFunction: "next",
        hasBack: true,
        video: explainerManual2,
        dot: 2,
        dots: 3,
    },
    3: {
        title: "See Total Amount of Business Expenses",
        text: "See your total expenses as you add amounts to each category.",
        continueButtonText: "Let's Go!",
        continueButtonFunction: "close",
        hasBack: true,
        video: explainerManual3,
        dot: 3,
        dots: 3,
        header: { type: "button", text: "close" },
    },
};

type ExplainerModalProps = {
    isOpen: boolean;
    subTitle: string;
    closeModal: () => void;
};

const ExplainerModal: FC<ExplainerModalProps> = ({ isOpen, closeModal, subTitle }) => {
    const dispatch = useAppDispatch();

    const { selectedSharingPreference } = useAppSelector(selectDocument);

    const steps = useMemo(
        () => (selectedSharingPreference === "connect" ? connectSteps : manualSteps),
        [selectedSharingPreference]
    );

    const stepKeys = useMemo(
        () => Object.keys(steps).map(Number).sort((a, b) => a - b),
        [steps]
    );

    const [currentStep, setCurrentStep] = useState<number>(0);
    const currentStepData = steps[currentStep];

    const handleNext = () => {
        if (currentStepData.continueButtonFunction === "close") {
            dispatch(setExpensesExplainerShown(true));
            closeModal();
            return;
        }

        const currentIndex = stepKeys.indexOf(currentStep);
        if (currentIndex < stepKeys.length - 1) {
            setCurrentStep(stepKeys[currentIndex + 1]);
        }
    };

    const handleBack = () => {
        const currentIndex = stepKeys.indexOf(currentStep);
        if (currentIndex > 0) {
            setCurrentStep(stepKeys[currentIndex - 1]);
        }
    };

    useEffect(() => {
        setCurrentStep(0);
    }, [selectedSharingPreference]);

    return (
        <Modal
            open={isOpen}
            maskClosable={false}
            closable={false}
            keyboard={false}
            footer={null}
            centered
            styles={{ content: { padding: "16px !important", borderRadius: "12px", background: "#F7F8FA" } }}
        >
            <div className="new-questionnaire__explainer-modal__container">
                <div className="new-questionnaire__explainer-modal__content">
                    <div className="new-questionnaire__explainer-modal__header">
                        {currentStepData.header && (<>
                            {currentStepData.header.type === "button" && (
                                <div
                                    className="new-questionnaire__explainer-modal__header__close-button"
                                    onClick={() => {
                                        dispatch(setExpensesExplainerShown(true));
                                        closeModal();
                                    }}
                                >
                                    {currentStepData.header.text}
                                </div>
                            )}
                            {currentStepData.header.type === "text" && (
                                <div className="new-questionnaire__explainer-modal__header__text">
                                    {currentStepData.header.text}
                                </div>
                            )}
                        </>
                        )}
                    </div>
                    {currentStepData.image && <Image src={currentStepData.image} width={126} preview={false} />}
                    {currentStepData.video && (
                        <div className="video-crop-container">
                            <video
                                src={currentStepData.video}
                                autoPlay
                                muted
                                loop
                                playsInline
                                webkit-playsinline
                            />
                        </div>
                    )}
                    {currentStepData.dots && (
                        <div className="new-questionnaire__explainer-modal__dots">
                            {[...Array(currentStepData.dots)].map((_, index) => (
                                <span
                                    key={index}
                                    className={`new-questionnaire__explainer-modal__dot ${
                                        currentStepData.dot === index + 1 ? "active" : ""
                                    }`}
                                />
                            ))}
                        </div>
                    )}
                    <div className="new-questionnaire__explainer-modal__title">
                        {currentStepData.title}
                    </div>
                    {currentStepData.subTitle && (
                        <div className="new-questionnaire__explainer-modal__sub-title">
                            {subTitle}
                        </div>
                    )}
                    <div className="new-questionnaire__explainer-modal__text mt12">{currentStepData.text}</div>
                </div>
                <div className="new-questionnaire__explainer-modal__footer">
                    {currentStepData.hasBack && (
                        <div
                            className="new-questionnaire__desktop-form-secondary-button w100"
                            onClick={handleBack}
                        >
                            Back
                        </div>
                    )}
                    <div
                        className={"new-questionnaire__desktop-form-primary-button w100"}
                        onClick={() => handleNext()}
                    >
                        {currentStepData.continueButtonText}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ExplainerModal;
