import { FC } from "react";

import { checkDepends } from "utils/new-questionnaire";

import { Question } from "../../constants/questions";
import AmountListQuestion from "../AmountListQuestion/AmountListQuestion";
import DateListQuestion from "../DateQuestion/DateListQuestion";
import TextListQuestion from "../TextListQuestion/TextListQuestion";

type QuestionnaireStepQuestionsListProps = {
    data: Question;
    onAnswer: (_question: string, _answer: any, _questionType?: string) => void;
    answers: any
};

const QuestionnaireStepQuestionsList: FC<QuestionnaireStepQuestionsListProps> = ({ data, answers, onAnswer }) => {
    return (
        <div style={{ maxWidth: "335px", margin: "0 auto" }}>
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                {data.question}
            </div>
            <div className="mt20">
                {data?.questions?.map((question, i) => {
                    const shouldShow = checkDepends(question?.depend || [], answers);

                    if (!shouldShow) onAnswer(question.question, undefined);

                    return shouldShow ? (
                        <div key={i}>
                            {question.type === "amountListQuestion" && (
                                <AmountListQuestion
                                    data={question}
                                    onAnswer={onAnswer}
                                    initialValue={answers[question?.question]}
                                    focus={i === 0}
                                />
                            )}
                            {question.type === "dateListQuestion" && (
                                <DateListQuestion
                                    data={question}
                                    onAnswer={onAnswer}
                                    initialValue={answers[question?.question]}
                                    focus={i === 0}
                                />
                            )}
                            {question.type === "textListQuestion" && (
                                <TextListQuestion
                                    data={question}
                                    onAnswer={onAnswer}
                                    initialValue={answers[question?.question]}
                                    focus={i === 0}
                                />
                            )}
                        </div>
                    ) : null;
                })}
            </div>
        </div>
    );
};

export default QuestionnaireStepQuestionsList;
