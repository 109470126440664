import "styles/authError.less";

import { FC } from "react";

import error from "assets/images/error.svg";
import { useNavigate } from "react-router-dom";

interface AuthErrorProps {
    resetPassword: () => void;
    code: number | string;
}

const AuthError: FC<AuthErrorProps> = ({ resetPassword, code }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (code === 403 || code === 409) {
            resetPassword();
        } else if (code === 404) {
            navigate("/");
        }
    };

    let errorMessage;

    switch (code) {
    case 404:
        errorMessage = (
            <p className="auth-error__message">
                    Oops! It seems you don’t have an account yet. Click{" "}
                <span className="auth-error__click-text">'Start Now'</span> to begin your journey with TaxZap.
            </p>
        );
        break;
    case 403:
        errorMessage = (
            <p className="auth-error__message">
                    Oops! Your password is not right. Click{" "}
                <span className="auth-error__click-text">'Reset'</span> to recover your password and secure your account.
            </p>
        );
        break;
    case 409:
        errorMessage = (
            <p className="auth-error__message">
                    Oops! It seems you haven't set up a password yet. Click{" "}
                <span className="auth-error__click-text">'Reset'</span> to set up and create your account.
            </p>
        );
        break;
    case "FETCH_ERROR":
        errorMessage = (
            <p className="auth-error__message">
                    Oops! Server unavailable.
            </p>
        );
        break;
    default:
        errorMessage = (
            <p className="auth-error__message">An unknown error occurred.</p>
        );
    }

    return (
        <div
            className="auth-error__container"
            onClick={handleClick}
        >
            <img src={error} alt="error" />
            {errorMessage}
        </div>
    );
};

export default AuthError;
