import "styles/questionnaire/sectionDoneProgress.less";

import { FC, useEffect, useRef, useState, useMemo } from "react";

import badge from "assets/images/new-questionnaire/circleProgress/badge.svg";

type SectionDoneProgressProps = {
    allSectionCount: number;
    doneSectionsCount: number;
    nextCategoryTitle: string | null;
};

const SectionDoneProgress: FC<SectionDoneProgressProps> = ({
    allSectionCount,
    doneSectionsCount,
    nextCategoryTitle,
}) => {
    const [progress, setProgress] = useState(0);
    const [isInstant, setIsInstant] = useState(true);

    const circleRef = useRef<SVGCircleElement>(null);

    const size = 250;
    const strokeWidth = 30;
    const radius = useMemo(() => (size - strokeWidth) / 2, [size, strokeWidth]);
    const circumference = useMemo(() => 2 * Math.PI * radius, [radius]);
    const offset = useMemo(() => circumference - (progress / 100) * circumference, [circumference, progress]);

    const sectionDots = useMemo(
        () =>
            Array.from({ length: allSectionCount }, (_, index) => {
                const angle = (360 / allSectionCount) * index - 90;
                return {
                    x: size / 2 + radius * Math.cos((angle * Math.PI) / 180),
                    y: size / 2 + radius * Math.sin((angle * Math.PI) / 180),
                };
            }),
        [allSectionCount, radius, size]
    );

    const pointSize = 30;
    const progressAngle = useMemo(() => (progress / 100) * 360, [progress]);

    useEffect(() => {
        setIsInstant(true);
        setProgress(((doneSectionsCount - 1) / allSectionCount) * 100);

        const timer = setTimeout(() => {
            setIsInstant(false);
            setProgress((doneSectionsCount / allSectionCount) * 100);
        }, 100);

        return () => clearTimeout(timer);
    }, [doneSectionsCount, allSectionCount]);

    return (
        <div className="section-done-progress">
            <svg width={size} height={size} style={{ overflow: "visible" }}>
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    fill="none"
                    stroke="#EFEFEF"
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                />
                <circle
                    ref={circleRef}
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    fill="none"
                    stroke="url(#progressGradient)"
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                    style={{
                        transition: isInstant ? "none" : "stroke-dashoffset 1s ease-in-out",
                    }}
                />
                {sectionDots.map((dot, index) => (
                    <circle key={index} cx={dot.x} cy={dot.y} r="3" className="section-dot" />
                ))}

                <g
                    style={{
                        transformOrigin: "center",
                        transform: `rotate(${progressAngle}deg)`,
                        transition: isInstant ? "none" : "transform 1s ease-in-out",
                    }}
                >
                    <circle
                        cx={size / 2}
                        cy={size / 2 - radius}
                        r={pointSize / 2}
                        fill="white"
                        stroke="#2EBB49"
                        strokeWidth="1"
                    />
                </g>

                <defs>
                    <linearGradient id="progressGradient" x1="100%" y1="0%" x2="0%" y2="0%">
                        <stop offset="0%" stopColor="#2EBB49" />
                        <stop offset="100%" stopColor="#90EDA2" />
                    </linearGradient>
                    <filter id="inner-shadow" x="-50%" y="-50%" width="200%" height="200%">
                        <feComponentTransfer in="SourceAlpha">
                            <feFuncA type="table" tableValues="1 0" />
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="3" />
                        <feOffset dx="0" dy="3" result="offsetblur" />
                        <feFlood floodColor="rgba(0, 0, 0, 0.24)" result="color" />
                        <feComposite in2="offsetblur" operator="in" />
                        <feComposite in2="SourceAlpha" operator="in" />
                        <feMerge>
                            <feMergeNode in="SourceGraphic" />
                            <feMergeNode />
                        </feMerge>
                    </filter>
                </defs>
            </svg>
            <img src={badge} alt="Badge" className="badge-icon" />
            <div className="progress-text">
                <div className="progress-count">
                    {doneSectionsCount}/{allSectionCount} completed
                </div>
                <div className="progress-next">
                    {nextCategoryTitle ? `${nextCategoryTitle} up next` : "All done!"}
                </div>
            </div>
        </div>
    );
};

export default SectionDoneProgress;
