import React from "react";

import { Button, Modal } from "antd";
import warning from "assets/images/profile/warning.svg";

interface BasicPlanModalProps {
    isOpened: boolean;
    close: () => void;
    onConfirm: () => void;
}

const BasicPlanModal: React.FC<BasicPlanModalProps> = ({
    isOpened,
    close,
    onConfirm,
}) => {
    return (
        <Modal
            open={isOpened}
            closable={false}
            className="install-modal"
            onCancel={close}
            width={335}
            footer={[]}
            // maskStyle={{
            //     backgroundColor: "rgba(0, 0, 0, 0.45) !important",
            // }}
        >
            <div className="basic-modal__content">
                <img src={warning} alt="rocket" />
                <h3 className="install-modal__title" style={{ marginBottom: "0px" }}>
                    Are you sure?
                </h3>
                <p className="install-modal__text">
                    Please be aware that cheque payments can be lost in transit.
                </p>
                <p className="install-modal__text" style={{
                    margin: "0px",
                    color: "#2EBB49",
                }}>
                    Consider safer alternatives.
                </p>
                <Button
                    type="primary"
                    className="payment-plans__confirm-button"
                    style={{ width: "300px", fontSize: "14px", marginBottom: "20px" }}
                    onClick={onConfirm}
                >
                    Yes, Confirm
                </Button>
                <Button
                    type="primary"
                    className="payment-select-plan-button profile__select-tab-button"
                    style={{ width: "300px", margin:  "0px" }}
                    onClick={close}
                >
                    Change Payment Method
                </Button>
            </div>
        </Modal>
    );
};

export default BasicPlanModal;
