import { useEffect, useState } from "react";

import ButtonFooter from "components/basic/Footer/ButtonFooter";
import Footer from "components/basic/Footer/Footer";
import Header from "components/basic/Header/Header";
import { useNavigate } from "react-router-dom";

import BlogPageComponent from "./components/BlogPage";

const BlogPage = () => {
    const [showButtonFooter, setShowButtonFooter] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.pathname.includes("Self_Assessment_CIS_Tax_Return_Tips")) {
            navigate("/How_to_do_a_CIS_Tax_Return_Your_Simple_Guide"); // Redirect to correct blog
        }
    }, [navigate]);

    return (
        <>
            <div>
                <Header/>
            </div>
            <div>
                <BlogPageComponent setShowButtonFooter={setShowButtonFooter} />
            </div>
            <div style={{ width: "100%" }}>
                <Footer />
            </div>
            <ButtonFooter showButtonFooter={showButtonFooter}/>
        </>
    );
};

export default BlogPage;
