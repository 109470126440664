import "styles/profile/idpal.less";

import { useEffect, useState, useContext } from "react";

import { AppContext } from "App";
import errorIcon from "assets/images/error_icon.png";
import LottieAnimation from "components/basic/Spinner/Lottie";
import { useUser } from "hooks/common/useUser";
import { idPalApi, useGetUuidMutation } from "services/idpal";

interface IIdPalStartPageProps {
    advanceStep: () => void;
}

function IdPalComponent({ advanceStep }: IIdPalStartPageProps) {
    const [uuid, setUuid] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const { user } = useUser();
    const { isMobile } = useContext(AppContext);
    const [getUuid] = useGetUuidMutation();

    if (!user) {
        throw new Error("User was not found");
    }
    const clientId = user.id;

    useEffect(() => {
        getUuid(clientId)
            .unwrap()
            .then(data => {
                if (data) {
                    setUuid(data.data.result);
                }
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, [clientId, getUuid]);

    // Use API query for ID verification status
    const { data: idVerificationData } = idPalApi.endpoints.isIdVerificationCompleted.useQuery(
        { clientId },
        { pollingInterval: 5000 }
    );

    useEffect(() => {
        if (idVerificationData?.idpalComplete) {
            advanceStep();
        }
    }, [idVerificationData, advanceStep]);

    return (
        <div className="profile__content">
            <LottieAnimation isLoading={loading} />
            {uuid ? (
                <iframe
                    title="IdPal Integration"
                    style={
                        isMobile
                            ? { width: "335px", height: "600px" }
                            : { width: "100%", height: "100%" }
                    }
                    src={`https://websubmission.client.id-pal.com/?lang=en&uuid=${uuid}`}
                    allowFullScreen
                ></iframe>
            ) : (
                !loading && (
                    <div style={{ marginTop: "150px" }}>
                        <img
                            className="error__icon"
                            alt="error icon"
                            src={errorIcon}
                        />
                        <div
                            className="idpal__content-title"
                            style={{ fontSize: "19px", marginBottom: "12px" }}
                        >
                            Something went wrong
                        </div>
                        <div
                            className="idpal__content-text"
                            style={{ padding: "0 30px 30px 30px" }}
                        >
                            Something went wrong with ID-Pal. Please try again.
                        </div>
                    </div>
                )
            )}
        </div>
    );
}

export default IdPalComponent;
