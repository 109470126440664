import { useAppSelector } from "redux/hooks";
import { IUser } from "types/auth";

export const useUser = (): {
    user: IUser | null;
} => {
    const user = useAppSelector(state => state.auth.user);

    return {
        user,
    };
};
