import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

import { TaxYear } from "../../types/taxYear";

interface TaxYearState {
  taxYears: TaxYear[];
}

const initialState: TaxYearState = {
    taxYears: [],
};
const taxYearSlice = createSlice({
    name: "taxYear",
    initialState,
    reducers: {
        setTaxYears(state, action: PayloadAction<TaxYear[]>) {
            state.taxYears = action.payload;
        },
    },
});

export const selectTaxYears = createSelector(
    (state: RootState) => state.taxYears,
    taxYears => taxYears
);

export const { setTaxYears } = taxYearSlice.actions;

export default taxYearSlice.reducer;
