import { useEffect } from "react";

import { ReloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import applicationError from "assets/images/application-error.png";
import { categories } from "components/pages/NewQuestionnaire/constants/questions";
import { useRouteError } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { next } from "redux/reducers/navigation";
import { logError } from "utils/sentry";

const CustomErrorFallback = () => {
    const routeError = useRouteError();
    const dispatch = useAppDispatch();
    const { currentCategory, currentStep } = useAppSelector(state => state.navigation);

    useEffect(() => {
        const contextInfo = {
            url: window.location.href,
            error: routeError,
        };
        logError("URGENT: UNHANDLED APPLICATON ERROR", contextInfo);
        logError(routeError, contextInfo);
    }, [routeError]);

    const refreshPageOnFirstStepOfCurrentCategory = () => {
        // Check if current step exists in the current category
        if (!categories[currentCategory]?.steps[currentStep]) {
            dispatch(next({ nextCategory: currentCategory, nextStep: 0, nextQuestion: 0 }));
        }
        window.location.reload();
    };

    return (
        <div className="p20 taCenter">
            <h2>Oops! Something went wrong.</h2>
            <div className="mAuto">
                <img width={300} src={applicationError} alt="Application Error"/>
            </div>
            <p>We’re sorry for the inconvenience.</p>
            <p>
                Please try again later or{" "}
                <a href="mailto:support@taxzap.co.uk" className="primary-color no-decoration">
                    contact support
                </a>{" "}
                if the issue persists.
            </p>

            <Button size="large" onClick={() => refreshPageOnFirstStepOfCurrentCategory()} type="primary" shape="round" icon={<ReloadOutlined />} >
            Refresh
            </Button>
        </div>
    );
};

export default CustomErrorFallback;
