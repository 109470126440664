/* eslint-disable no-unused-vars*/
import React, { ReactNode, useState } from "react";

import { Typography, Image, List, Button, Badge } from "antd";
import ProfileButtonFooter from "components/basic/Footer/ProfileButtonFooter";
import LootieAnimation from "components/basic/LootieAnimation/bankAnimation";
interface BankAccountProps {
    bankAccount: {
        _id: string;
        logo: string;
        accountName: string;
        ownerName: string;
        accountNumber: string;
        region: string;
        iban: string;
        balance: number;
        type: string;
        currencyCode: string;

    }[];
    countryCodePrefix: string;
    selectedItem: string | null;
    bankAccountById: (_id: string) => void;
    isSingleAccount: boolean;
    isMobile: boolean;
    handleFinish: any;
    UpdateBankDetails: () => void;
}

function BankAccount({
    bankAccount,
    countryCodePrefix,
    selectedItem,
    bankAccountById,
    isSingleAccount,
    isMobile,
    handleFinish,
    UpdateBankDetails,

}: Readonly<BankAccountProps>) {
    const [accountSelected, setAccountSelected] = useState(false);
    const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null);

    function handleClick(itemId: string) {
        bankAccountById(itemId);
        setAccountSelected(true);
        setSelectedAccountId(itemId);
    }

    return (
        <div className="profile__content-body" style={{ paddingBottom: "20px" }}>
            {bankAccount.length === 0 ? (
                <LootieAnimation isLottie={true} />
            ) : (
                <List
                    itemLayout="horizontal"
                    dataSource={selectedAccountId && isSingleAccount ? bankAccount.filter(item => item._id === selectedAccountId) : bankAccount}
                    className="accountOption_list-p"
                    renderItem={(item: {
                        currencyCode: ReactNode;
                        type: string;
                        balance: ReactNode;
                        iban: string;
                        ownerName: string; _id: string; logo: string; accountName: string; accountNumber: string; region: string
                    }) => (
                        <React.Fragment key={item._id}>
                            <List.Item
                                className={`accountOption_list ${selectedItem === item._id ? "selected" : ""} ${countryCodePrefix !== (item.region === "GB" ? "UK" : item.region) ? "disabled" : "enable"}`}
                                onClick={() => handleClick(item._id)}
                                style={{ display: "block", pointerEvents: countryCodePrefix !== (item.region === "GB" ? "UK" : item.region) ? "none" : "auto", backgroundColor: selectedItem === item._id ? "#EAF8ED" : "transparent" }}
                            >

                                <List.Item.Meta
                                    className="bankAccount_meta"

                                    avatar={<Image className="bankOption_logo" src={item?.logo} preview={false} width={isMobile ? 50 : 70} />}
                                    title={

                                        <div className="currency_symbol_container">
                                            <div className="bankaccount_title">
                                                {item?.accountName || item?.ownerName} <span className="account_number">{item?.iban || item?.accountNumber}</span>

                                            </div>

                                            <input
                                                type="radio"
                                                id="specifyColor"
                                                name="bankAccountSelection"
                                                checked={selectedItem === item._id}
                                                onChange={() => handleClick(item._id)}
                                                tabIndex={selectedItem === item._id ? 0 : -1}
                                            />

                                        </div>
                                    }
                                />
                                <div className="currency_avatar">
                                    <span className="current">
                                        {item.type === "CACC" ? "Current" : item.type === "SVGS" ? "Savings" : "Other"}
                                        &nbsp;
                                        <Badge dot color="green" /> {item?.currencyCode}
                                    </span>

                                    <span className="currency_symbol"> {item.region === "IE" ? "€" : "£"} {item?.balance} </span>
                                </div>
                            </List.Item>
                            {countryCodePrefix !== (item.region === "GB" ? "UK" : item.region) ? (
                                <Typography.Text className="account-error">
                                    {countryCodePrefix === "IE" ? "You can only select euro accounts" : "You can only select pound accounts"}
                                </Typography.Text>
                            ) : null}
                        </React.Fragment>
                    )}
                />
            )}
            {!isSingleAccount && (
                <>
                    {isMobile ? (
                        <ProfileButtonFooter>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="payment-details__save-button"
                                disabled={!accountSelected}
                                onClick={UpdateBankDetails}

                            >
                                Select Bank Account
                            </Button>
                        </ProfileButtonFooter>
                    ) : (
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="payment-details__save-button"
                            disabled={!accountSelected}
                            onClick={UpdateBankDetails}

                            style={{ marginTop: "40px" }}
                        >
                            Select Bank Account
                        </Button>
                    )}
                </>
            )}
            {isSingleAccount && (
                <>
                    {isMobile ? (
                        <ProfileButtonFooter>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="payment-details__save-button"
                            >
                                Add a New Account
                            </Button>
                            <p className="payment_manually" onClick={handleFinish}>
                                Enter bank details manually
                            </p>
                        </ProfileButtonFooter>
                    ) : (
                        <>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="payment-details__save-button"
                                style={{ marginTop: "40px" }}
                            >
                                Add a New Account
                            </Button>
                            <p className="payment_manually" onClick={handleFinish}>
                                Enter bank details manually
                            </p>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default BankAccount;
