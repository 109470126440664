import { useContext, useState } from "react";

import { Col, Divider, Image, List, Row, Typography } from "antd";
import { AppContext } from "App";
import bankFrame from "assets/images/payment/bank_frame.svg";
import banklogo from "assets/images/payment/bank_logo.svg";
import padLock from "assets/images/payment/padlock .svg";
import privacylogo from "assets/images/payment/privacy_policy.svg";
import ProfileButtonFooter from "components/basic/Footer/ProfileButtonFooter";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";

import connectionAnimation from "../../../../basic/LootieAnimation/Open-banking connection lotti-1.json";

/* eslint-disable no-unused-vars*/

interface AddPaymentDetailsProps {
    handleFinish: () => any;
    handleCountries: () => any;
}

const data = [
    {
        title: "Secure",
        logo: padLock,
        description: "Full protection with an encrypted end-to-end connection.",
    },
    {
        title: "Private",
        logo: privacylogo,
        description: "Your credentials will never be made accessible to anyone.",
    },
];

function AddPaymentDetails({
    handleFinish,
    handleCountries,
}: Readonly<AddPaymentDetailsProps>) {
    const { isMobile } = useContext(AppContext);
    const [selectAccount] = useState<boolean>(false);
    const [isSingleAccount] = useState<boolean>(false);

    return (
        <div className="profile__status-content">
            <Typography
                className="profile__content-title"
                style={{ fontSize: "16px" }}
            >
                {selectAccount
                    ? "Select Your Account"
                    : "Instantly Add Payment Details"}
            </Typography>
            <Typography
                className="profile__content-text"
                style={{ margin: "15px 0px" }}
            >
                {isSingleAccount
                    ? "You can only select a \"£ (pound)\" account for"
                    : selectAccount
                        ? "Select the account where you want to receive refunds."
                        : "Connect your bank account to instantly add your payment details."}
                <br />
                {isSingleAccount
                    ? "refund payment. Click below to add a new"
                    : selectAccount
                        ? "Your refund will be sent to the selected account."
                        : "Your refund will be sent to the connected account."}
                <br />
                {isSingleAccount && "account"}
            </Typography>
            <div
                className="profile__content-body"
                style={{ paddingBottom: "20px" }}
            >
                <Row className="img-row">
                    <Col>
                        <Image
                            src={banklogo}
                            width={isMobile ? 50 : 90}
                            preview={false}
                        />
                    </Col>
                    {isMobile && (
                        <Lottie
                            animationData={connectionAnimation}
                            style={{ width: "15%" }}
                        />
                    )}
                    <Col>
                        <Image
                            src={bankFrame}
                            width={isMobile ? 50 : 90}
                            preview={false}
                        />
                    </Col>
                </Row>

                <List
                    style={{ marginBottom: "60px" }}
                    itemLayout="vertical"
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <List.Item.Meta
                                style={{ width: "320px" }}
                                avatar={<img src={item.logo} alt={item.title} />}
                                title={item.title}
                                description={
                                    <div className="profile_description">
                                        {item.description}
                                    </div>
                                }
                            />
                            {index === 0 && <Divider className="divider_line" />}
                        </List.Item>
                    )}
                />

                {isMobile ? (
                    <ProfileButtonFooter>
                        <Link
                            className="profile__new-submission-button"
                            style={{ marginTop: "0px" }}
                            onClick={() => handleCountries()}
                            to={""}
                        >
                            Add a New Account
                        </Link>

                        <p className="payment_manually" onClick={handleFinish}>
                            Enter Bank Details Manually
                        </p>
                    </ProfileButtonFooter>
                ) : (
                    <>
                        <Link
                            className="profile__new-submission-button"
                            style={{ marginTop: "0px" }}
                            onClick={() => handleCountries()}
                            to={""}
                        >
                            Add a New Account
                        </Link>

                        <p className="payment_manually" onClick={handleFinish}>
                            Enter Bank Details Manually
                        </p>
                    </>
                )}

            </div>
        </div>
    );
}

export default AddPaymentDetails;
