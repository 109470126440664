import { Dispatch, SetStateAction, useContext } from "react";

import { Typography } from "antd";
import { AppContext } from "App";
import inProgress from "assets/images/profile/payment/payment_in_progress.png";

type PaymentInProgressProps = {
    setPageLoading: Dispatch<SetStateAction<boolean>>
};

export default function PaymentInProgress({ setPageLoading }: PaymentInProgressProps) {
    const { isMobile } = useContext(AppContext);

    return (
        <div className="profile__status-content">
            <img
                width={isMobile ? 200 : 337}
                src={inProgress}
                alt="In Progress"
                onLoad={() => {
                    const timer = setTimeout(() => {
                        setPageLoading(false);
                    }, 200);
                    return () => clearTimeout(timer);
                }}
                style={{
                    display: "block",
                    margin: "20px auto",
                }}
            />
            <Typography
                className="profile__content-title"
                style={{ padding: "10px" }}
            >
                Working on your return...
            </Typography>
            <Typography
                className="profile__content-text"
                style={{
                    margin: "10px 0px",
                    padding: "10px",
                }}
            >
                Sit tight, you’re return is still in progress. We’ll notify you when your payment is available.
            </Typography>
        </div>
    );
}
