import { useState } from "react";

const useScale = (initialScale = 1, scaleFactor = 1.1, delay = 200) => {
    const [scale, setScale] = useState(initialScale);

    const toggleScale = () => {
        setScale(prevScale => (prevScale === initialScale ? scaleFactor : initialScale));
        setTimeout(() => {
            setScale(initialScale);
        }, delay);
    };

    return { scale, toggleScale };
};

export default useScale;
