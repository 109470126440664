import councilTax from "assets/images/new-questionnaire/rental/council-tax.png";
import financial from "assets/images/new-questionnaire/rental/financial.png";
import gasElectricalSafety from "assets/images/new-questionnaire/rental/gas-electrical-safety.png";
import insurance from "assets/images/new-questionnaire/rental/insurance.png";
import legal from "assets/images/new-questionnaire/rental/legal.png";
import managementAdvertisingFees from "assets/images/new-questionnaire/rental/management-advertising-fees.png";
import propertyVisits from "assets/images/new-questionnaire/rental/property-visits.png";
import rent from "assets/images/new-questionnaire/rental/rent.png";
import repairsMaintenanceServices from "assets/images/new-questionnaire/rental/repairs-maintenance-services.png";
import staff from "assets/images/new-questionnaire/rental/staff.png";
import stationery from "assets/images/new-questionnaire/rental/stationary.png";
import waterCharges from "assets/images/new-questionnaire/rental/water-charges.png";

export const CategoryEmoji: Record<string, Record<string, string>> = {
    "Self Employment": {
        "Food": "🍕",
        "Tools & Materials": "⚒️",
        "Office": "💼",
        "Clothing": "👕",
        "Travel": "🚆",
        "Motor": "🚗",
        "Utilities": "🚰",
        "Phone": "☎️",
        "Financial": "🏦",
        "Rent": "🏠",
        "Hotels": "🏨",
        "Training": "📚",
        "Professional Services": "🖊️",
        "Interest": "📈",
        "Marketing": "🪧",
        "Repairs & Maintenance": "🧰",
        "Staff": "👩‍💼",
        "Other": "💳",
    },
    "Residential/Commercial": {
        "Rent": "🏠",
        "Council Tax": "📝",
        "Insurance": "💷",
        "Water Charges": "🌊",
        "Repairs & Maintenance": "⚒️",
        "Gas & Electrical Safety": "✅",
        "Management & Advertising Fees": "🗃️",
        "Legal": "🧑‍⚖️",
        "Property Visit": "🚆",
        "Financial": "🏦",
        "Staff": "👩‍💼",
        "Other": "💳",
    },
    "Furnished Holiday Let": {
        "Rent": "🏠",
        "Council Tax": "📝",
        "Insurance": "💷",
        "Water Charges": "🌊",
        "Repairs & Maintenance": "⚒️",
        "Gas & Electrical Safety": "✅",
        "Management & Advertising Fees": "🗃️",
        "Legal": "🧑‍⚖️",
        "Property Visit": "🚆",
        "Motor": "🚗",
        "Financial": "🏦",
        "Stationery": "🖊️",
        "Other": "💳",
    },
    "Rent-a-Room": {
        "Rent": "🏠",
        "Council Tax": "📝",
        "Insurance": "💷",
        "Water Charges": "🌊",
        "Repairs & Maintenance": "⚒️",
        "Gas & Electrical Safety": "✅",
        "Management & Advertising Fees": "🗃️",
        "Legal": "🧑‍⚖️",
        "Property Visit": "🚆",
        "Financial": "🏦",
        "Staff": "👩‍💼",
        "Other": "💳",
    },
    "cis": {
        "Food": "🍕",
        "Tools & Materials": "⚒️",
        "Office": "💼",
        "Clothing": "👕",
        "Travel": "🚆",
        "Motor": "🚗",
        "Utilities": "🚰",
        "Phone": "☎️",
        "Financial": "🏦",
        "Rent": "🏠",
        "Hotels": "🏨",
        "Training": "📚",
        "Professional Services": "🖊️",
        "Interest": "📈",
        "Marketing": "🪧",
        "Repairs & Maintenance": "🧰",
        "Staff": "👩‍💼",
        "Other": "💳",
    },
};

export const CategoryPrompt: Record<string, Record<string, string>> = {
    "Self Employment": {
        "Food": "Food and drink expenses incurred while working away from your usual workplace on short-term assignments (lasting less than two years). Swipe left on food and drink expenses that are not work related.",
        "Tools & Materials": "Business expenses relating to tools, raw materials, goods for resale, and equipment.",
        "Office": "Office expenses, such as electrical equipment, stationery, marketing costs, and computer software.",
        "Clothing": "Clothing expenses that were necessary for work, such as uniforms, laundry, and protective clothing (e.g. hard hats, safety boots).",
        "Travel": "Work-related travel costs, including train, bus, air, or taxi fares. This applies only to trips for short-term assignments (lasting less than two years).",
        "Motor": "Work-related car expenses.",
        "Utilities": "Bills for phone, internet, heating, electricity, and council tax. These must be relevant to your business or home office.",
        "Phone": "Expenses for business phone usage, including mobile and landline costs.",
        "Financial": "Work expenses relating to bank and overdraft fees, credit card charges, hire purchase interest, insurance costs, and professional services (accountants, surveyors, architects). Don't forget to include your TaxZap fee!",
        "Rent": "Rent for your business premises, including workshops or office spaces used for your construction work.",
        "Hotels": "Accommodation costs for overnight work trips away from your usual work location.",
        "Training": "Training expenses or professional subscriptions that are relevant to your line of work.",
        "Professional Services": "Expenses for professional services such as legal advice, contracts, or regulatory advice.",
        "Interest": "Expenses related to interest on business loans or financing.",
        "Marketing": "Marketing expenses such as advertising, branding, and promotional materials.",
        "Repairs and Maintenance": "Expenses for repairs and maintenance of work-related equipment or premises.",
        "Staff": "Expenses related to wages, benefits, and recruitment for employees or contractors.",
        "Other": "Other expenses that are not covered by the categories outlined.",
    },
    "Residential/Commercial": {
        "Rent": "Expenses that are classed as rents you pay for a property that you let (e.g. ground rent).",
        "Council Tax": "Expenses that qualify as council tax or business rates for your rental property.",
        "Insurance": "Expenses you've incurred for property insurance (covers buildings and contents), public liability insurance, or employer's public liability insurance.",
        "Water Charges": "Water charges that you've incurred relating to your rental property in the financial year.",
        "Repairs & Maintenance": "Expenses that qualify as routine repairs, general maintenance, or replacements.",
        "Gas & Electrical Safety": "Expenses for gas or electrical safety inspections and certifications.",
        "Management & Advertising Fees": "Property management fees, letting agent fees, promotional materials, or online listing fees.",
        "Legal": "Legal fees, accountancy fees, or consultancy fees related to your rental property.",
        "Property Visit": "Expenses for travelling to your rental property for management or maintenance purposes.",
        "Financial": "Expenses relating to financial services, such as accountancy, or bank fees",
        "Staff": "Expenses related to wages, benefits, or hiring staff for your rental business.",
        "Other": "Other expenses that are not covered by the categories outlined.",
    },
    "Furnished Holiday Let": {
        "Rent": "Expenses that are classed as rents you pay for a property that you let (e.g. ground rent).",
        "Council Tax": "Expenses that qualify as business rates (if the property is let for more than 140 days a year), or council tax (if the property is let for fewer than 140 days a year).",
        "Insurance": "Expenses incurred for property insurance, public liability insurance, or employer's liability insurance.",
        "Water Charges": "Expenses for water charges related to your holiday let.",
        "Repairs & Maintenance": "Expenses that qualify as routine repairs, general maintenance, or replacements.",
        "Gas & Electrical Safety": "Expenses for gas or electrical safety inspections and certifications.",
        "Management & Advertising Fees": "Property management fees, letting agent fees, promotional materials, or online listing fees.",
        "Legal": "Expenses for legal, accountancy, or consultancy fees.",
        "Property Visit": "Travel expenses for visiting your holiday let for management or maintenance purposes.",
        "Motor": "Work-related car expenses.",
        "Financial": "Expenses relating to financial services, such as accountancy, or bank fees",
        "Stationery": "Expenses for stationery or branded materials used in your holiday let business.",
        "Other": "Other expenses that are not covered by the categories outlined.",
    },
    "Rent-a-Room": {
        "Rent": "Expenses that are classed as rents you pay for a room that you let (e.g. ground rent).",
        "Council Tax": "Expenses that qualify as business rates (if the property is let for more than 140 days a year), or council tax (if the property is let for fewer than 140 days a year).",
        "Insurance": "Expenses incurred for property insurance, public liability insurance, or employer's liability insurance.",
        "Water Charges": "Expenses for water charges related to your rental room.",
        "Repairs & Maintenance": "Expenses that qualify as routine repairs, general maintenance, or replacements.",
        "Gas & Electrical Safety": "Expenses for gas or electrical safety inspections and certifications.",
        "Management & Advertising Fees": "Property management fees, letting agent fees, promotional materials, or online listing fees.",
        "Legal": "Expenses for legal, accountancy, or consultancy fees.",
        "Property Visit": "Travel expenses for visiting your rental room for management or maintenance purposes.",
        "Financial": "Expenses relating to financial services, such as accountancy, or bank fees",
        "Staff": "Expenses related to wages, benefits, or hiring staff for your rental business.",
        "Other": "Other expenses that are not covered by the categories outlined.",
    },
    "cis": {
        "Food": "Food and drink expenses incurred while working away from your usual workplace on short-term assignments (lasting less than two years). Swipe left on food and drink expenses that are not work related.",
        "Tools & Materials": "Work expenses relating to tools, raw materials, goods for resale, and equipment.",
        "Office": "Office expenses, such as electrical equipment, stationery, marketing costs, and computer software.",
        "Clothing": "Clothing expenses that were necessary for work, such as uniforms, laundry, and protective clothing (e.g. hard hats, safety boots).",
        "Travel": "Work-related travel costs, including train, bus, air, or taxi fares. This applies only to trips for short-term assignments (lasting less than two years).",
        "Motor": "Work-related car expenses.",
        "Utilities": "Bills for phone, internet, heating, electricity, and council tax. These must be relevant to your business or home office.",
        "Phone": "Business phone usage, including mobile and landline costs.",
        "Financial": "Work expenses relating to bank and overdraft fees, credit card charges, hire purchase interest, insurance costs, and professional services (accountants, surveyors, architects). Don't forget to include your TaxZap fee!",
        "Rent": "Rent for your business premises, including workshops or office spaces used for your construction work.",
        "Hotels": "Accommodation costs for overnight work trips away from your usual work location.",
        "Training": "Training expenses or professional subscriptions that are relevant to your line of work.",
        "Professional Services": "Expenses for professional services such as legal advice, contracts, or regulatory advice.",
        "Interest": "Expenses related to interest on business loans or financing.",
        "Marketing": "Marketing expenses such as advertising, branding, and promotional materials.",
        "Repairs and Maintenance": "Expenses for repairs and maintenance of work-related equipment or premises.",
        "Staff": "Expenses related to wages, benefits, and recruitment for employees or contractors.",
        "Other": "Other expenses that are not covered by the categories outlined.",
    },
};

export const ExpenseCategoryModalData: Record<string, Record<string, any>> = {
    "Self Employment": {
        "Food": {},
        "Tools & Materials": {},
        "Office": {},
        "Clothing": {},
        "Legal": {},
        "Vehicle and Fuel": {},
        "Travel": {},
        "Motor": {},
        "Utilities": {},
        "Financial": {},
        "Pension": {},
        "Rent": {},
        "Mortgage": {},
        "Hotels": {},
        "Training": {},
        "Student Loan": {},
        "Other": {},
    },
    "Residential/Commercial": {
        "Rent": {
            image: rent,
            manualExpenses: "Provide expenses for rents paid for properties that you let.",
            openBanking: "Provide expenses for rents paid for properties that you let.",
        },
        "Council Tax": {
            image: councilTax,
            manualExpenses: "Provide expenses for council tax or business rates applicable to your rental property.",
            openBanking: "Provide expenses for council tax or business rates applicable to your rental property.",
        },
        "Insurance": {
            image: insurance,
            manualExpenses: "Provide expenses for property insurance, public liability insurance, or employer's liability insurance.",
            openBanking: "Provide expenses for property insurance, public liability insurance, or employer's liability insurance.",
        },
        "Water Charges": {
            image: waterCharges,
            manualExpenses: "Provide expenses for water charges incurred for rental properties.",
            openBanking: "Provide expenses for water charges incurred for rental properties.",
        },
        "Repairs & Maintenance": {
            image: repairsMaintenanceServices,
            manualExpenses: "Provide expenses for repairs, maintenance, replacements, and utility bills for your property.",
            openBanking: "Provide expenses for repairs, maintenance, replacements, and utility bills for your property.",
        },
        "Gas & Electrical Safety": {
            image: gasElectricalSafety,
            manualExpenses: "Provide expenses for gas and electrical safety inspections and certifications.",
            openBanking: "Provide expenses for gas and electrical safety inspections and certifications.",
        },
        "Management & Advertising Fees": {
            image: managementAdvertisingFees,
            manualExpenses: "Provide property management fees, letting agent fees, and advertising costs.",
            openBanking: "Provide property management fees, letting agent fees, and advertising costs.",
        },
        "Legal": {
            image: legal,
            manualExpenses: "Provide expenses for legal fees, accountancy fees, or consultant services.",
            openBanking: "Provide expenses for legal fees, accountancy fees, or consultant services.",
        },
        "Property Visit": {
            image: propertyVisits,
            manualExpenses: "Provide travel expenses for visiting your rental property for management or maintenance.",
            openBanking: "Provide travel expenses for visiting your rental property for management or maintenance.",
        },
        "Financial": {
            image: financial,
            manualExpenses: "Provide expenses related to financial services, such as accountancy, bank fees, or insurance.",
            openBanking: "Provide expenses related to financial services, such as accountancy, bank fees, or insurance.",
        },
        "Staff": {
            image: staff,
            manualExpenses: "Provide expenses for wages, benefits, and hiring staff for your rental business.",
            openBanking: "Provide expenses for wages, benefits, and hiring staff for your rental business.",
        },
        "Other": {},
    },
    "Furnished Holiday Let": {
        "Rent": {
            image: rent,
            manualExpenses: "Provide expenses for rents paid for your holiday let property.",
            openBanking: "Provide expenses for rents paid for your holiday let property.",
        },
        "Council Tax": {
            image: councilTax,
            manualExpenses: "Provide expenses for council tax or business rates based on the property’s occupancy duration.",
            openBanking: "Provide expenses for council tax or business rates based on the property’s occupancy duration.",
        },
        "Insurance": {
            image: insurance,
            manualExpenses: "Provide expenses for property insurance, public liability insurance, or employer's liability insurance.",
            openBanking: "Provide expenses for property insurance, public liability insurance, or employer's liability insurance.",
        },
        "Water Charges": {
            image: waterCharges,
            manualExpenses: "Provide expenses for water charges incurred for your holiday let.",
            openBanking: "Provide expenses for water charges incurred for your holiday let.",
        },
        "Repairs & Maintenance": {
            image: repairsMaintenanceServices,
            manualExpenses: "Provide expenses for repairs, maintenance, replacements, and utility bills for your holiday let.",
            openBanking: "Provide expenses for repairs, maintenance, replacements, and utility bills for your holiday let.",
        },
        "Gas & Electrical Safety": {
            image: gasElectricalSafety,
            manualExpenses: "Provide expenses for gas and electrical safety inspections and certifications.",
            openBanking: "Provide expenses for gas and electrical safety inspections and certifications.",
        },
        "Management & Advertising Fees": {
            image: managementAdvertisingFees,
            manualExpenses: "Provide management fees, letting agent fees, and advertising costs for your holiday let.",
            openBanking: "Provide management fees, letting agent fees, and advertising costs for your holiday let.",
        },
        "Legal": {
            image: legal,
            manualExpenses: "Provide expenses for legal fees, accountancy fees, or consultancy services.",
            openBanking: "Provide expenses for legal fees, accountancy fees, or consultancy services.",
        },
        "Property Visit": {
            image: propertyVisits,
            manualExpenses: "Provide travel expenses for visiting your holiday let property for management or maintenance.",
            openBanking: "Provide travel expenses for visiting your holiday let property for management or maintenance.",
        },
        "Motor": {
            image: propertyVisits,
            manualExpenses: "Provide car expenses relating to your holiday let property.",
            openBanking: "Provide car expenses relating to your holiday let property.",
        },
        "Financial": {
            image: financial,
            manualExpenses: "Provide expenses related to financial services, such as accountancy, bank fees, or insurance.",
            openBanking: "Provide expenses related to financial services, such as accountancy, bank fees, or insurance.",
        },
        "Stationery": {
            image: stationery,
            manualExpenses: "Provide expenses for business stationery or branded materials used in your holiday let business.",
            openBanking: "Provide expenses for business stationery or branded materials used in your holiday let business.",
        },
        "Other": {},
    },
};
