import "../../../../../styles/profile/readyForReview.less";

import { useContext, useMemo } from "react";

import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Collapse, Typography } from "antd";
import { AppContext } from "App";
import openLinkIcon from "assets/images/dashboard/open_link_icon.svg";
import pdfIcon from "assets/images/new-questionnaire/documentParse/pdf_icon.svg";

type ReadyForReviewRefundProps = {
    currentDocumentYear: string;
    taxLiability: number;
};
export default function ReadyForReviewRefund({ currentDocumentYear, taxLiability }: ReadyForReviewRefundProps) {
    const { isMobile } = useContext(AppContext);
    const date = new Date().toLocaleDateString("en-GB");
    const { Panel } = Collapse;
    const taxLiabilityRefund = useMemo(() => "£" + Math.abs(taxLiability).toFixed(2), [taxLiability]);
    const showFirstContent = (
        <div className="mb20">
            <Typography className="ready-for-review__title">
                {currentDocumentYear} Tax Return
            </Typography>
            <div>
                <div className="ready-for-review__client-details">
                    <div className="ready-for-review__text-black">Status</div>
                    <div className="ready-for-review__value">
                        <div>Submitted</div>
                    </div>
                </div>
                <div className="ready-for-review__client-details">
                    <div className="ready-for-review__text-black">Date</div>
                    <div className="ready-for-review__value">
                        <div>{date}</div>
                    </div>
                </div>
                <div className="ready-for-review__client-details">
                    <div className="ready-for-review__text-green">Estimated Refund Due</div>
                    <div className="ready-for-review__value">
                        <div className="primary-color">{taxLiabilityRefund}</div>
                    </div>
                </div>
                <div className="ready-for-review__client-details vertical-center">
                    <div className="ready-for-review__text-black-pdf"><img src={pdfIcon} alt="pdf icon" width={"36px"} /> SA302 Form.pdf</div>
                    <div className="ready-for-review__value">
                        <div>Download</div>
                    </div>
                </div>
            </div>
        </div>);

    const showLastContent = (
        <div>
            <div className="mb20">
                <Typography className="ready-for-review__text">
                If you have saved your bank details with HMRC, your refund will be automatically processed and sent to your account.
                If HMRC does not have your bank details, they will contact you to arrange the refund.
                </Typography>
            </div>
            <div className="mb20">
                <Typography className="ready-for-review__title">
                When Will You Receive Your Refund?
                </Typography>
                <Typography className="ready-for-review__text">
                HMRC typically processes refunds within 5-10 working days. However, it may take longer during peak times.
                </Typography>
            </div>
            <div className="mb20">
                <Typography className="ready-for-review__title">
                Need Help?
                </Typography>
                <Typography className="ready-for-review__text">
                If you have not received your refund or need assistance, you can contact HMRC directly.
                </Typography>
            </div>
            <div className="mb20">
                <Typography className="ready-for-review__text-link">
                    <img src={openLinkIcon} alt="open link icon" /><a
                        href="https://www.gov.uk/self-assessment-tax-returns"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ready-for-review__link"
                    >Check Your Refund Status</a>
                </Typography>
            </div>
            <div className="mb20">
                <Typography className="ready-for-review__text-link">
                    <img src={openLinkIcon} alt="open link icon" /><a
                        href="https://www.gov.uk/self-assessment-tax-returns"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ready-for-review__link"
                    >Contact HMRC</a>
                </Typography>
            </div>
            <div className="mb20">
                <Typography className="ready-for-review__text-link">
                    <img src={openLinkIcon} alt="open link icon" /><a
                        href="https://www.gov.uk/guidance/claim-to-reduce-payments-on-account"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ready-for-review__link"
                    >Apply to Reduce Payments on Account</a>
                </Typography>
            </div>
        </div>
    );
    return (
        <div>
            {isMobile ? (
                <><div className="mb20">
                    {showFirstContent}
                </div>   <Collapse expandIcon={({ isActive }) => (isActive ? <UpOutlined /> : <DownOutlined />)} style={{ border: "none", background: "transparent" }}>
                    <Panel className="ready-for-review__panel ready-for-review__title" header="How to Claim Your Refund" key="1" style={{ borderBottom: "none", background: "transparent", fontSize: "16px" }}>
                        {showLastContent}
                    </Panel>
                </Collapse></>
            ) : (
                <>
                    {showFirstContent}
                    <Typography className="ready-for-review__title">
                    How to Claim Your Refund
                    </Typography>
                    {showLastContent}
                </>
            )}
        </div>
    );
}
