import "styles/buttonFooter.less";

import { FC, ReactNode } from "react";

import useDetectKeyboardOpen from "use-detect-keyboard-open";

interface ProfileButtonFooterProps {
    children?: ReactNode
}

const ProfileButtonFooter: FC<ProfileButtonFooterProps> = ({ children }) => {
    const isKeyboardOpen = useDetectKeyboardOpen();
    return !isKeyboardOpen ? (
        <div className="profile-button-footer">
            <div className="profile-button-footer-content">
                {children}
            </div>
        </div>
    ) : null;
};

export default ProfileButtonFooter;
