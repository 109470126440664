import { useContext } from "react";

import { AppContext } from "App";
import checkmark from "assets/images/uk/checkmarkgreen.svg";

const SubmissionBannerNew = () => {
    const { isMobile, maxWidth } = useContext(AppContext);

    return <div className={"uk-how-it-works-block horizontal-center"}>
        <div style={{ maxWidth: maxWidth, padding: isMobile ? "24px" : "64px 100px", overflow: "hidden" }}>
            <div className={"uk-sub-slogan"}>
                Simple, transparent pricing.
            </div>

            <div className={"mt20 mb40 self-employed__subtitle"}>
                TaxZap is a user-friendly platform that calculates your tax liability in real time and files your self-assessment tax return directly with HMRC.
            </div>
            <div className={"horizontal-center"} style={{ width: isMobile ? "" : "1312px" }}>
                <div className="uk-submission-banner-new">
                    <div style={{ textAlign: "center" }}>
                        <div className="h1">Submission Fee</div>
                        <div className="submission-fee-description" style={{ fontSize: "48px", fontWeight: 600 }}>
                            £179
                            <span style={{
                                color: isMobile ? "#7F43FF" : "#444444",
                                fontSize: isMobile ? "15px" : "20px",
                                fontWeight: 400,
                            }}>
                                {" "}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div style={{ width: isMobile ? "" : "450px" }}>
                            <div className={"h1 mb20"} style={{ textAlign: "center" }}>Why do you need to pay?</div>
                            <div className={"uk-banner-text-new mb16"}>
                                <img
                                    src={checkmark}
                                    height={48}
                                    width={48}
                                    alt={"check mark"}
                                    className={"mr10"}
                                />
                                HMRC-recognised software for secure and compliant submissions.
                            </div>
                            <div className={"uk-banner-text-new mb16"}>
                                <img
                                    src={checkmark}
                                    height={48}
                                    width={48}
                                    alt={"check mark"}
                                    className={"mr10"}
                                />
                                Automatic expense detection to help you claim all your allowable expenses.
                            </div>
                            <div className={"uk-banner-text-new mb16"}>
                                <img
                                    src={checkmark}
                                    height={48}
                                    width={48}
                                    alt={"check mark"}
                                    className={"mr10"}
                                />
                                Stay compliant and avoid fines with our easy-to-use platform.
                            </div>
                            <div className={"uk-banner-text-new mb16"}>
                                <img
                                    src={checkmark}
                                    height={48}
                                    width={48}
                                    alt={"check mark"}
                                    className={"mr10"}
                                />
                                More affordable than hiring an accountant.
                            </div>
                            <div className={"uk-banner-text-new mb16"}>
                                <img
                                    src={checkmark}
                                    height={48}
                                    width={48}
                                    alt={"check mark"}
                                    className={"mr10"}
                                />
                                Claim the TaxZap fee as a deductible expense on next year's return.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default SubmissionBannerNew;
