import moment from "moment";
import { Location } from "react-router-dom";
import { IClientStatusHistory } from "types/client";

export const extractDateFromURL = (url: string) => {
    const regex = /(\d{4})-(\d{2})-(\d{2})/;
    const match = url?.match(regex);

    if (match) {
        const year = match[1];
        const month = match[2];
        const day = match[3];

        return `${day}/${month}/${year}`;
    } else {
        return "";
    }
};

export const formatDate = (dateString: any) => {
    return moment(dateString).format("DD/MM/YYYY");
};

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
};

export const scrollToBottom = () => {
    window.scrollTo({
        top: document.documentElement.scrollHeight,
        left: 0,
        behavior: "smooth",
    });
};

export const scrollToElement = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
        element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
        });
    }
};

export const getStatusDate = (statusId: string, clientStatusHistory: IClientStatusHistory[] = []): string | null => {
    const firstMatch = [...clientStatusHistory].reverse().find(item => {
        return item.status.id === statusId;
    });

    if (!firstMatch) return "";

    return moment(firstMatch.date).format("DD MMMM YYYY");
};

export const convertTimestamp = (timestamp: string) => {
    const parsedTime = moment(timestamp);
    return parsedTime.format("DD/MM/YYYY HH:mm");
};

export const getPlanName = (plan: string) => {
    switch (plan) {
    case "basic-cis":
        return "Cheque";
    case "standard-cis":
        return "Bank Transfer";
    case "express-cis":
        return "Express Transfer";

    default:
        return "No Plan";
    }
};

export const formatDateAndAddTime = (dateString: string | undefined | null, duration: number, unit: moment.unitOfTime.DurationConstructor) => {
    const parsedDate = moment(dateString, "DD/MM/YYYY");

    if (parsedDate.isValid()) {
        return parsedDate.add(duration, unit).format("DD/MM/YYYY");
    } else {
        return moment().add(duration, unit).format("DD/MM/YYYY");
    }
};

export const getQueryParam = (name: string, location: Location) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(name);
};

export const getCurrencySymbol = (currencyCode: string) => {
    try {
        const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currencyCode,
        });
        const formatted = formatter.formatToParts(123); // format a dummy number
        const symbolPart = formatted.find(part => part.type === "currency");
        return symbolPart ? symbolPart.value : currencyCode;
    } catch (error) {
        return currencyCode;
    }
};
export const formatCurrency = (value?: number | string) => {
    const numValue = Number(value);
    return isNaN(numValue)
        ? "£0.00"
        : `£${numValue.toLocaleString("en-GB", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};
