import "styles/updateTransaction.less";
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";

import { Alert, Button, Select } from "antd";
import editPencil from "assets/images/new-questionnaire/edit_pencil.svg";
import bankFrame from "assets/images/payment/bank_frame.svg";
import { ProfileType } from "components/pages/NewQuestionnaire/enums/enum";
import moment from "moment";
import { logError } from "utils/sentry";

import { getTagIcon } from "./TransactionsFilters";
import { ExpenseCard } from "../../SwipeableTransactionsCards/SwipeableTransactionsCards";

type UpdateTransactionProps = {
    transactionToUpdate: ExpenseCard;
    taxProfileType: ProfileType;
    taxProfileId: string;
    updateTransaction: (_id: string, _updateData: any) => any;
    approveOrReject: (_approval: string, _transaction: ExpenseCard, _checkAreAllCategoriesDefined: boolean) => Promise<void>;
    handleClose: () => void;
    categories: string[];
    approval: string | undefined;
};

const UpdateTransaction: React.FC<UpdateTransactionProps> = ({
    transactionToUpdate: transaction,
    taxProfileType,
    updateTransaction,
    handleClose,
    categories,
    approval,
    approveOrReject,
}) => {
    const inputTotalRef = useRef<HTMLInputElement>(null);
    const [isTotalEdit, setIsTotalEdit] = useState(false);
    const [editableTotal, setEditableTotal] = useState<Number | undefined | string | 0>(undefined);
    const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
    const [error, setError] = useState<boolean>(false);

    const handleTotalWrapperClick = useCallback(() => {
        if (inputTotalRef.current) {
            inputTotalRef.current.focus();
        }
    }, []);

    const handleTotalChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(",", ".");
        if (/^-?\d*\.?\d{0,2}$/.test(value)) {
            setEditableTotal(value);
        }
    }, []);

    const handleChangeCategory = useCallback((value: string[]) => {
        setSelectedCategory(value);
        setError(value[0] === "No Category");
    }, []);

    const handleUpdate = useCallback(async () => {
        const updateData = {
            category: {
                ...transaction?.category,
                byTaxProfileType: {
                    ...transaction?.category?.byTaxProfileType,
                    [taxProfileType]: {
                        ...transaction?.category?.byTaxProfileType[taxProfileType],
                        clientUpdatedCategory: selectedCategory[0],
                    },
                },
            },
            clientUpdatedAmount: Number(`-${Math.abs(Number(Number(editableTotal)))}`),
        };
        try {
            await updateTransaction(transaction.id, updateData);
            if (approval) {
                await approveOrReject(approval, transaction, false);
            }
            handleClose();
        } catch (error) {
            logError(error, {
                function: "handleUpdate",
                updateData,
            });
        } finally {
            setIsTotalEdit(false);
            setEditableTotal(undefined);
        }
    }, [transaction, taxProfileType, selectedCategory, editableTotal, updateTransaction, handleClose, approval, approveOrReject]);

    const handleKeyDown = useCallback((e: any) => {
        if (e.key === "Enter") {
            setIsTotalEdit(false);
            e.currentTarget.blur();
        }
    }, [setIsTotalEdit]);

    useEffect(() => {
        if (transaction) {
            setSelectedCategory(transaction.tags);
            setError(transaction.tags[0] === "No Category");
            setEditableTotal(transaction.totalAmount);
        }
    }, [transaction]);

    if (!transaction) return null;

    return (
        <div className="updateTransaction">
            <div className="updateTransaction__content">
                <div className="expenses__swipeable-card-content">
                    <div className="expenses__swipeable-card-row">
                        <div className="expenses__swipeable-card-amount" style={transaction.styles || {}}>
                            <div className="expenses__swipeable-card-amount-value">
                                <div className="expenses__swipeable-card-amount-value">
                                    £{transaction.totalAmount}
                                </div>
                            </div>
                        </div>
                        <div>
                            {transaction?.merchant?.logo && (
                                <img
                                    height={66}
                                    src={transaction?.merchant?.logo || bankFrame}
                                    alt={transaction?.merchant?.name}
                                    style={{ display: "block" }}
                                    onError={e => { e.currentTarget.src = bankFrame; }}
                                />
                            )}
                        </div>
                    </div>
                    <div className="expenses__swipeable-card-merchant">
                        <div className="expenses__swipeable-card-description">
                            {transaction.description.length > 33 ? `${transaction.description.slice(0, 33)}...` : transaction.description}
                        </div>

                        {transaction.bookingDateTime && (
                            <div className="expenses__swipeable-card-date mb10">
                                {moment(transaction.bookingDateTime).format("D MMM YYYY")}
                            </div>
                        )}

                        {transaction?.merchant?.name && transaction.merchant.name.toLowerCase() && (
                            <div className="expenses__swipeable-card-merchant-pill">
                                {transaction.merchant.name}
                            </div>
                        )}
                        <div className="new-questionnaire__amount-card-wrapper mt12" onClick={handleTotalWrapperClick}>
                            <div className="new-questionnaire__income-card-total-text">Amount</div>
                            {isTotalEdit ? (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                    <input
                                        className="new-questionnaire__edit-expense-input mr15"
                                        onChange={handleTotalChange}
                                        type="text"
                                        inputMode="decimal"
                                        pattern="[0-9]*"
                                        autoFocus={true}
                                        value={editableTotal?.toString()}
                                        ref={inputTotalRef}
                                        onBlur={() => {setIsTotalEdit(false);}}
                                        onKeyDown={(e: any) => handleKeyDown(e)}
                                    />
                                </div>
                            ) : (
                                <div className="new-questionnaire__income-card-total-amount-wrapper" style={{ backgroundColor: "transparent" }} onClick={() => {
                                    setIsTotalEdit(true);
                                }}>
                                    <img src={editPencil} alt="edit" style={{ display: "block" }} />
                                    <div className="new-questionnaire__income-card-total-amount">£{editableTotal?.toString()}</div>
                                </div>
                            )}
                        </div>
                        <div className="new-questionnaire__amount-card-wrapper mt12">
                            <div className="new-questionnaire__income-card-total-text">Category</div>
                            <div className="new-questionnaire__income-card-total-amount-wrapper" style={{ backgroundColor: "transparent", cursor: "default" }}>
                                <Select
                                    variant="borderless"
                                    className="swipeable-card-tag"
                                    style={{ fontSize: "12px", width: "fit-content" }}
                                    dropdownStyle={{ width: 230 }}
                                    value={selectedCategory}
                                    onSelect={value => handleChangeCategory([value])}
                                >
                                    {categories.map(category => (
                                        <Select.Option key={category} value={category}>{`${getTagIcon(category, taxProfileType)} ${category}`}</Select.Option>
                                    ))}
                                </Select>

                            </div>

                        </div>
                        {error && (
                            <Alert
                                className="w100"
                                message="Category is required"
                                type="error"
                                showIcon
                                style={{ marginTop: 8 }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="updateTransaction__action-buttons">
                <Button
                    shape="round"
                    type="primary"
                    color="primary"
                    className="w100"
                    onClick={handleUpdate}
                    style={{ height: 60 }}
                    disabled={error}
                >
                    Update
                </Button>

            </div>
        </div>
    );
};

export default UpdateTransaction;
