import { api } from "./connection/api";

const ENDPOINT_PREFIX = "tax-years";

export const taxYearApi = api.injectEndpoints({
    endpoints: build => ({
        getTaxYears: build.mutation({
            query() {
                return {
                    url: `${ENDPOINT_PREFIX}/`,
                    method: "GET",
                };
            },
        }),
    }),
});

export const {
    useGetTaxYearsMutation,
} = taxYearApi;
