import "../../../../../styles/profile/readyForReview.less";
import { useContext, useMemo } from "react";

import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Collapse, Typography } from "antd";
import { AppContext } from "App";
import openLinkIcon from "assets/images/dashboard/open_link_icon.svg";
import pdfIcon from "assets/images/new-questionnaire/documentParse/pdf_icon.svg";

type ReadyForReviewOweTaxProps = {
    currentDocumentYear: string;
    taxLiability: number;
};
export default function ReadyForReviewOweTax({ currentDocumentYear, taxLiability }: ReadyForReviewOweTaxProps) {
    const { isMobile } = useContext(AppContext);
    const date = new Date().toLocaleDateString("en-GB");
    const { Panel } = Collapse;
    const taxLiabilityOwed = useMemo(() => "£" + Math.abs(taxLiability).toFixed(2), [taxLiability]);
    const showFirstContent = (
        <div className="mb20">
            <Typography className="ready-for-review__title">
                {currentDocumentYear} Tax Return
            </Typography>
            <div>
                <div className="ready-for-review__client-details">
                    <div className="ready-for-review__text-black">Status</div>
                    <div className="ready-for-review__value">
                        <div>Submitted</div>
                    </div>
                </div>
                <div className="ready-for-review__client-details">
                    <div className="ready-for-review__text-black">Date</div>
                    <div className="ready-for-review__value">
                        <div>{date}</div>
                    </div>
                </div>
                <div className="ready-for-review__client-details">
                    <div className="ready-for-review__text-black">Total Tax Due</div>
                    <div className="ready-for-review__value">
                        <div>{taxLiabilityOwed}</div>
                    </div>
                </div>
                <div className="ready-for-review__client-details vertical-center">
                    <div className="ready-for-review__text-black-pdf"><img src={pdfIcon} alt="pdf icon" width={"36px"} /> SA302 Form.pdf</div>
                    <div className="ready-for-review__value">
                        <div>Download</div>
                    </div>
                </div>
            </div>
        </div>);

    const showLastContent = (
        <div>
            <div className="mb20">
                <Typography className="ready-for-review__text">
                    You have a <strong>Payment on Account </strong>due. This is an advance payment towards your next year’s tax bill.
                </Typography>
            </div>
            <div className="mb20">
                <Typography className="ready-for-review__text">
                    Payments on Account are required if your tax bill is over £1,000, and they are split into two installments:
                </Typography>
                <Typography className="ready-for-review__text">
                    <li><strong>First Payment on Account:</strong> Due by 31 January 2026, covering half of your estimated next tax bill.</li>
                </Typography>
                <Typography className="ready-for-review__text">
                    <li><strong>Second Payment on Account:</strong> Due by 31 July 2026, covering the remaining half.</li>
                </Typography>
            </div>
            <div className="mb20">
                <Typography className="ready-for-review__text">
                    If you expect your income to be lower next year, you can apply to reduce your Payments on Account.
                </Typography>
            </div>
            <div className="mb20">
                <Typography className="ready-for-review__text-link">
                    <img src={openLinkIcon} alt="open link icon" /><a
                        href="https://www.gov.uk/understand-self-assessment-bill/payments-on-account"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ready-for-review__link"
                    >Learn About Payments on Account</a>
                </Typography>
            </div>
            <div className="mb20">
                <Typography className="ready-for-review__text-link">
                    <img src={openLinkIcon} alt="open link icon" /><a
                        href="https://www.gov.uk/pay-self-assessment-tax-bill"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ready-for-review__link">Pay Your Tax Bill to HMRC</a>
                </Typography>
            </div>
            <div className="mb20">
                <Typography className="ready-for-review__text-link">
                    <img src={openLinkIcon} alt="open link icon" /><a
                        href="https://www.gov.uk/guidance/claim-to-reduce-payments-on-account"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ready-for-review__link">Apply to Reduce Payments on Account</a>
                </Typography>
            </div>
        </div>
    );
    return (
        <div>
            {isMobile ? (
                <><div className="mb20">
                    {showFirstContent}
                </div>   <Collapse expandIcon={({ isActive }) => (isActive ? <UpOutlined /> : <DownOutlined />)} style={{ border: "none", background: "transparent" }}>
                    <Panel className="ready-for-review__panel ready-for-review__title" header="Paying your Tax Bill" key="1" style={{ border: "none", borderBottom: "none", background: "transparent" }}>
                        {showLastContent}
                    </Panel>
                </Collapse></>
            ) : (
                <>
                    {showFirstContent}
                    <Typography className="ready-for-review__title">
                    Paying your Tax Bill
                    </Typography>
                    {showLastContent}
                </>
            )}
        </div>
    );
}
