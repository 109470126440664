/* eslint-disable */
export enum CategoryTitle {
    BankAccountConnection = "Bank Account Connection",
    PersonalInfo = "Personal Info",
    CapitalGains = "Capital Gains",
    CIS = "CIS",
    Dividends = "Dividends",
    Employment = "Employment",
    General = "General",
    InterestIncome = "Interest Income",
    PensionIncome = "Pension Income",
    Rental = "Rental",
    ReviewAndSubmit = "Review & Submit",
    SelfEmployment = "Self Employment",
    StateBenefits = "State Benefits",
}

export enum StepType {
    onboarding = "onboarding",
    questionnaire = "questionnaire",
    bankAccount = "bankAccount",
    categoryDone = "categoryDone",
    payment = "payment",
    review = "review",
    submit = "submit",
}

export enum ConditionType {
    hasReviewableExpensesTransactions = "hasReviewableExpensesTransactions",
    hasReviewableRevenueTransactions = "hasReviewableRevenueTransactions",
    hasUnansweredRequiredQuestions = "hasUnansweredRequiredQuestions",
}

export enum ParentProfileType {
    cis = "cis",
    paye = "paye",
    selfEmployment = "Self Employment",
    rental = "Rental",
    dividends = "Dividends",
    interest = "Interest",
    capitalGainsLosses = "Capital Gains/Losses",
    ventureCapitalScheme = "Venture Capital Scheme",
}

export enum ProfileType {
    cis = "cis",
    paye = "paye",
    selfEmployment = "Self Employment",
    furnishedHolidayLet = "Furnished Holiday Let",
    rentARoom = "Rent-a-Room",
    residentialCommercial = "Residential/Commercial",
    domesticDividends = "Domestic Dividends",
    foreignDividends = "Foreign Dividends",
    interest = "Interest",
    directorDividends = "Director Dividends",
    capitalGain = "Capital Gain",
    capitalLoss = "Capital Loss",
    ventureCapitalScheme = "Venture Capital Scheme",
    none = "",
}

export enum UploadType {
    employeePAYE = "Employee PAYE",
    cisDeductionStatement = "CIS Deduction Statement",
    proofOfForeignDividends = "Proof of Foreign Dividends",
    residentialCommercialPropertyMortgageInterestStatement = "Residential/Commercial Property - Mortgage Interest Statement",
    holidayLetPropertyMortgageInterestStatement = "Holiday-Let Property - Mortgage Interest Statement",
    capitalGainsLossesInvestmentPortfolio = "Capital Gains/Losses - Investment Portfolio",
    capitalGainsLossesSupportingDocuments = "Capital Gains/Losses - Supporting Documents",
    p60EndOfYearCertificate = "P60 End of Year Certificate",
    p45LeavingWorkCertificate = "P45 Leaving Work Certificate",
    p11DBenefitsDocument = "P11D benefits document",
}

export enum QuestionType {
    amountQuestion = "amountQuestion",
    textQuestion = "textQuestion",
    percentageQuestion = "percentageQuestion",
    selectOneIconQuestion = "selectOneIconQuestion",
    selectOneOptionQuestion = "selectOneOptionQuestion",
    selectOneOptionQuestionV2 = "selectOneOptionQuestionV2",
    selectManyQuestion = "selectManyQuestion",
    dateQuestion = "dateQuestion",
    arrayQuestion = "arrayQuestion",
    arrayQuestionV3 = "arrayQuestionV3",
    profileQuestion = "profileQuestion",
    uploadFileQuestion = "uploadFileQuestion",
    cardArrayQuestion = "cardArrayQuestion",
    objectQuestion = "objectQuestion",
    questionsList = "questionsList",
    amountListQuestion = "amountListQuestion",
    textListQuestion = "textListQuestion",
    dateListQuestion = "dateListQuestion",
    profileExpensesNew = "profileExpensesNew",
    profileExpensesSummary = "profileExpensesSummary",
    profileIncomeSummary = "profileIncomeSummary",
    selectProfileTypeQuestion = "selectProfileTypeQuestion",
    primaryIncomeQuestion = "primaryIncomeQuestion",
    expensesList = "expensesList",
    ninUtrQuestion = "ninUtrQuestion",
    documentParseQuestion = "documentParseQuestion",
    selectOneTilesQuestion = "selectOneTilesQuestion",
}

export enum QuestionSubType {
   confirmedTotalGross = "confirmedTotalGross",
   taxAlreadyPaidOnRevenue = "taxAlreadyPaidOnRevenue",
}

export enum PromptModalType {
    agreement = "agreement",
    confirmation = "confirmation",
    info = "info",
}

export enum ClientProgressType {
    ReadyForReview = "readyForReview",
    SubmittedToHMRC = "submittedToHMRC",
    SubmittedByClient = "submittedByClient",
    ReadyForClientSubmission = "readyForClientSubmission",
    PersonalInfoCompleted = "personalInfoCompleted",
    PersonalDetailsStarted = "personalDetailsStarted",
    SelectedTaxDetailSource = "selectedTaxDetailSource",
    TaxReturnCreated = "taxReturnCreated",
}
/* eslint-enable */
