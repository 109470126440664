import { UK_ROUTES_NEW } from "constants/routes-new";

import { useState } from "react";

import ButtonFooterNew from "components/basic/Footer/ButtonFooterNew";
import Navigation from "components/basic/Navigation/Navigation";
import SignUpSectionNew from "components/common/SignUpSectionNew";
import SubmissionBannerNew from "components/common/SubmissionBannerNew";

import FaqsNew from "../../basic/FAQs/FAQsNew";
import LayoutNew from "../../basic/LayoutNew";

const FaqsPageNew = () => {
    const [showButtonFooter, setShowButtonFooter] = useState(false);

    return <LayoutNew>
        <Navigation items={[{
            title: "Home",
            url: UK_ROUTES_NEW.home,
        }, {
            title: "FAQs",
            url: UK_ROUTES_NEW.faqs,
        }]} />
        <FaqsNew setShowButtonFooter={setShowButtonFooter}/>
        <div>
            <SubmissionBannerNew />
        </div>
        <div>
            <SignUpSectionNew />
        </div>
        <ButtonFooterNew showButtonFooter={showButtonFooter}/>
    </LayoutNew>;
};

export default FaqsPageNew;
