import { useEffect, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getNotifications } from "redux/reducers/auth";
import { INotification } from "types/auth";

export const useNotifications = (): {
    userNotifications?: {
        pagination: any,
        notifications: INotification[],
    },
    unseenNotifications: boolean,
    unseenNotificationsIds: string[],
} => {
    const dispatch = useAppDispatch();
    const userNotifications = useAppSelector(state => state.auth.userNotifications);

    useEffect(() => {
        dispatch(getNotifications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { unseenNotifications, unseenNotificationsIds } = useMemo(() => {
        const unseenList = (userNotifications?.notifications || []).filter(n => !n.seen);
        const ids = unseenList.map(n => n._id);
        const unseen = unseenList.length > 0;

        return { unseenNotifications: unseen, unseenNotificationsIds: ids };
    }, [userNotifications]);

    return { userNotifications, unseenNotifications, unseenNotificationsIds };
};
