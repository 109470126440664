
export const UK_ROUTES = {
    home: "/",
    howItWorks: "/#how-it-works",
    whyTaxzap: "/#why-taxzap",
    faqs: "/faqs",
    contact: "/#contact",
    pricing: "/pricing",
    tcs: "/terms-and-conditions",
    privacyPolicy: "/privacy-policy",
    engagement: "/terms-of-engagement",
};
