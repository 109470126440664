import { FC, ChangeEvent, useCallback, useEffect, useRef, useState, useMemo, useContext } from "react";

import { AppContext } from "App";
import errorIcon from "assets/images/new-questionnaire/exclamationmark-circle.svg";
import infoIcon from "assets/images/new-questionnaire/info_icon.svg";
import { isAnswerValid } from "utils/new-questionnaire";

import { Question } from "../../constants/questions";

type PercentageQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: string | undefined) => void;
    initialValue?: any;
};

const PercentageQuestion: FC<PercentageQuestionProps> = ({ data, onAnswer, initialValue }) => {
    const isMobile = useContext(AppContext);
    const [percentage, setPercentage] = useState<string>(initialValue || "");
    const [error, setError] = useState<string | null>(null);
    const spanRef = useRef<HTMLSpanElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const helpText = useMemo(() => data.infoText, [data]);

    // Function to format value as integer percentage
    const formatPercentage = (value: string) => {
        return value.replace(/[^\d]/g, ""); // Allow only digits
    };

    const handlePercentageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        value = formatPercentage(value); // Apply percentage format

        // Ensure the percentage is between 0 and 100
        let numericValue = parseInt(value, 10);
        if (isNaN(numericValue)) {
            numericValue = 0;
        }
        if (numericValue > 100) {
            numericValue = 100;
        }

        const formattedValue = numericValue.toString();
        if (percentage !== formattedValue) {
            setPercentage(formattedValue);
        }

        // Error handling for percentages outside of 0-100 range
        if (numericValue < 0 || numericValue > 100) {
            setError("Please enter a valid percentage between 0 and 100.");
            return;
        } else {
            setError(null);
        }

        if (data.validation) {
            const isValid = isAnswerValid(formattedValue, data.validation.regExp);
            if (!isValid) {
                setError(data.validation.error);
            } else {
                setError(null);
            }
        }
    }, [percentage, data.validation]);

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            if (isMobile) {
                e.currentTarget.blur();
            } else {
                e.preventDefault();
            }
        }
    };

    const handleWrapperClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    useEffect(() => {
        if (error) {
            onAnswer(data.question, undefined);
        } else if (percentage !== initialValue) {
            onAnswer(data.question, percentage);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [percentage, error]);

    useEffect(() => {
        if (spanRef.current) {
            spanRef.current.innerText = percentage || "0";
        }
    }, [percentage]);

    useEffect(() => {
        const focusInput = () => {
            if (inputRef.current) {
                inputRef.current.focus();
                inputRef.current.click();
            }
        };

        const timer = setTimeout(focusInput, 100);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="new-questionnaire__question">
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                {data.question}
            </div>
            <div className="new-questionnaire__question-input-wrapper" style={{ borderBottom: "4px solid #7F43FF" }} onClick={handleWrapperClick}>
                <div className="new-questionnaire__amount-question-input-prefix">%</div>
                <input
                    className="new-questionnaire__amount-question-input"
                    onChange={handlePercentageChange}
                    type="text"
                    inputMode="decimal"
                    pattern="[0-9]*"
                    autoFocus={true}
                    value={percentage}
                    ref={inputRef}
                    style={{
                        width: spanRef.current
                            ? spanRef.current.offsetWidth + 2
                            : "auto",
                    }}
                    onKeyDown={handleKeyDown}
                />
            </div>
            <span ref={spanRef} style={{ visibility: "hidden", position: "absolute", whiteSpace: "pre", fontFamily: "Poppins", fontSize: "24px", fontWeight: 600 }}></span>
            {helpText && (
                <div className="new-questionnaire__info-message">
                    <img src={infoIcon} width={20} alt="info" />
                    <div className="new-questionnaire__info-message-text ml4">
                        {helpText}
                    </div>
                </div>
            )}
            {error && (
                <div className="new-questionnaire__error-message">
                    <img src={errorIcon} width={20} alt="error" />
                    <div className="new-questionnaire__error-message-text ml4">
                        {error}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PercentageQuestion;
