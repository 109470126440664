import { FC } from "react";

import { Collapse, Row, Alert } from "antd";
import Spinner from "components/basic/Spinner/Spinner";
import CategoryFiles from "components/inputs/CategoryFiles";
import { IFile } from "types/files";

const { Panel } = Collapse;

type CategoryProps = {
    requiredDocuments: {
        name: string;
    }[] | undefined,
    files: IFile[],
    uploadFile: (_file: IFile, _category: string, _name: string) => Promise<boolean>
    deleteFile: (_id: string, _category: string) => Promise<boolean>
    getFile: (_url: string, _originalName: string) => Promise<void>,
    allowAdditionalFiles: boolean,
    isLoading: boolean,
    categoryKey: string,
    categoryLabel: string,
    filesInCategory: boolean,
    iconSelected: string,
    icon: string,
};

const Category: FC<CategoryProps> = ({
    requiredDocuments,
    files,
    uploadFile,
    deleteFile,
    getFile,
    allowAdditionalFiles,
    isLoading,
    categoryKey,
    categoryLabel,
    filesInCategory,
    iconSelected,
    icon,
}) => {
    return (
        <Collapse className="document__collapse" expandIconPosition="end">
            <Panel
                header={
                    <div className="document__content-item-header">
                        <span
                            className={`document__content-item-icon-wrapper${
                                filesInCategory ? "-filled" : ""
                            }`}
                        >
                            <img
                                src={filesInCategory ? iconSelected : icon}
                                alt="Category Icon"
                                width={"36px"}
                            />
                        </span>
                        <span
                            className={`document__content-item-header-text${
                                filesInCategory ? "-filled" : ""
                            }`}
                        >
                            {categoryLabel}
                        </span>
                    </div>
                }
                key={categoryKey}
            >
                <Row className="documents__item-row">
                    <Spinner isLoading={isLoading}>
                        {/* Render the CategoryFiles component */}
                        <CategoryFiles
                            requiredDocuments={requiredDocuments}
                            files={files}
                            uploadFile={(file, name) => uploadFile(file, categoryKey, name)}
                            deleteFile={id => deleteFile(id, categoryKey)}
                            getFile={getFile}
                            allowAdditionalFiles={allowAdditionalFiles}
                        />
                        {files.map(file => file.warning && (
                            <Alert
                                key={file._id}
                                style={{
                                    backgroundColor: "#fff3cd",
                                    borderColor: "#ffeeba",
                                    color: "#856404",
                                    padding: "15px",
                                    marginTop: "15px",
                                    marginBottom: "10px",
                                    fontSize: "12px",
                                    fontFamily: "Poppins, sans-serif",
                                }}
                                message={file.warning}
                                type="warning"
                                showIcon
                            />
                        ))}
                    </Spinner>
                </Row>
            </Panel>
        </Collapse>
    );
};

export default Category;
