import "styles/auth.less";
import { useContext, useEffect, useState } from "react";

import { Layout } from "antd";
import { AppContext } from "App";
import loginImage from "assets/images/login-image.png";
import AuthButtonFooter from "components/basic/Footer/AuthButtonFooter";
import Header from "components/basic/Header/Header";
import LottieAnimation from "components/basic/Spinner/Lottie";
import { Link, useNavigate } from "react-router-dom";

import SignIn from "./components/SignIn";

const Auth = () => {
    const { isAuth, isMobile } = useContext(AppContext);
    const navigate = useNavigate();
    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        if (isAuth) {
            const lastPage = sessionStorage.getItem("last-visited-page");
            if (lastPage === "/questionnaire") {
                navigate("/profile");
            } else {
                navigate(lastPage ? lastPage : "/profile");
            }
            sessionStorage.removeItem("last-visited-page");
        }
    }, [isAuth, navigate]);

    useEffect(() => {
        const delay = isMobile ? 200 : 2000;
        const timer = setTimeout(() => {
            setPageLoading(false);
        }, delay);
        return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isAuth)
        return (
            <Layout className="layout">
                <LottieAnimation isLoading={pageLoading} backgroundColor={"white"}/>
                <Header/>
                <div className="auth__container">
                    <div className="auth__content">
                        {!isMobile && <div className="auth__content-left">
                            <h1 className="auth__title">
                                {"Login to"}
                                <br />
                                {"track your return"}
                            </h1>
                            <div className="auth__subtitle">
                                First time using TaxZap?
                                <span>
                                    <Link
                                        to={"/"}
                                        className="auth__link"
                                    >
                                        Start Now!
                                    </Link>
                                </span>
                            </div>
                            <div className="auth__content-left-decorative">
                                <div>
                                    <img
                                        src={loginImage}
                                        className="auth__image"
                                        alt={"tax-zap"}
                                        onLoad={() => {
                                            const timer = setTimeout(() => {
                                                setPageLoading(false);
                                            }, 200);
                                            return () => clearTimeout(timer);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>}
                        <div className="auth__content-right">
                            <SignIn />
                        </div>
                    </div>
                </div>
                <AuthButtonFooter />
            </Layout>
        );

    return (
        <div className="auth__spinner">
            <LottieAnimation isLoading/>
        </div>
    );
};

export default Auth;
