import { useContext } from "react";

import { Typography } from "antd";
import { AppContext } from "App";
import smiley from "assets/images/dashboard/smiley.png";

export default function SubmittedToHmrc() {
    const { isMobile } = useContext(AppContext);
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    return (
        <div className={`client-progress__content${isMobile ? "-mobile" : "-desktop"}`}>
            <div className="mb20">
                <Typography className="client-progress__text">
                        Your tax filing has been submitted to HMRC. If you need any assistance, we’re here to help. Looking forward to see you again next year!
                </Typography>
                <a className="client-progress__text-link" href="https://go.baboost.com/X4ZEW4">
                        Review TaxZap
                </a>
            </div>
            <div className="mb20">
                <Typography className="client-progress__text">
                        If you’ve already provided your bank details, and you are due a refund, HMRC will send directly into your account. follow the step-by-step guide below to check your eligibility and learn how to claim it if applicable.
                </Typography>
                <a className="client-progress__text-link" href={`${REACT_APP_BASE_URL}/uk/tax_return_steps`}>
                        View Step-by-Step Guide
                </a>
            </div>
            <img src={smiley} alt="stage2 icon" width={isMobile ? 120 : 240}/>,
        </div>
    );
}
