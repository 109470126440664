import "styles/transactionsFilters.less";
import React from "react";

import { Tag, Flex } from "antd";
import dayjs from "dayjs"; // Import dayjs for formatting

import { RefinementType } from "../ApprovedRejectedExpenseNew";

interface QuickFilterTagsProps {
    filters: {
        page?: number;
        limit?: number;
        sortBy?: string;
        viewFilter?: string;
        searchQuery?: string;
        account?: string;
        amountRange?: [number, number];
        dateRange?: [string, string];
        [key: string]: any;
    };
    updateFilters: (_updatedFields: Partial<Record<string, any>>, _type: RefinementType) => void;
}

const EXCLUDED_KEYS = ["page", "limit", "sortBy", "viewFilter", "searchQuery", "account"];
const RANGE_KEYS = ["amountRange", "dateRange"];

const DATE_FORMAT = "MMM D, YYYY";

const QuickFilterTags: React.FC<QuickFilterTagsProps> = ({ filters, updateFilters }) => {
    return (
        <Flex wrap="nowrap" gap={2} className="transactionsFilters__mobile-menu">
            {Object.entries(filters).flatMap(([key, value]) => {
                if (EXCLUDED_KEYS.includes(key) || value === null || value === undefined || value === "" || (Array.isArray(value) && value.length === 0)) {
                    return []; // Skip empty arrays to prevent empty tags
                }

                // Handle range values (e.g., amountRange, dateRange)
                if (RANGE_KEYS.includes(key) && Array.isArray(value)) {
                    const [min, max] = value;

                    if (key === "dateRange") {
                        // Format date values
                        const formattedMin = min ? dayjs(min).format(DATE_FORMAT) : "Any";
                        const formattedMax = max ? dayjs(max).format(DATE_FORMAT) : "Any";

                        // Show chip only if at least one value is set
                        if (!(min || max)) return [];

                        return (
                            <Tag
                                className="transactionsFilters__tag pointer ph10 pv2"
                                color="black"
                                key={key}
                                closable
                                onClose={() => {
                                    const newFilters = { ...filters, dateRange: [null, null], page: 1 };
                                    updateFilters(newFilters, RefinementType.filter);
                                }}>
                                {formattedMin} ➝ {formattedMax}
                            </Tag>
                        );
                    }

                    // Handle amount range normally
                    if (!(min > 0 || max !== null)) return [];

                    return (
                        <Tag
                            className="transactionsFilters__tag pointer ph10 pv2"
                            color="black"
                            key={key}
                            closable
                            onClose={() => {
                                const newFilters = { ...filters, amountRange: [0, null], page: 1 };
                                updateFilters(newFilters, RefinementType.filter);
                            }}>
                            £{min ?? 0} ➝ £{max ?? "∞"}
                        </Tag>
                    );
                }

                // Handle general arrays (e.g., category, account) - each item gets its own tag
                if (Array.isArray(value)) {
                    return value.map(item => (
                        <Tag
                            className="transactionsFilters__tag pointer ph10 pv2"
                            key={`${key}-${item}`}
                            color="black"
                            closable
                            onClose={() => {
                                const newFilters = { ...filters };
                                newFilters[key] = value.filter(v => v !== item);
                                newFilters.page = 1;
                                updateFilters(newFilters, RefinementType.filter);
                            }}>
                            {item}
                        </Tag>
                    ));
                }

                // Handle other valid values
                return (
                    <Tag
                        className="transactionsFilters__tag pointer ph10 pv2"
                        color="black"
                        key={key}
                        closable
                        onClose={() => {
                            const newFilters = { ...filters, page: 1 };
                            updateFilters(newFilters, RefinementType.filter);
                        }}>
                        {value}
                    </Tag>
                );
            })}
        </Flex>
    );
};

export default QuickFilterTags;
