import { FC, useContext, useEffect } from "react";

import { Typography } from "antd";
import { AppContext } from "App";
import pricingFrame from "assets/images/pricing-frame-new.png";
import pricingMobile from "assets/images/pricing-mobile-new.png";
import StartButtonNew from "components/basic/StartButtonNew";

interface HeroCallProps {
    setShowButtonFooter: (_value: boolean) => void;
}

const HeroCall: FC<HeroCallProps> = ({ setShowButtonFooter }) => {
    const { isMobile, maxWidth } = useContext(AppContext);

    useEffect(() => {
        const handleScroll = () => {
            const startButton = document.getElementById("pricingStartButton");
            if (startButton) {
                const rect = startButton.getBoundingClientRect();
                const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
                setShowButtonFooter(!isInView);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: isMobile ? "column-reverse" : "row",
                justifyContent: "center",
                alignItems: "center",
                gap: isMobile ? "20px" : "120px",
                padding: isMobile ? "40px 20px 64px" : "0 20px 100px",
                maxWidth: maxWidth,
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                }}
            >
                <Typography
                    style={{ lineHeight: isMobile ? "36px" : "60px",
                        fontSize: isMobile ? "28px" : "48px" }}
                    className={"uk-slogan-new"}
                >
                    <span className={"uk-sub-slogan-line-through"}>
                        Tax Returns Made Easy
                    </span>{" "}
                    –
                    <span className={"uk-sub-slogan-purple"}
                        style={{ fontSize: isMobile ? "28px" : "48px", lineHeight: isMobile ? "36px" : "60px" }}>
                        Swipe
                    </span>{" "}
                    & claim your tax <span className={"uk-sub-slogan-green"} style={{ fontSize: isMobile ? "28px" : "48px", lineHeight: isMobile ? "36px" : "60px" }}>
                        refund
                    </span>{" "} today.
                </Typography>
                <Typography
                    style={{ fontSize: isMobile ? "16px" : "20px" }}
                    className="uk-slogan-description"
                >
                    Join over <span className="bold">125,000</span> satisfied clients who trust TaxZap for their tax returns. With our simple, transparent pricing of just <span className="bold">£179</span>, we make the process stress-free and easy. It's that simple. Get started today!
                </Typography>
                <div style={{ width: "100%" }} id="pricingStartButton">
                    <StartButtonNew />
                </div>
            </div>
            <div>
                <img
                    alt="phone with tax agent on screen"
                    src={isMobile ? pricingMobile : pricingFrame}
                    height={isMobile ? 300 : 490}
                    width={isMobile ? 335 : 560}
                />
            </div>
        </div>
    );
};

export default HeroCall;
