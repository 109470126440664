
import { useEffect } from "react";

import { useLocalStorage } from "hooks/helpers/useLocalStorage";
import { IUtmParameters } from "types/potential-clients";

const extractUtmParameters = (url: string): IUtmParameters => {
    const queryString = url.split("?")[1];
    const searchParams = new URLSearchParams(queryString);
    const utmParameters: IUtmParameters = {};

    const allowedFields: string[] = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];
    searchParams.forEach((value, key) => {
        if (allowedFields.includes(key)) {
            utmParameters[key] = value;
        }
    });

    return utmParameters;
};

const UtmHandler = () => {
    const utmParameters = extractUtmParameters(window.location.href);
    const [, setLocalUtmParamData] = useLocalStorage<IUtmParameters | null>(
        "utmParamData",
        null
    );

    useEffect(() => {
        if (Object.keys(utmParameters).length > 0) {
            setLocalUtmParamData(utmParameters);
        }
    }, [ setLocalUtmParamData, utmParameters ]);

    return null;
};

export default UtmHandler;
