import { FC, useContext, useEffect, useRef, useState } from "react";

import RevolutCheckout, { FieldStatus, RevolutCheckoutCardField } from "@revolut/checkout";
import { Address, CountryCode, SubmitMeta } from "@revolut/checkout/types/types";
import { Checkbox } from "antd";
import { AppContext } from "App";
import AmexIcon from "assets/images/paywall/amex-icon.svg";
import DinersClubIcon from "assets/images/paywall/icon-diners-club.svg";
import DiscoverIcon from "assets/images/paywall/icon_discover.svg";
import MastercardIcon from "assets/images/paywall/master-card-icon.svg";
import VisaIcon from "assets/images/paywall/visa-icon.svg";
import lookup from "country-code-lookup";
import { useUser } from "hooks/common/useUser";
import ReactMetaPixel from "react-facebook-pixel";
import { useLocation } from "react-router-dom";
import { useGetOrderMutation, usePayOrderMutation } from "services/payments";
import { logError, logMessage } from "utils/sentry";

import AppleGooglePayWidget from "./AppleGooglePayWidget";
import { CLIENT_PAID } from "../../../../../../constants/events";
import { IPayOrderRequest } from "../../../../../../types/payments";
import sendGTMEvent from "../../../../../../utils/gtm-utils";
import { NewRelic } from "../../../../../../utils/NewRelic";
import sendGaEvent from "../../../../../../utils/react-ga";
import { isPostcodeValid } from "../../../../../../utils/validation";

type RevolutPaymentComponentProps = {
    setGenericPaymentFailure: (_failed: boolean) => void;
    setInsufficientFundsPaymentFailure: (_failed: boolean) => void;
    setShowPaymentSuccessModal: (_successful: boolean) => void;
    setAmount: (_amount: number) => void;
    amount: number
};

const RevolutPaymentComponent: FC<RevolutPaymentComponentProps> = ({
    setGenericPaymentFailure,
    setInsufficientFundsPaymentFailure,
    setShowPaymentSuccessModal,
    setAmount,
    amount,
}) => {
    const { isMobile } = useContext(AppContext);
    const location = useLocation();
    const isHomePage = location.pathname.includes("profile");
    const revolutEnvironment = process.env.REACT_APP_ENV === "production" ? "prod" : "sandbox";
    const { user } = useUser();
    const cardDetailsRef = useRef<HTMLDivElement>(null);

    const [isValidName, setIsValidName] = useState(true);
    const [postcodeValid, setPostcodeValid] = useState(true);
    const [isValidAddress, setIsValidAddress] = useState(true);
    const [isCardErrors, setIsCardErrors] = useState(false);

    const [enterBusinessAddress, setEnterBusinessAddress] = useState(false);
    const [cardHoldersName, setCardHoldersName] = useState("");
    const [address1, setAddress1] = useState("");
    const [country, setCountry] = useState("");
    const [townCity, setTownCity] = useState("");
    const [postcode, setPostcode] = useState("");
    const [orderToken, setOrderToken] = useState<string | null>(null);
    const [cardField, setCardField] = useState<RevolutCheckoutCardField | null>(null);
    const [buttonIsDisabled, setButtonIsDisabled] = useState<boolean>(false);

    const [getOrder] = useGetOrderMutation();
    const [payOrder] = usePayOrderMutation();

    const handleContinue = () => {
        if (orderToken) {
            const request: IPayOrderRequest = { orderToken: orderToken };
            payOrder(request);
        }
    };

    useEffect(() => {
        NewRelic.createCustomEvent("PaymentPageRendered", { user: user });
    }, [user]);

    const handleCheckBoxClicked = (event: any) => {
        setEnterBusinessAddress(event.target.checked);
        setTimeout(() => {
            window.scrollTo({
                top: document.body.scrollHeight, // Scroll to the height of the body
                behavior: "smooth", // Smooth scrolling
            });
        }, 50);
    };

    const handleAddressChange = (event: any, setAddressField: (_input: string) => void) => {
        setAddressField(event.target.value);
        setIsValidAddress(true);
    };

    const handleNameChange = (event: any) => {
        setCardHoldersName(event.target.value);
        setButtonIsDisabled(false);
        setIsValidName(true);
    };

    const handlePostcodeChange = (event: any) => {
        setPostcode(event.target.value);
        setPostcodeValid(true);
    };

    const checkNameValidity = () => {
        const words = cardHoldersName.trim().split(/\s+/);
        return setIsValidName(words.length >= 2);
    };

    const checkPostCodeValidity = () => {
        const isValid = isPostcodeValid(postcode);
        return setPostcodeValid(isValid);
    };

    const checkAddressIsValid = () => {
        return postcode && address1 && townCity && country;
    };

    const getCountryCode = (country: string | undefined) => {
        let countryCode: string | undefined;
        if (country) {
            if (country.toLowerCase().match(/england|wales|scotland|northern ireland/)) {
                countryCode = "GB";
            } else {
                countryCode = lookup.byCountry(country)?.iso2;
            }
        }

        NewRelic.createCustomEvent("countryCodeLookup", { countryCode: countryCode, country: country, user: user?.id });

        return countryCode ? countryCode as CountryCode : "GB"; //if we can't get the code, lets assume its UK
    };

    const getBillingAddress = () => {
        let address: Address;
        if (enterBusinessAddress) {
            address = {
                countryCode: getCountryCode(country) as CountryCode,
                postcode: postcode,
                city: townCity,
                streetLine1: address1,
            };
        } else {
            address = {
                countryCode: getCountryCode(user?.address?.country),
                postcode: user?.address?.postcode!,
                city: user?.address.city,
                streetLine1: user?.address.address1,
                streetLine2: user?.address.address2,
            };
        }
        return address;
    };

    const handleSubmit = () => {
        NewRelic.createCustomEvent("payButtonHit", { user: user?.id });
        if (!cardHoldersName && isMobile) {
            window.scrollTo({
                top: document.body.scrollHeight, // Scroll to the height of the body
                behavior: "smooth", // Smooth scrolling
            });
        } else if (enterBusinessAddress && !checkAddressIsValid()) {
            setIsValidAddress(false);
        } else if (isValidName && cardField) {
            setButtonIsDisabled(true);
            let meta: SubmitMeta;
            if (user?.address?.postcode) {
                meta = {
                    name: cardHoldersName,
                    email: user?.info.email,
                    phone: user?.info.phone,
                    billingAddress: getBillingAddress(),
                };
            } else {
                meta = {
                    name: cardHoldersName,
                    email: user?.info.email,
                    phone: user?.info.phone,
                };
            }
            cardField.submit(meta);
        }
    };

    useEffect(() => {
        if (!enterBusinessAddress) {
            setIsValidAddress(true);
        }
        setButtonIsDisabled(!isValidAddress);
    }, [isValidAddress, enterBusinessAddress]);

    const commonStyles = {
        fontWeight: "400",
        fontcolor: "#737373",
        color: "#737373",
        textDecoration: "none",
        padding: "10px 0 10px 0",
        margin: "10px 0 10px 0",
        fontFamily: "Poppins, sans-serif",
        borderRadius: "5px",
        fontSize: "14px",
    };

    const handlePaymentSuccess = (orderToken: string) => {
        logMessage("Payment successful");
        const request: IPayOrderRequest = { orderToken };
        payOrder(request);
        sendGaEvent(CLIENT_PAID);
        sendGTMEvent({
            event: "purchase",
            user_data: {
                order_id: orderToken,
                email_address: user?.info?.email,
                phone_number: user?.info?.phone,
            },
            ecommerce: {
                value: amount,
                currency: "GBP",
                items: [{ item_id: "UK_SUBMISSION_FEE" }],
            },
        });
        NewRelic.createCustomEvent("paymentSuccess", { orderToken, user: user?.id, amount });
        process.env.REACT_APP_META_PIXEL_ID && ReactMetaPixel.fbq("track", "clientPaid");
        setShowPaymentSuccessModal(true);
    };

    const handlePaymentFailure = (orderToken: string, errorMessage: string) => {
        logError("Payment failed", {
            error: errorMessage,
            component: "RevolutPaymentComponent",
            orderToken,
        });
        NewRelic.createCustomEvent("paymentFailure", {
            orderToken,
            error: errorMessage,
            user: user?.id,
        });
        if (errorMessage === "Insufficient funds. Please top up your account or use another card") {
            setInsufficientFundsPaymentFailure(true);
        } else {
            setGenericPaymentFailure(true);
        }
    };

    useEffect(() => {
        getOrder().unwrap().then(order => {
            setOrderToken(order.orderToken);
            const amount = order.amountInPence / 100;
            setAmount(amount);
            if (amount > 0) {
                if (cardDetailsRef.current != null) {
                    RevolutCheckout(order.orderToken, revolutEnvironment).then(instance => {
                        const cardField = instance.createCardField({
                            target: cardDetailsRef.current!,
                            hidePostcodeField: true,
                            onStatusChange(status: FieldStatus) {
                                setButtonIsDisabled(false);
                                if (status.focused || status.completed || status.empty || status.autofilled) {
                                    setIsCardErrors(false);
                                } else {
                                    setIsCardErrors(true);
                                }
                            },
                            onSuccess() {
                                handlePaymentSuccess(order.orderToken);
                            },
                            onError(error) {
                                handlePaymentFailure(order.orderToken, error.message);
                            },
                            styles: {
                                default: {
                                    ...commonStyles,
                                },
                                focused: {
                                    ...commonStyles,
                                    border: "none",
                                },
                                invalid: {
                                    ...commonStyles,
                                },
                            },
                        });
                        setCardField(cardField);
                    }
                    );
                }
            }
        });
        // eslint-disable-next-line
    }, []);

    if (amount <= 0) {
        return (<div className="no-payment-continue-button-container">
            <button className={`no-payment-continue-button${isMobile ? "-mobile" : "-desktop"} mt60`}
                onClick={handleContinue}>
                Continue
            </button>
        </div>);
    }

    return (
        <div>
            <div className="revolut-apple-google-pay-container-background">
                <span className="revolut-apple-google-pay-title">
                    Express Checkout
                </span>
                <div className="revolut-apple-google-pay-container">
                    <AppleGooglePayWidget amountInPence={amount * 100} handlePaymentFailure={handlePaymentFailure} handlePaymentSuccess={handlePaymentSuccess} orderToken={orderToken} revolutEnvironment={revolutEnvironment} platform="google" />
                    <AppleGooglePayWidget amountInPence={amount * 100} handlePaymentFailure={handlePaymentFailure} handlePaymentSuccess={handlePaymentSuccess} orderToken={orderToken} revolutEnvironment={revolutEnvironment} platform="apple" />
                </div>
            </div>
            <div className={`revolut-payment-outer-container${isMobile ? "-mobile" : "-desktop"}`} data-testid="revolut-payment-component">
                <div className="taLeft fs16 fc444 m5">
                    <span className="fw600 fc000000">Payment</span>
                    <br/>
                    {!isMobile && "All transactions are secure and encrypted"}
                </div>
                <div className={`revolut-inner-container-div${isMobile ? "-mobile" : "-desktop"}`}>
                    <div className={`revolut-inner-container-header${isMobile ? "-mobile" : "-desktop"}`}>
                        <span className="p10 fw500"> Credit Card </span>
                        <div className="p10 fs14 fw100 fc737373 vertical-center">
                            <img src={VisaIcon} alt="Visa Icon" className="cardbrand-logos"/>
                            <img src={MastercardIcon} alt="Mastercard Icon" className="cardbrand-logos"/>
                            <img src={DiscoverIcon} alt="Discover Icon" className="cardbrand-logos"/>
                            <img src={AmexIcon} alt="Amex Icon" className="cardbrand-logos"/>
                            <img src={DinersClubIcon} alt="Diners Club Icon" className="cardbrand-logos"/>
                            <span className="ml6">and more...</span>
                        </div>
                    </div>
                    <input type="text" placeholder="Name On Card" onChange={handleNameChange} value={cardHoldersName} onBlur={checkNameValidity}
                        className={`revolut-payment-component__user-input${isMobile ? "-mobile" : "-desktop"} ${isValidName ? "revolut-payment-component__border-none" : "revolut-payment-component__error-boundary"}`}/>
                    {!isValidName && <span className="revolut-payment-component__error-message">Please enter both a first and last name</span> }
                    <div id="revolut-card-field-container" ref={cardDetailsRef}
                        className={`revolut-target-container${isMobile ? "-mobile" : "-desktop"} ${!isCardErrors ? "revolut-payment-component__border-none" : "revolut-payment-component__error-boundary"}`}>
                    </div>
                    {isCardErrors && <div className="revolut-payment-component__error-message">Please ensure card data is correct</div>}
                    {enterBusinessAddress &&
                    <div>
                        <input type="text" placeholder="Address"
                            onChange={(event: any) => handleAddressChange(event, setAddress1)} value={address1}
                            className={`revolut-payment-component__user-input${isMobile ? "-mobile" : "-desktop"} ${isValidAddress ? "revolut-payment-component__border-none" : "revolut-payment-component__error-boundary"}`}/>
                        <input type="text" placeholder="Town/City" onChange={(event: any) => handleAddressChange(event, setTownCity)} value={townCity}
                            className={`revolut-payment-component__user-input${isMobile ? "-mobile" : "-desktop"} ${isValidAddress ? "revolut-payment-component__border-none" : "revolut-payment-component__error-boundary"}`}/>
                        <input type="text" placeholder="Country" onChange={(event: any) => handleAddressChange(event, setCountry)} value={country}
                            className={`revolut-payment-component__user-input${isMobile ? "-mobile" : "-desktop"} ${isValidAddress ? "revolut-payment-component__border-none" : "revolut-payment-component__error-boundary"}`}/>
                        <input type="text" placeholder="Postcode" onChange={handlePostcodeChange} value={postcode} onBlur={checkPostCodeValidity}
                            className={`revolut-payment-component__user-input${isMobile ? "-mobile" : "-desktop"} ${(isValidAddress && postcodeValid) ? "revolut-payment-component__border-none" : "revolut-payment-component__error-boundary"}`}/>
                        {!postcodeValid && <div className="revolut-payment-component__error-message mt6">Please enter a valid Postcode (Ex: W1A1HQ)</div> }
                        {!isValidAddress && <div className="revolut-payment-component__error-message mt6">Please make sure highlighted address fields are populated correctly</div> }
                    </div>}
                    <Checkbox className="ml10 mt6 mb6" onChange={handleCheckBoxClicked}>
                    Billing address is different to provided address
                    </Checkbox>
                </div>
                <div
                    className={`revolut-payment-component__pay-button-div${isMobile ? "-mobile" : "-desktop"} ${isHomePage && isMobile ? "bottom-90" : "bottom-20"}`}>
                    <button id="button-submit" type="button" onClick={handleSubmit}
                        className={`payment-button${isMobile ? "-mobile" : "-desktop"} ${buttonIsDisabled ? "bg-secondary-light " : "bg-secondary"}`}
                        disabled={amount === 0 || buttonIsDisabled}>
                            Pay now
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RevolutPaymentComponent;
