import "styles/profile/history.less";

import { Dispatch, SetStateAction, useContext, useEffect, useMemo } from "react";

import { Typography, Collapse } from "antd";
import { AppContext } from "App";
import DownChevron from "assets/images/profile/dropdown-down.svg";
import UpChevron from "assets/images/profile/dropdown-up.svg";
import SmallStatus from "components/common/SmallStatus";
import { IUser } from "types/auth";
import { formatDate } from "utils/strings";

import Document from "./Document";

const { Panel } = Collapse;

type DocumentsProps = {
    user: IUser;
    setPageLoading: Dispatch<SetStateAction<boolean>>
};

const customExpandIcon = (panelProps: any) => {
    if (panelProps.isActive) {
        return <img className="history-chevron" src={UpChevron} width={24} alt="Up Chevron" />;
    } else {
        return <img className="history-chevron" src={DownChevron} width={24} alt="Down Chevron" />;
    }
};

export default function Documents({ user, setPageLoading }: DocumentsProps) {
    const { isMobile } = useContext(AppContext);
    const documents = useMemo(() => [...user.documents].reverse(), [user.documents]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setPageLoading(false);
        }, 200);
        return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="profile__content">
            {!isMobile && <div className="profile__content-header">
                <Typography className="profile__content-heading" style={{ width: "345px" }}>
                    Submission History
                </Typography>
            </div>}
            <div className="history__content">
                {documents.map(document => (
                    <div key={document.id} className="history__content-item">
                        <Collapse className="history__collapse" expandIcon={customExpandIcon}>
                            <Panel
                                header={
                                    <div className="history__content-item-header">
                                        {`Submission ${formatDate(document.createdAt)}`}
                                        <SmallStatus status={document.status} />
                                    </div>
                                }
                                key={document.id}
                            >
                                <Document
                                    key={document.id}
                                    document={document}
                                    user={user}
                                />
                            </Panel>
                        </Collapse>
                    </div>
                ))}
            </div>
        </div>
    );
}
