import "styles/installModal.less";

import React, { useState, useEffect, Dispatch, SetStateAction } from "react";

import { Modal } from "antd";
import addSquare from "assets/images/add-square.svg";
import rocket from "assets/images/rocket.svg";
import shareIos from "assets/images/share-ios.svg";
import { useLocalStorage } from "hooks/helpers/useLocalStorage";

let deferredPrompt: any;

interface InstallModalProps {
    shouldInstall: boolean;
    setShouldInstall: Dispatch<SetStateAction<boolean>>;
}

const InstallModal: React.FC<InstallModalProps> = ({
    shouldInstall,
    setShouldInstall,
}) => {
    const [visible, setVisible] = useState(false);
    const [, setInstallChosen] = useLocalStorage<boolean | null>(
        "installChoice",
        null
    );
    const isiOS13AndUp = /OS (13|14|15|16|17|18)/.test(
        window.navigator.userAgent
    );

    useEffect(() => {
        if (shouldInstall && deferredPrompt) {
            setVisible(true);
        }
    }, [shouldInstall]);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e: Event) => {
            e.preventDefault();
            deferredPrompt = e;
        };

        window.addEventListener(
            "beforeinstallprompt",
            handleBeforeInstallPrompt
        );

        return () => {
            window.removeEventListener(
                "beforeinstallprompt",
                handleBeforeInstallPrompt
            );
        };
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("User accepted the install prompt");
                } else {
                    console.log("User dismissed the install prompt");
                }
                deferredPrompt = null;
            });
        }
        setVisible(false);
        setShouldInstall(false);
        setInstallChosen(true);
    };

    const handleCancel = () => {
        setVisible(false);
        setShouldInstall(false);
        setInstallChosen(true);
    };

    return (
        <>
            {isiOS13AndUp ? (
                shouldInstall && (
                    <Modal
                        open={true}
                        closable={false}
                        className="install-modal"
                        onCancel={handleCancel}
                        width={335}
                        footer={[]}
                        // maskStyle={{
                        //     backgroundColor: "rgba(0, 0, 0, 0.45) !important",
                        // }}
                    >
                        <div className="install-modal__content">
                            <img src={rocket} alt="rocket" />
                            <h3 className="install-modal__title">
                                Add TaxZap to Your Home Screen
                            </h3>
                            <p className="install-modal__text">
                                To install the <strong>Taxzap</strong> App on
                                your iOS device, just follow these easy steps:
                            </p>
                            <ol className="install-modal__instructions">
                                <li>
                                    <img src={shareIos} alt="share" width={24} className="icon"/>
                                    Find and tap the <strong>
                                        'Share'
                                    </strong>{" "}
                                    icon in your browser.
                                </li>
                                <li>
                                    <img src={addSquare} alt="add" width={24} className="icon"/>
                                    Select <strong>
                                        'Add to Home Screen'
                                    </strong>{" "}
                                    from the options.
                                </li>
                            </ol>
                            <p className="install-modal__text">
                                Receive instant notifications the moment your
                                refund is ready for transfer!
                            </p>
                        </div>
                        <div className="install-modal__footer">
                            <div
                                onClick={handleInstallClick}
                                className="install-modal__got-it-button"
                            >
                                Got It!
                            </div>
                        </div>
                    </Modal>
                )
            ) : (
                <Modal
                    open={visible}
                    closable={false}
                    className="install-modal"
                    onCancel={handleCancel}
                    width={335}
                    footer={[]}
                    // maskStyle={{
                    //     backgroundColor: "rgba(0, 0, 0, 0.45) !important",
                    // }}
                >
                    <div className="install-modal__content">
                        <img src={rocket} alt="rocket" />
                        <h3 className="install-modal__title">
                            Download the Taxzap App & Speed Up
                        </h3>
                        <p className="install-modal__text">
                            Receive instant notifications the moment your refund
                            is ready for transfer!
                        </p>
                    </div>
                    <div className="install-modal__footer">
                        <div className="install-modal__close-button">
                            <span onClick={handleCancel}>Close</span>
                        </div>
                        <div
                            onClick={handleInstallClick}
                            className="install-modal__install-button"
                        >
                            Download App
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default InstallModal;
