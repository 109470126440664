export interface RowContent {
    text: string
}
export interface PriceTableContent {
    category: string
    price: string
    priceSuperScript: string
    priceSubScript: string
    rows: RowContent[]
    isAvailable: boolean
}

export const priceTableData: PriceTableContent[] = [
    {
        category: "SELF ASSESSMENT TAX RETURN",
        price: "£179",
        priceSuperScript: "/return",
        priceSubScript: "(including VAT)",
        rows: [
            { text: "Automatic expense detection" },
            { text: "Direct submission to HMRC" },
            { text: "Interest, Dividends SA100" },
            { text: "Pension & benefits SA100" },
            { text: "Employment SA102" },
            { text: "Self-employment SA103" },
            { text: "Rental Income SA105" },
            { text: "Capital Gains SA108" },
            { text: "State benefits" },
        ],
        isAvailable: true,
    },
];
