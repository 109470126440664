import "styles/nameCircle.less";

import { FC } from "react";

interface NameCircleProps {
  firstName?: string;
  lastName?: string;
}

const NewQuestionnaireNameCircle: FC<NameCircleProps> = ({ firstName, lastName }) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : "";
    const lastInitial = lastName ? lastName[0].toUpperCase() : "";

    return (
        <div>
            <div className="name-circle">
                {firstInitial}
                {lastInitial}
            </div>
        </div>
    );
};

export default NewQuestionnaireNameCircle;
