import { Dispatch } from "@reduxjs/toolkit";
import { CategoryTitle, QuestionType, StepType } from "components/pages/NewQuestionnaire/enums/enum";
import { setAnswers } from "redux/reducers/answers";
import { setArrayItemAnswers, setArrayItemQuestions, setInnerArrayItemAnswers, setInnerArrayItemQuestions, setIsEditingInnerArrayItem, setIsEditingItem } from "redux/reducers/arrayQuestion";
import { setCurrentProfile, setCurrentProfileAnswers, setIsEditingProfile } from "redux/reducers/taxProfile";

import { checkDepends, formatAnswers } from "./new-questionnaire";
import { Card, categories, Question, Step } from "../components/pages/NewQuestionnaire/constants/questions";
import { next, previous, setCurrentArrayQuestionIndex, setCurrentInnerArrayQuestionIndex } from "../redux/reducers/navigation";
import { RootState } from "../redux/store";

// ------------------------------ Utility Functions ------------------------------ //

export const findNextCategory = (currentCategory: number, navigationItems: string[]): number | null => {
    let nextCategory = currentCategory + 1;

    while (nextCategory < categories.length) {
        if (navigationItems.includes(categories[nextCategory].title)) {
            return nextCategory;
        }
        nextCategory++;
    }

    return null;
};

export const sortNavigationItems = (navigationItems: string[]): string[] => {
    const allCategories = categories.map(category => category.title);

    return [...navigationItems]
        .sort((a, b) => {
            const indexA = allCategories.indexOf(a as CategoryTitle);
            const indexB = allCategories.indexOf(b as CategoryTitle);
            return indexA - indexB;
        });
};

const clearCardsAnswers = (categoryTitle: string, answers: any, item: any) => {
    const newAnswers = {
        ...answers,
        [categoryTitle]: { ...answers[categoryTitle] },
    };

    if (newAnswers[categoryTitle]) {
        item?.cards?.forEach((card: Card) => {
            if (answers[categoryTitle] && answers[categoryTitle][card.question] !== undefined) {
                newAnswers[categoryTitle][card.question] = undefined;
            }
        });
    }

    return newAnswers;
};

const clearStepQuestionsAnswers = (categoryTitle: string, answers: any, item: any) => {
    const newAnswers = {
        ...answers,
        [categoryTitle]: { ...answers[categoryTitle] },
    };

    if (newAnswers[categoryTitle]) {
        item?.questions?.forEach((question: Question) => {
            if (answers[categoryTitle] && answers[categoryTitle][question.question] !== undefined) {
                newAnswers[categoryTitle][question.question] = undefined;
            }
        });
    }

    return newAnswers;
};

const getRelevantAnswers = (categoryTitle: string, answers: any) => {
    if (categoryTitle === CategoryTitle.ReviewAndSubmit) return {};
    return answers[categoryTitle];
};

const hasValidQuestionsOrCards = (step: any, answers: any) => {
    return (
        (step.cards && step.cards.some((card: Card) => !card.depend || checkDepends(card.depend, answers))) ||
        (step.questions && step.questions.some((question: Question) => !question.depend || checkDepends(question.depend, answers)))
    );
};

const meetsDependencies = (question: Question, answers: Record<string, any>) => {
    if (question.type === QuestionType.questionsList) {
        return question?.questions?.some(subQuestion => {
            if (subQuestion.depend?.length) {
                return checkDepends(subQuestion.depend, answers);
            }
            return true;
        });
    }
    if (!question?.depend || question.depend.length === 0) return true;
    return checkDepends(question.depend, answers);
};

// ------------------------------ Next Navigation ------------------------------ //

const handleProfileQuestionsOnNext = (
    question: Question,
    state: RootState,
    dispatch: Dispatch,
    currentCategory: number,
    currentStep: number,
    currentQuestion: number,
    currentProfileQuestion: number
) => {
    if (!state.taxProfile.isEditingProfile || !question?.questions || question.questions.length === 0) {
        return false; // Not a profile question or editing is disabled
    }

    let nextProfileIndex = currentProfileQuestion + 1;
    const totalQuestions = question.questions.length;
    let foundValidQuestion = false; // ✅ Track if we find a valid question

    while (nextProfileIndex < totalQuestions) {
        const nestedQuestion: Question = question.questions[nextProfileIndex];
        if (!state.taxProfile.currentProfile) return true;
        const formattedProfileAnswers = formatAnswers(state.taxProfile.currentProfileAnswers);

        // ✅ Check dependencies
        if (nestedQuestion.depend && !checkDepends(nestedQuestion.depend, formattedProfileAnswers)) {
            nextProfileIndex++;
            continue;
        }

        // ✅ Handle dynamic options logic
        if (nestedQuestion.type === QuestionType.selectOneOptionQuestionV2 && nestedQuestion.dynamicOptions) {
            const { depend, options } = nestedQuestion.dynamicOptions;
            let currentOptions = [];
            let dependencyMatched = false;

            for (const dependency of depend) {
                const dependencyAnswer = formattedProfileAnswers[dependency.question];

                if (dependencyAnswer && options[dependencyAnswer]) {
                    currentOptions = options[dependencyAnswer];
                    dependencyMatched = true;
                    break;
                }
            }

            if (!dependencyMatched || !Array.isArray(currentOptions) || currentOptions.length === 0) {
                nextProfileIndex++;
                continue;
            }
        }

        // ✅ Found a valid nested question, move to it
        dispatch(next({
            nextCategory: currentCategory,
            nextStep: currentStep,
            nextQuestion: currentQuestion,
            nextProfileQuestion: nextProfileIndex,
        }));

        foundValidQuestion = true;
        return true; // Stop further processing since a valid question was found
    }

    // ✅ If NO valid nested questions were found, execute final reset logic
    if (!foundValidQuestion) {
        dispatch(next({
            nextCategory: currentCategory,
            nextStep: currentStep,
            nextQuestion: currentQuestion,
            nextProfileQuestion: 0, // Reset profile index
        }));

        if (state.taxProfile.isEditingProfile) {
            dispatch(setIsEditingProfile(false));
            dispatch(setCurrentProfile(undefined));
            dispatch(setCurrentProfileAnswers([]));
        }
    }

    return true; // Indicate profile question handling was performed
};

export const findAndSetProfileNext = (state: RootState, dispatch: Dispatch) => {
    console.log("//---------------------findAndSetProfileNext---------------------//");

    let { currentCategory, currentStep, currentQuestion, currentProfileQuestion } = state.navigation;
    const { answers } = state.answers;

    console.log("🔵 Current:", { currentCategory, currentStep, currentQuestion, currentProfileQuestion });

    while (currentCategory < categories.length) {
        // ✅ Keep skipping until we find a valid category in navigationItems
        while (currentCategory < categories.length && !state.navigation.navigationItems.includes(categories[currentCategory].title)) {
            console.log(`⏩ Skipping Category: ${categories[currentCategory].title} (Not in navigationItems)`);
            currentCategory++;
            currentStep = 0;
            currentQuestion = 0;
            currentProfileQuestion = 0;
        }

        // ✅ If all categories are invalid, stop execution
        if (currentCategory >= categories.length) {
            console.warn("⚠️ No valid next category found. Staying in place.");
            return;
        }

        const category = categories[currentCategory];
        console.log(`✅ Entering Category: ${category.title}`);

        while (currentStep < category.steps.length) {
            const step = category.steps[currentStep];

            // // ✅ Clear answers for skipped steps
            let relevantAnswers = getRelevantAnswers(category.title, answers);

            // ✅ If step should be skipped due to dependencies, move to the next step
            if (step.depend && !checkDepends(step.depend, relevantAnswers)) {
                console.log(`⏩ Clear answers for skipped steps: ${step.title} (Dependencies not met)`);
                const updatedAnswers = clearCardsAnswers(category.title, answers, step); //TODO: add clearQuestionsAnswers
                dispatch(setAnswers(updatedAnswers));
                relevantAnswers = getRelevantAnswers(category.title, updatedAnswers);

                console.log(`⏩ Skipping Step: ${step.title} (Dependencies not met)`);
                currentStep++;
                currentQuestion = 0;
                continue;
            }

            // ✅ If no valid questions/cards exist, move to the next step
            if (!hasValidQuestionsOrCards(step, relevantAnswers)) {
                console.log(`🔄 No valid questions/cards, moving to next step: ${step.title}`);
                currentStep++;
                currentQuestion = 0;
                continue;
            }

            console.log(`✅ Entering Step: ${step.title}`);
            console.log(`🔵 Current Question: ${currentQuestion}`);

            // ✅ Move within the step (if there are questions)
            if (step.questions && step.questions.length > 0) {
                while (currentQuestion < step.questions.length) {
                    const question = step.questions[currentQuestion];

                    // ✅ Check dependencies first
                    if (question.depend && !checkDepends(question.depend, relevantAnswers)) {
                        console.log(`⏩ Skipping Question: ${question.question} (Dependencies not met)`);
                        currentQuestion++;
                        continue;
                    }

                    // ✅ Handle profile questions first
                    if (
                        handleProfileQuestionsOnNext(
                            question, state, dispatch,
                            currentCategory, currentStep, currentQuestion, currentProfileQuestion
                        )
                    ) {
                        return; // Stop execution if profile handling was done
                    }

                    // ✅ If this was the last question, move to the next step
                    console.log("🔄 Last question in step, moving to next step.");
                    break;
                }
            }

            // ✅ Move to next step if no more questions are left
            currentStep++;
            currentQuestion = 0;
            currentProfileQuestion = 0;

            // ✅ If there are more steps, move to the next step
            if (currentStep < category.steps.length) {
                console.log(`🔄 Moving to Next Step: ${category.steps[currentStep].title}`);
                dispatch(next({
                    nextCategory: currentCategory,
                    nextStep: currentStep,
                    nextQuestion: 0,
                    nextProfileQuestion: 0,
                }));
                return;
            }

            // ✅ If no more steps, move to the next category
            console.log("🔄 No more steps in category, moving to next category.");
            break;
        }

        // ✅ Move to next category
        currentCategory++;
        currentStep = 0;
        currentQuestion = 0;
        currentProfileQuestion = 0;

        // ✅ Keep checking until a valid category is found (EVEN AT THE END)
        while (currentCategory < categories.length && !state.navigation.navigationItems.includes(categories[currentCategory].title)) {
            console.log(`⏩ Skipping Category: ${categories[currentCategory].title} (Not in navigationItems)`);
            currentCategory++;
        }

        // ✅ If all categories are invalid, stop execution
        if (currentCategory >= categories.length) {
            console.warn("⚠️ No valid next category found. Staying in place.");
            return;
        }

        console.log(`🔄 Moving to Next Category: ${categories[currentCategory].title}`);
        dispatch(next({
            nextCategory: currentCategory,
            nextStep: 0,
            nextQuestion: 0,
            nextProfileQuestion: 0,
        }));
        return;
    }

    console.warn("⚠️ No valid next question, step, or category found. Staying in place.");
};

export const findAndSetInnerArrayNext = (
    currentInnerArrayQuestionIndex: number,
    dataQuestions: Question[],
    arrayAnswers: any[],
    handleArrayQuestionAnswer: (_question: string, _answer: any) => void,
    dispatch: Dispatch
): { isArrayEnd: boolean } => {
    const totalQuestions = dataQuestions.length || 0;

    const categoryAnswers = formatAnswers(arrayAnswers);

    const nextIndex = currentInnerArrayQuestionIndex + 1;

    let foundValidQuestion = false;
    let currentIndex = nextIndex;

    while (currentIndex < totalQuestions) {
        const nextQuestion = dataQuestions?.[currentIndex];
        if (nextQuestion && nextQuestion.depend) {
            if (!checkDepends(nextQuestion.depend, categoryAnswers)) {
                handleArrayQuestionAnswer(nextQuestion.question, undefined);
                currentIndex++;
                continue;
            }
        }
        foundValidQuestion = true;
        break;
    }

    if (!foundValidQuestion) {
        setTimeout(() => {
            dispatch(setIsEditingInnerArrayItem(false));
            dispatch(setCurrentInnerArrayQuestionIndex(0));
            dispatch(setInnerArrayItemAnswers([]));
            dispatch(setInnerArrayItemQuestions([]));
        }, 10);
        return { isArrayEnd: true };
    }

    dispatch(setCurrentInnerArrayQuestionIndex(currentIndex));
    return { isArrayEnd: false };
};

export const findAndSetArrayNext = (
    currentArrayQuestionIndex: number,
    dataQuestions: Question[],
    arrayAnswers: any[],
    handleArrayQuestionAnswer: (_question: string, _answer: any) => void,
    dispatch: Dispatch
): { isArrayEnd: boolean } => {
    const nextIndex = currentArrayQuestionIndex + 1;
    const totalQuestions = dataQuestions.length || 0;

    let foundValidQuestion = false;
    let currentIndex = nextIndex;

    const categoryAnswers = formatAnswers(arrayAnswers);

    while (currentIndex < totalQuestions) {
        const nextQuestion = dataQuestions[currentIndex];

        if (nextQuestion?.depend) {
            if (!checkDepends(nextQuestion.depend, categoryAnswers)) {
                currentIndex++;
                if (nextQuestion.type === QuestionType.cardArrayQuestion) {
                    const clearedCards = nextQuestion.cards?.map(card => ({
                        question: card.question,
                        answer: "",
                        type: QuestionType.cardArrayQuestion,
                    }));
                    handleArrayQuestionAnswer(nextQuestion.question, clearedCards);
                } else {
                    handleArrayQuestionAnswer(nextQuestion.question, undefined);
                }
                continue;
            }
        }

        if (nextQuestion?.type === QuestionType.selectOneOptionQuestionV2 && nextQuestion.dynamicOptions) {
            const { depend, options } = nextQuestion.dynamicOptions;

            let currentOptions = [];
            let dependencyMatched = false;

            for (const dependency of depend) {
                const dependencyAnswer = categoryAnswers[dependency.question];

                if (dependencyAnswer && options[dependencyAnswer]) {
                    currentOptions = options[dependencyAnswer];
                    dependencyMatched = true;
                    break;
                }
            }

            if (!dependencyMatched) {
                currentIndex++;
                handleArrayQuestionAnswer(nextQuestion.question, undefined);
                continue;
            }

            if (!Array.isArray(currentOptions) || currentOptions.length === 0) {
                currentIndex++;
                handleArrayQuestionAnswer(nextQuestion.question, undefined);
                continue;
            }
        }

        foundValidQuestion = true;
        break;
    }

    if (!foundValidQuestion) {
        dispatch(setIsEditingItem(false));
        dispatch(setCurrentArrayQuestionIndex(0));
        dispatch(setArrayItemAnswers([]));
        dispatch(setArrayItemQuestions([]));
        return { isArrayEnd: true };
    }

    dispatch(setCurrentArrayQuestionIndex(currentIndex));
    return { isArrayEnd: false };
};

export const findAndSetNextStep = (state: RootState, dispatch: Dispatch) => {
    let { currentCategory, currentStep } = state.navigation;
    const { answers } = state.answers;

    currentStep++;

    while (currentCategory < categories.length) {
        while (currentCategory < categories.length && !state.navigation.navigationItems.includes(categories[currentCategory].title)) {
            currentCategory++;
            currentStep = 0;
        }

        if (currentCategory >= categories.length) {
            return;
        }

        const category = categories[currentCategory];

        while (currentStep < category.steps.length) {
            const step = category.steps[currentStep];

            if (step.type === StepType.payment || step.type === StepType.review) {
                dispatch(next({
                    nextCategory: currentCategory,
                    nextStep: currentStep,
                    nextQuestion: 0,
                    nextProfileQuestion: 0,
                }));
                return;
            }

            if (step.type === StepType.categoryDone) {
                dispatch(next({
                    nextCategory: currentCategory,
                    nextStep: currentStep,
                    nextQuestion: 0,
                    nextProfileQuestion: 0,
                }));
                return;
            }

            let relevantAnswers = getRelevantAnswers(category.title, answers);

            if (step.hasCards) {
                const updatedAnswers = clearCardsAnswers(category.title, answers, step);
                dispatch(setAnswers(updatedAnswers));
                relevantAnswers = getRelevantAnswers(category.title, updatedAnswers);
            }

            if (step.depend && !checkDepends(step.depend, relevantAnswers)) {
                const updatedAnswers = clearStepQuestionsAnswers(category.title, answers, step);
                dispatch(setAnswers(updatedAnswers));
                relevantAnswers = getRelevantAnswers(category.title, updatedAnswers);

                currentStep++;
                continue;
            }

            if (!hasValidQuestionsOrCards(step, relevantAnswers)) {
                currentStep++;
                continue;
            }

            if (currentStep < category.steps.length) {
                dispatch(next({
                    nextCategory: currentCategory,
                    nextStep: currentStep,
                    nextQuestion: 0,
                    nextProfileQuestion: 0,
                }));
                return;
            }

            break;
        }

        currentCategory++;
        currentStep = 0;

        while (currentCategory < categories.length && !state.navigation.navigationItems.includes(categories[currentCategory].title)) {
            currentCategory++;
        }

        if (currentCategory >= categories.length) return;
    }
};

// ------------------------------ Previous Navigation ------------------------------ //

const handleProfileQuestionsOnPrevious = (
    step: any,
    state: RootState,
    dispatch: Dispatch,
    currentCategory: number,
    currentStep: number,
    currentQuestion: number,
    currentProfileQuestion: number
) => {
    const parentQuestion = step.questions?.[currentQuestion];

    if (!parentQuestion?.questions || parentQuestion.questions.length === 0) {
        return false; // Not a profile question
    }

    while (currentProfileQuestion > 0) {
        currentProfileQuestion--;
        const nestedQuestion = parentQuestion.questions[currentProfileQuestion];

        // ✅ Ensure current profile exists before proceeding
        if (!state.taxProfile.currentProfile) return true;
        const formattedProfileAnswers = formatAnswers(state.taxProfile.currentProfileAnswers);

        // ✅ Check dependencies before moving to the previous nested question
        if (nestedQuestion.depend && !checkDepends(nestedQuestion.depend, formattedProfileAnswers)) {
            continue;
        }

        // ✅ Handle dynamic options logic
        if (nestedQuestion.type === QuestionType.selectOneOptionQuestionV2 && nestedQuestion.dynamicOptions) {
            const { depend, options } = nestedQuestion.dynamicOptions;
            let currentOptions = [];
            let dependencyMatched = false;

            for (const dependency of depend) {
                const dependencyAnswer = formattedProfileAnswers[dependency.question];

                if (dependencyAnswer && options[dependencyAnswer]) {
                    currentOptions = options[dependencyAnswer];
                    dependencyMatched = true;
                    break;
                }
            }

            if (!dependencyMatched || !Array.isArray(currentOptions) || currentOptions.length === 0) {
                continue;
            }
        }

        // ✅ Found valid previous nested question, move to it
        dispatch(previous({
            prevCategory: currentCategory,
            prevStep: currentStep,
            prevQuestion: currentQuestion, // Stay on the parent question
            prevProfileQuestion: currentProfileQuestion, // Move to the previous nested question
        }));
        return true; // Stop further processing
    }

    // ✅ If all nested questions are done, reset profile and move back to the main question
    dispatch(previous({
        prevCategory: currentCategory,
        prevStep: currentStep,
        prevQuestion: currentQuestion, // Stay on the parent question
        prevProfileQuestion: 0, // Reset nested index
    }));

    return true; // Indicate that profile question handling was performed
};

export const findAndSetProfilePrev = (state: RootState, dispatch: Dispatch, currentStepData: Step) => {
    let { currentCategory, currentStep, currentQuestion } = state.navigation;
    const { currentProfileQuestion } = state.navigation;
    const { answers } = state.answers;

    if (currentStepData?.parentProfileType && currentProfileQuestion === 0) {
        dispatch(setIsEditingProfile(false));
        dispatch(setCurrentProfile(undefined));
        return;
    }

    while (currentCategory >= 0) {
        const category = categories[currentCategory];

        // 🛑 Skip categories not in navigationItems
        if (!state.navigation.navigationItems.includes(category.title)) {
            currentCategory--;
            continue;
        }

        while (currentStep >= 0) {
            const step = category.steps[currentStep];

            const updatedAnswers = clearCardsAnswers(category.title, answers, step);
            dispatch(setAnswers(updatedAnswers));
            const relevantAnswers = getRelevantAnswers(category.title, updatedAnswers);

            // ✅ Skip steps where dependencies aren't met
            if (step.depend && !checkDepends(step.depend, relevantAnswers)) {
                currentStep--;
                continue;
            }

            // ✅ Handle profile questions if applicable
            if (
                state.taxProfile.isEditingProfile &&
                handleProfileQuestionsOnPrevious(
                    step, state, dispatch,
                    currentCategory, currentStep, currentQuestion, currentProfileQuestion
                )
            ) {
                return; // Stop execution if profile handling was done
            }

            // ✅ Try moving **backward** within the step (main questions)
            if (step.questions && currentQuestion > 0) {
                currentQuestion--;
                const question = step.questions[currentQuestion];

                if (!question?.depend || checkDepends(question?.depend, relevantAnswers)) {
                    dispatch(previous({
                        prevCategory: currentCategory,
                        prevStep: currentStep,
                        prevQuestion: currentQuestion,
                        prevProfileQuestion: 0,
                    }));
                    return;
                }
            }

            // ✅ If no more questions in the step, move to the previous step
            while (currentStep > 0) {
                currentStep--;

                // ✅ Skip categoryDone steps
                if (category.steps[currentStep].type === StepType.categoryDone) {
                    continue;
                }

                const prevStep = category.steps[currentStep];

                // ✅ Ensure dependencies are met before moving
                if (prevStep.depend && !checkDepends(prevStep.depend, relevantAnswers)) {
                    continue;
                }

                // ✅ Move to the last question in the previous step
                const lastQuestionIndex = prevStep.questions?.length ? prevStep.questions.length - 1 : 0;
                dispatch(previous({
                    prevCategory: currentCategory,
                    prevStep: currentStep,
                    prevQuestion: lastQuestionIndex,
                    prevProfileQuestion: 0,
                }));
                return;
            }

            // ✅ If at step 0, move to the last valid step of the previous category
            currentCategory--;

            while (currentCategory >= 0) {
                const prevCategory = categories[currentCategory];

                if (state.navigation.navigationItems.includes(prevCategory.title)) {
                    currentStep = prevCategory.steps.length - 1;

                    // ✅ Find the last valid step
                    while (currentStep >= 0) {
                        const prevStep = prevCategory.steps[currentStep];

                        // ✅ Skip categoryDone type steps
                        if (prevStep.type === StepType.categoryDone) {
                            currentStep--;
                            continue;
                        }

                        // ✅ Ensure dependencies are met before moving
                        if (prevStep.depend && !checkDepends(prevStep.depend, getRelevantAnswers(prevCategory.title, answers))) {
                            currentStep--;
                            continue;
                        }

                        // ✅ Move to the last question in the previous step
                        const lastQuestionIndex = prevStep.questions?.length ? prevStep.questions.length - 1 : 0;
                        dispatch(previous({
                            prevCategory: currentCategory,
                            prevStep: currentStep,
                            prevQuestion: lastQuestionIndex,
                            prevProfileQuestion: 0,
                        }));
                        return;
                    }
                }

                currentCategory--;
            }
        }

        return;
    }
};

export const findAndSetInnerArrayPrev = (state: RootState, dispatch: Dispatch) => {
    const { currentInnerArrayQuestionIndex } = state.navigation;
    const { innerArrayItemAnswers, innerArrayItemQuestions } = state.arrayQuestion;

    if (currentInnerArrayQuestionIndex === 0) {
        dispatch(setIsEditingInnerArrayItem(false));
        dispatch(setCurrentInnerArrayQuestionIndex(0));
        dispatch(setInnerArrayItemAnswers([]));
        dispatch(setInnerArrayItemQuestions([]));
        return;
    }

    let prevIndex = currentInnerArrayQuestionIndex - 1;

    const formattedAnswers = formatAnswers(innerArrayItemAnswers);

    while (prevIndex >= 0) {
        if (meetsDependencies(innerArrayItemQuestions[prevIndex], formattedAnswers)) break;
        prevIndex--;
    }

    if (prevIndex >= 0) {
        dispatch(setCurrentInnerArrayQuestionIndex(prevIndex));
    } else {
        dispatch(setIsEditingInnerArrayItem(false));
        dispatch(setInnerArrayItemAnswers([]));
        dispatch(setInnerArrayItemQuestions([]));
    }
};

export const findAndSetArrayPrev = (state: RootState, dispatch: Dispatch) => {
    const { currentArrayQuestionIndex } = state.navigation;
    const { arrayItemAnswers, arrayItemQuestions } = state.arrayQuestion;

    if (currentArrayQuestionIndex === 0) {
        dispatch(setIsEditingItem(false));
        dispatch(setArrayItemAnswers([]));
        dispatch(setArrayItemQuestions([]));
        return;
    }

    let prevIndex = currentArrayQuestionIndex - 1;

    const formattedAnswers = formatAnswers(arrayItemAnswers);

    while (prevIndex >= 0) {
        if (meetsDependencies(arrayItemQuestions[prevIndex], formattedAnswers)) break;
        prevIndex--;
    }

    if (prevIndex >= 0) {
        dispatch(setCurrentArrayQuestionIndex(prevIndex));
    } else {
        dispatch(setIsEditingItem(false));
        dispatch(setArrayItemAnswers([]));
        dispatch(setArrayItemQuestions([]));
    }
};

export const findAndSetPrevStep = (state: RootState, dispatch: Dispatch) => {
    let { currentCategory, currentStep } = state.navigation;
    const { answers } = state.answers;
    currentStep--;

    while (currentCategory >= 0) {
        const category = categories[currentCategory];

        if (!state.navigation.navigationItems.includes(category.title)) {
            currentCategory--;
            currentStep = categories[currentCategory].steps.length - 1 || -1;
            continue;
        }

        while (currentStep >= 0) {
            const step = category.steps[currentStep];

            if (step.type === StepType.categoryDone) {
                currentStep--;
                continue;
            }

            if (step.type === StepType.bankAccount || step.type === StepType.onboarding) {
                dispatch(previous({
                    prevCategory: currentCategory,
                    prevStep: currentStep,
                    prevQuestion: 0,
                    prevProfileQuestion: 0,
                }));
                return;
            }

            let relevantAnswers = getRelevantAnswers(category.title, answers);

            if (step.hasCards) {
                const updatedAnswers = clearCardsAnswers(category.title, answers, step);
                dispatch(setAnswers(updatedAnswers));
                relevantAnswers = getRelevantAnswers(category.title, updatedAnswers);
            }

            if (step.depend && !checkDepends(step.depend, relevantAnswers)) {
                currentStep--;
                continue;
            }

            let validCardStep = false;
            if (step.cards && step.cards.length > 0) {
                for (const card of step.cards) {
                    if (!card.depend || checkDepends(card.depend, relevantAnswers)) {
                        validCardStep = true;
                        break;
                    }
                }
            }

            if (validCardStep) {
                dispatch(previous({
                    prevCategory: currentCategory,
                    prevStep: currentStep,
                    prevQuestion: 0,
                    prevProfileQuestion: 0,
                }));
                return;
            }

            let validQuestionIndex = -1;
            if (step.questions && step.questions.length > 0) {
                for (let i = step.questions.length - 1; i >= 0; i--) {
                    const question = step.questions[i];

                    if (!question.depend || checkDepends(question.depend, relevantAnswers)) {
                        validQuestionIndex = i;
                        break;
                    }
                }
            }

            if (validQuestionIndex !== -1) {
                dispatch(previous({
                    prevCategory: currentCategory,
                    prevStep: currentStep,
                    prevQuestion: validQuestionIndex,
                    prevProfileQuestion: 0,
                }));
                return;
            }

            currentStep--;
        }

        currentCategory !== 0 && currentCategory--;
        currentStep = categories[currentCategory].steps.length - 1 || 0;
    }
};
