import { FC } from "react";

interface ProgressDividerProps {
    progress: number;
}

const ProgressDivider: FC<ProgressDividerProps> = ({ progress }) => {
    return (
        <div className="new-questionnaire__divider-container">
            <div
                className="new-questionnaire__progress-bar"
                style={{ width: `${progress}%`, maxWidth: "100%" }}
            ></div>
        </div>
    );
};

export default ProgressDivider;
