const Error = ({
    text,
}: {
    text: string;
}) => {
    return <div style={{ fontSize: 12, margin: 4 }}>
        {text}
    </div>;
};

export default Error;
