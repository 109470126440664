import { FC, useContext, useState } from "react";

import { AppContext } from "App";
import BankAccountMode from "components/modals/Payment/BankAccountMode";
import { IUser } from "types/auth";
import { AccountDetails } from "types/bankAccounts";

import BankAccountCards from "../BankAccountCards";

type BankAccountProps = {
    user: IUser;
    connectedBankAccounts: AccountDetails[];
    handleContinue: () => void;
    openConnectModal: () => void;
    isHeaderComponent: boolean;
};

const BankAccount: FC<BankAccountProps> = ({ handleContinue, openConnectModal, connectedBankAccounts, isHeaderComponent }) => {
    const { isMobile } = useContext(AppContext);
    const [paymentModeModal, setPaymentModeModal] = useState<boolean>(false);

    return (
        <div className={`new-questionnaire__bank-account ${isMobile || isHeaderComponent ? "mt10" : "mt60"}`}>
            <div className="new-questionnaire__bank-account-title">
                Connected Bank Accounts
            </div>
            <div className="mt12">
                <BankAccountCards accounts={connectedBankAccounts || []} />
            </div>

            <div className="new-questionnaire__bank-accounts-text2 mt40">
                Is there {connectedBankAccounts.length ? "another" : "any"} bank account you use for your business finances?
            </div>
            {isHeaderComponent && <div className="new-questionnaire__warning-text text-align-center">⚠️ Connect all the accounts where you receive your salary and manage your business expenses</div>}
            <div className="new-questionnaire__bank-accounts-buttons mt30">
                {!isHeaderComponent && <div
                    className={"new-questionnaire__desktop-form-secondary-button w100"}
                    onClick={handleContinue}
                >
                    No, Continue
                </div>}
                <div
                    className={"new-questionnaire__desktop-form-primary-button w100"}
                    onClick={openConnectModal}
                >
                    {connectedBankAccounts?.length ? "Yes, Add More" : "Connect Account"}
                </div>
            </div>
            <BankAccountMode
                paymentModeModal={paymentModeModal}
                setPaymentModeModal={setPaymentModeModal}
            />
        </div>
    );
};

export default BankAccount;
