import "styles/profile/idpal.less";
import "swiper/css";
import "@dotlottie/player-component";

import { useEffect } from "react";

import { Typography } from "antd";

type ThankyouPageProps = {
    setSelectedTab: (_tab: string) => void;

};
export default function ThankyouPage({ setSelectedTab }: ThankyouPageProps) {
    useEffect(() => {
        const delayTime = 2000;
        setTimeout(() => {
            setSelectedTab("Payment");
        }, delayTime);
    }, [setSelectedTab]);
    return (
        <div>
            <dotlottie-player
                src="https://lottie.host/b845b9ea-d0dc-410a-aa60-ecfb678676f8/SZz4pI1tZ0.json"
                background="transparent" speed="1"
                style={{ margin: "auto", justifyContent: "center", height: "250px", width: "260.3px", marginTop: "200px" }}
                loop
                autoplay
            />
            <Typography className="success__content-title">
                Thank you!
            </Typography>
        </div>
    );
}
