import pigFinish from "assets/animation/piggy-bank-section-complete.json";
import foreignIncomeImage from "assets/images/foreign-income-popup.png";
import babyBoyActive from "assets/images/new-questionnaire/baby-boy-active.svg";
import babyBoyDefault from "assets/images/new-questionnaire/baby-boy-default.svg";
import businessLosses from "assets/images/new-questionnaire/business-losses-prompt.svg";
import governmentPrompt from "assets/images/new-questionnaire/capitaGainsLosses/government.png";
import directorPrompt from "assets/images/new-questionnaire/employment/employment-director-prompt.svg";
import workFromHomePrompt from "assets/images/new-questionnaire/employment/employment-home.svg";
import employmentPrompt from "assets/images/new-questionnaire/employment/employment-prompt.svg";
import taxReturnPrompt from "assets/images/new-questionnaire/employment/employment-tax-return.png";
import fixedMileage from "assets/images/new-questionnaire/fixed-mileage.png";
import charitableGivingPopup from "assets/images/new-questionnaire/general/charitable-giving.png";
import marriageAllowancePopup from "assets/images/new-questionnaire/general/marriage-allowance.png";
import pensionContributionPopup from "assets/images/new-questionnaire/general/pension-contribution.png";
import investmentTypeChangePopup from "assets/images/new-questionnaire/investment/investment-type-change-popup.png";
import pensionPrompt from "assets/images/new-questionnaire/pension/pension_income.png";
import rentalTypeChangePopup from "assets/images/new-questionnaire/rental/rental-type-change-popup.png";
import rentalPrompt from "assets/images/new-questionnaire/rental-prompt.png";
import childBenefitsPrompt from "assets/images/new-questionnaire/stateBenefits/child-benefits.png";
import incapacityBenefitPrompt from "assets/images/new-questionnaire/stateBenefits/incapacity-benefit.png";
import jobseekersPrompt from "assets/images/new-questionnaire/stateBenefits/jobseekers.png";
import taxDetails from "assets/images/new-questionnaire/tax-details.svg";
import { cleanHTMLString } from "utils/strings";

import { EmploymentIndustries, EmploymentJobs, EmploymentSubIndustries } from "./employmentIndustries";
import { AssetsIconMapping, CapitalIconMapping, CisIconMapping, DividendsIconMapping, EmploymentIconMapping, EmploymentStartEndIconMapping, GeneralOptionsIconMapping, P11DIconMapping, PensionIconMapping, PersonalInfoIconMapping, RentalIconMapping, SelfEmployedIconMapping, StateBenefitsIconMapping, SubsistenceExpenseIconMapping, TransferIconMapping, VehicleExpensesIconMapping, VehiclesIconMapping, VehicleTypeIconMapping } from "./iconMapping";
import { QuestionID } from "./questionIds";
import { CategoryTitle, ConditionType, ParentProfileType, ProfileType, PromptModalType, QuestionSubType, QuestionType, StepType, UploadType } from "../enums/enum";

export const templates = {
    itemName: "[_itemName_]",
};

export interface Depend {
    question: string;
    answer?: string | object | string[];
    opposite?: boolean;
    orDepend?: boolean;
    conditionType?: ConditionType;
    conditionValue?: boolean;
}

export interface Card {
    id: QuestionID;
    question: string;
    tags: string[];
    styles?: object;
    depend?: Depend[];
    itemNameFromQuestionAnswer?: boolean;
    answerRequired?: boolean;
}
export interface ValidationType {
    regExp: string;
    error: string;
    type?: "nin"
}
export interface PromptModalDataType {
    type?: PromptModalType;
    title: string;
    text: string;
    image?: string;
    depend?: Depend[];
    promptActionButtonText?: string,
    promptActionButtonUrl?: string,
    manualOpen?: boolean,
    confirmButtonText?: string,
    cancelButtonText?: string,
    optionToConfirm?: string,
    onCancelUnselect?: boolean,
    showModalOpener?: boolean,
    oneTimePopUp?: boolean,
}

export interface Question {
    id: QuestionID;
    question: string;
    questionTitle?: string;
    tags?: string[];
    options?: string[];
    initValue?: string;
    answerRequired?: boolean;
    addButtonText?: string;
    itemTitle?: string;
    itemName?: string;
    infoText?: string;
    minDate?: string;
    maxDate?: string;
    minRows?: number;
    maxRows?: number;
    percentageAmountModificatorFor?: string;
    amountCategory?: string;
    uploadType?: UploadType;
    type: QuestionType;
    subtype?: "confirmedTotalGross" | "taxAlreadyPaidOnRevenue";
    validation?: ValidationType;
    validationInfo?: string[];
    depend?: Depend[];
    requiredItems?: {
        question: string;
    };
    disallowedItems?: {
        question: string;
        answer: string;
    };
    questions?: Question[];
    icons?: {
        active: string;
        default: string;
    };
    cards?: Card[];
    promptModal?: PromptModalDataType;
    errorModal?: PromptModalDataType;
    inputType?: "number" | "string";
    unit?: string;
    isItemName?: boolean;
    itemNameFromQuestionAnswer?: boolean;
    hideExpenseDisclaimer?: boolean;
    dynamicOptions?: {
        depend: {
            question: string;
        }[];
        options: Record<string, any>;
    };
    isNone?: string;
    baseForExpensesList?: boolean;
    shouldUpdateProfile?: boolean;
    iconMapping?: Record<string, string>;
}

export interface Step {
    title: string;
    description: string;
    hasCards: boolean;
    allowContinue?: boolean;
    hasDepends: boolean;
    cards?: Card[];
    animation?: any;
    isAnimation?: boolean;
    questions?: Question[];
    hasBack: boolean;
    type: StepType;
    depend?: Depend[];
    promptModal?: PromptModalDataType
    hideExpenseDisclaimer?: boolean;
    parentProfileType?: ParentProfileType;
    profileType?: ProfileType;
}

export interface Category {
    title: CategoryTitle;
    showAnswersInAdmin: boolean;
    steps: Step[];
}

export const categories: Category[] = [
    // --------------------------------- Bank Account Connection
    {
        title: CategoryTitle.BankAccountConnection,
        showAnswersInAdmin: false,
        steps: [
            {
                type: StepType.onboarding,
                title: "Onboarding",
                description: "",
                hasCards: false,
                hasDepends: false,
                hasBack: false,
                questions: [],
            },
            {
                type: StepType.bankAccount,
                title: "Bank Account Connection",
                description: "",
                hasCards: false,
                hasDepends: false,
                hasBack: false,
                questions: [],
            },
        ],
    },
    // --------------------------------- Personal Info
    {
        title: CategoryTitle.PersonalInfo,
        showAnswersInAdmin: true,
        steps: [
            // --------------------------------- UTR&NIN
            {
                title: "Enter Your National Insurance (NI) Number and UTR",
                description: "This information is needed to submit your tax return.",
                type: StepType.questionnaire,
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                questions: [
                    {
                        id: "PERSONAL_INFO_UTR_NIN",
                        type: QuestionType.ninUtrQuestion,
                        question: "Enter Your National Insurance Number and UTR",
                        answerRequired: true,
                        tags: [],
                        depend: [],
                    },
                ],
            },
            // --------------------------------- Employment Type
            {
                title: "Primary Income",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                questions: [
                    {
                        id: "PERSONAL_INFO_JOB_STATUS",
                        type: QuestionType.primaryIncomeQuestion,
                        question: "Select your primary income sources",
                        answerRequired: false,
                        options: [
                            "🦺 Construction worker (CIS)",
                            "🧾 Self-Employed",
                            "🧑‍💼 Employed (PAYE)",
                            "💼 Director of a LTD company",
                            // "Self-employed Partnership",
                            "🏠 Landlord",
                            "🌎 Foreign income",
                        ],
                        iconMapping: PersonalInfoIconMapping,
                        disallowedItems: {
                            question: "Select your primary income sources",
                            answer: "🌎 Foreign income",
                        },
                        tags: [],
                        depend: [],
                        promptModal: {
                            type: PromptModalType.info,
                            title: "Foreign Income? We've Got You Covered!",
                            text: "It looks like your tax return includes foreign income. While our platform service doesn't currently handle returns with foreign income, we can still help! \n\nTo ensure you get the best possible support, we recommend booking an appointment with one of our expert accountants. They’ll guide you through the process and make sure your tax return is submitted accurately.",
                            image: foreignIncomeImage,
                            promptActionButtonText: "Book an appointment",
                            promptActionButtonUrl: "https://taxzapuk.setmore.com/",
                            depend: [
                                {
                                    question: "Select your primary income sources",
                                    answer: "🌎 Foreign income",
                                },
                            ],
                        },
                    },
                ],

            },
            // --------------------------------- Additional Income
            {
                title: "Additional Income",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                questions: [
                    {
                        id: "PERSONAL_INFO_ADDITIONAL_INCOME",
                        type: QuestionType.primaryIncomeQuestion,
                        question: "Select your additional income sources",
                        answerRequired: false,
                        options: [
                            "🏠 Rental Income",
                            "🧩 Dividends",
                            "🏦 Interest Income",
                            "📈 Capital Gains/Losses",
                            "👴 Pension Income",
                            "💸 State Benefits",
                        ],
                        iconMapping: PersonalInfoIconMapping,
                        tags: [],
                        depend: [],
                    },
                ],
            },
            // --------------------------------- Change of Address
            {
                title: "Change Of Home Address",
                type: StepType.questionnaire,
                description: "Please answer questions about your home address.",
                hasCards: true,
                hasDepends: false,
                hasBack: true,
                cards: [
                    {
                        id: "PERSONAL_INFO_HOME_ADDRESS_CHANGE",
                        question: "Did your home address change during the tax year?",
                        tags: ["🏠 Home Address"],
                        depend: [],
                        answerRequired: true,
                    },
                ],
                depend: [],
            },
            {
                title: "Change Of Home Address",
                type: StepType.questionnaire,
                description: "Please answer questions about your home address.",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                questions: [
                    {
                        id: "PERSONAL_INFO_HOME_ADDRESS_CHANGE_VALUE",
                        type: QuestionType.textQuestion,
                        question: "Please enter your new home address",
                        answerRequired: true,
                        tags: [],
                    },
                ],
                depend: [
                    {
                        question: "Did your home address change during the tax year?",
                        answer: "yes",
                    },
                ],
            },
            {
                title: "Change Of Home Address",
                type: StepType.questionnaire,
                description: "Please answer questions about your home address.",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                questions: [
                    {
                        id: "PERSONAL_INFO_HOME_ADDRESS_CHANGE_DATE",
                        type: QuestionType.dateQuestion,
                        question: "When did you move to your new home address?",
                        answerRequired: true,
                        tags: [],
                        minDate: "06/04/2024",
                        maxDate: "05/04/2025",
                    },
                ],
                depend: [
                    {
                        question: "Did your home address change during the tax year?",
                        answer: "yes",
                    },
                ],
            },
            // --------------------------------- Personal Information section is done
            {
                title: "Personal information section is complete!",
                type: StepType.categoryDone,
                description: "Your answers are saved successfully.",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                allowContinue: true,
                animation: pigFinish,
                isAnimation: true,
                questions: [],
            },

        ],
    },
    // --------------------------------- Self Employment
    {
        title: CategoryTitle.SelfEmployment,
        showAnswersInAdmin: true,
        steps: [
            {
                title: "Self Employment",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                depend: [],
                parentProfileType: ParentProfileType.selfEmployment,
                profileType: ProfileType.selfEmployment,
                questions: [
                    {
                        id: "SELF_EMPLOYMENT_PROFILES",
                        type: QuestionType.profileQuestion,
                        itemTitle: "Self Employment",
                        itemName: "Self Employment",
                        question: "Add a Self Employment",
                        infoText: "For freelancers, consultants, tradespeople, side hustles, and small business owners.",
                        promptModal: {
                            type: PromptModalType.info,
                            title: "Helpful Tips",
                            text: cleanHTMLString(`Before you start, it's handy to have the following:
                            <ul>
                                <li>Earnings Details of travel (mileage or car costs)</li>
                                <li>If you've connected your bank account(s) we'll find all your expenses! If you track your earnings on a spreadsheet or app, keep this to hand.</li>
                            </ul>
                            `),
                            depend: [],
                            showModalOpener: true,
                            manualOpen: true,
                        },
                        addButtonText: "+ Add Self-Employed Business",
                        itemNameFromQuestionAnswer: true,
                        depend: [],
                        questions: [
                            {
                                id: "SELF_EMPLOYMENT_NAME",
                                type: QuestionType.textQuestion,
                                question: "Your name, or the name of your business.",
                                isItemName: true,
                                tags: [],
                                depend: [],
                                answerRequired: true,
                                shouldUpdateProfile: true,
                            },
                            {
                                id: "SELF_EMPLOYMENT_INDUSTRY",
                                type: QuestionType.selectOneOptionQuestionV2,
                                questionTitle: "Your industry",
                                question: "Please select which one of the below options best describes your self-employed business.",
                                options: [
                                    "🛵 Food Delivery",
                                    "🚚 Taxi or Courier",
                                    "📚 Teacher or Tutor",
                                    "🦺 Construction",
                                    "💪 Health & Fitness",
                                    "🎶 Musician",
                                    "🏆 Entertainment or Sports",
                                    "🛒 Retail",
                                    "🏥 Healthcare",
                                    "👀 Other",
                                ],
                                iconMapping: SelfEmployedIconMapping,
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            {
                                id: "SELF_EMPLOYMENT_INDUSTRY_OTHER",
                                type: QuestionType.textQuestion,
                                question: "Please describe what industry your business operates in.",
                                tags: [],
                                depend: [
                                    {
                                        question: "Please select which one of the below options best describes your self-employed business.",
                                        answer: "👀 Other",
                                    },
                                ],
                                answerRequired: true,
                            },
                            {
                                id: "SELF_EMPLOYMENT_ADDRESS_SELECT",
                                type: QuestionType.selectOneOptionQuestion,
                                question: "Select the address of your business",
                                tags: [],
                                options: [
                                    "Account address",
                                    "Other",
                                ],
                                depend: [],
                                minRows: 1,
                                maxRows: 1,
                                answerRequired: true,
                            },
                            {
                                id: "SELF_EMPLOYMENT_ADDRESS_OTHER",
                                type: QuestionType.textQuestion,
                                question: "Enter the postcode of your business address",
                                tags: [],
                                depend: [
                                    {
                                        question: "Select the address of your business",
                                        answer: "Other",
                                    },
                                ],
                                answerRequired: true,
                            },
                            {
                                id: "SELF_EMPLOYMENT_ADDITIONAL_INFO_TRADING_NAME",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "",
                                cards: [
                                    {
                                        id: "SELF_EMPLOYMENT_TRADING_NAME_CHANGE",
                                        question: "Did your business name change during the tax year?",
                                        tags: ["📋 Business"],
                                        answerRequired: true,
                                        depend: [],
                                    },
                                ],
                            },
                            {
                                id: "SELF_EMPLOYMENT_OLD_TRADING_NAME",
                                type: QuestionType.textQuestion,
                                question: "Enter the previous business name",
                                tags: [],
                                depend: [
                                    {
                                        question: "Did your business name change during the tax year?",
                                        answer: "yes",
                                    },
                                ],
                                answerRequired: true,

                            },
                            {
                                id: "SELF_EMPLOYMENT_ADDITIONAL_INFO_ADDRESS_CHANGE",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "",
                                cards: [
                                    {
                                        id: "SELF_EMPLOYMENT_TRADING_ADDRESS_CHANGE",
                                        question: "Did your business address change during the tax year?",
                                        tags: ["📋 Business"],
                                        answerRequired: true,
                                        depend: [],
                                    },
                                ],
                            },
                            {
                                id: "SELF_EMPLOYMENT_TRADING_OLD_POSTCODE",
                                type: QuestionType.textQuestion,
                                question: "Enter the post code of your previous business address",
                                tags: [],
                                depend: [
                                    {
                                        question: "Did your business address change during the tax year?",
                                        answer: "yes",
                                    },
                                ],
                                answerRequired: true,

                            },
                            {
                                id: "SELF_EMPLOYMENT_TRADING_DATE",
                                type: QuestionType.selectOneOptionQuestionV2,
                                question: "Did the business start or cease trading in the financial year?",
                                options: [
                                    "Started trading",
                                    "Ceased trading",
                                    "None of the above",
                                ],
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            {
                                id: "SELF_EMPLOYMENT_START_TRADING_DATE",
                                type: QuestionType.dateQuestion,
                                minDate: "06/04/2024",
                                maxDate: "05/04/2025",
                                question: "Please enter the date in which your business started trading",
                                tags: [],
                                depend: [
                                    {
                                        question: "Did the business start or cease trading in the financial year?",
                                        answer: "Started trading",
                                    },
                                ],
                                answerRequired: true,
                            },
                            {
                                id: "SELF_EMPLOYMENT_CEASE_TRADING_DATE",
                                type: QuestionType.dateQuestion,
                                minDate: "06/04/2024",
                                maxDate: "05/04/2025",
                                question: "Please enter the date in which your business ceased trading",
                                tags: [],
                                depend: [
                                    {
                                        question: "Did the business start or cease trading in the financial year?",
                                        answer: "Ceased trading",
                                    },
                                ],
                                answerRequired: true,
                            },
                            // --------------------------------- Manual Income Self Employment
                            {
                                id: "SELF_EMPLOYMENT_MANUAL_INCOME",
                                type: QuestionType.amountQuestion,
                                subtype: QuestionSubType.confirmedTotalGross,
                                question: "What was your gross (before tax) self employment income in the financial year?",
                                validationInfo: [
                                    "Add the total amount you received for your products or services.",
                                    "It may included expenses or reimbursements. Don't worry we'll deduct them later.",
                                ],
                                depend: [],
                                shouldUpdateProfile: true,
                                answerRequired: true,
                            },
                            // --------------------------------- Self Employment Income Summary
                            {
                                id: "SELF_EMPLOYMENT_INCOME_SUMMARY",
                                type: QuestionType.profileIncomeSummary,
                                question: "Please review and confirm your self employment gross income.",
                                questions: [],
                                depend: [],
                                answerRequired: true,
                            },
                            // --------------------------------- Business losses
                            {
                                id: "SELF_EMPLOYMENT_BUSINESS_LOSSES",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                cards: [
                                    {
                                        id: "SELF_EMPLOYMENT_BUSINESS_LOSSES_CARD",
                                        question: "Do you have any business losses to bring forward from the previous year?",
                                        tags: ["📉 Business Loss"],
                                        styles: {
                                            fontSize: "18px",
                                        },
                                        depend: [],
                                        answerRequired: true,
                                    },
                                ],
                                depend: [],
                            },
                            {
                                id: "SELF_EMPLOYMENT_BUSINESS_LOSSES_AMOUNT",
                                type: QuestionType.amountQuestion,
                                question: "What is the total amount of losses brought forward?",
                                tags: [],
                                depend: [
                                    {
                                        question: "Do you have any business losses to bring forward from the previous year?",
                                        answer: "yes",
                                    },
                                ],
                                answerRequired: true,
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: businessLosses,
                                    title: "Business Losses",
                                    manualOpen: true,
                                    text: "If you're self-employed or a member of a trading partnership, you'll usually make a loss when the trade expenses are more than the income. The notes for self-employment and partnership pages of your tax return explain how to work out the profit or loss for tax.",
                                    depend: [
                                        {
                                            question: "Do you have any business losses to bring forward from the previous year?",
                                            answer: "yes",
                                        },
                                    ],
                                },

                            },
                            // --------------------------------- Capital Allowances Self Employment
                            {
                                id: "SELF_EMPLOYMENT_USED_VEHICLE",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                cards: [
                                    {
                                        id: "SELF_EMPLOYMENT_USED_VEHICLE_QUESTION",
                                        question: "Did you use a vehicle for business purposes this year?",
                                        tags: ["🚗 Business Vehicles"],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                ],
                                tags: [],
                                depend: [],
                            },
                            {
                                id: "SELF_EMPLOYMENT_VEHICLE_DETAILS_ARRAY",
                                type: QuestionType.arrayQuestion,
                                question: "Add each vehicle used this year for this self-employment",
                                itemTitle: "Vehicle",
                                addButtonText: "+ Add Vehicle",
                                itemName: "Vehicle",
                                itemNameFromQuestionAnswer: true,
                                answerRequired: false,
                                depend: [
                                    {
                                        question: "Did you use a vehicle for business purposes this year?",
                                        answer: "yes",
                                    },
                                ],
                                questions: [
                                    {
                                        id: "SELF_EMPLOYMENT_CAR_TYPE",
                                        type: QuestionType.selectOneOptionQuestionV2,
                                        question: "What type of vehicle is this?",
                                        answerRequired: true,
                                        options: ["Car", "Van/Truck", "Motorbike", "Bicycle"],
                                        iconMapping: VehicleTypeIconMapping,
                                        tags: [],
                                        depend: [],
                                    },
                                    {
                                        id: "SELF_EMPLOYMENT_CAR_MAKE_MODEL",
                                        type: QuestionType.textQuestion,
                                        question: "What make and model is this vehicle?",
                                        isItemName: true,
                                        shouldUpdateProfile: true,
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                    {
                                        id: "SELF_EMPLOYMENT_CAR_CLAIM_METHOD",
                                        type: QuestionType.selectOneOptionQuestionV2,
                                        questionTitle: "Choose How to Record Your Vehicle Expenses",
                                        question: "How would you like to claim for this vehicle?",
                                        infoText: cleanHTMLString(`<div>To stay compliant with tax rules, you must use the same method as in your previous self-assessment.
                                                <ul>
                                                    <li>
                                                        <strong>Total Vehicle Cost:</strong> Deduct the cost of your vehicle using capital allowances, along with all running expenses.
                                                    </li>
                                                    <li>
                                                        <strong>Mileage Rate:</strong> Claim a fixed rate per mile, but you cannot switch to total costs later unless you get a new vehicle.
                                                    </li>
                                                </ul>
                                                </div>`),
                                        options: [
                                            "Total Vehicle Costs (Capital Allowances)",
                                            "Simple Mileage Rate",
                                        ],
                                        answerRequired: true,
                                        iconMapping: VehicleExpensesIconMapping,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },

                                    // -----------------------------
                                    // Simple Mileage Rate
                                    // -----------------------------
                                    {
                                        id: "SELF_EMPLOYMENT_MILEAGE_DRIVEN",
                                        type: QuestionType.textQuestion,
                                        question: "Enter total business miles travelled this year",
                                        inputType: "number",
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Simple Mileage Rate",
                                            },
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                orDepend: true,
                                            },
                                        ],
                                    },

                                    // -----------------------------
                                    // Capital Allowances (Restricted to Cars, Vans, Motorbikes)
                                    // -----------------------------
                                    {
                                        id: "SELF_EMPLOYMENT_CAR_TYPE_NEW_USED",
                                        type: QuestionType.selectOneOptionQuestion,
                                        question: "Did you buy this vehicle brand new or second hand?",
                                        options: ["Brand New", "Second Hand"],
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                    {
                                        id: "SELF_EMPLOYMENT_CAR_PURCHASE_DATE",
                                        type: QuestionType.dateQuestion,
                                        question: "Enter the purchase date",
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                    {
                                        id: "SELF_EMPLOYMENT_CAR_PURCHASE_PRICE",
                                        type: QuestionType.amountQuestion,
                                        question: "Enter the purchase price of the vehicle",
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                    {
                                        id: "SELF_EMPLOYMENT_CAR_SALE",
                                        type: QuestionType.selectOneOptionQuestion,
                                        question: "Have you sold the vehicle this financial year?",
                                        options: ["Yes", "No"],
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                    {
                                        id: "SELF_EMPLOYMENT_CAR_SALE_DATE",
                                        type: QuestionType.dateQuestion,
                                        question: "Enter the sale date",
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Have you sold the vehicle this financial year?",
                                                answer: "Yes",
                                            },
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                        ],
                                    },
                                    {
                                        id: "SELF_EMPLOYMENT_CAR_SALE_PRICE",
                                        type: QuestionType.amountQuestion,
                                        question: "Enter the sale price of the vehicle",
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Have you sold the vehicle this financial year?",
                                                answer: "Yes",
                                            },
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                        ],
                                    },
                                    {
                                        id: "SELF_EMPLOYMENT_CAR_EMISSION_DETAILS",
                                        type: QuestionType.selectOneOptionQuestion,
                                        question: "Select the vehicle's CO₂ emissions (g/km)",
                                        options: [
                                            "0g/km",
                                            "1-50g/km",
                                            "51-75g/km",
                                            "76-95g/km",
                                            "96-110g/km",
                                            "111-130g/km",
                                            "131-160g/km",
                                            "160g/km+",
                                        ],
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                    {
                                        id: "SELF_EMPLOYMENT_CAR_PERSONAL_USAGE",
                                        type: QuestionType.selectOneOptionQuestion,
                                        question: "Select the personal usage percentage (%)",
                                        answerRequired: true,
                                        options: [
                                            "0% (only using for business)",
                                            "10% (rarely)",
                                            "30% (sometimes)",
                                            "50% (generally)",
                                            "70% (often)",
                                            "90% (regularly)",
                                            "Other",
                                        ],
                                        tags: [],
                                        depend: [
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                            // --------------------------------- Open Banking Expenses Self Employment
                            {
                                id: "SELF_EMPLOYMENT_EXPENSES",
                                type: QuestionType.profileExpensesNew,
                                question: "Please swipe left (no) or right (yes) to refundable self employment expenses.",
                                questions: [],
                                depend: [
                                    {
                                        question: "Please swipe left (no) or right (yes) to refundable self employment expenses.",
                                        conditionType: ConditionType.hasReviewableExpensesTransactions,
                                        conditionValue: true,
                                    },
                                ],
                            },
                            // --------------------------------- Self Employment Expenses Summary
                            {
                                id: "SELF_EMPLOYMENT_EXPENSES_SUMMARY",
                                type: QuestionType.profileExpensesSummary,
                                question: "Please review and confirm your self employment expenses.",
                                questions: [],
                                depend: [],
                                answerRequired: true,
                            },
                        ],
                    },
                ],
            },

            // --------------------------------- Self Employment Income section is done
            {
                title: "Self Employment section is complete!",
                type: StepType.categoryDone,
                description: "Your answers are saved successfully.",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                allowContinue: true,
                animation: pigFinish,
                isAnimation: true,
                questions: [],
            },
        ],
    },
    // --------------------------------- Employment (PAYE)
    {
        title: CategoryTitle.Employment,
        showAnswersInAdmin: true,
        steps: [
            // --------------------------------- Employment
            {
                title: "Employment",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                depend: [],
                parentProfileType: ParentProfileType.paye,
                profileType: ProfileType.paye,
                questions: [
                    {
                        id: "EMPLOYMENT_PROFILES",
                        type: QuestionType.profileQuestion,
                        itemTitle: "Employment",
                        itemName: "Employment",
                        question: "Add a PAYE Employment",
                        infoText: "Click below to add your PAYE employment information.",
                        promptModal: {
                            type: PromptModalType.info,
                            title: "Helpful Tips",
                            text: cleanHTMLString(`Before you start, it's handy to have the following:
                            <ul>
                                <li>Your P60 or P45</li>
                                <li>A P11D document (if applicable)</li>
                                <li>If you track your expenses on a spreadsheet or app, keep this to hand.</li>
                            </ul>
                            `),
                            depend: [],
                            showModalOpener: true,
                            manualOpen: true,
                        },
                        addButtonText: "+ Add PAYE Employment",
                        itemNameFromQuestionAnswer: true,
                        depend: [],
                        questions: [
                            {
                                id: "EMPLOYMENT_NAME",
                                type: QuestionType.textQuestion,
                                question: "What was the company name?",
                                isItemName: true,
                                tags: [],
                                depend: [],
                                shouldUpdateProfile: true,
                                answerRequired: true,
                            },
                            {
                                id: "EMPLOYMENT_INDUSTRY",
                                type: QuestionType.selectOneOptionQuestionV2,
                                questionTitle: "Your industry",
                                question: "Please select which one of the below options best describes your employment.",
                                options: EmploymentIndustries,
                                iconMapping: EmploymentIconMapping,
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            {
                                id: "EMPLOYMENT_SUB_INDUSTRY",
                                type: QuestionType.selectOneOptionQuestionV2,
                                questionTitle: "Your industry",
                                question: "Does your employment fall into any of these sub-industries?",
                                options: [],
                                dynamicOptions: {
                                    depend: [
                                        { question: "Please select which one of the below options best describes your employment." },
                                    ],
                                    options: EmploymentSubIndustries,
                                },
                                tags: [],
                                depend: [],
                                // For now, if there are dynamic options, make answerRequired false. Update when factored into the logic for checking profile completion
                                answerRequired: false,
                            },
                            {
                                id: "EMPLOYMENT_JOB_TITLE",
                                type: QuestionType.selectOneOptionQuestionV2,
                                questionTitle: "Your industry",
                                question: "Is your job specified in the list below?",
                                options: [],
                                dynamicOptions: {
                                    depend: [
                                        { question: "Does your employment fall into any of these sub-industries?" },
                                        { question: "Please select which one of the below options best describes your employment." },
                                    ],
                                    options: EmploymentJobs,
                                },
                                tags: [],
                                depend: [],
                                // For now, if there are dynamic options, make answerRequired false. Update when factored into the logic for checking profile completion
                                answerRequired: false,
                            },
                            {
                                id: "EMPLOYMENT_FLAT_RATE_EXPENSES",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "Flat Rate Expenses",
                                cards: [
                                    {
                                        id: "EMPLOYMENT_FLAT_RATE_EXPENSES_CARD",
                                        question: "Were you responsible for purchasing or cleaning a uniform?",
                                        styles: {
                                            fontSize: "16px",
                                        },
                                        tags: ["🥋 Uniform"],
                                        depend: [
                                            {
                                                question: "Please select which one of the below options best describes your employment.",
                                                answer: "👀 None of the above",
                                            },
                                            {
                                                question: "Does your employment fall into any of these sub-industries?",
                                                answer: "None of the above",
                                                orDepend: true,
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                ],
                                depend: [
                                    {
                                        question: "Please select which one of the below options best describes your employment.",
                                        answer: "👀 None of the above",
                                    },
                                    {
                                        question: "Does your employment fall into any of these sub-industries?",
                                        answer: "None of the above",
                                        orDepend: true,
                                    },
                                ],
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: employmentPrompt,
                                    manualOpen: true,
                                    title: "Flat Rate Expenses",
                                    text: "Unfortunately you don't meet any specific fixed deductions, however if you were responsible for purchasing or cleaning a uniform you may be eligible for a deduction.",
                                    depend: [],
                                },
                            },
                            {
                                id: "EMPLOYMENT_COMPANY_DIRECTOR",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "Company Director definition",
                                cards: [
                                    {
                                        id: "EMPLOYMENT_COMPANY_DIRECTOR_CARD",
                                        question: `Were you a director of ${templates.itemName}?`,
                                        styles: {
                                            fontSize: "16px",
                                        },
                                        itemNameFromQuestionAnswer: true,
                                        tags: ["🕴️ Director"],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                ],
                                questions: [],
                                depend: [],
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: directorPrompt,
                                    manualOpen: true,
                                    title: "Limited Company Director?",
                                    text: cleanHTMLString(`<div>
                                        <p>A limited company director holds a significant leadership position and is responsible for fulfilling legal obligations around maintaining company records, filing company accounts, and following the company's articles of association.</p>
                                    </div>`),
                                    depend: [],
                                },
                            },
                            {
                                id: "EMPLOYMENT_DIRECTOR_CEASE_EMPLOYMENT",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "",
                                cards: [
                                    {
                                        id: "EMPLOYMENT_DIRECTOR_CEASE_EMPLOYMENT_CARD",
                                        question: "Did you cease being a Director in the 2024-2025 tax year?",
                                        styles: {
                                            fontSize: "16px",
                                        },
                                        tags: ["🕴 Director"],
                                        depend: [
                                            {
                                                question: `Were you a director of ${templates.itemName}?`,
                                                answer: "yes",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                ],
                                questions: [],
                                depend: [
                                    {
                                        question: `Were you a director of ${templates.itemName}?`,
                                        answer: "yes",
                                    },
                                ],
                            },
                            {
                                id: "EMPLOYMENT_DIRECTOR_CEASE_DATE",
                                type: QuestionType.dateQuestion,
                                minDate: "06/04/2024",
                                maxDate: "05/04/2025",
                                question: "When did you cease being a director?",
                                tags: [],
                                depend: [
                                    {
                                        question: "Did you cease being a Director in the 2024-2025 tax year?",
                                        answer: "yes",
                                    },
                                ],
                                answerRequired: true,
                            },
                            {
                                id: "EMPLOYMENT_DIRECTOR_COMPANY_SIZE",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "",
                                cards: [
                                    {
                                        id: "EMPLOYMENT_DIRECTOR_COMPANY_SIZE_CARD",
                                        question: "Was this company privately owned or controlled by 5 or fewer individuals?",
                                        styles: {
                                            fontSize: "16px",
                                        },
                                        tags: ["👔 Company size"],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                ],
                                questions: [],
                                depend: [],
                            },
                            {
                                id: "EMPLOYMENT_DIRECTOR_START_END_DATE",
                                type: QuestionType.selectOneOptionQuestionV2,
                                questionTitle: "Your Employment",
                                question: "Did you start or leave this job during the tax year?",
                                options: [
                                    "I started work",
                                    "I ended work",
                                    "I started and ended",
                                    "No change",
                                ],
                                iconMapping: EmploymentStartEndIconMapping,
                                tags: [],
                                depend: [],
                                answerRequired: true,
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: directorPrompt,
                                    manualOpen: true,
                                    title: "Employment Period",
                                    text: cleanHTMLString(`<div>
                                        <p>
                                            The UK tax year runs from April to April, and any pay within this period is relevant for your tax return. If you started or ended an employment we need to know these dates. If you started and ended an employment within the tax year select “I started and ended”. If you had no change to your employment status and are currently working at the same job then select “No change”.
                                        </p>
                                    </div>`),
                                    depend: [],
                                },
                            },
                            {
                                id: "EMPLOYMENT_START_DATE",
                                type: QuestionType.dateQuestion,
                                minDate: "06/04/2024",
                                maxDate: "05/04/2025",
                                question: "Please confirm the date you started working here",
                                tags: [],
                                depend: [
                                    {
                                        question: "Did you start or leave this job during the tax year?",
                                        answer: "I started work",
                                    },
                                    {
                                        question: "Did you start or leave this job during the tax year?",
                                        answer: "I started and ended",
                                        orDepend: true,
                                    },
                                ],
                                answerRequired: true,
                            },
                            {
                                id: "EMPLOYMENT_END_DATE",
                                type: QuestionType.dateQuestion,
                                minDate: "06/04/2024",
                                maxDate: "05/04/2025",
                                question: "Please confirm the date you stopped working here",
                                tags: [],
                                depend: [
                                    {
                                        question: "Did you start or leave this job during the tax year?",
                                        answer: "I ended work",
                                    },
                                    {
                                        question: "Did you start or leave this job during the tax year?",
                                        answer: "I started and ended",
                                        orDepend: true,
                                    },
                                ],
                                answerRequired: true,
                            },
                            {
                                id: "EMPLOYMENT_P60",
                                type: QuestionType.uploadFileQuestion,
                                question: "Upload P60 End of Year Certificate",
                                infoText: "",
                                uploadType: UploadType.p60EndOfYearCertificate,
                                depend: [
                                    {
                                        question: "Did you start or leave this job during the tax year?",
                                        answer: "I started work",
                                    },
                                    {
                                        question: "Did you start or leave this job during the tax year?",
                                        answer: "No change",
                                        orDepend: true,
                                    },
                                    {
                                        question: "Did you start or leave this job during the tax year?",
                                        answer: "I started and ended",
                                        orDepend: true,
                                    },
                                ],
                            },
                            {
                                id: "EMPLOYMENT_P45",
                                type: QuestionType.uploadFileQuestion,
                                question: "Upload P45 Leaving Work Certificate",
                                infoText: "",
                                uploadType: UploadType.p45LeavingWorkCertificate,
                                depend: [
                                    {
                                        question: "Did you start or leave this job during the tax year?",
                                        answer: "I ended work",
                                    },
                                    {
                                        question: "Did you start or leave this job during the tax year?",
                                        answer: "I started and ended",
                                        orDepend: true,
                                    },
                                ],
                            },
                            {
                                id: "EMPLOYMENT_REDUNDANCY_PAYMENTS",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "",
                                cards: [
                                    {
                                        id: "EMPLOYMENT_REDUNDANCY_PAYMENTS_CARD",
                                        question: "Did you receive any redundancy payments?",
                                        styles: {
                                            fontSize: "16px",
                                        },
                                        tags: ["💸 Redundancy"],
                                        depend: [
                                            {
                                                question: "Did you start or leave this job during the tax year?",
                                                answer: "I ended work",
                                            },
                                            {
                                                question: "Did you start or leave this job during the tax year?",
                                                answer: "I started and ended",
                                                orDepend: true,
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                ],
                                questions: [],
                                depend: [
                                    {
                                        question: "Did you start or leave this job during the tax year?",
                                        answer: "I ended work",
                                    },
                                    {
                                        question: "Did you start or leave this job during the tax year?",
                                        answer: "I started and ended",
                                        orDepend: true,
                                    },
                                ],
                            },
                            {
                                id: "EMPLOYMENT_REDUNDANCY_PAYMENTS_QUESTIONS",
                                type: QuestionType.questionsList,
                                question: "",
                                infoText: "",
                                cards: [],
                                depend: [
                                    {
                                        question: "Did you receive any redundancy payments?",
                                        answer: "yes",
                                    },
                                ],
                                questions: [
                                    {
                                        id: "EMPLOYMENT_REDUNDANCY_PAYMENTS_AMOUNT",
                                        type: QuestionType.amountListQuestion,
                                        question: "Enter the total redundancy payment you received.",
                                        tags: [],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_REDUNDANCY_PAYMENTS_TAX",
                                        type: QuestionType.amountListQuestion,
                                        question: "What was the tax withheld or paid to HMRC from your redundancy payment?",
                                        tags: [],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                ],
                            },
                            {
                                id: "EMPLOYMENT_TIPS",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "Tips and other payments",
                                cards: [
                                    {
                                        id: "EMPLOYMENT_TIPS_CARD",
                                        question: "Did you receive any tips or payments from someone other than your employer?",
                                        styles: {
                                            fontSize: "16px",
                                        },
                                        tags: ["💷 Tips"],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                ],
                                questions: [],
                                depend: [],
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: employmentPrompt,
                                    manualOpen: true,
                                    title: "Tips and other payments",
                                    text: cleanHTMLString(`<div>
                                        <p>
                                            For example, you may get a tip if you're a hairdresser, a taxi driver or waiter.
                                        </p>
                                    </div>`),
                                    depend: [],
                                },
                            },
                            {
                                id: "EMPLOYMENT_TIPS_AMOUNT",
                                type: QuestionType.amountQuestion,
                                question: "How much did you receive?",
                                tags: [],
                                depend: [
                                    {
                                        question: "Did you receive any tips or payments from someone other than your employer?",
                                        answer: "yes",
                                    },
                                ],
                                answerRequired: true,
                            },
                            {
                                id: "EMPLOYMENT_INCOME_SUMMARY",
                                type: QuestionType.profileIncomeSummary,
                                question: "Please review and confirm your employment gross salary.",
                                questions: [],
                                depend: [],
                                tags: [],
                                answerRequired: true,
                            },
                            {
                                id: "EMPLOYMENT_BENEFITS",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "Employment benefits",
                                cards: [
                                    {
                                        id: "EMPLOYMENT_BENEFITS_CARD",
                                        question: "Did you receive any benefits or perks from your employer, like a company car, private healthcare, or other extras?",
                                        styles: {
                                            fontSize: "16px",
                                        },
                                        tags: ["⚖️ Benefits"],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                ],
                                questions: [],
                                depend: [],
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: employmentPrompt,
                                    manualOpen: true,
                                    title: "Employment benefits",
                                    text: cleanHTMLString(`<div>
                                        <p>
                                            As an employee or director, you might be given benefits specific to your employment. Your employer will tell HMRC and pay tax and National Insurance on these.
                                        </p>
                                        <div>Examples of benefits include:</div>
                                        <ul>
                                            <li>Company cars
                                            </li>
                                            <li>Health insurance
                                            </li>
                                            <li>Life insurance
                                            </li>
                                            <li>Retirement plan benefits
                                            </li>
                                        </ul>
                                        <p>
                                            Some employers cover the tax through payroll. If you're not sure, you should check with your employer.
                                        </p>
                                        </div>`),
                                    depend: [],
                                },
                            },
                            // TO DO: Uncomment this when the parsing is ready for this. Use manual question afterwards for now
                            // {
                            //     id: "EMPLOYMENT_BENEFITS_DOCUMENT_UPLOAD",
                            //     type: QuestionType.uploadFileQuestion,
                            //     question: "Upload P11D benefits document",
                            //     infoText: "",
                            //     uploadType: UploadType.p11DBenefitsDocument,
                            //     depend: [
                            //         {
                            //             question: "Do you have a P11D? A P11D is a tax form which is used to report benefits and expense payments made to employees.",
                            //             answer: "yes",
                            //         },
                            //     ],
                            //     promptModal: {
                            //         type: PromptModalType.info,
                            //         image: taxReturnPrompt,
                            //         title: "P11D Form",
                            //         manualOpen: true,
                            //         text: cleanHTMLString(`<div>
                            //             <p>
                            //             You can get your P11D from your employer. If you don't have it right now you can uploaded it through your dashboard later
                            //             </p>
                            //             </div>`),
                            //         depend: [],
                            //     },
                            // },
                            {
                                id: "EMPLOYMENT_BENEFITS_P11D_LIST",
                                type: QuestionType.selectManyQuestion,
                                questionTitle: "P11D Benefits",
                                question: "Please select any benefits appearing on your P11D form",
                                answerRequired: true,
                                isNone: "None",
                                baseForExpensesList: true,
                                tags: [],
                                depend: [
                                    {
                                        question: "Did you receive any benefits or perks from your employer, like a company car, private healthcare, or other extras?",
                                        answer: "yes",
                                    },
                                ],
                                options: [
                                    "🤑 Assets transferred (e.g. property)",
                                    "🧾 Payments made on behalf of employee",
                                    "💳 Voucher & credit cards",
                                    "🏠 Living accommodation",
                                    "🚙 Mileage allowance payments not taxed at source",
                                    "Cars",
                                    "Car fuel",
                                    "🏦 Interest-free & low interest loans",
                                    "🏥 Private medical treatment or insurance",
                                    "📍 Qualifying relocation expenses payments",
                                    "📋 Services supplied",
                                    "Assets placed at the employee's disposal",
                                    "💷 Other items (professional fees/subscriptions)",
                                    "Expense payments made on behalf of the employee",
                                    "None",
                                ],
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: taxReturnPrompt,
                                    manualOpen: true,
                                    title: "Employee Benefits in Kind",
                                    text: cleanHTMLString(`<div>
                                        <p>Benefits in kind are non-cash perks provided by your employer, such as:</p>
                                        <ul>
                                            <li>Company car</li>
                                            <li>Private health insurance</li>
                                            <li>Life insurance</li>
                                            <li>Retirement plan contributions</li>
                                        </ul>
                                        <p>Your employer reports these benefits to HMRC and typically manages any associated tax. You'll find these details on your P11D form, which is important for accurate tax returns.</p>
                                    </div>`),
                                    depend: [],
                                },
                                iconMapping: P11DIconMapping,
                            },
                            {
                                id: "EMPLOYMENT_BENEFITS_P11D_LIST_AMOUNTS",
                                type: QuestionType.questionsList,
                                questionTitle: "P11D Benefits",
                                question: "Please enter the amount(s) as they appear on your P11D form.",
                                infoText: "",
                                cards: [],
                                depend: [
                                    {
                                        question: "Did you receive any benefits or perks from your employer, like a company car, private healthcare, or other extras?",
                                        answer: "yes",
                                    },
                                    {
                                        question: "Please select any benefits appearing on your P11D form",
                                        answer: "None",
                                        opposite: true,
                                    },
                                ],
                                questions: [
                                    {
                                        id: "EMPLOYMENT_BENEFITS_P11D_LIST_ASSETS_TRANSFERRED",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Assets transferred (e.g. property)",
                                        question: "Please enter the assets transferred expense/benefit",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Please select any benefits appearing on your P11D form",
                                                answer: "🤑 Assets transferred (e.g. property)",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_BENEFITS_P11D_LIST_PAYMENTS_BEHALF_OF_EMPLOYEE",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Payments made on behalf of employee",
                                        question: "Please enter the payment made on behalf of employee",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Please select any benefits appearing on your P11D form",
                                                answer: "🧾 Payments made on behalf of employee",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_BENEFITS_P11D_LIST_VOUCHER_CREDIT_CARDS",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Voucher & credit cards",
                                        question: "Please enter the voucher & credit cards expense/benefit",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Please select any benefits appearing on your P11D form",
                                                answer: "💳 Voucher & credit cards",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_BENEFITS_P11D_LIST_LIVING_ACCOMMODATION",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Living accommodation",
                                        question: "Please enter the living accommodation expense/benefit",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Please select any benefits appearing on your P11D form",
                                                answer: "🏠 Living accommodation",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_BENEFITS_P11D_LIST_MILEAGE_ALLOWANCE_PAYMENTS",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Mileage allowance payments not taxed at source",
                                        question: "Please enter the mileage allowance payments expense/benefit",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Please select any benefits appearing on your P11D form",
                                                answer: "🚙 Mileage allowance payments not taxed at source",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_BENEFITS_P11D_LIST_CARS",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Cars",
                                        question: "Please enter the cars expense/benefit",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Please select any benefits appearing on your P11D form",
                                                answer: "Cars",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_BENEFITS_P11D_LIST_CAR_FUEL",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Car fuel",
                                        question: "Please enter the car fuel expense/benefit",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Please select any benefits appearing on your P11D form",
                                                answer: "Car fuel",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_BENEFITS_P11D_LIST_INTEREST_FREE_LOW_INTEREST_LOANS",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Interest-free & low interest loans",
                                        question: "Please enter the interest-free & low interest loans expense/benefit",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Please select any benefits appearing on your P11D form",
                                                answer: "🏦 Interest-free & low interest loans",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_BENEFITS_P11D_LIST_PRIVATE_MEDICAL_TREATMENT_INSURANCE",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Private medical treatment or insurance",
                                        question: "Please enter the private medical treatment or insurance expense/benefit",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Please select any benefits appearing on your P11D form",
                                                answer: "🏥 Private medical treatment or insurance",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_BENEFITS_P11D_LIST_QUALIFYING_RELOCATION_EXPENSES_PAYMENTS",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Qualifying relocation expenses payments",
                                        question: "Please enter the qualifying relocation expenses payments expense/benefit",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Please select any benefits appearing on your P11D form",
                                                answer: "📍 Qualifying relocation expenses payments",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_BENEFITS_P11D_LIST_SERVICES_SUPPLIED",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Services supplied",
                                        question: "Please enter the services supplied expense/benefit",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Please select any benefits appearing on your P11D form",
                                                answer: "📋 Services supplied",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_BENEFITS_P11D_LIST_ASSETS_PLACED_EMPLOYEE_DISPOSAL",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Assets placed at the employee's disposal",
                                        question: "Please enter the assets placed at the employee's disposal expense/benefit",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Please select any benefits appearing on your P11D form",
                                                answer: "Assets placed at the employee's disposal",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_BENEFITS_P11D_LIST_OTHER_ITEMS",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Other items (professional fees/subscriptions)",
                                        question: "Please enter the other items expense/benefit",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Please select any benefits appearing on your P11D form",
                                                answer: "💷 Other items (professional fees/subscriptions)",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_BENEFITS_P11D_LIST_EXPENSE_PAYMENTS",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Expense payments made on behalf of the employee",
                                        question: "Please enter the expense payments made on behalf of the employee expense/benefit",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Please select any benefits appearing on your P11D form",
                                                answer: "Expense payments made on behalf of the employee",
                                            },
                                        ],
                                    },
                                ],
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: taxReturnPrompt,
                                    manualOpen: true,
                                    title: "P11D Form",
                                    text: cleanHTMLString(`<div>If you received a P11D from your employer this tax year:
                                        <div>
                                            Find and enter the total monetary value of benefits from the formLook for values in sections such as: 
                                        </div>
                                        <ul>
                                            <li>Company car benefit</li>
                                            <li>Private medical insurance</li>
                                            <li>Other taxable benefits</li>
                                        </ul>
                                        <div>
                                            For detailed guidance, please refer to the <a href="https://assets.publishing.service.gov.uk/media/660bffea38f66c001184a906/SA102_Notes-2024.pdf">HMRC SA102 Notes</a>
                                        </div>
                                            If you have not received a P11D but believe you should have, contact your employer to request the form.
                                        </div>`),
                                    depend: [],
                                },
                            },
                            {
                                id: "EMPLOYMENT_TEMPORARY_WORKPLACE_EXPENSES",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "Temporary Workplace",
                                cards: [
                                    {
                                        id: "EMPLOYMENT_TEMPORARY_WORKPLACE_EXPENSES_CARD",
                                        question: "Did you spend your own money on travel or living expenses directly related to your work for this employer?",
                                        styles: {
                                            fontSize: "16px",
                                        },
                                        tags: ["✈️ Travel"],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                ],
                                questions: [],
                                depend: [],
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: taxReturnPrompt,
                                    manualOpen: true,
                                    title: "Travel Expenses",
                                    text: cleanHTMLString(`<div style="text-align: left">
                                            <div>You can claim travel expenses only if they were “wholly, exclusively, and necessarily” for business purposes, including:</div>
                                            <ul>
                                                <li>Travel to temporary workplaces (e.g., client meetings, business trips).</li>
                                                <li>Public transport costs (train, bus, flights, taxi).</li>
                                                <li>Mileage for using your personal vehicle (car, motorcycle, bicycle).</li>
                                                <li>Parking, tolls, congestion charges.</li>
                                                <li>Hotel and accommodation costs for business trips.</li>
                                                <li>Subsistence (food and drink) during business travel.</li>
                                            </ul>
                                            </div>`),
                                    depend: [],
                                },
                            },
                            {
                                id: "EMPLOYMENT_TRAVEL_EXPENSES",
                                type: QuestionType.selectManyQuestion,
                                questionTitle: "Subsistence Expense",
                                question: "Please select any personal expenses that you've incurred while at work that you haven't already been reimbursed for.",
                                answerRequired: false,
                                baseForExpensesList: true,
                                tags: [],
                                depend: [
                                    {
                                        question: "Did you spend your own money on travel or living expenses directly related to your work for this employer?",
                                        answer: "yes",
                                    },
                                ],
                                options: [
                                    "Subsistence",
                                    "Public Transport",
                                    "Parking",
                                    "Accommodation Expenses",
                                ],
                                iconMapping: SubsistenceExpenseIconMapping,
                            },
                            {
                                id: "EMPLOYMENT_EXPENSES",
                                type: QuestionType.questionsList,
                                question: "",
                                infoText: "",
                                cards: [],
                                depend: [
                                    {
                                        question: "Please select any personal expenses that you've incurred while at work that you haven't already been reimbursed for.",
                                        answer: "Subsistence",
                                        orDepend: true,
                                    },
                                    {
                                        question: "Please select any personal expenses that you've incurred while at work that you haven't already been reimbursed for.",
                                        answer: "Public Transport",
                                        orDepend: true,
                                    },
                                    {
                                        question: "Please select any personal expenses that you've incurred while at work that you haven't already been reimbursed for.",
                                        answer: "Parking",
                                        orDepend: true,
                                    },
                                    {
                                        question: "Please select any personal expenses that you've incurred while at work that you haven't already been reimbursed for.",
                                        answer: "Accommodation Expenses",
                                        orDepend: true,
                                    },
                                ],
                                questions: [
                                    {
                                        id: "EMPLOYMENT_EXPENSES_SUBSISTENCE",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Subsistence",
                                        question: "What was the total amount you spent on food and non-alcoholic drinks?",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Did you spend your own money on travel or living expenses directly related to your work for this employer?",
                                                answer: "yes",
                                            },
                                            {
                                                question: "Please select any personal expenses that you've incurred while at work that you haven't already been reimbursed for.",
                                                answer: "Subsistence",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_EXPENSES_PUBLIC_TRANSPORT",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Public Transport",
                                        question: "What was the total amount you spent on public transport to your temporary workplace?",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Did you spend your own money on travel or living expenses directly related to your work for this employer?",
                                                answer: "yes",
                                            },
                                            {
                                                question: "Please select any personal expenses that you've incurred while at work that you haven't already been reimbursed for.",
                                                answer: "Public Transport",
                                            },
                                        ],
                                        answerRequired: true,

                                    },
                                    {
                                        id: "EMPLOYMENT_EXPENSES_PARKING",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Parking",
                                        question: "What was the total amount you spent on parking at your temporary workplace?",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Did you spend your own money on travel or living expenses directly related to your work for this employer?",
                                                answer: "yes",
                                            },
                                            {
                                                question: "Please select any personal expenses that you've incurred while at work that you haven't already been reimbursed for.",
                                                answer: "Parking",
                                            },
                                        ],
                                        answerRequired: true,

                                    },
                                    {
                                        id: "EMPLOYMENT_EXPENSES_ACCOMMODATION",
                                        type: QuestionType.amountListQuestion,
                                        questionTitle: "Accommodation Expenses",
                                        question: "What was the total amount you spent on accommodation? This may include hotels, hostels, Airbnb, or other lodging.",
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Did you spend your own money on travel or living expenses directly related to your work for this employer?",
                                                answer: "yes",
                                            },
                                            {
                                                question: "Please select any personal expenses that you've incurred while at work that you haven't already been reimbursed for.",
                                                answer: "Accommodation Expenses",
                                            },
                                        ],
                                        answerRequired: true,

                                    },
                                ],
                            },
                            {
                                id: "EMPLOYMENT_EXPENSES_VEHICLES",
                                type: QuestionType.selectManyQuestion,
                                questionTitle: "Vehicles",
                                question: "Select the types of vehicles you used for work purposes during the tax year.",
                                options: [
                                    "🚗 Car/Van",
                                    "🏍️ Motorcycle",
                                    "Bicycle",
                                ],
                                iconMapping: VehiclesIconMapping,
                                tags: [],
                                depend: [
                                    {
                                        question: "Did you spend your own money on travel or living expenses directly related to your work for this employer?",
                                        answer: "yes",
                                    },
                                ],
                                answerRequired: false,
                            },
                            {
                                id: "EMPLOYMENT_EXPENSES_VEHICLES_CAR_MILES",
                                type: QuestionType.questionsList,
                                question: "",
                                infoText: "",
                                cards: [],
                                depend: [
                                    {
                                        question: "Select the types of vehicles you used for work purposes during the tax year.",
                                        answer: "🚗 Car/Van",
                                    },
                                ],
                                questions: [
                                    {
                                        id: "EMPLOYMENT_EXPENSES_VEHICLES_CAR_MILES_AMOUNT",
                                        type: QuestionType.textListQuestion,
                                        inputType: "number",
                                        question: "How many miles did you travel via car/van for work purposes?",
                                        tags: [],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_EXPENSES_VEHICLES_CAR_REIMBURSED",
                                        type: QuestionType.amountListQuestion,
                                        question: "How much have you been reimbursed for your car/van journeys?",
                                        tags: [],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                ],
                            },
                            {
                                id: "EMPLOYMENT_EXPENSES_VEHICLES_MOTORCYCLE_MILES",
                                type: QuestionType.questionsList,
                                question: "",
                                infoText: "",
                                cards: [],
                                depend: [
                                    {
                                        question: "Select the types of vehicles you used for work purposes during the tax year.",
                                        answer: "🏍️ Motorcycle",
                                    },
                                ],
                                questions: [
                                    {
                                        id: "EMPLOYMENT_EXPENSES_VEHICLES_MOTORCYCLE_MILES_AMOUNT",
                                        type: QuestionType.textListQuestion,
                                        inputType: "number",
                                        question: "How many miles did you travel via motorcycle for work purposes?",
                                        tags: [],
                                        depend: [],
                                    },
                                    {
                                        id: "EMPLOYMENT_EXPENSES_VEHICLES_MOTORCYCLE_REIMBURSED",
                                        type: QuestionType.amountListQuestion,
                                        question: "How much have you been reimbursed for your motorcycle journeys?",
                                        tags: [],
                                        depend: [],
                                    },
                                ],

                            },
                            {
                                id: "EMPLOYMENT_EXPENSES_VEHICLES_CYCLE_MILES",
                                type: QuestionType.questionsList,
                                question: "",
                                infoText: "",
                                cards: [],
                                depend: [
                                    {
                                        question: "Select the types of vehicles you used for work purposes during the tax year.",
                                        answer: "Bicycle",
                                    },
                                ],
                                questions: [
                                    {
                                        id: "EMPLOYMENT_EXPENSES_VEHICLES_CYCLE_MILES_AMOUNT",
                                        type: QuestionType.textListQuestion,
                                        inputType: "number",
                                        question: "How many miles did you travel via bicycle for work purposes?",
                                        tags: [],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "EMPLOYMENT_EXPENSES_VEHICLES_CYCLE_REIMBURSED",
                                        type: QuestionType.amountListQuestion,
                                        question: "How much have you been reimbursed for your bicycle journeys?",
                                        tags: [],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                ],
                            },
                            {
                                id: "EMPLOYMENT_EXPENSES_WFH",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "Working from Home Expenses",
                                cards: [
                                    {
                                        id: "EMPLOYMENT_EXPENSES_WFH_CARD",
                                        question: "<div>Did you <strong>have</strong> to work from home during the tax year?</div>",
                                        styles: {
                                            fontSize: "16px",
                                        },
                                        tags: ["🏠 WFH"],
                                        depend: [],
                                    },
                                ],
                                questions: [],
                                depend: [],
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: workFromHomePrompt,
                                    manualOpen: true,
                                    title: "Working from Home",
                                    text: cleanHTMLString(`<div>
                                        <p>If you had to work from home because of your employment, you might be able to claim tax relief on some of your costs.</p>
                                
                                        <div><strong>Eligibility</strong></div>
                                        <ul>
                                            <li>Your employer required you to work from home (not just an option).</li>
                                            <li>You incurred additional costs (e.g., heating, electricity, internet).</li>
                                            <li>Your employer did not fully reimburse you for these costs.</li>
                                        </ul>
                                
                                        <div><strong>How We Calculate It</strong></div>
                                        <p>TaxZap uses the simplified method (flat rate £6 per week or £312 per year) for ease and to avoid the need for receipts. This ensures you get tax relief without extra paperwork.</p>
                                
                                        <div><strong>Important:</strong></div>
                                        <ul>
                                            <li>You cannot claim for costs that would stay the same regardless of working from home (e.g., mortgage, rent).</li>
                                            <li>If your employer reimbursed your expenses, you cannot claim this relief.</li>
                                        </ul>
                                    </div>`),
                                    depend: [],
                                },
                            },
                            {
                                id: "EMPLOYMENT_EXPENSES_WFH_CLAIM",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "",
                                cards: [
                                    {
                                        id: "EMPLOYMENT_EXPENSES_WFH_CLAIM_CARD",
                                        question: "Have you already claimed the £6 per week work from home allowance?",
                                        styles: {
                                            fontSize: "16px",
                                        },
                                        tags: ["🏠 WFH"],
                                        depend: [],
                                    },
                                ],
                                questions: [],
                                depend: [
                                    {
                                        question: "<div>Did you <strong>have</strong> to work from home during the tax year?</div>",
                                        answer: "yes",
                                    },
                                ],
                            },
                            // --------------------------------- Work from Home Expenses
                            {
                                id: "EMPLOYMENT_EXPENSES_WFH_EXPENSES",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "Working from home expenses",
                                cards: [
                                    {
                                        id: "EMPLOYMENT_EXPENSES_WFH_EXPENSES_CARD",
                                        question: "Did you have any work from home expenses?",
                                        styles: {
                                            fontSize: "16px",
                                        },
                                        tags: ["🏠 WFH"],
                                        depend: [],
                                    },
                                ],
                                questions: [],
                                depend: [
                                    {
                                        question: "<div>Did you <strong>have</strong> to work from home during the tax year?</div>",
                                        answer: "yes",
                                    },
                                    {
                                        question: "Have you already claimed the £6 per week work from home allowance?",
                                        answer: "no",
                                    },
                                ],
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: workFromHomePrompt,
                                    manualOpen: true,
                                    title: "Working from Home Setup",
                                    text: cleanHTMLString(`<div>
                                    <p>If you had to work from home because of your employment, you might be able to claim tax relief on some of your costs.</p>
                            
                                    <div><strong>Eligibility</strong></div>
                                    <ul>
                                        <li>Your employer required you to work from home (not just an option).</li>
                                        <li>You incurred additional costs (e.g., heating, electricity, internet).</li>
                                        <li>Your employer did not fully reimburse you for these costs.</li>
                                    </ul>
                            
                                    <div><strong>How We Calculate It</strong></div>
                                    <p>TaxZap uses the simplified method (flat rate £6 per week or £312 per year) for ease and to avoid the need for receipts. This ensures you get tax relief without extra paperwork.</p>
                                </div>`),
                                    depend: [],
                                },
                            },
                            {
                                id: "EMPLOYMENT_EXPENSES_WFH_EXPENSES_HOURS",
                                type: QuestionType.textQuestion,
                                inputType: "number",
                                unit: "hours",
                                question: "On average how many hours a week do you work or expect to work from home?",
                                tags: [],
                                depend: [
                                    {
                                        question: "<div>Did you <strong>have</strong> to work from home during the tax year?</div>",
                                        answer: "yes",
                                    },
                                    {
                                        question: "Have you already claimed the £6 per week work from home allowance?",
                                        answer: "no",
                                    },
                                    {
                                        question: "Did you have any work from home expenses?",
                                        answer: "yes",
                                    },
                                ],
                                answerRequired: true,
                            },
                        ],
                    },
                ],
            },
            // --------------------------------- Employment section is done
            {
                title: "Employment section is complete!",
                type: StepType.categoryDone,
                description: "Your answers are saved successfully.",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                allowContinue: true,
                animation: pigFinish,
                isAnimation: true,
                questions: [],
            },
        ],
    },
    // --------------------------------- CIS
    {
        title: CategoryTitle.CIS,
        showAnswersInAdmin: true,
        steps: [
            {
                title: "CIS",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                depend: [],
                parentProfileType: ParentProfileType.cis,
                profileType: ProfileType.cis,
                questions: [
                    {
                        id: "CIS_EMPLOYMENT_PROFILES",
                        type: QuestionType.profileQuestion,
                        itemTitle: "CIS Employment",
                        itemName: "CIS Employment",
                        question: "Add a CIS Employment",
                        infoText: "Add all of your contracting jobs below.",
                        addButtonText: "+ Add CIS Employment",
                        itemNameFromQuestionAnswer: true,
                        depend: [],
                        promptModal: {
                            type: PromptModalType.info,
                            title: "Helpful Tips",
                            text: cleanHTMLString(`Before you start, it's handy to have the following:
                            <ul>
                                <li>CIS deduction statements</li>
                                <li>Details of travel (mileage or car costs)</li>
                                <li>If you've connected your bank account(s) we'll find all your expenses! If you track your earnings on a spreadsheet or app, keep this to hand.</li>
                            </ul>
                            `),
                            depend: [],
                            showModalOpener: true,
                            manualOpen: true,
                        },
                        questions: [
                            {
                                id: "CIS_EMPLOYMENT_NAME",
                                type: QuestionType.textQuestion,
                                question: "Please provide the name of the contractor you were subcontracted by.",
                                isItemName: true,
                                tags: [],
                                depend: [],
                                shouldUpdateProfile: true,
                                answerRequired: true,
                            },
                            {
                                id: "CIS_EMPLOYMENT_CATEGORY",
                                type: QuestionType.selectOneOptionQuestionV2,
                                questionTitle: "CIS Employment Description",
                                question: "Please select the category of your CIS employment",
                                options: [
                                    "Alterations, repairs and decoration",
                                    "Building work",
                                    "Cleaning",
                                    "Demolition and dismantling",
                                    "Installing systems",
                                    "Other",
                                ],
                                iconMapping: CisIconMapping,
                                answerRequired: true,
                            },
                            {
                                id: "CIS_EMPLOYMENT_CATEGORY_OTHER",
                                type: QuestionType.textQuestion,
                                question: "Please specify the category of your CIS employment",
                                tags: [],
                                depend: [
                                    {
                                        question: "Please select the category of your CIS employment",
                                        answer: "Other",
                                    },
                                ],
                                answerRequired: true,
                            },
                            {
                                id: "CIS_EMPLOYMENT_ADDRESS_SELECT",
                                type: QuestionType.selectOneOptionQuestion,
                                question: "Select the address of your business",
                                infoText: "If your self-employment business is associated with a different postcode, provide this. If not, then it is likely to be the postcode of your home.",
                                tags: [],
                                options: [
                                    "Account address",
                                    "Other",
                                ],
                                depend: [],
                                minRows: 1,
                                maxRows: 1,
                                answerRequired: true,
                            },
                            {
                                id: "CIS_EMPLOYMENT_ADDRESS_OTHER",
                                type: QuestionType.textQuestion,
                                question: "Enter postcode of your business address",
                                tags: [],
                                depend: [
                                    {
                                        question: "Select the address of your business",
                                        answer: "Other",
                                    },
                                ],
                                answerRequired: true,
                            },
                            {
                                id: "CIS_EMPLOYMENT_ADDITIONAL_INFO_TRADING_NAME",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "",
                                cards: [
                                    {
                                        id: "CIS_EMPLOYMENT_TRADING_NAME_CHANGE",
                                        question: "Did your business name change during the tax year?",
                                        tags: ["📋 Business"],
                                        answerRequired: true,
                                        depend: [],
                                    },
                                ],
                            },
                            {
                                id: "CIS_EMPLOYMENT_OLD_TRADING_NAME",
                                type: QuestionType.textQuestion,
                                question: "Enter the previous business name",
                                tags: [],
                                depend: [
                                    {
                                        question: "Did your business name change during the tax year?",
                                        answer: "yes",
                                    },
                                ],
                                answerRequired: true,
                            },
                            {
                                id: "CIS_EMPLOYMENT_ADDITIONAL_INFO_ADDRESS_CHANGE",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "",
                                cards: [
                                    {
                                        id: "CIS_EMPLOYMENT_TRADING_ADDRESS_CHANGE",
                                        question: "Did your business address change during the tax year?",
                                        tags: ["📋 Business"],
                                        answerRequired: true,
                                        depend: [],
                                    },
                                ],
                            },
                            {
                                id: "CIS_EMPLOYMENT_TRADING_OLD_POSTCODE",
                                type: QuestionType.textQuestion,
                                question: "Enter the post code of your previous business address",
                                tags: [],
                                depend: [
                                    {
                                        question: "Did your business address change during the tax year?",
                                        answer: "yes",
                                    },
                                ],
                                answerRequired: true,

                            },
                            {
                                id: "CIS_EMPLOYMENT_TRADING_DATE",
                                type: QuestionType.selectOneOptionQuestionV2,
                                question: "Have you started or stopped working as a CIS subcontractor during this financial year?",
                                options: [
                                    "Started working",
                                    "Stopped working",
                                    "None of the above",
                                ],
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            {
                                id: "CIS_EMPLOYMENT_START_TRADING_DATE",
                                type: QuestionType.dateQuestion,
                                question: "Please enter the date in which you started working under the CIS as a subcontractor",
                                tags: [],
                                depend: [
                                    {
                                        question: "Have you started or stopped working as a CIS subcontractor during this financial year?",
                                        answer: "Started working",
                                    },
                                ],
                                answerRequired: true,
                            },
                            {
                                id: "CIS_EMPLOYMENT_CEASE_TRADING_DATE",
                                type: QuestionType.dateQuestion,
                                minDate: "06/04/2024",
                                maxDate: "05/04/2025",
                                question: "Please enter the date in which you stopped working under the CIS as a subcontractor",
                                tags: [],
                                depend: [
                                    {
                                        question: "Have you started or stopped working as a CIS subcontractor during this financial year?",
                                        answer: "Stopped working",
                                    },
                                ],
                                answerRequired: true,
                            },
                            {
                                id: "CIS_EMPLOYMENT_CIS_DEDUCTION",
                                type: QuestionType.documentParseQuestion,
                                question: "Upload CIS Deduction Statement(s)",
                                uploadType: UploadType.cisDeductionStatement,
                                depend: [],
                            },
                            // --------------------------------- CIS Income Summary
                            {
                                id: "CIS_EMPLOYMENT_INCOME_SUMMARY",
                                type: QuestionType.profileIncomeSummary,
                                question: "Please review and confirm your CIS gross income",
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            // --------------------------------- Business losses
                            {
                                id: "CIS_EMPLOYMENT_BUSINESS_LOSSES",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                cards: [
                                    {
                                        id: "CIS_EMPLOYMENT_BUSINESS_LOSSES_CARD",
                                        question: "Do you have any business losses to bring forward from the previous year?",
                                        tags: ["📉 Business Loss"],
                                        styles: {
                                            fontSize: "18px",
                                        },
                                        depend: [],
                                        answerRequired: true,
                                    },
                                ],
                                depend: [],
                            },
                            {
                                id: "CIS_EMPLOYMENT_BUSINESS_LOSSES_AMOUNT",
                                type: QuestionType.amountQuestion,
                                question: "What is the total amount of losses brought forward?",
                                tags: [],
                                depend: [
                                    {
                                        question: "Do you have any business losses to bring forward from the previous year?",
                                        answer: "yes",
                                    },
                                ],
                                answerRequired: true,
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: businessLosses,
                                    manualOpen: true,
                                    title: "Business Losses",
                                    text: "If you're self-employed or a member of a trading partnership, you'll usually make a loss when the trade expenses are more than the income. The notes for self-employment and partnership pages of your tax return explain how to work out the profit or loss for tax.",
                                    depend: [
                                        {
                                            question: "Do you have any business losses to bring forward from the previous year?",
                                            answer: "yes",
                                        },
                                    ],
                                },
                            },
                            // --------------------------------- Capital Allowances Self Employment
                            {
                                id: "CIS_USED_VEHICLE",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                cards: [
                                    {
                                        id: "SELF_EMPLOYMENT_USED_VEHICLE_QUESTION",
                                        question: "Did you use a vehicle for business purposes this year?",
                                        tags: ["🚗 Business Vehicles"],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                ],
                                tags: [],
                                depend: [],
                            },
                            {
                                id: "CIS_VEHICLE_DETAILS_ARRAY",
                                type: QuestionType.arrayQuestion,
                                question: "Add each vehicle used this year for this CIS employment",
                                itemTitle: "Vehicle",
                                addButtonText: "+ Add Vehicle",
                                itemName: "Vehicle",
                                itemNameFromQuestionAnswer: true,
                                answerRequired: false,
                                depend: [
                                    {
                                        question: "Did you use a vehicle for business purposes this year?",
                                        answer: "yes",
                                    },
                                ],
                                questions: [
                                    {
                                        id: "CIS_VEHICLE_DETAILS_ARRAY",
                                        type: QuestionType.selectOneOptionQuestionV2,
                                        question: "What type of vehicle is this?",
                                        answerRequired: true,
                                        options: ["Car", "Van/Truck", "Motorbike", "Bicycle"],
                                        iconMapping: VehicleTypeIconMapping,
                                        tags: [],
                                        depend: [],
                                    },
                                    {
                                        id: "CIS_CAR_MAKE_MODEL",
                                        type: QuestionType.textQuestion,
                                        question: "What make and model is this vehicle?",
                                        isItemName: true,
                                        shouldUpdateProfile: true,
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                    {
                                        id: "CIS_CAR_CLAIM_METHOD",
                                        type: QuestionType.selectOneOptionQuestionV2,
                                        questionTitle: "Choose How to Record Your Vehicle Expenses",
                                        question: "How would you like to claim for this vehicle?",
                                        infoText: cleanHTMLString(`<div>To stay compliant with tax rules, you must use the same method as in your previous self-assessment.
                                                <ul>
                                                    <li>
                                                        <strong>Total Vehicle Cost:</strong> Deduct the cost of your vehicle using capital allowances, along with all running expenses.
                                                    </li>
                                                    <li>
                                                        <strong>Mileage Rate:</strong> Claim a fixed rate per mile, but you cannot switch to total costs later unless you get a new vehicle.
                                                    </li>
                                                </ul>
                                                </div>`),
                                        options: [
                                            "Total Vehicle Costs (Capital Allowances)",
                                            "Simple Mileage Rate",
                                        ],
                                        answerRequired: true,
                                        iconMapping: VehicleExpensesIconMapping,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },

                                    // -----------------------------
                                    // Simple Mileage Rate
                                    // -----------------------------
                                    {
                                        id: "CIS_MILEAGE_DRIVEN",
                                        type: QuestionType.textQuestion,
                                        question: "Enter total business miles travelled this year",
                                        inputType: "number",
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Simple Mileage Rate",
                                            },
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                orDepend: true,
                                            },
                                        ],
                                    },

                                    // -----------------------------
                                    // Capital Allowances (Restricted to Cars, Vans, Motorbikes)
                                    // -----------------------------
                                    {
                                        id: "CIS_CAR_TYPE_NEW_USED",
                                        type: QuestionType.selectOneOptionQuestion,
                                        question: "Did you buy this vehicle brand new or second hand?",
                                        options: ["Brand New", "Second Hand"],
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                    {
                                        id: "CIS_CAR_PURCHASE_DATE",
                                        type: QuestionType.dateQuestion,
                                        question: "Enter the purchase date",
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                    {
                                        id: "CIS_CAR_PURCHASE_PRICE",
                                        type: QuestionType.amountQuestion,
                                        question: "Enter the purchase price of the vehicle",
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                    {
                                        id: "CIS_CAR_SALE",
                                        type: QuestionType.selectOneOptionQuestion,
                                        question: "Have you sold the vehicle this financial year?",
                                        options: ["Yes", "No"],
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                    {
                                        id: "CIS_CAR_SALE_DATE",
                                        type: QuestionType.dateQuestion,
                                        question: "Enter the sale date",
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Have you sold the vehicle this financial year?",
                                                answer: "Yes",
                                            },
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                        ],
                                    },
                                    {
                                        id: "CIS_CAR_SALE_PRICE",
                                        type: QuestionType.amountQuestion,
                                        question: "Enter the sale price of the vehicle",
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "Have you sold the vehicle this financial year?",
                                                answer: "Yes",
                                            },
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                        ],
                                    },
                                    {
                                        id: "CIS_CAR_EMISSION_DETAILS",
                                        type: QuestionType.selectOneOptionQuestion,
                                        question: "Select the vehicle's CO₂ emissions (g/km)",
                                        options: [
                                            "0g/km",
                                            "1-50g/km",
                                            "51-75g/km",
                                            "76-95g/km",
                                            "96-110g/km",
                                            "111-130g/km",
                                            "131-160g/km",
                                            "160g/km+",
                                        ],
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                    {
                                        id: "CIS_EMPLOYMENT_CAR_PERSONAL_USAGE",
                                        type: QuestionType.selectOneOptionQuestion,
                                        question: "Select the personal usage percentage (%)",
                                        options: [
                                            "0% (only using for business)",
                                            "10% (rarely)",
                                            "30% (sometimes)",
                                            "50% (generally)",
                                            "70% (often)",
                                            "90% (regularly)",
                                            "Other",
                                        ],
                                        answerRequired: true,
                                        tags: [],
                                        depend: [
                                            {
                                                question: "How would you like to claim for this vehicle?",
                                                answer: "Total Vehicle Costs (Capital Allowances)",
                                            },
                                            {
                                                question: "What type of vehicle is this?",
                                                answer: "Bicycle",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                            // --------------------------------- CIS Expenses OB New
                            {
                                id: "CIS_EMPLOYMENT_EXPENSES",
                                type: QuestionType.profileExpensesNew,
                                question: "Please swipe left (no) or right (yes) to refundable CIS expenses.",
                                questions: [],
                                depend: [
                                    {
                                        question: "Please swipe left (no) or right (yes) to refundable CIS expenses.",
                                        conditionType: ConditionType.hasReviewableExpensesTransactions,
                                        conditionValue: true,
                                    },
                                ],
                            },
                            // --------------------------------- CIS Profile Summary
                            {
                                id: "CIS_EMPLOYMENT_EXPENSES_SUMMARY",
                                type: QuestionType.profileExpensesSummary,
                                question: "Please review and confirm your CIS expenses.",
                                questions: [],
                                depend: [],
                                answerRequired: true,
                            },
                        ],
                    },
                ],
            },
            // --------------------------------- CIS section is done
            {
                title: "CIS section is complete!",
                type: StepType.categoryDone,
                description: "Your answers are saved successfully.",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                allowContinue: true,
                animation: pigFinish,
                isAnimation: true,
                questions: [],
            },
        ],
    },
    // --------------------------------- Rental
    {
        title: CategoryTitle.Rental,
        showAnswersInAdmin: true,
        steps: [
            {
                title: "Rental",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                depend: [],
                parentProfileType: ParentProfileType.rental,
                questions: [
                    {
                        id: "RENTAL_PROFILES",
                        type: QuestionType.profileQuestion,
                        itemTitle: "Rental Property",
                        itemName: "Rental Property",
                        question: "Add a Rental Property",
                        infoText: "If you earned income by renting out a room, property, or furnished holiday let, add the details of each property here.",
                        addButtonText: "+ Add Rental Property",
                        itemNameFromQuestionAnswer: true,
                        depend: [],
                        questions: [
                            {
                                id: "RENTAL_TYPE",
                                type: QuestionType.selectProfileTypeQuestion,
                                question: "What type of property did you lease out in the financial year?",
                                options: [
                                    "Residential/Commercial",
                                    "Furnished Holiday Let",
                                    "Rent-a-Room",
                                ],
                                iconMapping: RentalIconMapping,
                                tags: [],
                                depend: [],
                                errorModal: {
                                    type: PromptModalType.info,
                                    image: rentalTypeChangePopup,
                                    title: "Wrong Rental Type?",
                                    text: "Unfortunately you cannot edit the property type of a rental property once selected. If you have selected the wrong property type please delete this rental property and create a new one",
                                },
                                answerRequired: true,
                            },
                            {
                                id: "RENTAL_NAME",
                                type: QuestionType.textQuestion,
                                question: "Please give this rental property a name.",
                                isItemName: true,
                                tags: [],
                                depend: [],
                                shouldUpdateProfile: true,
                                answerRequired: true,
                            },
                            {
                                id: "RENTAL_JOINTLY_OWNED",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "",
                                cards: [
                                    {
                                        id: "RENTAL_JOINTLY_OWNED_CARD",
                                        question: "Is this home jointly owned?",
                                        tags: ["🏠 Property Details"],
                                        depend: [],
                                        answerRequired: true,
                                    },

                                ],
                                questions: [],
                                depend: [],
                            },
                            {
                                id: "RENTAL_INCOME_THRESHOLD",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "",
                                cards: [
                                    {
                                        id: "RENTAL_INCOME_THRESHOLD_CARD",
                                        question: "Was your income less than £7,500 (£3,750 if jointly let) from the Rent-a-Room scheme in the financial year?",
                                        tags: ["🏠 Property Details"],
                                        depend: [
                                            {
                                                question: "What type of property did you lease out in the financial year?",
                                                answer: "Rent-a-Room",
                                            },
                                        ],
                                        answerRequired: true,
                                    },
                                ],
                                questions: [],
                                depend: [
                                    {
                                        question: "What type of property did you lease out in the financial year?",
                                        answer: "Rent-a-Room",
                                    },
                                ],
                            },
                            {
                                id: "RENTAL_GROSS_INCOME",
                                type: QuestionType.amountQuestion,
                                subtype: QuestionSubType.confirmedTotalGross,
                                question: "How much gross income did you receive from the Rent-a-Room scheme in the financial year?",
                                tags: [],
                                questions: [],
                                shouldUpdateProfile: true,
                                depend: [
                                    {
                                        question: "Was your income less than £7,500 (£3,750 if jointly let) from the Rent-a-Room scheme in the financial year?",
                                        answer: "no",
                                    },
                                    {
                                        question: "What type of property did you lease out in the financial year?",
                                        answer: "Rent-a-Room",
                                    },
                                ],
                                answerRequired: true,
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: rentalPrompt,
                                    manualOpen: true,
                                    title: "Rent-a-Room",
                                    text: "Please only provide the gross income you received yourself before tax in the financial year (6th April 2024 - 5th April 2025).",
                                    depend: [
                                        {
                                            question: "Is this home jointly owned?",
                                            answer: "yes",
                                        },
                                    ],
                                },
                            },
                            {
                                id: "RENTAL_MANUAL_INCOME",
                                type: QuestionType.amountQuestion,
                                subtype: QuestionSubType.confirmedTotalGross,
                                question: "How much rental income did you receive in the financial year (before tax)?",
                                tags: [],
                                depend: [
                                    {
                                        question: "What type of property did you lease out in the financial year?",
                                        answer: "Residential/Commercial",
                                    },
                                ],
                                answerRequired: true,
                                shouldUpdateProfile: true,
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: rentalPrompt,
                                    manualOpen: true,
                                    title: "Residential/Commercial Property",
                                    text: "Please only provide the gross rental income you received before tax in the financial year (6th April 2024 - 5th April 2025).",
                                    depend: [
                                        {
                                            question: "Is this home jointly owned?",
                                            answer: "yes",
                                        },
                                    ],
                                },
                            },
                            {
                                id: "RENTAL_MANUAL_INCOME",
                                type: QuestionType.amountQuestion,
                                subtype: QuestionSubType.confirmedTotalGross,
                                question: "How much rental income did you receive in the financial year (before tax)?",
                                tags: [],
                                depend: [
                                    {
                                        question: "What type of property did you lease out in the financial year?",
                                        answer: "Furnished Holiday Let",
                                    },
                                ],
                                answerRequired: true,
                                shouldUpdateProfile: true,
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: rentalPrompt,
                                    manualOpen: true,
                                    title: "Furnished Holiday Let",
                                    text: "Please only provide the gross rental income you received before tax in the financial year (6th April 2024 - 5th April 2025).",
                                    depend: [
                                        {
                                            question: "Is this home jointly owned?",
                                            answer: "yes",
                                        },
                                    ],
                                },
                            },
                            {
                                id: "RENTAL_MORTGAGE",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "",
                                cards: [
                                    {
                                        id: "RENTAL_MORTGAGE_CARD",
                                        question: "Do you have a mortgage?",
                                        tags: ["🏠 Property Details"],
                                        answerRequired: true,
                                        depend: [
                                            {
                                                question: "What type of property did you lease out in the financial year?",
                                                answer: "Rent-a-Room",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                ],
                                depend: [
                                    {
                                        question: "What type of property did you lease out in the financial year?",
                                        answer: "Rent-a-Room",
                                        opposite: true,
                                    },
                                ],
                            },
                            {
                                id: "RENTAL_MORTGAGE_INTEREST_AMOUNT",
                                type: QuestionType.amountQuestion,
                                question: "How much did you pay in interest on the mortgage in the financial year?",
                                tags: [],
                                depend: [
                                    {
                                        question: "Do you have a mortgage?",
                                        answer: "yes",
                                    },
                                    {
                                        question: "What type of property did you lease out in the financial year?",
                                        answer: "Rent-a-Room",
                                        opposite: true,
                                    },
                                ],
                                answerRequired: true,
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: rentalPrompt,
                                    manualOpen: true,
                                    title: "Interest On Mortgage",
                                    text: "If you have a joint mortgage, please only provide the amount you paid in interest in the financial year.",
                                    depend: [
                                        {
                                            question: "Is this home jointly owned?",
                                            answer: "yes",
                                        },
                                    ],
                                },
                            },
                            {
                                id: "RENTAL_PERSONAL_USAGE",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "",
                                cards: [
                                    {
                                        id: "RENTAL_PERSONAL_USAGE_CARD",
                                        question: "Did you use this property for yourself in the last tax year?",
                                        tags: ["🏠 Property Details"],
                                        answerRequired: true,
                                        depend: [
                                            {
                                                question: "What type of property did you lease out in the financial year?",
                                                answer: "Rent-a-Room",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                ],
                                depend: [
                                    {
                                        question: "What type of property did you lease out in the financial year?",
                                        answer: "Rent-a-Room",
                                        opposite: true,
                                    },
                                ],
                            },
                            {
                                id: "RENTAL_PERSONAL_USAGE_DAYS",
                                type: QuestionType.textQuestion,
                                inputType: "number",
                                unit: "days",
                                question: "For how many days in the financial year was this property used privately?",
                                tags: [],
                                depend: [
                                    {
                                        question: "Did you use this property for yourself in the last tax year?",
                                        answer: "yes",
                                    },
                                    {
                                        question: "What type of property did you lease out in the financial year?",
                                        answer: "Rent-a-Room",
                                        opposite: true,
                                    },
                                ],
                                answerRequired: true,
                            },
                            // --------------------------------- Fixed Mileage Rate
                            {
                                id: "RENTAL_FIXED_MILEAGE",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "Fixed mileage rate",
                                cards: [
                                    {
                                        id: "RENTAL_FIXED_MILEAGE_CARD",
                                        question: "Would you like to claim fixed mileage rate for any of your vehicles/bicycles?",
                                        tags: ["🚗 Vehicle"],
                                        styles: {
                                            fontSize: "18px",
                                        },
                                        answerRequired: true,
                                        depend: [
                                            {
                                                question: "What type of property did you lease out in the financial year?",
                                                answer: "Rent-a-Room",
                                                opposite: true,
                                            },
                                        ],
                                    },
                                ],
                                questions: [],
                                depend: [
                                    {
                                        question: "What type of property did you lease out in the financial year?",
                                        answer: "Rent-a-Room",
                                        opposite: true,
                                    },
                                ],
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: fixedMileage,
                                    manualOpen: true,
                                    title: "What is Fixed Mileage Rate?",
                                    text: cleanHTMLString(`<div><div style="text-align: left;">Fixed mileage rates allow landlords to claim tax relief based on miles driven to and from your rental property. </div><ul><li style="text-align: left;">Cars/vans: the rate is <strong>45p</strong> per mile for the first <strong>10,000 miles</strong> and <strong>25p</strong> per mile thereafter.
                                        </li>
                                        <li style="text-align: left;">Motorbikes/scooters: the rate is <strong>24p</strong> per mile.
                                        </li>
                                        <li style="text-align: left;">Cycles: the rate is <strong>20p</strong> per mile.
                                        </li>
                                        </ul><p style="text-align: left;">If you choose to claim fixed mileage rate, you will not be able to claim the total running cost of your vehicle in this tax return or for any future years (unless you change vehicles).</p></div>`),
                                    depend: [],
                                },
                            },
                            {
                                id: "RENTAL_FIXED_MILEAGE_MILES",
                                type: QuestionType.objectQuestion,
                                question: "Please enter how many miles you traveled in the financial year",
                                options: ["🚗 Car", "🚐 Van/Truck", "🏍️ Motorbike", "🚲 Bicycle"],
                                addButtonText: "+Add Miles",
                                unit: "Miles",
                                infoText: "HMRC may require some details to confirm the miles you're claiming for. We recommend keeping a travel log to monitor this.",
                                tags: [],
                                answerRequired: true,
                                depend: [
                                    {
                                        question: "Would you like to claim fixed mileage rate for any of your vehicles/bicycles?",
                                        answer: "yes",
                                    },
                                ],
                            },
                            // --------------------------------- Rental Expenses OB New
                            {
                                id: "RENTAL_EXPENSES",
                                type: QuestionType.profileExpensesNew,
                                question: "Please swipe left (no) or right (yes) to refundable rental expenses.",
                                questions: [],
                                depend: [
                                    {
                                        question: "What type of property did you lease out in the financial year?",
                                        answer: "Rent-a-Room",
                                        opposite: true,
                                    },
                                    {
                                        question: "Please swipe left (no) or right (yes) to refundable rental expenses.",
                                        conditionType: ConditionType.hasReviewableExpensesTransactions,
                                        conditionValue: true,
                                    },
                                ],
                            },
                            // --------------------------------- Rental Profile Summary
                            {
                                id: "RENTAL_EXPENSES_SUMMARY",
                                type: QuestionType.profileExpensesSummary,
                                question: "Please review and confirm your rental expenses.",
                                questions: [],
                                depend: [
                                    {
                                        question: "What type of property did you lease out in the financial year?",
                                        answer: "Rent-a-Room",
                                        opposite: true,
                                    },
                                ],
                                answerRequired: true,
                            },
                        ],

                    },
                ],
            },

            // --------------------------------- Rental section is done
            {
                title: "Rental section is complete!",
                type: StepType.categoryDone,
                description: "Your answers are saved successfully.",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                allowContinue: true,
                animation: pigFinish,
                isAnimation: true,
                questions: [],
            },
        ],
    },
    // --------------------------------- Pension Income
    {
        title: CategoryTitle.PensionIncome,
        showAnswersInAdmin: true,
        steps: [
            {
                title: "Pension Income",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "PENSION_INCOME_OPTIONS_APPLY",
                        type: QuestionType.selectManyQuestion,
                        question: "Which type of pension income did you receive?",
                        options: [
                            "State Pension",
                            "Private Pension",
                        ],
                        iconMapping: PensionIconMapping,
                        tags: [],
                        depend: [],
                        answerRequired: true,
                    },
                ],
            },
            // -------------- State Pension
            {
                title: "Pension Income",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "PENSION_INCOME_STATE_AMOUNT",
                        type: QuestionType.amountQuestion,
                        question: "What was the annual amount of state pension that you received?",
                        tags: [],
                        depend: [
                            {
                                question: "Which type of pension income did you receive?",
                                answer: "State Pension",
                            },
                        ],
                        promptModal: {
                            type: PromptModalType.info,
                            image: pensionPrompt,
                            manualOpen: true,
                            title: "Annual amount of state pension received",
                            text: cleanHTMLString(`<div>
                                <p>This information can be found on your bank statements. Payments are usually made every 4 weeks and you can see them in your bank account. You can also use the State Pension enquiry line at 0800 731 0469 if you prefer to speak with someone directly.</p>
                            </div>`),
                            depend: [],
                        },
                        answerRequired: true,
                    },
                ],
            },
            {
                title: "Pension Income",
                type: StepType.questionnaire,
                description: "Please answer questions about pension income.",
                hasCards: true,
                hasDepends: false,
                hasBack: true,
                cards: [
                    {
                        id: "PENSION_INCOME_LUMP_SUM",
                        question: "Did this include a lump sum payment?",
                        tags: ["🧓 Pension Income"],
                        depend: [
                            {
                                question: "Which type of pension income did you receive?",
                                answer: "State Pension",
                            },
                        ],
                        answerRequired: true,
                    },
                ],
                promptModal: {
                    type: PromptModalType.info,
                    image: pensionPrompt,
                    manualOpen: true,
                    title: "State Pension Lump Sum",
                    text: cleanHTMLString(`<div>
                        <p>You can get a one-off lump sum payment if you defer claiming your State Pension for at least 12 months in a row. This will include interest of 2% above the Bank of England base rate. If you did this during the tax year, provide us with the full amount received and the tax taken off.</p>
                    </div>`),
                    depend: [],
                },
            },
            {
                title: "Pension Income",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "PENSION_INCOME_LUMP_SUM_AMOUNT",
                        type: QuestionType.amountQuestion,
                        question: "What was the state pension lump sum amount that you received?",
                        tags: [],
                        depend: [
                            {
                                question: "Which type of pension income did you receive?",
                                answer: "State Pension",
                            },
                            {
                                question: "Did this include a lump sum payment?",
                                answer: "yes",
                            },
                        ],
                        answerRequired: true,
                    },
                ],
            },
            {
                title: "Pension Income",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "PENSION_INCOME_LUMP_SUM_TAX_PAY",
                        type: QuestionType.amountQuestion,
                        question: "How much tax was deducted from the pension lump sum?",
                        tags: [],
                        depend: [
                            {
                                question: "Which type of pension income did you receive?",
                                answer: "State Pension",
                            },
                            {
                                question: "Did this include a lump sum payment?",
                                answer: "yes",
                            },
                        ],
                        answerRequired: false,
                    },
                ],
            },
            // -------------- Private Pension
            {
                title: "Pension Income",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "PENSION_INCOME_PRIVATE_AMOUNT",
                        type: QuestionType.amountQuestion,
                        question: "What was the annual amount of private pension that you received before tax?",
                        tags: [],
                        depend: [
                            {
                                question: "Which type of pension income did you receive?",
                                answer: "Private Pension",
                            },
                        ],
                        answerRequired: true,
                    },
                ],
            },
            {
                title: "Pension Income",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "PENSION_INCOME_PRIVATE_TAX_PAY",
                        type: QuestionType.amountQuestion,
                        question: "How much tax did you pay on income from your private pension?",
                        infoText: "click 'None' if no tax was withheld or paid",
                        tags: [],
                        depend: [
                            {
                                question: "Which type of pension income did you receive?",
                                answer: "Private Pension",
                            },
                        ],
                        answerRequired: false,
                    },
                ],
            },
            // --------------------------------- Pension Income section is done
            {
                title: "Pension Income section is complete!",
                type: StepType.categoryDone,
                description: "Pension Income coming soon.",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                allowContinue: true,
                animation: pigFinish,
                isAnimation: true,
                questions: [],
            },
        ],
    },
    // --------------------------------- State Benefits
    {
        title: CategoryTitle.StateBenefits,
        showAnswersInAdmin: true,
        steps: [
            // --------------------------------- Options Apply
            {
                title: "State Benefits",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "STATE_BENEFITS_OPTIONS_APPLY",
                        type: QuestionType.selectManyQuestion,
                        question: "Did you receive any of the following in the financial year?",
                        answerRequired: true,
                        options: [
                            "Child Benefit",
                            "Jobseeker's Allowance",
                            "Incapacity Benefit",
                            "None",
                        ],
                        iconMapping: StateBenefitsIconMapping,
                        tags: [],
                        depend: [],
                    },
                ],
            },
            // --------------------------------- Child Benefit
            {
                title: "Child Benefit",
                type: StepType.questionnaire,
                description: "Please answer questions about child benefit.",
                hasCards: true,
                hasDepends: false,
                hasBack: true,
                cards: [
                    {
                        id: "STATE_BENEFITS_CHILD_BENEFIT_PARTNER_EARN",
                        question: "Does your partner earn a higher income than you?",
                        tags: ["🍼 Child Benefit"],
                        depend: [],
                        answerRequired: true,
                    },
                ],
                promptModal: {
                    type: PromptModalType.info,
                    image: childBenefitsPrompt,
                    manualOpen: true,
                    title: "Child Benefit in Your Self-Assessment",
                    text: cleanHTMLString(`<div>
                        <p>If you're receiving Child Benefit, you’ll need to let HMRC know. This is particularly important if you or your partner earn over £50,000, as you may need to pay back part or all of the Child Benefit through the High Income Child Benefit Charge (HICBC).</p>
                        
                            <p><strong>Do you receive Child Benefit?</strong><p>
                            <ul>
                                <li>If you do, you’ll be asked how much you've received during the tax year.</li>
                            </ul>            
                            <p><strong>Do you or your partner earn over £50,000?</strong></p>
                            <ul>
                                <li>If yes, you’ll be asked to report the High Income Child Benefit Charge on your Self-Assessment. This means you may need to pay back part or all of the Child Benefit.</li>
                            </ul> 
                            <p><strong>Why is this important?</strong></p>
                            <ul>
                                <li>If you’re earning over £50,000, the amount of Child Benefit you can keep will be reduced, and you may have to pay it all back through the HICBC.</li>
                                <li>Even if you’re repaying the Child Benefit due to high income, it's important to still claim it as you’ll receive National Insurance credits, which count towards your state pension.</li>
                            </ul> 
                        
                    </div>`),
                },
                depend: [
                    {
                        question: "Did you receive any of the following in the financial year?",
                        answer: "Child Benefit",
                    },
                ],
            },
            {
                title: "Child Benefit",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "STATE_BENEFITS_CHILD_BENEFIT_BENEFIT_PAYMENT",
                        type: QuestionType.amountQuestion,
                        question: "How much did you receive in child benefit payments in the financial year?",
                        tags: ["🍼 Child Benefit"],
                        answerRequired: true,
                        promptModal: {
                            type: PromptModalType.info,
                            image: childBenefitsPrompt,
                            manualOpen: true,
                            title: "Child Benefit",
                            text: cleanHTMLString(`<div>
                                <p>To check how much child benefit you received:</p>
                                <p>Visit the HMRC website and log into your personal account. Navigate to the "Child Benefit" section: Once logged in, go to your "Payments" section, where you can view the total amount of Child Benefit you've received.</p>
                                <p>Alternatively, you can check your bank statements if you receive the payments directly, or contact HMRC for a detailed summary of payments.</p>
                            </div>`),
                            depend: [],
                        },
                    },
                ],
                depend: [
                    {
                        question: "Did you receive any of the following in the financial year?",
                        answer: "Child Benefit",
                    },
                    {
                        question: "Does your partner earn a higher income than you?",
                        answer: "no",
                    },
                ],
            },
            {
                title: "Child Benefit",
                type: StepType.questionnaire,
                description: "Please answer questions about child benefit.",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                questions: [
                    {
                        id: "STATE_BENEFITS_CHILD_BENEFIT_CHILDREN",
                        type: QuestionType.selectOneIconQuestion,
                        question: "For how many children have you received child benefit payments?",
                        initValue: "1",
                        tags: [],
                        icons: {
                            active: babyBoyActive,
                            default: babyBoyDefault,
                        },
                    },
                ],
                depend: [
                    {
                        question: "Did you receive any of the following in the financial year?",
                        answer: "Child Benefit",
                    },
                    {
                        question: "Does your partner earn a higher income than you?",
                        answer: "no",
                    },
                ],
            },
            {
                title: "Child Benefit",
                type: StepType.questionnaire,
                description: "Please answer questions about child benefit.",
                hasCards: true,
                hasDepends: false,
                hasBack: true,
                cards: [
                    {
                        id: "STATE_BENEFITS_CHILD_BENEFIT_STOP_RECEIVING",
                        question: "Did you or your partner stop receiving child benefit payments in the financial year?",
                        tags: ["🍼 Child Benefit"],
                        depend: [],
                        answerRequired: true,
                    },
                ],
                depend: [
                    {
                        question: "Did you receive any of the following in the financial year?",
                        answer: "Child Benefit",
                    },
                    {
                        question: "Does your partner earn a higher income than you?",
                        answer: "no",
                    },
                ],
            },
            {
                title: "Child Benefit",
                type: StepType.questionnaire,
                description: "Please answer questions about child benefit.",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                questions: [
                    {
                        id: "STATE_BENEFITS_CHILD_BENEFIT_STOP_RECEIVING_DATE",
                        type: QuestionType.dateQuestion,
                        question: "When did you stop receiving child benefit payments?",
                        tags: [],
                    },
                ],
                depend: [
                    {
                        question: "Did you receive any of the following in the financial year?",
                        answer: "Child Benefit",
                    },
                    {
                        question: "Did you or your partner stop receiving child benefit payments in the financial year?",
                        answer: "yes",
                    },
                ],
            },
            // --------------------------------- Jobseeker’s Allowance
            {
                title: "Jobseeker's Allowance",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "STATE_BENEFITS_JOBSEEKER_ALLOWANCE_PAYMENT",
                        type: QuestionType.amountQuestion,
                        question: "How much Jobseeker's Allowance did you receive?",
                        tags: [],
                        answerRequired: true,
                        depend: [
                            {
                                question: "Did you receive any of the following in the financial year?",
                                answer: "Jobseeker's Allowance",
                            },
                        ],
                        promptModal: {
                            type: PromptModalType.info,
                            image: jobseekersPrompt,
                            manualOpen: true,
                            title: "Jobseeker's Benefit",
                            text: cleanHTMLString(`<div>
                                <p>Jobseeker's Allowance (JSA) is an unemployment benefit you can claim while looking for work. Use the P60 or P45 that the Department of Work and Pensions (DWP) gave you in order to find the total amount in which you received in the financial year.</p>
                            </div>`),
                            depend: [],
                        },
                    },
                ],
            },
            {
                title: "Jobseeker's Allowance",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "STATE_BENEFITS_JOBSEEKER_ALLOWANCE_TAX_PAID",
                        type: QuestionType.amountQuestion,
                        question: "What was the tax paid or withheld on the Jobseeker's Allowance?",
                        tags: [],
                        answerRequired: false,
                        depend: [
                            {
                                question: "Did you receive any of the following in the financial year?",
                                answer: "Jobseeker's Allowance",
                            },
                        ],
                        promptModal: {
                            type: PromptModalType.info,
                            image: jobseekersPrompt,
                            manualOpen: true,
                            title: "Jobseeker's Benefit",
                            text: cleanHTMLString(`<div>
                                <p>Jobseeker's Allowance (JSA) is an unemployment benefit you can claim while looking for work. Use the P60 or P45 that the Department of Work and Pensions (DWP) gave you in order to find the total amount in which you received in the financial year.</p>
                            </div>`),
                            depend: [],
                        },
                    },
                ],
            },
            // --------------------------------- Incapacity Benefit
            {
                title: "Incapacity Benefit",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "STATE_BENEFITS_INCAPACITY_BENEFIT_PAYMENT",
                        type: QuestionType.amountQuestion,
                        question: "How much Incapacity Benefit did you receive?",
                        tags: [],
                        answerRequired: true,
                        depend: [
                            {
                                question: "Did you receive any of the following in the financial year?",
                                answer: "Incapacity Benefit",
                            },
                        ],
                        promptModal: {
                            type: PromptModalType.info,
                            image: incapacityBenefitPrompt,
                            manualOpen: true,
                            title: "Incapacity Benefit",
                            text: cleanHTMLString(`<div>
                                <p>This benefit was replaced by the Employment and Support Allowance from 27 October 2008. Existing Incapacity Benefit awards at that date will continue to be paid.</p>
                                <p>Use the P60 or P45 that the Department for Work and Pensions (DWP) gave you to help when claiming for this.</p>
                            </div>`),
                            depend: [],
                        },
                    },
                ],
            },
            {
                title: "Incapacity Benefit",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "STATE_BENEFITS_INCAPACITY_BENEFIT_TAX_PAID",
                        type: QuestionType.amountQuestion,
                        question: "What was the tax paid or withheld on the Incapacity Benefit?",
                        tags: [],
                        answerRequired: false,
                        depend: [
                            {
                                question: "Did you receive any of the following in the financial year?",
                                answer: "Incapacity Benefit",
                            },
                        ],
                        promptModal: {
                            type: PromptModalType.info,
                            image: incapacityBenefitPrompt,
                            manualOpen: true,
                            title: "Incapacity Benefit",
                            text: cleanHTMLString(`<div>
                                <p>This benefit was replaced by the Employment and Support Allowance from 27 October 2008. Existing Incapacity Benefit awards at that date will continue to be paid.</p>
                                <p>Use the P60 or P45 that the Department for Work and Pensions (DWP) gave you to help when claiming for this.</p>
                            </div>`),
                            depend: [],
                        },
                    },
                ],
            },
            // --------------------------------- State Benefits section is done
            {
                title: "State Benefits section is complete!",
                type: StepType.categoryDone,
                description: "Your answers are saved successfully.",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                allowContinue: true,
                animation: pigFinish,
                isAnimation: true,
                questions: [],
            },
        ],
    },
    // --------------------------------- Capital Gains/Losses
    {
        title: CategoryTitle.CapitalGains,
        showAnswersInAdmin: true,
        steps: [
            {
                title: "Capital Gains/Losses",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                parentProfileType: ParentProfileType.capitalGainsLosses,
                depend: [],
                questions: [
                    {
                        id: "CAPITAL_GAIN_LOSS_PROFILES",
                        type: QuestionType.profileQuestion,
                        itemTitle: "Capital Gain/Loss",
                        itemName: "Capital Gain/Loss",
                        question: "Add Capital Gains/Loss",
                        infoText: "Please click below to add in a capital gain/loss.",
                        addButtonText: "+ Add Capital Gains/Loss",
                        itemNameFromQuestionAnswer: true,
                        depend: [],
                        questions: [
                            {
                                id: "CAPITAL_GAIN_LOSS_TYPE",
                                type: QuestionType.selectProfileTypeQuestion,
                                question: "Was this a capital gain or capital loss?",
                                options: ["Capital Gain", "Capital Loss"],
                                errorModal: {
                                    type: PromptModalType.info,
                                    image: investmentTypeChangePopup,
                                    title: "Wrong Capital Gain / Loss?",
                                    text: "Unfortunately you cannot change this field after it has been selected. If the information is incorrect please delete this capital gain/loss and add a new entry.",
                                },
                                iconMapping: CapitalIconMapping,
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            {
                                id: "CAPITAL_GAIN_LOSS_ASSET_TYPE",
                                type: QuestionType.selectOneOptionQuestionV2,
                                question: "What type of asset did you make the gain/loss on?",
                                options: [
                                    "Residential Property",
                                    "Listed Shares",
                                    "Unlisted Shares",
                                    "Crypto-currency",
                                    "Other Assets",
                                ],
                                iconMapping: AssetsIconMapping,
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            {
                                id: "CAPITAL_GAIN_LOSS_NAME",
                                type: QuestionType.textQuestion,
                                question: "Let's give this asset a name, this helps keep track of any capital gains/losses",
                                isItemName: true,
                                tags: [],
                                depend: [],
                                shouldUpdateProfile: true,
                                answerRequired: true,
                            },
                            {
                                id: "CAPITAL_GAIN_LOSS_ASSET_VALUES",
                                type: QuestionType.questionsList,
                                question: "Let's fill in the values of the asset",
                                cards: [],
                                questions: [
                                    {
                                        id: "CAPITAL_GAIN_LOSS_ASSET_SELL_AMOUNT",
                                        type: QuestionType.textListQuestion,
                                        inputType: "number",
                                        question: "How many of these assets did you sell?",
                                        tags: [],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "CAPITAL_GAIN_LOSS_ASSET_SELL_VALUE",
                                        type: QuestionType.amountListQuestion,
                                        question: "How much did you sell this asset(s) for?",
                                        tags: [],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "CAPITAL_GAIN_LOSS_ASSET_PURCHASE_COST",
                                        type: QuestionType.amountListQuestion,
                                        question: "Enter any costs relating to purchasing or selling these assets (e.g. marketing).",
                                        tags: [],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "CAPITAL_GAIN_LOSS_ASSET_GAIN_LOSS",
                                        type: QuestionType.amountListQuestion,
                                        question: "Provide capital gains/loss made on these assets?",
                                        tags: [],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                ],
                                depend: [],
                            },
                            {
                                id: "CAPITAL_GAIN_LOSS_REAL_TIME",
                                type: QuestionType.cardArrayQuestion,
                                question: "",
                                infoText: "Please answer the below questions about capital gains/losses.",
                                cards: [
                                    {
                                        id: "CAPITAL_GAIN_LOSS_GOVERNMENT_REPORTING",
                                        question: "Did you report any capital gains/losses using a Government reporting service",
                                        tags: ["📊 Capital Gains/Loss"],
                                        depend: [],
                                    },

                                ],
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: governmentPrompt,
                                    manualOpen: true,
                                    title: "Government Reporting Services",
                                    text: cleanHTMLString(`<div>
                                    <p style="text-align: left">There is a number of services available to report capital gains tax.</p>
                                    <div style="text-align: left;">These include -</div><ol style="padding-left: 20px;">
                                        <li style="text-align: left;">Real Time Capital Gains Tax Service: This service is designed for UK residents with chargeable gains on personally held assets. It includes a broad range of asset disposals, such as shares and other investments.</li>
                                        <li style="text-align: left;">UK Property Tax: Report gains from selling property (other than your main home) within 60 days of the sale.</li>
                                    </ol>
                                    </div>`),
                                    depend: [],
                                },
                            },
                            {
                                id: "CAPITAL_GAIN_LOSS_ASSET_VALUES",
                                type: QuestionType.questionsList,
                                question: "Let's fill in the values of the asset",
                                cards: [],
                                questions: [

                                    {
                                        id: "CAPITAL_GAIN_LOSS_ASSET_GAIN_LOSS_REPORTED",
                                        type: QuestionType.amountListQuestion,
                                        question: "How much of a gain/loss have you already reported using a Government reporting service?",
                                        tags: [],
                                        depend: [],
                                        answerRequired: true,
                                    },
                                    {
                                        id: "CAPITAL_GAIN_LOSS_ASSET_TAX_PAID",
                                        type: QuestionType.amountListQuestion,
                                        question: "How much tax have you paid on the  gains reported using a Government reporting service (if any)?",
                                        tags: [],
                                        depend: [],
                                        answerRequired: false,
                                    },
                                ],
                                depend: [
                                    {
                                        question: "Did you report any capital gains/losses using a Government reporting service",
                                        answer: "yes",
                                    },
                                ],
                            },
                            {
                                id: "CAPITAL_GAIN_LOSS_SUPPORTING_DOCUMENT_UPLOAD",
                                type: QuestionType.uploadFileQuestion,
                                question: "Upload Supporting Documents (Optional)",
                                infoText: "Please upload supporting documents such as statements from Government report services, broker reports, or crypto exchange reports. ",
                                uploadType: UploadType.capitalGainsLossesSupportingDocuments,
                                depend: [],
                            },
                        ],
                    },
                ],
            },
            {
                title: "Capital Gains/losses",
                type: StepType.questionnaire,
                description: "Please answer the below questions about capital gains/losses.",
                hasCards: true,
                hasDepends: false,
                hasBack: true,
                cards: [
                    {
                        id: "CAPITAL_GAIN_LOSS_PREVIOUS_YEAR",
                        question: "Do you have any capital losses from a previous year to bring forward?",
                        tags: ["📊 Capital Gains/Loss"],
                        depend: [],
                        answerRequired: true,
                    },

                ],
            },
            {
                title: "Capital Gains/losses",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "CAPITAL_GAIN_LOSS_PREVIOUS_YEAR_AMOUNT",
                        type: QuestionType.amountQuestion,
                        question: "What is the total amount of capital losses to bring forward?",
                        tags: [],
                        depend: [
                            {
                                question: "Do you have any capital losses from a previous year to bring forward?",
                                answer: "yes",
                            },
                        ],
                        answerRequired: true,
                    },
                ],
            },
            // --------------------------------- Capital Gains section is done
            {
                title: "Capital Gains section is complete!",
                type: StepType.categoryDone,
                description: "Capital Gains coming soon.",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                allowContinue: true,
                animation: pigFinish,
                isAnimation: true,
                questions: [],
            },
        ],
    },
    // --------------------------------- Dividends
    {
        title: CategoryTitle.Dividends,
        showAnswersInAdmin: true,
        steps: [
            {
                title: "Dividends",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                parentProfileType: ParentProfileType.dividends,
                depend: [],
                questions: [
                    {
                        id: "DIVIDENDS_PROFILES",
                        type: QuestionType.profileQuestion,
                        itemTitle: "Dividends",
                        itemName: "Dividends",
                        question: "Add Dividends",
                        infoText: "Excl. Dividends from Individual Savings Accounts (ISA).",
                        addButtonText: "+ Add Dividend",
                        itemNameFromQuestionAnswer: true,
                        depend: [],
                        questions: [
                            {
                                id: "DIVIDENDS_NAME",
                                type: QuestionType.textQuestion,
                                question: "What company/fund was this dividend from?",
                                isItemName: true,
                                tags: [],
                                depend: [],
                                answerRequired: true,
                                shouldUpdateProfile: true,
                            },
                            {
                                id: "DIVIDENDS_TYPE",
                                type: QuestionType.selectProfileTypeQuestion,
                                question: "What type of dividend was this?",
                                options: ["Domestic Dividends", "Director Dividends"],
                                iconMapping: DividendsIconMapping,
                                tags: [],
                                depend: [],
                                errorModal: {
                                    type: PromptModalType.info,
                                    image: investmentTypeChangePopup,
                                    title: "Wrong Dividends Type?",
                                    text: "Unfortunately you cannot edit the dividends type of a once selected. If you have selected the wrong dividends type please delete this entry and create a new one",
                                },
                                answerRequired: true,
                            },
                            // --------------------------------- Domestic Dividends
                            {
                                id: "DIVIDENDS_AMOUNT",
                                type: QuestionType.amountQuestion,
                                subtype: QuestionSubType.confirmedTotalGross,
                                question: "How much did you receive in gross dividends in the financial year?",
                                tags: [],
                                depend: [
                                    {
                                        question: "What type of dividend was this?",
                                        answer: "Domestic Dividends",
                                    },
                                ],
                                answerRequired: true,
                                shouldUpdateProfile: true,
                                amountCategory: "Domestic Dividends",
                            },
                            {
                                id: "DIVIDENDS_TAX_AMOUNT",
                                type: QuestionType.amountQuestion,
                                subtype: QuestionSubType.taxAlreadyPaidOnRevenue,
                                question: "How much tax did you pay (if any) on gross dividends in the financial year?",
                                tags: [],
                                depend: [
                                    {
                                        question: "What type of dividend was this?",
                                        answer: "Domestic Dividends",
                                    },
                                ],
                                answerRequired: false,
                                shouldUpdateProfile: true,
                                amountCategory: "Domestic Dividends",
                            },
                            // --------------------------------- Director Dividends
                            {
                                id: "DIVIDENDS_AMOUNT",
                                type: QuestionType.amountQuestion,
                                subtype: QuestionSubType.confirmedTotalGross,
                                question: "How much did you receive in gross dividends in the financial year?",
                                tags: [],
                                depend: [
                                    {
                                        question: "What type of dividend was this?",
                                        answer: "Director Dividends",
                                    },
                                ],
                                answerRequired: true,
                                shouldUpdateProfile: true,
                                amountCategory: "Director Dividends",
                            },
                            {
                                id: "DIVIDENDS_TAX_AMOUNT",
                                type: QuestionType.amountQuestion,
                                subtype: QuestionSubType.taxAlreadyPaidOnRevenue,
                                question: "How much tax did you pay (if any) on gross dividends in the financial year?",
                                tags: [],
                                depend: [
                                    {
                                        question: "What type of dividend was this?",
                                        answer: "Director Dividends",
                                    },
                                ],
                                answerRequired: false,
                                shouldUpdateProfile: true,
                                amountCategory: "Director Dividends",
                            },
                        ],
                    },
                ],
            },
            // --------------------------------- Dividends section is done
            {
                title: "Dividends section is complete!",
                type: StepType.categoryDone,
                description: "Dividends coming soon.",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                allowContinue: true,
                animation: pigFinish,
                isAnimation: true,
                questions: [],
            },
        ],
    },
    // --------------------------------- Interest Income
    {
        title: CategoryTitle.InterestIncome,
        showAnswersInAdmin: true,
        steps: [
            {
                title: "Interest",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                parentProfileType: ParentProfileType.interest,
                profileType: ProfileType.interest,
                depend: [],
                questions: [
                    {
                        id: "INTEREST_PROFILES",
                        type: QuestionType.profileQuestion,
                        itemTitle: "Interest",
                        itemName: "Interest",
                        question: "Add Interest Income",
                        infoText: "Excluding interest from Individual Savings Accounts (ISA).",
                        addButtonText: "+ Add Interest",
                        itemNameFromQuestionAnswer: true,
                        depend: [],
                        questions: [
                            {
                                id: "INTEREST_NAME",
                                type: QuestionType.textQuestion,
                                question: "Name of asset, bank, or brokerage?",
                                isItemName: true,
                                tags: [],
                                depend: [],
                                shouldUpdateProfile: true,
                                answerRequired: true,
                            },
                            {
                                id: "INTEREST_AMOUNT",
                                type: QuestionType.amountQuestion,
                                subtype: QuestionSubType.confirmedTotalGross,
                                question: "What was the gross amount of interest income earned?",
                                tags: [],
                                depend: [],
                                answerRequired: true,
                                shouldUpdateProfile: true,
                            },
                            {
                                id: "INTEREST_TAX_AMOUNT",
                                type: QuestionType.amountQuestion,
                                subtype: QuestionSubType.taxAlreadyPaidOnRevenue,
                                question: "What was the total tax amount paid on this interest?",
                                tags: [],
                                depend: [],
                                answerRequired: false,
                                shouldUpdateProfile: true,
                            },
                        ],
                    },
                ],
            },
            // --------------------------------- Interest Income section is done
            {
                title: "Interest Income section is complete!",
                type: StepType.categoryDone,
                description: "Interest Income coming soon.",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                allowContinue: true,
                animation: pigFinish,
                isAnimation: true,
                questions: [],
            },
        ],
    },
    // --------------------------------- General
    {
        title: CategoryTitle.General,
        showAnswersInAdmin: true,
        steps: [
            // --------------------------------- Options Apply
            {
                title: "General",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "GENERAL_OPTIONS_APPLY",
                        type: QuestionType.selectManyQuestion,
                        question: "Which of the following apply to you?",
                        options: [
                            "Marriage Allowance",
                            "Blind Person Allowance",
                            "Pension Contributions",
                            "Charitable Giving",
                            "Venture Capital Scheme",
                        ],
                        iconMapping: GeneralOptionsIconMapping,
                        tags: [],
                        depend: [],
                        answerRequired: false,
                    },
                ],
            },
            // --------------------------------- Marriage Allowance
            {
                title: "Marriage Allowance",
                type: StepType.questionnaire,
                description: "Please answer questions about your marital status.",
                hasCards: true,
                hasDepends: false,
                hasBack: true,
                cards: [
                    {
                        id: "GENERAL_MARRIAGE_ALLOWANCE_TRANSFER",
                        question: "Would you or your partner like to transfer part of your Personal Allowance to each other?",
                        tags: ["💍 Marriage Allowance"],
                        depend: [
                            {
                                question: "Which of the following apply to you?",
                                answer: "Marriage Allowance",
                            },
                        ],
                        answerRequired: true,
                    },
                ],
                promptModal: {
                    type: PromptModalType.info,
                    image: marriageAllowancePopup,
                    manualOpen: true,
                    title: "Marriage Allowance",
                    text: cleanHTMLString(`<div>
                        <p>Marriage Allowance lets you transfer £1,260 of your personal tax allowance to your spouse or civil partner, reducing their tax bill by up to £252 per year.</p>
                        <p>To qualify:</p>
                        <ul>
                            <li>You must be married or in a civil partnership.</li>
                            <li>One of you needs to earn less than £12,570 (the personal allowance).</li>
                            <li>The other must be a basic rate taxpayer (earning between £12,571 and £50,270)</li>
                        </ul>
                        <p>It’s a simple way to save on taxes as a couple. If you’re eligible, it can be backdated for up to 4 years!</p>
                    </div>`),
                    depend: [
                        {
                            question: "Which of the following apply to you?",
                            answer: "Marriage Allowance",
                        },
                    ],
                },
            },
            {
                title: "Marriage Allowance",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "GENERAL_MARRIAGE_ALLOWANCE_TRANSFER_TYPE",
                        type: QuestionType.selectOneTilesQuestion,
                        question: "Are you transferring your Personal Allowance to your partner or will they transfer this to you?",
                        options: [
                            "Transfer from Partner",
                            "Transfer to Partner",
                        ],
                        iconMapping: TransferIconMapping,
                        tags: [],
                        depend: [
                            {
                                question: "Would you or your partner like to transfer part of your Personal Allowance to each other?",
                                answer: "yes",
                            },
                            {
                                question: "Which of the following apply to you?",
                                answer: "Marriage Allowance",
                            },
                        ],
                        promptModal: {
                            type: PromptModalType.info,
                            image: marriageAllowancePopup,
                            manualOpen: true,
                            title: "Marriage Allowance",
                            text: cleanHTMLString(`<div>
                                <p>If you earn less than the Personal Allowance, you may be able to transfer a portion of it to your partner to reduce their tax.</p>
                                <p>Likewise, if your partner earns below the Personal Allowance, they can transfer some of their allowance to you.</p>
                            </div>`),
                            depend: [],
                        },
                        answerRequired: true,
                    },
                ],
            },
            {
                title: "Marriage Allowance",
                type: StepType.questionnaire,
                description: "Please answer questions about your marital status.",
                hasCards: true,
                hasDepends: false,
                hasBack: true,
                cards: [
                    {
                        id: "GENERAL_MARRIAGE_ALLOWANCE_CONFIRM_TRANSFER_FROM_PARTNER",
                        question: "Are you sure that you want to transfer a portion of your partner's Personal Allowance to yourself?",
                        tags: ["💍 Marriage Allowance"],
                        depend: [
                            {
                                question: "Did your partner earn an income less than the personal allowance in the financial year?",
                                answer: "yes",
                            },
                            {
                                question: "Would you or your partner like to transfer part of your Personal Allowance to each other?",
                                answer: "yes",
                            },
                            {
                                question: "Which of the following apply to you?",
                                answer: "Marriage Allowance",
                            },
                        ],
                        answerRequired: true,
                    },
                    {
                        id: "GENERAL_MARRIAGE_ALLOWANCE_EARN_LESS_THAN_ALLOWANCE",
                        question: "Did your partner earn an income less than the personal allowance in the financial year?",
                        tags: ["💍 Marriage Allowance"],
                        depend: [
                            {
                                question: "Are you transferring your Personal Allowance to your partner or will they transfer this to you?",
                                answer: "Transfer from Partner",
                            },
                            {
                                question: "Would you or your partner like to transfer part of your Personal Allowance to each other?",
                                answer: "yes",
                            },
                            {
                                question: "Which of the following apply to you?",
                                answer: "Marriage Allowance",
                            },
                        ],
                        answerRequired: true,
                    },
                ],
                promptModal: {
                    type: PromptModalType.info,
                    image: marriageAllowancePopup,
                    manualOpen: true,
                    title: "Marriage Allowance",
                    text: cleanHTMLString(`<div>
                        <p>The Personal Allowance is the amount you can earn tax-free. For most people, this is £12,570 for the 2024/25 tax year.</p>
                    </div>`),
                    depend: [],
                },
            },
            {
                title: "Marriage Allowance",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "GENERAL_MARRIAGE_ALLOWANCE_CONFIRM_TRANSFER_TO_PARTNER",
                        type: QuestionType.questionsList,
                        questionTitle: "To confirm, do you wish to transfer £1,260 to your partner's Personal Allowance?",
                        question: "If this is correct, we need some additional details about your partner.",
                        cards: [],
                        depend: [
                            {
                                question: "Are you transferring your Personal Allowance to your partner or will they transfer this to you?",
                                answer: "Transfer to Partner",
                            },
                            {
                                question: "Would you or your partner like to transfer part of your Personal Allowance to each other?",
                                answer: "yes",
                            },
                            {
                                question: "Which of the following apply to you?",
                                answer: "Marriage Allowance",
                            },
                        ],
                        questions: [
                            {
                                id: "GENERAL_MARRIAGE_ALLOWANCE_PARTNER_FIRST_NAME",
                                type: QuestionType.textListQuestion,
                                inputType: "string",
                                question: "What is your partner's first name?",
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            {
                                id: "GENERAL_MARRIAGE_ALLOWANCE_PARTNER_LAST_NAME",
                                type: QuestionType.textListQuestion,
                                inputType: "string",
                                question: "What is your partner's last name?",
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            {
                                id: "GENERAL_MARRIAGE_ALLOWANCE_PARTNER_NIN",
                                type: QuestionType.textListQuestion,
                                inputType: "string",
                                question: "What is your partner's National Insurance Number?",
                                tags: [],
                                depend: [],
                                validation: {
                                    regExp: "",
                                    error: "Please enter valid National Insurance Number",
                                    type: "nin",
                                },
                                answerRequired: true,
                            },
                            {
                                id: "GENERAL_MARRIAGE_ALLOWANCE_PARTNER_DOB",
                                type: QuestionType.dateListQuestion,
                                question: "What is your partner's date or birth?",
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            {
                                id: "GENERAL_MARRIAGE_ALLOWANCE_PARTNER_MARRIAGE_DATE",
                                type: QuestionType.dateListQuestion,
                                question: "What was the date of your marriage or civil partnership",
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                        ],
                    },
                ],
            },

            // --------------------------------- Blind Person Allowance
            {
                title: "Blind Person Allowance",
                type: StepType.questionnaire,
                description: "Please answer questions about your general tax details.",
                hasCards: true,
                hasDepends: false,
                hasBack: true,
                cards: [
                    {
                        id: "GENERAL_BLIND_PERSON_ALLOWANCE",
                        question: "Are you registered blind or severely sight impaired on a local authority or other register?",
                        tags: ["🧑‍🦯 Blind Person Allowance"],
                        depend: [
                            {
                                question: "Which of the following apply to you?",
                                answer: "Blind Person Allowance",
                            },
                        ],
                        answerRequired: true,
                    },
                ],
            },
            {
                title: "Blind Person Allowance",
                type: StepType.questionnaire,
                description: "Please answer questions about your general tax details.",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "GENERAL_BLIND_PERSON_REGISTER",
                        type: QuestionType.textQuestion,
                        question: "Enter the name of the local authority or other register",
                        tags: [],
                        depend: [
                            {
                                question: "Are you registered blind or severely sight impaired on a local authority or other register?",
                                answer: "yes",
                            },
                            {
                                question: "Which of the following apply to you?",
                                answer: "Blind Person Allowance",
                            },
                        ],
                        answerRequired: true,
                    },
                ],
            },

            // --------------------------------- Pension Contributions
            {
                title: "",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "GENERAL_PENSION_CONTRIBUTIONS",
                        type: QuestionType.arrayQuestionV3,
                        question: "Pension Contribution",
                        infoText: "Please click below to add a pension contribution.",
                        itemTitle: "Pension Contribution",
                        addButtonText: "+ Add Pension Contribution",
                        itemName: "Pension Contribution",
                        itemNameFromQuestionAnswer: true,
                        answerRequired: false,
                        depend: [
                            {
                                question: "Which of the following apply to you?",
                                answer: "Pension Contributions",
                            },
                        ],
                        questions: [
                            {
                                id: "GENERAL_PENSION_CONTRIBUTION_ITEM_NAME",
                                type: QuestionType.textQuestion,
                                question: "What was the name of your pension provider?",
                                isItemName: true,
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            {
                                id: "GENERAL_PENSION_CONTRIBUTION_ITEM_TYPE",
                                type: QuestionType.selectOneTilesQuestion,
                                question: "What type of pension contribution is this?",
                                options: [
                                    "Workplace Pension",
                                    "Private Pension (SIPP)",
                                ],
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            {
                                id: "GENERAL_PENSION_CONTRIBUTION_ITEM_WHEN_MADE",
                                type: QuestionType.selectOneTilesQuestion,
                                question: "Were pension contributions made before tax (net pay agreement) or after tax (relief at source)?",
                                options: [
                                    "Before Tax (Net Pay Agreement)",
                                    "After Tax (Relief at Source)",
                                ],
                                tags: [],
                                depend: [ {
                                    question: "What type of pension contribution is this?",
                                    answer: "Workplace Pension",
                                }],
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: pensionContributionPopup,
                                    title: "Net Pay Agreement Vs Relief at Source",
                                    text: cleanHTMLString(`<div style="color: #707070">
                                        <strong>Net Pay Agreement </strong>
                                        <span>deducts pension contributions from gross salary before tax, automatically reducing taxable income and providing tax relief, commonly used for workplace pensions.</span><br/>
                                        <strong>Relief at Source </strong>
                                        <span>deducts contributions from net salary and applies basic-rate tax relief, with higher-rate taxpayers needing to claim additional relief through their tax return. The main difference is that Net Pay offers automatic relief before tax, while Relief at Source requires the pension provider to claim relief, with higher-rate taxpayers needing to take extra steps.</span> 
                                    </div>`),
                                    depend: [],
                                    manualOpen: true,
                                },
                                answerRequired: true,
                            },
                            {
                                id: "GENERAL_PENSION_CONTRIBUTION_ITEM_AMOUNT",
                                type: QuestionType.amountQuestion,
                                question: "How much did you contribute to your pension?",
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            {
                                id: "GENERAL_PENSION_CONTRIBUTION_ITEM_ONE_OFF_AMOUNT",
                                type: QuestionType.amountQuestion,
                                question: "Of this amount, how much did you contribute in one-off payments?",
                                tags: [],
                                depend: [],
                                promptModal: {
                                    type: PromptModalType.info,
                                    image: pensionContributionPopup,
                                    title: "One-off Payments to Pension Provider",
                                    text: cleanHTMLString(`<div style="color: #707070">
                                        <span>Click 'None' if all your pension payments were made on a consistent basis. HMRC will not adjust your tax code for one off payments.</span>
                                    </div>`),
                                    depend: [],
                                    manualOpen: true,
                                    showModalOpener: true,
                                },
                                answerRequired: false,
                            },
                        ],
                    },
                ],
            },

            // --------------------------------- Charitable Giving
            {
                title: "Charitable Giving",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "GENERAL_CHARITABLE_GIVING_NAME",
                        type: QuestionType.textQuestion,
                        question: "What was the name of the charity in which you donated to?",
                        tags: [],
                        answerRequired: true,
                        depend: [
                            {
                                question: "Which of the following apply to you?",
                                answer: "Charitable Giving",
                            },
                        ],
                    },
                ],
            },
            {
                title: "Charitable Giving",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "GENERAL_CHARITABLE_GIVING_AMOUNT",
                        type: QuestionType.amountQuestion,
                        question: "How much did you donate to this charity via Gift Aid in the financial year?",
                        tags: [],
                        answerRequired: true,
                        promptModal: {
                            type: PromptModalType.info,
                            image: charitableGivingPopup,
                            title: "Charitable Giving",
                            text: cleanHTMLString(`<div style="color: #707070">
                                        <span>We currently only support Gift Aid. By including Gift Aid donations in your Self Assessment, this lets you claim back extra tax relief—reducing your bill or increasing your tax refund. Plus, your declared donations extend your basic rate tax band, meaning more of your income is taxed at the basic rate.</span>
                                    </div>`),
                            depend: [],
                            manualOpen: true,
                            showModalOpener: true,
                        },
                        depend: [
                            {
                                question: "Which of the following apply to you?",
                                answer: "Charitable Giving",
                            },
                        ],
                    },
                ],
            },

            // --------------------------------- Venture Capital Scheme
            {
                title: "Venture Capital Scheme",
                type: StepType.questionnaire,
                description: "",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                parentProfileType: ParentProfileType.ventureCapitalScheme,
                depend: [
                    {
                        question: "Which of the following apply to you?",
                        answer: "Venture Capital Scheme",
                    },
                ],
                questions: [
                    {
                        id: "GENERAL_VENTURE_CAPITAL_SCHEME_PROFILES",
                        type: QuestionType.profileQuestion,
                        itemTitle: "Venture Capital Scheme",
                        itemName: "Venture Capital Scheme",
                        question: "Add Venture Capital Scheme",
                        infoText: "Please click below to add a venture capital scheme.",
                        addButtonText: "+ Add Venture Capital Scheme",
                        itemNameFromQuestionAnswer: true,
                        depend: [],
                        questions: [
                            {
                                id: "GENERAL_VENTURE_CAPITAL_SCHEME_TYPE",
                                type: QuestionType.selectOneTilesQuestion,
                                question: "Select venture capital scheme",
                                options: ["EIS", "VCT"],
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            {
                                id: "GENERAL_VENTURE_CAPITAL_SCHEME_UIR",
                                type: QuestionType.textQuestion,
                                question: "Enter the Unique Investment Reference (UIR)",
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            {
                                id: "GENERAL_VENTURE_CAPITAL_SCHEME_COMPANY_NAME",
                                type: QuestionType.textQuestion,
                                question: "Enter the invested company name",
                                isItemName: true,
                                tags: [],
                                depend: [],
                                answerRequired: true,
                                shouldUpdateProfile: true,
                            },
                            {
                                id: "GENERAL_VENTURE_CAPITAL_SCHEME_AMOUNT",
                                type: QuestionType.amountQuestion,
                                question: "How much did you invest in this company?",
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                            {
                                id: "GENERAL_VENTURE_CAPITAL_SCHEME_DATE",
                                type: QuestionType.dateQuestion,
                                question: "Enter the share issue date",
                                tags: [],
                                depend: [],
                                answerRequired: true,
                            },
                        ],
                    },
                ],
            },

            // --------------------------------- Student Loan
            {
                title: "Student Loan",
                type: StepType.questionnaire,
                description: "Please answer questions about your general tax details.",
                hasCards: true,
                hasDepends: false,
                hasBack: true,
                cards: [
                    {
                        id: "GENERAL_STUDENT_LOAN",
                        question: "Do you have a student loan?",
                        tags: ["👩‍🎓 Student Loan"],
                        depend: [],
                        answerRequired: true,
                    },

                ],
            },
            {
                title: "Student Loan",
                type: StepType.questionnaire,
                description: "Please answer questions about your general tax details.",
                hasCards: true,
                hasDepends: false,
                hasBack: true,
                cards: [
                    {
                        id: "GENERAL_STUDENT_LOAN_REPAYMENTS",
                        question: "Have you started making repayments on your student loan?",
                        tags: ["👩‍🎓 Student Loan"],
                        depend: [
                            {
                                question: "Do you have a student loan?",
                                answer: "yes",
                            },
                        ],
                        answerRequired: true,
                    },

                ],
            },
            {
                title: "Student Loan",
                type: StepType.questionnaire,
                description: "Please answer questions about your general tax details.",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                questions: [
                    {
                        id: "GENERAL_STUDENT_LOAN_PLAN",
                        type: QuestionType.selectOneOptionQuestion,
                        question: "What repayment plan are you currently on?",
                        options: [
                            "Plan 1",
                            "Plan 2",
                            "Plan 4",
                            "Postgraduate Loan",
                        ],
                        depend: [
                            {
                                question: "Have you started making repayments on your student loan?",
                                answer: "yes",
                            },
                        ],
                        answerRequired: true,
                    },
                ],
            },

            // --------------------------------- Tax Details
            {
                title: "",
                type: StepType.questionnaire,
                description: "Please answer questions about your general tax details.",
                hasCards: true,
                hasDepends: true,
                hasBack: true,
                promptModal: {
                    type: PromptModalType.info,
                    image: taxDetails,
                    manualOpen: true,
                    title: "",
                    text: cleanHTMLString(`<div>
                        <h3 style="color: black;text-align: left;font-size: 20px">Payments on Account</h3>
                        <p style="text-align: left;font-size: 16px";>If you've filed a self-assessment previously you may have made a payment for the next tax year. These payments are made in January and July. Each payment is usually half of the tax you owed the previous year.</p>
                        <h3 style="color: black;text-align: left; font-size: 20px">Where to Find Your Payments on Account Info</h3>
                        <p style="text-align: left;font-size: 16px">Log in to your HMRC online account, go to “Self Assessment,” and view your “Tax Overview” or “Payment History” to see payments and dates. <br>
                        You can also check your tax calculation letter (SA302) or contact HMRC directly for help!</p> 
                    </div>`),
                    depend: [],
                },
                cards: [
                    {
                        id: "GENERAL_TAX_DETAILS",
                        question: "Did you make any payments towards this year's tax return to HMRC called \"Payments on account\"?",
                        tags: ["🫰 Payments on Account"],
                        depend: [],
                        answerRequired: true,
                    },
                ],
            },
            {
                title: "",
                type: StepType.questionnaire,
                description: "Payments on account made for 2024-2025 tax year",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                allowContinue: true,
                questions: [
                    {
                        id: "GENERAL_TAX_DETAILS_AMOUNT_JAN",
                        type: QuestionType.amountQuestion,
                        question: "Please enter the amount you paid on account by the 31 Jan 2025",
                        tags: [],
                        depend: [
                            {
                                question: "Did you make any payments towards this year's tax return to HMRC called \"Payments on account\"?",
                                answer: "yes",
                            },
                        ],
                        answerRequired: true,
                    },
                ],
            },
            {
                title: "",
                type: StepType.questionnaire,
                description: "Payments on account made for 2024-2025 tax year",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                allowContinue: true,
                questions: [
                    {
                        id: "GENERAL_TAX_DETAILS_AMOUNT_JUL",
                        type: QuestionType.amountQuestion,
                        question: "Please enter the amount you paid on account by the 31 July 2025",
                        tags: [],
                        depend: [
                            {
                                question: "Did you make any payments towards this year's tax return to HMRC called \"Payments on account\"?",
                                answer: "yes",
                            },
                        ],
                        answerRequired: true,
                    },
                ],
            },
            {
                title: "",
                type: StepType.questionnaire,
                description: "Payments on account made for 2024-2025 tax year",
                hasCards: false,
                hasDepends: true,
                hasBack: true,
                allowContinue: true,
                questions: [
                    {
                        id: "GENERAL_TAX_DETAILS_AMOUNT_OTHER",
                        infoText: "If you have made any other payments/credits on your account within the tax year, please enter the total amount above",
                        type: QuestionType.amountQuestion,
                        question: "Any other payments or credits on account?",
                        tags: [],
                        depend: [
                            {
                                question: "Did you make any payments towards this year's tax return to HMRC called \"Payments on account\"?",
                                answer: "yes",
                            },
                        ],
                        answerRequired: true,
                    },
                ],
            },

            // --------------------------------- General section is done
            {
                title: "General section is complete!",
                type: StepType.categoryDone,
                description: "Your answers are saved successfully.",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                allowContinue: true,
                animation: pigFinish,
                isAnimation: true,
                questions: [],
            },
        ],
    },
    // --------------------------------- Review & Submit
    {
        title: CategoryTitle.ReviewAndSubmit,
        showAnswersInAdmin: true,
        steps: [

            // --------------------------------- Payment
            {
                title: "Payment",
                type: StepType.payment,
                description: "Please pay",
                hasCards: false,
                hasDepends: false,
                hasBack: true,
                allowContinue: true,
                questions: [],
            },
            // --------------------------------- Submit
            {
                title: "Submit",
                type: StepType.submit,
                description: "Please review and submit your tax return",
                hasBack: true,
                hasCards: false,
                hasDepends: false,
                allowContinue: true,
                questions: [],
            },
        ],
    },
];
