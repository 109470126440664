import "styles/profile/mobileTabMenu.less";

import { Dispatch, SetStateAction, useCallback, useMemo } from "react";

import { Menu } from "antd";
import bankDetailsDefaultIcon from "assets/images/profile/bank_detail_defaulted.svg";
import bankDetailsSelectedIcon from "assets/images/profile/bank_detail_selected.svg";
import supportDefaultIcon from "assets/images/profile/customer_support_defaulted.svg";
import supportSelectedIcon from "assets/images/profile/customer_support_selected.svg";
import dashboardDefaultIcon from "assets/images/profile/dashboard_defaulted.svg";
import dashboardSelectedIcon from "assets/images/profile/dashboard_selected.svg";
import personalDetailsDefaultIcon from "assets/images/profile/setting_defaulted.svg";
import personalDetailsSelectedIcon from "assets/images/profile/setting_selected.svg";

type MobileTabMenuProps = {
    selectedTab: string;
    setSelectedTab: (_tab: string) => void;
    setPageLoading: Dispatch<SetStateAction<boolean>>;
};

const MobileTabMenu = ({ selectedTab, setSelectedTab, setPageLoading }: MobileTabMenuProps) => {
    const handleMenuClick = useCallback(
        (e: any) => {
            if (selectedTab !== e.key) {
                setPageLoading(true);
            }
            setSelectedTab(e.key);
        },
        [selectedTab, setPageLoading, setSelectedTab]
    );

    const selectedKeys = useMemo(() => {
        const profileTabs = ["Customer Support", "Profile Bank Details", "Personal Details", "Profile"];
        if (profileTabs.includes(selectedTab)) {
            return ["Profile"];
        }
        const paymentTabs = ["Payment", "IDPal"];
        if (paymentTabs.includes(selectedTab)) {
            return ["Payment"];
        }
        return [selectedTab];
    }, [selectedTab]);

    const menuItems = [
        {
            key: "Homepage",
            label: "Dashboard",
            icon: (
                <img
                    src={selectedTab === "Homepage" ? dashboardSelectedIcon : dashboardDefaultIcon}
                    alt="Homepage Icon"
                />
            ),
        },
        // Uncomment if needed:
        // {
        //     key: "Documents",
        //     label: "Docs",
        //     icon: (
        //         <img
        //             src={selectedTab === "Documents" ? documentsSelectedIcon : documentsDefaultIcon}
        //             alt="Documents Icon"
        //         />
        //     ),
        // },
        {
            key: "Personal Details",
            label: "Settings",
            icon: (
                <img
                    src={selectedTab === "Personal Details" ? personalDetailsSelectedIcon : personalDetailsDefaultIcon}
                    alt="Personal Details Icon"
                />
            ),
        },
        {
            key: "Bank Details",
            label: "Bank Details",
            icon: (
                <img
                    src={selectedTab === "Bank Details" ? bankDetailsSelectedIcon : bankDetailsDefaultIcon}
                    alt="Bank Details Icon"
                />
            ),
        },
        {
            key: "Customer Support",
            label: "Support",
            icon: (
                <img
                    src={selectedTab === "Customer Support" ? supportSelectedIcon : supportDefaultIcon}
                    alt="Customer Support Icon"
                    width={32}
                />
            ),
            className: "profile__submenu profile__menu-item",
        },
    ];

    return (
        <div className="footer-tabs-wrapper">
            <Menu
                onClick={handleMenuClick}
                selectedKeys={selectedKeys}
                mode="horizontal"
                className="footer-tabs"
                items={menuItems}
            />
        </div>
    );
};

export default MobileTabMenu;
