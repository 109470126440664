import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import answersReducer from "./reducers/answers";
import arrayQuestionReducer from "./reducers/arrayQuestion";
import auth from "./reducers/auth";
import client from "./reducers/client";
import bankReducer from "./reducers/countries";
import documentReducer from "./reducers/document";
import featureFlagsReducer from "./reducers/featureFlags";
import navigationReducer from "./reducers/navigation";
import paymentReducer from "./reducers/payment";
import taxProfileReducer from "./reducers/taxProfile";
import taxYearsReducer from "./reducers/taxYears";
import { api } from "../services/connection/api";

// Persist only specific reducers
const persistConfig = {
    key: "taxzap",
    storage,
    whitelist: ["auth", "answers", "navigation", "taxProfile", "document", "featureFlags"],
};

// Combine reducers first
const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    auth,
    client,
    answers: answersReducer,
    bank: bankReducer,
    payment: paymentReducer,
    taxProfile: taxProfileReducer,
    arrayQuestion: arrayQuestionReducer,
    navigation: navigationReducer,
    document: documentReducer,
    taxYears: taxYearsReducer,
    featureFlags: featureFlagsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false, // Prevent Redux Persist errors
        }).concat(api.middleware),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
export default store;
