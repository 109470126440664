import { UK_ROUTES_NEW } from "constants/routes-new";

import { useCallback, useContext } from "react";

import { AppContext } from "App";
import blackBackground from "assets/images/home/black-frame.png";
import blackBackgroundMobile from "assets/images/home/black_mobile.png";
import greenBackground from "assets/images/home/green-image.png";
import greenBackgroundMobile from "assets/images/home/green_mobile.png";
import purpleBackground from "assets/images/home/purple-image.png";
import purpleBackgroundMobile from "assets/images/home/purple_mobile.png";
import { useNavigate } from "react-router-dom";
import { scrollToElement } from "utils/common";

const HowItWorks = () => {
    const navigate = useNavigate();
    const {
        maxWidth,
        isMobile,
        toggleFormScale,
    } = useContext(AppContext);

    const handleStart = useCallback(
        (route: string) => {
            if (isMobile) {
                navigate("/sign-up?step=personalInfo");
                window.scrollTo(0, 0);
            } else {
                navigate(route);
                toggleFormScale(true);
                scrollToElement("sign-up");
            }
        },
        [isMobile, navigate, toggleFormScale]
    );
    return (
        <div className={"horizontal-center"}>
            <div className={`how-it-works-container${isMobile ? "-mobile" : "-desktop"}`} style={{ maxWidth: maxWidth }}>
                <div className="uk-how-it-works__banner">
                    <div>
                        <div className={"uk-how-it-works__title"}>
                    Are you ...  🦺  🏠  💼  🤝  🛵?
                        </div>
                        <div className= "mt40">
                            <div className={"mb20 uk-how-it-works__grey_bubble"} onClick={() => handleStart(UK_ROUTES_NEW.constructionwWorkers)}>
                                <div className="uk-how-it-works__type">
                                    🦺 Construction worker (CIS)
                                </div>
                            </div>
                            <div className={"mb20 uk-how-it-works__grey_bubble"} onClick={() => handleStart(UK_ROUTES_NEW.landlord)}>
                                <div className="uk-how-it-works__type">
                            🏠 Renting out property or room
                                </div>
                            </div>
                            <div className={"mb20 uk-how-it-works__grey_bubble"} onClick={() => handleStart(UK_ROUTES_NEW.home)}>
                                <div className="uk-how-it-works__type" >
                            💼 Company director
                                </div>
                            </div>
                            <div className={"mb20 uk-how-it-works__grey_bubble"} onClick={() => handleStart(UK_ROUTES_NEW.home)}>
                                <div className="uk-how-it-works__type">
                            🤝 Small business owner
                                </div>
                            </div>
                            <div className={"mb20 uk-how-it-works__grey_bubble"} onClick={() => handleStart(UK_ROUTES_NEW.deliveryDrivers)}>
                                <div className="uk-how-it-works__type">
                            🛵 Delivery driver
                                </div>
                            </div>
                            <div className={"mb20 uk-how-it-works__grey_bubble"} onClick={() => handleStart(UK_ROUTES_NEW.home)}>
                                <div className="uk-how-it-works__type">
                            🤑 Self-employed
                                </div>
                            </div>
                        </div>
                        <div className="uk-how-it-works__position">
                            <img className="uk-how-it-works__black-box" src={isMobile ? blackBackgroundMobile : blackBackground} alt="blackImage" />
                            <div className="mb20 uk-how-it-works__black-box-position-one">Delivered</div>
                            <div className={"uk-how-it-works__title uk-how-it-works__black-box-position-two"}>
                            125,000+
                            tax returns
                            </div>
                        </div>
                    </div>
                    <div className="uk-how-it-works__boxes-position">
                        <div className="uk-how-it-works__position">
                            <img className="uk-how-it-works__purple-box" src={isMobile ? purpleBackgroundMobile : purpleBackground} alt="purple background box" />
                            <div className="uk-how-it-works__title uk-how-it-works__purple-box-position">
                                Connect your bank account with confidence
                                <div className={"uk-how-it-works__description mb16"}>
                                Your data is <span
                                        className="bold"
                                    >encrypted</span> & <span
                                        className="bold"
                                    >protected</span>, ensuring peace of mind every step of the way.
                                </div>
                            </div>
                        </div>
                        <div className="uk-how-it-works__position">
                            <img className="uk-how-it-works__green-box" src={isMobile ? greenBackgroundMobile : greenBackground} alt="green background box" />
                            <div className="uk-how-it-works__title uk-how-it-works__green-box-position">
                                Catch all your expenses & income
                                <div className={"uk-how-it-works__description"}>
                                Maximize your refund by claiming every eligible expense.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
};

export default HowItWorks;
