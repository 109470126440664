import { REGION } from "constants/common";
import { IInput, UK_SIGNUP_STEP } from "constants/signupFormNew";

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Button, Form, message } from "antd";
import backArrow from "assets/images/arrow-back-grey.svg";
import nextArrow from "assets/images/left-arrow.svg";
import UkCustomFormItem from "components/basic/FormWrapper/UkFormItemWrapper";
import LottieAnimation from "components/basic/Spinner/Lottie";
import UkFloatDatePicker from "components/inputs/Form/Inputs/UkFloatDatePicker";
import UkFloatInput from "components/inputs/Form/Inputs/UkFloatInput";
import UkPhoneInputWithCountryCode from "components/inputs/Form/Inputs/UkPhoneInputWithCountryCode";
import UkToggleQuestion from "components/inputs/Form/Inputs/UkToggleQuestion";
import { CategoryTitle } from "components/pages/NewQuestionnaire/enums/enum";
import { useAppSize } from "hooks/helpers/useAppSize";
import { useLocalStorage } from "hooks/helpers/useLocalStorage";
import { useDeviceData } from "react-device-detect";
import ReactMetaPixel from "react-facebook-pixel";
import { useLocation, useNavigate } from "react-router-dom";
import ReactSnapchatPixel from "react-snapchat-pixel";
// @ts-ignore
import TwitterPixel from "react-twitter-pixel";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { login } from "redux/reducers/auth";
import { setCompletedCategories, setInProgressCategories, setNavigationItems } from "redux/reducers/navigation";
import { useSignInMutation } from "services/auth";
import { checkClientEmailAvailability, checkNinAvailability, useCreateMutation } from "services/client";
import { DependT } from "types/questionnaire";
import { checkDepend } from "utils/builder";
import { logError } from "utils/sentry";
import {
    isEmailValid,
    isNameValid,
    isNINValid,
    isPhoneValid,
    isPostcodeValid,
    isProbableEmailDomainMisspelling,
} from "utils/validation";

import SignInputs from "./SignInputs";
import UkAutoCompleteAddressInput from "./UkAutoCompleteAddressInput";
import UkBooleanQuestion from "./UkBooleanQuestion";
import { CLIENT_CREATED_ACCOUNT } from "../../../constants/events";
import { getCurrentTaxYear } from "../../../constants/taxYears";
import { setCurrentDocumentYear } from "../../../redux/reducers/document";
import { RootState } from "../../../redux/store";
import {
    useCreatePotentialClientMutation,
    useUpdatePotentialClientByIdMutation,
} from "../../../services/potential-clients";
import { IUtmParameters } from "../../../types/potential-clients";
import sendGTMEvent from "../../../utils/gtm-utils";
import sendGaEvent from "../../../utils/react-ga";

export type ErrorsT = {
    [key: string]: string;
};
export type WarningsT = {
    [key: string]: string;
};
export type FormDataT = {
    [key: string]: any;
};
export type EnableValidationT = {
    [key: string]: boolean;
};

type SignDataType = {
    [key: string]: boolean | Blob | null | string;
    termsAgreed: boolean;
    signature: Blob | null;
    referralCode: string;
    password: string;
};

const SighUpFormNew = ({ step, step1VariableInfo }: {
    step?: UK_SIGNUP_STEP,
    step1VariableInfo?: {
        headerLabel: string,
        nextButtonLabel: string,
        infoText: string
    }
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const scrollRef = useRef<HTMLDivElement>(null);
    const prevScrollHeightRef = useRef(0);
    const isMobile = useAppSize();

    const [createClient, { isLoading: isCreatingClient }] = useCreateMutation();
    const [signIn, { isLoading: isSigningIn }] = useSignInMutation();
    const [updatePotentialClientById] = useUpdatePotentialClientByIdMutation();
    const [createPotentialClient] = useCreatePotentialClientMutation();
    const { taxYears } = useAppSelector((state: RootState) => ({
        taxYears: state.taxYears.taxYears,
    }));
    const ukSignupDataNew = useMemo(() => [
        {
            id: UK_SIGNUP_STEP.PERSONAL_INFO,
            headerLabel: step1VariableInfo && step1VariableInfo.headerLabel ? step1VariableInfo.headerLabel : "Start Your Return",
            nextButtonLabel:  step1VariableInfo && step1VariableInfo.nextButtonLabel ? step1VariableInfo.nextButtonLabel : "START TAX RETURN",
            account: "Already have an account? Login",
            backButtonId: null,
            nextButtonId: UK_SIGNUP_STEP.ADDRESS,
            infoText:
                step1VariableInfo && step1VariableInfo.infoText ? step1VariableInfo.infoText : "Enter your details to begin your tax return.",
            inputs: [
                {
                    id: "firstName",
                    depend: [],
                    type: "nameQuestion",
                    name: "First Name",
                    inputName: "given-name",
                    required: true,
                },
                {
                    id: "lastName",
                    depend: [],
                    type: "nameQuestion",
                    name: "Last Name",
                    inputName: "family-name",
                    required: true,
                },
                {
                    id: "dateOfBirth",
                    depend: [],
                    type: "dateQuestion",
                    name: "Date of Birth",
                    inputName: "bday",
                    required: true,
                },
                {
                    id: "email",
                    depend: [],
                    type: "emailQuestion",
                    name: "Email Address",
                    inputName: "email",
                    required: true,
                },
                {
                    id: "phone",
                    depend: [],
                    type: "phoneQuestion",
                    name: "Contact Number",
                    inputName: "tel",
                    required: true,
                },
            ],
        },
        {
            id: UK_SIGNUP_STEP.ADDRESS,
            headerLabel: "Address",
            nextButtonLabel: "CONTINUE TO THE FINAL STEP",
            account: "",
            backButtonId: UK_SIGNUP_STEP.PERSONAL_INFO,
            nextButtonId: UK_SIGNUP_STEP.SIGN,
            inputs: [
                {
                    id: "autocompleteAddress",
                    type: "addressQuestion",
                    depend: [],
                    name: "Start typing to find your address",
                    inputName: "street-address",
                    required: true,
                    simplifiedInputs: [{
                        id: "autocompleteAddress",
                        depend: [],
                        type: "addressQuestion",
                        name: "Type to find Address",
                        required: true,
                    }],
                    inputs: [
                        {
                            id: "address1",
                            depend: [],
                            type: "textQuestion",
                            name: "Address Line 1",
                            inputName: "address-line1",
                            required: false,
                        },
                        {
                            id: "address2",
                            depend: [],
                            type: "textQuestion",
                            name: "Address Line 2",
                            inputName: "address-line2",
                            required: false,
                        },
                        {
                            id: "city",
                            depend: [],
                            type: "textQuestion",
                            name: "Town/City",
                            inputName: "address-level2",
                            required: false,
                        },
                        {
                            id: "country",
                            depend: [],
                            type: "textQuestion",
                            name: "Country",
                            inputName: "country-name",
                            required: true,
                        },
                        {
                            id: "postcode",
                            depend: [],
                            type: "textQuestion",
                            name: "Postcode",
                            inputName: "postal-code",
                            required: true,
                        },
                    ],
                },
            ],
        },
        {
            id: UK_SIGNUP_STEP.SIGN,
            headerLabel: "Final Step",
            nextButtonLabel: "SIGN & SUBMIT",
            account: "",
            backButtonId: UK_SIGNUP_STEP.ADDRESS,
            nextButtonId: null,
            infoText: "Enter password to create an account.",
            inputs: [
                {
                    id: "sign",
                    depend: [],
                    type: "signQuestion",
                    name: "Create password",
                    inputName: "new-password",
                    required: true,
                },
            ],
        },
    ], [step1VariableInfo]);

    useEffect(() => {
        const potentialClientId = localStorage.getItem("potentialClientId");

        if (!potentialClientId) {
            createPotentialClient()
                .unwrap()
                .then(data => {
                    localStorage.setItem("potentialClientId", data.data.potentialClient._id);
                })
                .catch((error: any) => {
                    const contextInfo = {
                        potentialClientId,
                        function: "createPotentialClient",
                        error: error.message,
                    };
                    logError(error, contextInfo);
                });
        }
    }, [createPotentialClient]);

    const deviceData = useDeviceData(window.navigator.userAgent);
    const authData = useAppSelector((state: RootState) => state.auth.authData);
    const [currentStep, setCurrentStep] = useState(UK_SIGNUP_STEP.PERSONAL_INFO);
    const [inputData, setInputData] = useState<FormDataT>({});
    const [errors, setErrors] = useState<ErrorsT>({});
    const [warnings, setWarnings] = useState<WarningsT>({});
    const [enableValidation, setEnableValidation] = useState<EnableValidationT>({});
    const [isCreateAccountChecked, setIsCreateAccountChecked] = useState(true);
    const [passwordError, setPasswordError] = useState<string>("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [forbidden, setForbidden] = useState("");
    const [simpleAddressMode, setSimpleAddressMode] = useState(true);
    const [signData, setSignData] = useState<SignDataType>({
        termsAgreed: true,
        signature: null,
        referralCode: "",
        password: "",
    });

    const [, setRememberMe] = useLocalStorage<boolean | null>("rememberMe", null);

    // removing leading and trailing spaces
    if (inputData["email"]) {
        inputData["email"] = inputData["email"].trim();
    }
    const emailToCheck = inputData["email"];
    const isValidEmail = useMemo(() => isEmailValid(emailToCheck), [emailToCheck]);
    const { data: emailAvailability } =
        checkClientEmailAvailability.useQuery(
            { email: emailToCheck },
            { skip: !isValidEmail }
        );

    const ninToCheck = inputData["nin"];
    const isValidNin = useMemo(() => isNINValid(ninToCheck), [ninToCheck]);
    const { data: ninAvailability } =
        checkNinAvailability.useQuery(
            { nin: ninToCheck },
            { skip: !isValidNin }
        );

    const currentStepData = useMemo(
        () => ukSignupDataNew.find(step => step.id === currentStep),
        [currentStep, ukSignupDataNew]
    );

    const scrollContentToTop = useCallback(() => {
        if (scrollRef.current) {
            const scrollTop = 0; // Scroll to the top
            if (scrollTop !== prevScrollHeightRef.current) {
                scrollRef.current.scrollTo({
                    top: scrollTop, // Scroll to the top position (0)
                    behavior: "smooth", // Smooth scrolling
                });
                prevScrollHeightRef.current = scrollTop;
            }
        }
    }, []);

    const scrollContentToBottom = useCallback(() => {
        if (scrollRef.current) {
            const scrollHeight = scrollRef.current.scrollHeight;
            if (scrollHeight !== prevScrollHeightRef.current) {
                scrollRef.current.scrollTo({
                    top: scrollHeight,
                    behavior: "smooth",
                });
                prevScrollHeightRef.current = scrollHeight;
            }
        }
    }, []);

    const updatePotentialClient = useCallback(() => {
        const utmParametersString = localStorage.getItem("utmParamData");
        const potentialClientId = localStorage.getItem("potentialClientId");
        const utmParameters: IUtmParameters | null = utmParametersString ? JSON.parse(utmParametersString) : null;
        if (potentialClientId) {
            updatePotentialClientById({
                potentialClientId,
                tab: currentStepData?.id || "",
                email: inputData["email"],
                firstName: inputData["firstName"],
                lastName: inputData["lastName"],
                maidenName: inputData["maidenName"] || "",
                dateOfBirth: inputData["dateOfBirth"],
                phone: inputData["phone"],
                address: {
                    street: inputData["address1"],
                    city: inputData["city"],
                    postcode: inputData["postcode"],
                },
                utmParameters: utmParameters,
            });
        }
    }, [currentStepData?.id, inputData, updatePotentialClientById]);

    const handleInputChange = useCallback(
        ({ name, value, object }: { name?: string, value?: string | string[], object?: { [key: string]: string } }) => {
            if (name) {
                setEnableValidation((prev: EnableValidationT) => ({
                    ...prev,
                    [name]: true,
                }));
                setInputData((prev: FormDataT) => ({ ...prev, ...object, [name]: value }));
            } else if (object) {
                setInputData((prev: FormDataT) => ({ ...prev, ...object }));
            } else {
                throw new Error("Name or object are required");
            }
        },
        []
    );

    const validate = useCallback(() => {
        const newErrors: Record<string, any> = {};
        const newWarnings: Record<string, any> = {};

        currentStepData?.inputs.forEach(input => {
            if (input.type === "addressQuestion") {
                const inputValue = inputData[input.id];
                if (input.id === "autocompleteAddress" && inputValue && !inputData["postcode"]) {
                    newErrors[input.id] = "Required to select an address";
                }
                // @ts-ignore
                input.inputs?.forEach(input => {
                    if (enableValidation[input.id]) {
                        const inputValue = inputData[input.id];
                        if (input.id === "postcode") {
                            const postcodeValid = isPostcodeValid(inputValue);
                            if (!postcodeValid) {
                                newErrors[input.id] = "Please enter a valid Postcode (Ex: W1A1HQ)";
                            }
                        }
                        if (input.required && !inputValue) {
                            newErrors[input.id] = "This field is required";
                        }
                    }
                });
            }

            if (enableValidation[input.id]) {
                const inputValue = inputData[input.id];
                if (input.type === "phoneQuestion") {
                    const trimmedValue = inputValue?.trim();
                    if (trimmedValue === "+353" || trimmedValue === "+44") {
                        newErrors[input.id] = input.required
                            ? "Please enter a valid contact number"
                            : "";
                    } else {
                        const isNumberValid = isPhoneValid(trimmedValue);
                        if (!isNumberValid) {
                            if (trimmedValue.startsWith("+353")) {
                                newErrors[input.id] =
                                    "Please enter a valid mobile number. Ex: +353 822 334455";
                            }
                            if (trimmedValue.startsWith("+44")) {
                                newErrors[input.id] =
                                    "Please enter a valid mobile number. Ex: +44 2071 234567";
                            }
                        }
                    }
                }
                if (input.type === "emailQuestion") {
                    const isValid = isEmailValid(inputValue);
                    const isProbableMistakeEmailDomain = isProbableEmailDomainMisspelling(inputValue);
                    if (!isValid) {
                        newErrors[input.id] =
                            "Please enter a valid email address";
                    } else if (isProbableMistakeEmailDomain) {
                        newWarnings[input.id] = "This email address looks unusual. Proceed anyway if you're sure it's correct!";
                    } else if (emailAvailability?.available === false) {
                        newErrors[input.id] =
                            "This email address is already exists";
                    }
                }
                if (input.type === "signQuestion") {
                    if (isCreateAccountChecked) {
                        newErrors[input.id] =
                            !signData.termsAgreed ||
                            // !signData.signature ||
                            !signData.password ||
                            !!passwordError
                                ? "sign data required"
                                : "";
                    } else {
                        newErrors[input.id] =
                            // !signData.signature ||
                            !signData.termsAgreed
                                ? "sign data required"
                                : "";
                    }
                }
                if (input.type === "nameQuestion") {
                    const isValid = isNameValid(inputValue);
                    if (!isValid) {
                        newErrors[input.id] =
                            "Please write your name without an apostrophe";
                    }
                    if (inputValue && inputValue.length < 2) {
                        newErrors[input.id] =
                            "Name must be at least 2 characters long";
                    }
                }
                if (input.required && !inputValue) {
                    newErrors[input.id] = "This field is required";
                }
            }
        });
        setErrors(newErrors);
        setWarnings(newWarnings);
        return { errors: newErrors, warnings: newWarnings };
    }, [
        currentStepData?.inputs,
        emailAvailability,
        enableValidation,
        inputData,
        isCreateAccountChecked,
        passwordError,
        signData.password,
        // signData.signature,
        signData.termsAgreed,
    ]);
    useEffect(() => {
        const finalizeClientCreation = async () => {
            dispatch(setNavigationItems([CategoryTitle.BankAccountConnection, CategoryTitle.PersonalInfo, CategoryTitle.General, CategoryTitle.ReviewAndSubmit]));
            dispatch(setCompletedCategories([CategoryTitle.BankAccountConnection]));
            dispatch(setInProgressCategories([CategoryTitle.PersonalInfo]));
            setInputData({});
            setEnableValidation({});
            setCurrentStep(UK_SIGNUP_STEP.PERSONAL_INFO);
            setForbidden("");
            localStorage.removeItem("potentialClientId");
            dispatch(setCurrentDocumentYear(getCurrentTaxYear(taxYears)));
            navigate("/questionnaire");
        };
        if (authData) {
            finalizeClientCreation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authData]);

    const handleNextClick = useCallback(async () => {
        if (forbidden) {
            setInputData({});
            setEnableValidation({});
            setCurrentStep(UK_SIGNUP_STEP.PERSONAL_INFO);
            setForbidden("");
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("step", UK_SIGNUP_STEP.PERSONAL_INFO);
            navigate(isMobile ? "/" : `${location.pathname}?${searchParams.toString()}`);
        } else if (currentStepData?.nextButtonId) {
            setCurrentStep(currentStepData?.nextButtonId);
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("step", currentStepData?.nextButtonId);
            navigate(`${location.pathname}?${searchParams.toString()}`);
        } else {
            try {
                if (signData.termsAgreed) {
                    const formData = new FormData();
                    formData.append("region", REGION.UK);
                    formData.append("email", inputData["email"]);
                    formData.append("firstName", inputData["firstName"]);
                    formData.append("lastName", inputData["lastName"]);
                    formData.append("phone", inputData["phone"]);
                    formData.append("autocompleteAddress", inputData["autocompleteAddress"]);
                    formData.append("address1", inputData["address1"] || "");
                    formData.append("address2", inputData["address2"] || "");
                    formData.append("city", inputData["city"] || "");
                    formData.append("country", inputData["country"] || "");
                    formData.append("postcode", inputData["postcode"]);
                    formData.append("dateOfBirth", inputData["dateOfBirth"]);
                    formData.append("password", signData.password);
                    formData.append("deviceDetect", JSON.stringify(deviceData));

                    createClient(formData)
                        .unwrap()
                        .then(async () => {
                            sendGaEvent(CLIENT_CREATED_ACCOUNT);
                            sendGTMEvent({
                                event: "SubmitForm",
                                user_data: {
                                    email_address: inputData["email"],
                                    phone_number: inputData["phone"],
                                },
                            });
                            process.env.REACT_APP_META_PIXEL_ID && ReactMetaPixel.fbq("track", "SubmitApplication");
                            process.env.REACT_APP_TWITTER_PIXEL_ID && process.env.REACT_APP_TWITTER_SUBMIT_EVENT_CODE &&
                            TwitterPixel.twq("event", process.env.REACT_APP_TWITTER_SUBMIT_EVENT_CODE, {
                                conversion_id: null, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
                                email_address: inputData["email"],
                                phone_number: inputData["phone"],
                            });
                            process.env.REACT_APP_SNAPCHAT_PIXEL_ID && ReactSnapchatPixel.track("SIGN_UP");

                            if (signData.password) {
                                setRememberMe(true);
                                await signIn({
                                    email: inputData["email"],
                                    password: signData.password,
                                })
                                    .unwrap()
                                    .then(async data => {
                                        await dispatch(login(data.token)); // finalizeClientCreation is called after authData is set
                                    })
                                    .catch(async error => {
                                        await message.error(error);
                                    });
                            }
                        })
                        .catch(error => {
                            const formDataObject = {} as any;
                            formData.forEach((value, key) => {
                                formDataObject[key] = value;
                            });

                            logError(error,
                                {
                                    function: "createClient",
                                    extra: {
                                        email: formDataObject.email,
                                        firstName: formDataObject.firstName,
                                        lastName: formDataObject.lastName,
                                        phone: formDataObject.phone,
                                        address1: formDataObject.address1,
                                        address2: formDataObject.address2,
                                        dateOfBirth: formDataObject.dateOfBirth,
                                        region: formDataObject.region,
                                        city: formDataObject.city,
                                        country: formDataObject.country,
                                        postcode: formDataObject.postcode,
                                    },
                                    potentialClientId: localStorage.getItem("potentialClientId") });
                            message.error(error.data?.message || "Failed to create client. Please try again.");
                        });
                }
            } catch (error) {
                await message.error("An error occurred during the agreement signing process. Please attempt to sign the agreement again.");
                console.error("Unexpected error type:", error);
            }
        }
        updatePotentialClient();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        createClient,
        currentStepData,
        deviceData,
        forbidden,
        inputData,
        navigate,
        signData.password,
        // signData.signature,
        updatePotentialClientById,
        location,
        isMobile,
    ]);

    const handleBackClick = useCallback(() => {
        scrollContentToTop();

        if (currentStepData?.backButtonId) {
            setCurrentStep(currentStepData?.backButtonId);
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("step", currentStepData?.backButtonId);
            navigate(`${location.pathname}?${searchParams.toString()}`);
        }
    }, [scrollContentToTop, currentStepData, setCurrentStep, location, navigate]);

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            if (isMobile) {
                event.target.blur();
            }
        }
    };

    const calculateMaxAgeDate = useCallback(() => {
        const today = new Date();
        const maxYear = today.getFullYear() - 18;
        const maxDate = new Date(maxYear, 11, 31); // December 31st of max valid year

        return maxDate.toLocaleDateString("en-GB"); // "31/12/YYYY"
    }, []);

    useEffect(() => {
        if (inputData["cashBasisQuestion"] === "no") {
            setIsButtonDisabled(true);
        } else if (currentStep === UK_SIGNUP_STEP.ADDRESS) {
            setForbidden("");
            const { errors } = validate();

            let currentStepErrors = 0;

            currentStepData?.inputs.forEach(
                input => {
                    const showQuestion = checkDepend(input.depend, inputData);
                    if (showQuestion && errors[input.id]) {
                        currentStepErrors = +1;
                    }
                    if (input.type === "addressQuestion") {
                        // @ts-ignore
                        input.inputs?.forEach(
                            (input: { depend: DependT[]; id: string | number; }) => {
                                const showQuestion = checkDepend(input.depend, inputData);
                                if (showQuestion && errors[input.id]) {
                                    currentStepErrors = +1;
                                }
                            }
                        );
                    }
                }
            );

            const notAnsweredRequiredQuestions = currentStepData?.inputs.filter(
                input => {
                    const showQuestion = checkDepend(input.depend, inputData);
                    return (
                        input.required && showQuestion && !inputData[input.id]
                    );
                }
            );

            setIsButtonDisabled(
                !!notAnsweredRequiredQuestions?.length || !!currentStepErrors
            );
        } else {
            setForbidden("");
            const { errors } = validate();

            const currentStepErrors = currentStepData?.inputs.filter(
                input => {
                    const showQuestion = checkDepend(input.depend, inputData);
                    // @ts-ignore
                    return showQuestion ? errors[input.id] : undefined;
                }
            ).length;

            const notAnsweredRequiredQuestions = currentStepData?.inputs.filter(
                input => {
                    const showQuestion = checkDepend(input.depend, inputData);
                    return (
                        input.required && showQuestion && !inputData[input.id]
                    );
                }
            );

            setIsButtonDisabled(
                !!notAnsweredRequiredQuestions?.length || !!currentStepErrors
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputData, enableValidation, currentStepData, signData]);

    useEffect(() => {
        scrollContentToBottom();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputData]);

    useEffect(() => {
        scrollContentToTop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStepData]);

    useEffect(() => {
        if (signData.termsAgreed) {
            setInputData((prev: FormDataT) => ({ ...prev, sign: "provided" }));
        }
    }, [signData]);

    useEffect(() => {
        if (emailAvailability?.available === false) {
            setErrors(prevState => {
                return {
                    ...prevState,
                    "email": "This email address already exists",
                };
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailAvailability]);

    useEffect(() => {
        if (ninAvailability?.available === false) {
            setErrors(prevState => {
                return {
                    ...prevState,
                    "nin": "This NIN number already exists",
                };
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ninAvailability]);

    useEffect(() => {
        step && setCurrentStep(step);
    }, [step]);

    useEffect(() => {
        setCurrentStep(UK_SIGNUP_STEP.PERSONAL_INFO);
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("step", UK_SIGNUP_STEP.PERSONAL_INFO);
        navigate(`${location.pathname}?${searchParams.toString()}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<>
        <LottieAnimation isLoading={isCreatingClient || isSigningIn}/>
        {!isMobile && <div id="calendar-popup-target-div"/>}
        <div id="sign-up" className="uk-sign-up-header-wrapper-new">
            <div className="uk-sign-up-header-new ">
                <div className={"uk-sign-up-header-title-new"}
                >
                    {currentStepData?.headerLabel}
                </div>
                {}
                <div>
                    {currentStepData?.backButtonId && (
                        <div
                            className="dflex uk-sign-up-back-button-new"
                            onClick={handleBackClick}
                        >
                            BACK{" "}
                            <img
                                src={backArrow}
                                width={11}
                                height={11}
                                alt={"back button"}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>

        <div className="uk-sign-up-content-new" ref={scrollRef}>
            {currentStepData?.infoText && (
                <span
                    className={"uk-sign-up-help-text-new fc444"}
                >
                    {currentStepData?.infoText}
                </span>
            )}
            <Form className="mt0" data-testid="sign-up-form">
                {currentStepData?.inputs.map((input, index) => {
                    const showQuestion = checkDepend(
                        input.depend,
                        inputData
                    );
                    if (!showQuestion) return null;

                    switch (input.type) {
                    case "dateQuestion":
                        return (
                            <UkCustomFormItem
                                key={input.id}
                                helpText={""}
                                error={errors[input.id]}
                                warning={warnings[input.id]}
                            >
                                <UkFloatDatePicker
                                    inputId={input.id}
                                    label={input.name}
                                    value={inputData[input.id]}
                                    error={errors[input.id]}
                                    maxDate={calculateMaxAgeDate()}
                                    portalId="calendar-popup-target-div"
                                    setEnableValidation={
                                        setEnableValidation
                                    }
                                    onChange={date => {
                                        handleInputChange({
                                            name: input.id,
                                            value: date,
                                        });
                                    }}
                                />
                            </UkCustomFormItem>
                        );
                    case "signQuestion":
                        return (
                            <div key={input.id}>
                                <SignInputs
                                    setSignData={setSignData}
                                    isCreateAccountChecked={
                                        isCreateAccountChecked
                                    }
                                    setIsCreateAccountChecked={
                                        setIsCreateAccountChecked
                                    }
                                    passwordError={passwordError}
                                    setPasswordError={setPasswordError}
                                    inputId={input.id}
                                    setEnableValidation={
                                        setEnableValidation
                                    }
                                    handleKeyDown={handleKeyDown}
                                />
                            </div>
                        );

                    case "booleanQuestion":
                        return (
                            <UkCustomFormItem
                                key={input.id}
                                error={errors[input.id]}
                                warning={warnings[input.id]}
                            >
                                <UkBooleanQuestion
                                    data={input as IInput}
                                    onAnswer={(answer: string) => {
                                        handleInputChange({
                                            name: input.id,
                                            value: answer,
                                        });
                                    }}
                                    value={inputData[input.id]}
                                />
                            </UkCustomFormItem>
                        );

                    case "phoneQuestion":
                        return (
                            <UkCustomFormItem
                                key={input.id}
                                error={errors[input.id]}
                                warning={warnings[input.id]}
                            >
                                <UkPhoneInputWithCountryCode
                                    index={
                                        currentStepData.id !==
                                        UK_SIGNUP_STEP.PERSONAL_INFO
                                            ? index
                                            : undefined
                                    }
                                    label={input.name}
                                    inputName={input.inputName}
                                    inputId={input.id}
                                    value={inputData[input.id]}
                                    error={errors[input.id]}
                                    disableCountryLabel
                                    setEnableValidation={
                                        setEnableValidation
                                    }
                                    onChange={(
                                        fullPhoneNumber: string
                                    ) => {
                                        handleInputChange({
                                            name: input.id,
                                            value: fullPhoneNumber,
                                        });
                                    }}
                                    handleKeyDown={handleKeyDown}
                                />
                            </UkCustomFormItem>
                        );

                    case "addressQuestion":
                        return (
                            <>
                                {simpleAddressMode
                                    ? (<UkCustomFormItem
                                        key={input.id}
                                        error={errors[input.id]}
                                        warning={warnings[input.id]}
                                    >
                                        <div className="uk-sign-up-question-text mb20">
                                            {input.name}
                                        </div>
                                        <UkAutoCompleteAddressInput
                                            style={{
                                                marginBottom: "5px",
                                            }}
                                            label={"🔍 Search address"}
                                            value={inputData[input.id]}
                                            formData={inputData}
                                            setSimpleAddressMode={setSimpleAddressMode}
                                            setEnableValidation={
                                                setEnableValidation
                                            }
                                            addressChange={(address: { [key: string]: string }) => {
                                                handleInputChange({
                                                    object: address,
                                                });
                                            }}
                                            onChange={(value: string, object?: { [key: string]: string }) => {
                                                handleInputChange({
                                                    name: input.id,
                                                    value,
                                                    object,
                                                });
                                            }}
                                            handleKeyDown={handleKeyDown}
                                        />
                                    </UkCustomFormItem>)
                                    : (
                                        <div key={input.id} className="mt10">
                                            {// @ts-ignore
                                                input?.inputs?.map((innerInput, index) => (
                                                    <UkCustomFormItem
                                                        key={innerInput.id}
                                                        error={errors[innerInput.id]}
                                                    >
                                                        <UkFloatInput
                                                            style={{
                                                                marginBottom: "5px",
                                                            }}
                                                            index={index}
                                                            label={innerInput.name}
                                                            inputName={innerInput.inputName}
                                                            inputId={innerInput.id}
                                                            value={inputData[innerInput.id]}
                                                            error={errors[innerInput.id]}
                                                            setEnableValidation={
                                                                setEnableValidation
                                                            }
                                                            onChange={(event: any) => {
                                                                if (!inputData[input.id]) {
                                                                    handleInputChange({
                                                                        name: input.id,
                                                                        value: "manual",
                                                                    });
                                                                }
                                                                handleInputChange({
                                                                    name: innerInput.id,
                                                                    value: event.target.value,
                                                                });
                                                            }}
                                                            handleKeyDown={handleKeyDown}
                                                        />
                                                    </UkCustomFormItem>))}
                                        </div>)
                                }</>

                        );
                    case "toggleQuestion":
                        return (
                            <div key={input.id}>
                                {!forbidden && <UkCustomFormItem
                                    key={input.id}
                                    error={errors[input.id]}
                                    warning={warnings[input.id]}
                                >
                                    <UkToggleQuestion
                                        data={input as IInput}
                                        onAnswer={(answer: string) => {
                                            handleInputChange({
                                                name: input.id,
                                                value: answer,
                                            });
                                        }}
                                        value={inputData[input.id]}
                                    />

                                </UkCustomFormItem>}
                            </div>

                        );
                    case "textQuestion":
                    case "nameQuestion":
                    case "emailQuestion":
                    default:
                        return (
                            <UkCustomFormItem
                                key={input.id}
                                error={errors[input.id]}
                                warning={warnings[input.id]}
                            >
                                <UkFloatInput
                                    style={{
                                        marginBottom: "5px",
                                    }}
                                    index={
                                        currentStepData.id !==
                                        UK_SIGNUP_STEP.PERSONAL_INFO
                                            ? index
                                            : undefined
                                    }
                                    label={input.name}
                                    inputId={input.id}
                                    inputName={input.inputName}
                                    value={inputData[input.id]}
                                    error={errors[input.id]}
                                    setEnableValidation={
                                        setEnableValidation
                                    }
                                    onChange={(event: any) => {
                                        handleInputChange({
                                            name: input.id,
                                            value: event.target.value,
                                        });
                                    }}
                                    type={input.type === "emailQuestion" ? "email" : input.type}
                                />
                            </UkCustomFormItem>
                        );
                    }
                })}
            </Form>
        </div>
        <div className="uk-sign-up-footer">
            <Button
                disabled={isButtonDisabled}
                shape={"round"}
                size="large"
                className="uk-sign-up-next-button-new"
                onClick={handleNextClick}
            >
                <span className="ml10 mAuto ">
                    {currentStepData?.nextButtonLabel}
                </span>

                <img
                    src={nextArrow}
                    width={11}
                    height={11}
                    alt={"next button"}
                    className="mr10"
                    style={{ transform: "rotate(180deg)" }}
                />

            </Button>
            {currentStepData?.account && <div className="mt10 pointer secondary-color taCenter" onClick={() => {
                navigate("/auth");
            }}>
                <span>
                    {currentStepData?.account}
                </span>
            </div>}
        </div>
    </>);
};

export default SighUpFormNew;
