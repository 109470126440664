import "styles/floatInput.less";

import {
    useState,
    ChangeEvent,
    CSSProperties,
    useMemo,
    useContext,
    Dispatch,
    SetStateAction,
} from "react";

import { Input } from "antd";
import { AppContext } from "App";
import checkMark from "assets/images/checkmark.svg";
import warning from "assets/images/warning.svg";
import { EnableValidationT } from "components/basic/SignUpForm/SignUpFormNew";
import { FaEye, FaEyeSlash } from "react-icons/fa";

interface FloatInputProps {
    label: string;
    inputId: string;
    value?: string;
    placeholder?: string;
    staticPlaceholder?: string;
    type?: string;
    error?: string;
    dataTestId?: string;
    inputName?: string;
    style?: CSSProperties;
    index?: number;
    onChange?: (_event: ChangeEvent<HTMLInputElement>) => void;
    setEnableValidation?: Dispatch<SetStateAction<EnableValidationT>>
    icon?: string
    handleKeyDown?: (_event: any) => void
    errorIconClickable?: boolean
    handleErrorIconClickable?: (_event: any) => void
    togglePasswordVisibility?: () => void
    showPassword?: boolean
}

const UkFloatInput = ({
    label,
    inputId,
    value,
    placeholder,
    staticPlaceholder,
    type,
    error,
    style,
    onChange,
    setEnableValidation,
    icon,
    dataTestId,
    inputName,
    index,
    handleKeyDown,
    errorIconClickable,
    handleErrorIconClickable,
    showPassword,
    togglePasswordVisibility,
}: FloatInputProps) => {
    const { isMobile } = useContext(AppContext);

    const [focus, setFocus] = useState(false);

    const isOccupied = focus || value;

    const labelClass = useMemo(() => {
        const baseClass = "uk-label";
        const isLongLabel = label.length > 40;
        const isVeryLongLabel = label.length > 65;

        let computedLabelClass = isOccupied
            ? `${baseClass} uk-as-label`
            : `${baseClass} as-placeholder`;

        if (isOccupied && error) {
            computedLabelClass += " text-error";
        }

        if (isMobile) {
            if (!isOccupied && (isLongLabel || isVeryLongLabel)) {
                computedLabelClass += " two-line-placeholder";
            }

            if (isOccupied && (isLongLabel || isVeryLongLabel)) {
                computedLabelClass += " two-line-label";
            }
        } else {
            if (!isOccupied && isVeryLongLabel) {
                computedLabelClass += " two-line-placeholder";
            }

            if (isOccupied && isVeryLongLabel) {
                computedLabelClass += " two-line-label";
            }
        }

        return computedLabelClass;
    }, [isOccupied, error, label.length, isMobile]);

    const handleBlur = () => {
        setEnableValidation && setEnableValidation((prev: EnableValidationT) => ({ ...prev, [inputId]: true }));
        setFocus(false);
    };

    const handleFocus = () => {
        setFocus(true);
    };

    const getSuffix = () => {
        if (showPassword && togglePasswordVisibility) {
            return <FaEye size={20} className="pointer" onClick={togglePasswordVisibility}/>;
        } else if (togglePasswordVisibility) {
            return <FaEyeSlash size={20} className="pointer" onClick={togglePasswordVisibility}/>;
        } else {
            return null;
        }
    };

    return (
        <div
            className="uk-float-label"
            onBlur={handleBlur}
            onFocus={handleFocus}
            style={style}
        >
            <Input
                data-testid={dataTestId}
                className="uk-input-field"
                onChange={onChange}
                name={inputName}
                type={type}
                value={value}
                placeholder={isOccupied ? placeholder : ""}
                autoFocus={index === 0}
                onKeyDown={handleKeyDown}
                suffix={getSuffix()}
            />
            <label className={labelClass}>{label}</label>
            {icon && <img className="suffix-icon mr10" src={icon} alt="icon"/>}
            {staticPlaceholder && <div className="static-placeholder mr10">{staticPlaceholder}</div>}
            {(value || error) && !icon && (errorIconClickable
                ? <img onClick={handleErrorIconClickable} className="status-icon-clickable mr10" src={error ? warning : checkMark} alt="status icon"/>
                : <img className="status-icon mr10" src={error ? warning : checkMark} alt="status icon"/>)}
        </div>
    );
};

export default UkFloatInput;
