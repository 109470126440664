import { FC, useCallback, useEffect, useRef, useState } from "react";

import { InputRef } from "antd";
import errorIcon from "assets/images/new-questionnaire/exclamationmark-circle.svg";
import { parse, isBefore, isAfter } from "date-fns";
import dayjs from "dayjs";

import { Question } from "../../constants/questions";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";

type DateListQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: string | undefined) => void;
    initialValue?: string;
    focus?: boolean;
};

const DateListQuestion: FC<DateListQuestionProps> = ({ data, onAnswer, initialValue, focus }) => {
    const [date, setDate] = useState<string | undefined>(initialValue || "");
    const [error, setError] = useState<string | null>(null);
    const [isFocused, setIsFocused] = useState(false);
    const minDate = data.minDate;
    const maxDate = data.maxDate;

    const inputRef = useRef<InputRef>(null);

    const handleDateChange = useCallback((date: string | null) => {
        if (date) {
            setDate(date);

            const parsedDate = parse(date, "dd/MM/yyyy", new Date());
            if (minDate && maxDate) {
                const parsedMinDate = parse(minDate, "dd/MM/yyyy", new Date());
                const parsedMaxDate = parse(maxDate, "dd/MM/yyyy", new Date());
                if (isBefore(parsedDate, parsedMinDate) || isAfter(parsedDate, parsedMaxDate)) {
                    setError(`Date must be between ${minDate} and ${maxDate}`);
                    onAnswer(data.question, undefined);
                } else {
                    setError(null);
                    onAnswer(data.question, date);
                }
            } else {
                onAnswer(data.question, date);
            }
        } else {
            setDate(undefined);
            onAnswer(data.question, undefined);
        }
    }, [minDate, maxDate, data.question, onAnswer]);

    const handleWrapperClick = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        // Check if the initialValue is in this format "17/01/2025"
        const isInitialValueValid = dayjs(initialValue, "DD/MM/YYYY", true).isValid();

        if (!isInitialValueValid) {
            setDate(undefined);
        } else {
            setDate(initialValue);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useEffect(() => {
        if (focus) {
            const focusInput = () => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            };

            const timer = setTimeout(focusInput, 10);

            return () => clearTimeout(timer);
        }
    }, [focus]);

    return (
        <div className="new-questionnaire__question">
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                {data.question}
            </div>
            <div
                className="new-questionnaire__question-input-wrapper"
                style={{ borderBottom: isFocused ? "4px solid #7F43FF" : "4px solid #C4C4C4", marginTop: "10px" }}
                onClick={handleWrapperClick}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            >
                <CustomDatePicker
                    minDate={minDate}
                    maxDate={maxDate}
                    value={date}
                    onChange={handleDateChange}
                    disableBorder
                />
            </div>
            {error && <div className="new-questionnaire__error-message">
                <img src={errorIcon} width={20} alt="error" />
                <div className="new-questionnaire__error-message-text ml4">
                    {error}
                </div>
            </div>}
        </div>
    );
};

export default DateListQuestion;
