import "styles/auth.less";

import { RESET_PASSWORD_TYPE } from "constants/emails";

import React, { useContext, useEffect, useState } from "react";

import { Button, Checkbox, Col, Form, Row, Typography, message, Layout } from "antd";
import { AppContext } from "App";
import loginImage from "assets/images/login-image.png";
import Header from "components/basic/Header/Header";
import LottieAnimation from "components/basic/Spinner/Lottie";
import EmailInput from "components/inputs/Form/Inputs/EmailInput";
import PasswordInput from "components/inputs/Form/Inputs/PasswordInput";
import { useLoading } from "hooks/common/useLoading";
import { useLocalStorage } from "hooks/helpers/useLocalStorage";
import queryString from "query-string";
import { Navigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import { login } from "redux/reducers/auth";
import { useResetPasswordMutation } from "services/auth";

const ResetPassword: React.FC = () => {
    const { isMobile, isAuth } = useContext(AppContext);
    const { loading } = useLoading();
    const location = useLocation();
    const [initialValues, setInitialValues] = useState({ email: "", password: "" });
    const [token, setToken] = useState("");
    const [resetCompleted, setResetCompleted] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [type, setType] = useState(RESET_PASSWORD_TYPE.RESET_PASSWORD);

    const dispatch = useAppDispatch();

    const [rememberMe, setRememberMe] = useLocalStorage<boolean | null>("rememberMe", null);

    const handleRememberMe = (e: any) => {
        setRememberMe(e.target.checked);
    };

    const [form] = Form.useForm();

    const [resetPassword, { isLoading }] = useResetPasswordMutation();

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        const email = (parsed.email) as string;
        const token = (parsed.token) as string;
        const type = (parsed.type) as RESET_PASSWORD_TYPE;
        email && setInitialValues(prev => ({ ...prev, email }));
        type && setType(type);
        setToken(token || "");
    }, [location.search]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setPageLoading(false);
        }, 300);
        return () => clearTimeout(timer);
    }, []);

    if (isAuth && resetCompleted) {
        return <Navigate replace to={type === RESET_PASSWORD_TYPE.NEW_USER ? "/profile?tab=payment-details" : "/profile"} />;
    }

    return (
        <Layout className="layout">
            <LottieAnimation isLoading={pageLoading} backgroundColor={"white"}/>
            <Header />
            <div className="auth__container">
                <div className="auth__content">
                    {!isMobile && <div className="auth__content-left">
                        <h1 className="auth__title">
                            {type === RESET_PASSWORD_TYPE.NEW_USER ? "Sign up to" : "Set new"}
                            <br />
                            {type === RESET_PASSWORD_TYPE.NEW_USER ? "claim your refund" : "password"}
                        </h1>
                        <div className="auth__content-left-decorative">
                            <div>
                                <img
                                    src={loginImage}
                                    className="auth__image"
                                    alt={"tax-zap"}
                                />
                            </div>
                        </div>
                    </div>}
                    <div className="auth__content-right">
                        <div style={{ width: "100%" }}>
                            <LottieAnimation isLoading={isLoading || loading}/>
                            <Typography className="auth__login-account-header">
                                {type === RESET_PASSWORD_TYPE.NEW_USER
                                    ? "Sign up"
                                    : "Set new password"}
                            </Typography>
                            <Typography className="auth__existing-user-text">
                                {type === RESET_PASSWORD_TYPE.NEW_USER
                                    ? "Set up your password and log in to claim your tax refund."
                                    : "Set new password and log in to your account."}
                            </Typography>
                            {initialValues.email &&
                                <Form
                                    form={form}
                                    onFinish={data => {
                                        resetPassword({ token, email: data.email, password: data.password })
                                            .unwrap()
                                            .then(async data => {
                                                await dispatch(login(data.token));
                                                setResetCompleted(true);
                                            })
                                            .catch(async error => {
                                                await message.error(error?.data?.message || "Reset Password Error", 4);
                                            });
                                    }}
                                    initialValues={initialValues}
                                    layout={"vertical"}
                                >
                                    <Row>
                                        <Col span={24}>
                                            <EmailInput required disabled/>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={24}>
                                            <PasswordInput required autoFocus/>
                                        </Col>
                                    </Row>
                                    <Row justify={"space-between"}>
                                        <Col>
                                            <Checkbox checked={!!rememberMe} onChange={handleRememberMe} className="auth__remember-me-checkbox">
                                                Remember me
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Row className="auth__row-margin-top">
                                        <Col style={{ width: "100%" }}>
                                            <Form.Item style={{ marginBottom: "0px", width: "100%" }}>
                                                <Button
                                                    type="primary"
                                                    data-testid="sign-in-submit-button"
                                                    htmlType="submit"
                                                    className={"start-button auth__start-button"}
                                                    style={{ width: "100%" }}
                                                >
                                                    Log In
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ResetPassword;
