import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";

import { AppContext } from "App";
import editPencil from "assets/images/new-questionnaire/edit_pencil.svg";
import successIcon from "assets/images/new-questionnaire/success-icon.svg";
import QuestionnaireButtonFooterV2 from "components/basic/Footer/QuestionnaireButtonFooterV2";
import ButtonLoading from "components/basic/Spinner/ButtonLoading";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectDocument } from "redux/reducers/document";
import { selectCurrentNavigation } from "redux/reducers/navigation";
import { setCurrentProfileAnswers } from "redux/reducers/taxProfile";
import { useGetClientTaxProfileQuery, useUpdateClientTaxProfileMutation } from "services/client";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { IUser } from "types/auth";
import { AccountDetails } from "types/bankAccounts";
import { ExpensesCategory, TaxProfile } from "types/client";
import { splitIntoCategoryChunks } from "utils/data";
import { round2Decimals } from "utils/numbers";
import { logError } from "utils/sentry";

import { CategoryEmoji, ExpenseCategoryModalData } from "../../constants/categories";
import { PromptModalDataType, Question, Step } from "../../constants/questions";
import { ParentProfileType, ProfileType, PromptModalType } from "../../enums/enum";
import ExplainerModal from "../Shared/ExplainerModal";
import InlineAmountInput from "../Shared/InlineEditableAmountInput";
import PromptModal from "../Shared/PromptModal";

type ProfileExpensesSummaryProps = {
    user: IUser;
    currentStepData: Step;
    data: Question;
    taxProfileType: ProfileType;
    taxParentProfileType: ParentProfileType;
    taxProfile: TaxProfile;
    handleContinue: () => void;
    handlePrev: () => void;
    connectedBankAccounts: AccountDetails[];
};

const ProfileExpensesSummary: React.FC<ProfileExpensesSummaryProps> = ({
    user,
    currentStepData,
    taxProfileType,
    taxProfile,
    handleContinue,
    data,
}) => {
    const dispatch = useAppDispatch();
    const [ updateClientTaxProfile] = useUpdateClientTaxProfileMutation();
    const { stepLoading } = useAppSelector(selectCurrentNavigation);
    const { expensesExplainerShown } = useAppSelector(selectDocument);

    const { isMobile } = useContext(AppContext);
    const [, setEdit] = useState<Record<string, boolean>>({});
    const [editableCategoryAmount, setEditableCategoryAmount] = useState<Record<string, string>>({});
    const [currentProfile, setCurrentProfile] = useState<TaxProfile>(taxProfile);
    const { currentProfileAnswers } = useAppSelector(state => state.taxProfile);
    const { data: taxProfileResponse, refetch: refetchTaxProfile } = useGetClientTaxProfileQuery(
        {
            client: user?.id!,
            taxProfileId: taxProfile?._id!,
        },
        {
            skip: !user?.id || !taxProfile, // Skip the query if user or currentProfile is not available
        }
    );

    const [promptModalData, setPromptModalData] = useState<PromptModalDataType>({
        type: PromptModalType.info,
        title: "",
        text: "",
        image: "",
    });

    const {
        isOpened: isQuestionHelpModalOpened,
        open: handleQuestionHelpOpenModal,
        close: handleQuestionHelpCloseModal,
    } = useModalOpener();

    const {
        isOpened: isExplainerModalOpened,
        open: handleExplainerOpenModal,
        close: handleExplainerCloseModal,
    } = useModalOpener();

    const categoryChunks = useMemo(() => {
        if (!currentProfile?.expenses?.category) return [];

        const categories = Object.entries(currentProfile.expenses.category);

        // Sort categories: Those with `swipedApprovedTransactionsTotal` > 0 come first
        categories.sort(([_, valueA], [__, valueB]) => {
            const hasSwipedA = valueA.swipedApprovedTransactionsTotal > 0 ? 1 : 0;
            const hasSwipedB = valueB.swipedApprovedTransactionsTotal > 0 ? 1 : 0;
            return hasSwipedB - hasSwipedA; // Prioritize categories with swipedApprovedTransactionsTotal > 0
        });

        return splitIntoCategoryChunks(Object.fromEntries(categories), categories.length);
    }, [currentProfile]);

    const canShowModal = useMemo(() => (taxProfileType === "Furnished Holiday Let" || taxProfileType === "Residential/Commercial"), [taxProfileType]);

    const editableTotalAmount = useMemo(() => {
        const totalAmount = Object.values(editableCategoryAmount).reduce((acc, value) => {
            const numericValue = parseFloat(value) || 0;
            return acc + numericValue;
        }, 0);

        return round2Decimals(totalAmount);
    }, [editableCategoryAmount]);

    const handleConfirm = useCallback(async () => {
        setEdit({});

        const updatedCategories = Object.entries(currentProfile.expenses.category).reduce((acc, [categoryName, categoryData]) => {
            acc[categoryName] = {
                ...categoryData,
                confirmedTotal: Number(editableCategoryAmount[categoryName] || "0"),
            };
            return acc;
        }, {} as Record<string, ExpensesCategory>);

        await updateClientTaxProfile({
            taxProfileId: currentProfile._id,
            client: currentProfile.client,
            expenses: {
                ...currentProfile.expenses,
                confirmedTotal: editableTotalAmount,
                category: updatedCategories,
            },

        }).unwrap()
            .then(() => {
                refetchTaxProfile?.();
                handleContinue();
            })
            .catch(error => {
                logError(error);
            });
    }, [editableCategoryAmount, handleContinue, currentProfile, updateClientTaxProfile, refetchTaxProfile, editableTotalAmount]);

    const handleModalOpen = useCallback((categoryName: string) => {
        if (canShowModal && currentStepData) {
            setPromptModalData({
                type: PromptModalType.info,
                title: CategoryEmoji[taxProfileType][categoryName] ? (CategoryEmoji[taxProfileType][categoryName] + " " + categoryName) : categoryName,
                text: ExpenseCategoryModalData[taxProfileType][categoryName]["manualExpenses"] || "",
                image: ExpenseCategoryModalData[taxProfileType][categoryName]?.image || "",
            });
            handleQuestionHelpOpenModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canShowModal, handleQuestionHelpOpenModal, currentStepData, taxProfileType]);

    const handleTotalAmountChange = (e: ChangeEvent<HTMLInputElement>, category: string) => {
        const value = e.target.value.replace(",", ".");
        if (/^\d*\.?\d{0,2}$/.test(value)) {
            setEditableCategoryAmount(prev => ({ ...prev, [category]: value }));
        }
    };

    const handleEditToggle = (categoryName: string, isEditing: boolean) => {
        setEdit(prev => ({
            ...prev,
            [categoryName]: isEditing,
        }));
    };

    const getExplainerText = () => {
        switch (taxProfileType) {
        case "Self Employment":
            if (!currentProfile?.expenses?.reviewableTransactionsTotal) {
                return "⚠️ Let's do your expenses. Add the total expenses associated with this self-employment into the specific categories";
            } else {
                return "Please review and confirm the expenses associated with this self-employment";
            }
        case "Furnished Holiday Let":
            if (!currentProfile?.expenses?.reviewableTransactionsTotal) {
                return "Let's do your expenses. Add the total expenses associated with this furnished holiday let into the specific categories";
            } else {
                return "Please review and confirm the expenses associated with this furnished holiday let";
            }
        case "Residential/Commercial":
            if (!currentProfile?.expenses?.reviewableTransactionsTotal) {
                return "Let's do your expenses. Add the total expenses associated with this residential/commercial property into the specific categories";
            } else {
                return "Please review and confirm the expenses associated with this residential/commercial property";
            }
        case "Rent-a-Room":
            if (!currentProfile?.expenses?.reviewableTransactionsTotal) {
                return "Let's do your expenses. Add the total expenses associated with this rent-a-room property into the specific categories";
            } else {
                return "Please review and confirm the expenses associated with this rent-a-room property";
            }
        case "cis":
            if (!currentProfile?.expenses?.reviewableTransactionsTotal) {
                return "Let's do your expenses. Add the total expenses associated with this CIS employment into the specific categories";
            } else {
                return "Please review and confirm the expenses associated with this CIS employment";
            }
        default:
            return "";
        }
    };

    useEffect(() => {
        if (currentProfile && currentProfile.expenses && currentProfile.expenses.category) {
            const initialAmounts = Object.entries(currentProfile.expenses.category).reduce((acc, [categoryName, categoryData]) => {
                acc[categoryName] = Number(categoryData.confirmedTotal || categoryData.swipedApprovedTransactionsTotal || "0.00").toFixed(2);
                return acc;
            }, {} as Record<string, string>);

            setEditableCategoryAmount(initialAmounts);
        }
    }, [currentProfile]);

    useEffect(() => {
        if (taxProfileResponse?.taxProfile) {
            setCurrentProfile(taxProfileResponse.taxProfile);
        }
    }, [taxProfileResponse]);

    useEffect(() => {
        const updatedAnswers = currentProfileAnswers.map(q => ({ ...q })); // ✅ Deep copy objects
        const existingIndex = updatedAnswers.findIndex(q => q.question === data.question);
        const updatedAnswer = { question: data.question, answer: editableTotalAmount, type: data.type || "", id: data.id || "" };
        if (existingIndex > -1) {
            updatedAnswers[existingIndex] = updatedAnswer;
        } else {
            updatedAnswers.push(updatedAnswer);
        }
        dispatch(setCurrentProfileAnswers(updatedAnswers));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableTotalAmount]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!expensesExplainerShown) {
                handleExplainerOpenModal();
            }
        }, 500);
        return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        refetchTaxProfile?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <div className={`new-questionnaire__income-expenses-container ${isMobile ? "" : "mt30"}`}>
            <div className="new-questionnaire__income-expenses-title taLeft mt15 w335px">{"Confirm Your Expenses"}</div>
            <div className={"new-questionnaire__income-expenses-explainer"}>{getExplainerText()}</div>
            <div className="new-questionnaire__income-report__wrapper m20">
                <div className={"new-questionnaire__expense-report"}>
                    <img src={successIcon} alt="success" className="new-questionnaire__income-report__success-icon"/>
                    <div className="w100">
                        <div className="new-questionnaire__income-report__sub-title mt20">Total Business Expenses
                        </div>
                        <div
                            className="new-questionnaire__income-report-total-amount mb10">£{new Intl.NumberFormat("en-GB", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(parseFloat(editableTotalAmount.toString() || "0.00"))}
                        </div>
                        <Swiper
                            {...({ slidesPerView: 1 } as any)}
                            {...({ spaceBetween: 3 } as any)}
                            pagination={{ type: "fraction" }}
                            loop={true}
                            modules={[Pagination]}
                        >
                            {categoryChunks.map((categoryChunk, index) => (
                                <SwiperSlide key={index}>
                                    <div className={"new-questionnaire__expense-report-card"}>
                                        {Object.entries(categoryChunk).map(([categoryName]) => (
                                            <InlineAmountInput
                                                key={categoryName}
                                                categoryName={categoryName}
                                                categoryEmoji={CategoryEmoji[taxProfileType]?.[categoryName]}
                                                amount={editableCategoryAmount[categoryName] ?? "0.00"}
                                                canShowModal={true} // Pass conditionally if needed
                                                onAmountChange={handleTotalAmountChange}
                                                onEditToggle={handleEditToggle}
                                                onModalOpen={handleModalOpen}
                                                editIcon={editPencil}
                                            />

                                        ))}
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                    </div>
                </div>

                <div className="mt120">
                    <QuestionnaireButtonFooterV2>
                        <div
                            className={`new-questionnaire__form-continue ${!stepLoading ? "" : "disabled"} mt25`}
                            onClick={() => {
                                if (!stepLoading) {
                                    handleConfirm();
                                }
                            }}
                        >
                            {stepLoading ? <ButtonLoading/>
                                : <span>{"Confirm"}</span>}
                        </div>
                    </QuestionnaireButtonFooterV2>
                </div>

                {isQuestionHelpModalOpened && (
                    <PromptModal
                        isOpen={isQuestionHelpModalOpened}
                        closeModal={handleQuestionHelpCloseModal}
                        data={promptModalData}
                        handlePrev={() => {}}
                        imageWidth={300}
                        closeButtonText={"Got It"}
                    />
                )}
                {isExplainerModalOpened && (
                    <ExplainerModal
                        isOpen={isExplainerModalOpened}
                        closeModal={handleExplainerCloseModal}
                        subTitle={`${currentStepData.title} - ${taxProfile.name}`}
                    />
                )}
            </div>
        </div>
    );
};

export default ProfileExpensesSummary;
