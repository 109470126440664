import { UK_SIGNUP_STEP } from "constants/signupFormNew";

import { useContext } from "react";

import { Col, Row } from "antd";
import { AppContext } from "App";
import SignUpFormNew from "components/basic/SignUpForm/SignUpFormNew";
import StartButtonNew from "components/basic/StartButtonNew";
import { useLocation } from "react-router-dom";
import { getQueryParam } from "utils/common";

const SignUpSectionNew = () => {
    const location = useLocation();
    const {
        isMobile,
        maxWidth,
        formScale,
        isAuth,
    } = useContext(AppContext);
    const step = getQueryParam("step", location) || UK_SIGNUP_STEP.PERSONAL_INFO;

    return (
        <div className='horizontal-center' style={{ maxWidth: "100vw", backgroundColor: "#F7F7F7" }}>
            <div className='horizontal-center' style={{ maxWidth: maxWidth, padding: isMobile ? "20px" : "64px 100px", overflow: isMobile ? "hidden" : "" }}>
                <Row gutter={[64, { xs: 32, sm: 32, md: 72, lg: 72 }]} >
                    <Col sm={24} xs={24} md={24} lg={14} xl={14}>
                        <div>
                            <div className={"uk-sub-slogan"}>
                                Are you ready to get your refund now?
                            </div>

                            <div className={"mt40 uk-why-taxzap-description"}>
                                Your tax return has never been easier! Sign up now and begin your journey with TaxZap!
                            </div>

                            <div className={"mt40"}>
                                <StartButtonNew/>
                            </div>
                        </div>
                    </Col>
                    <Col md={24} lg={10} xl={10} className="horizontal-right">

                        {!isMobile && (
                            <div
                                style={{
                                    transform: `scale(${formScale})`,
                                    transition: "transform 0.2s ease-in-out",
                                    ...(isAuth && {
                                        pointerEvents: "none",
                                        opacity: 0.5,
                                        filter: "grayscale(100%)",
                                    }),
                                }}
                            >
                                <div className="uk-sign-up-desktop-new">
                                    <SignUpFormNew step={step as UK_SIGNUP_STEP}/>
                                </div>
                            </div>
                        )}

                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default SignUpSectionNew;
