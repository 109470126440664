import { useCallback } from "react";

import { Typography, Row, Col, Divider, Tag, Modal } from "antd";
import { Link } from "react-router-dom";

import closeIconImage from "../../../assets/images/close.svg";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import QRCodeComponent from "../QRCode";
/* eslint-disable no-unused-vars*/

const BankAccountMode = ({
    paymentModeModal,
    setPaymentModeModal,
    closeConnectModal,
}: {
    paymentModeModal: boolean;
    setPaymentModeModal: (value: boolean) => void;
    closeConnectModal?: () => void;
}) => {
    const countryBank = useAppSelector((state: RootState) => state.bank.countryBank);
    const handleContinue = useCallback(() => {
        setPaymentModeModal(false);
        closeConnectModal && closeConnectModal();
    }, [closeConnectModal, setPaymentModeModal]);

    if (!paymentModeModal) return null;

    return (
        <div className="profile__content">
            <Modal
                open={paymentModeModal}
                centered
                onOk={() => handleContinue()}
                onCancel={() => handleContinue()}
                width={1150}
                destroyOnClose
                footer={null}
                closeIcon={<img src={closeIconImage} alt="Close" style={{ width: "20px", height: "20px", cursor: "pointer" }} />}
            >
                <div >
                    <Typography
                        className="paymentmode-title" >
                        Options to Connect Your Bank Account
                    </Typography>

                    <Row>

                        <Col span={12} style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                            <div className="scanQR_container">
                                <Typography
                                    className="profile__content-title"
                                    style={{ color: "#000", textAlign: "left" }}
                                >
                                    Continue on Mobile
                                </Typography>
                                <Tag bordered={false} className="recommended-tag" style={{ marginLeft: "10px", fontWeight: "bold" }}>
                                    RECOMMENDED
                                </Tag>

                            </div>
                            <Typography
                                className="text_scancontainer"
                                style={{
                                    margin: "15px 0px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                Continue connecting your bank account using your mobile banking app. Scan the QR code below.
                            </Typography>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <QRCodeComponent url={countryBank?.authLink ?? ""}/>
                            </div>

                        </Col>
                        <Col span={1} className="payment_col">   <Divider type="vertical" className="paymentmode_divider" /> </Col>

                        <Col span={11} style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                            <div className="scanQR_textcontainer">

                                <Typography
                                    className="profile__content-title"
                                    style={{ color: "#000", textAlign: "center" }}
                                >
                                    Continue on Desktop
                                </Typography>
                            </div>
                            <Typography
                                className="text_scancontainer"
                                style={{
                                    margin: "15px 0px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                Continue connecting your bank account on your desktop by using the link below. However, we strongly recommend using your mobile phone for a smoother experience.
                            </Typography>
                            <Link to={countryBank?.authLink ?? ""} target="_blank" className="desktop-payment-button">
                                Continue on Desktop
                            </Link>

                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );
};

export default BankAccountMode;
