import moment from "moment";

export const extractDateFromURL = (url: string) => {
    const regex = /(\d{4})-(\d{2})-(\d{2})/;
    const match = url.match(regex);

    if (match) {
        const year = match[1];
        const month = match[2];
        const day = match[3];

        return `${day}/${month}/${year}`;
    } else {
        return "";
    }
};

export const formatDate = (dateString: any) => {
    return moment(dateString).format("DD/MM/YYYY");
};

export const addSpaceEveryNthChar = (inputString: string, chunkSize: number): string => {
    const words = inputString.split(" ");
    let result = "";

    for (let i = 0; i < words.length; i++) {
        const word = words[i];

        if (word.length > chunkSize) {
            const chunks = [];
            for (let j = 0; j < word.length; j += chunkSize) {
                chunks.push(word.slice(j, j + chunkSize));
            }
            result += chunks.join(" ");
        } else {
            result += word;
        }

        if (i < words.length - 1) {
            result += " ";
        }
    }

    return result;
};

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const cleanHTMLString = (html: string) => {
    return html
        .replace(/\n\s*\n/g, "\n")
        .replace(/>\s+</g, "><")
        .trim();
};

export const removeEmojis = (text: string): string => {
    const result = text.replace(
        /[\uD800-\uDBFF][\uDC00-\uDFFF]|\u200D/g,
        ""
    ).trim();

    if (result.length > 18) {
        return result.split("/").join("/ ");
    }

    return result;
};
