import "styles/categoryFiles.less";

import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { DownloadOutlined } from "@ant-design/icons";
import { message, Modal, Upload } from "antd";
import { AppContext } from "App";
import whitePixel from "assets/images/1-white-pixel.png";
import addPhoto from "assets/images/add-photo.svg";
import checkmark from "assets/images/checkmark.svg";
import deleteIcon from "assets/images/delete.svg";
import downLoading from "assets/images/down-loading.svg";
import googleDocs from "assets/images/google-docs.svg";
import upLoading from "assets/images/up-loading.svg";
import Spinner from "components/basic/Spinner/Spinner";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import moment from "moment/moment";
import { fetchFile } from "services/files";
import { IFile } from "types/files";
import { addSpaceEveryNthChar } from "utils/strings";

const { Dragger } = Upload;

const CategoryFiles = ({
    requiredDocuments = [],
    files,
    uploadFile,
    deleteFile,
    getFile,
    allowAdditionalFiles,
}: {
    requiredDocuments?: { name: string; }[];
	files: IFile[];
	deleteFile: (_id: string) => Promise<boolean>;
	uploadFile: (_file: IFile, _name: string) => Promise<boolean>;
    getFile: (_url: string, _originalName: string) => Promise<void>;
    allowAdditionalFiles?: boolean;
}) => {
    const { isMobile } = useContext(AppContext);

    const [currentImagePreview, setCurrentImagePreview] = useState<string>("");
    const [currentFile, setCurrentFile] = useState<IFile | null>(null);
    const [loadingFilePreview, setLoadingFilePreview] = useState<Record<string, boolean>>({});

    const {
        isOpened: openImageModal,
        open: handleOpenImageModal,
        close: handleCloseImageModal,
    } = useModalOpener();

    const handleImageClick = useCallback(async (file: IFile) => {
        setCurrentFile(null);
        setCurrentImagePreview(whitePixel); // to overwrite the previous PDF preview
        setLoadingFilePreview(prevState => ({ ...prevState, [file._id!]: true }));

        try {
            const fetchedFileBlob = await fetchFile(file.url);
            if (fetchedFileBlob) {
                const objectURL = URL.createObjectURL(fetchedFileBlob);
                setCurrentImagePreview(objectURL);
                setCurrentFile(file);
            } else {
                setCurrentImagePreview("");
                message.error("Could not preview the file.");
            }
        } catch (error) {
            console.error("Error fetching file:", error);
            setCurrentImagePreview("");
            message.error("An error occurred while fetching the file.");
        } finally {
            setLoadingFilePreview(prevState => ({ ...prevState, [file._id!]: false }));
        }
    }, []);

    const dragComponent = useCallback((name: string) => {
        return <div style={{ width: isMobile ? 335 : 160 }}>
            {isMobile
                ? <div className="drag-component-wrapper">
                    <Dragger
                        name={"file"}
                        multiple={false}
                        showUploadList={false}
                        style={{
                            backgroundColor: "white",
                        }}
                        customRequest={async data => {
                            const result = await uploadFile(data.file as unknown as IFile, name);

                            if (!result) {
                                await message.error("File was not uploaded!");
                            }

                            return true;
                        }}
                    >
                        <div className={"drag-component-content"}>
                            <div className="mr4">
                                <img src={upLoading} alt="up loading" />
                            </div>
                            <div className={"drag-component-text"}>
                                {name}
                            </div>
                        </div>
                    </Dragger>
                    <div className="drag-component-wrapper">
                        <div className="or-component">
                        or
                        </div>

                        <div className="button-component">
                            <Dragger
                                name={"file"}
                                multiple={false}
                                showUploadList={false}
                                style={{
                                    backgroundColor: "white",
                                }}
                                customRequest={async data => {
                                    const result = await uploadFile(data.file as unknown as IFile, name);

                                    if (!result) {
                                        await message.error("File was not uploaded!");
                                    }

                                    return true;
                                }}
                            >
                                <div className={"button-component-content"}>
                                    <div className="mr4">
                                        <img src={addPhoto} alt="up loading" />
                                    </div>
                                    <div className={"drag-component-text"}>
                                    Take Photo
                                    </div>
                                </div>
                            </Dragger>
                        </div>
                    </div>
                </div>
                : <div style={{ width: isMobile ? 148 : 160 }}>
                    <div>
                        <Dragger
                            name={"file"}
                            multiple={false}
                            showUploadList={false}
                            style={{
                                backgroundColor: "white",
                            }}
                            customRequest={async data => {
                                const result = await uploadFile(data.file as unknown as IFile, name);

                                if (!result) {
                                    await message.error("File was not uploaded!");
                                }

                                return true;
                            }}
                        >
                            <div
                                style={{ width: isMobile ? 148 : 160, height: 120 }}
                                className={"center"}
                            >
                                <div className={"drag-component-content"}>
                                    <div className={"p10 fs12 grey-7-color"}>
                                        <img src={upLoading} alt="up loading" className="mr4"/>
                                        Drop file to upload or <span className={"blue-color"}>browse</span>.
                                    </div>
                                </div>
                            </div>
                        </Dragger>
                        <div className={"mt10 fs12"}>
                            {name || "File"}
                        </div>
                    </div>
                </div>
            }
        </div>;
    }, [isMobile, uploadFile]);

    const fileComponent = useCallback((file: IFile) => {
        return <div>
            <Spinner isLoading={!!loadingFilePreview[file._id!]}>
                <div
                    className={"vertical-space-around"}
                    style={{
                        position: "relative",
                        flexDirection: "column",
                        width: (isMobile ? 148 : 160),
                        minHeight: 120,
                        borderRadius: 8,
                        border: "1px #D7D7D7 solid",
                    }}
                >
                    <div className={"file-component-download"}>
                        <img
                            src={downLoading}
                            alt="download"
                            onClick={async () => {
                                await getFile(file.url, file.originalName);
                            }}
                        />
                    </div>
                    <div className={"center"} style={{ height: "50%" }}>
                        <div
                            className="documents__image-preview"
                            onClick={() => handleImageClick(file)}
                        >
                            <div className={"center"}>
                                <img src={googleDocs} alt="docs" height={24} />
                            </div>
                            <div className={"mt4 ph4 grey-7-color center fs12"} style={{ textAlign: "center" }}>
                                {addSpaceEveryNthChar(file.originalName, isMobile ? 18 : 22)}
                            </div>
                            {file.createdAt && <div className={"grey-3-color center fs12"}>
                                {moment(file.createdAt).format("DD.MM.YYYY HH:mm")}
                            </div>}
                        </div>
                    </div>
                    <div className={"file-component-delete"}>
                        <img
                            src={deleteIcon}
                            alt="delete"
                            onClick={async () => {
                                const result = await deleteFile(file._id!);

                                if (!result) {
                                    await message.error("File was not deleted!");
                                }
                            }}
                        />
                    </div>
                </div>
            </Spinner>
            <div className={"mt10 space-between fs12"} style={{ width: isMobile ? 148 : 160, alignItems: "normal" }}>
                <div>
                    {file.name || "File"}
                </div>
                <img src={checkmark} alt="checkmark" width={18} height={18}/>
            </div>
        </div>;
    }, [deleteFile, getFile, handleImageClick, isMobile, loadingFilePreview]);

    const previewComponent = useMemo(() => (
        <Modal
            className="category-files-modal"
            open={openImageModal}
            footer={null}
            centered={true}
            closable={!isMobile}
            onCancel={() => {
                URL.revokeObjectURL(currentImagePreview);
                setCurrentImagePreview("");
                setCurrentFile(null);
                handleCloseImageModal();
            }}
            {...(isMobile ? { height: "80%" } : { width: "80%" })}
        >
            {currentFile && (
                /\.(jpg|jpeg|png|gif|svg)$/i.test(currentFile.originalName) ? (
                    <img src={currentImagePreview} alt="Preview" style={{ display: "block", width: "100%", height: "auto" }} />
                ) : /\.(pdf)$/i.test(currentFile.originalName) ? (
                    <object data={currentImagePreview} type="application/pdf" width="100%" height="900px">
                        <iframe src={currentImagePreview} width="100%" height="800px" title="File Preview">
                            <p>This browser does not support PDFs. Please download the PDF to view it: {" "}
                                <DownloadOutlined
                                    className={"blue-color fs16"}
                                    onClick={async () => {
                                        await getFile(currentFile.url, currentFile.originalName);
                                    }}
                                />
                            </p>
                        </iframe>
                    </object>
                ) : <p>This browser does not support Files Preview. Please download the File to view it: {" "}
                    <DownloadOutlined
                        className={"blue-color fs16"}
                        onClick={async () => {
                            await getFile(currentFile.url, currentFile.originalName);
                        }}
                    />
                </p>
            )}
        </Modal>
    ), [currentFile, currentImagePreview, getFile, handleCloseImageModal, isMobile, openImageModal]);

    const filterAdditionalFiles = (files: IFile[], requiredDocuments: {name: string}[]) => {
        return files.filter(file => {
            return !requiredDocuments.some(requiredDocument => requiredDocument.name === file.name);
        });
    };

    useEffect(() => {
        if (currentImagePreview) {
            handleOpenImageModal();
        }
    }, [currentImagePreview, handleOpenImageModal]);

    if (requiredDocuments.length) {
        const additionalFiles = filterAdditionalFiles(files, requiredDocuments);
        return <div className="category-files">
            {requiredDocuments.map((requiredDocument, i) => {
                const found = files.filter(file => file.name === requiredDocument.name);

                if (found.length) {
                    return found.map(file => {
                        return <div key={file._id! + i}>
                            {fileComponent(file)}
                        </div>;
                    });
                } else {
                    return <div key={i}>
                        {dragComponent(requiredDocument.name)}
                    </div>;
                }
            })}

            {additionalFiles.map(file => {
                return <div key={file._id}>
                    {fileComponent(file)}
                </div>;
            })}

            {allowAdditionalFiles && !!files.length && <div>
                {dragComponent("Additional File")}
            </div>}
            {previewComponent}
        </div>;
    }

    return <div className="category-files">
        {files.map(file => {
            return <div key={file._id}>
                {fileComponent(file)}
            </div>;
        })}

        <div>
            {dragComponent("File")}
        </div>

        {previewComponent}
    </div>;
};

export default CategoryFiles;
