import { FC } from "react";

import { Button, Upload, UploadProps } from "antd";
import choosePhotoIcon from "assets/images/new-questionnaire/documentParse/photo_icon.svg";
import QuestionnaireButtonFooterV2 from "components/basic/Footer/QuestionnaireButtonFooterV2";
import { IFile } from "types/files";
import { logError } from "utils/sentry";

import { Receipt } from "./DocumentParseQuestion";
import DocumentUploadList from "./DocumentUploadList";

interface ParserProps {
  uploadQFile: (_file: IFile) => Promise<boolean>;
  uploadedCategoryReceipts: Receipt[];
  getFileIcon: (_fileName: string) => string;
  closeDrawer: () => void
  uploadErrorMessage: string;
}

const Parser: FC<ParserProps> = ({ uploadQFile, uploadedCategoryReceipts, getFileIcon, closeDrawer, uploadErrorMessage }) => {
    const customRequest = async (data: any) => {
        const result = await uploadQFile(data.file);
        if (!result) {
            logError("File was not uploaded!");
        }
    };
    const props: UploadProps = {
        customRequest,
        fileList: uploadedCategoryReceipts?.map(file => ({
            uid: file._id!,
            name: file.originalName,
            status: "done",
            url: file.url,
        })),
        showUploadList: false,
    };

    return (
        <><div className="fs16 fw400 taCenter mb20">
            Choose Document
        </div>
        <div className="document-parse__array">
            <Upload {...props} accept=".png, .pdf, .jpeg, .jpg, .doc, .docx">
                <div className="document-parse__array-item-wrapper flex-column">
                    <div className={"document-parse__array-item"}>
                        Choose Photo or File
                        <span>
                            <img src={choosePhotoIcon} alt="choose file icon"/>
                        </span>
                    </div>
                </div>
            </Upload>
        </div>
        <div>
            <DocumentUploadList uploadErrorMessage={uploadErrorMessage} uploadedCategoryReceipts={uploadedCategoryReceipts} getFileIcon={getFileIcon} />
        </div>
        <QuestionnaireButtonFooterV2>
            <Button type="primary" className="document-parse-continue" onClick={closeDrawer}>Continue</Button>
        </QuestionnaireButtonFooterV2>
        </>
    );
};

export default Parser;
