import "styles/common/sizes.less";

import { useMemo } from "react";

import { Col, Drawer, Row } from "antd";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import Picker, { PickerValue } from "react-mobile-picker";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

function getDayArray(year: number, month: number, minDate?: Date, maxDate?: Date) {
    // Adjust month to 0-based index
    const adjustedMonth = month - 1;

    // Get the number of days in the month
    const dayCount = new Date(year, adjustedMonth + 1, 0).getDate();

    // Generate days in the month (01, 02, ..., last day)
    const days = Array.from({ length: dayCount }, (_, i) => String(i + 1).padStart(2, "0"));

    if (minDate || maxDate) {
        const filteredDays = days.filter(day => {
            // Construct the current date string as "YYYY-MM-DD"
            const currentDate = dayjs(`${year}-${String(adjustedMonth + 1).padStart(2, "0")}-${day}`, "YYYY-MM-DD");

            // Parse minDate and maxDate with Day.js
            const minDayjs = minDate ? dayjs(minDate).startOf("day") : null;
            const maxDayjs = maxDate ? dayjs(maxDate).endOf("day") : null;

            // Check if currentDate is within the range
            const isAfterMinDate = !minDate || currentDate.isSameOrAfter(minDayjs);
            const isBeforeMaxDate = !maxDate || currentDate.isSameOrBefore(maxDayjs);

            return isAfterMinDate && isBeforeMaxDate;
        });

        return filteredDays;
    }

    return days;
}

function getYearArray(minDate?: Date, maxDate?: Date) {
    const currentYear = new Date().getFullYear();
    const minYear = minDate ? dayjs(minDate).year() : currentYear - 100;
    const maxYear = maxDate ? dayjs(maxDate).year() : currentYear + 100;

    return Array.from({ length: maxYear - minYear + 1 }, (_, i) => String(minYear + i));
}

type DatePickerMobileProps = {
    isOpen: boolean;
    onClose: () => void;
    selectedDate: Date | null;
    handlePickerChange: (_newValue: PickerValue, _key?: string) => void;
    minDate?: string; // "dd/MM/yyyy"
    maxDate?: string; // "dd/MM/yyyy"
    pickerTitle: string;
};

export default function DatePickerMobile(props: DatePickerMobileProps) {
    const { isOpen, onClose, selectedDate, handlePickerChange, minDate, maxDate, pickerTitle } = props;
    // Parse minDate and maxDate strings into Date objects
    const minDateObj = minDate ? dayjs(minDate, "DD/MM/YYYY").toDate() : undefined;
    const maxDateObj = maxDate ? dayjs(maxDate, "DD/MM/YYYY").toDate() : undefined;

    const pickerValue = useMemo(() => {
        let defaultDate = selectedDate ? dayjs(selectedDate) : dayjs();

        // Clamp defaultDate to minDate or maxDate if necessary
        if (minDateObj && defaultDate.isBefore(dayjs(minDateObj))) {
            defaultDate = dayjs(minDateObj);
        } else if (maxDateObj && defaultDate.isAfter(dayjs(maxDateObj))) {
            defaultDate = dayjs(maxDateObj);
        }

        return {
            year: String(defaultDate.year()),
            month: String(defaultDate.month() + 1).padStart(2, "0"),
            day: String(defaultDate.date()).padStart(2, "0"),
        };
    }, [selectedDate, minDateObj, maxDateObj]);

    return (
        <Drawer
            title={<>
                <span>{pickerTitle}</span>

            </>}
            height={"65%"}
            footer={
                <div style={{ display: "flex", flexDirection: "row", gap: "6px", justifyContent: "flex-end" }}>
                    <div
                        className="new-questionnaire__form-continue"
                        onClick={() => {
                            handlePickerChange(pickerValue);
                            onClose();
                        }}
                    >
                        DONE
                    </div>
                </div>
            }
            placement={"bottom"}
            closable={true}
            onClose={onClose}
            open={isOpen}
            key={"bottom"}
            styles={{
                body: {
                    height: "fit-content",
                    padding: 0,
                },
            }}
        >
            <Row className="mt10" justify={"center"} align={"middle"}>
                <Col span={8} className="taCenter grey-7-color">Day</Col>
                <Col span={8} className="taCenter grey-7-color">Month</Col>
                <Col span={8} className="taCenter grey-7-color">Year</Col>
            </Row>

            <Picker
                value={pickerValue}
                onChange={newValue => {
                    handlePickerChange(newValue);
                }}
                wheelMode="normal"
            >

                <Picker.Column name="day" className={"height-fit-content"} key={"day"}>
                    {getDayArray(
                        Number(pickerValue.year),
                        Number(pickerValue.month),
                        minDateObj,
                        maxDateObj
                    ).map(day => (

                        <Picker.Item key={day} value={day}>
                            {({ selected }) => (
                                <div
                                    className={`fs20 ${
                                        selected ? "font-semibold text-neutral-900" : "text-neutral-400"
                                    }`}
                                >
                                    {day}
                                </div>
                            )}
                        </Picker.Item>
                    ))}
                </Picker.Column>
                <Picker.Column name="month" className={"height-fit-content"} key={"month"}>
                    {Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, "0"))
                        .filter(month => {
                            const current = dayjs(`${pickerValue.year}-${month}-01`, "YYYY-MM-DD");
                            return (!minDateObj || current.isAfter(dayjs(minDateObj).subtract(1, "month"))) &&
                                   (!maxDateObj || current.isBefore(dayjs(maxDateObj).add(1, "month")));
                        })
                        .map(month => (
                            <Picker.Item key={month} value={month}>
                                {({ selected }) => (
                                    <div
                                        className={`fs20 ${
                                            selected ? "font-semibold text-neutral-900" : "text-neutral-400"
                                        }`}
                                    >
                                        {month}
                                    </div>
                                )}
                            </Picker.Item>
                        ))}
                </Picker.Column>
                <Picker.Column name="year" className={"height-fit-content"} key={"year"}>
                    {getYearArray(minDateObj, maxDateObj).map(year => (
                        <Picker.Item key={year} value={year}>
                            {({ selected }) => (
                                <div
                                    className={`fs20 ${
                                        selected ? "font-semibold text-neutral-900" : "text-neutral-400"
                                    }`}
                                >
                                    {year}
                                </div>
                            )}
                        </Picker.Item>
                    ))}
                </Picker.Column>
            </Picker>
        </Drawer>
    );
}
