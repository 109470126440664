import "styles/nameCircle.less";

import { FC } from "react";

interface ProfileTabNameCircleProps {
  firstName?: string;
  lastName?: string;
}

const ProfileTabNameCircle: FC<ProfileTabNameCircleProps> = ({ firstName, lastName }) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : "";
    const lastInitial = lastName ? lastName[0].toUpperCase() : "";

    return (
        <div className="profile-tab__name-circle">
            {firstInitial}
            {lastInitial}
        </div>
    );
};

export default ProfileTabNameCircle;
