import { Menu } from "antd";
import connectbanks from "assets/images/new-questionnaire/connect_banks.svg";
import connectbanksDefault from "assets/images/new-questionnaire/connect_banks_not_selected.svg";
import dashboard from "assets/images/new-questionnaire/dash_board.svg";
import logout from "assets/images/new-questionnaire/logout.svg";
import selfAssessmentForm from "assets/images/new-questionnaire/self_assessment_form.svg";
import selfAssessmentFormDefault from "assets/images/new-questionnaire/self_assessment_form_not_selected.svg";
import support from "assets/images/new-questionnaire/support.svg";
import Logout from "components/inputs/Auth/Logout";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { next } from "redux/reducers/navigation";

type DropDownDesktopProps = {
    showDropDown: boolean;
    showToggle: () => void;
    connectedBankAccounts: any;
};

const DropDownDesktop = ({ showDropDown, showToggle, connectedBankAccounts }: DropDownDesktopProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { currentCategory, currentStep } = useAppSelector(state => state.navigation);

    const goToSection = (category: number, step: number) => {
        dispatch(next({ nextCategory: category, nextStep: step, nextQuestion: 0 }));
    };

    const menuItems = [
        {
            key: "Homepage",
            label: "Self-assessment Form",
            icon: (
                <img
                    src={currentCategory === 1 && currentStep === 0 ? selfAssessmentForm : selfAssessmentFormDefault}
                    alt="Returns Icon"
                />
            ),
            onClick: () => {
                goToSection(1, 0);
                showToggle();
            },
            className: `client-navbar__dropdown-text ${(currentCategory !== 0 && currentStep !== 1) ? "active" : ""}`,
            style: { height: "48px" },
        },
        {
            key: "Bank",
            label: (
                <div className="client-navbar__bank-accounts">
                    Connected Banks
                    <div className="client-navbar__connected-accounts-number ml20">
                        {connectedBankAccounts?.length || 0}
                    </div>
                </div>
            ),
            icon: <img src={currentCategory === 0 && currentStep === 1 ? connectbanks : connectbanksDefault} alt="connect banks" />,
            onClick: () => {
                goToSection(0, 1);
                showToggle();
            },
            className: `client-navbar__dropdown-text ${(currentCategory === 0 && currentStep === 1) ? "active" : ""}`,
        },
        {
            key: "Dashboard",
            label: "Profile",
            icon: <img src={dashboard} alt="dashboard" />,
            onClick: () => navigate("/profile"),
            className: "client-navbar__dropdown-text",
        },
        {
            key: "Support",
            label: "Support",
            icon: <img src={support} alt="support" />,
            onClick: () => navigate("/profile?tab=customer-support"),
            className: "client-navbar__dropdown-text",
        },
        {
            key: "Log out",
            label: <Logout />,
            icon: <img src={logout} alt="Log out" />,
            className: "client-navbar__dropdown-text",
        },
    ];

    return (
        <div className="pt10" style={{ position: "absolute" }}>
            {showDropDown && <Menu className="client-navbar__dropdown-desktop" items={menuItems} />}
        </div>
    );
};

export default DropDownDesktop;
