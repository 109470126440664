import { ChangeEvent, KeyboardEvent, FC, useCallback, useEffect, useMemo, useState } from "react";

import infoIcon from "assets/images/new-questionnaire/info_icon.svg";
import questionIcon from "assets/images/new-questionnaire/question_icon.svg";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { checkDepends } from "utils/new-questionnaire";

import { Depend, Question } from "../../constants/questions";
import { PromptModalType } from "../../enums/enum";
import PromptModal from "../Shared/PromptModal";

type ObjectQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: string[] | undefined | Record<string, string | null>) => void;
    initialValue?: Record<string, string | null>;
    categoryAnswers?: Record<string, any>;
    handlePrev: () => void;
};

const initializeAnswers = (options: string[]) =>
    options.reduce<Record<string, string | null>>((acc, option) => {
        acc[option] = null;
        return acc;
    }, {});

const ObjectQuestion: FC<ObjectQuestionProps> = ({ data, onAnswer, handlePrev, initialValue, categoryAnswers }) => {
    const [showModalOpener, setShowModalOpener] = useState(false);
    const [editableOptionsAmount, setEditableOptionsAmount] = useState<Record<string, string | null>>();
    const [isEdit, setEdit] = useState<Record<string, boolean>>({});
    const helpText = useMemo(() => data.infoText, [data]);

    const promptModalData = useMemo(
        () => ({
            type: data.promptModal?.type || PromptModalType.info,
            title: data.promptModal?.title || "",
            text: data.promptModal?.text || "",
            image: data.promptModal?.image || "",
        }),
        [data.promptModal]
    );

    const { isOpened: isQuestionHelpModalOpened, open: handleQuestionHelpOpenModal, close: handleQuestionHelpCloseModal } =
        useModalOpener();

    const checkModalDepends = useCallback(
        (depend: Depend[], categoryAnswers?: Record<string, any>) => {
            if (categoryAnswers) {
                return checkDepends(depend, categoryAnswers);
            } else {
                return false;
            }
        },
        []
    );

    const handleBlur = (option: string) => {
        setEdit(prev => ({ ...prev, [option]: false }));
    };

    const handleEnterPress = (e: KeyboardEvent<HTMLInputElement>, option: string) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleBlur(option);
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>, category: string) => {
        const value = e.target.value.replace(",", ""); // Remove commas

        if (/^\d*\.?\d{0,2}$/.test(value)) { // Allow numbers + max 2 decimal places
            const newEditableOptionsAmount = { ...editableOptionsAmount, [category]: value || null };
            setEditableOptionsAmount(newEditableOptionsAmount);
            onAnswer(data.question, newEditableOptionsAmount);
        }
    };

    useEffect(() => {
        if (data?.promptModal?.depend && categoryAnswers) {
            const shouldModalShow = checkModalDepends(data.promptModal?.depend, categoryAnswers);
            setShowModalOpener(shouldModalShow);
            if (shouldModalShow && !data.promptModal?.manualOpen) {
                handleQuestionHelpOpenModal();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data?.promptModal?.depend && categoryAnswers) {
            const shouldModalShow = checkModalDepends(data.promptModal?.depend, categoryAnswers);
            setShowModalOpener(shouldModalShow);
            if (shouldModalShow && !data.promptModal?.manualOpen) {
                handleQuestionHelpOpenModal();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const newValues = initialValue || (data.options && initializeAnswers(data.options)) || {};
        setEditableOptionsAmount(newValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue, data.options]);

    return (
        <div className="new-questionnaire__question">
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                {data.question}
                {(data.promptModal?.showModalOpener || showModalOpener) && (
                    <span className="new-questionnaire__question-icon" onClick={handleQuestionHelpOpenModal}>
                        <img src={questionIcon} width={20} alt="help"/>
                    </span>
                )}
            </div>
            <div className="new-questionnaire__select-one-option__question-options mt10">
                <div className={"new-questionnaire__object-question-tear-off"}>
                    {data?.options?.map(option => (
                        <div key={option} className="new-questionnaire__amount-card-wrapper">
                            <div className="new-questionnaire__income-card-total-text" style={{ fontSize: "14px" }}>
                                {option}
                            </div>
                            {isEdit[option] ? (
                                <div className="new-questionnaire__amount-question-input-wrapper">
                                    <input
                                        className="new-questionnaire__amount-question-input"
                                        onChange={e => handleChange(e, option)}
                                        type="text"
                                        inputMode="decimal"
                                        pattern="[0-9]*"
                                        autoFocus={true}
                                        value={(editableOptionsAmount ?? {})[option] ?? ""}
                                        style={{ fontSize: "16px", textAlign: "end", marginLeft: "0" }}
                                        onBlur={() => handleBlur(option)} // Exit edit mode on blur
                                        onKeyDown={e => handleEnterPress(e, option)} // Exit edit mode on Enter
                                    />
                                    <div className="ml6">{data.unit}</div>
                                </div>
                            ) : (
                                <div
                                    className="new-questionnaire__income-report-total-amount-wrapper"
                                    onClick={() => {
                                        setEdit(prev => ({ ...prev, [option]: true }));
                                    }}
                                >
                                    <div className="new-questionnaire__income-card-total-amount">
                                        {editableOptionsAmount && Object.keys(editableOptionsAmount).length !== 0 && editableOptionsAmount[option] !== null
                                            ? editableOptionsAmount[option] + " " + data.unit
                                            : data?.addButtonText || `0 ${data.unit}`}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            {helpText && (
                <div className="new-questionnaire__info-message">
                    <img src={infoIcon} width={20} alt="info" />
                    <div className="new-questionnaire__info-message-text ml4">
                        {helpText}
                    </div>
                </div>
            )}
            {isQuestionHelpModalOpened && (
                <PromptModal
                    isOpen={isQuestionHelpModalOpened}
                    closeModal={handleQuestionHelpCloseModal}
                    handlePrev={handlePrev}
                    data={promptModalData}
                />
            )}
        </div>
    );
};

export default ObjectQuestion;
