const Label = ({
    text,
}: {
    text: string;
}) => {
    return <div
        style={{
            color: "#555",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
        }}
    >
        {text}
    </div>;
};

export default Label;
