import { useEffect, useState } from "react";

import { useAppDispatch } from "../../../../../redux/hooks";
import { setCountryBanks } from "../../../../../redux/reducers/countries";
import { usePushBankMutation, useGetBanksMutation } from "../../../../../services/connectBankAccount";

export const useFetchBanks = () => {
    const [getBanks] = useGetBanksMutation();
    const [banks, setBanks] = useState<any[]>([]);
    const specificBankNames = [
        "Hsbc Personal",
        "Natwest",
        "Lloyds Bank Personal",
        "Tsb bank",
    ];
    const countryCode = "GB";
    useEffect(() => {
        const fetchBanks = async () => {
            try {
                const response = await getBanks({ countryCode }).unwrap();
                const filteredBanks = response.institutions.filter((bank: any) => {
                    const bankName = bank.name.trim().toLowerCase();
                    const bankMatch = specificBankNames.map(name => name.toLowerCase()).includes(bankName);
                    return bankMatch;
                });
                setBanks(filteredBanks);
            } catch (err) {
                console.error("Error fetching banks:", err);
            }
        };

        if (countryCode) {
            fetchBanks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode, getBanks]);

    return { banks };
};

export const useHandleRegionalBank = (
    isMobile: boolean,
    // eslint-disable-next-line no-unused-vars
    setPageLoading: (loading: boolean) => void,
    // eslint-disable-next-line no-unused-vars
    setPaymentModeModal: (value: boolean) => void
) => {
    const dispatch = useAppDispatch();
    const [pushBank] = usePushBankMutation();

    function handleRegionalBank(Id: any) {
        setPageLoading(true);
        pushBank({ Id: Id })
            .unwrap()
            .then(data => {
                if (data && data?.authLink) {
                    dispatch(setCountryBanks(data));

                    if (isMobile) {
                        setPaymentModeModal(false);
                        window.location.href = data.authLink;
                    } else {
                        setPaymentModeModal(true);
                    }
                } else {
                    console.error("Invalid data received from API:", data);
                }
                return data;
            })
            .catch(error => {
                console.error("Error fetching banks:", error);
            })
            .finally(() => {
                setPageLoading(false);
            });
    }
    return { handleRegionalBank };
};
