import { Dispatch, SetStateAction } from "react";

import { Menu } from "antd";
import closeIcon from "assets/images/header/cancel.svg";
import logout from "assets/images/new-questionnaire/logout.svg";
import support from "assets/images/new-questionnaire/support.svg";
import taxzapLogo from "assets/images/taxzap-logo-R.svg";
import NewQuestionnaireNameCircle from "components/common/NewQuestionnaireNameCircle";
import Logout from "components/inputs/Auth/Logout";
import { useNavigate } from "react-router-dom";
import { IUserNotifications } from "types/auth";

type DashboardMenuProps = {
    showNotifications: boolean;
    showToggle: () => void;
    userNotifications?: IUserNotifications;
    firstName: string;
    lastName: string;
    email: string;
    setPageLoading: Dispatch<SetStateAction<boolean>>;
};

const DashboardMenu = ({
    showNotifications,
    showToggle,
    firstName,
    lastName,
    email,
    setPageLoading,
}: DashboardMenuProps) => {
    const navigate = useNavigate();

    const menuItems = [
        {
            key: "Support",
            label: "Support",
            icon: <img src={support} alt="support" />,
            onClick: () => {
                navigate("/profile?tab=customer-support");
                setPageLoading(true);
                showToggle();
            },
        },
    ];

    return (
        <div>
            {showNotifications && (
                <div className="client-navbar__overlay-menu">
                    <div className="client-navbar__parent">
                        <div className="client-navbar__notifications-header">
                            <img
                                className="header-burger-menu__close"
                                src={closeIcon}
                                alt="close icon"
                                onClick={showToggle}
                            />
                        </div>
                        <Menu className="client-navbar__dropdown-mobile" items={menuItems}>
                            <div className="horizontal center">
                                <img className="logo-questionnaire-navbar" src={taxzapLogo} alt="TaxZap Logo" />
                            </div>
                        </Menu>
                    </div>

                    <div className="client-navbar__border">
                        <div className="client__navbar">
                            <NewQuestionnaireNameCircle firstName={firstName} lastName={lastName} />
                            <div className="ml6">
                                <div className="new-questionnaire__client-name">{`${firstName} ${lastName}`}</div>
                                <div className="new-questionnaire__client-email">{email}</div>
                            </div>
                        </div>
                        <div className="client-navbar__dropdown-text" onClick={showToggle}>
                            <img src={logout} alt="Log out" />
                            <Logout />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DashboardMenu;
