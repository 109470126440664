
import { FC, ChangeEvent, KeyboardEvent, useCallback, useEffect, useState, useMemo, useContext } from "react";

import { Form, Input } from "antd";
import { AppContext } from "App";
import errorIcon from "assets/images/new-questionnaire/exclamationmark-circle.svg";
import questionIcon from "assets/images/new-questionnaire/question_icon.svg";
import { useSetDocumentNinUtrMutation, checkNinAvailability } from "services/client";
import { isUTRValid, normalizeUTR } from "utils/validation";

import { useUser } from "../../../../../hooks/common/useUser";
import { useAppSelector } from "../../../../../redux/hooks";
import { RootState } from "../../../../../redux/store";
import { Question } from "../../constants/questions";
import PromptModal from "../Shared/PromptModal";

/* eslint-disable */
export enum NinUtrQuestionType {
    NIN = "National Insurance Number",
    UTR = "Unique Tax Reference Number (UTR)",
}
/* eslint-enable */

type NinUtrQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: string | undefined) => void;
    categoryAnswers: Record<string, any>;
};

const isNINValid = (nin = "") => {
    const ukNINPattern = /^[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z]\d{6}[A-D]$/i;
    return ukNINPattern.test(nin);
};

const NinUtrQuestion: FC<NinUtrQuestionProps> = ({ data, onAnswer, categoryAnswers }) => {
    const { isMobile } = useContext(AppContext);
    const { user } = useUser();
    const [nin, setNin] = useState<string | undefined>(undefined);
    const [utr, setUtr] = useState<string | undefined>(undefined);
    const [promptModalData, setPromptModalData] = useState({ title: "", text: "" });
    const [showModalOpener, setShowModalOpener] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorNin, setErrorNin] = useState<string | null>(null);
    const [errorUtr, setErrorUtr] = useState<string | null>(null);
    const helpText = useMemo(() => data.infoText, [data]);
    const { currentDocument } = useAppSelector(
        (state: RootState) => ({
            currentDocument: state.document.currentDocument,
        })
    );
    const [setDocumentNinUtr] = useSetDocumentNinUtrMutation();
    const { data: ninAvailability } = checkNinAvailability.useQuery(
        { nin: nin },
        { skip: !isNINValid(nin) }
    );

    const validateUTR = useCallback((value: string) => {
        if (!value) return false;
        return isUTRValid(normalizeUTR(value));
    }, []);

    const handleInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, inputType: NinUtrQuestionType) => {
            const value = e.target.value;
            const isNin = inputType === NinUtrQuestionType.NIN;

            isNin ? setNin(value) : setUtr(value);

            const isValid = isNin ? isNINValid(value) : validateUTR(value);
            const setError = isNin ? setErrorNin : setErrorUtr;
            const errorMessage = `Please enter a valid ${isNin ? "NIN" : "UTR"}`;

            if (!isValid) return setError(errorMessage);

            if (isNin && ninAvailability?.available === false) {
                return setError("This NIN number already exists");
            }

            setError(null);
            onAnswer(inputType, value);

            if ((!user || !currentDocument?._id) || !isValid) return;

            setDocumentNinUtr({
                clientId: user.id,
                documentId: currentDocument?._id,
                body: {
                    nin: isNin ? value : nin,
                    utr: isNin ? utr : value,
                },
            });
        },
        [currentDocument, nin, utr, onAnswer, user, ninAvailability, validateUTR, setDocumentNinUtr]
    );

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.key === "Enter") {
            isMobile ? e.currentTarget.blur() : e.preventDefault();
        }
    };

    const handleIconClick = (ninUtrQuestionType: NinUtrQuestionType) => {
        switch (ninUtrQuestionType) {
        case NinUtrQuestionType.NIN:
            setPromptModalData({
                title: "",
                text: `<div style='font-weight: 600; color: #000; font-size: 18px; text-align: left;'>What is a National Insurance Number?</div>
                    <div style='text-align: left;'>A National Insurance Number (NIN) is a number used in the United Kingdom in the administration of the National Insurance or social security system. It is also used for some purposes in the UK tax system. The number is sometimes referred to as a NI No or NINO.</div>
                    <div style='font-weight: 600; color: #000; font-size: 18px; text-align: left'>How do I find my National Insurance (NI) Number?</div>
                    <ul style='text-align: left; padding-left: 5%; margin-top: 0px'>
                        <li>Payslip or P60: Your NI Number is often printed on your payslip or end-of-year tax statement (P60)</li>
                        <li>National Insurance Card or Letter: If you received a National Insurance card or letter from HMRC, it will show your NI Number</li>
                        <li>HMRC App or Online Account: Log in to your Personal Tax Account on the HMRC website or app to view your NI Number</li>
                        <li>Letters from HMRC: Check any official letters about tax, benefits, or pensions</li>
                    </ul>`,
            });
            setIsModalOpen(true);
            break;
        case NinUtrQuestionType.UTR:
            setPromptModalData({
                title: "",
                text: `<div style='font-weight: 600; color: #000; font-size: 18px; text-align: left; margin-top: 10px'>What is a UTR number?</div>
                    <div style='text-align: left;'>A Unique Taxpayer Reference number is a 10-digit number issued by HMRC to self-employed individuals or people required to file a Self Assessment tax return.</div>
                    <div style='font-weight: 600; color: #000; font-size: 18px; text-align: left'>How do I find my UTR number?</div>
                    <ul style='text-align: left; padding-left: 5%; margin-top: 0px'
                    <li>In your HMRC Personal Tax Account</li>
                    <li>On documents sent by HMRC</li>
                    </ul>`,
            });
            setIsModalOpen(true);
            break;
        default:
            break;
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (categoryAnswers[NinUtrQuestionType.NIN]) {
            setNin(categoryAnswers[NinUtrQuestionType.NIN] || user?.nin || "");
        }

        if (categoryAnswers[NinUtrQuestionType.UTR]) {
            setUtr(categoryAnswers[NinUtrQuestionType.UTR] || user?.utr || "");
        }
    }, [categoryAnswers, user]);

    return (
        <div className="new-questionnaire__question">
            <div className={"new-questionnaire__question-text mb20"}>
                {isMobile && <span>{data.question}</span>}
                {showModalOpener && (
                    <span className="new-questionnaire__question-icon" onClick={() => setShowModalOpener(true)}>
                        <img src={questionIcon} width={20} alt="help"/>
                    </span>
                )}
            </div>
            <Form layout="vertical" className="w335px">
                <Form.Item className={"w100"} label={"National Insurance (NI) Number"}>
                    <Input className="new-questionnaire__input new-questionnaire__input-text"
                        onChange={(e: any) => handleInputChange(e, NinUtrQuestionType.NIN)}
                        value={nin}
                        onKeyDown={handleKeyDown}
                        suffix={
                            <img
                                src={questionIcon}
                                alt="icon"
                                onClick={e => {
                                    e.stopPropagation();
                                    handleIconClick(NinUtrQuestionType.NIN);
                                }}
                                width={20}
                                height={20}
                                className="pointer"
                            />
                        }
                    />

                    {errorNin && (
                        <div className="new-questionnaire__error-message">
                            <img src={errorIcon} width={20} alt="error" />
                            <div className="new-questionnaire__error-message-text ml4">{errorNin}</div>
                        </div>
                    )}
                </Form.Item>
                <Form.Item className={"w100"} label={"Unique Tax Reference Number (UTR)"} >
                    <Input
                        className="new-questionnaire__input new-questionnaire__input-text"
                        onChange={(e: any) => handleInputChange(e, NinUtrQuestionType.UTR)}
                        value={utr}
                        onKeyDown={handleKeyDown}
                        suffix={
                            <img
                                src={questionIcon}
                                alt="icon"
                                onClick={e => {
                                    e.stopPropagation();
                                    handleIconClick(NinUtrQuestionType.UTR);
                                }}
                                width={20}
                                height={20}
                                className="pointer"
                            />
                        }
                        inputMode="decimal"

                    />
                    {errorUtr && (
                        <div className="new-questionnaire__error-message">
                            <img src={errorIcon} width={20} alt="error" />
                            <div className="new-questionnaire__error-message-text ml4">{errorUtr}</div>
                        </div>
                    )}
                </Form.Item>
            </Form>

            <PromptModal
                isOpen={isModalOpen}
                closeModal={handleModalClose}
                data={promptModalData}
                handlePrev={() => {
                }}
            />
            <div className={isMobile ? "w90" : "w335px"}>
                {helpText && (
                    <div className="new-questionnaire__info-message m0">
                        <div className="new-questionnaire__info-message-text fcBrand">{helpText}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NinUtrQuestion;
