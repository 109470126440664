import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";

import { AppContext } from "App";
import editPencil from "assets/images/new-questionnaire/edit_pencil.svg";
import successIcon from "assets/images/new-questionnaire/success-icon.svg";
import QuestionnaireButtonFooterV2 from "components/basic/Footer/QuestionnaireButtonFooterV2";
import ButtonLoading from "components/basic/Spinner/ButtonLoading";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { selectCurrentNavigation } from "redux/reducers/navigation";
import { setCurrentProfileAnswers } from "redux/reducers/taxProfile";
import { useGetClientTaxProfileQuery, useUpdateClientTaxProfileMutation } from "services/client";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { IUser } from "types/auth";
import { AccountDetails } from "types/bankAccounts";
import { GetClientTaxProfileResponse, TaxProfile } from "types/client";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { logError } from "utils/sentry";

import { Question } from "../../constants/questions";
import { ProfileType } from "../../enums/enum";
import InlineAmountInput from "../Shared/InlineEditableAmountInput";

type ProfileIncomeSummaryProps = {
    user: IUser;
    currentStepData: any;
    data: Question;
    taxProfileType: ProfileType;
    taxProfile: TaxProfile;
    handleContinue: () => void;
    handlePrev: () => void;
    connectedBankAccounts: AccountDetails[];
};

const ProfileIncomeSummary: React.FC<ProfileIncomeSummaryProps> = ({
    user,
    taxProfile,
    taxProfileType,
    handleContinue,
    data,
}) => {
    const dispatch = useDispatch();
    const { stepLoading } = useAppSelector(selectCurrentNavigation);
    const [ updateClientTaxProfile] = useUpdateClientTaxProfileMutation();
    const isKeyboardOpen = useDetectKeyboardOpen();

    const { isMobile } = useContext(AppContext);
    const [, setEdit] = useState<Record<string, boolean>>({});

    const [editableCategoryAmount, setEditableCategoryAmount] = useState<Record<string, string>>({});
    const [amountEntered, setAmountEntered] = useState(false);
    const [currentProfile, setCurrentProfile] = useState<TaxProfile>(taxProfile);
    const { currentProfileAnswers } = useAppSelector(state => state.taxProfile);

    const incomeTitle = "Total Gross Income";

    const { data: taxProfileResponse, refetch: refetchTaxProfile } = useGetClientTaxProfileQuery(
        {
            client: user?.id!,
            taxProfileId: taxProfile?._id!,
        },
        {
            skip: !user?.id || !taxProfile, // Skip the query if user or currentProfile is not available
        }
    );

    const getTotalGrossRevenue = useCallback(
        (profileResponse: GetClientTaxProfileResponse | undefined) => {
            return (
                profileResponse?.taxProfile?.revenue?.confirmedTotalGross ||
                profileResponse?.taxProfile?.revenue?.swipedApprovedTransactionsTotal ||
                profileResponse?.taxProfile?.revenue?.parsedP60GrossPay ||
                profileResponse?.taxProfile?.revenue?.parsedCisGrossPay ||
                0
            );
        },
        []
    );

    const getTotalTaxPaid = useCallback(
        (profileResponse: GetClientTaxProfileResponse | undefined) => {
            return (
                profileResponse?.taxProfile?.revenue?.taxAlreadyPaidOnRevenue ||
            profileResponse?.taxProfile?.revenue?.parsedP60TaxDeducted ||
            profileResponse?.taxProfile?.revenue?.parsedCisTaxDeducted ||
            0
            );
        },
        []
    );

    const fetchedProfileRevenue = useMemo(() => {
        const revenue: Record<string, string> = {
            "Total Gross Income": getTotalGrossRevenue(taxProfileResponse).toFixed(2),
        };

        if (taxProfileType !== "Self Employment") {
            revenue["Total Tax Paid"] = getTotalTaxPaid(taxProfileResponse).toFixed(2);
        }

        return revenue;
    }, [taxProfileType, taxProfileResponse, getTotalGrossRevenue, getTotalTaxPaid]);

    const handleConfirm = useCallback(async () => {
        setEdit({});

        await updateClientTaxProfile({
            taxProfileId: currentProfile._id,
            client: currentProfile.client,
            revenue: {
                ...currentProfile.revenue,
                confirmedTotalGross: Number(editableCategoryAmount[incomeTitle]),
                taxAlreadyPaidOnRevenue: Number(editableCategoryAmount["Total Tax Paid"]),
            },

        }).unwrap()
            .then(() => {
                refetchTaxProfile?.();
                handleContinue();
            })
            .catch(error => {
                logError(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableCategoryAmount, handleContinue, currentProfile, updateClientTaxProfile, refetchTaxProfile, taxProfileType]);

    const handleTotalAmountChange = (e: ChangeEvent<HTMLInputElement>, category: string) => {
        const value = e.target.value.replace(",", ".");
        if (/^\d*\.?\d{0,2}$/.test(value)) {
            setEditableCategoryAmount(prev => ({ ...prev, [category]: value }));
        }
    };

    const handleEditToggle = (categoryName: string, isEditing: boolean) => {
        setEdit(prev => ({
            ...prev,
            [categoryName]: isEditing,
        }));
    };

    const getPageExplainerText = () => {
        switch (taxProfileType) {
        case ProfileType.selfEmployment:
            return "Let's add your income. Add the total gross income associated with this self-employment";
        case ProfileType.paye:
            return "Please provide the total salary you earned and the tax you paid for this employment, as shown on your P60.";
        case ProfileType.cis:
            return "Please review and confirm the total gross income that you received from this contractor, and the CIS tax deducted.";

        default:
            return "";
        }
    };

    const getPageHeaderText = () => {
        switch (taxProfileType) {
        case ProfileType.selfEmployment:
            return "Confirm Your Earnings";
        case ProfileType.paye:
            return "Enter Your Salary and Tax Paid";
        case ProfileType.cis:
            return "Confirm Your Earnings";
        default:
            return "Confirm Your Earnings";
        }
    };

    useEffect(() => {
        if (Object.keys(fetchedProfileRevenue).length > 0) {
            setEditableCategoryAmount(fetchedProfileRevenue);
        }
    }, [fetchedProfileRevenue]);

    useEffect(() => {
        if (taxProfileResponse?.taxProfile) {
            setCurrentProfile(taxProfileResponse.taxProfile);
        }
    }, [taxProfileResponse]);

    useEffect(() => {
        if (Number(editableCategoryAmount[incomeTitle] || 0) > 0) {
            setAmountEntered(true);
        } else {
            setAmountEntered(false);
        }
    }, [editableCategoryAmount]);

    useEffect(() => {
        const updatedAnswers = currentProfileAnswers.map(q => ({ ...q })); // ✅ Deep copy objects
        const existingIndex = updatedAnswers.findIndex(q => q.question === data.question);
        const updatedAnswer = { question: data.question, answer: Number(editableCategoryAmount[incomeTitle]), type: data.type || "", id: data.id || "" };
        if (existingIndex > -1) {
            updatedAnswers[existingIndex] = updatedAnswer;
        } else {
            updatedAnswers.push(updatedAnswer);
        }
        dispatch(setCurrentProfileAnswers(updatedAnswers));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableCategoryAmount]);

    useEffect(() => {
        refetchTaxProfile?.();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`new-questionnaire__income-expenses-container ${isMobile ? "" : "mt30"}`}>
            <div className="new-questionnaire__income-expenses-title taLeft mt15 w335px">{getPageHeaderText()}</div>
            <div className="new-questionnaire__income-expenses-explainer">{getPageExplainerText()}</div>
            <div className="new-questionnaire__income-report__wrapper m20">
                <div className="new-questionnaire__expense-report">
                    {amountEntered && <img src={successIcon} alt="success" className="new-questionnaire__income-report__success-icon"/>}
                    <div className="w100">
                        <Swiper
                            {...({ slidesPerView: 1 } as any)}
                            {...({ spaceBetween: 3 } as any)}
                            pagination={{ type: "fraction" }}
                            loop={true}
                            modules={[Pagination]}
                        >

                            <SwiperSlide>
                                <div className="new-questionnaire__expense-report-card">
                                    {Object.entries(fetchedProfileRevenue!).map(([categoryName]) => (
                                        <InlineAmountInput
                                            key={categoryName}
                                            categoryName={categoryName}
                                            amount={editableCategoryAmount[categoryName] ?? "0.00"}
                                            onAmountChange={handleTotalAmountChange}
                                            onEditToggle={handleEditToggle}
                                            editIcon={editPencil}
                                        />
                                    ))}
                                </div>
                            </SwiperSlide>

                        </Swiper>
                    </div>

                </div>
                <div className={`${isKeyboardOpen ? "" : "mt120"}`}>
                    <QuestionnaireButtonFooterV2>
                        <div
                            className={`new-questionnaire__form-continue ${!stepLoading ? "" : "disabled"} mt25`}
                            onClick={() => {
                                if (!stepLoading) {
                                    handleConfirm();
                                }
                            }}
                        >
                            {stepLoading ? <ButtonLoading/>
                                : <span>{"Confirm"}</span>}
                        </div>
                    </QuestionnaireButtonFooterV2>
                </div>
            </div>
        </div>
    );
};

export default ProfileIncomeSummary;
