import { ExpensesCategory } from "types/client";

export function convertArrayOfPrimitivesToDict<T extends readonly string[]>(keys: T): Record<T[number], T[number]> {
    const obj: Record<string, typeof keys[number]> = {};

    for (const key of keys) {
        obj[key] = key;
    }

    return obj;
}

export function splitIntoChunks<T>(obj: Record<string, T>, chunkSize: number): Record<string, T>[] {
    const entries = Object.entries(obj);
    const chunks = [];
    for (let i = 0; i < entries.length; i += chunkSize) {
        chunks.push(Object.fromEntries(entries.slice(i, i + chunkSize)));
    }
    return chunks;
}

export function splitIntoCategoryChunks<T>(obj: ExpensesCategory, chunkSize: number): Record<string, T>[] {
    const entries = Object.entries(obj);
    const chunks = [];
    for (let i = 0; i < entries.length; i += chunkSize) {
        chunks.push(Object.fromEntries(entries.slice(i, i + chunkSize)));
    }
    return chunks;
}
