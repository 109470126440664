import React, { useEffect, useRef } from "react";

import QRCode from "qrcode";

interface QRCodeComponentProps {
    url: string;
}

const QRCodeComponent: React.FC<QRCodeComponentProps> = ({ url }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            QRCode.toCanvas(canvas, url, { width: 400 }, error => {
                if (error) {
                    console.error(error);
                } else {
                    console.log("QR code generated!");
                }
            });
        }
    }, [url]);

    return (
        <div style={{ flex: 1, textAlign: "right" }}>
            <canvas ref={canvasRef} className="main-image-mobile" style={{ width: "400px", height: "400px" }}></canvas>
        </div>
    );
};

export default QRCodeComponent;
