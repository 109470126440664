import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "styles/common/swipeableCards.less";

import { AppContext } from "App";
import noIcon from "assets/images/new-questionnaire/no.svg";
import prevIcon from "assets/images/new-questionnaire/prev.svg";
import questionIcon from "assets/images/new-questionnaire/question_icon.svg";
import yesIcon from "assets/images/new-questionnaire/yes.svg";
import DOMPurify from "dompurify";
import { useLocalStorage } from "hooks/helpers/useLocalStorage";

import { templates } from "../../constants/questions";
import GenericSwipeHelpModal from "../Shared/GenericSwipeHelpModal";

type Card = {
    question: string;
    id: string;
    tags: string[];
    styles?: object;
};

type SwipeableCardsProps = {
    cards: Card[];
    title: string;
    categoryAnswers: Record<string, any>;
    cardHeight?: number;
    cardWidth?: number;
    description: string;
    onAnswerChange: (_question: string, _answer: string | undefined, _id: string) => void;
    handleStepHelpOpenModal?: () => void
    showModalOpener?: boolean;
    insertItemName?: boolean;
    itemName?: string;
};

const SwipeableCards: React.FC<SwipeableCardsProps> = ({
    cards: initialCards,
    title,
    description,
    categoryAnswers = {},
    cardHeight = 250,
    cardWidth = 335,
    onAnswerChange,
    handleStepHelpOpenModal,
    showModalOpener,
    insertItemName,
    itemName,
}) => {
    const { isMobile } = useContext(AppContext);

    const [cards, setCards] = useState<Card[]>([]);
    const [answers, setAnswers] = useState<{ card: Card; answer: string }[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [isSwiping, setIsSwiping] = useState<boolean>(false);
    const [dragStartX, setDragStartX] = useState<number | null>(null);
    const [offsetX, setOffsetX] = useState<number>(0);
    const [swipeDirection, setSwipeDirection] = useState<"left" | "right" | null>(null);
    const [reappear, setReappear] = useState<boolean>(false);
    const [disappear, setDisappear] = useState<boolean>(false);
    const [showGenericSwipeModal, setShowGenericSwipeModal] = useState<boolean>(false);
    const [hasGenericSwipeModalShown] = useLocalStorage<boolean>("generic_swipe_modal_shown", false);

    const SWIPE_THRESHOLD = useMemo(() => (isMobile ? 50 : 100), [isMobile]);

    const handleCloseModal = useCallback(() => {
        setShowGenericSwipeModal(false);
    }, [setShowGenericSwipeModal]);

    const handleMouseDown = useCallback((e: React.MouseEvent) => {
        setIsSwiping(true);
        setDragStartX(e.clientX);
        setSwipeDirection(null);
        document.body.classList.add("swipeable-card-no-select");
    }, []);

    const handleTouchStart = useCallback((e: React.TouchEvent) => {
        setIsSwiping(true);
        setDragStartX(e.touches[0].clientX);
        setSwipeDirection(null);
        document.body.classList.add("swipeable-card-no-select");
    }, []);

    const handleMouseMove = useCallback((e: React.MouseEvent) => {
        if (!isSwiping || dragStartX === null) return;
        const newOffsetX = e.clientX - dragStartX;
        setOffsetX(newOffsetX);
        setSwipeDirection(newOffsetX > 0 ? "right" : "left");
    }, [isSwiping, dragStartX]);

    const handleTouchMove = useCallback((e: React.TouchEvent) => {
        if (!isSwiping || dragStartX === null) return;
        const newOffsetX = e.touches[0].clientX - dragStartX;
        setOffsetX(newOffsetX);
        setSwipeDirection(newOffsetX > 0 ? "right" : "left");
    }, [isSwiping, dragStartX]);

    const handleSwipe = useCallback((direction: "left" | "right", currIndex: number) => {
        setSwipeDirection(direction);
        const answer = direction !== "left" ? "yes" : "no";
        const currentCard = cards[currIndex];
        setAnswers(prevAnswers => [...prevAnswers, {
            card: currentCard,
            answer,
        }]);
        onAnswerChange(currentCard.question, answer, currentCard.id);
        setCards(prevCards => prevCards.filter((_, index) => index !== currIndex));

        setTimeout(() => {
            // Only update the card index if its not -1
            // This is to prevent the index from being -1 when all cards are swiped
            const index = currIndex - 1;
            if (index >= 0) {
                setCurrentIndex(index);
            }
            setOffsetX(0);
            setSwipeDirection(null);
            document.body.classList.remove("swipeable-card-no-select");
            setDisappear(false);
        }, 300);
    }, [cards, onAnswerChange]);

    const handleMouseUp = useCallback(() => {
        setIsSwiping(false);
        document.body.classList.remove("swipeable-card-no-select");

        if (Math.abs(offsetX) > SWIPE_THRESHOLD) {
            const direction = offsetX > 0 ? "right" : "left";
            setOffsetX(direction === "right" ? 400 : -400);
            handleSwipe(direction, currentIndex);
        } else {
            setOffsetX(0);
            setSwipeDirection(null);
        }
    }, [offsetX, SWIPE_THRESHOLD, handleSwipe, currentIndex]);

    const getCardStyle = useCallback((index: number) => {
        const isCurrent = index === currentIndex;
        const isSecond = index === currentIndex - 1;
        const isThird = index === currentIndex - 2;

        if (isCurrent) {
            return {
                transform: `translate(${offsetX}px, ${offsetX < 0 ? "" : "-"}${(offsetX) * 0.4}px) rotate(${-(offsetX) * 0.15}deg)`,
            };
        } else if (isSecond) {
            return {
                transform: `scale(0.95) translateY(${-20}px)`,
            };
        } else if (isThird) {
            return {
                transform: `scale(0.90) translateY(${-40}px)`,
            };
        } else {
            return {
                transform: `scale(0.85) translateY(${-60}px)`,
            };
        }
    }, [offsetX, currentIndex]);

    const cardElements = useMemo(() => (

        cards.map((card, index) => {
            if (index < cards.length - 3) return null;
            const isCurrent = index === currentIndex;
            return (
                <div
                    key={index}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleMouseUp}
                    style={getCardStyle(index)}
                    className={`swipeable-card ${reappear && isCurrent ? "reappear" : ""} ${disappear && isCurrent ? `disappear-${swipeDirection === "right" ? "right" : "left"}` : ""}`}
                >
                    {isCurrent && (Math.abs(offsetX) > SWIPE_THRESHOLD || disappear) && (
                        <div
                            className={`swipeable-card-overlay ${swipeDirection === "right" ? "right" : "left"}`}
                        ></div>
                    )}
                    <div></div>
                    <div className="swipeable-card-title" style={card.styles || {}}>
                        {card.question && <span
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize((insertItemName && itemName)
                                    ? card.question.replace(templates.itemName, itemName)
                                    : card.question),
                            }}
                        />}
                    </div>
                    <div className="swipeable-card-tags">
                        {card.tags.map(value => (
                            <div key={value} className="swipeable-card-tag">
                                {value}
                            </div>
                        ))}
                    </div>
                </div>
            );
        })
    ), [cards, currentIndex, handleMouseDown, handleMouseMove, handleTouchStart, handleTouchMove, handleMouseUp, getCardStyle, reappear, disappear, swipeDirection, offsetX, SWIPE_THRESHOLD, insertItemName, itemName]);

    const handlePreviousCard = useCallback(() => {
        if (answers.length === 0) return;

        const lastAnswer = answers[answers.length - 1];
        setAnswers(prevAnswers => prevAnswers.slice(0, -1));
        setCards(prevCards => [...prevCards, lastAnswer.card]);
        onAnswerChange(lastAnswer.card.question, undefined, lastAnswer.card.id);
        setCurrentIndex(prevIndex => prevIndex + 1);
        setReappear(true);

        setTimeout(() => {
            setReappear(false);
        }, 200);
    }, [answers, onAnswerChange]);

    const handleNo = useCallback(() => {
        if (!disappear) {
            setDisappear(true);
            setSwipeDirection("left");
            setTimeout(() => {
                handleSwipe("left", currentIndex);
            }, 300);
        }
    }, [disappear, handleSwipe, currentIndex]);

    const handleYes = useCallback(() => {
        if (!disappear) {
            setDisappear(true);
            setSwipeDirection("right");
            setTimeout(() => {
                handleSwipe("right", currentIndex);
            }, 300);
        }
    }, [disappear, handleSwipe, currentIndex]);

    useEffect(() => {
        const handleDocumentMouseUp = () => {
            if (isSwiping) {
                handleMouseUp();
            }
        };

        document.addEventListener("mouseup", handleDocumentMouseUp);

        return () => {
            document.removeEventListener("mouseup", handleDocumentMouseUp);
        };
    }, [isSwiping, handleMouseUp]);

    useEffect(() => {
        if (!hasGenericSwipeModalShown) {
            setShowGenericSwipeModal(true);
        }
    }, [hasGenericSwipeModalShown]);

    useEffect(() => {
        const filteredCards = initialCards.filter(card => categoryAnswers[card.question] === undefined);
        setCards(filteredCards);

        setAnswers(
            initialCards
                .filter(card => categoryAnswers[card.question] !== undefined)
                .map(card => ({
                    card,
                    answer: categoryAnswers[card.question],
                }))
        );

        // Ensure currentIndex is always within bounds
        setCurrentIndex(filteredCards.length - 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialCards]);

    return (
        <div className="swipeable-cards-container">
            {showGenericSwipeModal && (
                <GenericSwipeHelpModal
                    onClose={handleCloseModal}
                    data={{
                        swipeLeftText: "Swipe left if answer is no!",
                        swipeRightText: "Swipe right if answer is yes!",
                    }}
                />

            )}
            {!isMobile && <div className="swipeable-cards-title">{title}</div>}
            <div className="swipeable-cards-description horizontal-center">
                {description}
                {showModalOpener && (
                    <span className="new-questionnaire__question-icon"
                        onClick={() => handleStepHelpOpenModal && handleStepHelpOpenModal()}>
                        <img src={questionIcon} width={20} alt="help"/>
                    </span>
                )}
            </div>
            <div className="swipeable-cards-wrapper" style={{
                height: `${cardHeight}px`,
                width: `${cardWidth}px`,
            }}>
                {cardElements}
            </div>
            <div className="swipeable-cards-buttons">
                <div onClick={() => handleNo()}>
                    <div className="swipeable-card-no-button">
                        <img src={noIcon} alt="no"/>
                    </div>
                    <div className="swipeable-card-buttons-text">
                        No
                    </div>
                </div>
                {answers.length !== 0
                    ? <div onClick={() => handlePreviousCard()}>
                        <div className="swipeable-card-prev-button">
                            <img src={prevIcon} alt="no"/>
                        </div>
                        <div className="swipeable-card-buttons-text">
                            Previous
                        </div>
                    </div> : <div style={{ width: "59px" }}></div>
                }
                <div onClick={() => handleYes()}>
                    <div className="swipeable-card-yes-button">
                        <img src={yesIcon} alt="yes"/>
                    </div>
                    <div className="swipeable-card-buttons-text">
                        Yes
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SwipeableCards;
