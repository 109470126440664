import { FC, useCallback, useEffect, useMemo, useState } from "react";

import questionIcon from "assets/images/new-questionnaire/question_icon.svg";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { useAppSelector } from "redux/hooks";
import { selectCurrentNavigation } from "redux/reducers/navigation";
import { checkDepends } from "utils/new-questionnaire";
import { removeEmojis } from "utils/strings";

import { Depend, Question } from "../../constants/questions";
import { PromptModalType } from "../../enums/enum";
import PromptModal from "../Shared/PromptModal";

type SelectManyOptionQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: string[] | undefined) => void;
    initialValue?: any;
    categoryAnswers?: Record<string, any>;
    handlePrev: () => void;
    setContinueButtonText?: (_value: string) => void;
};

const SelectManyOptionQuestion: FC<SelectManyOptionQuestionProps> = ({
    data,
    onAnswer,
    handlePrev,
    initialValue,
    categoryAnswers,
    setContinueButtonText,
}) => {
    const { stepLoading } = useAppSelector(selectCurrentNavigation);

    const [selectedOptions, setSelectedOptions] = useState<string[]>(initialValue || []);
    const [showModalOpener, setShowModalOpener] = useState(false);

    const promptModalData = useMemo(() => ({
        type: data.promptModal?.type || PromptModalType.info,
        title: data.promptModal?.title || "",
        text: data.promptModal?.text || "",
        image: data.promptModal?.image || "",
        promptActionButtonText: data.promptModal?.promptActionButtonText || "",
        promptActionButtonUrl: data.promptModal?.promptActionButtonUrl || "",
    }), [data.promptModal]);

    const {
        isOpened: isQuestionHelpModalOpened,
        open: handleQuestionHelpOpenModal,
        close: handleQuestionHelpCloseModal,
    } = useModalOpener();

    const handleOptionChange = (value: string) => {
        if (stepLoading) return;
        const updatedOptions = selectedOptions.length && selectedOptions?.includes(value)
            ? selectedOptions.filter(option => option !== value)
            : [...selectedOptions, value];

        setSelectedOptions(updatedOptions);
        onAnswer(data.question, updatedOptions);
    };

    const checkModalDepends = useCallback((depend: Depend[], categoryAnswers?: Record<string, any>) => {
        if (categoryAnswers) {
            return checkDepends(depend, categoryAnswers);
        } else {
            return false;
        }
    }, []);

    useEffect(() => {
        setSelectedOptions(initialValue || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useEffect(() => {
        if (data?.promptModal?.depend && categoryAnswers) {
            const shouldModalShow = checkModalDepends(data.promptModal?.depend, categoryAnswers);
            setShowModalOpener(shouldModalShow);
            if (shouldModalShow && !data.promptModal?.manualOpen) {
                handleQuestionHelpOpenModal();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryAnswers]);

    useEffect(() => {
        if (!selectedOptions.length && data.answerRequired === false) {
            setContinueButtonText && setContinueButtonText("None");
        }

        if (selectedOptions.length) {
            setContinueButtonText && setContinueButtonText("Continue");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptions]);

    return (
        <div className="new-questionnaire__question">
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                {data.question}
                {showModalOpener && (
                    <span className="new-questionnaire__question-icon" onClick={handleQuestionHelpOpenModal}>
                        <img src={questionIcon} width={20} alt="help"/>
                    </span>
                )}
            </div>

            <div className="new-questionnaire__select-many-tiles__question-options mt10">
                {data?.options?.map((value, index) => (
                    <div
                        key={index}
                        className={`new-questionnaire__select-many-tiles__question-option${selectedOptions?.includes(value) ? " selected" : stepLoading ? " disabled" : ""}`}
                        onClick={() => handleOptionChange(value)}
                    >
                        {data.iconMapping && (
                            <div>
                                <img width={30} height={30} src={data.iconMapping[value]} alt="icon" style={{ display: "block" }} />
                            </div>
                        )}
                        <div className="new-questionnaire__select-many-tiles__text">
                            {data.iconMapping ? removeEmojis(value) : value}
                        </div>
                    </div>
                ))}
            </div>
            {isQuestionHelpModalOpened && (
                <PromptModal
                    isOpen={isQuestionHelpModalOpened}
                    closeModal={handleQuestionHelpCloseModal}
                    handlePrev={handlePrev}
                    data={promptModalData}
                />
            )}
        </div>
    );
};

export default SelectManyOptionQuestion;
