import "styles/profile/paymentDetails.less";

import { useState, useEffect, useCallback, useContext } from "react";

import { Input, Form, Button, Typography, message, Divider, Modal } from "antd";
import { AppContext } from "App";
import cardInfo from "assets/images/profile/payment/card-info.png";
import ProfileButtonFooter from "components/basic/Footer/ProfileButtonFooter";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { useAppDispatch } from "redux/hooks";
import { setBankConnection, setOpenBankingError } from "redux/reducers/countries";
import { useCompleteConnectionMutation } from "services/connectBankAccount";
import { useGetAccountByIdMutation, useGetAllAccountsByInstitutionMutation } from "services/paymentBank";
import { IUpdateUserPaymentDetailsRequestBody, IUser } from "types/auth";
import { extractCountryCodeAndRefParams } from "utils/urlUtils";
import { isNameValid, isIbanValid, isBicValid } from "utils/validation";

import { DETAILS_TYPE_DICT } from "../../../../../constants/common";
import BankAccount from "../Homepage/BankAccount";
import GoCardlessErrorModal from "../Homepage/GoCardlessErrorModal";

type valuesT = {
    fullName: string;
    iban: string;
    bic: string;
    bankAccountNumber: string;
    sortCode: string;
}

interface Account {
    fullName: string;
    address: string;
    iban?: string;
    bic?: string;
    bankAccountNumber?: string,
    sortCode?: string,
}

type PaymentDetailsPropsT = {
    user: IUser;
    // eslint-disable-next-line no-unused-vars
    handleDetailsConfirm: (values: IUpdateUserPaymentDetailsRequestBody) => void;
    planConfirmed: boolean;
    handleFinish: (_values: IUpdateUserPaymentDetailsRequestBody) => void;
    showPaymentDetails: () => void;
    // eslint-disable-next-line no-unused-vars
    updateUserPaymentDetails: (payload: Account) => any;
    // eslint-disable-next-line no-unused-vars
    setPageLoading?: (loading: boolean) => void;
    isEditing?: boolean;
    title?: string | null;
    isError: boolean;
    isBankPage?: boolean;
};

const initialValues: valuesT = {
    fullName: "",
    iban: "",
    bic: "",
    bankAccountNumber: "",
    sortCode: "",
};

export default function PaymentDetails({
    user,
    handleDetailsConfirm,
    planConfirmed,
    showPaymentDetails,
    handleFinish,
    updateUserPaymentDetails,
    setPageLoading,
    isEditing,
    title,
    isError,
    isBankPage = false,
}: Readonly<PaymentDetailsPropsT>) {
    const dispatch = useAppDispatch();
    const [completeConnection] = useCompleteConnectionMutation();
    const [getAllAccountsByInstitution] = useGetAllAccountsByInstitutionMutation();
    const [getAccountById] = useGetAccountByIdMutation();
    const { isMobile } = useContext(AppContext);
    const {
        isOpened: isCardInfoOpened,
        close: handleCloseCardInfo,
    } = useModalOpener();
    const [selectAccount, setSelectAccount] = useState<boolean>(false);
    const [bankAccount, setBankAccount] = useState<any[]>([]);
    const [isSingleAccount, setIsSingleAccount] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const [updatedAccountDetails, setUpdatedAccountDetails] = useState<Account>({
        fullName: "",
        address: "",
        bankAccountNumber: "",
        sortCode: "",
    });

    const [form] = Form.useForm();
    const [detailsType, setDetailsType] = useState(DETAILS_TYPE_DICT["account"]);
    const [hasErrors, setHasErrors] = useState(false);

    const validateName = useCallback(async (value: string) => {
        if (!value) return Promise.resolve();
        if (isNameValid(value)) return Promise.resolve();
        return Promise.reject("Please write your name without an apostrophe");
    }, []);

    const validateIBAN = useCallback(async (value: string) => {
        if (!value) return Promise.resolve();
        if (isIbanValid(value)) return Promise.resolve();
        return Promise.reject("Please enter a valid IBAN");
    }, []);

    const validateBIC = useCallback(async (value: string) => {
        if (!value) return Promise.resolve();
        if (isBicValid(value)) return Promise.resolve();
        return Promise.reject("Please enter a valid BIC");
    }, []);

    const [countryCodePrefix, refParams] = extractCountryCodeAndRefParams(
        window.location.href
    );

    useEffect(() => {
        form.setFieldsValue(user.paymentDetails || initialValues);
    }, [user.paymentDetails, form]);

    useEffect(() => {
        if (refParams && !isBankPage) {
            setSelectAccount(true);
            completeConnection({ ref: refParams, bankDetailsOnly: true })
                .then((data: any) => {
                    if (data) {
                        dispatch(setBankConnection(data.data.institutionConnection));
                    } else {
                        console.error("Invalid data received from API:", data);
                    }
                    setPageLoading && setPageLoading(false);
                    return data;
                })
                .then(data =>
                    getAllAccountsByInstitution({
                        institution: data.data.institutionConnection.institution,
                    })
                )
                .then((data: any) => {
                    const accounts = data.data.accDetails;
                    setBankAccount(accounts);
                    const sameCountryCurrency = accounts.filter((account: any) => {
                        const region = account.region === "GB" ? "UK" : account.region;
                        return region === countryCodePrefix;
                    });

                    setIsSingleAccount(sameCountryCurrency.length === 0);
                    setPageLoading && setPageLoading(false);
                })
                .catch(error => {
                    console.error("Error fetching connection:", error);
                    handleGoCardlessError();
                    setPageLoading && setPageLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        refParams,
        completeConnection,
        getAllAccountsByInstitution,
        dispatch,
        setPageLoading,
        countryCodePrefix,
    ]);

    function handleGoCardlessError() {
        dispatch(setOpenBankingError(true));
        // handleFinish();
        showPaymentDetails();
    }

    function bankAccountById(_id: string) {
        if (_id === selectedItem) {
            return;
        }

        setSelectedItem(_id);

        getAccountById({ accountId: _id })
            .unwrap()
            .then(data => {
                if (data) {
                    const account = {
                        fullName: data.account.ownerName,
                        address: data.account.address,
                        iban: data.account.iban,
                        bic: data.account.bic,
                        bankAccountNumber: data.account.bankAccountNumber,
                        sortCode: data.account.sortCode,
                    };

                    setUpdatedAccountDetails(account);
                }
            })
            .catch(error => {
                console.error("Error fetching banks:", error);
                handleGoCardlessError();
            });
    }

    async function UpdateBankDetails() {
        try {
            const response = await updateUserPaymentDetails({
                fullName: updatedAccountDetails.fullName!,
                address: updatedAccountDetails.address!,
                iban: updatedAccountDetails.iban,
                bic: updatedAccountDetails.bic,
                bankAccountNumber: updatedAccountDetails.bankAccountNumber,
                sortCode: updatedAccountDetails.sortCode,
            });

            if (response.data.message === "Client Updated Successfully") {
                message.success("Payment details confirmed successfully");
            } else {
                console.error("Unexpected status code:", response.status);
                message.error("Failed to update payment details. Please try again.");
                handleGoCardlessError();
            }
        } catch (error) {
            handleGoCardlessError();
            console.error("Error updating bank details:", error);
        }
    }

    return (
        <div style={isMobile ? { width: "345px", margin: "0 auto" } : {}}>
            <div className="payment-details__content">
                {selectAccount ? (
                    <BankAccount
                        bankAccount={bankAccount}
                        countryCodePrefix={countryCodePrefix}
                        selectedItem={selectedItem}
                        bankAccountById={bankAccountById}
                        isSingleAccount={isSingleAccount}
                        isMobile={isMobile}
                        handleFinish={handleDetailsConfirm}
                        UpdateBankDetails={UpdateBankDetails}
                    />
                ) : (
                    <div className="profile__content-body payment-details__content-body" style={{ marginTop: 0 }}>
                        {isError && <GoCardlessErrorModal />}
                        {/* changes  {title! == null} */}
                        {title !== null && (
                            <Typography
                                className="payment-plans__text"
                                style={{ marginTop: "0px" }}
                            >
                                {title || user.paymentDetails?.iban
                                    ? "Please confirm your bank details"
                                    : "Please enter your bank details"}
                            </Typography>
                        )}
                        <Form
                            layout="vertical"
                            style={{ width: "100%" }}
                            form={form}
                            onFinish={handleFinish}
                            onFieldsChange={(_, allFields) => {
                                const errorFields = allFields.filter(
                                    field => !!field?.errors?.length
                                );
                                setHasErrors(errorFields.length > 0);
                            }}
                        >
                            <div className="payment-details__type-switch-wrapper mt20">
                                <div
                                    className={`payment-details__type-switch left${detailsType === DETAILS_TYPE_DICT["account"] ? " active" : ""}`}
                                    onClick={() => setDetailsType(DETAILS_TYPE_DICT["account"])}>
                                    with Account No
                                </div>
                                <div
                                    className={`payment-details__type-switch right${detailsType === DETAILS_TYPE_DICT["iban"] ? " active" : ""}`}
                                    onClick={() => setDetailsType(DETAILS_TYPE_DICT["iban"])}>
                                    with IBAN
                                </div>
                            </div>
                            <div className="payment-details__form" style={{ justifyContent: "center" }}>
                                <div className="payment-details__form-column">
                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="fullName"
                                        help="Your name should be exactly the same as on your bank statements."
                                        label="Account Holder’s Full Name"
                                        rules={[{ required: true, message: "Please input your full name" }, {
                                            validator: (_, value) => validateName(value),
                                        }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            // disabled={!isEditing}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        hidden={detailsType !== DETAILS_TYPE_DICT["iban"]}
                                        className="payment-details__input-label no-required-mark"
                                        name="iban"
                                        label="IBAN"
                                        rules={[{
                                            required: detailsType === DETAILS_TYPE_DICT["iban"],
                                            message: "Please input your IBAN",
                                        }, {
                                            validator: (_, value) => validateIBAN(value),
                                        }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            // disabled={!isEditing}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        hidden={detailsType !== DETAILS_TYPE_DICT["iban"]}
                                        className="payment-details__input-label no-required-mark"
                                        name="bic"
                                        label="BIC"
                                        rules={[{ required: detailsType === DETAILS_TYPE_DICT["iban"], message: "Please input your BIC" }, {
                                            validator: (_, value) => validateBIC(value),
                                        }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            // disabled={!isEditing}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        hidden={detailsType !== DETAILS_TYPE_DICT["account"]}
                                        className="payment-details__input-label no-required-mark"
                                        name="bankAccountNumber"
                                        label="Account Number"
                                        rules={[{
                                            required: detailsType === DETAILS_TYPE_DICT["account"],
                                            message: "Please input your Account Number",
                                        }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            // disabled={!isEditing}
                                        />
                                        {/* <div>
                                                        <img className="payment-details__input-info-icon" src={infoIcon} alt="info" onClick={handleOpenCardInfo} />
                                                    </div> */}
                                    </Form.Item>
                                    <Form.Item
                                        hidden={detailsType !== DETAILS_TYPE_DICT["account"]}
                                        className="payment-details__input-label no-required-mark"
                                        name="sortCode"
                                        label="Sort Code"
                                        rules={[{
                                            required: detailsType === DETAILS_TYPE_DICT["account"],
                                            message: "Please input your Sort Code",
                                        }]}
                                    >
                                        {/* <div> */}
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            // disabled={!isEditing}
                                        />
                                        {/* <img className="payment-details__input-info-icon" src={infoIcon} alt="info" onClick={handleOpenCardInfo}/> */}
                                        {/* </div> */}
                                    </Form.Item>
                                </div>
                            </div>

                            {!isEditing && (
                                <>
                                    {isMobile ? (
                                        <ProfileButtonFooter>
                                            <Form.Item style={{ margin: "0", width: "345px" }}>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className="payment-details__save-button"
                                                    disabled={hasErrors}
                                                    style={{ width: "100%" }}
                                                >
                                                    {planConfirmed ? "Confirm Bank Details" : "Save Bank Details"}
                                                </Button>
                                                <>
                                                    <Divider className="divider_style"><p className="divider_txt">OR</p>
                                                    </Divider>
                                                    <p className="payment_automatically" onClick={showPaymentDetails}> Add Bank Details Automatically</p></>
                                            </Form.Item>
                                        </ProfileButtonFooter>
                                    ) : (
                                        <div style={{ width: "100%" }}>
                                            <Form.Item style={{ margin: "0 auto", width: "345px" }}>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    data-testid={"bank-details-confirm-button"}
                                                    className="payment-details__save-button"
                                                    disabled={hasErrors}
                                                    style={{ width: "100%" }}
                                                >
                                                    {planConfirmed ? "Confirm Bank Details" : "Save Bank Details"}
                                                </Button>
                                                <Divider className="divider_style"><p className="divider_txt">OR</p>
                                                </Divider>
                                                <p className="payment_automatically" onClick={showPaymentDetails}> Add Bank Details Automatically</p>
                                            </Form.Item>
                                        </div>
                                    )}
                                </>
                            )}
                        </Form>
                    </div>
                )}
            </div>
            <Modal
                open={isCardInfoOpened}
                width={350}
                destroyOnClose
                onCancel={handleCloseCardInfo}
                footer={null}
            >
                <div className={"p10 horizontal-center"}>
                    <div>
                        <div className={"mb20 horizontal-center bold grey-7-color"}>
                            You can find your sort code and account number on your debit card. If not there, check your
                            mobile banking app, bank statements, or cheques.
                        </div>
                        <div className={"horizontal-center"}>
                            <img
                                src={cardInfo}
                                alt="card"
                                width={300}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
