import "styles/categoryFiles.less";
import { message, Upload } from "antd";

import uploadIcon from "../../../../assets/images/uploadIcon.svg";

const FileUploadPassport = ({
    name,
    url,
    setUrl,
    uploadFile,
    setOriginalName,
}: {
    name: string;
    originalName: string;
    label: string;
    uploadFile: (_file: File) => Promise<string>;
    url: string;
    setUrl: (_url: string) => void;
    setOriginalName: (_name: string) => void;
}) => {
    return <div>
        <Upload.Dragger
            name={name}
            multiple={false}
            showUploadList={false}
            disabled={!uploadFile}
            style={{
                backgroundColor: "white",
            }}
            customRequest={async data => {
                const file = data.file as File;
                const result = await uploadFile?.(file);

                if (!result) {
                    message.error("File was not uploaded!");
                }
                setUrl(result);
                setOriginalName(file.name);
                return true;
            }}
        >
            <div
            >
                {!url ? <div>
                    <div className={"drag-component-content"} style={{ width: "100%", height: "40px" }}>
                        <div className="mr4">
                            <img src={uploadIcon} alt="Upload" style={{ marginBottom: -4, marginLeft: 8, height: "19.5px", marginRight: "10px" }} />Upload
                        </div>
                    </div>
                </div> : <div>
                    <div className={"p10 fs14 grey-5-color"}>
                        File has been successfully uploaded.
                    </div>
                </div>}
            </div>
        </Upload.Dragger>
    </div>;
};

export default FileUploadPassport;
