import { FC, useContext } from "react";

import { Button, Modal } from "antd";
import paymentFailedAnimation from "assets/animation/paymentFailed.json";
import taxzapLogo from "assets/images/taxzap-logo-R.svg";
import Lottie from "lottie-react";

import { AppContext } from "../../../../../../App";

type PaymentFailureModalProps = {
    title: string;
    message: string;
    paymentFailed: boolean;
    setPaymentFailed: (_shouldOpen: boolean) => void;
};

export const PaymentFailureModal: FC<PaymentFailureModalProps> = ({
    title,
    message,
    paymentFailed,
    setPaymentFailed,
}) => {
    const { isMobile } = useContext(AppContext);

    return <Modal
        closable={false}
        open={paymentFailed}
        footer={<div>
            <Button className="retry-payment-button" onClick={() => {setPaymentFailed(false);}}>
                Try Again
            </Button>
        </div>}
        className={`payment-modal-container${isMobile ? "-mobile" : "-desktop"}`}
    >
        <div>
            <div>
                <img
                    className = "payment-status-modal-taxzap-logo"
                    src={taxzapLogo}
                    alt="TaxZap Logo"
                />
            </div>
            <div>
                <Lottie
                    animationData={paymentFailedAnimation}
                    loop={false}
                    className={`payment-animation${isMobile ? "-mobile" : "-desktop"}`}
                />
                <div className="text-align-center">
                    <h3 className="payment-modal-h3-text">{title}</h3>
                    <p className="payment-modal-p-text">{message}</p>
                </div>
            </div>
        </div>
    </Modal>;
};
