import { ReactNode, useContext } from "react";

import { AppContext } from "App";
import { ScrollRestoration } from "react-router-dom";

import Footer from "./Footer/Footer";
import HeaderNew from "./Header/HeaderNew";

interface LayoutProps {
    children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    const { isMobile } = useContext(AppContext);
    return (
        <div>
            <ScrollRestoration />
            { !isMobile &&
                <div>
                    <HeaderNew/>
                </div>
            }
            <div style={{ margin: "64px 0 0" }}>
                {children}
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};

export default Layout;
