import { useAppSelector } from "redux/hooks";

export const useLoading = (): {
    loading: boolean;
} => {
    const loading = useAppSelector(state => state.auth.isLoading);

    return {
        loading,
    };
};
