import { useContext } from "react";

import Breadcrumb from "antd/lib/breadcrumb";
import { AppContext } from "App";
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";
import { Link } from "react-router-dom";

interface NavigationProps {
    items: { title: string, url: string }[]
}

interface BreadcrumbProps {
    items: { title: string, url: string }[]
}

interface BackButtonProps {
    title?: string,
    url?: string
}

const BreadcrumbNavigation = ({ items }: BreadcrumbProps) => {
    return <div className='horizontal-center'>
        <Breadcrumb
            style={{ padding: "10px 100px", width: "1500px" }}
            items={items.map(item => {
                return { title: <Link to={item.url}>{item.title}</Link> };
            })}
            separator={<div style={{ display: "flex", alignItems: "center", height: "24px" }}><PiCaretRightBold fontSize={"22px"} /></div>}
        />
    </div>;
};

const BackButtonNavigation = ({ title, url }: BackButtonProps) => {
    return <div className='horizontal-center'>
        <Link to={url ?? "/"} style={{
            display: "flex",
            alignItems: "left",
            padding: "8px 32px",
            width: "100%",
            cursor: "pointer",
            textDecoration: "none",
            color: "black",
            borderBottom: "1px solid #EBEDEF",
            gap: "12px",
        }}>
            <PiCaretLeftBold fontSize={"22px"} />
            <div style={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
            }}>{title}</div>
        </Link>
    </div>;
};

const Navigation = ({ items }: NavigationProps) => {
    const { isMobile } = useContext(AppContext);

    const previousPage = items[items.length - 2];
    const currentPage = items[items.length - 1];

    return <div className={isMobile ? "header-wrapper" : ""}>
        <div style={{ marginTop: isMobile ? "0px" : "120px" }}>
            {isMobile ? <BackButtonNavigation title={currentPage.title} url={previousPage.url}/> : <BreadcrumbNavigation items={items}/>}
        </div>
    </div>;
};

export default Navigation;
