import "../../../../../styles/profile/dashboard.less";

import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { AppContext } from "App";
import clientSubmission from "assets/images/dashboard/client_submission.svg";
import calendar from "assets/images/new-questionnaire/calendar.svg";
import { useDispatch } from "react-redux";
import { useGetOrCreateClientDocumentMutation, useGetTaxCalculationsMutation } from "services/client";
import { IUser } from "types/auth";
import { logError } from "utils/sentry";

import ClientProgress from "./ClientProgress";
import ReadyForReviewOweTax from "./ReadyForReviewOweTax";
import ReadyForReviewRefund from "./ReadyForReviewRefund";
import SubmittedToHmrc from "./SubmittedToHmrc";
import { getTaxYears } from "../../../../../constants/taxYears";
import { useAppSelector } from "../../../../../redux/hooks";
import { setCurrentDocument, setCurrentDocumentYear } from "../../../../../redux/reducers/document";
import { RootState } from "../../../../../redux/store";
import { ClientProgressType } from "../../../NewQuestionnaire/enums/enum";

type HomepageProps = {
    user: IUser;
    setSelectedTab: (_tab: string) => void;
    setPageLoading: Dispatch<SetStateAction<boolean>>;
};

export default function Homepage({ user }: HomepageProps) {
    const dispatch = useDispatch();
    const { isMobile } = useContext(AppContext);
    const [getOrCreateClientDocument] = useGetOrCreateClientDocumentMutation();
    const [clientProgress, setClientProgress] = useState("");
    const { currentDocument, currentDocumentYear, taxYears } = useAppSelector((state: RootState) => ({
        currentDocument: state.document.currentDocument,
        currentDocumentYear: state.document.currentDocumentYear,
        taxYears: state.taxYears.taxYears,
    }));
    const [taxData, setTaxData] = useState<any | null>(null);
    const [getGetTaxCalculations] = useGetTaxCalculationsMutation();
    useEffect(() => {
        const fetchTaxCalculations = async () => {
            if (!user?.id || !currentDocument) {
                logError("Error: User ID or current document is missing. This might indicate an issue with user authentication or document loading.");
                return;
            }
            try {
                const response = await getGetTaxCalculations({
                    clientId: user.id,
                    documentId: currentDocument.id,
                }).unwrap();
                const breakdown = response.breakdown;
                setTaxData(breakdown);
            } catch (error) {
                const errorContext = {
                    clientId: user?.id,
                    message: "Failed to fetch tax calculations",
                    function: "fetchTaxCalculations",
                };
                logError(error, errorContext);
            }
        };

        fetchTaxCalculations();
    }, [getGetTaxCalculations, user, currentDocument]);

    useEffect(() => {
        if (currentDocumentYear) {
            const data = { clientId: user?.id || "", year: currentDocumentYear };
            getOrCreateClientDocument(data)
                .unwrap()
                .then(response => {
                    if (response) {
                        dispatch(setCurrentDocument(response.document));
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDocumentYear]);

    useEffect(() => {
        if (currentDocument) {
            setClientProgress(currentDocument.clientProgress || "");
        }
    }, [currentDocument]);

    const handleMenuClick = ({ key }: { key: string }) => {
        dispatch(setCurrentDocumentYear(key));
    };

    const items: MenuProps["items"] = getTaxYears(taxYears).map(year => ({
        key: year,
        label: `${year} Tax Year`,
    }));

    const renderReadyForReview = () => {
        const taxLiability = taxData?.totalTaxLiability?.value;

        if (taxLiability < 0) {
            return <ReadyForReviewRefund currentDocumentYear={currentDocumentYear} taxLiability={taxLiability} />;
        }

        if (taxLiability >= 0) {
            return <ReadyForReviewOweTax currentDocumentYear={currentDocumentYear} taxLiability={taxLiability} />;
        }
        return <ClientProgress user={user} clientProgress={clientProgress}/>;
    };
    const renderClientProgress = () => {
        if (clientProgress === ClientProgressType.SubmittedToHMRC) {
            return <SubmittedToHmrc />;
        }

        if (clientProgress === ClientProgressType.ReadyForReview) {
            return renderReadyForReview();
        }

        return <ClientProgress user={user} clientProgress={clientProgress} />;
    };

    return (
        <>
            <div>
                <div className="dashboard__wrapper-calendar">
                    <Dropdown menu={{ items, onClick: handleMenuClick, selectedKeys: [currentDocumentYear] }}>
                        <Button className="horizontal-center" size="large" onClick={e => e.preventDefault()}>
                            <img src={calendar} alt="calendar" />
                            <Space className="client-navbar__calendar-title">
                                {currentDocumentYear}
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                    <div className="dashboard__tax-title"> Tax Return</div></div>
                {clientProgress === ClientProgressType.ReadyForReview && (
                    <div className="dashboard__wrapper-outer">
                        <img src={clientSubmission} alt="client submission"/>
                        <div className="dashboard__title">Congrats! Your {currentDocumentYear} Tax Return is Submitted</div>
                        <div className="dashboard__subtitle">Your tax return has been successfully submitted. You’ll receive a confirmation email shortly. </div></div>)}
                <div className="dashboard__layout">
                    <div className="dashboard__wrapper">
                        <div className={isMobile && clientProgress === "submittedToHMRC" ? "dashboard__title-hmrc" : "dashboard__title"}>
                            {clientProgress === "submittedToHMRC" ? "Tax Return is Completed!" : clientProgress === "readyForReview" ? "" : "Tax Return"}
                        </div>
                    </div>
                    <div className="w100">
                        {renderClientProgress()}
                    </div>

                </div>
            </div></>
    );
}
