import "../../../../../styles/questionnaire/documentParsePrompt.less";
import { FC, useContext, useState } from "react";

import { Drawer } from "antd";
import { AppContext } from "App";
import close from "assets/images/new-questionnaire/close.svg";
import parseIcon from "assets/images/new-questionnaire/documentParse/parse_image.svg";
import parseIcondesktop from "assets/images/new-questionnaire/documentParse/parse_image_desktop.svg";
import QuestionnaireButtonFooterV2 from "components/basic/Footer/QuestionnaireButtonFooterV2";
import { IFile } from "types/files";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

import { Receipt } from "./DocumentParseQuestion";
import Parser from "./Parser";
import UploadDesktop from "./UploadDesktopView";
import PromptModal from "../Shared/PromptModal";

export interface DocumentParsePromptScreenProps {
    openDrawer: () => void;
    closeDrawer: () => void;
    isDrawerOpen: boolean;
    uploadQFile: (_file: IFile) => Promise<boolean>;
    uploadedCategoryReceipts: Receipt[];
    handleContinue?: () => void;
    getFileIcon: (_fileName: string) => string;
    toggleUpload: () => void;
    isUploadOpen: boolean;
    uploadErrorMessage: string;
    setUploadErrorMessage: (_message: string) => void;
}

const DocumentParsePromptScreen: FC<DocumentParsePromptScreenProps> = ({
    openDrawer,
    closeDrawer,
    isDrawerOpen,
    uploadQFile,
    uploadedCategoryReceipts,
    handleContinue,
    getFileIcon,
    toggleUpload,
    isUploadOpen,
    uploadErrorMessage,
    setUploadErrorMessage,
}) => {
    const { isMobile } = useContext(AppContext);
    const isKeyboardOpen = useDetectKeyboardOpen();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleIconClick = () => {
        setIsModalOpen(true);
    };
    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            {isMobile && <Drawer className="document-parse-prompt-drawer" placement={"bottom"} height={"100%"} onClose={closeDrawer} open={isDrawerOpen} style={{
                borderRadius: "36px 36px 0 0",
                overflow: "hidden",
            }}closeIcon={<div className="document-parse-prompt-close"><img src={close} alt="close" className="dblock"/></div>}>
                <Parser uploadQFile={uploadQFile} uploadedCategoryReceipts={uploadedCategoryReceipts} getFileIcon={getFileIcon} closeDrawer={closeDrawer} uploadErrorMessage={uploadErrorMessage} />
            </Drawer>}
            <UploadDesktop
                toggleUpload={toggleUpload}
                isUploadOpen={isUploadOpen}
                uploadQFile={uploadQFile}
                uploadedCategoryReceipts={uploadedCategoryReceipts}
                getFileIcon={getFileIcon}
                uploadErrorMessage={uploadErrorMessage}
                setUploadErrorMessage={setUploadErrorMessage}
            />
            {<div className="mWidth500">
                <img className="document-parse-prompt-container" src={isMobile ? parseIcon : parseIcondesktop} alt="parse icon"/>
                <div className={isMobile ? "fs20 fw600" : "fs20 fw600 taCenter"}>
                Let’s get your income details.
                </div>
                <div className="fs16 fw400 mb10 mt10">
                You can either upload your CIS deduction statements or enter your income details manually-whichever works best for you!
                </div>
                <div>
                    <div className="document-parse-prompt-link" onClick={handleIconClick}>
                    Learn More
                    </div>
                    <PromptModal
                        isOpen={isModalOpen}
                        closeModal={handleModalClose}
                        data={{
                            title: "",
                            text: "<div style='font-weight: 600; color: #000; font-size: 18px; text-align: center; margin-bottom: 10px; margin-top: 10px'>Total CIS Income Details</div>" +
                                "<div style='text-align: left;color: #31333F;'>Your total CIS income is the gross amount you received from contractors before any deductions, as shown on your CIS deduction statements. Ensure it’s accurate and matches HMRC records to prevent tax issues.</div>" +
                                "<div style='font-weight: 600; color: #000; font-size: 18px; text-align: center; margin-bottom: 10px; margin-top: 10px'>How to Enter Your CIS Income</div>" +
                                "<div style='text-align: left;color: #31333F;'><strong>(1) Upload CIS Deduction Statements </strong> – Submit your weekly, monthly, or yearly statements from each contractor.\n\n</div>" +
                                "<div style='text-align: left;color: #31333F;'><strong>(2) Enter Manually</strong> – If you don’t have statements, add details carefully to match contractor records.\n\n</div>" +
                                "<div style='text-align: left;color: #31333F;'>If a deduction statement is missing, contact the contractor directly. You can also manually adjust the amount if needed to ensure accuracy.\n\n</div>",
                        }}
                        handlePrev={() => {
                        }}
                    />
                </div>
                {
                    isMobile ? (
                        <div className="new-questionnaire__question" style={{ maxWidth: "335px", margin: "26px auto 0" }}>
                            <div className={`${isKeyboardOpen ? "" : "mt120"}`}>
                                <QuestionnaireButtonFooterV2>
                                    <button className="document-parse-prompt-continue" onClick={openDrawer}>
                                Upload Deduction Statements
                                    </button>
                                    <button className="document-parse-prompt-continue-grey" onClick={handleContinue}>
                                Add Income Manually
                                    </button>
                                </QuestionnaireButtonFooterV2> </div>
                        </div>
                    ) : (
                        <div className="document-parse-prompt-container mt50">
                            <button
                                className="document-parse-prompt-continue-desktop" onClick={toggleUpload}>
                                Upload Deduction Statements
                            </button>
                            <button
                                className="document-parse-prompt-continue-grey-desktop" onClick={handleContinue}>
                                Add Income Manually
                            </button>
                        </div>
                    )
                }
            </div>}
        </div>
    );
};

export default DocumentParsePromptScreen;
