import { FC, useContext, useState } from "react";

import { AppContext } from "App";
import { IoIosCheckmarkCircle } from "react-icons/io";

import CheckboxComponent from "./CheckboxComponent";
import { DiscountBox } from "./DiscountBox";

type PriceBreakdownProps = {
    setAmount: (_amount: number) => void;
    amount: number
    styles?: {
        isFlex?: boolean,
        width?: string;
        hideBorder?: boolean;
    };
};

export const PriceBreakDown: FC<PriceBreakdownProps> = ({ amount, setAmount, styles }) => {
    const { isMobile } = useContext(AppContext);
    const [oldAmount, setOldAmount] = useState<number | null>(null);
    const { width } = styles || {};

    const onPayNowClick = () => {
        window.scrollTo({
            top: document.body.scrollHeight, // Scroll to the height of the body
            behavior: "smooth", // Smooth scrolling
        });
    };

    return (
        <div className={`price-breakdown-outer-div${isMobile ? "-mobile" : "-desktop"} common-font-style`}>
            <div className="why-you-need-to-pay-container" style={{ width }}>
                <div className="price-breakdown-title" >
                    <span>
                        Complete Your{" "}
                    </span>
                    <span>
                        Tax Return
                    </span>
                    {isMobile ? <div className="price-breakdown-subtitle">
                        <span>
                            Online self-assessment{" "}
                        </span>
                        <span>
                            submission direct to HMRC.
                        </span>
                    </div>
                        : <div className="price-breakdown-subtitle">
                            Please review and proceed to the next step.
                        </div>
                    }
                </div>
                {isMobile && <div className="price-container">
                    <div className="price-breakdown__box">
                        <IoIosCheckmarkCircle className="price-breakdown__box__checkmark"/>
                        <div className="price-breakdown__title">
                            One-time submission fee
                        </div>
                        <span className={`${oldAmount ? "price-breakdown__discount-amount" : "price-breakdown__non-discount-amount"}`}>£{amount.toFixed(2)}</span>
                        <DiscountBox setAmount={setAmount} setOldAmount={setOldAmount} amount={amount}/>
                    </div>
                </div>}
                <div className="price-breakdown__list-container">
                    <div className="price-breakdown__list">
                        <CheckboxComponent message="Full tax calculations & review prior to submission."/>
                        <CheckboxComponent
                            message="HMRC recognised."/>
                        <CheckboxComponent message="Submit instantly."/>
                        <CheckboxComponent message="The TaxZap fee will be an expense on next years return."/>
                        <CheckboxComponent message="Avoid fees and stay compliant."/>
                    </div>
                </div>
            </div>
            {!isMobile && <div className="price-container" style={{ width }}>
                {oldAmount != null &&
                    <div className="old-price-breakdown-outer-div">
                        <div className={"old-price-breakdown"}>
                            <span>£{oldAmount}</span>
                        </div>
                    </div>
                }
                <div className="price-breakdown__box">
                    <IoIosCheckmarkCircle className="price-breakdown__box__checkmark"/>
                    <div className="price-breakdown__title">
                    Total due now
                    </div>
                    <span className={`${oldAmount ? "price-breakdown__discount-amount" : "price-breakdown__non-discount-amount"}`}>£{amount.toFixed(2)}</span>
                    <div className="price-breakdown__breakdown">
                        <span className="price-breakdown__breakdown__section-title">Self Assessment</span>
                        <span>£{amount.toFixed(2)}</span>
                    </div>
                    <div className="price-breakdown__separator" />
                    <DiscountBox setAmount={setAmount} setOldAmount={setOldAmount} amount={amount}/>
                    <div className="w100 mt12">
                        <button className={`payment-button${isMobile ? "-mobile" : "-desktop"} bg-secondary w100`} onClick={onPayNowClick}>
                        Pay Now
                        </button>
                    </div>
                </div>
            </div>}
        </div>);
};
