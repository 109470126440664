import "styles/profile/progressBar.less";
import "styles/profile/return-status.less";

import React, { useContext } from "react";

import { AppContext } from "App";
import bankDetailsDone from "assets/images/profile/payment/bank_details_done.svg";
import bankDetailsGrey from "assets/images/profile/payment/bank_details_grey.svg";
import bankDetailsNext from "assets/images/profile/payment/bank_details_next.svg";
import idGrey from "assets/images/profile/payment/id_grey.svg";
import idNext from "assets/images/profile/payment/id_next.svg";
import paymentDone from "assets/images/profile/payment/payment_done.svg";
import paymentNext from "assets/images/profile/payment/payment_next.svg";
import successGrey from "assets/images/profile/payment/success_grey.svg";
import { IUser } from "types/auth";

export const ProgressBar: React.FC<{
    confirmationProgress: string;
    skippedDetails: boolean;
    planConfirmed: boolean;
    user: IUser;
}> = ({
    confirmationProgress,
    // skippedDetails,
    // planConfirmed,
}) => {
    const { isMobile } = useContext(AppContext);
    return (
        <>
            <div className="progress-bar__container">
                {confirmationProgress === "Payment Plan" && (<>
                    {!isMobile && (<div className="progress-bar__title">Select Payment Method</div>)}
                    <div className="return-status">
                        <div className="return-status__details">
                            <img
                                className="return-status__details-icon"
                                width={60}
                                src={paymentNext}
                                alt="application sent icon"
                            />
                            {/* {!isMobile && ( */}
                            <div className="return-status__title-active">
                                Payment
                            </div>
                            {/* )} */}
                        </div>
                        <div className="return-status__separator"></div>
                        <div className="return-status__details">
                            <img
                                className="return-status__details-icon"
                                width={60}
                                src={bankDetailsGrey}
                                alt="processing your return icon"
                            />
                            {/* {!isMobile && ( */}
                            <div className="return-status__title">
                                    Bank Details
                            </div>
                            {/* )} */}
                        </div>
                        <div className="return-status__separator"></div>
                        <div className="return-status__details">
                            <img
                                className="return-status__details-icon"
                                width={60}
                                src={idGrey}
                                alt="Submitted to HMRC icon"
                            />
                            {/* {!isMobile && ( */}
                            <div className="return-status__title">
                                    Id Verification
                            </div>
                            {/* )} */}
                        </div>
                        <div className="return-status__separator"></div>
                        <div className="return-status__details">
                            <img
                                className="return-status__details-icon"
                                width={60}
                                src={successGrey}
                                alt="return outcome icon"
                            />
                            {/* {!isMobile && ( */}
                            <div className="return-status__title">
                                Success
                            </div>
                            {/* )} */}
                        </div>
                    </div>
                </>)}
                {confirmationProgress === "Bank Details" && (<>
                    {/* {!isMobile && (<div className="progress-bar__title">Add Bank Details</div>)} */}
                    <div className="return-status">
                        <div className="return-status__details">
                            <img
                                className="return-status__details-icon"
                                width={60}
                                src={paymentDone}
                                alt="application sent icon"
                            />
                            {!isMobile && (
                                <div className="return-status__title-active">
                                        Payment
                                </div>
                            )}
                        </div>
                        <div className="return-status__separator-active"></div>
                        <div className="return-status__details">
                            <img
                                className="return-status__details-icon"
                                width={60}
                                src={bankDetailsNext}
                                alt="processing your return icon"
                            />
                            {/* {!isMobile && ( */}
                            <div className="return-status__title-active">
                                    Bank Details
                            </div>
                            {/* )} */}
                        </div>
                        <div className="return-status__separator"></div>
                        <div className="return-status__details">
                            <img
                                className="return-status__details-icon"
                                width={60}
                                src={idGrey}
                                alt="Submitted to HMRC icon"
                            />
                            {/* {!isMobile && ( */}
                            <div className="return-status__title">
                                    Id Verification
                            </div>
                            {/* )} */}
                        </div>
                        <div className="return-status__separator"></div>
                        <div className="return-status__details">
                            <img
                                className="return-status__details-icon"
                                width={60}
                                src={successGrey}
                                alt="return outcome icon"
                            />
                            {/* {!isMobile && ( */}
                            <div className="return-status__title">
                                Success
                            </div>
                            {/* )} */}
                        </div>
                    </div>
                </>)}
                {confirmationProgress === "ID Verification" && (<>
                    {!isMobile && (<div className="progress-bar__title">Complete ID Verification</div>)}
                    <div className="return-status">
                        <div className="return-status__details">
                            <img
                                className="return-status__details-icon"
                                width={60}
                                src={paymentDone}
                                alt="application sent icon"
                            />
                            {!isMobile && (
                                <div className="return-status__title-active">
                                        Payment
                                </div>
                            )}
                        </div>
                        <div className="return-status__separator-active"></div>
                        <div className="return-status__details">
                            <img
                                className="return-status__details-icon"
                                width={60}
                                src={bankDetailsDone}
                                alt="processing your return icon"
                            />
                            {!isMobile && (
                                <div className="return-status__title-active">
                                    Bank Details
                                </div>
                            )}
                        </div>
                        <div className="return-status__separator-active"></div>
                        <div className="return-status__details">
                            <img
                                className="return-status__details-icon"
                                width={60}
                                src={idNext}
                                alt="Submitted to HMRC icon"
                            />
                            {/* {!isMobile && ( */}
                            <div className="return-status__title-active">
                                    Id Verification
                            </div>
                            {/* )} */}
                        </div>
                        <div className="return-status__separator"></div>
                        <div className="return-status__details">
                            <img
                                className="return-status__details-icon"
                                width={60}
                                src={successGrey}
                                alt="return outcome icon"
                            />
                            {/* {!isMobile && ( */}
                            <div className="return-status__title">
                                Success
                            </div>
                            {/* )} */}
                        </div>
                    </div>
                </>)}
            </div>
        </>
    );
};
