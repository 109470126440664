import { FC, ChangeEvent, KeyboardEvent, useCallback, useEffect, useState, useMemo, useContext } from "react";

import { Input } from "antd";
import { AppContext } from "App";
import errorIcon from "assets/images/new-questionnaire/exclamationmark-circle.svg";
import infoIcon from "assets/images/new-questionnaire/info_icon.svg";
import { TaxProfile } from "types/client";
import { isAnswerValid } from "utils/new-questionnaire";

import { Question } from "../../constants/questions";

type TextQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: string | undefined, _id: string) => void;
    initialValue?: any;
    arrayItemsNames?: string[];
    taxProfile?: TaxProfile;
};

const { TextArea } = Input;

const TextQuestion: FC<TextQuestionProps> = ({ data, onAnswer, initialValue, arrayItemsNames, taxProfile }) => {
    const isMobile = useContext(AppContext);
    const [inputValue, setInputValue] = useState<string>(initialValue || "");
    const [error, setError] = useState<string | null>(null);
    const helpText = useMemo(() => data.infoText, [data]);
    // Memoizing the minRows and maxRows values with default fallback
    const { minRows, maxRows } = useMemo(() => ({
        minRows: data.minRows ?? 1, // default to 1 if undefined
        maxRows: data.maxRows ?? 6, // default to 6 if undefined
    }), [data]);

    const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setInputValue(value);

        if (data.isItemName && arrayItemsNames && value) {
            if (data.isItemName && arrayItemsNames && value) {
                const isDuplicate = arrayItemsNames
                    .filter(name => name !== taxProfile?.name)
                    .some(
                        name => name.trim().toLowerCase() === value.trim().toLowerCase()
                    );

                if (isDuplicate) {
                    setError("This name is already in use, please use another");
                    onAnswer(data.question, value, data.id);
                    return;
                }
            }
        }

        if (data.validation) {
            const isValid = isAnswerValid(value, data.validation.regExp);
            if (!isValid) {
                setError(data.validation.error);
                onAnswer(data.question, undefined, data.id);
            } else {
                setError(null);
                onAnswer(data.question, value, data.id);
            }
        } else {
            setError(null);
            onAnswer(data.question, value, data.id);
        }
    }, [data.isItemName, data.validation, data.question, data.id, arrayItemsNames, taxProfile?.name, onAnswer]);

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.key === "Enter") {
            if (isMobile) {
                e.currentTarget.blur();
            } else {
                e.preventDefault();
            }
        }
    };

    useEffect(() => {
        setInputValue(initialValue || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    return (
        <div className="new-questionnaire__question">
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                {data.question}
            </div>
            <div
                className="new-questionnaire__question-input-wrapper"
                style={{ borderBottom: "4px solid #7F43FF" }}
            >
                {data.inputType === "number" ? (
                    <>
                        {data.unit && <span style={{ width: "135px", height: "1px" }}>{}</span>}
                        <Input
                            className="new-questionnaire__text-question-input"
                            onChange={handleInputChange}
                            value={inputValue}
                            type="number"
                            inputMode="decimal"
                            onKeyDown={handleKeyDown}
                        />
                        {data.unit && <span className="new-questionnaire__text-question-unit">{data.unit}</span>}
                    </>
                ) : (
                    <TextArea
                        className="new-questionnaire__text-question-input"
                        onChange={handleInputChange}
                        value={inputValue}
                        autoSize={{ minRows, maxRows }}
                        onKeyDown={handleKeyDown}
                    />
                )}
            </div>
            {helpText && (
                <div className="new-questionnaire__info-message">
                    <img src={infoIcon} width={20} alt="info" />
                    <div className="new-questionnaire__info-message-text ml4">{helpText}</div>
                </div>
            )}
            {error && (
                <div className="new-questionnaire__error-message">
                    <img src={errorIcon} width={20} alt="error" />
                    <div className="new-questionnaire__error-message-text ml4">{error}</div>
                </div>
            )}
        </div>
    );
};

export default TextQuestion;
