export function extractCountryCodeAndRefParams(url: string): [string, string | null] {
    const currentUrl = new URL(url);
    const match = /\/([^/]+)\/[^/]+$/.exec(currentUrl.pathname);
    const countryCodePrefix = match ? match[1].toUpperCase() : "";

    const searchParams = new URLSearchParams(currentUrl.search);
    const refParams = searchParams.get("ref");

    return [countryCodePrefix, refParams];
}
