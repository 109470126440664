import TagManager from "react-gtm-module";

const sendGTMEvent = (dataLayer: object) => {
    if (process.env.REACT_APP_GOOGLE_GTM_TAG) {
        TagManager.dataLayer({
            dataLayer,
        });
    }
};

export default sendGTMEvent;
