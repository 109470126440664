import "styles/smallStatus.less";

import { FC } from "react";

import { IStatus } from "types/client";

interface DocumentStatusProps {
    status: IStatus;
    dot?: boolean;
}

const DocumentStatus: FC<DocumentStatusProps> = ({ status, dot = false }) => {
    const documentStatus = status.document || "pending";

    if (dot) {
        return <div className={`small-status__big-dot small-status__dot-${documentStatus}`} />;
    }

    return (
        <div className={`small-status small-status__${documentStatus}`}>
            <div className={`small-status__dot small-status__dot-${documentStatus}`} />
            <div className="small-status__text">
                {documentStatus}
            </div>
        </div>
    );
};

export default DocumentStatus;
