import "styles/questionnaire/newQuestionnaire.less";
import "styles/questionnaire/client.less";

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { message as antdMessage } from "antd";
import { AppContext } from "App";
import LottieAnimation from "components/basic/Spinner/Lottie";
import { useUser } from "hooks/common/useUser";
import useDebounce from "hooks/helpers/useDebounce";
import { useLocalStorage } from "hooks/helpers/useLocalStorage";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { selectAnswers, setAnswers } from "redux/reducers/answers";
import { selectArrayQuestionState } from "redux/reducers/arrayQuestion";
import { markCategoryInProgress, next, selectCurrentNavigation, selectLastNavigation, selectNavigationItems, setCompletedCategories, setInProgressCategories, setNavigationItems, setStepLoading } from "redux/reducers/navigation";
import { selectTaxProfile, setAllTaxProfiles, setCurrentProfile, setCurrentProfileAnswers, setIncompleteProfiles, setIsEditingProfile } from "redux/reducers/taxProfile";
import store from "redux/store";
import {
    useGetOrCreateClientDocumentMutation,
    useGetAllClientTaxProfilesQuery,
    useUpdateClientDocumentMutation,
} from "services/client";
import { useCompleteConnectionMutation, useGetPlaidLinkTokenMutation } from "services/connectBankAccount";
import {
    useFetchConnectedBankAccountsQuery,
} from "services/paymentBank";
import { findAndSetArrayPrev, findAndSetInnerArrayPrev, findAndSetNextStep, findAndSetPrevStep, findAndSetProfilePrev } from "utils/navigation";
import { calculateTotalProgress, checkDepends, createAnswersOBArray, findAllOBFiles, formatAnswers, getAdditionalIncomeSources, getEmploymentTypes, getNavigationItems, inferIfCompanyDirector } from "utils/new-questionnaire";
import { logError, logMessage } from "utils/sentry";
import { extractCountryCodeAndRefParams } from "utils/urlUtils";

import DesktopForm from "./components/DesktopForm";
import MobileForm from "./components/MobileForm";
import { NinUtrQuestionType } from "./components/NinUtrQuestion/NinUtrQuestion";
import { SharingPreferenceIdType } from "./components/OnBoarding/OnBoarding";
import ConnectGCModal from "./components/OpenBanking/ConnectGCModal";
import ConnectPlaidModal from "./components/OpenBanking/ConnectPlaidModal";
import { categories, Question, Step } from "./constants/questions";
import { ParentProfileType, CategoryTitle, StepType, QuestionType } from "./enums/enum";
import { selectDocument, setCurrentDocument, setSelectedSharingPreference } from "../../../redux/reducers/document";

const Questionnaire: React.FC = () => {
    const dispatch = useDispatch();
    const navigationItems = useAppSelector(selectNavigationItems);
    const { currentCategory, currentStep, currentQuestion, currentProfileQuestion, inProgressCategories, completedCategories } = useAppSelector(selectCurrentNavigation);
    const { lastCategory, lastStep } = useAppSelector(selectLastNavigation);
    const { isEditingItem, isEditingInnerArrayItem } = useAppSelector(selectArrayQuestionState);
    const { answers } = useAppSelector(selectAnswers);
    const { isEditingProfile } = useAppSelector(selectTaxProfile);
    const { currentDocument, currentDocumentYear, selectedSharingPreference } = useAppSelector(selectDocument);

    const { isMobile } = useContext(AppContext);
    const { user } = useUser();
    const { isPlaidEnabled } = useFlags();

    const [updateClientDocument, { isLoading: isClientDocumentSaving }] = useUpdateClientDocumentMutation();
    const [getOrCreateClientDocument, { isLoading: isClientDocumentGetting }] = useGetOrCreateClientDocumentMutation();
    const [currentDocumentIdForAnswers, setCurrentDocumentIdForAnswers] = useState("");
    const [switchingDocument, setSwitchingDocument] = useState(false);
    const { data: connectedBankAccountsData, refetch: refetchConnectedBankAccounts } = useFetchConnectedBankAccountsQuery();
    const [getLinkToken] = useGetPlaidLinkTokenMutation();
    const [completeConnection] = useCompleteConnectionMutation();

    const [pageLoading, setPageLoading] = useState(true);
    const [isAutosaving, setIsAutosaving] = useState(false);
    const [bankConnecting, setBankConnecting] = useState(false);
    const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [playPigAnimationTrigger, setPlayPigAnimationTrigger] = useState("");
    const [autoSaveTrigger, setAutoSaveTrigger] = useState<Date | null>(null);
    const [spinnerCompleted, setSpinnerCompleted] = useState(false);
    const [linkToken, setLinkToken] = useState<string|undefined>(undefined);

    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const [totalProgress, setTotalProgress] = useLocalStorage<number>("new_questionnaire_total_progress", 0);

    const debouncedProgress = useDebounce(totalProgress, 100);

    const debouncedAutoSaveTrigger = useDebounce(autoSaveTrigger, 10000, currentDocumentYear); //Autosave when set answers with 10 seconds debounce
    const [initialBankCount, setInitialBankCount] = useLocalStorage<number>("number_bank_accounts", 0);

    const currentCategoryData = useMemo(() => {
        return categories[currentCategory] || {};
    }, [currentCategory]);

    const currentStepData = useMemo(() => {
        return currentCategoryData?.steps?.[currentStep] || {};
    }, [currentCategoryData, currentStep]);

    const currentQuestionData = useMemo(() => {
        return currentStepData?.questions?.[currentQuestion];
    }, [currentQuestion, currentStepData]);

    const allCardsAnswered = useMemo(() => {
        if (!currentStepData?.hasCards) return true;
        const cardQuestions = currentStepData.cards?.map(card => card.question) || [];
        return cardQuestions.every(question => {
            const card = currentStepData.cards?.find(card => card.question === question);
            if (!card) return false;
            const dependsMet = !card.depend || checkDepends(card.depend, answers[currentCategoryData.title]);
            if (!dependsMet) return true;
            return answers[currentCategoryData.title]?.[question];
        });
    }, [currentStepData, answers, currentCategoryData.title]);

    const { allProfilesCompleted, incompleteProfiles } = useMemo(() => {
        if (currentQuestionData?.type !== QuestionType.profileQuestion) {
            return { allProfilesCompleted: true, incompleteProfiles: [] };
        }

        const profilesAnswers = answers[currentCategoryData.title]?.[currentQuestionData.question] || [];
        const incompleteProfiles: { itemId: string; missingQuestions: string[] }[] = [];

        // ✅ Iterate through each profile and collect missing questions
        const profileCompletionStatus = profilesAnswers.map((profile: any, index: number) => {
            const formattedProfileAnswers = formatAnswers(profile.answers);
            const missingQuestions: string[] = [];

            currentQuestionData.questions?.forEach(profileQuestion => {
                if (profileQuestion.type === QuestionType.cardArrayQuestion) {
                    profileQuestion.cards?.forEach(card => {
                        const isRequired = card.answerRequired && (!card.depend?.length || checkDepends(card.depend, formattedProfileAnswers));
                        if (isRequired && !formattedProfileAnswers[card.question]) {
                            missingQuestions.push(card.id);
                        }
                    });
                } else if (profileQuestion.type === QuestionType.arrayQuestion) {
                    const answers = formattedProfileAnswers[profileQuestion.question];

                    if (answers?.some((answer: any) => !answer?.isCompleted)) {
                        missingQuestions.push(profileQuestion.id);
                    }
                } else {
                    const isRequired =
                        profileQuestion.answerRequired &&
                        (!profileQuestion.depend?.length || checkDepends(profileQuestion.depend, formattedProfileAnswers));

                    if (isRequired && formattedProfileAnswers[profileQuestion.question] === undefined) {
                        missingQuestions.push(profileQuestion.id);
                    }
                }
            });

            if (missingQuestions.length > 0) {
                incompleteProfiles.push({ itemId: profile.itemId, missingQuestions });
                console.warn(`🚨 Profile ${index + 1} (ID: ${profile.itemId}) is missing answers for:`, missingQuestions);
                return false; // Mark this profile as incomplete
            }

            return true; // Mark this profile as complete
        });

        // ✅ All profiles are completed if no profile is marked as `false`
        const allProfilesCompleted = profileCompletionStatus.every((status: any) => status);

        return { allProfilesCompleted, incompleteProfiles };
    }, [currentQuestionData, answers, currentCategoryData.title]);

    const { data: taxProfilesResponse, refetch: refetchTaxProfiles, status: taxProfilesStatus } = useGetAllClientTaxProfilesQuery(
        {
            client: user?.id!,
            parentType: currentStepData.parentProfileType! as ParentProfileType,
        },
        {
            skip: !user?.id || !currentStepData.parentProfileType,
        }
    );

    const connectedBankAccounts = useMemo(() => {
        return connectedBankAccountsData?.accDetails || [];
    }, [connectedBankAccountsData]);

    const refParams = useMemo(() => {
        const [, refParams] = extractCountryCodeAndRefParams(window.location.href);
        return refParams;
    }, []);

    const handlePlayPigAnimationTrigger = useCallback(() => {
        setPlayPigAnimationTrigger(new Date().toString());
    }, []);

    const handleAnswerChange = useCallback((question: string, answer: any) => {
        setAutoSaveTrigger(new Date());
        const newAnswers = {
            ...answers,
            [currentCategoryData.title]: {
                ...answers[currentCategoryData.title],
                [question]: answer,
            },
        };

        if (currentStepData?.hasCards) {
            const categorySteps = categories.find(category => category.title === currentCategoryData.title)?.steps;

            categorySteps?.forEach((step: Step) => {
                // If any other category steps depend on the card swipe answer and dependency is not met, clear the answers
                if (step.depend && step.depend.some((depend: any) => depend.question === question) &&
                    !checkDepends(step.depend, newAnswers[currentCategoryData.title])) {
                    step.questions?.forEach((question: Question) => {
                        if (newAnswers[currentCategoryData.title]?.[question.question]) {
                            newAnswers[currentCategoryData.title][question.question] = undefined;
                        }
                    });
                }

                // If any other category step questions depend on the question and dependency is not met, clear the answers
                step.questions?.forEach((question: Question) => {
                    if (question.depend && question.depend.some((depend: any) => depend.question === question) &&
                        !checkDepends(question.depend, newAnswers[currentCategoryData.title])) {
                        if (newAnswers[currentCategoryData.title]?.[question.question]) {
                            newAnswers[currentCategoryData.title][question.question] = undefined;
                        }
                    }
                });
            });
        }

        if (currentStepData?.questions?.length) {
            const allAnswered = currentStepData.questions?.every(q => {
                if (q.answerRequired === false) return true;
                if (q.disallowedItems) {
                    const answers = newAnswers[currentCategoryData.title]?.[q.disallowedItems.question];
                    const disallowedAnswers = [q.disallowedItems.answer];
                    return !answers?.every((answer: string) => disallowedAnswers.includes(answer));
                }
                if (q.type === QuestionType.questionsList) {
                    const allAnswered = q.questions?.every(questionsListQuestion => {
                        if (questionsListQuestion.answerRequired) {
                            const answer = newAnswers[currentCategoryData.title]?.[questionsListQuestion.question];
                            return !!answer;
                        }
                        return true;
                    });
                    return !!allAnswered;
                }
                if (q.type === QuestionType.ninUtrQuestion) {
                    const nin = newAnswers[currentCategoryData.title]?.[NinUtrQuestionType.NIN];
                    const utr = newAnswers[currentCategoryData.title]?.[NinUtrQuestionType.UTR];
                    return nin && utr;
                }
                if (q.answerRequired) {
                    const answer: Array<string> = newAnswers[currentCategoryData.title]?.[q.question];
                    return answer?.length;
                }
                if (q?.requiredItems?.question) {
                    const requiredItems = Number(newAnswers[currentCategoryData.title]?.[q?.requiredItems?.question]);
                    const answersItems = Number(newAnswers[currentCategoryData.title]?.[q.question]?.length);
                    return requiredItems <= answersItems;
                }

                return newAnswers[currentCategoryData.title]?.[q.question];
            });
            setAllQuestionsAnswered(!!allAnswered);
        }
        dispatch(setNavigationItems(getNavigationItems(newAnswers)));
        dispatch(setAnswers(newAnswers));
    }, [answers, currentCategoryData.title, currentStepData, dispatch]);

    const handleNext = useCallback((questionType?: string) => {
        const timerValue = (
            currentStepData.hasCards ||
            currentStepData.type === StepType.categoryDone ||
            questionType === QuestionType.cardArrayQuestion
        ) ? 1 : 800;

        dispatch(setStepLoading(true));
        const timer = setTimeout(() => {
            findAndSetNextStep(store.getState(), dispatch);
            dispatch(setStepLoading(false));
        }, timerValue);
        return () => clearTimeout(timer);
    }, [currentStepData, dispatch]);

    const handlePrev = useCallback(() => {
        if (isEditingInnerArrayItem) {
            findAndSetInnerArrayPrev(store.getState(), dispatch);
        } else if (isEditingItem) {
            findAndSetArrayPrev(store.getState(), dispatch);
        } else if (isEditingProfile) {
            findAndSetProfilePrev(store.getState(), dispatch, currentStepData);
        } else {
            findAndSetPrevStep(store.getState(), dispatch);
        }
    }, [isEditingInnerArrayItem, isEditingItem, isEditingProfile, dispatch, currentStepData]);

    const handleStepContinue = useCallback((questionType?: string) => {
        const isEligibleToContinue = (
            currentStepData.type === StepType.bankAccount ||
            currentStepData.type === StepType.categoryDone ||
            currentStepData.type === StepType.review ||
            currentStepData.type === StepType.payment ||
            (currentStepData?.questions && (currentStepData.allowContinue || allQuestionsAnswered || (currentStepData.parentProfileType && allProfilesCompleted)))
        );

        if (isEligibleToContinue) {
            handleNext(questionType);
            if (currentStepData.type === StepType.categoryDone) {
                handlePlayPigAnimationTrigger();
            }
        }
    }, [
        currentStepData,
        allQuestionsAnswered,
        allProfilesCompleted,
        handleNext,
        handlePlayPigAnimationTrigger,
    ]);

    const handleCategoryClick = useCallback((categoryIndex: number) => {
        dispatch(setIsEditingProfile(false));
        dispatch(setCurrentProfile(undefined));
        dispatch(setCurrentProfileAnswers([]));
        const categoryData = categories[categoryIndex];
        if (categoryData.title === CategoryTitle.BankAccountConnection) {
            dispatch(next({ nextCategory: categoryIndex, nextStep: 1, nextQuestion: 0, nextProfileQuestion: 0 }));
        } else {
            dispatch(next({ nextCategory: categoryIndex, nextStep: 0, nextQuestion: 0, nextProfileQuestion: 0 }));
        }
    }, [dispatch]);

    useEffect(() => {
        const handleBackButtonClick = (event: PopStateEvent) => {
            if (event.state.step === 1) {
                handlePrev();
                window.history.forward();
            }
        };

        // Push initial state to the history stack
        if (initialLoad) {
            window.history.pushState({ step: 1 }, "", window.location.pathname);
            window.history.pushState({ step: 2 }, "", window.location.pathname);
            setInitialLoad(false);
        }

        // Add popstate event listener
        window.addEventListener("popstate", handleBackButtonClick);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener("popstate", handleBackButtonClick);
        };
    }, [handlePrev, currentCategory, currentStep, initialLoad]);

    const openConnectModal = useCallback(async () => {
        if (isPlaidEnabled) {
            try {
                setBankConnecting(true);
                // eslint-disable-next-line react-hooks/exhaustive-deps
                setInitialBankCount(connectedBankAccountsData?.accDetails?.length || 0);
                // Fetch the link token if Plaid is enabled
                const { data } = await getLinkToken({}) as any;
                const token = data.response.link_token;
                if (token) {
                    setLinkToken(token); // Set the link token in the state
                    setIsModalOpen(true); // Open the modal
                } else {
                    logError("[Plaid Link] Missing link token in response.");
                }
            } catch (error: any) {
                const contextInfo = {
                    clientId: user?.id,
                    function: "fetchLinkToken",
                    error: error.message || error,
                };
                logError(error, contextInfo);
            } finally {
                setBankConnecting(false);
            }
        } else {
            setIsModalOpen(true);
        }
    }, [isPlaidEnabled, setInitialBankCount, connectedBankAccountsData?.accDetails?.length, getLinkToken, user?.id]);

    const closeConnectModal = useCallback(() => {
        setIsModalOpen(false);

        let attempts = 0;
        const maxAttempts = 120; // Retry for up to 120 seconds

        const checkBankAccounts = async () => {
            const refreshedData = await refetchConnectedBankAccounts().unwrap();
            const updatedBankCount = refreshedData?.accDetails?.length || 0;
            if (updatedBankCount > initialBankCount) {
                return;
            }
            if (attempts >= maxAttempts) {
                return;
            }
            attempts++;
            setTimeout(checkBankAccounts, 5000); // Retry every 5 seconds
        };

        checkBankAccounts();

        dispatch(next({
            nextCategory: 1,
            nextStep: 0,
            nextQuestion: 0,
        }));
    }, [dispatch, refetchConnectedBankAccounts, initialBankCount]);

    useEffect(() => {
        if (refParams) {
            if (currentStep === 0) {
                dispatch(next({
                    nextCategory: 0,
                    nextStep: 1,
                    nextQuestion: 0,
                }));
            }
            setBankConnecting(true);

            const maxSpinnerDuration = setTimeout(() => {
                setBankConnecting(false);
                setSpinnerCompleted(true);
            }, 4000);

            completeConnection({ ref: refParams, bankDetailsOnly: false })
                .catch(error => {
                    const contextInfo = {
                        clientId: user?.id,
                        function: "completeConnection",
                        error: error.message,
                    };
                    logError(error, contextInfo);
                })
                .finally(() => {
                    clearTimeout(maxSpinnerDuration);

                    const url = new URL(window.location.href);
                    url.searchParams.delete("ref");
                    window.history.replaceState(null, "", url.toString());

                    setTimeout(() => {
                        refetchConnectedBankAccounts();
                    }, 500);

                    setBankConnecting(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refParams]);

    const pollConnectedBankAccounts = useCallback(async (retryCount = 0) => {
        try {
            const result = await refetchConnectedBankAccounts().unwrap();
            if (result) {
                logMessage("Connected bank accounts fetched successfully.");
                return;
            }
        } catch (error) {
            const contextInfo = {
                clientId: user?.id,
                function: "refetchConnectedBankAccounts",
                error: "Refreshing connected bank accounts failed.",
                attempt: retryCount + 1,
            };
            logError(error, contextInfo);
        }

        // Retry up to 5 times
        if (retryCount < 4) {
            setTimeout(() => pollConnectedBankAccounts(retryCount + 1), 1500);
        } else {
            logMessage(`Max retries reached for fetching connected bank accounts for client ${user?.id}.`);
        }
    }, [refetchConnectedBankAccounts, user?.id]);

    useEffect(() => {
        if (spinnerCompleted) {
            pollConnectedBankAccounts();
            setSpinnerCompleted(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spinnerCompleted]);

    useEffect(() => {
        if (currentStepData?.hasCards && allCardsAnswered) {
            handleNext();
        }
    }, [allCardsAnswered, currentStepData?.hasCards, handleNext]);

    useEffect(() => {
        if (currentStepData?.questions?.length) {
            const allAnswered = currentStepData.questions?.every(q => {
                if (q.answerRequired === false) return true;
                if (q.disallowedItems) {
                    const ans = answers[currentCategoryData.title]?.[q.disallowedItems.question];
                    const disallowedAnswers = [q.disallowedItems.answer];
                    return !ans?.every((answer: string) => disallowedAnswers.includes(answer));
                }
                if (q.type === QuestionType.questionsList) {
                    const allAnswered = q.questions?.every(questionsListQuestion => {
                        if (questionsListQuestion.answerRequired) {
                            const answer = answers[currentCategoryData.title]?.[questionsListQuestion.question];
                            return !!answer;
                        }
                        return true;
                    });
                    return !!allAnswered;
                }
                if (q.type === QuestionType.ninUtrQuestion) {
                    const nin = answers[currentCategoryData.title]?.[NinUtrQuestionType.NIN];
                    const utr = answers[currentCategoryData.title]?.[NinUtrQuestionType.UTR];
                    return nin && utr;
                }
                if (q.answerRequired) {
                    const answer: Array<string> = answers[currentCategoryData.title]?.[q.question];
                    return answer?.length;
                }
                if (q?.requiredItems?.question) {
                    const requiredItems = Number(answers[currentCategoryData.title]?.[q?.requiredItems?.question]);
                    const answersItems = Number(answers[currentCategoryData.title]?.[q.question]?.length);
                    return requiredItems <= answersItems;
                }
                return answers[currentCategoryData.title]?.[q.question];
            });
            setAllQuestionsAnswered(!!allAnswered);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStepData]);

    useEffect(() => {
        dispatch(setIncompleteProfiles(incompleteProfiles));
        if (incompleteProfiles.length > 0) {
            dispatch(markCategoryInProgress(categories[currentCategory].title));
        }
    }, [incompleteProfiles, dispatch, currentCategory]);

    useEffect(() => {
        setAutoSaveTrigger(new Date());

        if (navigationItems?.length) { // Safe check using optional chaining
            setTotalProgress(
                calculateTotalProgress(
                    categories.filter(cat => navigationItems.includes(cat.title)),
                    currentCategory,
                    currentStep
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCategory, currentStep]);

    useEffect(() => {
        setSwitchingDocument(true);

        if (!currentDocumentYear || !user?.id) {
            setSwitchingDocument(false);
            return;
        }

        const data = { clientId: user.id, year: currentDocumentYear };

        getOrCreateClientDocument(data)
            .unwrap()
            .then(response => {
                if (!response || !response.document) {
                    throw new Error("Invalid response format");
                }

                const { document } = response;
                const answersOB = document.answersOB || {}; // ✅ Prevents undefined errors

                dispatch(setCurrentDocument(document));
                dispatch(setAnswers(answersOB.answers || {}));
                dispatch(setSelectedSharingPreference(answersOB?.selectedSharingPreference || SharingPreferenceIdType.connect));
                setTotalProgress(answersOB.totalProgress || {});

                dispatch(
                    next({
                        nextCategory: answersOB.currentCategory || 0,
                        nextStep: answersOB.currentStep || 0,
                        nextQuestion: answersOB.currentQuestion || 0,
                        nextProfileQuestion: answersOB.currentProfileQuestion || 0,
                    })
                );

                setCurrentDocumentIdForAnswers(document.id);

                dispatch(
                    setNavigationItems(
                        answersOB.navigationItems?.length
                            ? answersOB.navigationItems
                            : [
                                CategoryTitle.BankAccountConnection,
                                CategoryTitle.PersonalInfo,
                                CategoryTitle.General,
                                CategoryTitle.ReviewAndSubmit,
                            ]
                    )
                );

                dispatch(
                    setCompletedCategories(
                        answersOB.completedCategories?.length
                            ? answersOB.completedCategories
                            : [CategoryTitle.BankAccountConnection]
                    )
                );

                dispatch(
                    setInProgressCategories(
                        answersOB.inProgressCategories?.length
                            ? answersOB.inProgressCategories
                            : [CategoryTitle.PersonalInfo]
                    )
                );
            })
            .catch(e => {
                antdMessage.error(`Failed to load form: ${e.message || "Unknown error"}`);
                logError(e, {
                    function: "getOrCreateClientDocument",
                    client: user?.id,
                    currentDocumentYear: currentDocumentYear,
                });
            })
            .finally(() => setSwitchingDocument(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDocumentYear]);

    useEffect(() => {
        if (
            debouncedProgress !== 100 &&
            debouncedProgress !== 0 &&
            user &&
            currentDocument.id &&
            (currentDocumentYear ? currentDocument.year === currentDocumentYear : true) &&
            !isClientDocumentGetting &&
            !switchingDocument &&
            (currentDocumentIdForAnswers ? currentDocument.id === currentDocumentIdForAnswers : true)
        ) {
            setIsAutosaving(true);
            const answersArray = createAnswersOBArray(answers, categories);
            const dataSections = findAllOBFiles(answers, categories);
            const employmentTypes = getEmploymentTypes(answers);
            const additionalIncomeSources = getAdditionalIncomeSources(answers);
            const isCompanyDirector = inferIfCompanyDirector(answers);
            const data = {
                clientId: user?.id || "",
                year: currentDocumentYear,
                answersOB: {
                    convertedAnswers: answersArray,
                    answers: answers,
                    shouldComplete: true,
                    totalProgress: totalProgress,
                    currentCategory: currentCategory,
                    currentStep: currentStep,
                    currentQuestion: currentQuestion,
                    currentProfileQuestion: currentProfileQuestion,
                    lastCategory: lastCategory,
                    lastStep: lastStep,
                    currentGroupId: currentStep,
                    navigationItems: navigationItems,
                    completedCategories: completedCategories,
                    inProgressCategories: inProgressCategories,
                    saveDate: new Date(),
                    dataSections,
                    employmentTypes: employmentTypes?.length ? employmentTypes : currentDocument?.employmentTypes || [],
                    additionalIncomeSources: additionalIncomeSources?.length ? additionalIncomeSources : currentDocument?.additionalIncomeSources || [],
                    isCompanyDirector: isCompanyDirector,
                    selectedSharingPreference: selectedSharingPreference,
                },
                employmentTypes: employmentTypes?.length ? employmentTypes : currentDocument?.employmentTypes || [],
                additionalIncomeSources: additionalIncomeSources?.length ? additionalIncomeSources : currentDocument?.additionalIncomeSources || [],
                isCompanyDirector: isCompanyDirector,
            };
            updateClientDocument({ documentId: currentDocument.id, data: data })
                .unwrap()
                .then(response => {
                    if (response) {
                        dispatch(setCurrentDocument(response.document));
                    }
                })
                .catch(async (e: any) => {
                    await antdMessage.error("Failed to save answers", e.message);
                    logError(e, {
                        function: "updateClientDocument",
                        client: user?.id,
                        currentDocument: currentDocument,
                        currentDocumentYear: currentDocumentYear,
                    });
                })
                .finally(() => {
                    setIsAutosaving(false);
                });
        } else {
            logMessage(`CHECK: Document is not ready for autosave. User: ${user?.id}, Progress: ${debouncedProgress}, Document: ${currentDocument.id}, Year: ${currentDocumentYear}, Getting: ${isClientDocumentGetting}, Switching: ${switchingDocument}, DocumentIdForAnswers: ${currentDocumentIdForAnswers}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedAutoSaveTrigger]);

    useEffect(() => {
        if (taxProfilesResponse && taxProfilesResponse.taxProfiles && taxProfilesStatus === "fulfilled") {
            dispatch(setAllTaxProfiles(taxProfilesResponse.taxProfiles || []));
        }
    }, [taxProfilesResponse, taxProfilesStatus, dispatch, incompleteProfiles]);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(setNavigationItems(getNavigationItems(answers)));
            if (!completedCategories?.includes(categories[currentCategory].title) && !inProgressCategories?.includes(categories[currentCategory].title)) {
                dispatch(next({ nextCategory: 1, nextStep: 0, nextQuestion: 0, nextProfileQuestion: 0 }));
            }
            setPageLoading(false);
        }, 300);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!user) {
        return null;
    }

    return (
        <div style={{ maxWidth: "1440px", margin: "0 auto" }}>
            <LottieAnimation isLoading={pageLoading || (!isAutosaving && isClientDocumentSaving) || bankConnecting} backgroundColor={"white"}/>
            {isMobile
                ? <MobileForm
                    user={user}
                    connectedBankAccounts={connectedBankAccounts}
                    refetchConnectedBankAccounts={refetchConnectedBankAccounts}
                    totalProgress={totalProgress}
                    handlePrev={handlePrev}
                    answers={answers}
                    currentStepData={currentStepData}
                    currentCategoryTitle={currentCategoryData.title}
                    handleStepContinue={handleStepContinue}
                    handleAnswerChange={handleAnswerChange}
                    allQuestionsAnswered={allQuestionsAnswered}
                    allProfilesCompleted={allProfilesCompleted}
                    openConnectModal={openConnectModal}
                    playPigAnimationTrigger={playPigAnimationTrigger}
                    handleCategoryClick={handleCategoryClick}
                    refetchTaxProfiles={refetchTaxProfiles}
                    currentCategoryData={currentCategoryData}
                    taxProfilesStatus={taxProfilesStatus}
                />
                : <DesktopForm
                    user={user}
                    connectedBankAccounts={connectedBankAccounts}
                    refetchConnectedBankAccounts={refetchConnectedBankAccounts}
                    totalProgress={totalProgress}
                    handlePrev={handlePrev}
                    answers={answers}
                    currentStepData={currentStepData}
                    currentCategoryTitle={currentCategoryData.title}
                    handleStepContinue={handleStepContinue}
                    handleAnswerChange={handleAnswerChange}
                    allQuestionsAnswered={allQuestionsAnswered}
                    allProfilesCompleted={allProfilesCompleted}
                    openConnectModal={openConnectModal}
                    playPigAnimationTrigger={playPigAnimationTrigger}
                    handleCategoryClick={handleCategoryClick}
                    refetchTaxProfiles={refetchTaxProfiles}
                    currentCategoryData={currentCategoryData}
                    taxProfilesStatus={taxProfilesStatus}
                />
            }

            {isPlaidEnabled && linkToken && isModalOpen && <ConnectPlaidModal
                linkToken={linkToken}
                onSuccess={() => {
                    closeConnectModal();
                }
                }
                onExit={() => {
                    setIsModalOpen(false);
                }
                }/>

            }

            {!isPlaidEnabled && isModalOpen && <ConnectGCModal
                isOpen={isModalOpen}
                closeConnectModal={closeConnectModal}
                openConnectModal={openConnectModal}
            />}
        </div>
    );
};

export default Questionnaire;
