import { useEffect, useState } from "react";

import { Modal } from "antd";
import Lottie from "lottie-react";

import animation from "../../basic/LootieAnimation/Open-banking loading lotti (1).json";

const LootieAnimation = ({ isLottie }: { isLottie: boolean }) => {
    const [animationWidth, setAnimationWidth] = useState<number>(300);

    useEffect(() => {
        const handleResize = () => {
            const newWidth = Math.min(window.innerWidth * 0.8, 400);
            setAnimationWidth(newWidth);
        };
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <Modal
            open={isLottie}
            footer={null}
            styles={{ body: { backgroundColor: "transparent", padding: 0 } }}
            closable={false}
            className="lootie_animation"
        >

            <div className="lootie_animationModal" style={{ display: "flex", flexDirection: "column" }}>
                <Lottie animationData={animation} style={{ width: animationWidth, height: "auto" }} />
                <div style={{ color: "white" }} className="payment_manually">
                    <p>This may take a few minutes</p>
                </div>
            </div>
        </Modal>
    );
};

export default LootieAnimation;
