import "./../../../styles/code.less";
import { useCallback, useContext, useEffect, useState } from "react";

import {
    CarryOutOutlined,
    EditOutlined,
    LeftOutlined,
    MailOutlined,
    PoundCircleOutlined,
    SmileOutlined,
} from "@ant-design/icons";
import { Button, Col, message, Progress, Row, Timeline } from "antd";
import { AppContext } from "App";
import OtpInput from "react-otp-input";
import { useParams } from "react-router-dom";

import FileUploadCode from "./components/FileUploadCode";
import FileUploadPassport from "./components/FileUploadPassport";
import QRCodeComponent from "./components/QRCode";
import { useAppDispatch } from "../../../redux/hooks";
import { useUpdateUserAgentLinkInfoMutation } from "../../../services/auth";
import { checkClientIdAvailability } from "../../../services/client";
import { useUploadFileMutation, useUploadAndParseFileMutation } from "../../../services/files";
import Spinner from "../../basic/Spinner/Spinner";

const PROGRESS = {
    "uploadCode": {
        "progress": 25,
        "label": "",
        "prev": undefined,
        "next": "inputCode",
        "errorMessage": "undefined",
        "buttonLabel": "",
        "validate": undefined,
    },
    "inputCode": {
        "progress": 50,
        "label": "",
        "prev": "uploadCode",
        "next": "passport",
        "buttonLabel": "Continue",
        "errorMessage": "Code is incorrect!",
        "validate": (code: string) => {
            return code.length >= 10;
        },
    },
    "passport": {
        "progress": 75,
        "label": "",
        "prev": "inputCode",
        "next": "result",
        "buttonLabel": "Continue",
        "errorMessage": "Not all files were uploaded !",
        "validate":  (idPassportDocumentUrl: string) => {
            return !!idPassportDocumentUrl;
        },
    },
    "result": {
        "progress": 100,
        "label": "Track your refund",
        "prev": "passport",
        "next": undefined,
        "errorMessage": undefined,
        "buttonLabel": "Login For Live Updates",
        "validate": undefined,
    },
};

type Page = keyof typeof PROGRESS;

const Code = () => {
    const { isAuth } = useContext(AppContext);
    const params = useParams();
    const dispatch = useAppDispatch();
    const { isMobile } = useContext(AppContext);

    const [exists, setExists] = useState<boolean | undefined>(undefined);
    const [page, setPage] = useState<Page>("uploadCode");
    const [code, setCode] = useState<string>("");
    const [agentLinkCodeDocumentUrl, setAgentLinkCodeDocumentUrl] = useState<string>("");
    const [idPassportDocumentUrl, setIdPassportDocumentUrl] = useState<string>("");
    const [agentLinkCodeDocumentOriginalName, setAgentLinkCodeDocumentOriginalName] = useState<string>("");
    const [idPassportDocumentOriginalName, setIdPassportDocumentOriginalName] = useState<string>("");
    const qrCodeUrl = `${process.env.REACT_APP_BASE_URL}/uk/finish/code/${params.clientId}`;

    useEffect(() => {
        if (params.clientId) {
            dispatch(checkClientIdAvailability.initiate({
                id: params.clientId,
            }))
                .unwrap()
                .then(result => {
                    setExists(!result?.available);
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }, [dispatch, params.clientId]);
    const [uploadFileAsync, { isLoading: isLoadingUploadFile }] = useUploadFileMutation();
    const [updateUserAgentLinkInfoAsync, { isLoading: isLoadingUpdateUserAgentLinkInfo }] = useUpdateUserAgentLinkInfoMutation();
    const [uploadAndParseImage, { isLoading: isLoadingCodeUploadFile }] = useUploadAndParseFileMutation();

    const handleFileUploadFailure = () => {
        setPage("inputCode");
    };
    const handleUploadSuccess = () => {
        setPage("passport");
    };

    const handleFile = async (file: File) => {
        try {
            const clientId = params.clientId;
            const type = "hmrc_agent_auth_form";
            const response = await uploadAndParseImage({ clientId, type, file }).unwrap();
            const { status, data, parsedData } = response;
            if (status === "Success") {
                setCode(parsedData);
                return data.url;
            } else {
                setAgentLinkCodeDocumentUrl(data.url);
                return null;
            }
        } catch (error) {
            console.error("Error uploading and parsing image:", error);
            return null;
        }
    };

    const uploadFile = useCallback(async (file: File) => {
        const uploadedFileResponse = await uploadFileAsync({
            file: file,
            path: `clients/${params.clientId}/files`,
        }).unwrap();

        if (!uploadedFileResponse?.data?.url) return "";

        return uploadedFileResponse.data.url;
    }, [uploadFileAsync, params.clientId]);

    const timelineItemComponent = useCallback((title: string, description: string) => {
        return (
            <div className={"ml10 pb30 default-font"}>
                <div className={"bold grey-7-color"}>
                    {title}
                </div>
                <div className={"fs12 grey-5-color"}>
                    {description}
                </div>
            </div>
        );
    }, []);

    if (exists === undefined) {
        return (
            <div className={"center"}>
                Wait...
            </div>
        );
    }

    if (!exists || !params.clientId) {
        return (
            <div className={"center"}>
                Client was not found
            </div>
        );
    }

    const handleBackNavigation = () => {
        let prevPage = PROGRESS[page].prev;
        if (page === "passport" && (!code)) {
            prevPage = "uploadCode";
        }
        setPage(prevPage as Page);
    };

    return (
        <>
            {isMobile ? (<>
                <div>
                    <Spinner isLoading={isLoadingUploadFile || isLoadingUpdateUserAgentLinkInfo || isLoadingCodeUploadFile}>
                        <div className={"code__wrapper"}>
                            <div className={"code__header"}>
                                <div style={{ width: "100%" }}>
                                    <div>
                                        <Row>
                                            <Col span={4}>
                                                {PROGRESS[page].prev ? (
                                                    <div className={"ml20 horizontal-left"}>
                                                        <LeftOutlined
                                                            className={"mt4 grey-6-color"}
                                                            onClick={handleBackNavigation}

                                                        />
                                                    </div>
                                                ) : null}
                                            </Col>
                                            <Col span={16}>
                                                <div className={"default-font center grey-7-color"}>
                                                    {PROGRESS[page].label}
                                                </div>
                                            </Col>
                                            <Col span={4}></Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <Progress
                                            size={["90%", 10]}
                                            strokeColor={"#2ebb49"}
                                            percent={PROGRESS[page].progress}
                                            showInfo={false}
                                            style={{
                                                margin: 0,
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={"horizontal-center"}>
                                <div className={"p20"} style={{ maxWidth: 800, paddingTop: "5px" }}>
                                    {page === "uploadCode" && (
                                        <div>
                                            <div className="uk-code-slogan">
                                        Upload HMRC agent-authorisation document
                                            </div>
                                            <p className="uk-code-description">
                                        Please upload the document sent to you by HMRC. <span style={{ color: "red" }}>*required*</span>
                                            </p>
                                            <div className="horizontal-center">
                                                <img
                                                    src="https://taxzap-images.s3.eu-west-1.amazonaws.com/image-document.png"
                                                    alt={"constructors"}
                                                    className={"uk-main-image-mobile"}
                                                    style={{ margin: "auto", display: "flex", height: "260px" }}
                                                />
                                            </div>
                                            <div>
                                                <div>
                                                    <p className={"uk-code-description-text"} style={{ fontSize: "14px" }}>
                                                            Didn’t get the document? HMRC typically takes <span style={{ fontWeight: "600" }}>14 days to deliver it to your address.</span>
                                                    </p>
                                                </div>
                                                <FileUploadCode
                                                    name="file-agent-link-document"
                                                    originalName={agentLinkCodeDocumentOriginalName}
                                                    label="agent-code document"
                                                    uploadFile={handleFile}
                                                    url={agentLinkCodeDocumentUrl}
                                                    setUrl={setAgentLinkCodeDocumentUrl}
                                                    setOriginalName={setAgentLinkCodeDocumentOriginalName}
                                                    handleUploadFailure={handleFileUploadFailure}
                                                    handleUploadSuccess={handleUploadSuccess}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {page === "inputCode" && (
                                        <div>
                                            <div className={"uk-code-slogan"}>
                                        Enter Authorization Code
                                            </div>
                                            <div className={"uk-code-slogan-description"} style={{ color: "red" }}>
                                        Uploading failed. Please enter Authorisation Code on the document manually.
                                            </div>
                                            <div
                                                className={"margin-top--small mt20 horizontal-left"}
                                                style={{
                                                    flexWrap: "wrap",
                                                }}
                                            >
                                                <OtpInput
                                                    inputStyle={"inputStyle"}
                                                    value={code}
                                                    onChange={value => setCode(value.toUpperCase())}
                                                    numInputs={10}
                                                    renderInput={props => <input {...props} placeholder="X" />}
                                                    inputType={"text"}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {page === "passport" && (
                                        <div>
                                            <div className={"uk-code-slogan"} >
                                                Last step: Upload your ID or passport
                                            </div>
                                            <p className="uk-code-description">
                                                    Please upload your ID or passport for security check. <span style={{ color: "red" }}>*required*</span>
                                            </p>
                                            <div className={"mt20"}>
                                                <img
                                                    src="https://taxzap-images.s3.eu-west-1.amazonaws.com/passport.png"
                                                    alt={"passport"}
                                                    className={"uk-main-image-mobile"}
                                                    style={{ margin: "auto", display: "flex", height: "220px" }}
                                                />
                                                <p className="uk-code-description"style={{ marginTop: "0" }}>
                                                    Security check is required to verify your ID. <span style={{ color: "#555555", fontWeight: "600", marginBottom: "45px" }}>It’s the last step.</span>
                                                </p>
                                                <FileUploadPassport
                                                    name="file-id-passport-document"
                                                    originalName={idPassportDocumentOriginalName}
                                                    label="your ID / Passport"
                                                    uploadFile={uploadFile}
                                                    url={idPassportDocumentUrl}
                                                    setUrl={setIdPassportDocumentUrl}
                                                    setOriginalName={setIdPassportDocumentOriginalName}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {page === "result" && <div>

                                        <div className={"mt20"}>
                                            <div className={"grey-7-color bold fs16"}>
                                            Track your refund
                                            </div>

                                            <div className={"mt40"}>
                                                <Timeline
                                                    items={[
                                                        {
                                                            color: "green",
                                                            dot: <SmileOutlined className={"fs40"}/>,
                                                            children: timelineItemComponent("Signed up", "today"),
                                                        },
                                                        {
                                                            color: "green",
                                                            dot: <MailOutlined className={"fs40"}/>,
                                                            children: timelineItemComponent("Submitted the code", "today"),
                                                        },
                                                        {
                                                            color: "purple",
                                                            dot: <EditOutlined className={"fs40"}/>,
                                                            children: timelineItemComponent("Reviewing your submission", "3-5 days"),
                                                        },
                                                        {
                                                            color: "gray",
                                                            dot: <CarryOutOutlined className={"fs40"}/>,
                                                            children: timelineItemComponent("Submitting to HMRC", "immediately"),
                                                        },
                                                        {
                                                            color: "gray",
                                                            dot: <PoundCircleOutlined className={"fs40"}/>,
                                                            children: timelineItemComponent("Get your refund", "5-6 weeks"),
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>

                            {page !== "uploadCode" && <div className={"code__footer"}>
                                <div className={"p20 border-top-1px"} style={{ width: "100%" }}>
                                    <div className={"horizontal-center"}>
                                        <Button
                                            size={"large"}
                                            shape={"round"}
                                            type={"primary"}
                                            className={"uk-button"}
                                            style={{ width: "100%", maxWidth: "300px", height: "60px" }}
                                            disabled={(() => {
                                                let validationResult = true;
                                                if (page === "inputCode") {
                                                    validationResult = PROGRESS[page].validate(code);
                                                } else if (page === "passport") {
                                                    validationResult = PROGRESS[page].validate(idPassportDocumentUrl);
                                                }
                                                return !validationResult;
                                            })()}
                                            onClick={() => {
                                                if (PROGRESS[page].next === "result") {
                                                    return updateUserAgentLinkInfoAsync({
                                                        clientId: params.clientId!,
                                                        code,
                                                        photoOfDocument: {
                                                            url: agentLinkCodeDocumentUrl,
                                                            status: "success",
                                                            originalName: agentLinkCodeDocumentOriginalName,
                                                        },
                                                        idPassport: {
                                                            url: idPassportDocumentUrl,
                                                            status: "success",
                                                            originalName: idPassportDocumentOriginalName,
                                                        },
                                                    })
                                                        .unwrap()
                                                        .then(() => {
                                                            setPage(PROGRESS[page].next as Page);
                                                        })
                                                        .catch(() => {
                                                            message.error("Failed to update agent link info");
                                                        });
                                                } else if (!PROGRESS[page].next) {
                                                    return window.location.href = `${process.env.REACT_APP_BASE_URL}/uk/auth`;
                                                }

                                                setPage(PROGRESS[page].next as Page);
                                            }}
                                        >
                                            {!PROGRESS[page].next && isAuth ? "Return to Profile" : PROGRESS[page].buttonLabel}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </Spinner>
                </div>
            </>
            ) : (<>
                <div>
                    <Spinner isLoading={isLoadingUploadFile || isLoadingUpdateUserAgentLinkInfo || isLoadingCodeUploadFile}>
                        <div className={"code__wrapper"}>
                            <div className={"code__header"}>
                                <div style={{ width: "100%" }}>
                                    <div>
                                        <Row>
                                            <Col span={4}>
                                                {PROGRESS[page].prev ? (
                                                    <div className={"ml20 horizontal-left"}>
                                                        <LeftOutlined
                                                            className={"mt4 grey-6-color"}
                                                            onClick={handleBackNavigation}
                                                        />
                                                    </div>
                                                ) : null}
                                            </Col>
                                            <Col span={16}>
                                                <div className={"default-font center grey-7-color"}>
                                                    {PROGRESS[page].label}
                                                </div>
                                            </Col>
                                            <Col span={4}></Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <Progress
                                            size={["90%", 10]}
                                            strokeColor={"#2ebb49"}
                                            percent={PROGRESS[page].progress}
                                            showInfo={false}
                                            style={{
                                                margin: 0,
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={"horizontal-center"}>
                                <div className={"p20"} style={{ maxWidth: 900 }}>
                                    {page === "uploadCode" && (
                                        <div>
                                            <div className="uk-code-slogan-header">
                                                Upload HMRC agent-authorisation document
                                            </div>
                                            <div className="uk-horizontal-container">
                                                <div style={{ flex: 1, textAlign: "right" }}>
                                                    <p className="uk-code-slogan" style={{ display: "flex", textAlign: "left", alignItems: "center" }}>Scan QR Code<div className="uk-recommended">RECOMMENDED</div></p>
                                                    <p className="uk-flex-left ">To continue with your mobile phone</p>
                                                    <QRCodeComponent url={qrCodeUrl}/>
                                                </div>
                                                <div className="middle-line-container" style={{ marginLeft: "70px", marginRight: "70px", marginTop: "20px" }}>
                                                    <div className="middle-line">
                                                        <img
                                                            src="https://taxzap-images.s3.eu-west-1.amazonaws.com/Line+56.png"
                                                            alt="line"
                                                            height={480}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ flex: 1, textAlign: "right" }}>
                                                    <img
                                                        src="https://taxzap-images.s3.eu-west-1.amazonaws.com/image-document.png"
                                                        alt="constructors"
                                                        className="uk-main-image-mobile"
                                                        style={{ width: "400px" }}
                                                    />
                                                    <div style={{ display: "inline-block" }}>
                                                        <p className={"uk-code-description-desktop" } style={{ marginTop: "-10px", marginBottom: "30px" }}>
                                                            Please upload the document sent to you by HMRC. <span style={{ color: "red" }}>*required*</span>
                                                        </p>
                                                        <p className={"uk-code-description-desktop"} style={{ fontSize: "14px" }}>
                                                            Didn’t get the document? HMRC typically takes <span style={{ fontWeight: "600" }}>14 days to deliver it to your address.</span>
                                                        </p>
                                                        <FileUploadCode
                                                            name="file-agent-link-document"
                                                            originalName={agentLinkCodeDocumentOriginalName}
                                                            label="agent-code document"
                                                            uploadFile={handleFile}
                                                            url={agentLinkCodeDocumentUrl}
                                                            setUrl={setAgentLinkCodeDocumentUrl}
                                                            setOriginalName={setAgentLinkCodeDocumentOriginalName}
                                                            handleUploadFailure={handleFileUploadFailure}
                                                            handleUploadSuccess={handleUploadSuccess}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {page === "inputCode" && (
                                        <div>
                                            <div className="uk-code-slogan-header">
                                                Upload HMRC agent-authorisation document
                                            </div>
                                            <div className="uk-horizontal-container">
                                                <div style={{ flex: 1, textAlign: "right" }}>
                                                    <p className="uk-code-slogan" style={{ display: "flex", textAlign: "left", alignItems: "center" }}>Scan QR Code<div className="uk-recommended">RECOMMENDED</div></p>
                                                    <p className="uk-flex-left ">To continue with your mobile phone</p>
                                                    <QRCodeComponent url={qrCodeUrl}/>

                                                </div>
                                                <div className="middle-line-container" style={{ marginLeft: "70px", marginRight: "70px", marginTop: "20px" }}>
                                                    <div className="middle-line">
                                                        <img
                                                            src="https://taxzap-images.s3.eu-west-1.amazonaws.com/Line+56.png"
                                                            alt="line"
                                                            height={480}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ flex: 1, textAlign: "right" }}>
                                                    <div className="uk-code-slogan" style={{ textAlign: "left", marginTop: "30px" }}>
                                                        Enter Authorization Code
                                                    </div>
                                                    <div className="uk-code-slogan-description" style={{ color: "red", textAlign: "left", marginBottom: "30px" }}>
                                                        Uploading failed. Please enter Authorisation Code on the document manually.
                                                    </div>
                                                    <div
                                                        className={"margin-top--small mt20 horizontal-left"}
                                                        style={{
                                                            flexWrap: "wrap",
                                                            marginBottom: "180px",
                                                        }}
                                                    >
                                                        <OtpInput
                                                            inputStyle={"inputStyle"}
                                                            value={code}
                                                            onChange={value => setCode(value.toUpperCase())}
                                                            numInputs={10}
                                                            renderInput={props => <input {...props} placeholder="X" />}
                                                            inputType={"text"}
                                                        />

                                                    </div>
                                                    <div className={"horizontal-center"}>
                                                        <Button
                                                            size={"large"}
                                                            shape={"round"}
                                                            type={"primary"}
                                                            className={"uk-button"}
                                                            style={{ width: "100%", maxWidth: "300px", height: "60px" }}
                                                            disabled={(() => {
                                                                let validationResult = true;

                                                                if (page === "inputCode") {
                                                                    validationResult = PROGRESS[page].validate(code);
                                                                }
                                                                return !validationResult;
                                                            })()}
                                                            onClick={() => {
                                                                if (PROGRESS[page].next === "result") {
                                                                    console.log("code", code);
                                                                    return updateUserAgentLinkInfoAsync({
                                                                        clientId: params.clientId!,
                                                                        code,
                                                                        photoOfDocument: {
                                                                            url: agentLinkCodeDocumentUrl,
                                                                            status: "success",
                                                                            originalName: agentLinkCodeDocumentOriginalName,
                                                                        },
                                                                        idPassport: {
                                                                            url: idPassportDocumentUrl,
                                                                            status: "success",
                                                                            originalName: idPassportDocumentOriginalName,
                                                                        },
                                                                    })
                                                                        .unwrap()
                                                                        .then(() => {
                                                                            setPage(PROGRESS[page].next as Page);
                                                                        })
                                                                        .catch(() => {
                                                                            message.error("Failed to update agent link info");
                                                                        });
                                                                } else if (!PROGRESS[page].next) {
                                                                    return window.location.href = `${process.env.REACT_APP_BASE_URL}/uk/auth`;
                                                                }

                                                                setPage(PROGRESS[page].next as Page);
                                                            }}
                                                        >
                                                            {!PROGRESS[page].next && isAuth ? "Continue" : PROGRESS[page].buttonLabel}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )}

                                    {page === "passport" && (
                                        <div>
                                            <div className="uk-code-slogan-header">
                                            Last step: Upload your ID or passport
                                            </div>
                                            <div className="uk-horizontal-container">
                                                <div className="uk-flex-right">
                                                    <p className="uk-code-slogan" style={{ display: "flex", textAlign: "left", alignItems: "center" }}>Scan QR Code<div className="uk-recommended">RECOMMENDED</div></p>
                                                    <p className="uk-flex-left ">To continue with your mobile phone</p>
                                                    <QRCodeComponent url={qrCodeUrl}/>
                                                </div>
                                                <div className="uk-middle-line-container">
                                                    <div className="middle-line">
                                                        <img
                                                            src="https://taxzap-images.s3.eu-west-1.amazonaws.com/Line+56.png"
                                                            alt="line"
                                                            height={480}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ flex: "1", textAlign: "center" }}>
                                                    <img
                                                        src="https://taxzap-images.s3.eu-west-1.amazonaws.com/passport.png"
                                                        alt="constructors"
                                                        className="uk-main-image-mobile"
                                                        style={{ height: "250px", textAlign: "center" }}
                                                    />
                                                    <div style={{ display: "inline-block" }}>
                                                        <p className={"uk-code-description-desktop" } style={{ marginTop: "-10px" }}>
                                                        Please upload your ID or passport for security check <span style={{ color: "red" }}>*required*</span>
                                                        </p>
                                                        <p className={"uk-code-description-desktop"} style={{ fontSize: "14px" }}>
                                                        Security check is required to verify your ID. <span style={{ fontWeight: "600" }}>It’s the last step.</span>
                                                        </p>
                                                        <FileUploadPassport
                                                            name="file-id-passport-document"
                                                            originalName={idPassportDocumentOriginalName}
                                                            label="your ID / Passport"
                                                            uploadFile={uploadFile}
                                                            url={idPassportDocumentUrl}
                                                            setUrl={setIdPassportDocumentUrl}
                                                            setOriginalName={setIdPassportDocumentOriginalName}
                                                        />
                                                    </div>
                                                    <div className={"horizontal-center"}>
                                                        <Button
                                                            size={"large"}
                                                            shape={"round"}
                                                            type={"primary"}
                                                            className={"uk-button"}
                                                            style={{ width: "100%", maxWidth: "300px", height: "60px", marginTop: "40px" }}
                                                            disabled={(() => {
                                                                let validationResult = true;

                                                                if (page === "passport") {
                                                                    validationResult = PROGRESS[page].validate(idPassportDocumentUrl);
                                                                }
                                                                return !validationResult;
                                                            })()}
                                                            onClick={() => {
                                                                if (PROGRESS[page].next === "result") {
                                                                    return updateUserAgentLinkInfoAsync({
                                                                        clientId: params.clientId!,
                                                                        code,
                                                                        photoOfDocument: {
                                                                            url: agentLinkCodeDocumentUrl,
                                                                            status: "success",
                                                                            originalName: agentLinkCodeDocumentOriginalName,
                                                                        },
                                                                        idPassport: {
                                                                            url: idPassportDocumentUrl,
                                                                            status: "success",
                                                                            originalName: idPassportDocumentOriginalName,
                                                                        },
                                                                    })
                                                                        .unwrap()
                                                                        .then(() => {
                                                                            setPage(PROGRESS[page].next as Page);
                                                                        })
                                                                        .catch(() => {
                                                                            message.error("Failed to update agent link info");
                                                                        });
                                                                } else if (!PROGRESS[page].next) {
                                                                    return window.location.href = `${process.env.REACT_APP_BASE_URL}/uk/auth`;
                                                                }

                                                                setPage(PROGRESS[page].next as Page);
                                                            }}
                                                        >
                                                            {!PROGRESS[page].next && isAuth ? "Continue" : PROGRESS[page].buttonLabel}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {page === "result" && <div>

                                        <div className={"mt20"}>
                                            <div className={"grey-7-color bold fs16"}>
                                            Track your refund
                                            </div>

                                            <div className={"mt40"}>
                                                <Timeline
                                                    items={[
                                                        {
                                                            color: "green",
                                                            dot: <SmileOutlined className={"fs40"}/>,
                                                            children: timelineItemComponent("Signed up", "today"),
                                                        },
                                                        {
                                                            color: "green",
                                                            dot: <MailOutlined className={"fs40"}/>,
                                                            children: timelineItemComponent("Submitted the code", "today"),
                                                        },
                                                        {
                                                            color: "purple",
                                                            dot: <EditOutlined className={"fs40"}/>,
                                                            children: timelineItemComponent("Reviewing your submission", "3-5 days"),
                                                        },
                                                        {
                                                            color: "gray",
                                                            dot: <CarryOutOutlined className={"fs40"}/>,
                                                            children: timelineItemComponent("Submitting to HMRC", "immediately"),
                                                        },
                                                        {
                                                            color: "gray",
                                                            dot: <PoundCircleOutlined className={"fs40"}/>,
                                                            children: timelineItemComponent("Get your refund", "5-6 weeks"),
                                                        },
                                                    ]}
                                                />
                                            </div>
                                            <div className={"horizontal-center"}>
                                                <Button
                                                    size={"large"}
                                                    shape={"round"}
                                                    type={"primary"}
                                                    className={"uk-button"}
                                                    style={{ width: "100%", maxWidth: "300px", height: "60px" }}
                                                    onClick={() => {
                                                        if (!PROGRESS[page].next) {
                                                            return window.location.href = `${process.env.REACT_APP_BASE_URL}/uk/auth`;
                                                        }
                                                    }}
                                                >
                                                    {!PROGRESS[page].next && isAuth ? "Return to Profile" : PROGRESS[page].buttonLabel}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </Spinner>
                </div>
            </>
            )}
        </>
    );
};
export default Code;
