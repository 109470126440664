import { useCallback, useContext } from "react";

import { Button } from "antd";
import { AppContext } from "App";
import rightArrow from "assets/images/right-arrow.svg";
import rightPurpleArrow from "assets/images/uk/right-arrow-purple.svg";
import { useNavigate } from "react-router-dom";
import { scrollToElement, scrollToTop } from "utils/common";

const StartButton = ({
    label = "Start now",
    purple,
}: {
    label?: string;
    purple? :boolean;
}) => {
    const navigate = useNavigate();
    const { isMobile, toggleFormScale } = useContext(AppContext);

    const handleStart = useCallback(
        () => {
            if (isMobile) {
                scrollToTop();
                navigate("/sign-up?step=personalInfo");
            } else {
                toggleFormScale(true);
                scrollToElement("sign-up");
            }
        },
        [isMobile, navigate, toggleFormScale]
    );
    return <div className={"horizontal-left"}>
        <Button
            shape={"round"}
            size="large"
            type="primary"
            className={`uk-rounded-button-start ${purple ? "purple" : ""}`}
            onClick={handleStart}
        >
            {label}
        </Button>

        <div className={"uk-rounded-button-start-arrow"} onClick={handleStart}>
            <img src={purple ? rightPurpleArrow : rightArrow} width={20} height={20} alt={"start button"}/>
        </div>
    </div>;
};

export default StartButton;
