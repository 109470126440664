import { FC, useContext, useEffect } from "react";

import { Modal } from "antd";
import { AppContext } from "App";
import paymentSuccessfulAnimation from "assets/animation/paymentSuccessful.json";
import taxzapLogo from "assets/images/taxzap-logo-R.svg";
import Lottie from "lottie-react";
import moment from "moment";
import ReactMetaPixel from "react-facebook-pixel";
import { useTimer } from "react-timer-hook";
import { logError } from "utils/sentry";

type PaymentSuccessModalProps = {
    showPaymentSuccessModal: boolean;
    onSuccess: () => void;
};

export const PaymentSuccessModal: FC<PaymentSuccessModalProps> = ({
    showPaymentSuccessModal,
    onSuccess,
}) => {
    const { isMobile } = useContext(AppContext);
    const expiryTimestamp = moment().add(3, "s").toDate(); //revert to seconds

    useEffect(() => {
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set("paid", "true");
        window.history.replaceState({}, "", currentUrl);
    }, []);

    useTimer({ expiryTimestamp,
        onExpire: () => {
            try {
                process.env.REACT_APP_META_PIXEL_ID && ReactMetaPixel.fbq("track", "Purchase");
                const currentUrl = new URL(window.location.href);
                currentUrl.searchParams.delete("paid");
                window.history.replaceState({}, "", currentUrl);
            } catch (error) {
                logError(error, { message: "Error in PaymentSuccessModal ReactMetaPixel" });
            } finally {
                onSuccess();
            }
        },

    });

    return <Modal
        closable={false}
        open={showPaymentSuccessModal}
        footer={<div></div>}
        className={`payment-modal-container${isMobile ? "-mobile" : "-desktop"}`}
    >
        <div>
            <div>
                <img
                    className = "payment-status-modal-taxzap-logo"
                    src={taxzapLogo}
                    alt="TaxZap Logo"
                />
            </div>
            <div>
                <Lottie
                    animationData={paymentSuccessfulAnimation}
                    loop={false}
                    className={`payment-animation${isMobile ? "-mobile" : "-desktop"}`}
                />
                <div className="text-align-center">
                    <h3 className="payment-modal-h3-text">Payment Approved</h3>
                    <p className="payment-modal-p-text">Your payment has been successful</p>
                </div>
            </div>
        </div>
    </Modal>;
};
