import { ChangeEvent, FC, useContext, useState } from "react";

import { AppContext } from "App";
import { CgSpinnerAlt } from "react-icons/cg";
import { IoIosCheckmark } from "react-icons/io";
import { useApplyDiscountMutation } from "services/payments";
import { IOrderDiscountRequest } from "types/payments";
import { useDebouncedCallback } from "use-debounce";

type IncomeProps = {
    setAmount: (_amount: number) => void;
    setOldAmount: (_amount: number) => void;
    amount: number;
};

export const DiscountBox: FC<IncomeProps> = ({
    setAmount,
    setOldAmount,
    amount,
}) => {
    const { isMobile } = useContext(AppContext);
    const [applyDiscount, { isLoading: isLoadingDiscount, isSuccess: discountApplied }] = useApplyDiscountMutation();
    const [isInvalid, setIsInvalid] = useState(false);
    const [discountInputVisible, setDiscountInputVisible] = useState(false);

    const debouncedCodeCheck = useDebouncedCallback((discountCode: string) => {
        const request: IOrderDiscountRequest = { discountCode };
        applyDiscount(request).unwrap().then(response => {
            const newAmount = response.amountInPence / 100;
            setOldAmount(amount);
            setAmount(newAmount);
        }).catch(() => setIsInvalid(true));
    }, 2000);

    const onApplyPromoCode = () => {
        setDiscountInputVisible(true);
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsInvalid(false);
        if (!e.target.value) {
            debouncedCodeCheck.cancel();
            return;
        }
        debouncedCodeCheck(e.target.value);
    };

    return (
        <div className="discount-outer-container">
            <div className={`apply-promo-button-outer-div${isMobile ? "-mobile" : "-desktop"}`}>
                <div className="apply-promo-button-inner-div">
                    <button className="apply-promo-button fc737373" onClick={onApplyPromoCode}>
                        + Apply discount code
                    </button>
                </div>
            </div>
            {discountInputVisible &&
                <div className = "discount-input-outer-div">
                    <div>
                        <div className="discount-input-inner-div">
                            <input className={`discount-input-box ${isInvalid ? "discount-input-box-error" : discountApplied ? "discount-input-box-success" : ""}`} type="text" placeholder="Discount Code" onChange={onChange}/>
                            {isLoadingDiscount && <CgSpinnerAlt className="discount-icon spin"/>}
                            {discountApplied && <IoIosCheckmark className="discount-icon"/>}
                        </div>
                        {isInvalid && <div className={`invalid-code-box ${isMobile ? "w80" : "w75"}`}>
                            Invalid Code
                        </div>
                        }
                    </div>
                </div>
            }
        </div>
    );
};
