export const EmploymentIndustries: string[] = [
    "🚜 Agriculture & Forestry",
    "Aviation",
    "🦺 Construction",
    "🏥 Healthcare",
    "🏭 Manufacturing",
    "📚 Public Service",
    "🔒 Security",
    "🏨 Hospitality",
    "📈 Financial Services",
    "💪 Health & Fitness",
    "🎥 Entertainment",
    "🎓 Education",
    "💼 Professional Services",
    "🚢 Seafarers",
    "📡 Technology",
    "🗞️ Media",
    "👀 None of the above",
];

export const EmploymentSubIndustries: Record<string, string[]> = {
    "🚜 Agriculture & Forestry": [],
    "Aviation": [],
    "🦺 Construction": [
        "Aluminium",
        "Brass and copper",
        "Building",
        "Building materials",
        "Constructional engineering (includes buildings, shipyards, bridges and roads)",
        "Electrical and electricity supply",
        "Heating",
        "Iron and steel",
        "Iron mining",
        "Leather",
        "Particular engineering",
        "Precious metals",
        "Printing",
        "Quarrying",
        "Railways",
        "Seamen",
        "Shipyards",
        "Textiles and textile printing",
        "Trades ancillary to engineering",
        "Vehicles",
        "None of the above",
    ],
    "🏥 Healthcare": [],
    "🏭 Manufacturing": [
        "Clothing",
        "Glass",
        "None of the above",
    ],
    "📚 Public Service": [
        "Armed Forces",
        "Fire Service",
        "Police",
        "Prison Service",
        "Docks and inland waterways",
        "Public Transport",
        "None of the above",
    ],
    "🔒 Security": [
        "Banks and building societies",
        "None of the above",
    ],
    "🏨 Hospitality": [],
    "📈 Financial Services": [],
    "💪 Health & Fitness": [],
    "🎥 Entertainment": [],
    "🎓 Education": [],
    "💼 Professional Services": [],
    "🚢 Seafarers": [],
    "📡 Technology": [],
    "🗞️ Media": [],
    "👀 None of the above": [],
};

export const EmploymentJobs: Record<string, string[]> = {
    "Aviation": [
        "Pilots",
        "Co-pilots",
        "Helicopter pilots",
        "Uniformed flight deck crew",
        "Cabin crew",
        "None of the above",
    ],
    "Aluminium": [
        "Continual casting operators",
        "Process operators",
        "De-dimplers",
        "Driers",
        "Drill punchers",
        "Dross unloaders",
        "Firemen",
        "Furnace operators and their helpers",
        "Leaders",
        "Mould-men",
        "Pourers",
        "Remelt department labourers",
        "Roll flatteners",
        "Cable hands",
        "Cable makers",
        "Labourers",
        "Mates",
        "Truck drivers",
        "Measurers",
        "Storekeepers",
        "Apprentices",
        "All other workers",
        "None of the above",
    ],
    "Brass and copper": [
        "Braziers",
        "Coppersmiths",
        "Finishers",
        "Fitters",
        "Moulders",
        "Turners",
        "All other workers",
        "None of the above",
    ],
    "Building": [
        "Joiners",
        "Carpenters",
        "Cement works",
        "Roofing felt",
        "Asphalt labourers",
        "Labourers",
        "Navvies",
        "All other workers",
        "None of the above",
    ],
    "Building materials": [
        "Stone masons",
        "Tilemakers",
        "All other workers",
        "None of the above",
    ],
    "Constructional engineering (includes buildings, shipyards, bridges and roads)": [
        "Blacksmiths and their strikers",
        "Burners",
        "Caulkers",
        "Chippers",
        "Drillers",
        "Erectors",
        "Fitters",
        "Holders up",
        "Markers off",
        "Platers",
        "Riggers",
        "Riveters",
        "Rivet heaters",
        "Scaffolders",
        "Sheeters",
        "Template workers",
        "Turners",
        "Welders",
        "Banksmen",
        "Labourers",
        "Shop-helpers",
        "Slewers",
        "Straighteners",
        "Apprentices",
        "Storekeepers",
        "All other workers",
        "None of the above",
    ],
    "Electrical and electricity supply": [
        "Workers incurring laundry costs only",
        "All other workers",
        "None of the above",
    ],
    "Heating": [
        "Pipe fitters",
        "Plumbers",
        "Coverers",
        "Laggers",
        "Domestic glaziers",
        "Heating engineers and all their mates",
        "All gas workers",
        "All other workers",
        "None of the above",
    ],
    "Iron and steel": [
        "Day labourers",
        "General labourers",
        "Stockmen",
        "Timekeepers",
        "Warehouse staff",
        "Weighmen",
        "Apprentices",
        "All other workers",
        "None of the above",
    ],
    "Iron mining": [
        "Fillers",
        "Miners",
        "Underground workers",
        "All other workers",
        "None of the above",
    ],
    "Leather": [
        "Curriers (wet workers)",
        "Fellmongering workers",
        "Tanning operatives (wet)",
        "All other workers",
        "None of the above",
    ],
    "Particular engineering": [
        "Pattern makers",
        "Chainmakers",
        "Cleaners",
        "Galvanisers",
        "Tinners",
        "Wire drawers",
        "Toolmakers in the lock making industry",
        "Apprentices",
        "Storekeepers",
        "All other workers",
        "None of the above",
    ],
    "Printing": [
        "Letterpress section-electrical engineers (rotary presses)",
        "Electrotypers",
        "Ink and roller makers",
        "Machine minders (rotary)",
        "Maintenance engineers (rotary presses)",
        "Stereotypers",
        "Bench hands (periodical and bookbinding section)",
        "Compositors (letterpress section)",
        "Readers",
        "Warehousemen (paper box making section)",
        "All other workers",
        "None of the above",
    ],
    "Railways": [
        "Engineers",
        "Vehicles",
        "All other workers",
        "None of the above",
    ],
    "Seamen": [
        "Carpenters",
        "Passenger liners",
        "Cargo vessels",
        "Tankers",
        "Coasters",
        "Ferries",
        "None of the above",
    ],
    "Shipyards": [
        "Blacksmiths and their strikers",
        "Boilermakers",
        "Burners",
        "Carpenters",
        "Caulkers",
        "Drillers",
        "Furnacemen (platers) holders up",
        "Fitters",
        "Platers",
        "Plumbers",
        "Riveters",
        "Sheet iron workers",
        "Shipwrights",
        "Tubers",
        "Welders",
        "Labourers",
        "Apprentices",
        "Storekeepers",
        "All other workers",
        "None of the above",
    ],
    "Textiles and textile printing": [
        "Carders",
        "Carding engineers",
        "Overlookers in spinning mills",
        "Technicians in spinning mills",
        "All other workers",
        "None of the above",
    ],
    "Trades ancillary to engineering": [
        "Pattern makers",
        "Labourers",
        "Supervisory",
        "Unskilled workers",
        "Apprentices",
        "Storekeepers",
        "Motor mechanics in garage repair shop",
        "All other workers",
        "None of the above",
    ],
    "Vehicles": [
        "Builders",
        "Railway vehicle repairers",
        "Railway wagon lifters",
        "Railway vehicle painters",
        "Letterers",
        "Builders' and repairers' assistants",
        "All other workers",
        "None of the above",
    ],
    "Wood and furniture": [
        "Carpenters",
        "Cabinetmakers",
        "Joiners",
        "Wood carvers",
        "Woodcutting machinists",
        "Artificial limb makers (other than in wood)",
        "Organ builders",
        "Packaging case makers",
        "Coopers not providing their own tools",
        "Labourers",
        "Polishers",
        "Upholsterers",
        "All other workers",
        "None of the above",
    ],
    "🏥 Healthcare": [
        "Ambulance staff on active service",
        "Nurses",
        "Midwives",
        "Chiropodists",
        "Dental nurses",
        "Occupational",
        "Speech",
        "Physiotherapists",
        "Other therapists",
        "Assistants",
        "Phlebotomists",
        "Radiographers",
        "Plaster room orderlies",
        "Hospital porters",
        "Ward clerks",
        "Sterile supply workers",
        "Hospital domestics",
        "Hospital catering staff",
        "Laboratory staff",
        "Pharmacists",
        "Pharmacy assistants",
        "Maintenance workers",
        "Grounds staff",
        "Drivers",
        "Parking attendants",
        "Security guards",
        "Receptionists",
        "Other uniformed staff",
        "None of the above",
    ],
    "Armed Forces": [
        "Army - any rank",
        "Royal air force - any rank",
        "Royal marines - any rank",
        "Navy - any rank",
        "None of the above",
    ],
    "Fire Service": [
        "Uniformed firefighters",
        "Uniformed fire officers",
        "None of the above",
    ],
    "Police": [
        "Police officers up to chief inspector",
        "Community support officers",
        "Metropolitan police",
        "Other police employees (not special constables)",
        "None of the above",
    ],
    "Prison Service": [
        "Uniformed prison officers",
        "None of the above",
    ],
    "Docks and inland waterways": [
        "Dockers",
        "Dredger drivers",
        "Hopper Steerers",
        "All other workers",
        "None of the above",
    ],
    "Public Transport": [
        "Garage hands",
        "Garage cleaners",
        "Conductors",
        "Drivers",
        "None of the above",
    ],
    "Banks and building societies": [
        "Uniformed doormen",
        "Uniformed messanger",
        "None of the above",
    ],
};
