import { Menu, Dropdown } from "antd";
import closeIcon from "assets/images/header/cancel.svg";
import calendar from "assets/images/new-questionnaire/calendar.svg";
import connectbanks from "assets/images/new-questionnaire/connect_banks.svg";
import connectbanksDefault from "assets/images/new-questionnaire/connect_banks_not_selected.svg";
import dashboard from "assets/images/new-questionnaire/dash_board.svg";
import logout from "assets/images/new-questionnaire/logout.svg";
import selfAssessmentForm from "assets/images/new-questionnaire/self_assessment_form.svg";
import selfAssessmentFormDefault from "assets/images/new-questionnaire/self_assessment_form_not_selected.svg";
import support from "assets/images/new-questionnaire/support.svg";
import taxzapLogo from "assets/images/taxzap-logo-R.svg";
import NewQuestionnaireNameCircle from "components/common/NewQuestionnaireNameCircle";
import Logout from "components/inputs/Auth/Logout";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { next } from "redux/reducers/navigation";

import { getTaxYears } from "../../../../../constants/taxYears";
import { setCurrentDocumentYear } from "../../../../../redux/reducers/document";
import { RootState } from "../../../../../redux/store";

type DropDownProps = {
  showDropDown: boolean;
  showToggle: () => void;
  firstName: string;
  lastName: string;
  email: string;
  connectedBankAccounts: any;
};

const DropDownMobile = ({ firstName, lastName, email, showDropDown, showToggle, connectedBankAccounts }: DropDownProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentCategory, currentStep } = useAppSelector(state => state.navigation);
    const { currentDocumentYear, taxYears } = useAppSelector((state: RootState) => ({
        currentDocumentYear: state.document.currentDocumentYear,
        taxYears: state.taxYears.taxYears,
    }));
    const handleMenuClick = ({ key }: any) => {
        dispatch(setCurrentDocumentYear(key));
    };
    const menu = (
        <Menu
            onClick={handleMenuClick}
            selectedKeys={[currentDocumentYear]}
            items={getTaxYears(taxYears).map(year => ({
                key: year,
                label: `${year} Tax Year`,
            }))}
        />
    );

    const goToSection = (category: number, step: number) => {
        dispatch(next({ nextCategory: category, nextStep: step, nextQuestion: 0 }));
    };

    // ✅ Define menu items
    const menuItems = [
        {
            key: "homepage",
            label: "Self-assessment Form",
            icon: (
                <img
                    src={currentCategory === 1 && currentStep === 0 ? selfAssessmentForm : selfAssessmentFormDefault}
                    alt="Returns Icon"
                />
            ),
            onClick: () => {
                goToSection(1, 0);
                showToggle();
            },
            className: `client-navbar__dropdown-text ${(currentCategory !== 0 && currentStep !== 1) ? "active" : ""}`,
        },
        {
            key: "bank",
            label: (
                <div className="client-navbar__bank-accounts">
                    Connected Banks
                    <div className="client-navbar__connected-accounts-number ml20">
                        {connectedBankAccounts?.length || 0}
                    </div>
                </div>
            ),
            icon: (
                <img
                    src={currentCategory === 0 && currentStep === 1 ? connectbanks : connectbanksDefault}
                    alt="connect banks"
                />
            ),
            onClick: () => {
                goToSection(0, 1);
                showToggle();
            },
            className: `client-navbar__dropdown-text ${(currentCategory === 0 && currentStep === 1) ? "active" : ""}`,
        },
        {
            key: "dashboard",
            label: "Profile",
            icon: <img src={dashboard} alt="dashboard" />,
            onClick: () => navigate("/profile"),
            className: "client-navbar__dropdown-text",
        },
        {
            key: "support",
            label: "Support",
            icon: <img src={support} alt="support" />,
            onClick: () => navigate("/profile?tab=customer-support"),
            className: "client-navbar__dropdown-text",
            style: { display: "flex", alignItems: "center", gap: 0 },
        },
    ];

    return (
        <>
            {showDropDown && (
                <div className="client-navbar__overlay-menu">
                    <div className="client-navbar__parent">
                        <div className="client-navbar__notifications-header">
                            <img className="header-burger-menu__close" src={closeIcon} alt="close icon" onClick={showToggle} />
                        </div>
                        <div className="horizontal center">
                            <img
                                className="logo-questionnaire-navbar"
                                src={taxzapLogo}
                                alt="TaxZap Logo"
                            />
                        </div>
                        <div className="client-navbar__calendar">
                            <img src={calendar} alt="calendar" />
                            <Dropdown overlay={menu}>
                                <div className="client-navbar__calendar-title">{currentDocumentYear} Tax Year</div>
                            </Dropdown>
                        </div>
                        <Menu className="client-navbar__dropdown-mobile" items={menuItems} />
                    </div>

                    <div className="client-navbar__border">
                        <div className="client__navbar">
                            <NewQuestionnaireNameCircle firstName={firstName} lastName={lastName} />
                            <div className="ml6">
                                <div className="new-questionnaire__client-name">{`${firstName} ${lastName}`}</div>
                                <div className="new-questionnaire__client-email">{email}</div>
                            </div>
                        </div>
                        <div className="client-navbar__dropdown-text">
                            <img src={logout} alt="Log out" />
                            <Logout />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DropDownMobile;
