import "swiper/css";
import "swiper/css/pagination";

import { FC } from "react";

import ellipse from "assets/images/new-questionnaire/ellipse.svg";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface BankAccountCardsProps {
    accounts: any[];
    horizontalShift?: string;
}

const BankAccountCards: FC<BankAccountCardsProps> = ({ accounts, horizontalShift }) => {
    if (!accounts.length) return null;

    return (
        <div className="new-questionnaire__accounts-cards-container" style={horizontalShift ? { transform: `translate(${horizontalShift}px, 0px)` } : {}}>
            <Swiper
                {...({ slidesPerView: 1 } as any)}
                {...({ spaceBetween: 3 } as any)}
                pagination={{
                    dynamicBullets: true,
                }}
                loop={true}
                modules={[Pagination]}
            >
                {accounts.map((account, index) => (
                    <SwiperSlide key={index}>
                        <div className="new-questionnaire__account-card-container">
                            <div className="new-questionnaire__account-card">
                                <div className="new-questionnaire__account-info">
                                    <div className="new-questionnaire__account-info-column">
                                        <div className="new-questionnaire__account-name">{account.accountName || "Bank Account"}</div>
                                        <div className="new-questionnaire__account-iban mt8">
                                            {account.iban}
                                        </div>
                                        <div className="new-questionnaire__account-description mt8">
                                            <div className="new-questionnaire__account-description-text">
                                                {account.region}
                                            </div>
                                            <img src={ellipse} height={6} alt="ellipse" className="dblock mr6 ml6"/>
                                            <div className="new-questionnaire__account-description-text">
                                                {account.ownerName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="new-questionnaire__account-logo">
                                        <img src={account.logo} height={62} alt={account.accountName} className="dblock"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default BankAccountCards;
