import { taxZapGoogleReviewsLink } from "constants/reviews";

import { useContext, useEffect, useRef, useState } from "react";

import { Row, Col, Typography } from "antd";
import { AppContext } from "App";
import backIcon from "assets/images/chevron-down.svg";
import googleIcon from "assets/images/google.svg";
import quotesLeft from "assets/images/quotes-left-grey.svg";
import quotesRight from "assets/images/quotes-right-grey.svg";
import verfiedIcon from "assets/images/verified.svg";
import { handleResponse } from "services/reviews";
import { Swiper, SwiperSlide } from "swiper/react";
import { IReview } from "types/reviews";

import { getStars } from "../HomeNew/components/getStars";

const WhatCustomersSaying = () => {
    const { maxWidth, isMobile } = useContext(AppContext);
    const swiperRef = useRef<any>(null);
    const [reviews, setReviews] = useState<IReview[]>([]);

    useEffect(() => {
        handleResponse().then(response => setReviews(response));
    }, []);

    const handleNextSlide = () => {
        swiperRef.current?.slideNext();
    };

    const handlePrevSlide = () => {
        swiperRef.current?.slidePrev();
    };

    return (
        <>
            {isMobile
                ? <div style={{ maxWidth: maxWidth, padding: isMobile ? "24px 0px" : "24px" }}>
                    <Row style={{ width: "375px", margin: "40px auto" }}>
                        <div style={{ width: "100%" }}>
                            <Swiper
                                {...({ slidesPerView: 1.1 } as any)}
                                {...({ spaceBetween: 1 } as any)}
                                onSwiper={(swiper: any) => { swiperRef.current = swiper; }}
                            >
                                {reviews.map((review:IReview, index:number) => (
                                    <SwiperSlide key={index}>
                                        <div className="customers-saying-card-new" style={{ marginLeft: "20px" }}>
                                            <Row justify="space-between" style={{ width: "100%", alignItems: "center" }}>
                                                <Row className="review-author">
                                                    <img className="review-author-icon" src={review.icon} alt={review.author}/>
                                                    <Typography className="review-author-text">
                                                        {review.author}
                                                    </Typography>
                                                </Row>
                                                <a
                                                    href={taxZapGoogleReviewsLink}
                                                    className="content-rating-link"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src={googleIcon} alt="google" width={24} height={24} />
                                                </a>
                                            </Row>
                                            <Row style={{ marginTop: "10px" }}>
                                                {getStars(review.rating)}
                                                <img src={verfiedIcon} alt="verfiedIcon" width={20} height={20} style={{ marginLeft: "10px" }} />
                                                <div className="uk-review-text"> Verified User</div>
                                            </Row>
                                            <Col className="review-content-wrapper">
                                                <Row justify="start">
                                                    <img src={quotesRight} alt="quotes-right" width={24} height={24}/>
                                                </Row>
                                                <Typography.Paragraph className="review-content" ellipsis={{ rows: 4, expandable: true, symbol: "more" }}>
                                                    {review.description}
                                                </Typography.Paragraph>
                                                <Row justify="end">
                                                    <img src={quotesLeft} alt="quotes-left" width={24} height={24}/>
                                                </Row>
                                            </Col>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </Row>
                </div>
                : <div className={"uk-review-block horizontal-center"} style={{ marginTop: -4 }}>
                    <div style={{ maxWidth: maxWidth, padding: "64px 100px" }}>
                        <Row justify="end" style={{ margin: "0px 50px 0px 0px" }}>
                            <div className="swiper-navigation-button" onClick={handlePrevSlide}>
                                <img src={backIcon} alt="back" width={20} height={20} />

                            </div>
                            <div className="swiper-navigation-button" onClick={handleNextSlide} style={{ marginLeft: "24px" }}>
                                <img src={backIcon} alt="back" width={20} height={20} style={{ transform: "rotate(180deg)" }}/>
                            </div>
                        </Row>
                        <Row className={"horizontal-center"} style={{ margin: "12px 0px 0px 0px" }}>
                            <div style={{ width: "100%" }}>
                                <Swiper
                                    {...({ slidesPerView: 3 } as any)}
                                    onSwiper={swiper => { swiperRef.current = swiper; }}
                                >
                                    {reviews.map((review:IReview, index:number) => (
                                        <SwiperSlide key={index}>
                                            <div className="customers-saying-card-new" style={{ marginRight: "20px" }}>
                                                <Row justify="space-between" style={{ width: "100%", alignItems: "center" }}>
                                                    <Row className="review-author">
                                                        <img className="review-author-icon" src={review.icon} alt={review.author}/>
                                                        <Typography className="review-author-text" >
                                                            {review.author}
                                                        </Typography >
                                                    </Row>
                                                    <a
                                                        href={taxZapGoogleReviewsLink}
                                                        className="content-rating-link"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img src={googleIcon} alt="google" width={24} height={24} />
                                                    </a>
                                                </Row>
                                                <Row style={{ marginTop: "10px" }}>
                                                    {getStars(review.rating)}
                                                    <img src={verfiedIcon} alt="verfiedIcon" width={20} height={20} style={{ marginLeft: "20px" }} />
                                                    <div className="uk-review-text"> Verified User</div>
                                                </Row>
                                                <Col className="review-content-wrapper">
                                                    <Row justify="start">
                                                        <img src={quotesRight} alt="quotes-right" width={24} height={24}/>
                                                    </Row>
                                                    <Typography.Paragraph className="review-content" ellipsis={{ rows: 5, expandable: true, symbol: "more" }}>
                                                        {review.description}
                                                    </Typography.Paragraph>
                                                    <Row justify="end">
                                                        <img src={quotesLeft} alt="quotes-left" width={24} height={24}/>
                                                    </Row>
                                                </Col>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </Row>
                    </div>
                </div>
            }
        </>
    );
};

export default WhatCustomersSaying;
