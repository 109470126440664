import { api } from "./connection/api";
import { IOrderDiscountRequest, IOrderTokenResponse, IPayOrderRequest, IPaymentStatus } from "../types/payments";

const ENDPOINT_PREFIX = "payments";

export const paymentApi = api.injectEndpoints({
    endpoints: build => ({
        getOrder: build.mutation<IOrderTokenResponse, void>({
            query() {
                return {
                    url: `${ENDPOINT_PREFIX}/getOrder`,
                    method: "GET",
                };
            },
        }),
        getPaymentStatus: build.mutation<IPaymentStatus, void>({
            query() {
                return {
                    url: `${ENDPOINT_PREFIX}/getPaymentStatus`,
                    method: "GET",
                };
            },
        }),
        payOrder: build.mutation<void, IPayOrderRequest>({
            query(body: IPayOrderRequest) {
                return {
                    url: `${ENDPOINT_PREFIX}/payOrder`,
                    method: "POST",
                    body: body,
                };
            },
        }),
        applyDiscount: build.mutation<IOrderTokenResponse, IOrderDiscountRequest>({
            query(body: IOrderDiscountRequest) {
                return {
                    url: `${ENDPOINT_PREFIX}/applyDiscount`,
                    method: "POST",
                    body: body,
                };
            },
        }),
    }),
});

export const {
    useGetOrderMutation,
    usePayOrderMutation,
    useApplyDiscountMutation,
    useGetPaymentStatusMutation,
} = paymentApi;
