import { UK_ROUTES } from "constants/routes";

import { useContext } from "react";

import { Col, Row } from "antd";
import { AppContext } from "App";
import facebook from "assets/images/facebook.svg";
import instagram from "assets/images/instagram.svg";
import britainFlag from "assets/images/regions/britain_24x24.png";
import irelandFlag from "assets/images/regions/ireland_24x24.png";
import taxzapLogo from "assets/images/taxzap-logo-R-white.svg";
import tiktok from "assets/images/tiktok.svg";
import { useLocalStorage } from "hooks/helpers/useLocalStorage";
import { NavLink, Link } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import { resetAnswers } from "redux/reducers/answers";
import { logout } from "redux/reducers/auth";
import { resetNavigation } from "redux/reducers/navigation";
import { resetTaxProfile } from "redux/reducers/taxProfile";
import { IClientState, ProgressT } from "types/questionnaire";

import { resetDocument } from "../../../redux/reducers/document";

const Footer = () => {
    const {
        isMobile,
        maxWidth,
    } = useContext(AppContext);
    const dispatch = useAppDispatch();
    const [, setLocalClientData] = useLocalStorage<IClientState | null>("clientData", null);
    const [, setTotalProgress] = useLocalStorage<ProgressT>("new_questionnaire_total_progress", {});

    return <div className={"uk-footer-block horizontal-center"}>
        <div style={{ maxWidth: maxWidth, padding: isMobile ? "24px" : "80px 100px 120px", minWidth: isMobile ? "auto" : "1300px" }}>
            <div>
                <img
                    src={taxzapLogo}
                    alt={"checked"}
                    width={300}
                />
            </div>

            <div className={"mt60"} style={{ overflow: "hidden" }}>
                <Row gutter={[64, 32]}>
                    <Col sm={24} xs={24} md={24} lg={24} xl={6}>
                        <div className={"uk-footer-header"}>
                        TaxZap in Your Area
                        </div>

                        <div className={"mt30"}>
                            {[{
                                id: "britain",
                                label: "TaxZap in UK",
                                icon: britainFlag,
                                to: `${process.env.REACT_APP_BASE_URL}/uk`,
                            }, {
                                id: "ireland",
                                label: "TaxZap in Ireland",
                                icon: irelandFlag,
                                to: `${process.env.REACT_APP_BASE_URL}/ie`,
                                shouldLogout: true,
                            }].map(item => {
                                return <div className={"mt20 horizontal-left"} key={item.id}>
                                    <div className={"center"}>
                                        <img
                                            className={"pointer"}
                                            src={item.icon}
                                            alt={item.id}
                                            width={20}
                                        />
                                    </div>
                                    <div className={"ml10"}>
                                        <NavLink
                                            to={item.to}
                                            className={"uk-footer-link"}
                                            onClick={() => {
                                                if (item.shouldLogout) {
                                                    setTotalProgress({});
                                                    setLocalClientData(null);
                                                    dispatch(resetAnswers());
                                                    dispatch(resetTaxProfile());
                                                    dispatch(resetNavigation());
                                                    dispatch(resetDocument());
                                                    dispatch(logout());
                                                }
                                            }}
                                        >
                                            {item.label}
                                        </NavLink>
                                    </div>
                                </div>;
                            })}
                        </div>
                    </Col>
                    <Col sm={24} xs={24} md={24} lg={24} xl={6}>
                        <div className={"uk-footer-header"}>
                            Get Support
                        </div>

                        <div className={"mt30"}>
                            {[{
                                id: "faqs",
                                label: "FAQs",
                                to: UK_ROUTES.faqs,
                            }, {
                                id: "contact",
                                label: "support@taxzap.co.uk",
                                to: "mailto:support@taxzap.co.uk",
                            }].map(item => {
                                return <div className={"mt20"} key={item.id}>
                                    <Link
                                        to={item.to}
                                        className={"uk-footer-link"}
                                    >
                                        {item.label}
                                    </Link>
                                </div>;
                            })}
                        </div>
                    </Col>
                    <Col sm={24} xs={24} md={24} lg={24} xl={6}>
                        <div className={"uk-footer-header"}>
                            Follow Us
                        </div>

                        <div className={"mt30"}>
                            {[{
                                id: "facebook",
                                label: "Facebook",
                                icon: facebook,
                                to: "https://www.facebook.com/TaxZap.ie/",
                            }, {
                                id: "instagram",
                                label: "Instagram",
                                icon: instagram,
                                to: "https://www.instagram.com/taxzap/",
                            }, {
                                id: "tiktok",
                                label: "Tiktok",
                                icon: tiktok,
                                to: "https://www.tiktok.com/@taxzap",
                            }].map(item => {
                                return <div className={"mt20 horizontal-left"} key={item.id}>
                                    <div className={"center"}>
                                        <img
                                            className={"pointer"}
                                            src={item.icon}
                                            alt={item.label}
                                            width={20}
                                        />
                                    </div>
                                    <div className={"ml10"}>
                                        <NavLink
                                            to={item.to}
                                            className={"uk-footer-link"}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {item.label}
                                        </NavLink>
                                    </div>
                                </div>;
                            })}
                        </div>
                    </Col>
                    <Col sm={24} xs={24} md={24} lg={24} xl={6}>
                        <div className={"uk-footer-header"}>
                            Legal Stuff
                        </div>

                        <div className={"mt30"}>
                            {[{
                                id: "privacy",
                                label: "Privacy Policy",
                                to: UK_ROUTES.privacyPolicy,
                            }, {
                                id: "terms",
                                label: "Terms & Conditions",
                                to: UK_ROUTES.tcs,
                            }].map(item => {
                                return <div className={"mt20"} key={item.id}>
                                    <Link
                                        to={item.to}
                                        className={"uk-footer-link"}
                                    >
                                        {item.label}
                                    </Link>
                                </div>;
                            })}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>

    </div>;
};

export default Footer;
