import "styles/buttonFooter.less";
import { FC, useContext } from "react";

import { AppContext } from "App";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

import StartButtonNew from "../StartButtonNew";

interface ButtonFooterMarketingProps {
}

const ButtonFooterMarketing: FC<ButtonFooterMarketingProps> = () => {
    const { isMobile } = useContext(AppContext);
    const isKeyboardOpen = useDetectKeyboardOpen();

    if (!isMobile || isKeyboardOpen) return null;

    return (
        <div className={"button-footer-marketing"}>
            <>
                <div>
                    <StartButtonNew label="Swipe to Start Now" width="350px"/>
                </div>
            </>

        </div>
    );
};

export default ButtonFooterMarketing;
