import "styles/banking/selectBank.less";
import React, { useContext, useState } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Typography, Input, List } from "antd";
import { AppContext } from "App";
import successfulConnection from "assets/animation/successful-connection.json";
import done from "assets/images/checkmark.svg";
import boltWhiteImg from "assets/images/new-questionnaire/onboarding/bolt-white.svg";
import bankFrame from "assets/images/payment/bank_frame.svg";
import BankAccountMode from "components/modals/Payment/BankAccountMode";
import ConnectBankError from "components/modals/Payment/ConnectBankError";
import Lottie from "lottie-react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { setCountryBanks } from "redux/reducers/countries";
import { RootState } from "redux/store";
import { usePushBankQuestionnaireMutation } from "services/connectBankAccount";
const { Text } = Typography;

interface Bank {
    _id: string;
    name: string;
    logo: string;
}

type SelectBankAccountProps = {
    closeConnectModal: () => void;
    openConnectModal: () => void;
};

export const SelectBankAccount: React.FC<SelectBankAccountProps> = ({ closeConnectModal, openConnectModal }) => {
    const { isMobile } = useContext(AppContext);
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [selectedBank, setSelectedBank] = useState<string | null>(null);
    const [paymentModeModal, setPaymentModeModal] = useState<boolean>(false);
    const [, setPageLoading] = useState<boolean>(false);
    const [connecting, setConnecting] = useState<boolean>(false);
    const [pushBank] = usePushBankQuestionnaireMutation();
    const dispatch = useAppDispatch();

    const countryRegion = useAppSelector((state: RootState) => state.bank.countryRegion);

    const filteredBanks = countryRegion.filter((bank: Bank) =>
        bank.name.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(e.target.value);
        setSelectedBank(null);
    };

    const handleFinish = (id: string) => {
        setSelectedBank(id);
        handleRegionalBank(id);
    };
    function handleRegionalBank(Id: string) {
        setPageLoading(true);
        pushBank({ Id: Id, bankDetailsOnly: false })
            .unwrap()
            .then(data => {
                if (data && data?.authLink) {
                    dispatch(setCountryBanks(data));

                    if (isMobile) {
                        setPaymentModeModal(false);
                        window.location.href = data.authLink;
                        setShowErrorModal(false);
                    } else {
                        setPaymentModeModal(true);
                        setShowErrorModal(false);
                    }
                } else {
                    console.error("Invalid data received from API:", data);
                }
                return data;
            })
            .catch(error => {
                console.error("Error fetching banks:", error);
                setShowErrorModal(true);
                closeConnectModal();
            })
            .finally(() => {
                setPageLoading(false);
            });
    }

    const searchBankLength = filteredBanks.length;

    if (countryRegion.length === 0) return null;

    return (
        <div className="select-bank__container">
            {connecting ? (
                <div className="select-bank__connect-success__container">

                    <Typography className="select-bank__connect-success__title center">Thank you for connecting your bank account</Typography>

                    <Lottie animationData={successfulConnection} loop={true} className="select-bank__success-animation"/>

                    <div
                        className="select-bank__continue-button mt20 mAuto"
                        onClick={() => {
                            closeConnectModal();
                            setConnecting(false);
                        }}
                    >
                        <img src={boltWhiteImg} alt="Connect Bank Account" className="mr6" />
                        <div className="select-bank__continue-button-text">Continue</div>
                    </div>

                </div>
            ) : (
                <div className="select-bank__list-container">
                    <div className="select-bank__sticky-header">
                        <Input
                            size="large"
                            placeholder="Search your bank"
                            prefix={<SearchOutlined style={{ width: "24px" }} />}
                            onChange={handleInputChange}
                            className="select-bank__search"
                        />
                        <Text
                            className="horizontal-center mt10 select-bank__search"
                            type="secondary"
                        >
                            {!searchKeyword
                                ? "All Banks (A-Z)"
                                : filteredBanks.length > 0
                                    ? `${searchBankLength} results`
                                    : ""}
                        </Text>
                    </div>

                    <List
                        className="p10"
                        itemLayout="horizontal"
                        dataSource={filteredBanks}
                        renderItem={item => (
                            <List.Item
                                className={`select-bank__list-item ${
                                    selectedBank === item._id ? "selected" : ""
                                }`}
                                onClick={() => handleFinish(item._id)}
                            >
                                <img
                                    className="select-bank__list-item__logo "
                                    src={item.logo || bankFrame}
                                    alt="logo"
                                    onError={e => {
                                        e.currentTarget.src = bankFrame;
                                    }}
                                />
                                <div
                                    className="select-bank__list-item__title "
                                    style={selectedBank === item._id ? { maxWidth: "215px" } : {}}
                                >
                                    {item.name}
                                </div>
                                {selectedBank === item._id && (
                                    <img src={done} width={20} alt="check" className="p8" />
                                )}
                            </List.Item>
                        )}
                    />
                </div>

            )}
            <BankAccountMode
                paymentModeModal={paymentModeModal}
                setPaymentModeModal={setPaymentModeModal}
                closeConnectModal={closeConnectModal}
            />
            {openConnectModal &&
            <ConnectBankError
                showErrorModal={showErrorModal}
                setShowErrorModal={setShowErrorModal}
                closeConnectModal={closeConnectModal}
                openConnectModal={openConnectModal}
            />}
        </div>
    );
};
