import { UK_ROUTES } from "constants/routes";

import { useCallback, useContext } from "react";

import { Button, Col, Menu, Row } from "antd";
import { AppContext } from "App";
import burgerIcon from "assets/images/header/menu-alt-2.svg";
import britainFlag from "assets/images/regions/united-kingdom.svg";
import taxzapLogo from "assets/images/taxzap-logo-R.svg";
import LoginButton from "components/basic/LogInButton";
import CountrySelect from "components/modals/CountrySelect";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { scrollToTop } from "utils/common";

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {
        isOpened: isCountrySelectorOpened,
        open: handleOpenCountrySelect,
        close: handleCloseCountrySelect,
    } = useModalOpener();

    const {
        isMobile,
        isAuth,
        maxWidth,
        toggleFormScale,
    } = useContext(AppContext);

    const handleStart = useCallback(
        () => {
            if (location.pathname === "/") {
                toggleFormScale(true);
                scrollToTop();
            } else {
                navigate("/");
                scrollToTop();
                setTimeout(() => {
                    toggleFormScale(true);
                }, 200);
            }
        },
        [location.pathname, navigate, toggleFormScale]
    );

    return <div className={"header-wrapper horizontal-center"}>
        <div style={{ maxWidth: maxWidth, width: "100%" }}>
            <Row style={{ height: isMobile ? 76 : 96, justifyContent: "center" }}>
                <Col
                    sm={{ span: 12, order: 2 }}
                    xs={{ span: 12, order: 2 }}
                    md={{ span: 12, order: 2 }}
                    lg={{ span: 12, order: 2 }}
                    xl={{ span: 4, order: 1 }}
                    className={"center"}
                >
                    <img
                        className="logo"
                        src={taxzapLogo}
                        alt="TaxZap Logo"
                        onClick={() => {
                            navigate("/");
                            scrollToTop();
                        }}
                    />
                </Col>
                <Col
                    sm={{ span: 4, order: 1 }}
                    xs={{ span: 4, order: 1 }}
                    md={{ span: 4, order: 1 }}
                    lg={{ span: 3, order: 1 }}
                    xl={{ span: 12, order: 2 }}
                    className={"horizontal-left"}
                >
                    <Menu
                        theme="light"
                        mode="horizontal"
                        className={"horizontal-left"}
                        overflowedIndicator={
                            <div
                                style={{
                                    padding: "20px 10px",
                                    display: "flex",
                                }}
                            >
                                <img
                                    height={24}
                                    width={24}
                                    src={burgerIcon}
                                    alt="burger"
                                />
                            </div>
                        }
                        style={{
                            border: "0px",
                            minWidth: 0,
                            flex: "auto",
                        }}
                    >
                        <Menu.Item key="how-it-works">
                            <HashLink smooth to={UK_ROUTES.howItWorks} className="uk-menu-item">
                                How it Works
                            </HashLink>
                        </Menu.Item>

                        <Menu.Item key="why-taxzap">
                            <HashLink smooth to={UK_ROUTES.whyTaxzap} className="uk-menu-item">
                                Why TaxZap
                            </HashLink>
                        </Menu.Item>

                        <Menu.Item key="pricing">
                            <Link to={UK_ROUTES.pricing} className="uk-menu-item">
                                Pricing
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="faqs">
                            <Link to={UK_ROUTES.faqs} className="uk-menu-item">
                                FAQs
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="contact">
                            <HashLink to={UK_ROUTES.contact} className="uk-menu-item">
                                Contact
                            </HashLink>
                        </Menu.Item>
                    </Menu>
                </Col>
                <Col
                    sm={{ span: 8, order: 3 }}
                    xs={{ span: 8, order: 3 }}
                    md={{ span: 8, order: 3 }}
                    lg={{ span: 8, order: 3 }}
                    xl={{ span: 8, order: 3 }}
                    className={"horizontal-right"}
                >
                    {!isMobile && <div>
                        <img
                            className={"pointer uk-flag mt6"}
                            src={britainFlag}
                            alt={"britain"}
                            width={24}
                            height={24}
                            onClick={handleOpenCountrySelect}
                        />
                    </div>}
                    {!isMobile && <div className={"ml20"}>
                        <Button
                            size={"large"}
                            shape={"round"}
                            type={"link"}
                            className={"uk-link-button"}
                            onClick={() => {
                                navigate("/auth");
                            }}
                        >
                            <span className={"uk-link-button-label"}>{isAuth ? "Profile" : "Login"}</span>
                        </Button>
                    </div>}
                    {!isMobile ? <div className={"ml20 mr40"}>
                        {!isAuth &&
                            <Button
                                size={"large"}
                                shape={"round"}
                                type={"primary"}
                                className={"uk-button-new"}
                                onClick={handleStart}
                            >
                                Start Tax Return
                            </Button>
                        }
                    </div> : <div style={{ marginRight: -30 }}>
                        <LoginButton label={isAuth ? "Profile" : "Login"}/>
                    </div>}
                </Col>
            </Row>
        </div>

        <CountrySelect
            isCountrySelectorOpened={isCountrySelectorOpened}
            handleClose={handleCloseCountrySelect}
        />
    </div>;
};

export default Header;
