import React from "react";

import { Card } from "antd";
import infoIcon from "assets/images/new-questionnaire/info_icon.svg";
import { IUser } from "types/auth";
import { formatCurrency } from "utils/common";

type TaxSummaryContentProps = {
    user: IUser;
    taxData: any;
    isVisible: boolean;
    handleClick: () => void;
};

const TaxSummaryContent: React.FC<TaxSummaryContentProps> = ({
    user,
    taxData,
    isVisible,
    handleClick,
}) => {
    const totalTaxDuePlus31Jan = formatCurrency(Math.max(0,
        (taxData?.paymentsOnAccount31stJan?.value ?? 0) + (taxData?.totalTaxLiability?.value ?? 0))
    );

    const cgtBasicRateTaxableAmount = formatCurrency(
        (taxData?.["cgtOnListed SharesBasicRate"]?.taxableAmount ?? 0) +
        (taxData?.["cgtOnUnlisted SharesBasicRate"]?.taxableAmount ?? 0) +
        (taxData?.["cgtOnCrypto-currencyBasicRate"]?.taxableAmount ?? 0) +
        (taxData?.["cgtOnOther AssetsBasicRate"]?.taxableAmount ?? 0)
    );
    const cgtBasicRateTax = formatCurrency(
        (taxData?.["cgtOnListed SharesBasicRate"]?.value ?? 0) +
        (taxData?.["cgtOnUnlisted SharesBasicRate"]?.value ?? 0) +
        (taxData?.["cgtOnCrypto-currencyBasicRate"]?.value ?? 0) +
        (taxData?.["cgtOnOther AssetsBasicRate"]?.value ?? 0)
    );
    const cgtBasicRateTaxRate = taxData?.["cgtOnListed SharesBasicRate"]?.taxedAt || taxData?.["cgtOnUnlisted SharesBasicRate"]?.taxedAt || taxData?.["cgtOnCrypto-currencyBasicRate"]?.taxedAt || taxData?.["cgtOnOther AssetsBasicRate"]?.taxedAt;

    const cgtBasicRateDisplay = cgtBasicRateTaxRate !== undefined
        ? `${cgtBasicRateTaxRate * 100}%`
        : "";

    const cgtHigherRateTaxableAmount = formatCurrency(
        (taxData?.["cgtOnListed SharesHigherRate"]?.taxableAmount ?? 0) +
        (taxData?.["cgtOnUnlisted SharesHigherRate"]?.taxableAmount ?? 0) +
        (taxData?.["cgtOnCrypto-currencyHigherRate"]?.taxableAmount ?? 0) +
        (taxData?.["cgtOnOther AssetsHigherRate"]?.taxableAmount ?? 0)
    );
    const cgtHigherRateTax = formatCurrency(
        (taxData?.["cgtOnListed SharesHigherRate"]?.value ?? 0) +
        (taxData?.["cgtOnUnlisted SharesHigherRate"]?.value ?? 0) +
        (taxData?.["cgtOnCrypto-currencyHigherRate"]?.value ?? 0) +
        (taxData?.["cgtOnOther AssetsHigherRate"]?.value ?? 0)
    );

    const cgtHigherRateTaxRate = taxData?.["cgtOnListed SharesHigherRate"]?.taxedAt || taxData?.["cgtOnUnlisted SharesHigherRate"]?.taxedAt || taxData?.["cgtOnCrypto-currencyHigherRate"]?.taxedAt || taxData?.["cgtOnOther AssetsHigherRate"]?.taxedAt;
    const cgtHigherRateDisplay = cgtHigherRateTaxRate !== undefined
        ? `${cgtHigherRateTaxRate * 100}%`
        : "";

    const cgtResidentialBasicRateTaxableAmount = formatCurrency(
        (taxData?.["cgtOnResidential PropertyBasicRate"]?.taxableAmount ?? 0));
    const cgtResidentialBasicRateTax = formatCurrency(
        (taxData?.["cgtOnResidential PropertyBasicRate"]?.value ?? 0)
    );
    const cgtResidentialBasicTaxRate = taxData?.["cgtOnResidential PropertyBasicRate"]?.taxedAt;
    const cgtResidentialBasicDisplay = cgtResidentialBasicTaxRate !== undefined
        ? `${cgtResidentialBasicTaxRate * 100}%`
        : "";

    const cgtResidentialHigherRateTaxableAmount = formatCurrency(
        (taxData?.["cgtOnResidential PropertyHigherRate"]?.taxableAmount ?? 0));
    const cgtResidentialHigherRateTax = formatCurrency(
        (taxData?.["cgtOnResidential PropertyigherRate"]?.value ?? 0)
    );
    const cgtResidentialHigherTaxRate = taxData?.["cgtOnResidential PropertyigherRate"]?.taxedAt;
    const cgtResidentialHigherDisplay = cgtResidentialHigherTaxRate !== undefined
        ? `${cgtResidentialHigherTaxRate * 100}%`
        : "";

    const taxResidency = (taxData?.isScottishTaxpayer.value === true) ? "Scotland" : "England/Wales/Northern Ireland";
    return (
        <div className="flex-column gap10">
            <Card>
                <div className="tax-summary__client-details-header">CLIENT DETAILS</div>
                <div className="tax-summary__client-details">
                    <div className="tax-summary__bold-text">Name</div>
                    <div className="tax-summary__client-details__value">
                        {user?.info.firstName} {user?.info.lastName}
                    </div>
                </div>
                <div className="tax-summary__client-details">
                    <div className="tax-summary__bold-text">UTR</div>
                    <div className="tax-summary__client-details__value">
                        {user.utr || "N/A"}
                    </div>
                </div>
                <div className="tax-summary__client-details">
                    <div className="tax-summary__bold-text">Tax Residency</div>
                    <div className="tax-summary__client-details__value">
                        {taxResidency}
                    </div>
                </div>
            </Card>
            {[
                taxData?.totalPAYEIncome?.value,
                taxData?.netSelfEmploymentIncome?.value,
                taxData?.netCISIncome?.value,
                taxData?.totalPensionsIncome?.value,
                taxData?.netRentalIncome?.value,
                taxData?.netInterestIncome?.value,
                taxData?.netDividendsIncome?.value,
                taxData?.totalNetIncome?.value,
            ].some(val => typeof val === "number" && val !== 0) && (
                <Card>
                    <div className="tax-summary__client-details-header">INCOME BREAKDOWN</div>

                    {typeof taxData?.totalPAYEIncome?.value === "number" &&
                        taxData.totalPAYEIncome.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Employment Income</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.totalPAYEIncome.value)}
                            </div>
                        </div>
                    )}

                    {typeof taxData?.netSelfEmploymentIncome?.value === "number" &&
                        taxData.netSelfEmploymentIncome.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Self-Employment Profit</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.netSelfEmploymentIncome.value)}
                            </div>
                        </div>
                    )}

                    {typeof taxData?.netCISIncome?.value === "number" &&
                        taxData.netCISIncome.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">CIS Employment Profit</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.netCISIncome.value)}
                            </div>
                        </div>
                    )}

                    {typeof taxData?.totalPensionsIncome?.value === "number" &&
                        taxData.totalPensionsIncome.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Pensions</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.totalPensionsIncome.value)}
                            </div>
                        </div>
                    )}

                    {typeof taxData?.netRentalIncome?.value === "number" &&
                        taxData.netRentalIncome.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Property Rental</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.netRentalIncome.value)}
                            </div>
                        </div>
                    )}

                    {typeof taxData?.netInterestIncome?.value === "number" &&
                        taxData.netInterestIncome.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Interest Income</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.netInterestIncome.value)}
                            </div>
                        </div>
                    )}

                    {typeof taxData?.netDividendsIncome?.value === "number" &&
                        taxData.netDividendsIncome.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Dividends</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.netDividendsIncome.value)}
                            </div>
                        </div>
                    )}

                    {typeof taxData?.totalNetIncome?.value === "number" &&
                        taxData.totalNetIncome.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Total Net Income</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.totalNetIncome.value)}
                            </div>
                        </div>
                    )}
                </Card>
            )}

            {[
                taxData?.personalAllowanceAfterTaper?.value,
                taxData?.marriageAllowanceTransferReceived?.value,
                taxData?.blindPersonsAllowance?.value,
                taxData?.usedPersonalSavingsAllowance?.value,
                taxData?.usedDividendAllowance?.value,
            ].some(val => typeof val === "number" && val !== 0) && (
                <Card>
                    <div className="tax-summary__client-details-header">ALLOWANCES</div>

                    {typeof taxData?.personalAllowanceAfterTaper?.value === "number" &&
                        taxData.personalAllowanceAfterTaper.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Personal Allowance</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.personalAllowanceAfterTaper.value)}
                            </div>
                        </div>
                    )}

                    {typeof taxData?.marriageAllowanceTransferReceived?.value === "number" &&
                        taxData.marriageAllowanceTransferReceived.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Marriage Allowance</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.marriageAllowanceTransferReceived.value)}
                            </div>
                        </div>
                    )}

                    {typeof taxData?.blindPersonsAllowance?.value === "number" &&
                        taxData.blindPersonsAllowance.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Blind Person’s Allowance</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.blindPersonsAllowance.value)}
                            </div>
                        </div>
                    )}

                    {typeof taxData?.usedPersonalSavingsAllowance?.value === "number" &&
                        taxData.usedPersonalSavingsAllowance.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Savings Allowance</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.usedPersonalSavingsAllowance.value)}
                            </div>
                        </div>
                    )}

                    {typeof taxData?.usedDividendAllowance?.value === "number" &&
                        taxData.usedDividendAllowance.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Dividends Allowance</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.usedDividendAllowance.value)}
                            </div>
                        </div>
                    )}
                </Card>
            )}

            {[
                taxData?.giftAidRelief?.value,
                taxData?.pensionContributionRelief?.value,
                taxData?.totalVentureCapitalSchemeRelief?.value,
            ].some(val => typeof val === "number" && val !== 0) && (
                <Card>
                    <div className="tax-summary__client-details-header">RELIEFS</div>

                    {typeof taxData?.giftAidRelief?.value === "number" &&
                        taxData.giftAidRelief.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Gift Aid</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.giftAidRelief.value)}
                            </div>
                        </div>)}

                    {typeof taxData?.pensionContributionRelief?.value === "number" &&
                        taxData.pensionContributionRelief.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Pension Contribution Relief</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.pensionContributionRelief.value)}
                            </div>
                        </div>
                    )}

                    {typeof taxData?.totalVentureCapitalSchemeRelief?.value === "number" &&
                        taxData.totalVentureCapitalSchemeRelief.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Venture Capital Schemes Relief</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.totalVentureCapitalSchemeRelief.value)}
                            </div>
                        </div>)}
                </Card>
            )}

            <Card>
                <div className="tax-summary__client-details-header">TAX CALCULATIONS</div>

                {taxData?.isScottishTaxpayer?.value ? (
                    <>
                        <div className="tax-summary__client-details-text">
                            Non-Savings Income{" "}
                            <img src={infoIcon} alt="info icon" onClick={handleClick} />
                            {isVisible && (
                                <p className="fs14">(Self Employment, PAYE, Rental, Pension, CIS)</p>
                            )}
                        </div>

                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Taxable Income</div>
                            <div className="tax-summary__client-details__value">
                                <div>{formatCurrency(taxData?.taxableNonSavings?.value)}</div>
                            </div>
                        </div>

                        {[
                            {
                                label: "Scottish Income Tax (Starter Rate)",
                                data: taxData?.starterRateTaxScotland,
                            },
                            {
                                label: "Scottish Income Tax (Basic Rate)",
                                data: taxData?.basicRateTaxScotland,
                            },
                            {
                                label: "Scottish Income Tax (Intermediate Rate)",
                                data: taxData?.intermediateRateTaxScotland,
                            },
                            {
                                label: "Scottish Income Tax (Higher Rate)",
                                data: taxData?.higherRateTaxScotland,
                            },
                            {
                                label: "Scottish Income Tax (Advanced Rate)",
                                data: taxData?.advancedRateTaxScotland,
                            },
                            {
                                label: "Scottish Income Tax (Top Rate)",
                                data: taxData?.topRateTaxScotland,
                            },
                        ]
                            .filter(item => typeof item.data?.value === "number" && item.data?.value !== 0)
                            .map((item, i) => (
                                <div className="tax-summary__client-details" key={i}>
                                    <div className="tax-summary__bold-text">{item.label}</div>
                                    <div className="tax-summary__client-details__value">
                                        <div>
                                            {formatCurrency(item.data?.taxableAmount)} x{" "}
                                            {`${(item.data?.taxedAt ?? 0) * 100}%`} ={" "}
                                            {formatCurrency(item.data?.value)}
                                        </div>
                                    </div>
                                </div>
                            ))}

                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Total Tax Charged</div>
                            <div className="tax-summary__client-details__value">
                                <div>{formatCurrency(taxData?.totalIncomeTaxScotland?.value)}</div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="tax-summary__client-details-text">
                            Non-Savings Income{" "}
                            <img src={infoIcon} alt="info icon" onClick={handleClick} />
                            {isVisible && (
                                <p className="fs14">(Self Employment, PAYE, Rental, Pension, CIS)</p>
                            )}
                        </div>

                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Taxable Income</div>
                            <div className="tax-summary__client-details__value">
                                <div>{formatCurrency(taxData?.taxableNonSavings?.value)}</div>
                            </div>
                        </div>

                        {[
                            {
                                label: "Income Tax (Basic Rate)",
                                data: taxData?.["basicRateTaxNon-Savings"],
                            },
                            {
                                label: "Income Tax (Higher Rate)",
                                data: taxData?.["higherRateTaxNon-Savings"],
                            },
                            {
                                label: "Income Tax (Additional Rate)",
                                data: taxData?.["additionalRateTaxNon-Savings"],
                            },
                        ]
                            .filter(item => typeof item.data?.value === "number" && item.data?.value !== 0)
                            .map((item, i) => (
                                <div className="tax-summary__client-details" key={i}>
                                    <div className="tax-summary__bold-text">{item.label}</div>
                                    <div className="tax-summary__client-details__value">
                                        <div>
                                            {formatCurrency(item.data?.taxableAmount)} x{" "}
                                            {`${(item.data?.taxedAt ?? 0) * 100}%`} ={" "}
                                            {formatCurrency(item.data?.value)}
                                        </div>
                                    </div>
                                </div>
                            ))}

                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Total Income Tax</div>
                            <div className="tax-summary__client-details__value">
                                <div>{formatCurrency(taxData?.["totalTaxNon-Savings"]?.value)}</div>
                            </div>
                        </div>
                    </>
                )}
            </Card>

            {[
                taxData?.taxableInterestAfterAllowance?.value,
                taxData?.basicRateTaxSavings?.value,
                taxData?.higherRateTaxSavings?.value,
                taxData?.additionalRateTaxSavings?.value,
            ].some(val => typeof val === "number" && val !== 0) && (
                <Card>
                    <div className="tax-summary__client-details-text">Savings Interest</div>

                    {typeof taxData?.taxableInterestAfterAllowance?.value === "number" &&
                        taxData.taxableInterestAfterAllowance.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Taxable Income</div>
                            <div className="tax-summary__client-details__value">
                                <div>{formatCurrency(taxData.taxableInterestAfterAllowance.value)}</div>
                            </div>
                        </div>
                    )}

                    {typeof taxData?.basicRateTaxSavings?.value === "number" &&
                        taxData.basicRateTaxSavings.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Savings Tax (Basic Rate)</div>
                            <div className="tax-summary__client-details__value">
                                <div>
                                    {formatCurrency(taxData.basicRateTaxSavings.taxableAmount)} x{" "}
                                    {`${(taxData.basicRateTaxSavings.taxedAt ?? 0) * 100}%`} ={" "}
                                    {formatCurrency(taxData.basicRateTaxSavings.value)}
                                </div>
                            </div>
                        </div>
                    )}

                    {typeof taxData?.higherRateTaxSavings?.value === "number" &&
                        taxData.higherRateTaxSavings.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Savings Tax (Higher Rate)</div>
                            <div className="tax-summary__client-details__value">
                                <div>
                                    {formatCurrency(taxData.higherRateTaxSavings.taxableAmount)} x{" "}
                                    {`${(taxData.higherRateTaxSavings.taxedAt ?? 0) * 100}%`} ={" "}
                                    {formatCurrency(taxData.higherRateTaxSavings.value)}
                                </div>
                            </div>
                        </div>
                    )}

                    {typeof taxData?.additionalRateTaxSavings?.value === "number" &&
                        taxData.additionalRateTaxSavings.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Savings Tax (Additional Rate)</div>
                            <div className="tax-summary__client-details__value">
                                <div>
                                    {formatCurrency(taxData.additionalRateTaxSavings.taxableAmount)} x{" "}
                                    {`${(taxData.additionalRateTaxSavings.taxedAt ?? 0) * 100}%`} ={" "}
                                    {formatCurrency(taxData.additionalRateTaxSavings.value)}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="tax-summary__client-details">
                        <div className="tax-summary__bold-text">Total Savings Tax</div>
                        <div className="tax-summary__client-details__value">
                            <div>{formatCurrency(taxData?.totalTaxSavings?.value)}</div>
                        </div>
                    </div>
                </Card>
            )}

            {[
                taxData?.taxableDividendsAfterAllowance?.value,
                taxData?.basicRateDividendTax?.value,
                taxData?.higherRateDividendTax?.value,
                taxData?.additionalRateDividendTax?.value,
            ].some(val => typeof val === "number" && val !== 0) && (
                <Card>
                    <div className="tax-summary__client-details-text">Dividend Income</div>

                    {typeof taxData?.taxableDividendsAfterAllowance?.value === "number" &&
                        taxData.taxableDividendsAfterAllowance.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Taxable Income</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.taxableDividendsAfterAllowance.value)}
                            </div>
                        </div>
                    )}

                    {typeof taxData?.basicRateDividendTax?.value === "number" &&
                        taxData.basicRateDividendTax.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Dividends Tax (Basic Rate)</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.basicRateDividendTax.taxableAmount)} x{" "}
                                {(taxData.basicRateDividendTax.taxedAt ?? 0) * 100}% ={" "}
                                {formatCurrency(taxData.basicRateDividendTax.value)}
                            </div>
                        </div>
                    )}

                    {typeof taxData?.higherRateDividendTax?.value === "number" &&
                        taxData.higherRateDividendTax.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Dividends Tax (Higher Rate)</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.higherRateDividendTax.taxableAmount)} x{" "}
                                {(taxData.higherRateDividendTax.taxedAt ?? 0) * 100}% ={" "}
                                {formatCurrency(taxData.higherRateDividendTax.value)}
                            </div>
                        </div>
                    )}

                    {typeof taxData?.additionalRateDividendTax?.value === "number" &&
                        taxData.additionalRateDividendTax.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Dividends Tax (Additional Rate)</div>
                            <div className="tax-summary__client-details__value">
                                {formatCurrency(taxData.additionalRateDividendTax.taxableAmount)} x{" "}
                                {(taxData.additionalRateDividendTax.taxedAt ?? 0) * 100}% ={" "}
                                {formatCurrency(taxData.additionalRateDividendTax.value)}
                            </div>
                        </div>
                    )}

                    <div className="tax-summary__client-details">
                        <div className="tax-summary__bold-text">Total Dividends Tax</div>
                        <div className="tax-summary__client-details__value">
                            {formatCurrency(taxData?.totalDividendTax?.value)}
                        </div>
                    </div>
                </Card>
            )}

            {[
                cgtBasicRateTax,
                cgtHigherRateTax,
                cgtResidentialBasicRateTaxableAmount,
                cgtResidentialHigherRateTaxableAmount,
            ].some(val => typeof val === "string" && val !== "£0.00") && (
                <Card>
                    <div className="tax-summary__client-details-text">Capital Gains</div>

                    {cgtBasicRateTax !== "£0.00" && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">CGT (Basic Rate)</div>
                            <div className="tax-summary__client-details__value">
                                <div>
                                    {cgtBasicRateTaxableAmount} x {cgtBasicRateDisplay} = {cgtBasicRateTax}
                                </div>
                            </div>
                        </div>
                    )}

                    {cgtHigherRateTax !== "£0.00" && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">CGT (Higher Rate)</div>
                            <div className="tax-summary__client-details__value">
                                <div>
                                    {cgtHigherRateTaxableAmount} x {cgtHigherRateDisplay} = {cgtHigherRateTax}
                                </div>
                            </div>
                        </div>
                    )}

                    {cgtResidentialBasicRateTaxableAmount !== "£0.00" && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Residential Property CGT (Basic Rate)</div>
                            <div className="tax-summary__client-details__value">
                                <div>
                                    {cgtResidentialBasicRateTaxableAmount} x {cgtResidentialBasicDisplay} = {cgtResidentialBasicRateTax}
                                </div>
                            </div>
                        </div>
                    )}

                    {cgtResidentialHigherRateTaxableAmount !== "£0.00" && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Residential Property CGT (Higher Rate)</div>
                            <div className="tax-summary__client-details__value">
                                <div>
                                    {cgtResidentialHigherRateTaxableAmount} x {cgtResidentialHigherDisplay} = {cgtResidentialHigherRateTax}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="tax-summary__client-details">
                        <div className="tax-summary__bold-text">Total Capital Gains Tax</div>
                        <div className="tax-summary__client-details__value">
                            <div>{formatCurrency(taxData?.totalCapitalGainsTax?.value)}</div>
                        </div>
                    </div>
                </Card>
            )}
            {[
                taxData?.class4NICBasic?.value,
                taxData?.class4NICHigher?.value,
                taxData?.class2NIC?.value,
                taxData?.studentLoanRepayment?.value,
                taxData?.highIncomeChildBenefitCharge?.value,
            ].some(val => typeof val === "number" && val !== 0) && (
                <Card>
                    <div className="tax-summary__client-details-text">NICs & Other Charges</div>

                    {typeof taxData?.class4NICBasic?.value === "number" &&
                        taxData.class4NICBasic.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Class 4 NICs (Basic Rate)</div>
                            <div className="tax-summary__client-details__value">
                                <div>
                                    {formatCurrency(taxData.class4NICBasic.taxableAmount)} x{" "}
                                    {`${(taxData.class4NICBasic.taxedAt ?? 0) * 100}%`} ={" "}
                                    {formatCurrency(taxData.class4NICBasic.value)}
                                </div>
                            </div>
                        </div>
                    )}

                    {typeof taxData?.class4NICHigher?.value === "number" &&
                        taxData.class4NICHigher.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Class 4 NICs (Higher Rate)</div>
                            <div className="tax-summary__client-details__value">
                                <div>
                                    {formatCurrency(taxData.class4NICHigher.taxableAmount)} x{" "}
                                    {`${(taxData.class4NICHigher.taxedAt ?? 0) * 100}%`} ={" "}
                                    {formatCurrency(taxData.class4NICHigher.value)}
                                </div>
                            </div>
                        </div>
                    )}

                    {typeof taxData?.class2NIC?.value === "number" && taxData.class2NIC.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Class 2 National Insurance</div>
                            <div className="tax-summary__client-details__value">
                                <div>{formatCurrency(taxData.class2NIC.value)}</div>
                            </div>
                        </div>
                    )}

                    {typeof taxData?.studentLoanRepayment?.value === "number" &&
                        taxData.studentLoanRepayment.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Student Loan Repayments</div>
                            <div className="tax-summary__client-details__value">
                                <div>{formatCurrency(taxData.studentLoanRepayment.value)}</div>
                            </div>
                        </div>
                    )}

                    {typeof taxData?.highIncomeChildBenefitCharge?.value === "number" &&
                        taxData.highIncomeChildBenefitCharge.value !== 0 && (
                        <div className="tax-summary__client-details">
                            <div className="tax-summary__bold-text">Child Benefit Charge</div>
                            <div className="tax-summary__client-details__value">
                                <div>{formatCurrency(taxData.highIncomeChildBenefitCharge.value)}</div>
                            </div>
                        </div>
                    )}
                </Card>
            )}

            <Card style={{ backgroundColor: "#F5F5F5" }}>
                <div className="tax-summary__client-details-text">Total Tax Calculation Summary</div>
                <div className="mb20">
                    <div className="tax-summary__client-details">
                        <div className="tax-summary__bold-text">Provisional Total Tax</div>
                        <div className="tax-summary__client-details__value">
                            <div>{formatCurrency(taxData?.provisionalTotalTax?.value)}</div>
                        </div>
                    </div>
                    <div className="tax-summary__client-details">
                        <div className="tax-summary__bold-text">(Plus) Total NICs & Other Charges</div>
                        <div className="tax-summary__client-details__value">
                            <div>{formatCurrency(taxData?.totalCharges?.value)}</div>
                        </div>
                    </div>
                    <div className="tax-summary__client-details">
                        <div className="tax-summary__bold-text">(Minus) Payments on Account Paid</div>
                        <div className="tax-summary__client-details__value">
                            <div>{formatCurrency(taxData?.paymentsOnAccountMadeAlready?.value)}</div>
                        </div>
                    </div>
                    <div className="tax-summary__client-details">
                        <div className="tax-summary__bold-text">(Minus) Tax Already Paid</div>
                        <div className="tax-summary__client-details__value">
                            <div>{formatCurrency(taxData?.taxPaidAlready?.value)}</div>
                        </div>
                    </div>
                    <div className="tax-summary__client-details tax-summary__spaced-section">
                        <div className="tax-summary__client-details-text">
                            {taxData?.totalTaxLiability?.value < 0
                                ? "Estimated Refund Due"
                                : "Total Tax (Due 31st January 2026)"}
                        </div>
                        <div className="tax-summary__client-details__value extra-bold">
                            <div>
                                {formatCurrency(Math.abs(taxData?.totalTaxLiability?.value ?? 0))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb20">
                    <div className="tax-summary__client-details">
                        <div className="tax-summary__bold-text">First Payment on Account (Due 31st Jan 2026)</div>
                        <div className="tax-summary__client-details__value">
                            <div>{formatCurrency(taxData?.paymentsOnAccount31stJan?.value)}</div>
                        </div>
                    </div>
                </div>
                <div className="tax-summary__client-details">
                    <div className="tax-summary__client-details-text">Total Due 31st January 2026 (Total Tax & First Payment on Account)</div>
                    <div className="tax-summary__client-details__value extra-bold">
                        <div>{totalTaxDuePlus31Jan}</div>
                    </div>
                </div>
                <div className="tax-summary__client-details">
                    <div className="tax-summary__bold-text">Second Payment on Account (Due 31st July 2026)</div>
                    <div className="tax-summary__client-details__value">
                        <div>{formatCurrency(taxData?.paymentsOnAccount31stJuly?.value)}</div>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default TaxSummaryContent;
