import React, { Dispatch, SetStateAction } from "react";

import { Typography } from "antd";
import onTheWay from "assets/images/profile/payment/payment_success.png";
import { IRefund } from "types/client";
import { round2Decimals } from "utils/numbers";

export const PlanConfirmed: React.FC<{
	plan?: string
    refund?: IRefund
    setSelectedTab?: (_value: string) => void;
    setPageLoading?: Dispatch<SetStateAction<boolean>>
}> = ({ refund, setPageLoading }) => {
    return (<>
        <div data-testid={"payment-confirmed-success-block"}>
            <div className="payment-plans__confirmed-content">
                <div className="payment-plans__confirmed-header">
                    <div className="profile__content-title">Your Payment is on the Way</div>
                    <img
                        src={onTheWay}
                        alt="on the way"
                        height={186}
                        style={{ marginTop: "30px" }}
                        onLoad={() => {
                            const timer = setTimeout(() => {
                                setPageLoading && setPageLoading(false);
                            }, 200);
                            return () => clearTimeout(timer);
                        }}
                    />
                    <div className="payment-plans__confirmed-calculation">
                        <div style={{ width: "100%", padding: "20px" }}>
                            <Typography className="payment-plans__confirmed-text">
                                Great news! Your refund is on the way!
                            </Typography>
                            <div className="payment-plans__confirm-calculation-row" style={{ marginTop: "20px" }} >
                                <Typography className="payment-plans__confirmed-text" style={{ marginTop: "0px" }}>
                                    Total refund
                                </Typography>
                                <Typography
                                    className="payment-plans__confirmed-text"
                                    style={{ marginTop: "0px" }}
                                >
                                    £{round2Decimals((refund?.received || 0)) || "0.00"}
                                </Typography>
                            </div>
                            <div className="payment-plans__confirm-calculation-row">
                                <Typography className="payment-plans__confirmed-text" style={{ marginTop: "0px" }}>
                                    TaxZap fee
                                </Typography>
                                <Typography className="payment-plans__confirmed-text" style={{ marginTop: "0px" }}>
                                    £{round2Decimals((refund?.fee || 0)) || "0.00"}
                                </Typography>
                            </div>
                            <div className="payment-plans__confirm-calculation-row">
                                <Typography className="payment-plans__confirmed-text" style={{ marginTop: "0px" }}>
                                    VAT (20%)
                                </Typography>
                                <Typography className="payment-plans__confirmed-text" style={{ marginTop: "0px" }}>
                                    £{round2Decimals((refund?.vat || 0)) || "0.00"}
                                </Typography>
                            </div>
                            <div className="payment-plans__confirm-calculation-row" style={{ marginTop: "20px" }}>
                                <Typography className="payment-plans__confirmed-text" style={{ marginTop: "0px", color: "#2EBB49" }}>
                                    Your net refund
                                </Typography>
                                <Typography className="payment-plans__confirmed-text" style={{ marginTop: "0px", color: "#2EBB49" }}>
                                    £{round2Decimals((refund?.amount || 0) + (refund?.paid || 0)) || "0.00"}
                                </Typography>
                            </div>
                        </div>
                        <Typography className="payment-plans__confirmed-text">
                            Got a question? Write us {" "}
                            <a href="mailto:support@taxzap.co.uk" className="email-link" target="_blank" rel="noopener noreferrer">
                                support@taxzap.co.uk
                            </a>. Thank you for choosing us!
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
};
