import React, { useEffect } from "react";

import leftSwipe from "assets/images/new-questionnaire/swipe-left.svg";
import rightSwipe from "assets/images/new-questionnaire/swipe-right.svg";
import { useLocalStorage } from "hooks/helpers/useLocalStorage";

type ModalProps = {
    onClose: () => void;
    data: {
        swipeLeftText: string;
        swipeRightText: string;
    }
};

const GenericSwipeHelpModal: React.FC<ModalProps> = ({ onClose, data }) => {
    const [, setHasGenericSwipeModalShown] = useLocalStorage<boolean>("generic_swipe_modal_shown", false);

    useEffect(() => {
        setHasGenericSwipeModalShown(true);
        const timer = setTimeout(() => {
            onClose();
        }, 5000);

        return () => clearTimeout(timer);
    }, [onClose, setHasGenericSwipeModalShown]);

    return (
        <div className="swipeable-cards-modal-overlay" onClick={onClose}>
            <div className="swipeable-cards-modal-content">
                <div className="swipeable-cards-modal-option">
                    <img src={leftSwipe} width={80} alt="No" />
                    <div className="swipeable-cards-modal-title mt20">
                        NO?
                    </div>
                    <div className="swipeable-cards-modal-text mt10">
                        {data.swipeLeftText}
                    </div>
                </div>
                <div className="swipeable-cards-modal-option">
                    <img src={rightSwipe} width={80} alt="Yes" />
                    <div className="swipeable-cards-modal-title mt20">
                        YES?
                    </div>
                    <div className="swipeable-cards-modal-text mt10">
                        {data.swipeRightText}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenericSwipeHelpModal;
