import React from "react";

import { CheckOutlined } from "@ant-design/icons";
import "styles/common/customCheckbox.less";

interface CustomCheckboxProps {
  checked: boolean;
  onChange: (_e: any) => void;
  disabled?: boolean;
  label?: string;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked, onChange, disabled = false, label }) => {
    return (
        <label className={`custom-checkbox-label ${disabled ? "disabled" : ""}`}>
            <input
                type="checkbox"
                className="custom-checkbox-input"
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
            <div className={`custom-checkbox-box ${checked ? "checked" : ""}`}>
                {checked && <CheckOutlined className="custom-checkbox-checkmark" />}
            </div>
            {label && <span className="custom-checkbox-text">{label}</span>}
        </label>
    );
};

export default CustomCheckbox;
