import { FC, useState } from "react";

import { Modal } from "antd";
import backChevron from "assets/images/new-questionnaire/back-chevron.svg";
import close from "assets/images/new-questionnaire/close.svg";
import avatar from "assets/images/new-questionnaire/questionnaire-avatar.svg";
import taxzapLogo from "assets/images/taxzap-logo-R.svg";
import DropDownMobile from "components/pages/Profile/tabs/Homepage/DropDownMobile";
import { Link } from "react-router-dom";
import { IUser } from "types/auth";
import { AccountDetails } from "types/bankAccounts";

import BankAccount from "./BankAccount/BankAccount";
import { Step } from "../constants/questions";

type HeaderProps = {
    user: IUser;
    connectedBankAccounts: AccountDetails[];
    hasBack: boolean;
    playPigAnimationTrigger: string;
    handlePrev: () => void;
    openConnectModal: () => void;
    handleContinue: () => void;
    currentStepData: Step;
};

const MobileHeader: FC<HeaderProps> = ({ user, connectedBankAccounts, hasBack, handlePrev, openConnectModal, handleContinue }) => {
    const [openBankAccounts, setOpenBankAccounts] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);

    const toggleDropDown = () => {
        setShowDropDown(prev => !prev);
    };
    return (
        <div>
            <div className="new-questionnaire__mobile-header-row">
                <div className="w33 taLeft">
                    {hasBack
                        ? <div onClick={() => handlePrev()} className="flex-row pointer">
                            <img src={backChevron} alt="back" className="dblock"/>
                            <span className="dblock grey-10-color">Back</span>
                        </div>
                        : <div></div>
                    }
                </div>

                <div className="">
                    <Link to={"profile"} className="new-questionnaire__profile-link">
                        <img
                            className="dblock logo-questionnaire mAuto"
                            src={taxzapLogo}
                            alt="TaxZap Logo"
                            style={{ cursor: "default" }}
                        />
                    </Link>
                </div>

                <div className="w33 taRight">
                    <div><img src={avatar} alt="avatar" onClick={toggleDropDown}/></div>
                    <div className="taLeft">
                        <DropDownMobile
                            firstName={user.info.firstName} lastName={user.info.lastName} email={user.info.email} showDropDown={showDropDown} showToggle={toggleDropDown} connectedBankAccounts={connectedBankAccounts}
                        />
                    </div>
                </div>

            </div>
            <div className="new-questionnaire__mobile-header-content">
                <Modal
                    open={openBankAccounts}
                    width={355}
                    destroyOnClose
                    closable={false}
                    onCancel={() => setOpenBankAccounts(false)}
                    footer={null}
                    className="new-questionnaire__shared-prompt-modal"
                    styles={{
                        content: {
                            borderRadius: "20px",
                            padding: "20px!important",
                        },
                    }}
                    centered
                >
                    <div className="new-questionnaire__bank-accounts-modal">
                        <div className="new-questionnaire__bank-accounts-close" onClick={() => setOpenBankAccounts(false)}>
                            <img src={close} alt="close" className="dblock p16"/>
                        </div>
                        <BankAccount
                            user={user}
                            handleContinue={handleContinue}
                            openConnectModal={() => {
                                openConnectModal();
                                setOpenBankAccounts(false);
                            }}
                            connectedBankAccounts={connectedBankAccounts}
                            isHeaderComponent={true}
                        ></BankAccount>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default MobileHeader;
