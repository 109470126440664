import { UK_SIGNUP_STEP } from "constants/signupFormNew";

import { FC, useContext, useEffect, useState } from "react";

import { Col, Row } from "antd";
import { AppContext } from "App";
import googleIcon from "assets/images/google.svg";
import cisworker from "assets/images/home/cisworker.png";
import deliverydriver from "assets/images/home/deliverdriver.png";
import greenStar from "assets/images/home/green-star.svg";
import headerImage from "assets/images/home/header-image.png";
import image1 from "assets/images/home/image1.png";
import image2 from "assets/images/home/image2.png";
import image3 from "assets/images/home/image3.png";
import image4 from "assets/images/home/image4.png";
import landlord from "assets/images/home/landlord.png";
import startApplication from "assets/images/home/start-application.svg";
import { useLocation } from "react-router-dom";
import { getQueryParam } from "utils/common";

import { getStars } from "./getStars";
import SignUpFormNew from "../../../basic/SignUpForm/SignUpFormNew";
import StartButtonNew from "../../../basic/StartButtonNew";

interface MainProps {
    setShowButtonFooter: (_value: boolean) => void;
}

const MainNew: FC<MainProps> = ({ setShowButtonFooter }) => {
    const location = useLocation();
    const { isMobile, isAuth, maxWidth, formScale } = useContext(AppContext);

    const step = getQueryParam("step", location) || UK_SIGNUP_STEP.PERSONAL_INFO;
    const [backgroundImage, setBackgroundimage] = useState("");
    const sloganOptions = {
        default: {
            purple: "Swipe",
            text: "& submit your tax ",
            green: "return  ",
            suffix: "instantly.",
        },
        delivery: {
            purple: "Pay less tax",
            text: "& ",
            green: "keep more",
            suffix: " money.",
        },
        cis: {
            purple: "Find out",
            text: "how much",
            green: "    you are owed!",
            suffix: "",
        },
        landlord: {
            purple: "Want to",
            text: "pay less",
            green: " tax?",
            suffix: "",
        },
    };

    const descriptions = {
        default: (
            <>
                File your self-assessment tax return for only
                <span className="bold"> £179. </span>
                We'll help you uncover your allowable expenses and submit your return directly to HMRC — fast, accurate, and stress-free.
            </>
        ),
        tips: "We'll help you uncover all your refundable expenses, allowing you to submit and claim with ease!",
        cis: "File your tax return in as little as 6 minutes for £179. The average construction worker refund is £2,232.",
    };
    const [description, setDescription] = useState<any>(descriptions.default);
    const [currentSlogan, setCurrentSlogan] = useState(sloganOptions.default);
    const [buttonLabel, setButtonLabel] = useState("Start Now");
    const [headerLabel, setHeaderLabel] = useState("");
    const [nextButtonLabel, setNextButtonLabel] = useState("");
    const [infoText, setInfoText] = useState("");

    useEffect(() => {
        switch (location.pathname) {
        case "/delivery-drivers":
            setBackgroundimage(deliverydriver);
            setCurrentSlogan(sloganOptions.delivery);
            setDescription(descriptions.tips);
            setButtonLabel("Get Tips");
            setHeaderLabel("Learn How with Our Free Tips!");
            setNextButtonLabel("GET STARTED");
            setInfoText("Find out how much you could be owed");
            break;
        case "/construction-worker":
            setBackgroundimage(cisworker);
            setCurrentSlogan(sloganOptions.cis);
            setDescription(descriptions.cis);
            setButtonLabel("Start Claim");
            setHeaderLabel("Claim Your Refund");
            setNextButtonLabel("APPLY FOR MY REFUND NOW");
            setInfoText("Find out how much you could be owed.");
            break;
        case "/landlord":
            setBackgroundimage(landlord);
            setCurrentSlogan(sloganOptions.landlord);
            setDescription(descriptions.tips);
            setButtonLabel("Get Tips");
            setHeaderLabel("Get Insider Tips & Strategies!");
            setNextButtonLabel("GET STARTED");
            setInfoText("Find out how much you could be owed");
            break;
        default:
            setBackgroundimage(headerImage);
            setCurrentSlogan(sloganOptions.default);
            setDescription(descriptions.default);
            setHeaderLabel("Start Your Tax Return");
            setNextButtonLabel("START TAX RETURN");
            setInfoText("Enter your details to begin your tax return.");
            break;
        }
        if (isMobile) {
            setShowButtonFooter(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <div className={"uk-main-block-new horizontal-center"}>
            <div
                className={!isMobile ? "uk-main-image-new uk-start-application" : ""}
                style={{
                    position: "relative",
                    height: isMobile ? "auto" : "690px",
                    maxWidth: maxWidth,
                    width: "90%",
                    padding: isMobile ? "24px" : "64px 100px",
                    backgroundPosition: "531px 220px, 784px 70px, 1320px 630px",
                    backgroundImage: `url(${backgroundImage}) ,url(${startApplication}), url(${greenStar})`,
                    backgroundRepeat: "no-repeat",
                }}
            >
                {isMobile && (
                    <div className="horizontal-center" style={{ marginBottom: "-30px" }}>
                        <img
                            src={backgroundImage}
                            alt={"constructors"}
                            className={"uk-main-image-mobile"}
                        />
                    </div>
                )}
                <Row>
                    <Col sm={24} xs={24} md={24} lg={24} xl={14}>
                        <div>
                            <div className={isMobile ? "uk-sub-slogan" : "uk-slogan-block-new"}>
                                <span className={"uk-slogan-new"}>
                                    <span className={"uk-sub-slogan-purple"}>
                                        {currentSlogan.purple}{" "}
                                    </span>
                                    {currentSlogan.text}
                                    <span className={"uk-sub-slogan-green"}>
                                        {currentSlogan.green}
                                    </span>
                                    {currentSlogan.suffix}
                                </span>
                            </div>
                            <div className={"uk-slogan-description-block"}>
                                <span className={"uk-slogan-description"}>
                                    {description}
                                </span>
                            </div>
                            <Row style={{ alignItems: "baseline" }}>
                                <div className={isMobile ? "mt20" : "mt40"} id="mainStartButton">
                                    <StartButtonNew label={buttonLabel}/>
                                </div>
                            </Row>
                            <div className={isMobile ? "mt20" : "mt40"} style={{ overflow: "hidden" }}>
                                <Row gutter={[32, 16]}>
                                    {[
                                        {
                                            id: "google",
                                            label: "Google Reviews",
                                            score: 4.7,
                                            icon: googleIcon,
                                        },
                                    ].map(item => {
                                        return (
                                            <Col
                                                key={item.id}
                                                sm={24}
                                                xs={24}
                                                md={24}
                                                lg={24}
                                                xl={9}
                                            >
                                                <div className={"horizontal-left"}>
                                                    <div>
                                                        <img
                                                            src={item.icon}
                                                            width={36}
                                                            height={36}
                                                            alt={item.id}
                                                        />
                                                    </div>
                                                    <div className={"ml10"}>
                                                        <div className={"grey-7-color bold fs12"}>
                                                            {item.label}
                                                        </div>
                                                        <div className={"horizontal-left"}>
                                                            <div className={"fs18 bold"}>
                                                                {item.score}
                                                            </div>
                                                            <div className={"ml10"}>
                                                                {getStars(5, 22)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                            <div className="container mt30" style={{ gap: "21px, mar" }}>
                                <div className="text-container">
                                    <span className={"uk-sub-slogan-purple"} style={{
                                        fontSize: isMobile ? "24px" : "48px",
                                        fontWeight: "500",
                                        lineHeight: "40px",
                                    }}>125K+</span>
                                    <p className={"mt20 grey-6-color"}>Tax returns filed for our clients.</p>
                                </div>
                                <div className="image-container">
                                    <img src={image1} alt="Person 1" className="overlay-image image1"/>
                                    <img src={image2} alt="Person 2" className="overlay-image image2"/>
                                    <img src={image3} alt="Person 3" className="overlay-image image3"/>
                                    <img src={image4} alt="125K+" className="overlay-image symbol"/>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} xs={24} md={24} lg={24} xl={10}>
                        <div></div>
                    </Col>
                </Row>
                {!isMobile && (
                    <div
                        className={"uk-signup-wrapper"}
                        style={{
                            transform: `scale(${formScale})`,
                            transition: "transform 0.2s ease-in-out",
                            ...(isAuth && {
                                pointerEvents: "none",
                                opacity: 0.5,
                                filter: "grayscale(100%)",
                            }),
                        }}
                    >
                        <div className="uk-sign-up-desktop-new">
                            <SignUpFormNew step={step as UK_SIGNUP_STEP} step1VariableInfo={{ headerLabel: headerLabel, nextButtonLabel: nextButtonLabel, infoText: infoText }} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MainNew;
