import { FC, useCallback, useEffect, useMemo, useState } from "react";

import decrement from "assets/images/new-questionnaire/minus.svg";
import increment from "assets/images/new-questionnaire/plus.svg";
import questionIcon from "assets/images/new-questionnaire/question_icon.svg";
import QuestionnaireButtonFooterV2 from "components/basic/Footer/QuestionnaireButtonFooterV2";
import ButtonLoading from "components/basic/Spinner/ButtonLoading";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { useAppSelector } from "redux/hooks";
import { selectCurrentNavigation } from "redux/reducers/navigation";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { checkDepends } from "utils/new-questionnaire";

import { Depend, Question } from "../../constants/questions";
import { PromptModalType } from "../../enums/enum";
import PromptModal from "../Shared/PromptModal";

type SelectOneIconQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: string | undefined) => void;
    handlePrev: () => void;
    handleContinue: () => void
    setHideContinue: (_value: boolean) => void;
    initialValue?: any;
    categoryAnswers?: Record<string, any>;
};

const SelectOneIconQuestion: FC<SelectOneIconQuestionProps> = ({ data, onAnswer, handlePrev, initialValue, setHideContinue, categoryAnswers, handleContinue }) => {
    const isKeyboardOpen = useDetectKeyboardOpen();
    const { stepLoading } = useAppSelector(selectCurrentNavigation);

    const [amount, setAmount] = useState<number>(initialValue || (data?.initValue ? Number(data.initValue) || 0 : 0));
    const [showModalOpener, setShowModalOpener] = useState(false);
    const promptModalData = useMemo(() => ({
        type: data.promptModal?.type || PromptModalType.info,
        title: data.promptModal?.title || "",
        text: data.promptModal?.text || "",
        image: data.promptModal?.image || "",
    }), [data.promptModal]);

    const {
        isOpened: isQuestionHelpModalOpened,
        open: handleQuestionHelpOpenModal,
        close: handleQuestionHelpCloseModal,
    } = useModalOpener();

    useEffect(() => {
        if (!initialValue && data?.initValue) {
            setTimeout(() => {
                onAnswer(data.question, data.initValue?.toString());
            }, 1);
        }

        if (Number(amount) !== Number(initialValue)) {
            onAnswer(data.question, amount.toString());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount]);

    useEffect(() => {
        setHideContinue(true);

        return () => setHideContinue(false);
    }, [setHideContinue]);

    const handleIconClick = (index: number) => {
        if (amount === index + 1) {
            setAmount(0);
        } else {
            setAmount(index + 1);
        }
    };

    const checkModalDepends = useCallback((depend: Depend[], categoryAnswers?: Record<string, any>) => {
        if (categoryAnswers) {
            return checkDepends(depend, categoryAnswers);
        } else {
            return false;
        }
    }, []);

    const handleIncrement = () => {
        setAmount(prevAmount => prevAmount + 1);
    };

    const handleDecrement = () => {
        setAmount(prevAmount => (prevAmount > 0 ? prevAmount - 1 : 0));
    };

    const renderIcons = () => {
        const icons = [];
        for (let i = 0; i < 5; i++) {
            const isActive = i < amount;
            icons.push(
                <img
                    key={i}
                    src={isActive ? data.icons?.active : data.icons?.default}
                    alt={`icon-${i}`}
                    onClick={() => handleIconClick(i)}
                    style={{ cursor: "pointer" }}
                />
            );
        }
        return icons;
    };

    useEffect(() => {
        if (data?.promptModal?.depend && categoryAnswers) {
            const shouldModalShow = checkModalDepends(data.promptModal?.depend, categoryAnswers);
            setShowModalOpener(shouldModalShow);
            if (shouldModalShow && !data.promptModal?.manualOpen) {
                handleQuestionHelpOpenModal();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="new-questionnaire__question">
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                {data.question}
                {showModalOpener && (
                    <span className="new-questionnaire__question-icon" onClick={handleQuestionHelpOpenModal}>
                        <img src={questionIcon} width={20} alt="help"/>
                    </span>
                )}
            </div>
            <div className="new-questionnaire__select-one-question-input-wrapper">
                {renderIcons()}
            </div>
            {amount >= 5 && (
                <div className="new-questionnaire__select-one-question-additional-input">
                    <img
                        src={decrement}
                        width={20}
                        alt={"decrement"}
                        className="new-questionnaire__select-one-question-additional-input-button"
                        onClick={handleDecrement}
                    />
                    <div className="new-questionnaire__select-one-question-additional-input-amount">
                        <img
                            src={data.icons?.active}
                            width={24}
                            alt={"active-icon"}
                        />
                        <div>{amount}</div>
                    </div>
                    <img
                        src={increment}
                        width={20}
                        alt={"increment"}
                        className="new-questionnaire__select-one-question-additional-input-button"
                        onClick={handleIncrement}
                    />
                </div>
            )}
            {
                <div className={`${isKeyboardOpen ? "" : "mt120"}`}>
                    <QuestionnaireButtonFooterV2>
                        <div
                            className={`new-questionnaire__form-continue ${(data.answerRequired && !amount) || stepLoading ? "disabled" : ""}`}
                            onClick={() => {
                                if ((data.answerRequired && !amount) || stepLoading) {
                                    return;
                                }
                                handleContinue();
                            }}
                        >
                            {stepLoading
                                ? <ButtonLoading/>
                                : <span> {data.answerRequired || (amount && amount > 0) ? "Continue" : "None"}</span>
                            }

                        </div>
                    </QuestionnaireButtonFooterV2>
                </div>}

            {isQuestionHelpModalOpened && (
                <PromptModal
                    isOpen={isQuestionHelpModalOpened}
                    closeModal={handleQuestionHelpCloseModal}
                    data={promptModalData}
                    handlePrev={handlePrev}
                />
            )}
        </div>
    );
};

export default SelectOneIconQuestion;
