import "styles/updateTransaction.less";

import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, Col, Form, Row, Select, Spin, Switch } from "antd";
import { ProfileType } from "components/pages/NewQuestionnaire/enums/enum";
import { useUser } from "hooks/common/useUser";
import { logError } from "utils/sentry";

import TransactionListItem from "./TransactionListItem";
import { getTagIcon } from "./TransactionsFilters";
import { ExpenseCard } from "../../SwipeableTransactionsCards/SwipeableTransactionsCards";

type BulkUpdateTransactionsProps = {
    transactionsToUpdate: ExpenseCard[];
    selectedTransactionsIds: Set<string>;
    patchingTransactionIds: Set<string>;
    taxProfileType: ProfileType;
    taxProfileId: string;
    updateTransaction: (_id: string, _updateData: any) => Promise<void>;
    handleClose: () => void;
    categories: string[];
    approval: string | undefined;
    bulkApproveOrReject: (_approval: string, _transactionIds: Set<string>, _checkAreAllCategoriesDefined: boolean) => Promise<void>;
};

const BulkUpdateTransactions: React.FC<BulkUpdateTransactionsProps> = ({
    transactionsToUpdate,
    taxProfileType,
    updateTransaction,
    handleClose,
    categories,
    approval,
    bulkApproveOrReject,
    selectedTransactionsIds,
    patchingTransactionIds,
}) => {
    const [categorizedTransactions, setCategorizedTransactions] = useState<{ [key: string]: string[] }>({});
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const [showMultiselect, setShowMultiselect] = useState<boolean>(false);
    const [selectedCategoryTransactions, setSelectedCategoryTransactions] = useState<Set<string>>(new Set());
    const [selectedBulkCategory, setSelectedBulkCategory] = useState<string>();
    const { user } = useUser();

    const categorizedTransactionsObjects = useMemo(() => {
        return transactionsToUpdate.filter(transaction => categorizedTransactions[transaction.id]);
    }, [categorizedTransactions, transactionsToUpdate]);

    const handleChangeCategory = useCallback((value: string[], transactionId: string) => {
        setCategorizedTransactions(prev => ({
            ...prev,
            [transactionId]: value,
        }));
        setErrors(prev => ({
            ...prev,
            [transactionId]: value[0] === "No Category",
        }));
    }, []);

    const handleBulkChangeCategory = useCallback((value: string[], transactionIds: Set<string>) => {
        const updatedCategories = Array.from(transactionIds).reduce((acc, txId) => {
            acc[txId] = value;
            return acc;
        }, {} as { [key: string]: string[] });

        setCategorizedTransactions(updatedCategories);
        setErrors(
            Array.from(transactionIds).reduce((acc, txId) => {
                acc[txId] = value[0] === "No Category";
                return acc;
            }, {} as { [key: string]: boolean })
        );
    }, []);

    const handleCloseModal = useCallback(() => {
        handleClose();
        setShowMultiselect(false);
        setSelectedBulkCategory(undefined);
        setSelectedCategoryTransactions(new Set());
        setCategorizedTransactions({});
        setErrors({});
    }
    , [handleClose]);

    const handleUpdate = useCallback(async () => {
        const successfullyUpdatedTransactions = new Set<string>();

        await Promise.all(
            categorizedTransactionsObjects.map(async transaction => {
                const updatedCategory = categorizedTransactions[transaction.id];

                const updateData = {
                    ...transaction,
                    category: {
                        ...transaction?.category,
                        byTaxProfileType: {
                            ...transaction?.category?.byTaxProfileType,
                            [taxProfileType]: {
                                ...transaction?.category?.byTaxProfileType[taxProfileType],
                                clientUpdatedCategory: updatedCategory[0],
                            },
                        },
                    },
                };

                try {
                    await updateTransaction(transaction.id, updateData);
                    successfullyUpdatedTransactions.add(transaction.id);
                } catch (error) {
                    logError(error, {
                        clientId: user?.id,
                        function: "handleUpdate",
                        updateData,
                        message: `Failed to update transaction ${transaction.id}`,
                    });
                }
            })
        );

        if (approval) {
            const alreadyCategorizedTransactions = new Set<string>();
            selectedTransactionsIds.forEach(id => {
                if (!transactionsToUpdate.some(tx => tx.id === id)) {
                    alreadyCategorizedTransactions.add(id);
                }
            });

            // Merge both sets: previously categorized + successfully updated
            const finalApprovalTransactions = new Set<string>(
                Array.from(alreadyCategorizedTransactions).concat(Array.from(successfullyUpdatedTransactions))
            );

            await bulkApproveOrReject(approval, finalApprovalTransactions, false);

            // Remove updated transactions from view
            const transactionsLeftToUpdate = transactionsToUpdate.filter(tx => !successfullyUpdatedTransactions.has(tx.id));
            setSelectedBulkCategory(undefined);
            setSelectedCategoryTransactions(new Set());
            setCategorizedTransactions({});
            setErrors({});

            // Close modal if all transactions are processed
            if (transactionsLeftToUpdate.length === 0) {
                handleCloseModal();
            }
        }
    }, [
        categorizedTransactions,
        categorizedTransactionsObjects,
        taxProfileType,
        updateTransaction,
        user,
        approval,
        bulkApproveOrReject,
        selectedTransactionsIds,
        transactionsToUpdate,
        handleCloseModal,
    ]);

    useEffect(() => {
        // If selected transactions to categorize are changed, update the new ones with the selected category if any
        if (selectedBulkCategory) {
            handleBulkChangeCategory([selectedBulkCategory], selectedCategoryTransactions);
        }
    }, [selectedCategoryTransactions, selectedBulkCategory, handleBulkChangeCategory]);

    // Map transactions to update to their initial categories
    useEffect(() => {
        const initialTags = transactionsToUpdate.reduce((acc, tx) => {
            acc[tx.id] = tx.tags;
            return acc;
        }, {} as { [key: string]: string[] });

        const initialErrors = transactionsToUpdate.reduce((acc, tx) => {
            acc[tx.id] = tx.tags[0] === "No Category";
            return acc;
        }, {} as { [key: string]: boolean });

        setCategorizedTransactions(initialTags);
        setErrors(initialErrors);
    }, [transactionsToUpdate, handleClose]);

    return (
        <div className="updateTransaction">
            <div className="updateTransaction__content">
                {!showMultiselect && <div className="mb10">{transactionsToUpdate.length} expense(s) need categories. Select one for each.</div>}
                {showMultiselect && <div className="mb10">{transactionsToUpdate.length} expense(s) need categories. Select multiple expenses to categorise.</div>}

                <Row gutter={[8, 8]} className="w100 mt6 mb10">
                    <Col span={24} className="taLeft">
                        <span className="mr4">Multiselect</span>
                        <Switch className="approvedRejected__filters__multiselect" value={showMultiselect} onChange={() => {
                            setCategorizedTransactions({});
                            setErrors({});
                            setSelectedCategoryTransactions(new Set());
                            setShowMultiselect(!showMultiselect);
                        }}/>
                    </Col>
                </Row>
                <div className="updateTransaction__list" style={{ marginBottom: showMultiselect ? "180px" : "75px" }}>
                    {transactionsToUpdate.map(transaction => (
                        <div className="updateTransaction__list-item" key={transaction.id}>
                            {patchingTransactionIds.has(transaction.id) ? <div className="w100 center" style={{ minHeight: "92px" }}><Spin size="small" /></div> : <TransactionListItem
                                key={transaction.id}
                                item={transaction}
                                showMultiselect={showMultiselect}
                                selectedTransactions={selectedCategoryTransactions}
                                toggleTransactionSelection={_id => {
                                    const newSelectedCategoryTransactions = new Set(selectedCategoryTransactions);
                                    if (selectedCategoryTransactions.has(_id)) {
                                        newSelectedCategoryTransactions.delete(_id);
                                    } else {
                                        newSelectedCategoryTransactions.add(_id);
                                    }
                                    setSelectedCategoryTransactions(newSelectedCategoryTransactions);
                                }}
                            />}

                            {!showMultiselect && <Select
                                variant="borderless"
                                size="large"
                                placeholder="Select Expense Category"
                                className="swipeable-card-tag"
                                style={{ fontSize: "12px" }}
                                dropdownStyle={{ width: 300 }}
                                onSelect={value => handleChangeCategory([value], transaction.id)}
                            >
                                {categories.map(category => (
                                    <Select.Option key={category} value={category}>{`${getTagIcon(category, taxProfileType)} ${category}`}</Select.Option>
                                ))}
                            </Select>}
                        </div>
                    ))}
                </div>

            </div>
            <div className="updateTransaction__action-buttons flex-column">
                {showMultiselect && <div className="p10">
                    <Form.Item label="Select Expense Category" className="mb10" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Select
                            size="large"
                            placeholder="Select Expense Category"
                            style={{ fontSize: "12px" }}
                            dropdownStyle={{ width: 300 }}
                            onSelect={value => {
                                setSelectedBulkCategory(value);
                            }}
                            disabled= {selectedCategoryTransactions.size === 0}
                            value={selectedBulkCategory}
                        >
                            {categories.map(category => (
                                <Select.Option key={category} value={category}>{`${getTagIcon(category, taxProfileType)} ${category}`}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>}
                <Button
                    shape="round"
                    type="primary"
                    color="primary"
                    className="w100"
                    onClick={handleUpdate}
                    style={{ height: 60 }}
                    disabled={Object.keys(categorizedTransactions).length === 0 || Object.values(errors).includes(true)} // Disable update if any errors
                >
                    Update
                </Button>
            </div>
        </div>
    );
};

export default BulkUpdateTransactions;
