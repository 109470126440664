import { BindValidationType, Questions, ValidationType } from "components/builder/GroupItem";
import { IAddress } from "types/client";
import { DependT, QuestionnaireData, GroupT, ChildrenT, TabT, TabAnswers, SubTabAnswers, QuestionWithAnswer, ProgressT, GroupProgressT } from "types/questionnaire";

import { QuestionType } from "./constructor";

type MessageT =
    | QuestionnaireData["forbiddenMessages"]
    | QuestionnaireData["allowMessages"];

export const checkDepend = (
    depend: DependT[],
    answers?: Record<string, any>
) => {
    if (!depend?.length || !answers) return true;

    for (const dependItem of depend) {
        const answerValue = answers[dependItem.id];

        if (Array.isArray(answerValue)) {
            if (answerValue.includes(dependItem.value)) {
                return true;
            }
        } else if (answerValue === dependItem.value) {
            return true;
        }
    }

    return false;
};

type CheckMessageDependencyResult = {
    message: string;
    affectTo: string;
} | null;

export const checkMessageDependency = (
    messages: MessageT,
    questionId: string,
    answer: any
): CheckMessageDependencyResult => {
    for (const message of messages) {
        const { depend, text, bindTo } = message;
        for (const dependItem of depend) {
            if (questionId === dependItem.id) {
                if (Array.isArray(answer)) {
                    if (answer.includes(dependItem.value)) {
                        return { message: text, affectTo: bindTo?.id || "" };
                    }
                } else if (answer === dependItem.value) {
                    return { message: text, affectTo: bindTo?.id || "" };
                }
            }
        }
    }
    return null;
};

export const getAllDependIds = (messages: MessageT): string[] => {
    const dependIds: string[] = [];
    messages.forEach(message => {
        message.depend.forEach(depend => {
            dependIds.push(depend.id);
        });
        message.andDepend.forEach(depend => {
            dependIds.push(depend.id);
        });
    });

    return dependIds;
};

const removeTrailingComma = (str: string) => {
    return str.trim().endsWith(",") ? str.trim().slice(0, -1) : str.trim();
};

const createAddressString = (address: IAddress) => {
    const address1 = removeTrailingComma(address?.address1 || "");
    const address2 = removeTrailingComma(address?.address2 || "");
    const city = removeTrailingComma(address?.city || "");
    const postcode = removeTrailingComma(address?.postcode || "");

    return address1 + ", " + address2 + ", " + city + ", " + postcode;
};

export const processChildren = (
    children: ChildrenT[] | undefined,
    answers: Record<string, any>
): any => {
    if (!children) return [];
    return children.map(child => {
        if (!answers) return null;
        if (child.type === "arrayGroup") {
            const arrayAnswers: Record<string, any>[] = answers[child.id];

            const arrayGroupAnswers: Array<QuestionWithAnswer | null>[] =
                arrayAnswers?.map((answer: Record<string, any>) => {
                    return (
                        child.children
                            ?.map(child => {
                                const childAnswer = answer[child.id];

                                if (child.type === "infoBlock") {
                                    return {
                                        id: child.id,
                                        question: child.data.label || "",
                                        type: child.type,
                                        answer: "",
                                        hided: child.data.hided,
                                    };
                                }

                                if (child.type === "addressQuestion") {
                                    return {
                                        id: child.id,
                                        question: child.data.adminQuestion || child.data.question || "",
                                        type: child.type,
                                        answer: createAddressString(childAnswer),
                                        hided: child.data.hided,
                                    };
                                }

                                if (typeof childAnswer === "undefined")
                                    return null;

                                return {
                                    id: child.id,
                                    question: child.data.adminQuestion || child.data.question || "",
                                    type: child.type,
                                    answer: childAnswer,
                                    hided: child.data.hided,
                                };
                            })
                            .filter(a => a !== null) || []
                    );
                }) as Array<QuestionWithAnswer | null>[];

            if (!arrayGroupAnswers) return null;
            return {
                id: child.id,
                label: child.data.label || "",
                type: child.type,
                questions: arrayGroupAnswers,
            };
        }

        if (child.type === "infoBlock") {
            return {
                id: child.id,
                question: child.data.label || "",
                type: child.type,
                answer: "",
                hided: child.data.hided,
            };
        }
        const answer = answers[child.id];

        if (typeof answer === "undefined") return null;

        if (child.type === "addressQuestion") {
            return {
                id: child.id,
                question: child.data.label || "",
                type: child.type,
                answer: createAddressString(answer),
                hided: child.data.hided,
            };
        }

        if (child.type === "amountQuestion") {
            return {
                id: child.id,
                type: child.type,
                answer: answer,
                question: child.data.adminQuestion || child.data.question || "",
                addToSum: child.data.addToSum || "",
                hided: child.data.hided,
            };
        }

        if (child.type === "booleanQuestion") {
            return {
                id: child.id,
                question: child.data.adminQuestion || child.data.question || "",
                type: child.type,
                answer: answer,
                amountToShow: child.data.amountToShow || "",
                amountShowCondition: child.data.amountShowCondition || "",
                hided: child.data.hided,
            };
        }

        if (child.type === "totalCostBlock") {
            return {
                id: child.id,
                question: child.data.label || "",
                type: child.type,
                answer: answer,
                addToSum: child.data.addToSum || "",
            };
        }

        return {
            id: child.id,
            question: child.data.adminQuestion || child.data.question || "",
            type: child.type,
            answer: answer,
            hided: child.data.hided,
        };
    });
};

export const createAnswersArray = (
    answers: Record<string, Record<string, any>>,
    tabs: TabT[]
): TabAnswers[] => {
    return tabs
        .map(tab => {
            const subTabs: SubTabAnswers[] = (tab.children || []).map(subTab => {
                const subTabsQuestions: QuestionWithAnswer[] = [];
                (subTab.children || []).forEach(group => {
                    const questions: QuestionWithAnswer[] = processChildren(
                        group.children,
                        answers[subTab.data.label!]
                    );
                    subTabsQuestions.push(...questions);
                    return {
                        groupLabel: group.data.label || "",
                        questions: questions,
                    };
                });

                return {
                    subTabLabel: subTab.data.label || "",
                    countAmountSum: subTab.data.countAmount,
                    questions: subTabsQuestions.filter(Boolean),
                };
            });

            return {
                tabLabel: tab.data.label || "",
                splitAnswers: !!tab.data.splitAnswers,
                subTabs: subTabs,
            };
        })
        .filter(tab => tab.subTabs.length);
};

export const getAnswerString = (answer: any) => {
    if (typeof answer === "boolean") {
        return answer ? "yes" : "no";
    } else if (answer instanceof Date) {
        return answer.toLocaleString();
    } else {
        return answer?.toString();
    }
};

export const getTotalRequiredQuestions = (questions: Questions) => {
    return Object.values(questions).filter(q => q.required).length;
};

export const isAnswerValid = (answer?: string, regexString?: string): boolean => {
    if (!regexString || !answer) return true;
    const regex = new RegExp(regexString.slice(1, -1));
    return regex.test(answer);
};

export const isBindAmountValid = (answer?: string, formula?: string, bindQuestionValue?: string): boolean => {
    if (!formula || !answer || bindQuestionValue === undefined) {
        return true;
    }

    const conditionMatch = formula.match(/^number(<=|>=|<|>)(bindQuestionValue)$/);
    if (!conditionMatch) {
        return true;
    }

    const operator = conditionMatch[1];
    const compareValue = parseFloat(bindQuestionValue);

    const numberPattern = /^-?\d+(\.\d+)?$/;
    if (!numberPattern.test(answer)) {
        return true;
    }

    const inputNumber = parseFloat(answer);

    switch (operator) {
    case "<":
        return inputNumber < compareValue;
    case ">":
        return inputNumber > compareValue;
    case "<=":
        return inputNumber <= compareValue;
    case ">=":
        return inputNumber >= compareValue;
    default:
        return true;
    }
};

export const checkValidAnswer = (answer: any, validation?: ValidationType): boolean => {
    if (Array.isArray(answer)) {
        return answer?.length > 0;
    } else if (typeof answer === "string") {
        const isValid = isAnswerValid(answer, validation?.regex) && answer.trim().length > 0;
        return isValid;
    } else if (typeof answer === "object" && Object.keys(answer).length === 0) {
        return false;
    } else {
        return answer !== undefined;
    }
};

export const getAnsweredRequiredQuestions = (
    questions: Questions,
    answers: Record<string, any>,
    groupBindValidationQuestions?: Record<string, { required: boolean, bindValidation: BindValidationType }>
) => {
    const totalAnsweredRequiredQuestions = Object.entries(questions).filter(([questionId, requirement]) => {
        if (requirement.required) {
            const answer = answers[questionId];
            const hasValidAnswer = checkValidAnswer(answer, requirement.validation);
            return hasValidAnswer;
        }
        return false;
    }).length;

    const totalNotAnsweredBindQuestions = groupBindValidationQuestions && Object.entries(groupBindValidationQuestions).filter(([questionId, requirement]) => {
        if (requirement.required) {
            const answer = answers[questionId];
            const bindAnswer = answers[requirement.bindValidation.depends];
            const hasValidAnswer = isBindAmountValid(answer, requirement.bindValidation.formula, bindAnswer);
            return !hasValidAnswer;
        }
        return false;
    }).length;

    return totalAnsweredRequiredQuestions - (totalNotAnsweredBindQuestions || 0);
};

export const isGroupShown = (group: GroupT, answers: Record<string, any>): boolean => {
    const show1 = checkDepend(group.depend, answers);
    const show2 = checkDepend(group.andDepend, answers);
    return show1 && show2;
};

export const getStepsCount = (tab: TabT, completedSubTabs: { [key: string]: boolean }) => {
    const totalSteps = tab.children?.length || 0;
    const completedSteps = tab.children?.filter(subTab => subTab.data.label && completedSubTabs[subTab.data.label]).length || 0;

    const stepLabel = totalSteps > 1 ? "steps" : "step";

    return completedSteps
        ? `${completedSteps} of ${totalSteps} ${stepLabel} complete`
        : `${totalSteps} ${stepLabel}`;
};

export const findAllFileSectionsData = (tabs: TabT[]): string[] => {
    const categories: any[] = [];

    const extractCategories = (children: ChildrenT[]) => {
        children.forEach(child => {
            if (child.type === QuestionType.DocumentsUpload && child.data && child.data.category) {
                categories.push(child.data);
            }
            if (child.children && child.children.length > 0) {
                extractCategories(child.children);
            }
        });
    };

    tabs.forEach(tab => {
        if (tab.children) {
            tab.children.forEach(subTab => {
                if (subTab.children) {
                    subTab.children.forEach(group => {
                        if (group.children) {
                            extractCategories(group.children);
                        }
                    });
                }
            });
        }
    });

    return categories;
};

export const getInitProgress = (tabs: TabT[], answersProgress?: ProgressT): ProgressT => {
    return tabs.reduce((acc, tab) => {
        const subTabProgress = tab.children?.reduce((subTabAcc, subTab) => {
            const subTabProgress = answersProgress && answersProgress[subTab.data.label!];
            const groupProgress = subTab.children?.reduce((groupAcc, group) => {
                groupAcc[group.id] = {
                    value: subTabProgress?.groups[group.id]?.value || 0,
                    toCalculation: !(group.depend && group.depend.length > 0),
                };
                return groupAcc;
            }, {} as GroupProgressT);

            subTabAcc[subTab.data.label!] = { groups: groupProgress! };
            return subTabAcc;
        }, {} as ProgressT);

        return { ...acc, ...subTabProgress };
    }, {} as ProgressT);
};
