import { FC, useEffect, useMemo, useState } from "react";

import questionIcon from "assets/images/new-questionnaire/question_icon.svg";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { checkDepends } from "utils/new-questionnaire";

import { Question } from "../../constants/questions";
import { PromptModalType, QuestionType } from "../../enums/enum";
import AmountListQuestion from "../AmountListQuestion/AmountListQuestion";
import DateListQuestion from "../DateQuestion/DateListQuestion";
import PromptModal from "../Shared/PromptModal";
import TextListQuestion from "../TextListQuestion/TextListQuestion";

type QuestionsListProps = {
    data: Question;
    answers: any;
    categoryAnswers: Record<string, any>;
    onAnswer: (_question: string, _answer: any, _questionType?: string) => void;

};

const QuestionsList: FC<QuestionsListProps> = ({ data, answers, categoryAnswers, onAnswer }) => {
    const [showModalOpener, setShowModalOpener] = useState(false);

    const promptModalData = useMemo(() => ({
        type: data.promptModal?.type || PromptModalType.info,
        title: data.promptModal?.title || "",
        text: data.promptModal?.text || "",
        image: data.promptModal?.image || "",
        promptActionButtonText: data.promptModal?.promptActionButtonText || "",
        promptActionButtonUrl: data.promptModal?.promptActionButtonUrl || "",
    }), [data.promptModal]);

    const {
        isOpened: isQuestionHelpModalOpened,
        open: handleQuestionHelpOpenModal,
        close: handleQuestionHelpCloseModal,
    } = useModalOpener();

    useEffect(() => {
        if (data?.promptModal?.depend && answers) {
            const shouldModalShow = checkDepends(data.promptModal?.depend, categoryAnswers);
            setShowModalOpener(shouldModalShow);
            if (shouldModalShow && !data.promptModal?.manualOpen) {
                handleQuestionHelpOpenModal();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answers]);
    return (
        <div className="mt10">
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={{ margin: "4px auto" }}>
                {data.question}
                {showModalOpener && (
                    <span className="new-questionnaire__question-icon" onClick={handleQuestionHelpOpenModal}>
                        <img src={questionIcon} width={20} alt="help"/>
                    </span>
                )}
            </div>
            {data?.questions?.map((question, i) => {
                const shouldShow = checkDepends(question?.depend || [], categoryAnswers);

                // if (!shouldShow) onAnswer(question.question, undefined);

                return shouldShow ? (
                    <div key={i} className="mt30">
                        {question.type === QuestionType.amountListQuestion && (
                            <AmountListQuestion
                                data={question}
                                onAnswer={onAnswer}
                                initialValue={answers?.find((q: any) => question?.question === q?.question)?.answer}
                                focus={i === 0}
                            />
                        )}
                        {question.type === QuestionType.dateListQuestion && (
                            <DateListQuestion
                                data={question}
                                onAnswer={onAnswer}
                                initialValue={answers?.find((q: any) => question?.question === q?.question)?.answer}
                                focus={i === 0}
                            />
                        )}
                        {question.type === QuestionType.textListQuestion && (
                            <TextListQuestion
                                data={question}
                                onAnswer={onAnswer}
                                initialValue={answers?.find((q: any) => question?.question === q?.question)?.answer}
                                focus={i === 0}
                            />
                        )}
                    </div>
                ) : null;
            })}
            {isQuestionHelpModalOpened && (
                <PromptModal
                    isOpen={isQuestionHelpModalOpened}
                    closeModal={handleQuestionHelpCloseModal}
                    handlePrev={() => {}}
                    data={promptModalData}
                />
            )}
        </div>
    );
};

export default QuestionsList;
