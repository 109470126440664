import { useContext, useState } from "react";

import { Button, Col, Form, message, Modal, Row } from "antd";
import { AppContext } from "App";
import checkIcon from "assets/images/check.svg";
import closeIcon from "assets/images/close.svg";
import Spinner from "components/basic/Spinner/Spinner";
import UserEmailInput from "components/inputs/Form/Inputs/EmailInput";
import NinInput from "components/inputs/Form/Inputs/NinInput";
import { useForgotPasswordMutation } from "services/auth";
import { isEmailValid } from "utils/validation";

const ForgotPasswordModal = ({
    close,
}: {
    close: () => void;
}) => {
    const [form] = Form.useForm();
    const { isMobile } = useContext(AppContext);
    const [resetAllowed, setResetAllowed] = useState(false);
    const [resetLinkSent, setResetLinkSent] = useState(false);
    const [emailForCheck, setEmailForCheck] = useState("");

    const [forgotPassword, {
        isLoading,
    }] = useForgotPasswordMutation();

    const handleFormValuesChange = async (changedValues: any, allValues: any) => {
        if ("email" in changedValues) {
            const { email } = allValues;
            const isValidEmail = isEmailValid(email);
            setEmailForCheck(isValidEmail ? email : "");
            form.setFieldsValue({ "nin": "" });
        }
    };

    return <Modal
        open
        className="forgot-password__modal"
        width={isMobile ? 354 : 478}
        closeIcon={<img src={closeIcon} alt="close"/>}
        title={!resetLinkSent && (
            <div className="reset__title">
                Reset Password
            </div>
        )}
        afterClose={() => form.resetFields()}
        destroyOnClose
        onCancel={() => close()}
        footer={!resetLinkSent && (
            <div>
                <Button
                    type="primary"
                    shape="round"
                    disabled={!resetAllowed}
                    htmlType="submit"
                    onClick={() => form.submit()}
                    className={"reset-button"}
                >
                    Reset password
                </Button>
            </div>
        )}
    >
        <div>
            {resetLinkSent
                ? <Col className="forgot-password__content">
                    <img src={checkIcon} alt="close"/>
                    <div className="auth__forgot-password-text mt24">
                        Instructions have been sent to your email.
                    </div>
                </Col>
                : <Spinner isLoading={isLoading}>
                    <Form
                        form={form}
                        layout={"vertical"}
                        onValuesChange={handleFormValuesChange}
                        onFinish={values => {
                            forgotPassword({
                                email: values.email.trim(),
                            })
                                .unwrap()
                                .then(() => {
                                    setResetLinkSent(true);
                                })
                                .catch(async error => {
                                    await message.error(error.data.message);
                                });
                        }}
                    >
                        <Row>
                            <Col span={24}>
                                <div className="auth__forgot-password-text">
                                    Enter your email address & NIN, and we’ll send you a link to reset your password.
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={16} className={"mt20"}>
                            <Col span={24}>
                                <UserEmailInput setAllowed={setResetAllowed} autoFocus required/>
                            </Col>
                        </Row>
                        <Row gutter={16} className={""}>
                            <Col span={24}>
                                <NinInput disabled={!emailForCheck} email={emailForCheck} setResetAllowed={setResetAllowed} required/>
                            </Col>
                        </Row>
                    </Form>
                </Spinner>
            }
        </div>
    </Modal>;
};

export default ForgotPasswordModal;
