import { Dispatch, SetStateAction, useState } from "react";

import EyeInvisibleOutlined from "@ant-design/icons/lib/icons/EyeInvisibleOutlined";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import { Form, Input } from "antd";
import { useCheckResetPasswordAvailabilityMutation } from "services/auth";
import { normalizeNIN } from "utils/validation";

import Error from "../Labels/Error";
import Label from "../Labels/Label";

const NinInput = ({
    required = false,
    autoFocus = false,
    disabled = false,
    name = "nin",
    label = "NIN",
    email,
    setResetAllowed,
}: {
    required?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    name?: string;
    label?: string;
    email: string
    setResetAllowed: Dispatch<SetStateAction<boolean>>
}) => {
    const [isResetAvailable] = useCheckResetPasswordAvailabilityMutation();
    const [ninInputType, setNinInputType] = useState("password");

    return <div>
        <Form.Item
            hasFeedback
            name={name}
            label={<Label text={label}/>}
            required={required}
            labelAlign={"left"}
            rules={[{
                required,
                message: <Error text={"Please input the nin!"}/>,
            }, {
                validator: async (_, value) => {
                    if (!value || value.length < 8) {
                        setResetAllowed(false);
                        return Promise.reject(<Error text={"Please enter a valid NIN"}/>);
                    }

                    const validateResetAvailability = async () => {
                        try {
                            const { available } = await isResetAvailable({ email, nin: normalizeNIN(value)! }).unwrap();
                            setResetAllowed(available);
                            return available
                                ? Promise.resolve()
                                : Promise.reject(<Error text={"NIN doesn’t match with your email. Enter your correct NIN."}/>);
                        } catch (error) {
                            console.log(error);
                            setResetAllowed(false);
                            return Promise.reject(<Error text={"Error checking reset availability. Please try again."}/>);
                        }
                    };

                    return validateResetAvailability();
                },
            }]}>
            <Input

                type={ninInputType}
                placeholder={"Enter your NIN"}
                data-testid={name}
                style={{
                    height: "48px",
                    borderRadius: "4px",
                }}
                size="large"
                suffix={ninInputType === "password"
                    ? <EyeInvisibleOutlined onClick={() => setNinInputType("text")}/>
                    : <EyeOutlined onClick={() => setNinInputType("password")}/>
                }
                autoFocus={autoFocus}
                disabled={disabled}
            />
        </Form.Item>
    </div>;
};

export default NinInput;
