import "styles/transactionsSort.less";
import React, { useState } from "react";

import { SortAscendingOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";

type Props = {
    onApplyFiltersSortSearch: (_filters: Record<string, any>) => void;
};

const TransactionsSort: React.FC<Props> = ({ onApplyFiltersSortSearch }) => {
    const [, setSortBy] = useState<string>("-1");

    const handleSortChange = (value: string) => {
        setSortBy(value);
        onApplyFiltersSortSearch({ sortBy: value });
    };

    const menu = (
        <Menu
            onClick={({ key }) => handleSortChange(key)}
            items={[
                { key: "-1", label: "Most Recent ➡️ Oldest" },
                { key: "1", label: "Oldest ➡️ Most Recent" },
            ]}
        />
    );

    return (
        <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
            <Button className="transactionsSort__button" type="text" shape="round" icon={<SortAscendingOutlined />}>
                Sort
            </Button>
        </Dropdown>
    );
};

export default TransactionsSort;
