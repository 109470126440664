import { FC, ChangeEvent, useCallback, useEffect, useRef, useState, useMemo, useContext } from "react";

import { AppContext } from "App";
import errorIcon from "assets/images/new-questionnaire/exclamationmark-circle.svg";
import infoIcon from "assets/images/new-questionnaire/info_icon.svg";
import { isAnswerValid, validateAmount } from "utils/new-questionnaire";

import { Question } from "../../constants/questions";
import { QuestionType } from "../../enums/enum";

type AmountListQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: string | undefined, _questionType?: string) => void;
    initialValue?: any;
    focus?: boolean;
};

const AmountListQuestion: FC<AmountListQuestionProps> = ({ data, onAnswer, initialValue, focus }) => {
    const [amount, setAmount] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const [isFocused, setIsFocused] = useState(false);
    const isMobile = useContext(AppContext);

    const inputRef = useRef<HTMLInputElement>(null);
    const helpText = useMemo(() => data.infoText, [data]);

    const formatAmountWithCommas = useCallback((value: string) => {
        if (!value) return "";
        const [integerPart, decimalPart] = value.split(".");
        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return decimalPart !== undefined ? `${formattedInteger}.${decimalPart}` : formattedInteger;
    }, []);

    const handleAmountChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/[^\d.]/g, "");
        if (value.includes(".")) {
            const [integer, decimal] = value.split(".");
            value = `${integer}.${decimal.slice(0, 2)}`;
        }
        value = formatAmountWithCommas(value);

        if (amount !== value) {
            setAmount(value);
        }

        const numericValue = value.replace(/,/g, "");
        if (!validateAmount(numericValue)) {
            setError("Please enter a valid amount.");
            return;
        } else {
            setError(null);
        }

        if (data.validation) {
            const isValid = isAnswerValid(numericValue, data.validation.regExp);
            if (!isValid) {
                setError(data.validation.error);
            } else {
                setError(null);
            }
        }
    }, [amount, data.validation, formatAmountWithCommas]);

    const handleWrapperClick = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            if (isMobile) {
                e.currentTarget.blur();
            } else {
                e.preventDefault();
            }
        }
    };

    useEffect(() => {
        if (error) {
            onAnswer(data.question, undefined, QuestionType.amountQuestion);
        } else if (amount !== initialValue) {
            onAnswer(data.question, amount, QuestionType.amountQuestion);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount, error, initialValue]);

    useEffect(() => {
        if (focus) {
            const focusInput = () => {
                if (inputRef.current) {
                    inputRef.current.focus();
                    inputRef.current.click();
                }
            };

            const timer = setTimeout(focusInput, 10);

            return () => clearTimeout(timer);
        }
    }, [focus]);

    useEffect(() => {
        setAmount(initialValue || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.question]);

    return (
        <div className="new-questionnaire__list-question">
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                {data.question}
            </div>
            <div
                className="new-questionnaire__question-input-wrapper"
                style={{ borderBottom: isFocused ? "4px solid #7F43FF" : "4px solid #C4C4C4", marginTop: "10px" }}
                onClick={handleWrapperClick}
            >
                <div className="new-questionnaire__amount-question-input-prefix">£</div>
                <input
                    className="new-questionnaire__amount-question-input"
                    onChange={handleAmountChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    type="text"
                    inputMode="decimal"
                    pattern="[0-9]*"
                    autoFocus={true}
                    value={amount}
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                />
            </div>
            {helpText && (
                <div className="new-questionnaire__info-message">
                    <img src={infoIcon} width={20} alt="info" />
                    <div className="new-questionnaire__info-message-text ml4">
                        {helpText}
                    </div>
                </div>
            )}
            {error && (
                <div className="new-questionnaire__error-message">
                    <img src={errorIcon} width={20} alt="error" />
                    <div className="new-questionnaire__error-message-text ml4">
                        {error}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AmountListQuestion;
