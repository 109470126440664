import { FC, useEffect, useMemo, useRef, useState } from "react";

import radioDefault from "assets/images/new-questionnaire/radio_default.svg";
import radioSelected from "assets/images/new-questionnaire/radio_selected.svg";
import { IUser } from "types/auth";
import { formatRawAddress } from "utils/new-questionnaire";

import { Question } from "../../constants/questions";

type SelectOneOptionQuestionProps = {
    user: IUser
    data: Question;
    onAnswer: (_question: string, _answer: string | undefined) => void;
    initialValue?: any;
    handleContinue: () => void;
};

const SelectOneOptionQuestion: FC<SelectOneOptionQuestionProps> = ({ user, data, onAnswer, initialValue, handleContinue }) => {
    const [selectedOption, setSelectedOption] = useState<string>(initialValue || "");
    const userAddress = useMemo(() => formatRawAddress(user.address), [user.address]);
    const optionHasBeenClickedRef = useRef(false); // Ref to track if an option was clicked

    const handleOptionChange = (value: string) => {
        setSelectedOption(value);
        onAnswer(data.question, value);
        optionHasBeenClickedRef.current = true; // Update the ref value
    };

    // Helper function to render the correct option text
    const getOptionText = (option: string) => {
        if (data.question === "Select the address of your business" && option === "Account address") {
            return userAddress;
        }
        return option;
    };

    useEffect(() => {
        setSelectedOption(initialValue || "");

        if (initialValue && optionHasBeenClickedRef.current) {
            handleContinue();
            optionHasBeenClickedRef.current = false; // Reset the ref value
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    return (
        <div className="new-questionnaire__question">
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                {data.question}
            </div>
            {data.infoText && <div className="new-questionnaire__info-text">
                {data.infoText}
            </div>}

            <div className="new-questionnaire__select-one-option__question-options mt10">
                {data?.options?.map((value, index) => (
                    <div
                        key={index}
                        className="new-questionnaire__select-one-option__question-option"
                        onClick={() => handleOptionChange(value)}
                    >
                        <div className="new-questionnaire__select-one-option__text">
                            {getOptionText(value)}
                        </div>
                        <img src={selectedOption === value ? radioSelected : radioDefault} alt="selector" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SelectOneOptionQuestion;
