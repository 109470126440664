import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { TaxProfile } from "types/client";
import { ArrayAnswersType } from "types/questionnaire";

interface IncompleteProfile {
    itemId: string;
    missingQuestions: string[];
}

interface TaxProfileState {
    isEditingProfile: boolean;
    currentProfile: TaxProfile | undefined;
    currentProfileAnswers: ArrayAnswersType["answers"];
    incompleteProfiles: IncompleteProfile[];
    taxProfiles: TaxProfile[];
}

const initialState: TaxProfileState = {
    isEditingProfile: false,
    currentProfile: undefined,
    currentProfileAnswers: [],
    incompleteProfiles: [],
    taxProfiles: [],
};

const taxProfileSlice = createSlice({
    name: "taxProfile",
    initialState,
    reducers: {
        setIsEditingProfile(state, action: PayloadAction<boolean>) {
            state.isEditingProfile = action.payload;
        },
        setCurrentProfile(state, action: PayloadAction<TaxProfile | undefined>) {
            state.currentProfile = action.payload ? JSON.parse(JSON.stringify(action.payload)) : undefined;
        },
        setCurrentProfileAnswers(state, action: PayloadAction<ArrayAnswersType["answers"]>) {
            state.currentProfileAnswers = action.payload;
        },
        setIncompleteProfiles(state, action: PayloadAction<IncompleteProfile[]>) {
            state.incompleteProfiles = action.payload;
        },
        setAllTaxProfiles: (state, action: PayloadAction<TaxProfile[]>) => {
            state.taxProfiles = action.payload;
        },
        resetTaxProfile: () => initialState,
    },
});

export const selectTaxProfile = createSelector(
    (state: RootState) => state.taxProfile,
    taxProfile => ({
        currentProfile: taxProfile.currentProfile,
        currentProfileAnswers: taxProfile.currentProfileAnswers,
        isEditingProfile: taxProfile.isEditingProfile,
    })
);

export const {
    setIsEditingProfile,
    setCurrentProfile,
    setCurrentProfileAnswers,
    setIncompleteProfiles,
    setAllTaxProfiles,
    resetTaxProfile,
} = taxProfileSlice.actions;

export default taxProfileSlice.reducer;
