import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { useModalOpener } from "hooks/helpers/useModalOpener";
import { ArrayAnswersType } from "types/questionnaire";
import { checkDepends } from "utils/new-questionnaire";

import { Depend, Question } from "../../constants/questions";
import { PromptModalType, QuestionType } from "../../enums/enum";
import PromptModal from "../Shared/PromptModal";
import SwipeableCards from "../SwipeableCards/SwipeableCards";

type CardsAnswersType = {
    question: string;
    id: string;
    answer: any;
    type: string;
}[];

type CardArrayQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: any) => void;
    handlePrev: () => void;
    initialValue?: any;
    handleContinue: (_answers?: CardsAnswersType) => void;
    arrayAnswers?: ArrayAnswersType["answers"];
    insertItemName?: boolean;
    itemName?: string;
};

const CardArrayQuestion: FC<CardArrayQuestionProps> = ({ data, onAnswer, handleContinue, handlePrev, arrayAnswers, insertItemName, itemName }) => {
    const [currentCardIndex, setCurrentCardIndex] = useState<number>(data.cards!.length - 1);
    const [, setCardsAnswers] = useState<CardsAnswersType>([]);
    const [cardSwipeAnswers, setCardSwipeAnswers] = useState<Record<string, any>>({});
    const [hideSwipeableCards, setHideSwipeableCards] = useState<boolean>(false);
    const [showModalOpener, setShowModalOpener] = useState(false);

    const promptModalData = useMemo(() => ({
        type: data.promptModal?.type || PromptModalType.info,
        title: data.promptModal?.title || "",
        text: data.promptModal?.text || "",
        image: data.promptModal?.image || "",
    }), [data.promptModal]);

    const {
        isOpened: isQuestionHelpModalOpened,
        open: handleQuestionHelpOpenModal,
        close: handleQuestionHelpCloseModal,
    } = useModalOpener();

    const onAnswerChangeCardSwipe = useCallback(
        (question: string | undefined, answer: string | undefined, id: string) => {
            // If answer is undefined, it means we press Prev button
            if (answer === undefined) {
                setCurrentCardIndex(prevIndex => prevIndex + 1);
            }

            setCardsAnswers(prev => {
                const newAnswers = prev.filter(q => q?.question !== question);

                if (answer !== undefined) {
                    newAnswers.push({ question: question!, answer: answer, type: QuestionType.cardArrayQuestion, id });
                }

                if (currentCardIndex > 0) {
                    setCurrentCardIndex(prevIndex => prevIndex - 1);
                } else {
                    onAnswer(data?.question, newAnswers);
                    setCardsAnswers([]);
                    setTimeout(() => {
                        handleContinue(newAnswers);
                    }, 10);
                }

                return newAnswers;
            });

            setCardSwipeAnswers(prev => {
                if (answer !== undefined) {
                    return { ...prev, [question!]: answer };
                } else {
                    const { [question!]: _, ...rest } = prev;
                    return rest;
                }
            });
        },
        [setCardsAnswers, setCardSwipeAnswers, handleContinue, onAnswer, data, currentCardIndex]
    );

    const checkModalDepends = useCallback((depend: Depend[], categoryAnswers?: Record<string, any>) => {
        if (categoryAnswers) {
            return checkDepends(depend, categoryAnswers);
        } else {
            return false;
        }
    }, []);

    useEffect(() => {
        // Reset state when data changes (case when cardArrayQuestion goes after cardArrayQuestion)
        if (data) {
            setHideSwipeableCards(true);
            setCurrentCardIndex(data.cards!.length - 1);
            setCardsAnswers([]);
            setCardSwipeAnswers({});
            setTimeout(() => {
                setHideSwipeableCards(false);
            }, 10);
        }

        if (data?.promptModal?.depend && arrayAnswers) {
            const shouldModalShow = checkModalDepends(data.promptModal?.depend, arrayAnswers);
            setShowModalOpener(shouldModalShow);
            if (shouldModalShow && !data.promptModal?.manualOpen) {
                handleQuestionHelpOpenModal();
            }
        } else {
            setShowModalOpener(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div className="new-questionnaire__question">
            {!hideSwipeableCards && (
                <SwipeableCards
                    cards={data?.cards || []}
                    onAnswerChange={onAnswerChangeCardSwipe}
                    title={data?.question}
                    description={data.infoText || ""}
                    categoryAnswers={cardSwipeAnswers}
                    insertItemName={insertItemName}
                    itemName={itemName}
                    showModalOpener={showModalOpener}
                    handleStepHelpOpenModal={handleQuestionHelpOpenModal}
                />
            )}

            {isQuestionHelpModalOpened && (
                <PromptModal
                    isOpen={isQuestionHelpModalOpened}
                    closeModal={handleQuestionHelpCloseModal}
                    data={promptModalData}
                    handlePrev={handlePrev}
                />
            )}
        </div>
    );
};

export default CardArrayQuestion;
