import adapter from "assets/images/new-questionnaire/iconsMapping/adapter.svg";
import baby from "assets/images/new-questionnaire/iconsMapping/baby.svg";
import bank from "assets/images/new-questionnaire/iconsMapping/bank.svg";
import bed from "assets/images/new-questionnaire/iconsMapping/bed.svg";
import benefit from "assets/images/new-questionnaire/iconsMapping/benefit.svg";
import bicycle from "assets/images/new-questionnaire/iconsMapping/bicycle.svg";
import boat from "assets/images/new-questionnaire/iconsMapping/boat.svg";
import building from "assets/images/new-questionnaire/iconsMapping/building.svg";
import bus from "assets/images/new-questionnaire/iconsMapping/bus.svg";
import call from "assets/images/new-questionnaire/iconsMapping/call.svg";
import car from "assets/images/new-questionnaire/iconsMapping/car.svg";
import card from "assets/images/new-questionnaire/iconsMapping/card.svg";
import chair from "assets/images/new-questionnaire/iconsMapping/chair.svg";
import chart from "assets/images/new-questionnaire/iconsMapping/chart.svg";
import cis from "assets/images/new-questionnaire/iconsMapping/cis.svg";
import cleaning from "assets/images/new-questionnaire/iconsMapping/cleaning.svg";
import clip from "assets/images/new-questionnaire/iconsMapping/clip.svg";
import cloud from "assets/images/new-questionnaire/iconsMapping/cloud.svg";
import coins from "assets/images/new-questionnaire/iconsMapping/coins.svg";
import company from "assets/images/new-questionnaire/iconsMapping/company.svg";
import construction from "assets/images/new-questionnaire/iconsMapping/construction.svg";
import cross from "assets/images/new-questionnaire/iconsMapping/cross.svg";
import crypto from "assets/images/new-questionnaire/iconsMapping/crypto.svg";
import delivery from "assets/images/new-questionnaire/iconsMapping/delivery.svg";
import desk from "assets/images/new-questionnaire/iconsMapping/desk.svg";
import electricity from "assets/images/new-questionnaire/iconsMapping/electricity.svg";
import file from "assets/images/new-questionnaire/iconsMapping/file.svg";
import film from "assets/images/new-questionnaire/iconsMapping/film.svg";
import fitness from "assets/images/new-questionnaire/iconsMapping/fitness.svg";
import foreign from "assets/images/new-questionnaire/iconsMapping/foreign.svg";
import fromIcon from "assets/images/new-questionnaire/iconsMapping/from.svg";
import gain from "assets/images/new-questionnaire/iconsMapping/gain.svg";
import gains from "assets/images/new-questionnaire/iconsMapping/gains.svg";
import gas from "assets/images/new-questionnaire/iconsMapping/gas.svg";
import gift from "assets/images/new-questionnaire/iconsMapping/gift.svg";
import glasses from "assets/images/new-questionnaire/iconsMapping/glasses.svg";
import guitar from "assets/images/new-questionnaire/iconsMapping/guitar.svg";
import hammer from "assets/images/new-questionnaire/iconsMapping/hammer.svg";
import health from "assets/images/new-questionnaire/iconsMapping/health.svg";
import heart from "assets/images/new-questionnaire/iconsMapping/heart.svg";
import holiday from "assets/images/new-questionnaire/iconsMapping/holiday.svg";
import home from "assets/images/new-questionnaire/iconsMapping/home.svg";
import house from "assets/images/new-questionnaire/iconsMapping/house.svg";
import incapacity from "assets/images/new-questionnaire/iconsMapping/incapacity.svg";
import installSystem from "assets/images/new-questionnaire/iconsMapping/install-system.svg";
import keyboard from "assets/images/new-questionnaire/iconsMapping/keyboard.svg";
import linkedin from "assets/images/new-questionnaire/iconsMapping/linkedin.svg";
import loss from "assets/images/new-questionnaire/iconsMapping/loss.svg";
import manufacture from "assets/images/new-questionnaire/iconsMapping/manufacture.svg";
import milage from "assets/images/new-questionnaire/iconsMapping/milage.svg";
import monitor from "assets/images/new-questionnaire/iconsMapping/monitor.svg";
import motorcycle from "assets/images/new-questionnaire/iconsMapping/motorcycle.svg";
import mouse from "assets/images/new-questionnaire/iconsMapping/mouse.svg";
import none from "assets/images/new-questionnaire/iconsMapping/none.svg";
import other from "assets/images/new-questionnaire/iconsMapping/other.svg";
import parking from "assets/images/new-questionnaire/iconsMapping/parking.svg";
import partnership from "assets/images/new-questionnaire/iconsMapping/partnership.svg";
import paye from "assets/images/new-questionnaire/iconsMapping/paye.svg";
import pencil from "assets/images/new-questionnaire/iconsMapping/pencil.svg";
import pension from "assets/images/new-questionnaire/iconsMapping/pension.svg";
import percentage from "assets/images/new-questionnaire/iconsMapping/percentage.svg";
import personFolder from "assets/images/new-questionnaire/iconsMapping/person-folder.svg";
import pie from "assets/images/new-questionnaire/iconsMapping/pie.svg";
import plain from "assets/images/new-questionnaire/iconsMapping/plain.svg";
import play from "assets/images/new-questionnaire/iconsMapping/play.svg";
import pound from "assets/images/new-questionnaire/iconsMapping/pound.svg";
import privatePension from "assets/images/new-questionnaire/iconsMapping/private-pension.png";
import publicService from "assets/images/new-questionnaire/iconsMapping/public.svg";
import repair from "assets/images/new-questionnaire/iconsMapping/repair.svg";
import retail from "assets/images/new-questionnaire/iconsMapping/retail.svg";
import road from "assets/images/new-questionnaire/iconsMapping/road.svg";
import sea from "assets/images/new-questionnaire/iconsMapping/sea.svg";
import security from "assets/images/new-questionnaire/iconsMapping/security.svg";
import selfEmployment from "assets/images/new-questionnaire/iconsMapping/self-employment.svg";
import statePension from "assets/images/new-questionnaire/iconsMapping/state-pension.svg";
import stop from "assets/images/new-questionnaire/iconsMapping/stop.svg";
import tag from "assets/images/new-questionnaire/iconsMapping/tag.svg";
import taxi from "assets/images/new-questionnaire/iconsMapping/taxi.svg";
import toIcon from "assets/images/new-questionnaire/iconsMapping/to.svg";
import tractor from "assets/images/new-questionnaire/iconsMapping/tractor.svg";
import tv from "assets/images/new-questionnaire/iconsMapping/tv.svg";
import van from "assets/images/new-questionnaire/iconsMapping/van.svg";
import wallet from "assets/images/new-questionnaire/iconsMapping/wallet.svg";
import water from "assets/images/new-questionnaire/iconsMapping/water.svg";

// ------------ Tiles Questions Icons Mapping ------------
export const PersonalInfoIconMapping = {
    "🧾 Self-Employed": selfEmployment,
    "💼 Director of a LTD company": company,
    "🧑‍💼 Employed (PAYE)": paye,
    "Self-employed Partnership": partnership,
    "🏠 Landlord": house,
    "🦺 Construction worker (CIS)": cis,
    "🌎 Foreign income": foreign,
    "🏠 Rental Income": house,
    "🧩 Dividends": chart,
    "🏦 Interest Income": percentage,
    "📈 Capital Gains/Losses": gains,
    "👴 Pension Income": pension,
    "💸 State Benefits": benefit,
};

export const CisIconMapping = {
    "Alterations, repairs and decoration": repair,
    "Building work": building,
    "Cleaning": cleaning,
    "Demolition and dismantling": hammer,
    "Installing systems": installSystem,
    "Other": other,
};

export const SelfEmployedIconMapping = {
    "🛵 Food Delivery": delivery,
    "🚚 Taxi or Courier": taxi,
    "📚 Teacher or Tutor": pencil,
    "🦺 Construction": construction,
    "💪 Health & Fitness": fitness,
    "🎶 Musician": guitar,
    "🏆 Entertainment or Sports": film,
    "🛒 Retail": retail,
    "🏥 Healthcare": health,
    "👀 Other": other,
};

export const EmploymentIconMapping = {
    "🚜 Agriculture & Forestry": tractor,
    "Aviation": plain,
    "🦺 Construction": construction,
    "🏥 Healthcare": health,
    "🏭 Manufacturing": manufacture,
    "📚 Public Service": publicService,
    "🔒 Security": security,
    "🏨 Hospitality": bed,
    "📈 Financial Services": bank,
    "💪 Health & Fitness": fitness,
    "🎥 Entertainment": film,
    "🎓 Education": pencil,
    "💼 Professional Services": call,
    "🚢 Seafarers": sea,
    "📡 Technology": tag,
    "🗞️ Media": tv,
    "👀 None of the above": none,
};

export const EmploymentStartEndIconMapping = {
    "I started work": play,
    "I ended work": stop,
    "I started and ended": stop,
    "No change": none,
};

export const P11DIconMapping = {
    "🤑 Assets transferred (e.g. property)": home,
    "🧾 Payments made on behalf of employee": coins,
    "💳 Voucher & credit cards": card,
    "🏠 Living accommodation": bed,
    "🚙 Mileage allowance payments not taxed at source": road,
    "Cars": car,
    "Car fuel": car,
    "🏦 Interest-free & low interest loans": bank,
    "🏥 Private medical treatment or insurance": cross,
    "📍 Qualifying relocation expenses payments": plain,
    "📋 Services supplied": publicService,
    "Assets placed at the employee's disposal": pound,
    "💷 Other items (professional fees/subscriptions)": cloud,
    "Expense payments made on behalf of the employee": wallet,
    "None": none,
};

export const VehiclesIconMapping = {
    "🚗 Car/Van": car,
    "🏍️ Motorcycle": motorcycle,
    "Bicycle": bicycle,
    "None": car,
};

export const WFHExpensesIconMapping = {
    "Desk": desk,
    "Chair": chair,
    "Monitor": monitor,
    "Mouse": mouse,
    "Keyboard": keyboard,
    "Adapters/Cables": adapter,
    "Stationery": clip,
    "Electricity": electricity,
    "Gas": gas,
    "Metered Water": water,
    "None": none,
};

export const CapitalIconMapping = {
    "Capital Gain": gain,
    "Capital Loss": loss,
};

export const AssetsIconMapping = {
    "Residential Property": home,
    "Listed Shares": pie,
    "Unlisted Shares": file,
    "Crypto-currency": crypto,
    "Other Assets": coins,
};

export const DividendsIconMapping = {
    "Domestic Dividends": pound,
    "Director Dividends": personFolder,
};

export const PensionIconMapping = {
    "State Pension": statePension,
    "Private Pension": privatePension,
};

export const GeneralOptionsIconMapping = {
    "Marriage Allowance": heart,
    "Blind Person Allowance": glasses,
    "Pension Contributions": boat,
    "Charitable Giving": gift,
    "Venture Capital Scheme": coins,
};

export const TransferIconMapping = {
    "Transfer from Partner": fromIcon,
    "Transfer to Partner": toIcon,
};

export const SubsistenceExpenseIconMapping = {
    "Subsistence": coins,
    "Public Transport": bus,
    "Parking": parking,
    "Accommodation Expenses": home,
};

export const StateBenefitsIconMapping = {
    "Child Benefit": baby,
    "Jobseeker's Allowance": linkedin,
    "Incapacity Benefit": incapacity,
    "None": none,
};

export const RentalIconMapping = {
    "Residential/Commercial": home,
    "Furnished Holiday Let": holiday,
    "Rent-a-Room": bed,
};

export const VehicleExpensesIconMapping = {
    "Total Vehicle Costs (Capital Allowances)": car,
    "Simple Mileage Rate": milage,
};

export const VehicleTypeIconMapping = {
    "Car": car,
    "Van/Truck": van,
    "Motorbike": motorcycle,
    "Bicycle": bicycle,
};
