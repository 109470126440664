import { FC, useCallback, useEffect, useRef, useState } from "react";

import radioDefault from "assets/images/new-questionnaire/radio_default.svg";
import radioSelected from "assets/images/new-questionnaire/radio_selected.svg";
import QuestionnaireButtonFooterV2 from "components/basic/Footer/QuestionnaireButtonFooterV2";
import ButtonLoading from "components/basic/Spinner/ButtonLoading";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { selectCurrentNavigation } from "redux/reducers/navigation";
import { setCurrentProfile } from "redux/reducers/taxProfile";
import { IUser } from "types/auth";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { removeEmojis } from "utils/strings";

import { useGetClientTaxProfileQuery, useUpdateClientTaxProfileMutation } from "../../../../../services/client";
import { TaxProfile } from "../../../../../types/client";
import { ArrayAnswersType } from "../../../../../types/questionnaire";
import { logError } from "../../../../../utils/sentry";
import { Question } from "../../constants/questions";
import { ProfileType } from "../../enums/enum";
import PromptModal from "../Shared/PromptModal";

type CardsAnswersType = {
    question: string;
    answer: any;
    type: string;
}[];

type SelectProfileTypeQuestionProps = {
    user: IUser
    data: Question;
    onAnswer: (_question: string, _answer: string | undefined) => void;
    handleContinue: (_answers: CardsAnswersType) => void;
    arrayAnswers: ArrayAnswersType["answers"];
    taxProfile: TaxProfile;
    initialValue?: any;
};

const SelectProfileTypeQuestion: FC<SelectProfileTypeQuestionProps> = ({ user, data, onAnswer, handleContinue, arrayAnswers, initialValue, taxProfile }) => {
    const dispatch = useDispatch();
    const isKeyboardOpen = useDetectKeyboardOpen();
    const { stepLoading } = useAppSelector(selectCurrentNavigation);
    const optionHasBeenClickedRef = useRef(false); // Ref to track if an option was clicked

    const [selectedOption, setSelectedOption] = useState<ProfileType>(initialValue || "");
    const [openModal, setOpenModal] = useState<boolean>(false);

    const editable = taxProfile.type === "";
    const [ updateClientTaxProfile ] = useUpdateClientTaxProfileMutation();
    const { refetch: refetchTaxProfile } = useGetClientTaxProfileQuery(
        {
            client: user?.id!,
            taxProfileId: taxProfile?._id!,
        },
        {
            skip: !user?.id || !taxProfile, // Skip the query if user or currentProfile is not available
        }
    );

    const handleConfirmType = useCallback(async () => {
        if (editable) {
            await updateClientTaxProfile({
                taxProfileId: taxProfile._id,
                client: taxProfile.client,
                type: selectedOption,
            })
                .unwrap()
                .then(response => {
                    dispatch(setCurrentProfile(response));
                    refetchTaxProfile?.();
                    handleContinue(arrayAnswers);
                })
                .catch(error => {
                    logError(error);
                });
        } else {
            handleContinue(arrayAnswers);
        }
    }, [editable, updateClientTaxProfile, taxProfile._id, taxProfile.client, selectedOption, refetchTaxProfile, handleContinue, arrayAnswers, dispatch]);

    const handleOptionChange = (value: string) => {
        if (stepLoading) return;
        if (editable) {
            const profileType = value as ProfileType;
            setSelectedOption(profileType);
            onAnswer(data.question, profileType);
            optionHasBeenClickedRef.current = true; // Update the ref value
        } else {
            setOpenModal(true);
        }
    };

    useEffect(() => {
        setSelectedOption(initialValue || "");

        if (initialValue && optionHasBeenClickedRef.current) {
            handleConfirmType();
            optionHasBeenClickedRef.current = false; // Reset the ref value
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    return (
        <div className="new-questionnaire__question" style={{ maxWidth: "335px", margin: "26px auto 0" }}>
            {openModal && data.errorModal && <PromptModal
                isOpen={openModal}
                closeModal={() => setOpenModal(false)}
                data={data.errorModal!}
                handlePrev={() => {}}
            />}
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                {data.question}
            </div>
            <div className="new-questionnaire__select-one-optionV2__question-options">
                {data?.options?.map((value, index) => (
                    <div
                        key={index}
                        className={`new-questionnaire__select-one-optionV2__question-option${selectedOption === value ? " selected" : stepLoading ? " disabled" : ""}`}
                        onClick={() => handleOptionChange(value)}
                    >
                        {data.iconMapping && (
                            <div>
                                <img width={30} height={30} src={data.iconMapping[value]} alt="icon" style={{ display: "block" }} />
                            </div>
                        )}
                        <div className="new-questionnaire__select-many-tiles__text">
                            {data.iconMapping ? removeEmojis(value) : value}
                        </div>
                        <div className="new-questionnaire__select-many-tiles__checkbox">
                            <img src={selectedOption === value ? radioSelected : radioDefault} alt="selector" />
                        </div>
                    </div>
                ))}
            </div>
            <div className={`${isKeyboardOpen ? "" : "mt120"}`}>
                <QuestionnaireButtonFooterV2>
                    <div
                        className={`new-questionnaire__form-continue ${selectedOption && !stepLoading ? "" : "disabled"} mt30`}
                        onClick={() => {
                            if (selectedOption && !stepLoading) {
                                handleConfirmType();
                            }
                        }}
                    >
                        {stepLoading
                            ? <ButtonLoading/>
                            : <span>{"Continue"}</span>
                        }
                    </div>
                </QuestionnaireButtonFooterV2>
            </div>
        </div>
    );
};

export default SelectProfileTypeQuestion;
