import { useEffect, useState } from "react";

import RevolutCheckout from "@revolut/checkout";
import "styles/paywall/appleGooglePayWidget.less";

interface AppleGooglePayWidgetProps {
  orderToken: string | null;
  amountInPence: number;
  revolutEnvironment: "prod" | "sandbox";
  platform: "google" | "apple";
  handlePaymentSuccess: (_orderToken: string) => void;
  handlePaymentFailure: (_orderToken: string, _errorMessage: string) => void
}

const AppleGooglePayWidget = ({ orderToken, amountInPence, revolutEnvironment, handlePaymentFailure, handlePaymentSuccess, platform }: AppleGooglePayWidgetProps) => {
    const [methodUnauthorized, setMethodUnauthorized] = useState(false);
    const [amount, setAmount] = useState(0);

    const target = document.getElementById(platform);

    useEffect(() => {
        const buttonsRendered = target?.hasChildNodes();
        if (!orderToken || !amountInPence || !revolutEnvironment || !target || buttonsRendered || (amount === amountInPence)) {
            return;
        }
        setAmount(amountInPence);
        const loadWidget = async () => {
            const checkout: any = await RevolutCheckout.payments({
                locale: "en",
                publicToken: process.env.REACT_APP_REVOLUT_PUBLIC_MERCHANT_KEY || "",
                mode: revolutEnvironment,
            });
            const paymentRequest = checkout.paymentRequest;

            const paymentRequestInstance = paymentRequest(target, {
                currency: "GBP",
                amount: amountInPence,
                createOrder: async () => {
                    return { publicId: orderToken };
                },
                onSuccess() {
                    handlePaymentSuccess(orderToken);
                },
                onError(error: any) {
                    handlePaymentFailure(orderToken, error.message);
                },
            });

            const method: string = await paymentRequestInstance.canMakePayment();

            if (method && method.toLowerCase().includes(platform)) {
                paymentRequestInstance.render();
            } else {
                setMethodUnauthorized(true);
                paymentRequestInstance.destroy();
            }
        };
        loadWidget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amountInPence, handlePaymentFailure, handlePaymentSuccess, target]);

    return (
        <>
            {!methodUnauthorized && <div id={platform} className="apple-google-button"></div>}
        </>
    );
};

export default AppleGooglePayWidget;
