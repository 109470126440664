import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

interface AnswersState {
    answers: Record<string, Record<string, any>>;
}

const initialState: AnswersState = {
    answers: window.localStorage.getItem("new_questionnaire_answers")
        ? JSON.parse(window.localStorage.getItem("new_questionnaire_answers") as string)
        : {},
};

const answersSlice = createSlice({
    name: "answers",
    initialState,
    reducers: {
        setAnswers: (state, action: PayloadAction<Record<string, Record<string, any>>>) => {
            // Save answers to localStorage as a fallback for now
            window.localStorage.setItem("new_questionnaire_answers", JSON.stringify(action.payload));
            state.answers = action.payload;
        },
        resetAnswers: () => {
            window.localStorage.setItem("new_questionnaire_answers", JSON.stringify({}));
            return { answers: {} };
        },
    },
});

export const selectAnswers = createSelector(
    (state: RootState) => state.answers,
    answers => answers
);

export const { setAnswers, resetAnswers } = answersSlice.actions;
export default answersSlice.reducer;
