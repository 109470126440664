import { useContext, useEffect, useState } from "react";

import { Alert, Typography } from "antd";
import { AppContext } from "App";
import Confetti from "assets/animation/confetti-blown-up.json";
import backChevron from "assets/images/new-questionnaire/back-chevron.svg";
import purpleInfoIcon from "assets/images/new-questionnaire/purple-info.svg";
import BankDetailsForm from "components/basic/BankDetailsForm/BankDetailsForm";
import LottieAnimation from "components/basic/Spinner/Lottie";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { useCompleteDocumentMutation } from "services/auth";
import { IUser } from "types/auth";

import IdPalStartPage from "./IDPal/StartPage";
import { TaxSummary } from "./TaxSummary";
import { useAppSelector } from "../../../../../redux/hooks";
import { RootState } from "../../../../../redux/store";
import "../../../../../styles/questionnaire/submitPage.less";
import { idPalApi } from "../../../../../services/idpal";

interface ISubmitPageProps {
    user: IUser;
}

/* eslint-disable */
enum SubmitStep {
    ID_VERIFICATION = "id-verification",
    BANK_DETAILS = "bank-details",
    TAX_SUMMARY = "tax-summary",
}
/* eslint-enable */

export const SubmitPage = ({ user }: ISubmitPageProps) => {
    const navigate = useNavigate();
    const { isMobile } = useContext(AppContext);
    const { currentDocument } = useAppSelector(
        (state: RootState) => ({
            currentDocument: state.document.currentDocument,
            currentDocumentYear: state.document.currentDocumentYear,
        })
    );
    const {
        data: isIdVerificationCompletedData,
        refetch: refetchIdVerification,
    } = idPalApi.endpoints.isIdVerificationCompleted.useQuery({
        clientId: user?.id,
    });
    const idpalComplete = isIdVerificationCompletedData?.idpalComplete;
    const [completeDocument] = useCompleteDocumentMutation();
    const [pageLoading, setPageLoading] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [step, setStep] = useState<SubmitStep | null>(null);
    const [, setLearnMoreModalOpen] = useState(false);

    useEffect(() => {
        refetchIdVerification();
    }, [refetchIdVerification]);

    useEffect(() => {
        if (idpalComplete !== undefined) {
            if (!idpalComplete) {
                setStep(SubmitStep.ID_VERIFICATION);
            } else if (!user.paymentDetails?.confirmed) {
                setStep(SubmitStep.BANK_DETAILS);
            } else {
                setStep(SubmitStep.TAX_SUMMARY);
            }
        }
    }, [idpalComplete, user]);
    const handleSubmit = async () => {
        try {
            const result = await completeDocument({ documentId: currentDocument?._id });

            if ("data" in result) {
                const { data } = result;
                if (data && !data.document?.answersOB?.shouldComplete) {
                    setShowConfetti(true);
                    setTimeout(() => {
                        setShowConfetti(false);
                        setPageLoading(true);
                        setTimeout(() => {
                            setPageLoading(false);
                            navigate("/profile");
                        }, 4000);
                    }, 2000);
                } else {
                    console.error("Failed to complete the document");
                }
            } else if ("error" in result) {
                console.error("Error completing the document:", result.error);
            }
        } catch (error) {
            console.error("Error submitting document", error);
        }
    };

    const taxSummaryComponent = (
        <>
            {!isMobile && <div className="tax-summary__desktop-form-left">
                <div className="submit-page__desktop-form-back" onClick={() => setStep(SubmitStep.BANK_DETAILS)}>
                    <img src={backChevron} alt="back" />
                    <div className="submit-page__desktop-form-back-text">Back</div>
                </div>
            </div>}
            <div className="submit-page__tax-summary">
                <div className="tax-summary__header">Tax Return Summary - {currentDocument?.year}</div>
                <div className="tax-summary__subheading">Please review and submit your tax return</div>
                <div className="submit-page__summary-tax-container">
                    <Alert className="tax-summary__alert" message="Click ‘Confirm & Submit’ below to finalise your tax return. Your taxes will not be processed until you confirm and submit." type="info" showIcon icon={<img src={purpleInfoIcon} alt="info icon"/>}/>
                    <TaxSummary handleSubmit={handleSubmit} user={user} />
                </div>
                {showConfetti &&
                    <div className="submit-page__submit-animation">
                        <Lottie
                            animationData={Confetti}
                            loop={true}
                            autoplay={true}
                        />
                    </div>
                }
            </div>
            {!isMobile && <div className="tax-summary__desktop-form-right">
            </div>}
        </>
    );

    const bankDetailsComponent = (
        <div className="submit-page__bank-details">
            <div className="submit-page__bank-details-container">
                <Typography
                    className="bold fs24 w100 mt0"
                >
                    Add Bank Details
                </Typography>
                <Typography
                    className="w100 fs16 grey-6-color mt0"
                >
                    If you're due a refund, HMRC will pay it directly into this bank account. <span onClick={() => setLearnMoreModalOpen(true)} className="submit-page__bank-details__learn-more">Learn More</span>
                </Typography>
                <BankDetailsForm advanceStep={() => setStep(SubmitStep.TAX_SUMMARY)} user={user} skipEnabled={true}/>
            </div>
        </div>
    );

    return (
        <>
            <LottieAnimation isLoading={pageLoading} backgroundColor={"white"} />
            {step === "id-verification" ? (
                <IdPalStartPage advanceStep={() => setStep(SubmitStep.BANK_DETAILS)} />
            )
                : step === "tax-summary" ? (
                    taxSummaryComponent
                ) : (
                    bankDetailsComponent
                )}
        </>
    );
};
