import { Dispatch, SetStateAction, useState } from "react";

import EyeInvisibleOutlined from "@ant-design/icons/lib/icons/EyeInvisibleOutlined";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import { Form, Input } from "antd";

import Error from "../Labels/Error";
import Label from "../Labels/Label";

const PasswordInput = ({
    required = false,
    autoFocus = false,
    error,
    name = "password",
    label = "Password",
    min = 8,
    setLoginErrorStatus,
}: {
    required?: boolean;
    autoFocus?: boolean;
    error?: boolean;
    name?: string;
    label?: string;
    min?: number;
    setLoginErrorStatus?: Dispatch<SetStateAction<number | null>>
}) => {
    const [passwordInputType, setPasswordInputType] = useState("password");

    const handleInputChange = () => {
        if (setLoginErrorStatus) {
            setLoginErrorStatus(null);
        }
    };

    return <div>
        <Form.Item
            hasFeedback
            name={name}
            label={<Label text={label}/>}
            required={required}
            labelAlign={"left"}
            {...(error ? { validateStatus: "error" } : {})}
            rules={[{
                required,
                message: <Error text={"Please input the password!"}/>,
            }, {
                min: min,
                message: <Error text={`Password must contain at least ${min} characters!`}/>,
            }]}>
            <Input
                autoFocus={autoFocus}
                type={passwordInputType}
                placeholder={"Enter your password"}
                data-testid={name}
                style={{
                    height: "48px",
                    borderRadius: "4px",
                }}
                size="large"
                suffix={passwordInputType === "password"
                    ? <EyeInvisibleOutlined onClick={() => setPasswordInputType("text")}/>
                    : <EyeOutlined onClick={() => setPasswordInputType("password")}/>
                }
                onChange={handleInputChange}
            />
        </Form.Item>
    </div>;
};

export default PasswordInput;
