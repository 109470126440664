import { useContext } from "react";

import { Modal } from "antd";
import { AppContext } from "App";
import paymentFailedAnimation from "assets/animation/paymentFailed.json";
import loopImg from "assets/images/new-questionnaire/onboarding/loop-white.svg";
import turtlePurpleImg from "assets/images/new-questionnaire/onboarding/turtle-purple.svg";
import Lottie from "lottie-react";

import closeIconImage from "../../../assets/images/close.svg";
/* eslint-disable no-unused-vars*/

const ConnectBankError = ({
    showErrorModal,
    setShowErrorModal,
    closeConnectModal,
    openConnectModal,
}: {
    showErrorModal: boolean;
    setShowErrorModal: (value: boolean) => void;
    closeConnectModal: () => void;
    openConnectModal: () => void;

}) => {
    const { isMobile } = useContext(AppContext);

    if (!showErrorModal) return null;
    return (
        <div>
            <Modal
                open={showErrorModal}
                centered
                onCancel={() => setShowErrorModal(false)}
                destroyOnClose
                footer={null}
                closeIcon={<img src={closeIconImage} alt="Close" style={{ width: "20px", height: "20px", cursor: "pointer" }} />}
            >
                <div>
                    <div className="custom-prompt-modal">
                        <Lottie
                            animationData={paymentFailedAnimation}
                            loop={false}
                            className={`payment-animation${isMobile ? "-mobile" : "-desktop"}`}
                        />
                        <div className="new-questionnaire__connection-title">
                        Connection Error
                        </div>
                        <div className="new-questionnaire__connection-text">
                            Please try again later or manually enter your tax information.
                        </div>
                        <div className="new-questionnaire__onboarding-welcome-buttons">
                            <div className="new-questionnaire__onboarding-welcome-button-purple" onClick={() => {
                                setShowErrorModal(false);
                                openConnectModal && openConnectModal();
                            }}>
                                <img src={loopImg} alt="Loop icon"/>
                                <div className="new-questionnaire__onboarding-welcome-button-text ml6">
                                    Try again
                                </div>
                            </div>
                            <div className="new-questionnaire__onboarding-welcome-button-white" onClick={() => {
                                setShowErrorModal(false);
                            }}>
                                <img src={turtlePurpleImg} alt="Turtle icon"/>
                                <div className="new-questionnaire__onboarding-welcome-button-text ml6" style={{ color: "#7F43FF" }} >
                                    Manually Input Details
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default ConnectBankError;
