import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import questionIcon from "assets/images/new-questionnaire/question_icon.svg";
import DOMPurify from "dompurify";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { useAppSelector } from "redux/hooks";
import { selectCurrentNavigation } from "redux/reducers/navigation";
import { ArrayAnswersType } from "types/questionnaire";
import { checkDepends } from "utils/new-questionnaire";
import { removeEmojis } from "utils/strings";

import { Depend, Question } from "../../constants/questions";
import { PromptModalType } from "../../enums/enum";
import PromptModal from "../Shared/PromptModal";

type SelectOneOptionQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: string | undefined) => void;
    initialValue?: any;
    handlePrev: () => void;
    arrayAnswers?: ArrayAnswersType["answers"];
    handleContinue: () => void;
};

const SelectOneOptionQuestionV2: FC<SelectOneOptionQuestionProps> = ({ data, onAnswer, initialValue, handlePrev, arrayAnswers, handleContinue }) => {
    const { stepLoading } = useAppSelector(selectCurrentNavigation);

    const [selectedOption, setSelectedOption] = useState<string>(initialValue || "");
    const [showModalOpener, setShowModalOpener] = useState(false);
    const [dynamicOptions, setDynamicOptions] = useState<string[]>(data.options || []);
    const optionHasBeenClickedRef = useRef(false); // Ref to track if an option was clicked

    const promptModalData = useMemo(() => ({
        type: data.promptModal?.type || PromptModalType.info,
        title: data.promptModal?.title || "",
        text: data.promptModal?.text || "",
        image: data.promptModal?.image || "",
    }), [data.promptModal]);

    const {
        isOpened: isQuestionHelpModalOpened,
        open: handleQuestionHelpOpenModal,
        close: handleQuestionHelpCloseModal,
    } = useModalOpener();

    const handleOptionChange = (value: string) => {
        if (stepLoading) return;
        setSelectedOption(value);
        onAnswer(data.question, value);
        optionHasBeenClickedRef.current = true; // Update the ref value
    };

    const checkModalDepends = useCallback((depend: Depend[], arrayAnswers?: Record<string, any>) => {
        if (arrayAnswers) {
            return checkDepends(depend, arrayAnswers);
        } else {
            return false;
        }
    }, []);

    const formattedInfoText = data.infoText ? DOMPurify.sanitize(data.infoText?.replace(/\n/g, "<br />")) : "";

    useEffect(() => {
        setSelectedOption(initialValue || "");

        if (initialValue && optionHasBeenClickedRef.current) {
            handleContinue();
            optionHasBeenClickedRef.current = false; // Reset the ref value
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useEffect(() => {
        if (data?.promptModal?.depend && arrayAnswers) {
            const shouldModalShow = checkModalDepends(data.promptModal?.depend, arrayAnswers);
            setShowModalOpener(shouldModalShow);
            if (shouldModalShow && !data.promptModal?.manualOpen) {
                handleQuestionHelpOpenModal();
            }
        } else {
            setShowModalOpener(false);
        }

        if (data.dynamicOptions && arrayAnswers) {
            const { depend, options } = data.dynamicOptions;

            let currentOptions = [];
            let dependencyMatched = false;

            for (const dependency of depend) {
                const dependencyAnswer = arrayAnswers.find(
                    answerObj => answerObj.question === dependency.question
                )?.answer;

                if (dependencyAnswer && options[dependencyAnswer] && options[dependencyAnswer].length) {
                    currentOptions = options[dependencyAnswer];
                    dependencyMatched = true;
                    break;
                }
            }

            if (dependencyMatched) {
                setDynamicOptions(Array.isArray(currentOptions) ? currentOptions : data.options || []);
            } else {
                setDynamicOptions(data.options || []);
            }
        } else {
            setDynamicOptions(data.options || []);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div className="new-questionnaire__question">
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                {data.question}
                {showModalOpener && (
                    <span className="new-questionnaire__question-icon" onClick={handleQuestionHelpOpenModal}>
                        <img src={questionIcon} width={20} alt="help"/>
                    </span>
                )}
            </div>
            {data.infoText && <div
                className="new-questionnaire__info-text"
                dangerouslySetInnerHTML={{ __html: formattedInfoText }}
            />}

            <div className="new-questionnaire__select-one-optionV2__question-options">
                {dynamicOptions?.map((option, index) => (
                    <div
                        key={index}
                        className={`new-questionnaire__select-one-optionV2__question-option${selectedOption === option ? " selected" : stepLoading ? " disabled" : ""}`}
                        onClick={() => handleOptionChange(option)}
                    >
                        {data.iconMapping && (
                            <div>
                                <img width={30} height={30} src={data.iconMapping[option]} alt="icon" style={{ display: "block" }} />
                            </div>
                        )}
                        <div className="new-questionnaire__select-many-tiles__text">
                            {data.iconMapping ? removeEmojis(option) : option}
                        </div>
                    </div>
                ))}
            </div>
            {isQuestionHelpModalOpened && (
                <PromptModal
                    isOpen={isQuestionHelpModalOpened}
                    closeModal={handleQuestionHelpCloseModal}
                    data={promptModalData}
                    handlePrev={handlePrev}
                />
            )}
        </div>
    );
};

export default SelectOneOptionQuestionV2;
