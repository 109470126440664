import React, { useContext, useState, JSX, useEffect, useMemo, useRef } from "react";

import { Steps } from "antd";
import { AppContext } from "App";
import backChevron from "assets/images/new-questionnaire/back-chevron.svg";
import activeStepCIS from "assets/images/new-questionnaire/navigation/active-step-cis.svg";
import activeStepEmployment from "assets/images/new-questionnaire/navigation/active-step-employment.svg";
import activeStepGeneral from "assets/images/new-questionnaire/navigation/active-step-general.svg";
import activeStepPersonalInfo from "assets/images/new-questionnaire/navigation/active-step-personal-info.svg";
import activeStepRental from "assets/images/new-questionnaire/navigation/active-step-rental.svg";
import activeStepSelfEmployment from "assets/images/new-questionnaire/navigation/active-step-self-employment.svg";
import QuestionnaireButtonFooterV2 from "components/basic/Footer/QuestionnaireButtonFooterV2";
import ButtonLoading from "components/basic/Spinner/ButtonLoading";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { next } from "redux/reducers/navigation";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { findRequiredQuestions, flattenCategoryAnswers } from "utils/new-questionnaire";

import { categories, Step } from "../../constants/questions";

type ProgressStep = {
    title: string;
    description: JSX.Element;
    icon: JSX.Element;
}

type ReviewProps = {
    currentStepData: Step;
    answers: Record<string, any>;
    handleContinue: () => void;
    handlePrev: () => void;
    handleAnswerChange: (_question: string, _answer: string[]) => void;
};

const ReviewUnanswered: React.FC<ReviewProps> = ({
    currentStepData,
    handleContinue,
    handlePrev,
    answers,
    handleAnswerChange,
}) => {
    const dispatch = useDispatch();
    const { isMobile } = useContext(AppContext);
    const isKeyboardOpen = useDetectKeyboardOpen();
    const { navigationItems, stepLoading } = useAppSelector(state => state.navigation);

    const [incompleteSections, setIncompleteSections] = useState<ProgressStep[]>([]);
    const hasUpdatedAnswers = useRef(false);

    const goToSection = (category: number, step: number) => {
        dispatch(next({ nextCategory: category, nextStep: step, nextQuestion: 0 }));
    };

    const allSections = [
        {
            title: "Personal Info",
            subTitle: "Please answer all questions about your personal information",
            description: <div className="new-questionnaire__review-page__complete-button" onClick={() => {
                const category = categories.findIndex(category => category.title === "Personal Info");
                goToSection(category, 0);
            }}>Complete</div>,
            icon: <img src={activeStepPersonalInfo} alt="personal info step" />,
        },
        {
            title: "Self Employment",
            subTitle: "Please answer all questions about your self employment business(es)",
            description: <div className="new-questionnaire__review-page__complete-button" onClick={() => {
                const category = categories.findIndex(category => category.title === "Self Employment");
                goToSection(category, 0);
            }}>Complete</div>,
            icon: <img src={activeStepSelfEmployment} alt="self employment step" />,
        },
        {
            title: "Employment",
            subTitle: "Please answer all questions about your employment(s)",
            description: <div className="new-questionnaire__review-page__complete-button" onClick={() => {
                const category = categories.findIndex(category => category.title === "Employment");
                goToSection(category, 0);
            }}>Complete</div>,
            icon: <img src={activeStepEmployment} alt="employment step" />,
        },
        {
            title: "CIS",
            subTitle: "Please answer all questions about your CIS employment(s)",
            description: <div className="new-questionnaire__review-page__complete-button" onClick={() => {
                const category = categories.findIndex(category => category.title === "CIS");
                goToSection(category, 0);
            }}>Complete</div>,
            icon: <img src={activeStepCIS} alt="cis step" />,
        },
        {
            title: "Rental",
            subTitle: "Please answer all questions about your rental properties",
            description: <div className="new-questionnaire__review-page__complete-button" onClick={() => {
                const category = categories.findIndex(category => category.title === "Rental");
                goToSection(category, 0);
            }}>Complete</div>,
            icon: <img src={activeStepRental} alt="rental step" />,
        },
        {
            title: "General",
            subTitle: "Please answer all the general questions about your employment(s)",
            description: <div className="new-questionnaire__review-page__complete-button" onClick={() => {
                const category = categories.findIndex(category => category.title === "General");
                goToSection(category, 0);
            }}>Complete</div>,
            icon: <img src={activeStepGeneral} alt="general step" />,
        },
    ];

    const unansweredRequiredQuestions = useMemo(() => {
        const flattenedAnswers = flattenCategoryAnswers(answers);
        // We include this to ensure that if a user changes the navigation tabs but previously answered questions in the old tabs, we exclude them
        // This also excludes any profile sections that were added in the navigation but no profiles created for them
        const answeredAndInNavigationTabs = Object.keys(answers)
            .filter(
                category =>
                    navigationItems.includes(category) &&
            answers[category] &&
            Object.keys(answers[category]).length > 0
            );

        const categoriesSet = Array.from(
            new Set(["Personal Info", ...answeredAndInNavigationTabs, "General"])
        );

        const filteredCategories = categories.filter(category => categoriesSet.includes(category.title));
        const requiredQuestions = findRequiredQuestions(filteredCategories, flattenedAnswers);

        const unansweredRequiredQs = requiredQuestions.filter(question => {
            const matchingAnswer = flattenedAnswers.find(obj => obj.id === question.id || obj.question === question.question);
            return !matchingAnswer || matchingAnswer.answer === "" ;
        });

        return unansweredRequiredQs;
    }, [navigationItems, answers]);

    const getIncompleteSections = (questions: Array<{ id: string, question: string }>) => {
        const sections: Set<string> = new Set();

        const sectionConditions = [
            { keyword: "PERSONAL_INFO", section: "Personal Info" },
            { keyword: "RENTAL", section: "Rental" },
            { keyword: "SELF", section: "Self Employment" },
            { keyword: "EMPLOYMENT", section: "Employment" },
            { keyword: "CIS", section: "CIS" },
            { keyword: "GENERAL", section: "General" },
            { keyword: "CAPITAL_GAIN_LOSS", section: "Investments" },
            { keyword: "VENTURE_CAPITAL_SCHEME", section: "Investments" },
            { keyword: "DIVIDENDS", section: "Investments" },
            { keyword: "INTEREST", section: "Investments" },
        ];

        questions.forEach(question => {
            if (question.id) {
                if (question.id.includes("SELF")) {
                    sections.add("Self Employment");
                } else if (question.id.includes("EMPLOYMENT") && !question.id.includes("SELF")) {
                    sections.add("Employment");
                } else {
                    sectionConditions.forEach(({ keyword, section }) => {
                        if (question.id.includes(keyword)) {
                            sections.add(section);
                        }
                    });
                }
            }
        });

        return Array.from(sections);
    };

    useEffect(() => {
        if (unansweredRequiredQuestions.length === 0) {
            handleContinue();
        } else {
            const sectionNames = getIncompleteSections(unansweredRequiredQuestions);
            const filteredSections = allSections.filter(section => sectionNames.includes(section.title));
            setIncompleteSections(filteredSections);
            // Avoid multiple updates on the same render by using a ref to track whether we've updated the answers
            if (!hasUpdatedAnswers.current) {
                handleAnswerChange(currentStepData?.questions?.[0].question!, unansweredRequiredQuestions.map(q => q.question));
                // Mark that we've updated answers to avoid an infinite loop
                hasUpdatedAnswers.current = true;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unansweredRequiredQuestions, handleContinue]);

    return (
        <>
            {!isMobile && <div className="new-questionnaire__desktop-form-left">
                {currentStepData?.hasBack && <div className="new-questionnaire__desktop-form-back" onClick={() => handlePrev()}>
                    <img src={backChevron} alt="back" />
                    <div className="new-questionnaire__desktop-form-back-text">Back</div>
                </div>}
            </div>}
            <div>
                <div className={`new-questionnaire__review-page ${isMobile ? "" : "mt60"}`}>
                    <div className="new-questionnaire__review-page__title">{currentStepData.description}</div>
                    {isMobile
                        ? <Steps current={0} labelPlacement="vertical" items={incompleteSections} direction={"vertical"} size={"small"} className="new-questionnaire__review-page__steps--mobile" />
                        : <Steps current={0} labelPlacement="vertical" items={incompleteSections} direction={"horizontal"} size={"small"} className="new-questionnaire__review-page__steps--desktop" />}

                </div>
                <div className={`${isKeyboardOpen ? "" : "mt120"}`}>
                    <QuestionnaireButtonFooterV2>
                        <div
                            className={`new-questionnaire__form-continue ${currentStepData.allowContinue && !stepLoading ? "" : "disabled"}`}
                            onClick={() => {
                                if (currentStepData.allowContinue && !stepLoading) {
                                    handleContinue();
                                }
                            }}
                            role="button"
                        >
                            {stepLoading ? <ButtonLoading/>
                                : <span>{"Continue"}</span>}
                        </div>
                    </QuestionnaireButtonFooterV2>
                </div>
            </div>

            {!isMobile && <div className="new-questionnaire__desktop-form-right">
            </div>}

        </>
    );
};

export default ReviewUnanswered;
