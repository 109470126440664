import { FC, ChangeEvent, useCallback, useEffect, useRef, useState, useMemo, useContext } from "react";

import { AppContext } from "App";
import checkmark from "assets/images/new-questionnaire/checkmark.svg";
import errorIcon from "assets/images/new-questionnaire/exclamationmark-circle.svg";
import infoIcon from "assets/images/new-questionnaire/info_icon.svg";
import questionIcon from "assets/images/new-questionnaire/question_icon.svg";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { TaxProfile } from "types/client";
import { ArrayAnswersType } from "types/questionnaire";
import { checkDepends, isAnswerValid, validateAmount } from "utils/new-questionnaire";

import { Depend, Question } from "../../constants/questions";
import { PromptModalType, QuestionSubType } from "../../enums/enum";
import PromptModal from "../Shared/PromptModal";

type AmountQuestionProps = {
    data: Question;
    handlePrev: () => void;
    onAnswer: (_question: string, _answer: string | undefined) => void;
    setContinueButtonText?: (_value: string) => void;
    initialValue?: any;
    arrayAnswers?: ArrayAnswersType["answers"];
    categoryAnswers?: Record<string, any>;
    taxProfile?: TaxProfile;
};

const AmountQuestion: FC<AmountQuestionProps> = ({ data, onAnswer, handlePrev, setContinueButtonText, initialValue, arrayAnswers, categoryAnswers, taxProfile }) => {
    const isMobile = useContext(AppContext);
    const [amount, setAmount] = useState<string>(initialValue || "");
    const [error, setError] = useState<string | null>(null);
    const [showModalOpener, setShowModalOpener] = useState(false);

    const spanRef = useRef<HTMLSpanElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const helpText = useMemo(() => data.infoText, [data]);
    const validationInfo = useMemo(() => data.validationInfo, [data]);
    const promptModalData = useMemo(() => ({
        type: data.promptModal?.type || PromptModalType.info,
        title: data.promptModal?.title || "",
        text: data.promptModal?.text || "",
        image: data.promptModal?.image || "",
    }), [data.promptModal]);

    const {
        isOpened: isQuestionHelpModalOpened,
        open: handleQuestionHelpOpenModal,
        close: handleQuestionHelpCloseModal,
    } = useModalOpener();

    const formatAmountWithCommas = useCallback((value: string) => {
        if (!value) return "";

        // Split integer and decimal parts
        const [integerPart, decimalPart] = value.split(".");

        // Format the integer part with commas
        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        // Ensure decimal part remains properly formatted
        return decimalPart !== undefined ? `${formattedInteger}.${decimalPart}` : formattedInteger;
    }, []);

    const checkModalDepends = useCallback((depend: Depend[], arrayAnswers?: ArrayAnswersType["answers"], categoryAnswers?: Record<string, any>) => {
        if (arrayAnswers) {
            const categoryAnswers = arrayAnswers.reduce((acc, curr) => {
                acc[curr.question] = curr.answer;
                return acc;
            }, {} as Record<string, any>);

            if (!depend || depend.length === 0) return false;

            return checkDepends(depend, categoryAnswers);
        } else if (categoryAnswers) {
            return checkDepends(depend, categoryAnswers);
        } else {
            return false;
        }
    }, []);

    const handleAmountChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/[^\d.]/g, "");
        if (value.includes(".")) {
            const [integer, decimal] = value.split(".");
            value = `${integer}.${decimal.slice(0, 2)}`;
        }
        value = formatAmountWithCommas(value);

        if (amount !== value) {
            setAmount(value);
        }

        const numericValue = value.replace(/,/g, "");
        if (data.answerRequired === false && !numericValue) {
            setError(null);
            onAnswer(data.question, numericValue);
        } else if (!validateAmount(numericValue)) {
            setError("Please enter a valid amount.");
            onAnswer(data.question, undefined);
            return;
        } else {
            setError(null);
            onAnswer(data.question, numericValue);
        }

        if (data.validation) {
            const isValid = isAnswerValid(numericValue, data.validation.regExp);
            if (!isValid) {
                setError(data.validation.error);
                onAnswer(data.question, undefined);
            } else {
                setError(null);
                onAnswer(data.question, numericValue);
            }
        }
    }, [formatAmountWithCommas, amount, data.answerRequired, data.validation, data.question, onAnswer]);

    const handleWrapperClick = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            if (isMobile) {
                e.currentTarget.blur();
            } else {
                e.preventDefault();
            }
        }
    };

    useEffect(() => {
        if (!amount && data.answerRequired === false) {
            setContinueButtonText && setContinueButtonText("None");
        }

        if (amount) {
            setContinueButtonText && setContinueButtonText("Continue");
        }

        if (spanRef.current) {
            spanRef.current.innerText = amount || "0";
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount]);

    useEffect(() => {
        const updateAmount = (value?: number) => {
            if (value !== undefined && value !== null && Number(value) !== Number(initialValue)) {
                setAmount(value.toString());
                onAnswer(data.question, value.toString());
            } else {
                setAmount(initialValue || "");
            }
        };

        if (data.subtype === QuestionSubType.confirmedTotalGross) {
            updateAmount(taxProfile?.revenue.confirmedTotalGross);
        }

        if (data.subtype === QuestionSubType.taxAlreadyPaidOnRevenue) {
            updateAmount(taxProfile?.revenue.taxAlreadyPaidOnRevenue);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.subtype]);

    useEffect(() => {
        if (initialValue !== amount) {
            setAmount(initialValue || "");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.question]);

    useEffect(() => {
        const focusInput = () => {
            if (inputRef.current) {
                inputRef.current.focus();
                inputRef.current.click();
            }
        };

        const timer = setTimeout(focusInput, 100);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (data?.promptModal?.depend && (arrayAnswers || categoryAnswers)) {
            const shouldModalShow = checkModalDepends(data.promptModal?.depend, arrayAnswers, categoryAnswers);
            setShowModalOpener(shouldModalShow);
            if (shouldModalShow && !data.promptModal?.manualOpen) {
                handleQuestionHelpOpenModal();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="new-questionnaire__question">
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                {data.question}
                {(data.promptModal?.showModalOpener || showModalOpener) && (
                    <span className="new-questionnaire__question-icon" onClick={handleQuestionHelpOpenModal}>
                        <img src={questionIcon} width={20} alt="help"/>
                    </span>
                )}
            </div>
            <div
                className="new-questionnaire__question-input-wrapper"
                style={{ borderBottom: "4px solid #7F43FF" }}
                onClick={handleWrapperClick}
            >
                <div className="new-questionnaire__amount-question-input-prefix">£</div>
                <input
                    className="new-questionnaire__amount-question-input"
                    onChange={handleAmountChange}
                    type="text"
                    inputMode="decimal"
                    pattern="[0-9]*"
                    autoFocus={true}
                    value={amount}
                    ref={inputRef}
                    style={{
                        width: spanRef.current
                            ? spanRef.current.offsetWidth + 2
                            : "auto",
                    }}
                    onKeyDown={handleKeyDown}
                />
            </div>
            <span ref={spanRef} style={{ visibility: "hidden", position: "absolute", whiteSpace: "pre", fontFamily: "Poppins", fontSize: "24px", fontWeight: 600, width: "100%" }}></span>

            {validationInfo && validationInfo.map((info: string, index: number) =>
                (
                    <div key={index} className="new-questionnaire__info-message">
                        <img src={checkmark} width={20} alt="check info" />
                        <div className="new-questionnaire__info-message-text ml4">
                            {info}
                        </div>
                    </div>
                )
            )}

            {helpText && <div className="new-questionnaire__info-message">
                <img src={infoIcon} width={20} alt="info" />
                <div className="new-questionnaire__info-message-text ml4">
                    {helpText}
                </div>
            </div>}
            {error && (
                <div className="new-questionnaire__error-message">
                    <img src={errorIcon} width={20} alt="error" />
                    <div className="new-questionnaire__error-message-text ml4">
                        {error}
                    </div>
                </div>
            )}

            {isQuestionHelpModalOpened && (
                <PromptModal
                    isOpen={isQuestionHelpModalOpened}
                    closeModal={handleQuestionHelpCloseModal}
                    data={promptModalData}
                    handlePrev={handlePrev}
                />
            )}
        </div>
    );
};

export default AmountQuestion;
