import "styles/transactionsFilters.less";

import React, { useContext, useMemo, useState, useEffect, useCallback } from "react";

import { CloseCircleOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, DatePicker, Select, InputNumber, Drawer, Row, Col, Tag, Badge } from "antd";
import { AppContext } from "App";
import { ProfileType } from "components/pages/NewQuestionnaire/enums/enum";
import dayjs, { Dayjs } from "dayjs";
import { useAppSelector } from "redux/hooks";
import { AccountDetails } from "types/bankAccounts";

import { CategoryEmoji } from "../../../constants/categories";

export const getTagIcon = (category: string, taxProfileType: ProfileType) => {
    return CategoryEmoji[taxProfileType][category];
};

const { RangePicker } = DatePicker;

type TransactionsFiltersProps = {
    filters: Record<string, any>; // <-- Accept filters as a prop
    onApplyFiltersSortSearch: (_filters: Record<string, any>) => void;
    connectedBankAccounts: AccountDetails[];
    transactionsCategories: string[];
    taxProfileType: ProfileType;
};

const TransactionsFilters: React.FC<TransactionsFiltersProps> = ({
    filters, // <-- Accept filters from parent
    onApplyFiltersSortSearch,
    connectedBankAccounts,
    transactionsCategories,
    taxProfileType,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { isMobile } = useContext(AppContext);
    const currentDocumentYear = useAppSelector(state => state.document.currentDocumentYear);

    // Internal state to track UI inputs
    const [minAmount, setMinAmount] = useState<number | null>(0);
    const [maxAmount, setMaxAmount] = useState<number | null>(null);
    const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
    const [account, setAccount] = useState<string[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    const getMinDate = useCallback(() => {
        const startTaxYear = currentDocumentYear?.split("/")[0];
        const dateString = `06/04/${startTaxYear}`;
        return dayjs(dateString, "DD/MM/YYYY");
    }, [currentDocumentYear]);

    const getMaxDate = useCallback(() => {
        const endTaxYear = currentDocumentYear?.split("/")[1];
        const dateString = `05/04/${endTaxYear}`;
        return dayjs(dateString, "DD/MM/YYYY");
    }, [currentDocumentYear]);

    // Sync UI state with external filters whenever filters change
    useEffect(() => {
        setMinAmount(filters.amountRange?.[0] ?? 0);
        setMaxAmount(filters.amountRange?.[1] ?? null);
        setDateRange([
            filters.dateRange?.[0] ? dayjs(filters.dateRange[0]) : null,
            filters.dateRange?.[1] ? dayjs(filters.dateRange[1]) : null,
        ]);
        setAccount(filters.account ?? []);
        setSelectedCategories(filters.category ?? []);
    }, [filters]); // Re-run effect when filters change

    // Compute the badge count dynamically
    const badgeCount = useMemo(() => {
        return selectedCategories.length + account.length + (minAmount ? 1 : 0) + (maxAmount ? 1 : 0) + (dateRange[0] ? 1 : 0) + (dateRange[1] ? 1 : 0);
    }, [selectedCategories, account, minAmount, maxAmount, dateRange]);

    // Toggle categories
    const toggleCategory = (category: string) => {
        setSelectedCategories(prev =>
            (prev.includes(category)
                ? prev.filter(item => item !== category) // Remove if already selected
                : [...prev, category]) // Add if not selected
        );
    };

    // Apply filters
    const applyFilters = () => {
        const formattedDateRange = dateRange.map(date => (date ? date.toISOString() : null));

        onApplyFiltersSortSearch({
            page: 1,
            amountRange: [minAmount, maxAmount ?? null],
            dateRange: formattedDateRange,
            account,
            category: selectedCategories,
        });
        setIsModalOpen(false);
    };

    // Reset filters
    const resetAllFilters = useCallback(() => {
        setMinAmount(0);
        setMaxAmount(null);
        setDateRange([null, null]);
        setAccount([]);
        setSelectedCategories([]);
    }, []);

    return (
        <div>
            <Button
                className="w100"
                type="default"
                shape="round"
                icon={<FilterOutlined />}
                onClick={() => setIsModalOpen(true)}
            >
                {"Filters "}
                {badgeCount > 0 && <Badge color="grey" className="ml4" count={badgeCount} overflowCount={20} />}
            </Button>

            <Drawer
                title="Filter Expenses"
                open={isModalOpen}
                onClose={() => applyFilters()}
                footer={null}
                width={isMobile ? "100%" : 500}
                placement="left"
                closeIcon={<CloseCircleOutlined />}
            >
                <Row gutter={[16, 16]}>
                    {/* Amount Range */}
                    <Col span={24}>
                        <div className="mb6">Amount Range</div>
                        <Row gutter={10}>
                            <Col span={12}>
                                <InputNumber
                                    prefix="£"
                                    size="large"
                                    min={0}
                                    value={minAmount}
                                    onChange={value => {
                                        const newMin = value !== null ? Math.max(0, value) : null;
                                        setMinAmount(newMin);
                                        if (newMin !== null && maxAmount !== null && newMin > maxAmount) {
                                            setMaxAmount(newMin); // Ensure maxAmount is not less than minAmount
                                        }
                                    }}
                                    placeholder="Min Amount"
                                    className="w100"
                                    inputMode="decimal"
                                />
                            </Col>
                            <Col span={12}>
                                <InputNumber
                                    prefix="£"
                                    size="large"
                                    min={minAmount || 0} // Ensure min is at least minAmount
                                    value={maxAmount}
                                    onChange={value => {
                                        const newMax = value !== null ? Math.max(minAmount || 0, value) : null;
                                        setMaxAmount(newMax);
                                    }}
                                    placeholder="Max Amount"
                                    className="w100"
                                    inputMode="decimal"
                                />
                            </Col>
                        </Row>
                    </Col>

                    {/* Date Range */}
                    <Col span={24}>
                        <div className="mb6">Date Range</div>
                        <RangePicker
                            value={dateRange}
                            size="large"
                            allowClear
                            className="w100"
                            onChange={dates => setDateRange(dates ? [dates[0], dates[1]] : [null, null])}
                            minDate={getMinDate()}
                            maxDate={getMaxDate()}
                            format={"DD/MM/YYYY"}
                        />
                    </Col>

                    {/* Bank Account */}
                    <Col span={24}>
                        <div className="mb6">Bank Account</div>
                        <Select
                            size="large"
                            className="w100"
                            mode="multiple"
                            allowClear
                            value={account}
                            onChange={setAccount}
                        >
                            {connectedBankAccounts.map(account => (
                                <Select.Option key={account._id} value={account._id}>
                                    <div className="flex-row horizontal-left">
                                        <img width={16} height={16} aria-label={account.accountName} src={account.logo} className="mr6" />
                                        <span>{account.accountName} ({account.currencyCode})</span>
                                    </div>
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>

                    {/* Categories */}
                    <Col span={24}>
                        <div className="mb6">Categories</div>
                        <Row gutter={[4, 16]}>
                            {transactionsCategories.map(category => (
                                <Col key={category}>
                                    <Tag
                                        icon={<span className="mr6">{getTagIcon(category, taxProfileType)}</span>}
                                        className="transactionsFilters__tag pointer ph10 pv5"
                                        color={selectedCategories.includes(category) ? "purple" : "default"}
                                        onClick={() => toggleCategory(category)}
                                    >
                                        {category}
                                    </Tag>
                                </Col>
                            ))}
                        </Row>
                    </Col>

                    {/* Buttons */}
                    <Col span={24}>
                        <Row justify="space-between" className="mt20" gutter={8}>
                            <Col span={12}>
                                <Button type="text" className="w100 h44px" size="large" shape="round" onClick={resetAllFilters}>Reset All</Button>
                            </Col>
                            <Col span={12}>
                                <Button type="primary" className="w100 h44px" size="large" shape="round" onClick={applyFilters}>Apply</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
};

export default TransactionsFilters;
