import { SECONDARY_STATUSES_DICT } from "constants/common";

import moment from "moment";
import { IClientDocument } from "types/client";

import { getStatusDate } from "./common";

export function getCurrentReturn(documents: IClientDocument[]) {
    if (!documents.length) return null;
    const activeReturns = [...documents].filter(returnObj => (returnObj.status.document === "active" || returnObj.status.document === "pending"));

    if (!activeReturns.length && documents.length) {
        const sortedDocuments = [...documents]?.sort((a, b) => {
            const dateA = new Date(a.updatedAt);
            const dateB = new Date(b.updatedAt);
            return dateB.getTime() - dateA.getTime();
        });
        return sortedDocuments[0];
    }

    const sortedActiveReturns = [...activeReturns].sort((a, b) => {
        const dateA = new Date(a.updatedAt);
        const dateB = new Date(b.updatedAt);
        return dateB.getTime() - dateA.getTime();
    });

    const latestYear = Math.max(...documents.map(returnObj => parseInt(returnObj.year, 10)));

    let currentReturn: IClientDocument | null = null;

    for (let year = latestYear; year >= 0; year--) {
        const latestYearActiveReturns = [...sortedActiveReturns].filter(returnObj => parseInt(returnObj.year, 10) === year);

        if (latestYearActiveReturns.length) {
            currentReturn = latestYearActiveReturns[0];
            break;
        }
    }
    return currentReturn;
}

export const checkCurrentReturn = ((currentReturn: IClientDocument | null) => {
    if (currentReturn?.status.secondary === SECONDARY_STATUSES_DICT["other-income"]) return true;
    if (currentReturn?.status.secondary === SECONDARY_STATUSES_DICT["agent-link-removed"]) return true;
    if (currentReturn?.status.secondary === SECONDARY_STATUSES_DICT["owe-tax"]) return true;
    if (currentReturn?.status.secondary === SECONDARY_STATUSES_DICT["owe-penalty"]) return true;
    if (currentReturn?.status.secondary === SECONDARY_STATUSES_DICT["payment-link-expired"]) return true;
    if (currentReturn?.status.secondary === SECONDARY_STATUSES_DICT["previously-submitted"]) return true;
    if (currentReturn?.status.secondary === SECONDARY_STATUSES_DICT["reduced-fee-submitted-to-hmrc"]) return true;
    if (currentReturn?.status.secondary === SECONDARY_STATUSES_DICT["client-not-converted"]) return true;
    if (currentReturn?.status.secondary === SECONDARY_STATUSES_DICT["custom-fee-submitted-to-hmrc"]) return true;
    if (currentReturn?.status.secondary === SECONDARY_STATUSES_DICT["appeal-fee-submitted-to-hmrc"]) return true;
    if (
        currentReturn &&
        currentReturn.status.client !== "application-closed"
    )
        return true;

    const closeDateStr = getStatusDate(
        "application-closed",
        currentReturn?.statusesHistory
    );

    if (!closeDateStr) return false;

    const closedDate = moment(closeDateStr, "DD/MM/YYYY");
    const oneWeekAgo = moment().subtract(1, "week");
    return !closedDate.isBefore(oneWeekAgo);
});
