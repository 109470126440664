import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

import { Col, Checkbox } from "antd";
import "styles/signInputs.less";
import UkCustomFormItem from "components/basic/FormWrapper/UkFormItemWrapper";
import UkFloatInput from "components/inputs/Form/Inputs/UkFloatInput";
import { Link } from "react-router-dom";
import { isPasswordLengthValid } from "utils/validation";

import { UK_ROUTES } from "../../../constants/routes";
import { EnableValidationT } from "../SignUpForm/SignUpFormNew";

type SignDataType = {
    [key: string]: boolean | Blob | null | string;
    termsAgreed: boolean;
    signature: Blob | null;
    referralCode: string;
    password: string;
};

interface SignInputsProps {
    setSignData: Dispatch<SetStateAction<SignDataType>>;
    isCreateAccountChecked: boolean;
    setIsCreateAccountChecked: Dispatch<SetStateAction<boolean>>
    setEnableValidation: Dispatch<SetStateAction<EnableValidationT>>
    passwordError: string;
    setPasswordError: Dispatch<SetStateAction<string>>
    inputId: string;
    handleKeyDown?: (_event: any) => void;
}

const SignInputs: FC<SignInputsProps> = ({
    setSignData, passwordError, setPasswordError, setEnableValidation, inputId, handleKeyDown,
}) => {
    const [password, setPassword] = useState("");

    const handleInputChange = (field: string, e: any) => {
        const newValue = e.target.value;

        if (field === "password") {
            setPassword(newValue);

            if (!isPasswordLengthValid(newValue)) {
                setPasswordError("Password must be at least 8 characters long");
            } else {
                setPasswordError("");
            }
        }

        setSignData((prevFormData: any) => ({
            ...prevFormData,
            [field]: newValue,
        }));
    };

    const handleTermsCheckChange = (e: any) => {
        setSignData((prevFormData: any) => ({
            ...prevFormData,
            termsAgreed: e.target.checked,
        }));
    };

    useEffect(() => {
        setEnableValidation && setEnableValidation((prev: EnableValidationT) => ({ ...prev, [inputId]: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    return (
        <Col>
            <UkCustomFormItem
                error={passwordError}
            >
                <UkFloatInput
                    label="Password"
                    dataTestId={"password"}
                    value={password}
                    type={showPassword ? "text" : "password"}
                    onChange={e =>
                        handleInputChange("password", e)
                    }
                    handleKeyDown={handleKeyDown}
                    inputId={inputId}
                    showPassword={showPassword}
                    togglePasswordVisibility={togglePasswordVisibility}
                    error={passwordError}
                />
            </UkCustomFormItem>

            <Checkbox
                defaultChecked
                className="input-item terms-conditions-checkbox"
                onChange={handleTermsCheckChange}
            >
                By continuing you accept the {" "}
                <Link to={UK_ROUTES.tcs} className="email-link" target="_blank" rel="noreferrer">
                    Terms & Conditions
                </Link>{" "}
                and{" "}
                <Link to={UK_ROUTES.engagement} className="email-link" target="_blank" rel="noreferrer">
                    Terms of Engagement
                </Link>
            </Checkbox>

        </Col>
    );
};
export default SignInputs;
