import { FC, useCallback, useContext, useEffect, useState } from "react";

import { CheckOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, List, MenuProps, Space } from "antd";
import { AppContext } from "App";
import fasterImg from "assets/images/new-questionnaire/onboarding/bolt.svg";
import rightArrow from "assets/images/new-questionnaire/onboarding/right-arrow.svg";
import turtleImg from "assets/images/new-questionnaire/onboarding/turtle-grey.svg";
import radioDefault from "assets/images/new-questionnaire/radio_default.svg";
import radioSelected from "assets/images/new-questionnaire/radio_selected.svg";
import taxzapLogo from "assets/images/taxzap-logo-R.svg";
import { useUser } from "hooks/common/useUser";
import { next } from "redux/reducers/navigation";
import { selectTaxYears } from "redux/reducers/taxYears";

import { getTaxYears } from "../../../../../constants/taxYears";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
    selectDocument,
    setCurrentDocumentYear,
    setSelectedSharingPreference,
} from "../../../../../redux/reducers/document";

type OnBoardingProps = {
    handleNext: () => void
    openConnectModal: () => void
};

/* eslint-disable */
export enum SharingPreferenceIdType {
    connect = "connect",
    manual = "manual",
}

/* eslint-enable */

const sharingPreferences: { id: SharingPreferenceIdType, img: string, title: string, isRecommended?: boolean }[] = [
    {
        id: SharingPreferenceIdType.connect,
        img: fasterImg,
        title: "Connect Bank Accounts",
        isRecommended: true,
    },
    {
        id: SharingPreferenceIdType.manual,
        img: turtleImg,
        title: "Manual Entry",
    },
];

const OnBoarding: FC<OnBoardingProps> = ({ openConnectModal }) => {
    const dispatch = useAppDispatch();
    const { isMobile } = useContext(AppContext);
    const { user } = useUser();

    const [step, setStep] = useState<"welcome" | "more-details">("welcome");

    const {
        currentDocumentYear,
        selectedSharingPreference,
    } = useAppSelector(selectDocument);
    const { taxYears } = useAppSelector(selectTaxYears);

    const handleWelcomeContinue = useCallback(() => {
        if (selectedSharingPreference === SharingPreferenceIdType.connect) {
            setStep("more-details");
        } else if (selectedSharingPreference === SharingPreferenceIdType.manual) {
            setStep("more-details");
        }
    }, [selectedSharingPreference]);

    const handleMoreDetailsContinue = useCallback(() => {
        if (selectedSharingPreference === SharingPreferenceIdType.connect) {
            openConnectModal();
        } else if (selectedSharingPreference === SharingPreferenceIdType.manual) {
            dispatch(next({
                nextCategory: 1,
                nextStep: 0,
                nextQuestion: 0,
                nextProfileQuestion: 0,
            }));
        }
    }, [openConnectModal, selectedSharingPreference, dispatch]);

    const handleMenuClick = ({ key }: { key: string }) => {
        dispatch(setCurrentDocumentYear(key));
    };

    const items: MenuProps["items"] = getTaxYears(taxYears).map(year => ({
        key: year,
        label: `${year} Tax Year`,
    }));

    useEffect(() => {
        const handleBackButtonClick = (_event: PopStateEvent) => {
            if (step === "more-details") {
                setStep("welcome");
            }
        };
        window.addEventListener("popstate", handleBackButtonClick);
        return () => {
            window.removeEventListener("popstate", handleBackButtonClick);
        };
    }, [step, setStep]);

    const renderWelcome = () => (
        <div className="new-questionnaire__onboarding-welcome-wrapper">
            {!isMobile && (<div className="new-questionnaire__onboarding-welcome-left">
            </div>)}
            <div className="new-questionnaire__onboarding-welcome-right">
                <div className="new-questionnaire__onboarding-welcome-right-content">
                    <div className="flex-column">
                        <img
                            className={"mt20 mlAuto mrAuto"}
                            src={taxzapLogo}
                            alt="TaxZap Logo"
                            width={150}
                        />
                        <div
                            className={`new-questionnaire__onboarding-welcome-name-title ${isMobile ? "mt20" : "mt40"}`}>
                            Welcome {user?.info?.firstName}
                        </div>
                        <div className="mt20">
                            Please select the tax year you want to file for.
                        </div>
                        <Dropdown menu={{
                            items,
                            onClick: handleMenuClick,
                            selectedKeys: [currentDocumentYear],
                        }} className={"mt20"}>
                            <Button size="large" onClick={e => e.preventDefault()}>
                                <Space className="client-navbar__calendar-title">
                                    {currentDocumentYear} Tax Year
                                    <DownOutlined/>
                                </Space>
                            </Button>
                        </Dropdown>

                        <div className={"new-questionnaire__onboarding-welcome-description bold mt20"}>
                            Choose how to share Your Expenses
                        </div>
                        <div className="mt20">
                            If you're a CIS worker, self-employed, or have rental properties, we can connect your bank
                            account to securely retrieve your transactions in the background, while you continue with
                            your tax return.
                            Not comfortable sharing bank details? No problem. You can still choose to enter your
                            expenses manually.
                        </div>
                        <div>
                            <div className="new-questionnaire__onboarding-welcome-sharing-preferences-container">
                                {sharingPreferences.map((value, index) => (
                                    <div
                                        key={value.id + index}
                                        className={`new-questionnaire__onboarding-welcome-sharing-preference${selectedSharingPreference === value.id ? "-selected" : ""} pointer`}
                                        onClick={() => dispatch(setSelectedSharingPreference(value.id))}
                                    >
                                        <img src={selectedSharingPreference === value.id ? radioSelected : radioDefault}
                                            alt="selector" width={25} className="ml10"/>
                                        <img src={value.img} alt={value.id} width={20} className="ml6"/>
                                        <div
                                            className={`new-questionnaire__onboarding-welcome-sharing-preference-title${selectedSharingPreference === value.id ? "-selected" : ""}`}>
                                            {value.title}
                                        </div>
                                        {value.isRecommended && <div
                                            className="new-questionnaire__onboarding-welcome-sharing-preference-badge">Recommended</div>}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className="new-questionnaire__onboarding-welcome-buttons">
                                <div className="new-questionnaire__onboarding-welcome-button-purple"
                                    onClick={() => handleWelcomeContinue()}>
                                    <div className="new-questionnaire__onboarding-welcome-button-text ml6">
                                        Continue
                                    </div>
                                    <img src={rightArrow} alt="continue" className="ml12"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    const renderMoreDetails = () => (
        <div className="new-questionnaire__onboarding-more-details-wrapper">
            <div className="new-questionnaire__onboarding-more-details">
                {selectedSharingPreference === SharingPreferenceIdType.connect && (
                    <div className="flex-column">
                        <div>
                            <div className="new-questionnaire__onboarding-more-details-title">
                                Getting Started With Your Tax Return
                            </div>
                            <div className="new-questionnaire__onboarding-more-details-description mt16">
                                To help us prepare your tax return accurately, we’ll use open banking through a trusted
                                third-party provider to securely retrieve your transaction data. This one-time process
                                gathers details about your business expenses.
                                <br/>
                                <br/>
                                To ensure accurate results, please connect:
                            </div>
                            <List className="w100">
                                <li className="pv10 flex-row">

                                    <CheckOutlined className="primary-color"/>
                                    <div className="ml10 grey-7-color">All accounts where income and salary are paid
                                        into
                                    </div>

                                </li>
                                <li className="pv10 flex-row">
                                    <CheckOutlined className="primary-color"/>
                                    <div className="ml10 grey-7-color">All accounts used for business expenses</div>
                                </li>
                            </List>
                            <div className="new-questionnaire__onboarding-more-details-description mt16">
                                Before we proceed, it's helpful to have some documents ready, including:
                            </div>
                            <List className="w100">
                                <li className="pv10 flex-row">

                                    <CheckOutlined className="primary-color"/>
                                    <div className="ml10 grey-7-color">CIS deduction statements</div>

                                </li>
                                <li className="pv10 flex-row">
                                    <CheckOutlined className="primary-color"/>
                                    <div className="ml10 grey-7-color">P60s or other income statements</div>
                                </li>
                            </List>
                            <div className="new-questionnaire__onboarding-more-details-description mt16">
                                Don’t worry if you don’t have everything right now — you can save your progress and
                                return anytime. Connecting your bank accounts typically takes less than 2 minutes, but
                                you can get started on a few questions while we’re setting things up.
                            </div>
                        </div>
                        <div className="new_questionnaire__onboarding-more-details-button-container">
                            <div className="new-questionnaire__onboarding-more-details-button"
                                onClick={handleMoreDetailsContinue}>
                                Got It
                            </div>
                            <div className="new-questionnaire__onboarding-more-details-back-button"
                                onClick={() => setStep("welcome")}>
                                Go Back
                            </div>
                        </div>

                    </div>)}

                {selectedSharingPreference === SharingPreferenceIdType.manual && (<>
                    <div className="new-questionnaire__onboarding-more-details-title">
                        Getting Started With Your Tax Return

                    </div>
                    <div className="new-questionnaire__onboarding-more-details-description mt16">
                        We’ll ask you a few questions to help put together your tax return. To make things easier, it’s
                        helpful to have some documents ready, like:
                    </div>
                    <List className="w100">
                        <li className="pv10 flex-row">

                            <CheckOutlined className="primary-color"/>
                            <div className="ml10 grey-7-color">CIS deduction statements</div>

                        </li>
                        <li className="pv10 flex-row">
                            <CheckOutlined className="primary-color"/>
                            <div className="ml10 grey-7-color"> P60s or other income statements</div>
                        </li>
                        <li className="pv10 flex-row">
                            <CheckOutlined className="primary-color"/>
                            <div className="ml10 grey-7-color">Income & Expense records (from a spreadsheet, app, or
                                bank statements)
                            </div>
                        </li>
                    </List>
                    <div className="new-questionnaire__onboarding-more-details-description mt10 mb20">Don’t worry if you
                        don’t have everything right now — you can save your progress and come back anytime.
                    </div>
                    <div className="new_questionnaire__onboarding-more-details-button-container">
                        <div className="new-questionnaire__onboarding-more-details-button"
                            onClick={handleMoreDetailsContinue}>
                            Continue
                        </div>
                        <div className="new-questionnaire__onboarding-more-details-back-button"
                            onClick={() => setStep("welcome")}>
                            Go Back
                        </div>
                    </div>
                </>)}
            </div>
        </div>
    );

    return (
        <div>
            {step === "welcome" && renderWelcome()}
            {step === "more-details" && renderMoreDetails()}
        </div>
    );
};

export default OnBoarding;
