import "styles/floatInput.less";

import {
    useState,
    ChangeEvent,
    useMemo,
    FC,
    useEffect,
    useContext,
    Dispatch,
    SetStateAction,
} from "react";

import { Input, Select } from "antd";
import { AppContext } from "App";
import checkMark from "assets/images/checkmark.svg";
import britainFlag from "assets/images/form/gb-flag.svg";
import irelandFlag from "assets/images/form/ireland-flag.svg";
import warning from "assets/images/warning.svg";
import { EnableValidationT } from "components/basic/SignUpForm/SignUpFormNew";

interface PhoneInputWithCountryCodeProps {
    label: string;
    inputId: string;
    value?: string;
    error?: string;
    className?: string;
    dataTestId?: string;
    inputName?: string;
    disabled?: boolean;
    disableCountryLabel?: boolean;
    disableNumberLabel?: boolean;
    index?: number;
    onChange?: (_fullPhoneNumber: string) => void;
    setEnableValidation?: Dispatch<SetStateAction<EnableValidationT>>
    handleKeyDown?: (_event: any) => void;
}

const UkPhoneInputWithCountryCode: FC<PhoneInputWithCountryCodeProps> = ({
    label,
    inputId,
    value = "",
    error,
    onChange,
    className,
    disabled,
    disableCountryLabel,
    disableNumberLabel,
    index,
    dataTestId,
    inputName,
    setEnableValidation,
    handleKeyDown,
}) => {
    const { isMobile } = useContext(AppContext);

    const [inputFocus, setInputFocus] = useState(false);
    const [selectFocus, setSelectFocus] = useState(false);

    const firstSpaceIndex = value.indexOf(" ");

    const initialCountryCode = useMemo(() => {
        return firstSpaceIndex !== -1
            ? value.substring(0, firstSpaceIndex)
            : "+44";
    }, [value, firstSpaceIndex]);

    const initialPhoneNumber = useMemo(() => {
        return firstSpaceIndex !== -1
            ? value.substring(firstSpaceIndex + 1)
            : "";
    }, [value, firstSpaceIndex]);

    const [countryCode, setCountryCode] = useState(initialCountryCode);
    const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);

    const isOccupied = useMemo(
        () => inputFocus || phoneNumber,
        [inputFocus, phoneNumber]
    );
    const isSelectOccupied = useMemo(
        () => selectFocus || countryCode,
        [selectFocus, countryCode]
    );

    const InputLabelClass = useMemo(() => {
        const baseClass = "uk-label";
        const isLongLabel = label.length > 40;
        const isVeryLongLabel = label.length > 65;

        let computedLabelClass = isOccupied
            ? `${baseClass} uk-as-label`
            : `${baseClass} as-placeholder`;

        if (isOccupied && error) {
            computedLabelClass += " text-error";
        }

        if (isMobile) {
            if (!isOccupied && (isLongLabel || isVeryLongLabel)) {
                computedLabelClass += " two-line-placeholder";
            }

            if (isOccupied && (isLongLabel || isVeryLongLabel)) {
                computedLabelClass += " two-line-label";
            }
        } else {
            if (!isOccupied && isVeryLongLabel) {
                computedLabelClass += " two-line-placeholder";
            }

            if (isOccupied && isVeryLongLabel) {
                computedLabelClass += " two-line-label";
            }
        }

        return computedLabelClass;
    }, [isOccupied, error, label.length, isMobile]);

    const selectLabelClass = useMemo(() => {
        let computedLabelClass = isSelectOccupied
            ? "label as-label"
            : "label as-placeholder";
        if (isSelectOccupied && error) computedLabelClass += " text-error";
        return computedLabelClass;
    }, [isSelectOccupied, error]);

    const handleBlur = () => {
        setEnableValidation && setEnableValidation((prev: EnableValidationT) => ({ ...prev, [inputId]: true }));
        setInputFocus(false);
    };
    const handleFocus = () => setInputFocus(true);

    const handleSelectBlur = () => {
        setEnableValidation && setEnableValidation((prev: EnableValidationT) => ({ ...prev, [inputId]: true }));
        setSelectFocus(false);
    };
    const handleSelectFocus = () => setSelectFocus(true);

    const handleCountryCodeChange = (newCountryCode: string) => {
        setCountryCode(newCountryCode);
        if (onChange) {
            onChange(newCountryCode + " " + phoneNumber);
        }
    };

    const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newPhoneNumber = event.target.value;
        setPhoneNumber(newPhoneNumber);
        if (onChange) {
            onChange(countryCode + " " + newPhoneNumber);
        }
    };

    useEffect(() => {
        setCountryCode(initialCountryCode);
        setPhoneNumber(initialPhoneNumber);
    }, [initialCountryCode, initialPhoneNumber]);

    return (
        <div
            className={className}
            style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
            }}
        >
            <div
                className="uk-float-label"
                onBlur={handleSelectBlur}
                onFocus={handleSelectFocus}
            >
                <Select
                    disabled={disabled}
                    value={countryCode}
                    onChange={handleCountryCodeChange}
                    className="uk-phone-input-country-code"
                >
                    <Select.Option
                        className="phone-input-country-code-item"
                        value="+44"
                    >
                        <div className="phone-input-country-code-select-item">
                            <img src={britainFlag} width={25} alt="ireland flag"/>
                            <p style={{ margin: "0px 0px 0px 10px" }}>
                                +44
                            </p>
                        </div>
                    </Select.Option>
                    <Select.Option
                        className="phone-input-country-code-item"
                        value="+353"
                    >
                        <div className="phone-input-country-code-select-item">
                            <img src={irelandFlag} width={25} alt="ireland flag"/>
                            <p style={{ margin: "0px 0px 0px 10px" }}>
                                +353
                            </p>
                        </div>
                    </Select.Option>

                </Select>
                {!disableCountryLabel && (
                    <label className={selectLabelClass}>Country</label>
                )}
            </div>
            <div
                className="uk-float-label"
                onBlur={handleBlur}
                onFocus={handleFocus}
                style={{ width: "100%" }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Input
                        className="uk-input-field"
                        value={phoneNumber}
                        data-testid={dataTestId}
                        name={inputName}
                        onChange={handlePhoneNumberChange}
                        disabled={disabled}
                        style={{ marginLeft: 10 }}
                        autoFocus={index === 0}
                        inputMode='numeric'
                        onKeyDown={handleKeyDown}
                    />
                </div>
                {!disableNumberLabel && (
                    <label
                        className={InputLabelClass}
                        style={{ marginLeft: 10 }}
                    >
                        {label}
                    </label>
                )}
                {(value || error) && <img className="status-icon mr10" src={error ? warning : checkMark} alt="status icon" />}
            </div>
        </div>
    );
};

export default UkPhoneInputWithCountryCode;
