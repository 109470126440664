import { FC } from "react";

import { IUser } from "types/auth";
import { AccountDetails } from "types/bankAccounts";

import BankAccount from "./BankAccount/BankAccount";
import CategoryDoneDesktop from "./CategoryDone/CategoryDoneDesktop";
import DesktopHeader from "./DesktopHeader";
import DesktopMenu from "./DesktopMenu";
import OnBoarding from "./OnBoarding/OnBoarding";
import PaymentPage from "./Payment/PaymentPage";
import ProgressDivider from "./ProgressDivider";
import QuestionnaireStepDesktop from "./QuestionnaireStep/QuestionnaireStepDesktop";
import ReviewUnanswered from "./ReviewUnanswered/ReviewUnanswered";
import { SubmitPage } from "./Submit/SubmitPage";
import { Category, Step } from "../constants/questions";
import { QuestionType, StepType } from "../enums/enum";

type FromProps = {
    user: IUser;
    totalProgress: number;
    connectedBankAccounts: AccountDetails[];
    refetchConnectedBankAccounts: () => void;
    handlePrev: () => void;
    answers: Record<string, Record<string, any>>;
    currentStepData: Step;
    handleStepContinue: (_questionType?: QuestionType) => void;
    openConnectModal: () => void;
    handleAnswerChange: (_question: string, _answer: any) => void;
    allQuestionsAnswered: boolean;
    allProfilesCompleted: boolean;
    playPigAnimationTrigger: string;
    currentCategoryTitle: string;
    handleCategoryClick: (_categoryNumber: number) => void;
    refetchTaxProfiles: () => void;
    currentCategoryData: Category;
    taxProfilesStatus: string;
};

const DesktopForm: FC<FromProps> = ({
    user,
    connectedBankAccounts,
    refetchConnectedBankAccounts,
    totalProgress,
    handlePrev,
    answers,
    currentStepData,
    handleStepContinue,
    openConnectModal,
    handleAnswerChange,
    allQuestionsAnswered,
    allProfilesCompleted,
    playPigAnimationTrigger,
    currentCategoryTitle,
    handleCategoryClick,
    refetchTaxProfiles,
}) => {
    return (
        <div className="new-questionnaire__desktop-form-wrapper">
            <DesktopHeader
                user={user}
                connectedBankAccounts={connectedBankAccounts}
                openConnectModal={openConnectModal}
                playPigAnimationTrigger={playPigAnimationTrigger}
                handleContinue={handleStepContinue}
                currentStepData={currentStepData}
            />

            <ProgressDivider progress={totalProgress} />
            <div className="new-questionnaire__desktop-form-container">
                <DesktopMenu handleCategoryClick={handleCategoryClick}/>
                <div className="new-questionnaire__desktop-form">

                    {currentStepData.type === StepType.onboarding && <>
                        <OnBoarding
                            handleNext={handleStepContinue}
                            openConnectModal={openConnectModal}
                        />
                    </>}

                    {currentStepData.type === StepType.bankAccount && <>
                        <BankAccount user={user} handleContinue={handleStepContinue} connectedBankAccounts={connectedBankAccounts} openConnectModal={openConnectModal} isHeaderComponent={false}/>
                    </>}

                    {currentStepData.type === StepType.questionnaire && <>
                        <QuestionnaireStepDesktop
                            currentStepData={{ ...currentStepData, hasBack: currentStepData.hasBack }}
                            handlePrev={handlePrev}
                            handleAnswerChange={handleAnswerChange}
                            categoryAnswers={answers[currentCategoryTitle]}
                            allQuestionsAnswered={allQuestionsAnswered}
                            handleStepContinue={handleStepContinue}
                            allProfilesCompleted={allProfilesCompleted}
                            user={user}
                            refetchTaxProfiles={refetchTaxProfiles}
                            refetchConnectedBankAccounts={refetchConnectedBankAccounts}
                            connectedBankAccounts={connectedBankAccounts}
                        />
                    </>}

                    {currentStepData.type === StepType.categoryDone && <>
                        <CategoryDoneDesktop
                            currentStepData={currentStepData}
                            handleContinue={handleStepContinue}
                        />
                    </>}

                    {currentStepData.type === StepType.payment && <>
                        <PaymentPage
                            currentStepData={currentStepData}
                            handlePrev={handlePrev}
                            answers={answers}
                            user={user}
                            handleContinue={handleStepContinue}
                        />
                    </>}

                    {currentStepData.type === StepType.review && <>
                        <ReviewUnanswered
                            currentStepData={currentStepData}
                            handlePrev={handlePrev}
                            answers={answers}
                            handleContinue={handleStepContinue}
                            handleAnswerChange={handleAnswerChange}
                        />
                    </>}

                    {currentStepData.type === StepType.submit && <>
                        <SubmitPage user={user} />
                    </>}

                </div>
            </div>
        </div>
    );
};

export default DesktopForm;
