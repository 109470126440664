import React, { useContext, useEffect } from "react";

import { AppContext } from "App";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

import { RootState } from "../../../redux/store";

const PrivateQuestionnaireRoute = ({
    component: Component,
    ...props
}: {
    component: React.FC<any>;
    props?: any;
}) => {
    const { isAuth } = useContext(AppContext);
    const { currentDocument } = useAppSelector(
        (state: RootState) => ({
            currentDocument: state.document.currentDocument,
        })
    );

    const location = useLocation();

    useEffect(() => {
        if (!isAuth) {
            sessionStorage.setItem("last-visited-page", location.pathname + location.search);
        }
    }, [isAuth, location.pathname, location.search]);

    if (!isAuth) {
        return <Navigate replace to="/auth" />;
    } else if (currentDocument && currentDocument.answersOB?.shouldComplete === false) {
        return <Navigate replace to="/profile" />;
    }

    return <Component {...props} />;
};

export default PrivateQuestionnaireRoute;
