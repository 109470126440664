import { UK_SIGNUP_STEP } from "constants/signupFormNew";

import { useContext, useEffect } from "react";

import { AppContext } from "App";
import HeaderNew from "components/basic/Header/HeaderNew";
import SignUpFormNew from "components/basic/SignUpForm/SignUpFormNew";
import { useLocation, useNavigate } from "react-router-dom";
import { getQueryParam, scrollToTop } from "utils/common";

const SignUpMobileNew = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isMobile, isAuth } = useContext(AppContext);

    const step = getQueryParam("step", location) || UK_SIGNUP_STEP.PERSONAL_INFO;

    useEffect(() => {
        scrollToTop();
        if (!isMobile || isAuth) {
            navigate("/");
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    return (
        <>
            <HeaderNew></HeaderNew>
            <div className="uk-sign-up-mobile">
                <SignUpFormNew step={step as UK_SIGNUP_STEP}/>
            </div>
        </>
    );
};

export default SignUpMobileNew;
