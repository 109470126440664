import { useContext } from "react";

import { Button, Upload } from "antd";
import { AppContext } from "App";
import { addSpaceEveryNthChar } from "utils/strings";

import uploadIcon from "../../../../assets/images/uploadIcon.svg";

const FileUploadCode = ({
    name,
    originalName,
    url,
    setUrl,
    uploadFile,
    setOriginalName,
    handleUploadFailure,
    handleUploadSuccess,
}: {
    name: string;
    originalName: string;
    label: string;
    uploadFile: (_file: File) => Promise<string>;
    url: string;
    setUrl: (_url: string) => void;
    setOriginalName: (_name: string) => void;
    handleUploadFailure: () => void;
    handleUploadSuccess: () => void;
}) => {
    const {
        isMobile,
    } = useContext(AppContext);
    return (
        <div>
            <Upload.Dragger
                name={name}
                multiple={false}
                showUploadList={false}
                disabled={!uploadFile}
                style={{
                    backgroundColor: "white",
                    border: "none",
                }}
                customRequest={async data => {
                    const file = data.file as File;
                    const result = await uploadFile(file);

                    if (!result) {
                        handleUploadFailure();
                    } else {
                        setUrl(result);
                        setOriginalName(file.name);
                        handleUploadSuccess();
                    }
                    return true;
                }}
            >
                {isMobile
                    ? <div className={"code__footer"}>
                        <div className={"p20 border-top-1px"} style={{ width: "100%" }}>
                            {!url
                                ? <Button size={"large"}
                                    shape={"round"}
                                    type={"primary"}
                                    className={"uk-button"}
                                    style={{ width: "100%", maxWidth: "300px", height: "60px" }}
                                >
                        Upload
                                    <img src={uploadIcon} alt="Upload" style={{ marginBottom: -4, marginLeft: 8, height: "19.5px" }} />
                                </Button>
                                : <div>
                                    <div>
                        File has been successfully uploaded.
                                    </div>
                                    <div className={"p10 fs12 primary-color"}>
                                        {addSpaceEveryNthChar(originalName || url, 52)}
                                    </div>
                                </div>}
                        </div></div> : <div>
                        {!url
                            ? <Button size={"large"}
                                shape={"round"}
                                type={"primary"}
                                className={"uk-button"}
                                style={{ width: "100%", maxWidth: "300px", height: "60px" }}
                            >
                        Upload
                                <img src={uploadIcon} alt="Upload" style={{ marginBottom: -4, marginLeft: 8, height: "19.5px" }} />
                            </Button>
                            : <div>
                                <div>
                        File has been successfully uploaded.
                                </div>
                                <div className={"p10 fs12 primary-color"}>
                                    {addSpaceEveryNthChar(originalName || url, 52)}
                                </div>
                            </div>}
                    </div>}
            </Upload.Dragger>
        </div>
    );
};

export default FileUploadCode;
