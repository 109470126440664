import { Button } from "antd";
import { useParams } from "react-router-dom";

import retry_logo from "../../../assets/images/Retry_Logo.png";

const HMRCErrorPage = () => {
    const { clientId } = useParams();
    const hmrcLinkUrl = process.env.REACT_APP_API_URL + `clients/agentLink/${clientId}`;
    return (
        <div style={{
            width: "100vw",
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            textAlign: "center",
        }}>

            <div style={{
                display: "block",
                marginTop: "60px",
                alignItems: "center",
                width: "600px",
                height: "800px",
                justifyContent: "center",
            }}>
                <div>
                    <img
                        src="https://taxzap-images.s3.eu-west-1.amazonaws.com/Logo+(1).png"
                        alt="submitted details"
                        style={{
                            position: "relative",
                            height: "50px",
                            marginTop: "5px",
                        }}
                    />
                </div>

                <img
                    src="https://taxzap-images.s3.eu-west-1.amazonaws.com/HMRC_ERROR_SCREEN.png"
                    alt="submitted details"
                    style={{
                        position: "relative",
                        width: "300px",
                        height: "300px",
                        marginTop: "15px",
                    }}
                />
                <div>
                    <h3 style={{
                        fontSize: "36px",
                        fontFamily: "Poppins",
                        fontWeight: 800,
                        color: "#000",
                        lineHeight: "42px",
                    }}>HMRC is down for now :(</h3>
                    <p style={{
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        color: "#474747",
                        fontWeight: 400,

                    }}>It seems like HMRC’s website is currently down. Please try again later.
                        <br/>
                        <br/>
                        In the meantime, we’ve requested your code by post as well, and you can enter it once it
                        arrives.</p>
                </div>
                <Button style={{
                    height: "70px",
                    width: "350px",
                    borderRadius: "100px",
                    background: "var(--primary, #2EBB49)",
                    color: "#FFFFFF",
                    fontSize: "16px",
                    textDecoration: "none",
                    justifyContent: "center",
                }} href={hmrcLinkUrl}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "5px",
                    }}>
                        <p style={{
                            color: "var(--white, #FFF)",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "18px", /* 128.571% */
                        }}>Try Again </p>
                        <img alt="retry" src={retry_logo} style={{
                            height: "20px",
                            margin: "0px",
                            padding: "0px",
                        }}/>
                    </div>
                </Button>
            </div>
        </div>
    );
};

export default HMRCErrorPage;
