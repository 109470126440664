import { FC, useContext, useState, useEffect } from "react";

import { Col, Typography } from "antd";
import { AppContext } from "App";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { IBlogUK } from "types/blogUK";

interface BlogPageProps {
    setShowButtonFooter: (_value: boolean) => void;
}

const BlogPageComponent: FC<BlogPageProps> = ({ setShowButtonFooter }) => {
    const { Title } = Typography;
    const { isMobile } = useContext(AppContext);
    useEffect(() => {
        const handleScroll = () => {
            const startButton = document.getElementById("blogStartButton");
            if (startButton) {
                const rect = startButton.getBoundingClientRect();
                const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
                setShowButtonFooter(!isInView);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const contentful = require("contentful");
    const client = contentful.createClient({
        space: "w0hcz4e46cwo",
        accessToken: "OEsI882KvnNdRBQ5iQXclL8wRzJZHD65Ah99KvmsPLg",
    });
    const { blogpostName } = useParams();
    const [blogPages, setBlogPages] = useState<IBlogUK[]>([]);
    useEffect(() => {
        const handleResponse = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "newUkBlogPost",
                    "fields.link": blogpostName,
                });

                const blogPages = response.items;
                return blogPages;
            } catch (error) {
                console.error("Error fetching blog posts:", error);
                return [];
            }
        };
        handleResponse().then(response => setBlogPages(response));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blogpostName]);
    return (
        <div>
            {blogPages.map((page: IBlogUK, index) => (
                <div key={index}>
                    {(page.fields.metaTitle && page.fields.metaDescription) &&
                        <Helmet>
                            <title>
                                {page.fields.metaTitle}
                            </title>
                            <meta name="description" content={page.fields.metaDescription}/>
                        </Helmet>
                    }
                    <div className="horizontal-center" style={isMobile ? {
                        marginTop: -4,
                        padding: "24px",
                    } : { padding: "0 100px 0 100px" }}>
                        <div className="blog__content" style={{ padding: "50px 0 50px 0" }
                        }
                        ><Title className="blog__title" style={
                                isMobile
                                    ? {
                                        fontSize: "30px",
                                        textAlign: "center",
                                    }
                                    : {
                                        fontSize: "36px",
                                        marginTop: "92px",
                                        marginBottom: "30px",
                                        paddingLeft: "140px",
                                        paddingRight: "140px",
                                        textAlign: "center",
                                    }
                            }> {page.fields.content}
                            </Title>
                            <img className="blog__image_1"
                                alt="author icon"
                                src={page.fields.authorImage.fields.file.url}/>
                            <Typography className="blog__title" style={{
                                textAlign: "center",
                                color: "#444",
                            }}>
                                {page.fields.author}
                            </Typography>
                            <Typography className="blog__title" style={isMobile
                                ? {
                                    textAlign: "center",
                                    fontSize: "14px",
                                    color: "#2EBB49",
                                    marginTop: "0px",
                                    marginBottom: "10px",
                                    fontWeight: "500",
                                }
                                : {
                                    textAlign: "center",
                                    fontSize: "14px",
                                    color: "#2EBB49",
                                    marginTop: "0px",
                                    marginBottom: "40px",
                                    fontWeight: "500",
                                }}>
                                {page.fields.agentRole}
                            </Typography>
                            <Col className="blog__icons"
                                style={isMobile ? { textAlign: "center" } : {
                                    textAlign: "center",
                                    marginBottom: "40px",
                                }}>
                                <a
                                    href={`https://www.facebook.com/sharer/sharer.php?u=https://taxzap.ie${page.fields.link}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="page-link"
                                >
                                    <img className="blog__icon"
                                        alt="facebook icon"
                                        src={page.fields.facebook.fields.file.url}
                                        style={{ marginRight: "20px" }}/>
                                </a>
                                <a
                                    href={`https://twitter.com/intent/tweet?text=https://taxzap.ie${page.fields.link}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="page-link"
                                >
                                    <img className="blog__icon"
                                        alt="twitter icon"
                                        src={page.fields.twitter.fields.file.url}
                                        style={{ marginRight: "20px" }}/>
                                </a>
                                <a
                                    href={`mailto:?subject=PAYE%20Explained%20%26%20the%20Importance%20of%20Having%20a%20Tax%20Agent&body=https://taxzap.ie${page.fields.link}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="page-link"
                                >
                                    <img className="blog__icon"
                                        alt="email icon"
                                        src={page.fields.email.fields.file.url}/>
                                </a>
                            </Col>
                            <Col id="blogStartButton" className="blog__content"
                                style={isMobile ? { paddingTop: "20px" } : {}}>
                                <ReactMarkdown className="blog__body" children={page.fields.body}/>
                            </Col>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
export default BlogPageComponent;
