import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { message } from "antd";
import { useUser } from "hooks/common/useUser";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { setAnswers } from "redux/reducers/answers";
import { selectCurrentNavigation } from "redux/reducers/navigation";
import { useDeleteClientTaxProfileMutation, useLazyGetAllClientTaxProfilesQuery } from "services/client"; // Import the lazy query hook
import { additionalIncomeMappingNewNavBar, checkDepends, employmentTypeMapping } from "utils/new-questionnaire";
import { logError } from "utils/sentry";
import { removeEmojis } from "utils/strings";

import { Depend, Question } from "../../constants/questions";
import { CategoryTitle, ParentProfileType, PromptModalType } from "../../enums/enum";
import DeleteModal from "../Shared/DeleteModal";
import PromptModal from "../Shared/PromptModal";

type PrimaryIncomeQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: string[] | undefined) => void;
    initialValue?: any;
    categoryAnswers?: Record<string, any>;
    handlePrev: () => void;
    setContinueButtonText?: (_text: string) => void;
};

const PrimaryIncomeQuestion: FC<PrimaryIncomeQuestionProps> = ({
    data,
    onAnswer,
    handlePrev,
    initialValue,
    categoryAnswers,
    setContinueButtonText,
}) => {
    const dispatch = useDispatch();
    const { user } = useUser();
    const { stepLoading } = useAppSelector(selectCurrentNavigation);
    const { answers } = useAppSelector(state => state.answers);

    const [selectedOptions, setSelectedOptions] = useState<string[]>(initialValue || []);
    const [pendingRemoval, setPendingRemoval] = useState<string | null>(null);
    const [deletingProfiles, setDeletingProfiles] = useState<boolean>(false);
    const [getAllClientTaxProfiles] = useLazyGetAllClientTaxProfilesQuery();
    const [deleteClientTaxProfile] = useDeleteClientTaxProfileMutation();

    const promptModalData = useMemo(() => ({
        type: data.promptModal?.type || PromptModalType.info,
        title: data.promptModal?.title || "",
        text: data.promptModal?.text || "",
        image: data.promptModal?.image || "",
        promptActionButtonText: data.promptModal?.promptActionButtonText || "",
        promptActionButtonUrl: data.promptModal?.promptActionButtonUrl || "",
    }), [data.promptModal]);

    const {
        isOpened: isQuestionHelpModalOpened,
        open: handleQuestionHelpOpenModal,
        close: handleQuestionHelpCloseModal,
    } = useModalOpener();

    const {
        isOpened: isConfirmRemoveModalOpened,
        open: openConfirmRemoveModal,
        close: closeConfirmRemoveModal,
    } = useModalOpener();

    const getAnswerKey = (mappedParentProfileType: string) => {
        switch (mappedParentProfileType) {
        case ParentProfileType.paye:
            return CategoryTitle.Employment;
        case ParentProfileType.cis:
            return CategoryTitle.CIS;
        case ParentProfileType.interest:
            return CategoryTitle.InterestIncome;
        case ParentProfileType.capitalGainsLosses:
            return CategoryTitle.CapitalGains;
        default:
            return mappedParentProfileType;
        }
    };

    const updateSelection = (value: string) => {
        const updatedOptions = selectedOptions.includes(value)
            ? selectedOptions.filter(option => option !== value)
            : [...selectedOptions, value];

        setSelectedOptions(updatedOptions);
        onAnswer(data.question, updatedOptions);
    };

    const fetchTaxProfilesForSection = async (mappedSection: ParentProfileType) => {
        if (!user?.id || !mappedSection) {
            logError(new Error("User ID or mapped section is not defined when fetching tax profiles"), {
                userId: user?.id,
                mappedSection,
            });
            message.error("Failed to fetch tax profiles. Please try again.");
            return null;
        }

        try {
            const response = await getAllClientTaxProfiles({
                client: user.id,
                parentType: mappedSection,
            }).unwrap();

            return response.taxProfiles;
        } catch (error) {
            logError(error, {
                clientId: user?.id,
                parentType: mappedSection,
                function: "fetchTaxProfilesForSection",
            });
            message.error("Failed to fetch tax profiles. Please try again.");
            return null;
        }
    };

    const updateAnswersState = async (answerKey: string) => {
        const updatedOptions = selectedOptions.includes(pendingRemoval!)
            ? selectedOptions.filter(option => option !== pendingRemoval)
            : [...selectedOptions, pendingRemoval!];

        setSelectedOptions(updatedOptions);

        const updatedAnswers = {
            ...answers,
            [CategoryTitle.PersonalInfo]: {
                ...answers[CategoryTitle.PersonalInfo],
                [data.question]: updatedOptions,
            },
            [answerKey]: {},
        };

        dispatch(setAnswers({ ...updatedAnswers }));
        message.success("All relevant tax profiles deleted successfully.");
    };

    const deleteClientTaxProfiles = async (taxProfileIds: string[], answerKey: string) => {
        if (!user?.id) {
            message.error("Please login again.");
            return;
        }

        if (taxProfileIds.length === 0) {
            // Update the answers in Redux
            await updateAnswersState(answerKey);
            return;
        }

        const failedDeletions: string[] = [];

        await Promise.all(
            taxProfileIds.map(profileId =>
                deleteClientTaxProfile({ client: user.id, taxProfileId: profileId })
                    .catch(error => {
                        failedDeletions.push(profileId);
                        logError(error, { clientId: user.id, profileId, function: "deleteClientTaxProfile" });
                    })
            )
        );

        if (failedDeletions.length > 0) {
            message.error(`Failed to delete ${failedDeletions.length} tax profile(s).`);
        } else {
            updateAnswersState(answerKey);
        }
    };

    const getMappedRemovedProfileType = (pendingRemoval: string) => {
        if (employmentTypeMapping[pendingRemoval]) {
            return employmentTypeMapping[pendingRemoval];
        }
        if (additionalIncomeMappingNewNavBar[pendingRemoval]) {
            return additionalIncomeMappingNewNavBar[pendingRemoval];
        }
        return pendingRemoval;
    };

    const confirmDeleteTaxProfiles = async (value: string) => {
        setDeletingProfiles(true);
        const mappedParentProfileType = getMappedRemovedProfileType(value);
        const answerKey = getAnswerKey(mappedParentProfileType);
        try {
            if (!Object.values(ParentProfileType).includes(mappedParentProfileType as ParentProfileType)) {
                await updateAnswersState(answerKey);
                return;
            }

            const taxProfiles = await fetchTaxProfilesForSection(mappedParentProfileType as ParentProfileType);

            if (!taxProfiles) {
                message.error("Could not retrieve tax profiles.");
                return;
            }

            const taxProfileIds = taxProfiles.map(profile => profile._id);
            await deleteClientTaxProfiles(taxProfileIds, answerKey);
        } catch (error) {
            logError(error, {
                clientId: user?.id,
                parentType: mappedParentProfileType,
                function: "confirmDeleteTaxProfiles",
            });
            message.error("An unexpected error occurred. Please try again later.");
        } finally {
            setDeletingProfiles(false);
            closeConfirmRemoveModal();
        }
    };

    const handleOptionChange = async (value: string) => {
        if (stepLoading) return;

        const mappedParentProfileType = getMappedRemovedProfileType(value);
        const answerKey = getAnswerKey(mappedParentProfileType);
        const taxProfiles = await fetchTaxProfilesForSection(mappedParentProfileType as ParentProfileType);

        // Check if removal should be prevented based on exceptions
        const primaryIncomeSources = categoryAnswers?.["Select your primary income sources"] || [];
        const additionalIncomeSources = categoryAnswers?.["Select your additional income sources"] || [];

        const isException =
            (value === "🧑‍💼 Employed (PAYE)" && primaryIncomeSources.includes("💼 Director of a LTD company")) ||
            (value === "💼 Director of a LTD company" && primaryIncomeSources.includes("🧑‍💼 Employed (PAYE)")) ||
            (value === "🏠 Landlord" && additionalIncomeSources.includes("🏠 Rental Income")) ||
            (value === "🏠 Rental Income" && primaryIncomeSources.includes("🏠 Landlord"));

        if (isException) {
            updateSelection(value);
            return;
        }

        // Proceed with normal flow
        if (selectedOptions.includes(value) && value !== "🌎 Foreign income") {
            // Check if there are answers for this section or profiles
            if ((answers[answerKey] && Object.keys(answers[answerKey]).length > 0) || (taxProfiles && taxProfiles.length > 0)) {
                setPendingRemoval(value);
                openConfirmRemoveModal();
            } else {
                // If no answers, just update the selection
                updateSelection(value);
            }
        } else {
            // If the option is not selected, just update the selection
            updateSelection(value);
        }
    };

    const confirmRemoval = () => {
        if (pendingRemoval) {
            confirmDeleteTaxProfiles(pendingRemoval);
        }
    };

    const cancelRemoval = () => {
        setPendingRemoval(null);
        closeConfirmRemoveModal();
    };

    const checkModalDepends = useCallback((depend: Depend[], categoryAnswers?: Record<string, any>) => {
        if (categoryAnswers) {
            return checkDepends(depend, categoryAnswers);
        } else {
            return false;
        }
    }, []);

    useEffect(() => {
        const initialValueArray = Array.isArray(initialValue) ? initialValue : [];
        setSelectedOptions(initialValueArray.filter(value => data?.options?.includes(value)) || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useEffect(() => {
        setContinueButtonText && setContinueButtonText(selectedOptions.length ? "Continue" : "None");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptions]);
    useEffect(() => {
        return () => setContinueButtonText && setContinueButtonText("Continue");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data?.promptModal?.depend && categoryAnswers) {
            const shouldModalShow = checkModalDepends(data.promptModal?.depend, categoryAnswers);
            if (shouldModalShow && !data.promptModal?.manualOpen) {
                handleQuestionHelpOpenModal();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryAnswers]);

    const closeModal = () => {
        const option = data.options?.find(it => it.toLowerCase().includes("foreign income"));
        if (option) {
            handleOptionChange(option);
            handleQuestionHelpCloseModal();
        }
    };

    return (
        <div className="new-questionnaire__question">
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title">{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                {data.question}
            </div>
            <div className="new-questionnaire__select-many-tiles__question-options">
                {data?.options?.map((value, index) => (
                    <div
                        key={index}
                        className={`new-questionnaire__select-many-tiles__question-option${selectedOptions.includes(value) ? " selected" : stepLoading ? " disabled" : ""}`}
                        onClick={() => handleOptionChange(value)}
                    >
                        {data.iconMapping && (
                            <div>
                                <img width={30} height={30} src={data.iconMapping[value]} alt="icon" style={{ display: "block" }} />
                            </div>
                        )}
                        <div className="new-questionnaire__select-many-tiles__text">
                            {data.iconMapping ? removeEmojis(value) : value}
                        </div>
                    </div>
                ))}
            </div>

            {/* Confirmation Modal for Removing an Option */}
            {isConfirmRemoveModalOpened && (
                <DeleteModal
                    isOpen={isConfirmRemoveModalOpened}
                    onCancel={cancelRemoval}
                    onConfirm={confirmRemoval}
                    closeable
                    data={{
                        title: "Removing income selection?",
                        text: `Are you sure? All tax profiles created in the <strong>"${getMappedRemovedProfileType(pendingRemoval!)}"</strong> section will be deleted. This action cannot be undone.`,
                        confirmButtonText: "Yes, remove",
                    }}
                    isDeleting={deletingProfiles}
                />
            )}

            {/* Help Modal */}
            {isQuestionHelpModalOpened && (
                <PromptModal
                    isOpen={isQuestionHelpModalOpened}
                    closeModal={closeModal}
                    handlePrev={handlePrev}
                    closeButtonText={"Unselect foreign income"}
                    data={promptModalData}
                    closeable={false}
                />
            )}
        </div>
    );
};

export default PrimaryIncomeQuestion;
