import { FC } from "react";

import { Row, Col, Typography, Tooltip } from "antd";
import bankFrame from "assets/images/payment/bank_frame.svg";
import moment from "moment";
import { getCurrencySymbol } from "utils/common";
import { formatAmountWithCommas } from "utils/new-questionnaire";

import CustomCheckbox from "../../CustomCheckbox";

type TransactionListItemProps = {
    item: any;
    showMultiselect: boolean;
    selectedTransactions?: Set<string>;
    toggleTransactionSelection?: (_id: string) => void;
    openDrawer?: (_item: any) => void;
};

const TransactionListItem: FC<TransactionListItemProps> = ({
    item,
    showMultiselect,
    selectedTransactions,
    toggleTransactionSelection,
}) => {
    return (
        <Row gutter={[8, 8]} className="w100">
            {/* Merchant Logo or Checkbox */}
            <Col span={3}>
                {showMultiselect && selectedTransactions && toggleTransactionSelection ? (
                    <CustomCheckbox
                        checked={selectedTransactions.has(item.id)}
                        onChange={(_e: any) => toggleTransactionSelection(item.id)}
                    />
                ) : (
                    <img
                        height={30}
                        src={item?.merchant?.logo || bankFrame}
                        alt={item?.merchant?.name}
                        style={{ display: "block", borderRadius: "50%" }}
                        onError={e => {
                            e.currentTarget.src = bankFrame;
                        }}
                    />
                )}
            </Col>

            {/* Transaction Details */}
            <Col span={15}>
                <Typography.Text className="approvedRejected__list-item-description" ellipsis>
                    {item.description || "N/A"}
                </Typography.Text>
                <div className="approvedRejected__list-item-merchant mt4">
                    {item?.merchant?.name}{" "}
                    <span style={{ padding: "0 3px 0 0", color: "#707070" }}>
                        &#x2022; {item.tags[0]}
                    </span>
                </div>
                <div className="approvedRejected__list-item-date mt4">
                    {moment(item.bookingDateTime).format("Do MMM YYYY")}
                </div>
            </Col>

            {/* Transaction Amount */}
            <Col span={6} className="taRight">
                <Tooltip title={item?.totalAmount}>
                    <Typography.Text className="approvedRejected__list-item-amount" ellipsis>
                        {`${getCurrencySymbol(item?.currencyCode || "GBP")}${formatAmountWithCommas(
                            Math.abs(item?.totalAmount || 0).toFixed(2)
                        )}`}
                    </Typography.Text>
                </Tooltip>
            </Col>
        </Row>
    );
};

export default TransactionListItem;
