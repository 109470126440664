import { FC, useContext, useState } from "react";

import { AppContext } from "App";
import { IClientDocument } from "types/client";

import { PaymentFailureModal } from "./PaymentFailureModal";
import { PaymentSuccessModal } from "./PaymentSuccessModal";
import { PriceBreakDown } from "./PriceBreakDown";
import { ReturnToQuestionnaire } from "./ReturnToQuestionnaire";
import RevolutPaymentComponent from "./RevolutPaymentComponent";

type PaywallProps = {
    showReturnToQuestionnaire: boolean
    currentReturn?: IClientDocument | null
    handleContinue?: () => void
}

export const Paywall: FC<PaywallProps> = ({ showReturnToQuestionnaire, currentReturn, handleContinue }) => {
    const { isMobile } = useContext(AppContext);

    const [amount, setAmount] = useState(0);
    const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);
    const [genericPaymentFailure, setGenericPaymentFailure] = useState(false);
    const [insufficientFunds, setInsufficientFunds] = useState(false);

    return (
        <div className="p20">
            {showPaymentSuccessModal && (
                <PaymentSuccessModal
                    showPaymentSuccessModal={showPaymentSuccessModal}
                    onSuccess={() => {
                        setShowPaymentSuccessModal(false);
                        handleContinue?.();
                    }}
                />
            )}
            {genericPaymentFailure && (
                <PaymentFailureModal
                    paymentFailed={genericPaymentFailure}
                    setPaymentFailed={setGenericPaymentFailure}
                    message="There was an issue processing your payment. Please check your internet connection and try again."
                    title="Payment Error"
                />
            )}
            {insufficientFunds && (
                <PaymentFailureModal
                    paymentFailed={insufficientFunds}
                    setPaymentFailed={setInsufficientFunds}
                    message="Please check your card details and try again. If this issue continues, please check with your bank."
                    title="Payment Declined"
                />
            )}

            {showReturnToQuestionnaire && <ReturnToQuestionnaire currentReturn={currentReturn}/>}
            <div className={`homepage__priceBreakDown${isMobile ? "-mobile" : "-desktop"}`}>
                <div>
                    <PriceBreakDown amount={amount} setAmount={setAmount} styles={{
                        width: "100%",
                        hideBorder: true,
                    }} />
                </div>
            </div>
            <div>
                <RevolutPaymentComponent
                    key={amount}
                    setGenericPaymentFailure={setGenericPaymentFailure}
                    setInsufficientFundsPaymentFailure={setInsufficientFunds}
                    setShowPaymentSuccessModal={setShowPaymentSuccessModal}
                    setAmount={setAmount}
                    amount={amount}
                />
            </div>
        </div>
    );
};
