import React, { useEffect, useRef, useState } from "react";

interface LazyLoadedImageProps {
  src: string;
  alt: string;
  className?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
}

const LazyLoadedImage: React.FC<LazyLoadedImageProps> = ({ src, alt, className, width, height, maxWidth }) => {
    const [isVisible, setIsVisible] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        const currentRef = imgRef.current;
        if (!currentRef) return;

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(currentRef);
                }
            });
        });

        observer.observe(currentRef);

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    const imgSrc: string = isVisible ? src : "";

    return (
        <img
            ref={imgRef}
            className={className}
            src={imgSrc}
            alt={alt}
            style={{ width: width, height: height, maxWidth: maxWidth }}
            loading="lazy"
        />
    );
};

export default LazyLoadedImage;
