import "styles/profile/paymentDetails.less";

import { SECONDARY_STATUSES_DICT } from "constants/common";

import { useState, useEffect, useMemo, useCallback, useContext, Dispatch, SetStateAction } from "react";

import { Input, Form, Typography, Button, message, DatePicker } from "antd";
import { AppContext } from "App";
import editIcon from "assets/images/profile/edit.svg";
import LottieAnimation from "components/basic/Spinner/Lottie";
import PhoneInputWithCountryCode from "components/inputs/Form/Inputs/PhoneInputWithCountryCode";
import dayjs from "dayjs";
import { useAppDispatch } from "redux/hooks";
import { useUpdateCurrentUserMutation } from "services/auth";
import { checkClientEmailAvailability } from "services/client";
import { IUser } from "types/auth";
import { getCurrentReturn } from "utils/returns";
import { isNameValid, isPasswordLengthValid, isPhoneValid, isPostcodeValid, isUTRValid, normalizeUTR } from "utils/validation";

import "dayjs/locale/en-gb";
dayjs.locale("en-gb");

type PersonalDetailsProps = {
    user: IUser;
    setPageLoading: Dispatch<SetStateAction<boolean>>
};

export default function PersonalDetails({ user, setPageLoading }: PersonalDetailsProps) {
    const dispatch = useAppDispatch();
    const [updateCurrentUser, { isLoading }] = useUpdateCurrentUserMutation();
    const { isMobile } = useContext(AppContext);

    const [form] = Form.useForm();
    const [isEditing, setIsEditing] = useState(false);
    const [hasErrors, setHasErrors] = useState(true);

    const currentDocument = useMemo(() => {
        return getCurrentReturn(user.documents);
    }, [user.documents]);

    const initialValues = useMemo(() => ({
        firstName: user.info.firstName || "",
        lastName: user.info.lastName || "",
        email: user.info.email || "",
        phone: user.info.phone || "",
        dateOfBirth: dayjs(user.info.dateOfBirth) || "",
        address1: user.address?.address1 || "",
        address2: user.address?.address2 || "",
        city: user.address?.city || "",
        postcode: user.address?.postcode || "",
        nin: user.nin || "",
        utr: user.utr || "",
        password: "",
    }), [user]);

    const handleEmailChange = async (value: string) => {
        const result = await dispatch(checkClientEmailAvailability.initiate({
            email: value,
        }, { forceRefetch: true })).unwrap();

        return result?.available;
    };

    const handleFinish = (values: any) => {
        updateCurrentUser({
            info: {
                dateOfBirth: values.dateOfBirth.format("YYYY-MM-DD"),
                email: values.email?.trim(),
                firstName: values.firstName?.trim(),
                lastName: values.lastName?.trim(),
                password: values.password,
                phone: values.phone,
            },
            address: {
                address1: values.address1.trim(),
                address2: values.address2.trim(),
                city: values.city.trim(),
                postcode: values.postcode.trim(),
                country: values.country.trim(),
            },
            utr: values.utr?.trim(),
            nin: values.nin?.trim(),
        }).unwrap()
            .then(async () => {
                await message.success("Personal Details updated successfully");
            })
            .catch(async error => {
                await message.error(error.data.message);
            });
        setIsEditing(false);
        form.setFieldsValue({ password: "" });
    };

    const handleEdit = () => {
        form.setFieldsValue(initialValues);
        setIsEditing(prev => !prev);
    };

    const validatePhoneNumber = useCallback(async (value: string) => {
        if (!value || value === "+353" || value === "+44" || isPhoneValid(value)) {
            return Promise.resolve();
        }
        if (value.startsWith("+353")) return Promise.reject("Please enter a valid mobile number. Ex: +353 822334455");
        if (value.startsWith("+44")) return Promise.reject("Please enter a valid mobile number. Ex: +44 2071234567");
    }, []);

    const validateUTR = useCallback(async (value: string) => {
        if (!value) return Promise.resolve();
        const isValid = isUTRValid(normalizeUTR(value));
        if (isValid) return Promise.resolve();
        return Promise.reject("Please enter a valid UTR");
    }, []);

    const validateName = useCallback(async (value: string) => {
        if (!value) return Promise.resolve();
        if (isNameValid(value)) return Promise.resolve();
        return Promise.reject("Please write your name without an apostrophe");
    }, []);

    const validatePostcode = useCallback(async (value: string) => {
        if (!value) return Promise.resolve();
        if (isPostcodeValid(value)) return Promise.resolve();
        return Promise.reject("Please enter a valid Postcode (Ex: W1A1HQ)");
    }, []);

    const validatePassword = useCallback(async (value: string) => {
        if (!value) return Promise.resolve();
        if (isPasswordLengthValid(value)) return Promise.resolve();
        return Promise.reject("Password must be at least 8 characters long");
    }, []);

    useEffect(() => {
        form.setFieldsValue(initialValues);

        form.validateFields()
            .then(() => setHasErrors(false))
            .catch(() => setHasErrors(true));
    }, [form, initialValues]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setPageLoading(false);
        }, 200);
        return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="profile__content">
            {!isMobile && <div className="profile__content-header">
                <Typography className="profile__content-heading">
                    Personal Details
                </Typography>
            </div>}
            <div className="payment-details__content-body">
                <LottieAnimation isLoading={isLoading}/>
                <div className="payment-details__content">
                    {!isMobile &&
                        <div className="payment-details__header">
                            <Typography className="payment-details__content-heading">
                                Personal Details
                            </Typography>
                            <div
                                className="payment-details__edit-button"
                                style={isMobile ? { marginTop: "10px" } : {}}
                                onClick={handleEdit}
                            >
                                <img src={editIcon} alt="edit icon" />
                                <span>
                                    {isEditing ? "Cancel" : "Edit"}
                                </span>
                            </div>
                        </div>
                    }
                    <Form
                        layout="vertical"
                        style={{ width: "100%" }}
                        form={form}
                        onFinish={handleFinish}
                        onFieldsChange={(_, allFields) => {
                            const errorFields = allFields.filter(field => !!field?.errors?.length);
                            setHasErrors(errorFields.length > 0);
                        }}
                    >
                        {isMobile
                            ? <div className="payment-details__form">
                                <div className="payment-details__form-column">
                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="firstName"
                                        label="First Name"
                                        rules={[{ required: true, message: "Please input your first name" }, {
                                            validator: (_, value) => validateName(value),
                                        }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="lastName"
                                        label="Last Name"
                                        rules={[{ required: true, message: "Please input your last name" }, {
                                            validator: (_, value) => validateName(value),
                                        }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="email"
                                        label="Email"
                                        validateFirst
                                        rules={[{
                                            required: true,
                                            message: "Please input the email address",
                                        }, {
                                            type: "email",
                                            message: "Please enter a valid email address",
                                        }, {
                                            validator: async (_, value) => {
                                                if (value === user.info.email) return Promise.resolve();
                                                const available = await handleEmailChange(value);

                                                if (available) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject("Email is already exists");
                                            },
                                        }]}>
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={true}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="password"
                                        label="Password"
                                        rules={[{
                                            validator: (_, value) => validatePassword(value),
                                        }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                            placeholder={isEditing ? "Enter New password to reset it" : "********"}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        style={{ marginBottom: "14px" }}
                                        name="phone"
                                        label="Phone number"
                                        rules={[{ required: true, message: "Please input your Phone number" }, {
                                            validator: (_, value) => validatePhoneNumber(value),
                                        }]}
                                    >
                                        <PhoneInputWithCountryCode
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                            label="Phone number"
                                            disableLabels={true}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="address1"
                                        label="Address Line 1"
                                        rules={[{ required: false, message: "Please input your street address" }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="address2"
                                        label="Address Line 2"
                                        rules={[{ required: false, message: "Please input your street address" }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="city"
                                        label="City"
                                        rules={[{ required: false, message: "Please input your city" }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="postcode"
                                        label="Postcode"
                                        rules={[{ required: true, message: "Please input your postcode" }, {
                                            validator: (_, value) => validatePostcode(value),
                                        }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="dateOfBirth"
                                        label="Date of Birth"
                                        rules={[{ required: true, message: "Please input your Date of Birth" }]}
                                    >
                                        <DatePicker
                                            className="payment-details__input payment-details__input-text"
                                            style={{ width: "100%" }}
                                            placeholder=""
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="nin"
                                        label="National Insurance Number"
                                        rules={[{ required: true, message: "Please input your NIN" }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing || currentDocument?.status.secondary !== SECONDARY_STATUSES_DICT["creation-error"]}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="utr"
                                        label="Unique Tax Reference Number (UTR)"
                                        rules={[{ required: true, message: "Please input your UTR" }, {
                                            validator: (_, value) => validateUTR(value),
                                        }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            : <div className="payment-details__form">
                                <div className="payment-details__form-column">
                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="firstName"
                                        label="First Name"
                                        rules={[{ required: true, message: "Please input your first name" }, {
                                            validator: (_, value) => validateName(value),
                                        }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="lastName"
                                        label="Last Name"
                                        rules={[{ required: true, message: "Please input your last name" }, {
                                            validator: (_, value) => validateName(value),
                                        }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        style={{ marginBottom: "14px" }}
                                        name="phone"
                                        label="Phone number"
                                        rules={[{ required: true, message: "Please input your Phone number" }, {
                                            validator: (_, value) => validatePhoneNumber(value),
                                        }]}
                                    >
                                        <PhoneInputWithCountryCode
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                            label="Phone number"
                                            disableLabels={true}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="email"
                                        label="Email"
                                        validateFirst
                                        rules={[{
                                            required: true,
                                            message: "Please input the email address",
                                        }, {
                                            type: "email",
                                            message: "Please enter a valid email address",
                                        }, {
                                            validator: async (_, value) => {
                                                if (value === user.info.email) return Promise.resolve();
                                                const available = await handleEmailChange(value);

                                                if (available) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject("Email is already exists");
                                            },
                                        }]}>
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={true}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="address1"
                                        label="Address Line 1"
                                        rules={[{ required: false, message: "Please input your street address" }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="city"
                                        label="City"
                                        rules={[{ required: false, message: "Please input your city" }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>

                                </div>
                                <div className="payment-details__form-column">
                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="nin"
                                        label="National Insurance Number"
                                        rules={[{ required: true, message: "Please input your NIN" }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing || currentDocument?.status.secondary !== SECONDARY_STATUSES_DICT["creation-error"]}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="utr"
                                        label="Unique Tax Reference Number (UTR)"
                                        rules={[{ required: true, message: "Please input your UTR" }, {
                                            validator: (_, value) => validateUTR(value),
                                        }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="dateOfBirth"
                                        label="Date of Birth"
                                        rules={[{ required: true, message: "Please input your Date of Birth" }]}
                                    >
                                        <DatePicker
                                            className="payment-details__input payment-details__input-text"
                                            style={{ width: "100%" }}
                                            placeholder=""
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="password"
                                        label="Password"
                                        rules={[{
                                            validator: (_, value) => validatePassword(value),
                                        }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                            placeholder={isEditing ? "Enter New password to reset it" : "********"}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="address2"
                                        label="Address Line 2"
                                        rules={[{ required: false, message: "Please input your street address" }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="payment-details__input-label no-required-mark"
                                        name="postcode"
                                        label="Postcode"
                                        rules={[{ required: true, message: "Please input your postcode" }, {
                                            validator: (_, value) => validatePostcode(value),
                                        }]}
                                    >
                                        <Input
                                            className="payment-details__input payment-details__input-text"
                                            disabled={!isEditing}
                                        />
                                    </Form.Item>

                                </div>
                            </div>
                        }
                        {isMobile && (
                            <div className="payment-details__mobile-save-button">
                                {isEditing
                                    ? <Button type="primary"
                                        htmlType="submit"
                                        disabled={hasErrors}
                                        className="payment-details__mobile-save-button-text"
                                    >
                                        Save
                                    </Button>
                                    : <div className="payment-details__mobile-edit-button-text" onClick={handleEdit}>
                                        Edit
                                    </div>
                                }
                            </div>
                        )}
                        {isEditing && !isMobile && (
                            <div style={{ width: "100%" }}>
                                <Form.Item style={{ margin: "0 auto", width: "335px" }}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="payment-details__save-button"
                                        disabled={hasErrors}
                                    >
                                        Save
                                    </Button>
                                </Form.Item>
                            </div>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    );
}
