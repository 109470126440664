import { FC, useState } from "react";

import { Row } from "antd";
import infoGrey from "assets/images/new-questionnaire/documentParse/info_grey.svg";
import wallet from "assets/images/new-questionnaire/expenses/wallet.svg";
import { formatAmountWithCommas } from "utils/new-questionnaire";

import PromptModal from "../Shared/PromptModal";

type TotalCISExpensesCardProps = {
    totalAggregatedGrossAmount: number;
    totalAggregatedCisAmount: number;
    heading: string;
    title?: string;
    subtitle?: string;
};

const TotalCISExpensesCard: FC<TotalCISExpensesCardProps> = ({ totalAggregatedGrossAmount, totalAggregatedCisAmount, heading, title, subtitle }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleIconClick = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };
    return (
        <div className="document-parse__totals-card">
            <Row className="w100" align="middle">
                <img src={wallet} alt="wallet" className="mr6" />
                <div className="document-parse__upload">
                    <div className="fs14 taLeft">{heading}</div>
                    <img src={infoGrey} alt="info" onClick={handleIconClick}/>
                </div>
                <PromptModal
                    isOpen={isModalOpen}
                    closeModal={handleModalClose}
                    data={{
                        title: "",
                        text: "<div style='font-weight: 600; color: #000; font-size: 18px; text-align: center; margin-bottom: 10px; margin-top: 10px'>Uploaded Statements Summary</div>" +
                              "<div style='text-align: left; margin-bottom: 10px; margin-top: 10px'>The total is automatically calculated based on the scanned amounts from your uploaded deduction statements. If any amount is incorrect, edit the extracted figures.\n\n</div>",
                    }}
                    handlePrev={() => {
                    }}
                />
            </Row>
            <Row>
                <div className="document-parse__array-v2-item fs16">
                    <div className="fs16">{title}</div>
                    <div className="fs20 fw600">{totalAggregatedGrossAmount ? `£${formatAmountWithCommas(totalAggregatedGrossAmount.toFixed(2))}` : "£0.00"}</div>
                </div>
                <div className="document-parse__array-v2-item fs16">
                    {subtitle}
                    <span className="fs20 fw600">{totalAggregatedCisAmount ? `£${formatAmountWithCommas(totalAggregatedCisAmount.toFixed(2))}` : "£0.00"}</span>
                </div>
            </Row>
        </div>
    );
};

export default TotalCISExpensesCard;
