import "styles/termsConditions.less";

import React from "react";

import { Col, Typography } from "antd";
import Layout from "components/basic/Layout";

import { UK_ROUTES } from "../../../constants/routes";
import Navigation from "../../basic/Navigation/Navigation";

const TermsEngagement: React.FC = () => {
    return (
        <Layout>
            <Navigation items={[{
                title: "Home",
                url: UK_ROUTES.home,
            }, {
                title: "Engagement Letter",
                url: UK_ROUTES.engagement,
            }]} />
            <Col className="terms-conditions__content">
                <Typography className="terms-conditions__title">
                    ENGAGEMENT LETTER
                </Typography>

                <Typography className="terms-conditions__text">
                    Thank you for engaging TaxZap UK Ltd as your tax agent. TaxZap will be your main point of contact and will have primary responsibility for this assignment.
                    This letter and the attached schedules of services together with our standard terms and conditions set out the basis on which we will act.
                </Typography>
                <Typography className="terms-conditions__title">
                    WHO WE ARE ACTING FOR
                </Typography>
                <Typography className="terms-conditions__text">
                    We are acting for 'you' only. Where you would like us to act for anyone else such as your spouse, a partner, a director, a partnership or a limited company we will issue a separate engagement letter to them.
                    We enclose a HMRC form 64-8 for you that will be signed with your e-signature which is in line with the Electronic Communications Act to us for submission to HMRC.
                    This authorises HMRC to communicate with us as your agent, although they consider that you should still take 'reasonable care' over your tax affairs.
                    By clicking you agree to our terms and conditions and providing us with this E-Signature you are entering into a legally binding contract with us.
                </Typography>
                <Typography className="terms-conditions__title">
                    PERIOD OF ENGAGEMENT
                </Typography>
                <Typography className="terms-conditions__text">
                    This engagement starts on the date of signing. The first period for which we will be responsible is 2023-24 tax year.
                    We will not deal with earlier years unless you specifically ask us to do so.
                </Typography>
                <Typography className="terms-conditions__title">
                    SCOPE OF SERVICES
                </Typography>
                <Typography className="terms-conditions__text">
                    The work that we will carry out within engagement is preparing your personal tax returns.
                    Details regarding the scope of work to be completed are contained in the attached schedules.
                    These state your and our responsibilities in relation to the work to be carried out.
                    If we agree to carry out additional services for you, we will provide you with a new or amended engagement letter if we feel necessary after communicating via email.
                    Only the services that are listed in the attached schedules are included within the scope of our instructions.
                    If there is additional work that you wish us to carry out which is not listed in the schedule(s),
                    please let us know and we will discuss with you whether they can be included in the scope of our work.
                </Typography>
                <Typography className="terms-conditions__title">
                    FEES
                </Typography>
                <Typography className="terms-conditions__text">
                    Cost of service is £249.00 + VAT at 20%. Your payment is due within 21 days of your tax return being filed to HMRC.
                    We will take this fee from the refund provided from us. In some cases we will ask for you to pay in advance of a submission to HMRC.
                    Any additional work that you will request for us to complete will be quoted before the work takes place.
                    Upon your approval of the quote we will invoice you for this additional work separately. Upon payment we will carry out the work.
                </Typography>
                <Typography className="terms-conditions__title">
                    LIMITATION OF LIABILITY
                </Typography>
                <Typography className="terms-conditions__text">
                    We specifically draw your attention toour standard terms and conditions that sets out the basis on which we limit our liability to you and to others.
                </Typography>
                <Typography className="terms-conditions__title">
                    YOUR AGREEMENT
                </Typography>
                <Typography className="terms-conditions__text">
                    By clicking on "I' agree to the terms of engagement or terms and conditions''' you confirm your agreement to the terms of this letter, the attached schedules of services and the standard terms and conditions.
                </Typography>
                <Typography className="terms-conditions__text">
                    The following schedules are attached to this engagement letter and should be read in conjunction with it.
                </Typography>
                <Typography className="terms-conditions__title">
                    Confirmation of Acceptance
                </Typography>
                <Typography className="terms-conditions__text">
                    I Acknowledge receipt of your letter dated upon the date you signed up.
                    The attached schedules of services and the standard terms and conditions which fully
                    record the agreement between us concerning your appointment to carry out the work described in those documents.
                </Typography>
                <Typography className="terms-conditions__title">
                    Schedule of Services
                </Typography>
                <Typography className="terms-conditions__text">
                    This schedule should be read in conjunction with the engagement letter and the standard terms and conditions.
                </Typography>

                <Typography className="terms-conditions__title">
                    PERSONAL TAX - INDIVIDUALS, SOLE TRADERS AND COUPLES
                </Typography>
                <Typography className="terms-conditions__text">
                    Recurring compliance work
                </Typography>
                <Typography className="terms-conditions__text">
                    1. We will prepare your self assessment tax returns together with any supplementary pages required from the information
                    and explanations that you provide to us. After obtaining your approval we will submit your returns to HM Revenue & Customs (HMRC).
                </Typography>
                <Typography className="terms-conditions__text">
                    2. We will prepare your business accounts in accordance with generally accepted accounting practice from the books,
                    accounting records and other information and explanations provided to us on your behalf.
                </Typography>
                <Typography className="terms-conditions__text">
                    3. We will calculate your income tax, national insurance contributions (NIC) and any capital gains tax liabilities and tell you how much you should pay and when.
                    We will tell you if there is interest, penalty and surcharge implications of tax or NIC is paid late. We will also check HMRC's calculation of your tax and NIC
                    liabilities and initiate repayment claims of tax or NIC has been overpaid.
                </Typography>
                <Typography className="terms-conditions__text">
                    4. Other than as regards tax credits we will tell you as to possible tax return related claims and elections arising from information supplied by you.
                    Where instructed by you, we will make such claims and elections in the form and manner required by HMRC.
                </Typography>
                <Typography className="terms-conditions__text">
                    5. We will review PAYE notices of coding provided to us.
                </Typography>
                <Typography className="terms-conditions__text">
                    6. Where you have instructed us to do so, we will also provide such other ad hoc taxation services as may be agreed between us from time to time;
                    these may be the subject to a separate engagement letter. Where appropriate we will discuss and agree an additional fee for such work when it is commissioned by you.
                    Examples of such work include:
                </Typography>
                <ul>
                    <li className="terms-conditions__text">Ad hoc transactions, preparing additional supplementary pages to your tax return and calculating any related liabilities.</li>
                    <li className="terms-conditions__text">Dealing with any enquiry opened into your tax return by HMRC.</li>
                    <li className="terms-conditions__text">Preparing any amended returns which may be required and corresponding with HMRC as necessary.</li>
                    <li className="terms-conditions__text">Explaining the rules relating with VAT registration.</li>
                </ul>
                <Typography className="terms-conditions__text">
                    7. Where specialist advice is required on occasions, we may need to seek this from or refer you to appropriate specialists.
                </Typography>
                <br />
                <br />
                <Typography className="terms-conditions__text">
                    Tax Credits
                </Typography>
                <Typography className="terms-conditions__text">
                    8. If we agree to tell you about tax credits we will issue a separate letter of engagement or schedule to cover this area. Tax credits are in effect a social security benefit.
                    Your entitlement or otherwise will depend not only on your own circumstances but also those of your household and we would require all relevant information to advise in this regard.
                </Typography>
                <br />
                <br />
                <Typography className="terms-conditions__text">
                    Changes in the law
                </Typography>
                <Typography className="terms-conditions__text">
                    9. We will not accept responsibility if you act on information given by us on an earlier occasion without first confirming with us that the information is still valid in the light of any change in the law or your circumstances.
                </Typography>
                <Typography className="terms-conditions__text">
                    10. We will accept no liability for losses arising from changes in the law or the interpretation thereof that are first published after the date on which the information is given.
                </Typography>
                <br />
                <br />
                <Typography className="terms-conditions__text">
                    Your responsibilities
                </Typography>
                <Typography className="terms-conditions__text">
                    11. You are legally responsible for:
                </Typography>
                <Typography className="terms-conditions__text">
                </Typography>
                <Typography className="terms-conditions__text">
                    (a) Ensuring that your self assessment tax returns are correct and complete.
                </Typography>
                <Typography className="terms-conditions__text">
                    (b) Filing any returns by the due date.
                </Typography>
                <Typography className="terms-conditions__text">
                    (c) Making payment of tax on time.
                </Typography>
                <br />
                <Typography className="terms-conditions__text">
                    Failure to do this may lead to automatic penalties, surcharges and/or interest.
                </Typography>
                <Typography className="terms-conditions__text">
                    Taxpayers who sign their returns cannot delegate this legal responsibility to others. You agree to check that returns that we have prepared for you are complete before you approve and sign them and you agree that we cannot be responsible for any mistakes incurred when filing.
                </Typography>
                <Typography className="terms-conditions__text">
                    12. To enable us to carry out our work you agree:
                </Typography>
                <Typography className="terms-conditions__text">
                    (a) That all returns are to be made on the basis of full disclosure of all sources of income, charges, allowances and capital transactions.
                </Typography>
                <Typography className="terms-conditions__text">
                    (b) To provide full information necessary for dealing with your affairs: we will rely on the information and documents being true, correct and complete and will not audit the information or those documents.
                </Typography>
                <Typography className="terms-conditions__text">
                    (c) To authorise us to approach such third parties as mavbe appropriate for information that we consider necessary to deal with your affairs, including those of your previous accountant, if applicable.
                </Typography>
                <Typography className="terms-conditions__text">
                    (d) To provide us with information in sufficient time for your return to be completed and submitted by Jan 31st 2025. In order that we can do this, we need to receive all relevant information by 1st Jan 2025.
                </Typography>
                <Typography className="terms-conditions__text">
                    (e) To provide us with the contact details of your previous accountant. We will rely on you to disclose this information in order for your accountant to ascertain professional clearance. If you are unable to provide this information within seven days of submitting your information to the accountant we will assume that you have not previously engaged with an accountant. We also will not ask for your previous accountants information always. You must email and provide us with all relevant information we need to make a filing on your behalf with HMRC. If this information is provided and we do not receive a response from the previous accountant within three days we will assume there are no matters related to professional clearance that we should take into consideration.
                </Typography>
                <br />
                <Typography className="terms-conditions__text">
                    13. You will keep us informed of material changes in your circumstances that could affect your tax liability. If you are unsure whether the change is material or not please let us know so that we can assess its significance.
                </Typography>
                <Typography className="terms-conditions__text">
                    14. You are responsible for monitoring your monthly turnover to establish whether you are liable to register for VAT. If you do not understand what you need to do, please contact HMRC. If you exceed the VAT registration threshold, and wish us to assist you in notifying HMRC of your liability to be VAT registered, please contact an accountant. In certain circumstances a fee may be payable to us if you require us to do work that was not agreed upon in relation to VAT. We will not be responsible if you fail to notify us in time and incur a late registration penalty as a result.
                </Typography>
                <Typography className="terms-conditions__text">
                    15. Our services as set out above are subject to the limitations on our liability set out in the engagement letter and in our standard terms and conditions of business.
                </Typography>
                <br />
                <br />
                <Typography className="terms-conditions__text">
                    These are important provisions that you should read and consider carefully.
                </Typography>
                <Typography className="terms-conditions__text">
                    16. Client spotting irregularities or fraud
                </Typography>
                <Typography className="terms-conditions__text">
                    You acknowledge and agree that you have a duty to monitor and detect discrepancies
                    along with any potential fraud in the services provided by TaxZap UK Ltd. You shall show reasonable care and oversight in all matters related to the services and transactions.
                    You acknowledge that the TaxZap UK Ltd will rely on you to detect discrepancies and fraud and that their ability to produce an accurate account of your tax situation is based on you actively participating in detecting any potential issues. TaxZap UK Ltd shall not be held liable for discrepancies or fraud that could have been
                    reasonably detected by you but were not reported in a timely manner, in this case within one month of the filing submission date.
                </Typography>
                <br />
                <Typography className="terms-conditions__text">
                    Recovery of unpaid fees
                </Typography>
                <Typography className="terms-conditions__text">
                    If you fail to make payment for services rendered by TaxZap UK Ltd, we may, at our discretion, initiate legal action to recover the unpaid fees. You shall be responsible for all legal costs associated with any such action.
                </Typography>
                <br />
                <br />
                <Typography className="terms-conditions__text">
                    Complaints to ACCA
                </Typography>
                <Typography className="terms-conditions__text">
                    Right to Lodge Complaint
                </Typography>
                <Typography className="terms-conditions__text">
                    In the event of a dispute or disagreement arising from services provided by TaxZap UK Ltd, you have the right to lodge a complaint with ACCA Exhaustion of Internal Remedies
                    Before initiating any complaint with ACCA, you agree to make a good-faith effort to resolve the dispute by first following any internal procedures for a resolution that may be
                    established by TaxZap. This includes reaching out to the support team and TaxZap UK Ltd who can then investigate the complaint and provide remedial actions based on the issue outlined.
                </Typography>
                <Typography className="terms-conditions__text">
                    Submission of Complaint to ACCA
                </Typography>
                <Typography className="terms-conditions__text">
                    If the dispute remains unresolved after internal procedures, you may file a complaint with the ACCA
                </Typography>
            </Col>
        </Layout>
    );
};

export default TermsEngagement;
