import image from "assets/images/checkmark.svg";

const CheckboxComponent = ({ message } : {message: string}) => {
    return (
        <div className="checkbox-outer-div">
            <img src={image} alt="checkbox logo" className="checkbox-checkmark"/>
            <span className="checkbox-text">{message}</span>
        </div>
    );
};

export default CheckboxComponent;
