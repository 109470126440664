import { api } from "./connection/api";
import {
    ICreatePotentialClientResponse,
    IUpdatePotentialClientByIdResponse,
    IUpdatePotentialClientRequestBody,
} from "../types/potential-clients";

const ENDPOINT_PREFIX = "potential-clients";

export const potentialClientsApi = api.injectEndpoints({
    endpoints: build => ({
        createPotentialClient: build.mutation<ICreatePotentialClientResponse, void>({
            query() {
                return {
                    url: `${ENDPOINT_PREFIX}`,
                    method: "POST",
                };
            },
        }),

        updatePotentialClientById: build.mutation<IUpdatePotentialClientByIdResponse, IUpdatePotentialClientRequestBody>({
            query({ potentialClientId, ...data }) {
                return {
                    url: `${ENDPOINT_PREFIX}/${potentialClientId}`,
                    method: "PUT",
                    body: data,
                };
            },
        }),

        getPotentialClientById: build.mutation({
            query({ potentialClientId, marketingSource }) {
                return {
                    url: `${ENDPOINT_PREFIX}/${potentialClientId}?${marketingSource}`,
                    method: "GET",
                };
            },
        }),
    }),
});

export const {
    useCreatePotentialClientMutation,
    useUpdatePotentialClientByIdMutation,
    useGetPotentialClientByIdMutation,
} = potentialClientsApi;
