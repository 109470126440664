import { FC } from "react";

import { Modal, Spin } from "antd";
import DOMPurify from "dompurify";

type DeleteModalProps = {
    isOpen: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    closeable?: boolean;
    data: any
    isDeleting?: boolean;
};

const DeleteModal: FC<DeleteModalProps> = ({ isOpen, onCancel, onConfirm, data, closeable, isDeleting }) => {
    const { title, text, confirmButtonText } = data;

    const formattedText = DOMPurify.sanitize(text?.replace(/\n/g, "<br />"));

    return (
        <Modal
            open={isOpen}
            onCancel={() => closeable && onCancel()}
            maskClosable={closeable !== false}
            closable={closeable !== false}
            footer={null}
            className="new-questionnaire__shared-delete-modal"
            centered
        >
            <div className="new-questionnaire__shared-delete-modal__content">
                {isDeleting && (
                    <div className="new-questionnaire__shared-delete-modal__spinner">
                        <Spin size="small" />
                    </div>
                )}{
                    <>
                        <div className="new-questionnaire__shared-delete-modal__title mt20">{title}</div>
                        <div
                            className="new-questionnaire__shared-delete-modal__text"
                            dangerouslySetInnerHTML={{ __html: formattedText }}
                        />
                        <div className="new-questionnaire__shared-delete-modal__button-container">
                            <div
                                className="new-questionnaire__desktop-form-primary-button"
                                onClick={onConfirm}
                            >
                                {confirmButtonText}
                            </div>
                            <div
                                className="new-questionnaire__desktop-form-secondary-button mt10"
                                onClick={onCancel}
                            >
                        No, go back
                            </div>
                        </div>
                    </>
                }
            </div>
        </Modal>
    );
};

export default DeleteModal;
