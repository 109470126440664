import React, { useContext, useEffect } from "react";

import { AppContext } from "App";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({
    component: Component,
    ...props
}: {
    component: React.FC<any>;
    props?: any;
}) => {
    const { isAuth } = useContext(AppContext);

    const location = useLocation();

    useEffect(() => {
        if (!isAuth) {
            sessionStorage.setItem("last-visited-page", location.pathname + location.search);
        }
    }, [isAuth, location.pathname, location.search]);

    if (isAuth) {
        return <Component {...props} />;
    }

    return <Navigate replace to="/auth" />;
};

export default PrivateRoute;
