import "styles/profile/paymentDetails.less";

import { Dispatch, SetStateAction, useContext, useEffect } from "react";

import { Typography } from "antd";
import { AppContext } from "App";
import BankDetailsForm from "components/basic/BankDetailsForm/BankDetailsForm";
import LottieAnimation from "components/basic/Spinner/Lottie";
import dayjs from "dayjs";
import { useUpdateUserPaymentDetailsMutation } from "services/auth";
import { IUser } from "types/auth";

import "dayjs/locale/en-gb";

dayjs.locale("en-gb");

type BankDetailsProps = {
    user: IUser;
    setPageLoading: Dispatch<SetStateAction<boolean>>
};

export default function BankDetails({ user, setPageLoading }: BankDetailsProps) {
    const { isMobile } = useContext(AppContext);
    const [, { isLoading: updatePaymentDetailsLoading }] = useUpdateUserPaymentDetailsMutation();

    useEffect(() => {
        const timer = setTimeout(() => {
            setPageLoading(false);
        }, 200);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="profile__content">
            {!isMobile && <div className="profile__content-header">
                <Typography className="profile__content-heading">
                    Bank Details
                </Typography>
            </div>}
            <div className="payment-details__bank-details-container" style={{ marginTop: "25px" }}>
                <LottieAnimation isLoading={updatePaymentDetailsLoading} />
                <BankDetailsForm user={user} />
            </div>
        </div>

    );
}
