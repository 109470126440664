import { PromptModalDataType } from "components/pages/NewQuestionnaire/constants/questions";

/* eslint-disable no-unused-vars */
export enum UK_SIGNUP_STEP {
    PERSONAL_INFO = "personalInfo",
    TAX_INFO = "taxInfo",
    ADDRESS = "address",
    SIGN = "sign"
}

export type InputType =
    | "textQuestion"
    | "emailQuestion"
    | "nameQuestion"
    | "phoneQuestion"
    | "dateQuestion"
    | "selectOneQuestion"
    | "selectManyQuestion"
    | "signQuestion"
    | "booleanQuestion"
    | "addressQuestion"
    | "ninQuestion"
    | "utrQuestion"
    | "toggleQuestion"
    | "otherQuestion";

export interface IInputOption {
    option: string;
}
interface IInputDepend {
    id: string;
    value: string | boolean;
}

export interface IInput {
    id: string;
    type: InputType;
    name: string;
    required: boolean;
    options?: IInputOption[];
    optionType?: "radio" | "card";
    depend: IInputDepend[];
    simplifiedInputs?: IInput[];
    inputs?: IInput[];
    promptModal?: PromptModalDataType
}

export interface IStep {
    id: UK_SIGNUP_STEP;
    headerLabel: string;
    account: string;
    infoText?: string;
    nextButtonLabel: string;
    backButtonId: UK_SIGNUP_STEP | null;
    nextButtonId: UK_SIGNUP_STEP | null;
    inputs: IInput[];
}
