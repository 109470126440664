import { api } from "./connection/api";

export const jobsApi = api.injectEndpoints({
    endpoints: builder => ({
        getJob: builder.query<any, { jobId: string }>({
            query: ({ jobId }) => ({
                url: `/jobs/${jobId}`,
                method: "GET",
            }),
        }),
    }),
});

export const { useLazyGetJobQuery } = jobsApi;
