import { ExpenseCard, GroupedCards } from "components/pages/NewQuestionnaire/components/CategoryTransactionsListView/CategoryTransactionsListView";
import { categories, Category, Depend, Question } from "components/pages/NewQuestionnaire/constants/questions";
import { QuestionType, StepType } from "components/pages/NewQuestionnaire/enums/enum";
import { format } from "date-fns";
import { featureFlags } from "redux/reducers/featureFlags";
import { AdditionalIncomeSource, EmploymentType, IAddress, TaxProfile } from "types/client";
import { ArrayAnswersType } from "types/questionnaire";

import { round2Decimals } from "./numbers";
import { store } from "../redux/store";

export const isAnswerValid = (answer?: string, regexString?: string): boolean => {
    if (!regexString || !answer) return true;
    const regex = new RegExp(regexString.slice(1, -1));
    return regex.test(answer);
};

export const validateAmount = (value: string): boolean => {
    const decimalRegEx = /^\d+(\.\d{0,2})?$/;
    return decimalRegEx.test(value);
};

const handleOppositeCondition = (
    depend: Depend,
    categoryAnswers: Record<string, any>
): boolean => {
    const answer = categoryAnswers[depend.question];
    if (Array.isArray(answer)) {
        return !answer.includes(depend.answer);
    }
    return answer && answer !== depend.answer;
};

const handleProfileCondition = (
    depend: Depend,
    currentProfile: TaxProfile) => {
    switch (depend.conditionType) {
    case "hasReviewableExpensesTransactions": {
        const hasExpenses = currentProfile?.expenses?.reviewableTransactionsTotal > 0;
        return hasExpenses === depend.conditionValue;
    }
    case "hasReviewableRevenueTransactions": {
        const hasRevenue = currentProfile?.revenue?.reviewableTransactionsTotal > 0;
        return hasRevenue === depend.conditionValue;
    }
    default:
        return true;
    }
};

const handleFeatureToggleCondition = (
    depend: Depend
) => {
    if (!depend.conditionType) return false;
    const state = store.getState();
    const flags = state.featureFlags;
    const featureFlagName = depend.conditionType;
    return flags[featureFlagName as unknown as keyof typeof flags] === depend.conditionValue;
};

export const employmentTypeMapping: Record<string, EmploymentType> = {
    "🧾 Self-Employed": "Self Employment",
    "💼 Director of a LTD company": "paye",
    "🧑‍💼 Employed (PAYE)": "paye",
    "🏠 Landlord": "Rental",
    "🦺 Construction worker (CIS)": "cis",
};

export const additionalIncomeMappingNewNavBar: Record<string, AdditionalIncomeSource> = {
    "🏠 Rental Income": "Rental",
    "🧩 Dividends": "Dividends",
    "🏦 Interest Income": "Interest",
    "🦺 Construction Industry (CIS)": "cis",
    "📈 Capital Gains/Losses": "Capital Gains/Losses",
    "👴 Pension Income": "Pension Income",
    "💸 State Benefits": "State Benefits",
    "🌎 Foreign Income": "Foreign Income",
};

export const getEmploymentTypes = (
    answers: Record<string, any>
) => {
    const employmentTypes =
        answers["Personal Info"]?.["Select your primary income sources"] as EmploymentType[] ||
        answers["Personal Info"]?.["Select all job statuses that apply to you"] as EmploymentType[] || [];

    return Array.from(new Set(
        employmentTypes
            .map(type => employmentTypeMapping[type])
            .filter(Boolean)
    ));
};

export const getAdditionalIncomeSources = (
    answers: Record<string, any>
) => {
    const additionalIncomeSources = answers["Personal Info"]?.["Select your additional income sources"] as AdditionalIncomeSource[] || [];
    return additionalIncomeSources
        .map(type => additionalIncomeMappingNewNavBar[type])
        .filter(Boolean);
};

export const getNavigationItems = (answers: Record<string, any>) => {
    const employmentTypes = getEmploymentTypes(answers);
    const additionalIncomeSources = getAdditionalIncomeSources(answers);

    const categoryNavigationMappingNewNavBar: Record<string, string> = {
        "cis": "CIS",
        "paye": "Employment",
        "Self Employment": "Self Employment",
        "Rental": "Rental",
        "Rental Income": "Rental",
        "Interest": "Interest Income",
        "Capital Gains/Losses": "Capital Gains",
        "Pension Income": "Pension Income",
        "State Benefits": "State Benefits",
        "Dividends": "Dividends",
        "Foreign Income": "Investments",
    };

    const relevantCategoryNames = Array.from(
        new Set([
            "Bank Account Connection",
            "Personal Info",
            "General",
            ...employmentTypes.map(type => categoryNavigationMappingNewNavBar[type]),
            ...additionalIncomeSources.map(source => categoryNavigationMappingNewNavBar[source]),
            "Review & Submit",
        ])
    );

    return relevantCategoryNames;
};

const handleAnswerMatchRequired = (
    depend: Depend,
    answers: Record<string, any> | undefined
): boolean => {
    if (!answers) return false;

    // look in the entire answers object for the question which will be a key  in the key value pair
    const answer = answers.find((obj: any) => obj.question === depend.question)?.answer;

    if (Array.isArray(answer)) {
        return answer.includes(depend.answer);
    }

    if (typeof answer === "object" && typeof depend.answer === "object") {
        return Object.entries(depend.answer).every(
            ([key, value]) => answer?.[key] === value
        );
    }

    return answer === depend.answer;
};

export const checkDependsForRequiredQuestions = (
    depends: Depend[],
    categoryAnswers: any[]
): boolean => {
    const orDepends = depends.filter(depend => depend.orDepend);
    const andDepends = depends.filter(depend => !depend.orDepend);

    const isDependMatch = (depend: Depend): boolean => {
        if (depend.opposite && categoryAnswers) {
            return handleOppositeCondition(depend, categoryAnswers);
        }

        return handleAnswerMatchRequired(depend, categoryAnswers);
    };

    const andDependsMatch = andDepends?.every(isDependMatch);

    if (andDependsMatch) return true; // If all AND depends match, return true

    // Check all OR depends only if AND conditions do not match
    if (orDepends.length) {
        return orDepends.every(isDependMatch);
    }

    return false;
};

export const flattenCategoryAnswers = (categoryAnswers: Record<string, any>): any[] => {
    const flattened: any[] = [];

    // Traverse each category
    for (const category in categoryAnswers) {
        const categoryData = categoryAnswers[category];

        if (typeof categoryData === "object") {
            for (const key in categoryData) {
                const item = categoryData[key];

                if (Array.isArray(item)) {
                    // Check if this is a top-level array answer (like in 'Personal Info')
                    if (item.every((subItem: any) => typeof subItem === "string")) {
                        flattened.push({
                            question: key,
                            answer: item,
                            id: null,
                        });
                    } else {
                        // Handle arrays like 'Add a Self Employment' or 'Add an Employment'
                        item.forEach((subItem: any) => {
                            if (subItem.answers && Array.isArray(subItem.answers)) {
                                subItem.answers.forEach((answer: any) => {
                                    flattened.push({
                                        question: answer.question,
                                        answer: answer.answer,
                                        id: answer.id || null,
                                    });
                                });
                            }
                        });
                    }
                } else if (typeof item === "object" && item !== null) {
                    // If the item is an object, iterate over its keys
                    for (const subKey in item) {
                        flattened.push({
                            question: subKey,
                            answer: item[subKey],
                            id: null,
                        });
                    }
                } else {
                    // Handle top-level key-value pairs (like in 'Personal Info')
                    flattened.push({
                        question: key,
                        answer: item,
                        id: null,
                    });
                }
            }
        }
    }

    return flattened;
};

export const findRequiredQuestions = (
    categories: Category[],
    flattenedAnswers: any[]
): {question: string, type: string, id: string}[] => {
    // Helper function to recursively extract required questions
    const extractQuestions = (questions: Question[]): {id: string, type: string, question: string}[] => {
        return questions.flatMap(question => {
            const isRequired =
                question.answerRequired && question.type !== "questionsList" &&
            (!question.depend || checkDependsForRequiredQuestions(question.depend, flattenedAnswers));

            if (isRequired) {
                // Include the current question and recursively process nested questions
                return question.questions
                    ? [{ id: question.id,
                        type: question.type,
                        question: question.question,
                    }, ...extractQuestions(question.questions)]
                    : [{ id: question.id,
                        type: question.type,
                        question: question.question,
                    }];
            }

            // If not required, only process nested questions
            return question.questions ? extractQuestions(question.questions) : [];
        });
    };

    const flattenedQuestions = categories.flatMap(category =>
        category.steps.flatMap(step => {
            const questionsFromSteps = step.questions ? extractQuestions(step.questions) : [];
            return [...questionsFromSteps];
        })
    );
    return flattenedQuestions;
};

const handleAnswersCondition = (
    depend: Depend,
    categoryAnswers: Record<string, any> | undefined
): boolean => {
    switch (depend.conditionType) {
    case "hasUnansweredRequiredQuestions": {
        if (!categoryAnswers) return false;

        const flattenedAnswers = flattenCategoryAnswers(categoryAnswers);
        const navigationItems = getNavigationItems(categoryAnswers);
        // We include this to ensure that if a user changes the navigation tabs but previously answered questions in the old tabs, we exclude them
        // This also excludes any profile sections that were added in the navigation but no profiles created for them
        const answeredAndInNavigationTabs = Object.keys(categoryAnswers)
            .filter(
                category =>
                    navigationItems.includes(category) &&
                categoryAnswers[category] &&
                    Object.keys(categoryAnswers[category]).length > 0
            );

        const categoriesSet = Array.from(
            new Set(["Personal Info", ...answeredAndInNavigationTabs, "General"])
        );

        const filteredCategories = categories.filter(category => categoriesSet.includes(category.title));

        const requiredQuestions = findRequiredQuestions(filteredCategories, flattenedAnswers);

        return requiredQuestions.some(question => {
            // Check if the question exists in the flattened answers by matching 'id' or 'question'
            const foundAnswer = flattenedAnswers.find(answer =>
                answer.id === question.id || answer.question === question.question
            );
            if (!foundAnswer || foundAnswer.answer === "") {
                console.log(question, "MISSING QUESTION");
            }
            // If the question is not found or its answer is empty, return true (missing)
            return !foundAnswer || foundAnswer.answer === "";
        });
    }
    default:
        return true;
    }
};

const handleAnswerMatch = (
    depend: Depend,
    categoryAnswers: Record<string, any> | undefined
): boolean => {
    if (!categoryAnswers) return false;

    const answer = categoryAnswers[depend.question];

    if (Array.isArray(answer)) {
        return answer.includes(depend.answer);
    }

    if (typeof answer === "object" && typeof depend.answer === "object") {
        return Object.entries(depend.answer).every(
            ([key, value]) => answer?.[key] === value
        );
    }

    return answer === depend.answer;
};

export const checkDepends = (
    depends: Depend[],
    categoryAnswers: Record<string, any> | undefined
): boolean => {
    const orDepends = depends.filter(depend => depend.orDepend);
    const andDepends = depends.filter(depend => !depend.orDepend);
    const currentProfile = store.getState().taxProfile.currentProfile;

    const isDependMatch = (depend: Depend): boolean => {
        if (depend.conditionType && featureFlags.includes(depend.conditionType)) {
            return handleFeatureToggleCondition(depend);
        }

        if (depend.conditionType && (depend.conditionType === "hasReviewableRevenueTransactions" || depend.conditionType === "hasReviewableExpensesTransactions") && depend.conditionValue !== undefined && currentProfile) {
            return handleProfileCondition(depend, currentProfile);
        }

        if (depend.conditionType && depend.conditionType === "hasUnansweredRequiredQuestions" && depend.conditionValue !== undefined && categoryAnswers) {
            return handleAnswersCondition(depend, categoryAnswers);
        }

        if (depend.opposite && categoryAnswers) {
            return handleOppositeCondition(depend, categoryAnswers);
        }

        return handleAnswerMatch(depend, categoryAnswers);
    };

    const andDependsMatch = andDepends?.every(isDependMatch);

    if (!andDepends.length && orDepends.length) return orDepends.some(isDependMatch);

    if (andDependsMatch) return true; // If all AND depends match, return true

    // Check all OR depends only if AND conditions do not match
    if (orDepends.length) {
        return orDepends.some(isDependMatch);
    }

    return false;
};

export const calculateTotalProgress = (categories: Category[], categoryIndex: number, stepIndex: number) => {
    let totalSteps = 0;
    let completedSteps = 0;
    categories.forEach((category, index) => {
        totalSteps += category.steps.length;
        if (index < categoryIndex) {
            completedSteps += category.steps.length;
        }
    });
    completedSteps += stepIndex;
    return (completedSteps / (totalSteps - 1)) * 100;
};

export type QuestionWithAnswer = {
    id: string,
    type: string;
    question: string;
    answer: any
};

export type TabAnswersOB = {
    tabLabel: string;
    questions: QuestionWithAnswer[];
}

export const createAnswersOBArray = (
    answers: Record<string, any>,
    categories: Category[]
): TabAnswersOB[] => {
    const categoriesToSave = categories.filter((category: any) => category.showAnswersInAdmin);

    return categoriesToSave.map(category => {
        const questions: QuestionWithAnswer[] = [];

        category.steps
            .filter(step => step.type === StepType.questionnaire || step.type === StepType.review)
            .forEach(step => {
                if (step.cards && step.cards.length) {
                    step.cards.forEach(card => {
                        if (card.question && answers[category.title]?.[card.question]) {
                            questions.push({
                                id: card.id,
                                type: "card",
                                question: card.question,
                                answer: answers[category.title][card.question],
                            });
                        }
                    });
                }

                if (step.questions && step.questions.length) {
                    step.questions.forEach(question => {
                        if (question.type === QuestionType.questionsList) {
                            question.questions?.forEach(currentQuestion => {
                                if (answers[category.title]?.[question.question]) {
                                    questions.push({
                                        id: currentQuestion.id,
                                        type: currentQuestion.type,
                                        question: currentQuestion.question,
                                        answer: answers[category.title][question.question],
                                    });
                                }
                            });
                        }
                        if (answers[category.title]?.[question.question]) {
                            questions.push({
                                id: question.id,
                                type: question.type,
                                question: question.question,
                                answer: answers[category.title][question.question],
                            });
                        }
                    });
                }
            });

        return {
            tabLabel: category.title,
            questions,
        };
    });
};

export const findAllOBFiles = (
    answers: Record<string, any>,
    categories: Category[]
): any[] => {
    const categoriesFiles: any[] = [];

    const categoriesToSave = categories.filter((category: any) => category.showAnswersInAdmin);

    categoriesToSave.forEach(category => {
        category.steps
            .filter(step => step.type === "questionnaire")
            .forEach(step => {
                if (!step.questions || !step.questions.length) return;

                step.questions.forEach(question => {
                    if (["profileQuestion", "arrayQuestion"].includes(question.type) && question.questions) {
                        question.questions.forEach(arrayQuestion => {
                            const questionAnswers = answers[category.title]?.[question.question];
                            if (questionAnswers && arrayQuestion.type === "uploadFileQuestion") {
                                const answersWithFiles = questionAnswers.map((arrayAnswer: any) => {
                                    const itemAnswersWithFiles = arrayAnswer.answers?.filter(
                                        (itemAnswer: any) => itemAnswer.type === "uploadFileQuestion"
                                    );

                                    return itemAnswersWithFiles?.map((fileAnswer: any) => ({
                                        ...fileAnswer.answer,
                                        category: `${fileAnswer?.answer?.category} - ${arrayAnswer.item}`,
                                    })) || [];
                                });

                                categoriesFiles.push(...answersWithFiles.flat());
                            }
                        });
                    }

                    if (question.type === "uploadFileQuestion" && answers[category.title]?.[question.question]) {
                        categoriesFiles.push(answers[category.title][question.question]);
                    }
                });
            });
    });

    return categoriesFiles;
};

export const inferIfCompanyDirector = (answers: Record<string, any>) =>
    answers["Personal Info"]?.["Select your primary income sources"]?.includes("💼 Director of a LTD company") ||
    answers["Personal Info"]?.["Select all job statuses that apply to you"]?.includes("💼 Director of a LTD company") || false;

export const getOrdinalSuffix = (num: number) => {
    if (!num) num = 1;

    const mod10 = num % 10;
    const mod100 = num % 100;

    if (mod10 === 1 && mod100 !== 11) {
        return `${num}st`;
    } else if (mod10 === 2 && mod100 !== 12) {
        return `${num}nd`;
    } else if (mod10 === 3 && mod100 !== 13) {
        return `${num}rd`;
    } else {
        return `${num}th`;
    }
};

export const groupCardsByMonth = (cards: ExpenseCard[]) => {
    return cards.reduce((acc: GroupedCards, card) => {
        const cardDate = new Date(card.earliestDate);
        const monthYear: string = format(cardDate, "MMMM yyyy").toUpperCase();

        if (!acc[monthYear]) {
            acc[monthYear] = [];
        }

        acc[monthYear].push(card);

        return acc;
    }, {} as GroupedCards);
};

export const parseAmount = (value: string): number => {
    const sanitizedValue = value.replace(/,/g, "");
    return parseFloat(sanitizedValue) || 0;
};

type amountQuestion = {
    amountCategory?: string;
    question: string;
    answer: string;
    type: string;
    percentageAmountModificatorFor?: string;
};

type Totals = Record<string, number>;

export const calculateTotalAmounts = (questions: amountQuestion[]): Totals => {
    const questionAmounts: { [key: string]: number[] } = {};
    const percentageModifiers: { [key: string]: number } = {};

    questions.forEach(question => {
        const { amountCategory, answer, type, percentageAmountModificatorFor } = question;

        const parsedAmount = parseAmount(answer || "0");

        if (type === QuestionType.amountQuestion && amountCategory) {
            if (!questionAmounts[amountCategory]) {
                questionAmounts[amountCategory] = [];
            }

            questionAmounts[amountCategory].push(parsedAmount);
        }

        if (percentageAmountModificatorFor) {
            const percentage = parseAmount(answer || "0") / 100;
            percentageModifiers[percentageAmountModificatorFor] = percentage;
        }
    });

    questions.forEach(question => {
        const { question: questionText, amountCategory } = question;

        if (amountCategory && percentageModifiers[questionText]) {
            const amountsArray = questionAmounts[amountCategory];

            if (amountsArray && amountsArray.length > 0) {
                const lastIndex = amountsArray.length - 1;
                amountsArray[lastIndex] *= percentageModifiers[questionText];
            }
        }
    });

    const totals: Totals = {};
    Object.keys(questionAmounts).forEach(category => {
        totals[category] = round2Decimals(
            questionAmounts[category].reduce((sum, value) => sum + value, 0)
        );
    });

    return totals;
};

export const formatRawAddress = (userAddress: IAddress): string => {
    const { address1, address2, city, postcode, country } = userAddress;

    const sanitize = (value: string | undefined): string | undefined => {
        // Trim whitespace and remove trailing commas
        return value?.trim().replace(/,+$/, "");
    };

    const rawAddress = [
        sanitize(address1),
        sanitize(address2),
        sanitize(city),
        sanitize(postcode),
        sanitize(country),
    ]
        // Filter out falsy values and those that are entirely whitespace
        .filter(value => value && value.trim())
        .join(", ");

    return rawAddress;
};

export const formatCategoryWithEmoji = (category: string, emoji: string) => {
    const MAX_LENGTH = 10;
    if (category.length <= MAX_LENGTH + 3) {
        return `${category} ${emoji}`;
    }
    const truncatedCategory = `${category.slice(0, MAX_LENGTH)}...`;
    return `${truncatedCategory} ${emoji}`;
};

export const getNameFromQuestionAnswer = (
    answers: ArrayAnswersType["answers"],
    questions: Question[] | undefined,
    index: number,
    defaultName?: string
): string => {
    const itemNameQuestion = questions?.find(q => q.isItemName)?.question;
    const itemNameAnswer =
        itemNameQuestion &&
        answers.find(ans => ans.question === itemNameQuestion)?.answer;
    return itemNameAnswer || `${defaultName || ""} ${index + 1}`;
};

export const formatAnswers = (answers: { question: string, answer: any }[]) => {
    return answers.reduce((acc, curr) => {
        acc[curr.question] = curr.answer;
        return acc;
    }, {} as Record<string, any>);
};

export const getAnswerForItem = (
    item: string,
    data: Question,
    arrayAnswers: ArrayAnswersType["answers"]
): string => {
    const baseQuestion = data.questions?.find(q => q.baseForExpensesList);
    if (!baseQuestion) return "";

    const baseQuestionAnswers = arrayAnswers.find(q => q.question === baseQuestion.question)?.answer || [];
    if (!baseQuestionAnswers.includes(item)) return "";

    const subQuestion = data.questions
        ?.filter(q => q.type === "questionsList")
        .flatMap(q => q.questions || [])
        .find(sq => sq.depend?.some(dep => dep.answer === item && dep.question === baseQuestion.question));

    return subQuestion
        ? arrayAnswers.find(answer => answer.question === subQuestion.question)?.answer || ""
        : "";
};

export const formatAmountWithCommas = (value: string) => {
    const parts = value.split(".");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const decimalPart = parts[1] ? `.${parts[1]}` : "";
    return integerPart + decimalPart;
};
