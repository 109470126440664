import React, { useContext } from "react";

import { Typography, Row, message, Divider } from "antd";
import { AppContext } from "App";
import downloadIcon from "assets/images/profile/download.svg";
import pdfIcon from "assets/images/profile/pdf.svg";
import { fetchFile } from "services/files";
import { IUser } from "types/auth";
import { extractDateFromURL } from "utils/common";

type DocumentProps = {
    document: any;
    user: IUser;
};

const Document: React.FC<DocumentProps> = ({ document, user }) => {
    const { isMobile } = useContext(AppContext);

    const downloadPdf = async (url: string) => {
        let downloadedFilePdf: string = "";
        const fetchedFileBlob = await fetchFile(url);

        if (fetchedFileBlob) {
            const objectURL = URL.createObjectURL(fetchedFileBlob);
            downloadedFilePdf = objectURL;
        } else {
            message.error("Unable to load file");
        }

        if (downloadedFilePdf) {
            const link = window.document.createElement("a");
            link.href = downloadedFilePdf;
            const fileName = url?.split("/").pop() || "document.pdf";
            link.download = fileName;
            window.document.body.appendChild(link);
            link.click();
            window.document.body.removeChild(link);
        }
    };

    return (
        <div className="history__item">
            <Row className="history__item-row">
                <div>
                    <Typography className="history__content-item-title">
                        Client Agreement
                    </Typography>
                    {!isMobile && <Typography className="history__content-item-text">
                        Please find the client agreement with TaxZap
                    </Typography>}
                    {user.agreementUrl && <Typography className="history__content-item-date">
                        {extractDateFromURL(user.agreementUrl)}
                    </Typography>}
                </div>
                {user.agreementUrl && <div className="history__download-wrapper">
                    <img
                        width={48}
                        src={pdfIcon}
                        alt="Pdf Icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => (downloadPdf(user.agreementUrl))}
                    />
                    <Row className="history__download-row" onClick={() => downloadPdf(user.agreementUrl)}>
                        <img
                            width={24}
                            src={downloadIcon}
                            alt="Download Icon"
                        />
                        <Typography className="history__download-text">
                            Download
                        </Typography>
                    </Row>
                </div>}
            </Row>
            <Divider />
            <Row className="history__item-row">
                <div>
                    <Typography className="history__content-item-title">
                        Refund Invoice
                    </Typography>
                    {!isMobile && <Typography className="history__content-item-text">
                        {`Your invoice for ${document.year} refund`}
                    </Typography>}
                    {document.invoiceUrl && <Typography className="history__content-item-date">
                        {extractDateFromURL(document.invoiceUrl)}
                    </Typography>}
                </div>
                {document.invoiceUrl && <div className="history__download-wrapper">
                    <img
                        width={48}
                        src={pdfIcon}
                        alt="Pdf Icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => downloadPdf(document.invoiceUrl)}
                    />
                    <Row className="history__download-row" onClick={() => downloadPdf(document.invoiceUrl)}>
                        <img
                            width={24}
                            src={downloadIcon}
                            alt="Download Icon"
                        />
                        <Typography className="history__download-text">
                            Download
                        </Typography>
                    </Row>
                </div>}
            </Row>
        </div>
    );
};

export default Document;
