import React, { useCallback, useEffect, useState } from "react";

interface InlineEditableAmountInputProps {
  categoryName: string;
  categoryEmoji?: string;
  amount: string;
  canShowModal?: boolean;
  onAmountChange: (_e: React.ChangeEvent<HTMLInputElement>, _categoryName: string) => void;
  onEditToggle: (_categoryName: string, _isEditing: boolean) => void;
  onModalOpen?: (_categoryName: string) => void;
  editIcon: string; // Path to the edit pencil icon
  style?: React.CSSProperties;
}

const InlineEditableAmountInput: React.FC<InlineEditableAmountInputProps> = ({
    categoryName,
    categoryEmoji,
    amount,
    canShowModal,
    onAmountChange,
    onEditToggle,
    onModalOpen,
    editIcon,
    style,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(amount);
    const [originalValue, setOriginalValue] = useState(amount);

    const handleBlur = useCallback(() => {
        setIsEditing(false);
        onEditToggle(categoryName, false);
        // Revert to the original value if the input is empty
        if (!currentValue.trim()) {
            setCurrentValue(originalValue);
        }
    }, [categoryName, currentValue, originalValue, onEditToggle]);

    const handleEditClick = () => {
        setIsEditing(true);
        setOriginalValue(currentValue); // Backup the original value
        setCurrentValue(""); // Clear the input field
        onEditToggle(categoryName, true);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(e.target.value);
        onAmountChange(e, categoryName);
    };

    const handleKeyDown = useCallback(
        (e: any) => {
            if (e.key === "Enter") {
                handleBlur();
            }
        },
        [handleBlur]);

    useEffect(() => {
        setCurrentValue(amount);
        setOriginalValue(amount);
    }, [amount]);

    return (
        <div className="new-questionnaire__amount-card-wrapper" style={style}>
            <div
                className="new-questionnaire__income-card-total-text"
                style={{ fontSize: "14px", ...(canShowModal ? { cursor: "help" } : {}) }}
                onClick={() => onModalOpen?.(categoryName)} // Optional modal open handler
            >
                {categoryEmoji ? `${categoryEmoji} ${categoryName}` : categoryName}
            </div>
            {isEditing ? (
                <div className="new-questionnaire__amount-question-input-wrapper">
                    <input
                        className="new-questionnaire__edit-expense-input mr5"
                        onChange={handleChange}
                        type="text"
                        inputMode="decimal"
                        pattern="[0-9]*"
                        autoFocus={true}
                        value={currentValue}
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                    />
                </div>
            ) : (
                <div className="horizontal-right" onClick={handleEditClick}>
                    <div className="horizontal-center gap6">
                        <img src={editIcon} alt="edit" />
                        <div className="new-questionnaire__income-card-total-amount">
              £{new Intl.NumberFormat("en-GB", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(parseFloat(currentValue))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InlineEditableAmountInput;
