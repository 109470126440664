import React, { useCallback, useEffect, useMemo, useState } from "react";

import backChevron from "assets/images/new-questionnaire/back-chevron.svg";
import QuestionnaireButtonFooterV2 from "components/basic/Footer/QuestionnaireButtonFooterV2";
import ButtonLoading from "components/basic/Spinner/ButtonLoading";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { useAppSelector } from "redux/hooks";
import { selectCurrentNavigation } from "redux/reducers/navigation";
import { IUser } from "types/auth";
import { AccountDetails } from "types/bankAccounts";
import { checkDepends } from "utils/new-questionnaire";

import { Depend, Step } from "../../constants/questions";
import { PromptModalType, QuestionType } from "../../enums/enum";
import AmountQuestion from "../AmountQuestion/AmountQuestion";
import ArrayQuestionV3 from "../ArrayQuestionV3/ArrayQuestionV3";
import DateQuestion from "../DateQuestion/DateQuestion";
import NinUtrQuestion from "../NinUtrQuestion/NinUtrQuestion";
import ObjectQuestion from "../ObjectQuestion/ObjectQuestion";
import PercentageQuestion from "../PercentageQuestion/PercentageQuestion";
import ProfileQuestion from "../ProfileQuestion/ProfileQuestion";
import QuestionnaireStepQuestionsList from "../QuestionnaireStepQuestionsList/QuestionnaireStepQuestionsList";
import PrimaryIncomeQuestion from "../SelectManyOptionIncomeQuestion/PrimaryIncomeQuestion";
import SelectManyOptionQuestion from "../SelectManyOptionQuestion/SelectManyOptionQuestion";
import SelectOneIconQuestion from "../SelectOneIconQuestion/SelectOneIconQuestion";
import SelectOneOptionQuestion from "../SelectOneOptionQuestion/SelectOneOptionQuestion";
import SelectOneTilesQuestion from "../SelectOneTilesQuestion/SelectOneTilesQuestion";
import PromptModal from "../Shared/PromptModal";
import SwipeableCards from "../SwipeableCards/SwipeableCards";
import TextQuestion from "../TextQuestion/TextQuestion";
import UploadFileQuestion from "../UploadFileQuestion/UploadFileQuestion";

type QuestionnaireStepProps = {
    currentStepData: Step;
    handlePrev: () => void;
    categoryAnswers: Record<string, any>;
    handleAnswerChange: (_question: string, _answer: any) => void;
    allQuestionsAnswered: boolean;
    handleStepContinue: (_questionType?: QuestionType) => void;
    allProfilesCompleted: boolean;
    user: IUser;
    refetchTaxProfiles?: () => void;
    connectedBankAccounts: AccountDetails[];
    refetchConnectedBankAccounts?: () => void;
};

const QuestionnaireStepDesktop: React.FC<QuestionnaireStepProps> = ({
    currentStepData,
    handlePrev,
    handleAnswerChange,
    categoryAnswers = {},
    allQuestionsAnswered,
    handleStepContinue,
    allProfilesCompleted,
    user,
    refetchTaxProfiles,
    connectedBankAccounts,
    refetchConnectedBankAccounts,
}) => {
    const { stepLoading } = useAppSelector(selectCurrentNavigation);

    const [hideContinue, setHideContinue] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(false);
    const [continueButtonText, setContinueButtonText] = useState("Continue");
    const [hideTitle, setHideTitle] = useState(false);
    const [showModalOpener, setShowModalOpener] = useState(false);
    const [modalHasBeenOpened, setModalHasBeenOpened] = useState(false);
    const [remount, setRemount] = useState(false);

    const promptModalData = useMemo(() => ({
        type: currentStepData.promptModal?.type || PromptModalType.info,
        title: currentStepData.promptModal?.title || "",
        text: currentStepData.promptModal?.text || "",
        image: currentStepData.promptModal?.image || "",
    }), [currentStepData?.promptModal]);

    const {
        isOpened: isStepHelpModalOpened,
        open: handleStepHelpOpenModal,
        close: handleStepHelpCloseModal,
    } = useModalOpener();

    const checkModalDepends = useCallback((depend: Depend[], categoryAnswers?: Record<string, any>) => {
        if (categoryAnswers) {
            return checkDepends(depend, categoryAnswers);
        } else {
            return false;
        }
    }, []);

    useEffect(() => {
        if (currentStepData?.promptModal?.depend && categoryAnswers) {
            const shouldModalShow = checkModalDepends(currentStepData.promptModal?.depend, categoryAnswers);
            setShowModalOpener(shouldModalShow);
            if (shouldModalShow && !currentStepData.promptModal?.manualOpen && !modalHasBeenOpened) {
                handleStepHelpOpenModal();
                setModalHasBeenOpened(true);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryAnswers]);

    useEffect(() => {
        setRemount(true);
        const timer = setTimeout(() => {
            setRemount(false);
        }, 1);
        return () => clearTimeout(timer);
    }, [currentStepData]);

    return (
        <>
            <div className="new-questionnaire__desktop-form-left">
                {currentStepData?.hasBack && <div className="new-questionnaire__desktop-form-back" onClick={() => handlePrev()}>
                    <img src={backChevron} alt="back" />
                    <div className="new-questionnaire__desktop-form-back-text">Back</div>
                </div>}
            </div>
            <div className="mt30">
                {!remount && currentStepData?.hasCards && (
                    <SwipeableCards
                        categoryAnswers={categoryAnswers}
                        cards={currentStepData.cards || []}
                        title={currentStepData.title}
                        description={currentStepData.description}
                        onAnswerChange={handleAnswerChange}
                        showModalOpener={showModalOpener}
                        handleStepHelpOpenModal={handleStepHelpOpenModal}
                    />
                )}
                {currentStepData?.questions && (
                    <div style={{ width: "100%" }}>
                        {!hideTitle && (
                            <>
                                <div className="new-questionnaire__form-title">{currentStepData.title}</div>
                                <div className="new-questionnaire__form-description mWidth500">{currentStepData.description}</div>
                            </>
                        )}
                        {currentStepData.questions.map((question, idx) => {
                            if (question.depend?.length && !checkDepends(question.depend, categoryAnswers)) {
                                return null;
                            }
                            return (
                                <div key={idx}>
                                    {question.type === QuestionType.amountQuestion && (
                                        <AmountQuestion
                                            data={question}
                                            onAnswer={handleAnswerChange}
                                            setContinueButtonText={setContinueButtonText}
                                            handlePrev={handlePrev}
                                            initialValue={categoryAnswers[question.question]}
                                            categoryAnswers={categoryAnswers}
                                        />
                                    )}
                                    {question.type === QuestionType.percentageQuestion && (
                                        <PercentageQuestion
                                            data={question}
                                            onAnswer={handleAnswerChange}
                                            initialValue={categoryAnswers[question.question]}
                                        />
                                    )}
                                    {question.type === QuestionType.textQuestion && (
                                        <TextQuestion
                                            data={question}
                                            onAnswer={handleAnswerChange}
                                            initialValue={categoryAnswers[question.question]}
                                        />
                                    )}
                                    {question.type === QuestionType.ninUtrQuestion && (
                                        <NinUtrQuestion
                                            data={question}
                                            onAnswer={handleAnswerChange}
                                            categoryAnswers={categoryAnswers}
                                        />
                                    )}
                                    {question.type === QuestionType.dateQuestion && (
                                        <DateQuestion
                                            data={question}
                                            onAnswer={handleAnswerChange}
                                            initialValue={categoryAnswers[question.question]}
                                        />
                                    )}
                                    {question.type === QuestionType.selectOneIconQuestion && (
                                        <SelectOneIconQuestion
                                            data={question}
                                            onAnswer={handleAnswerChange}
                                            handlePrev={handlePrev}
                                            setHideContinue={setHideContinue}
                                            handleContinue={handleStepContinue}
                                            initialValue={categoryAnswers[question.question]}
                                            categoryAnswers={categoryAnswers}
                                        />
                                    )}
                                    {question.type === QuestionType.selectOneOptionQuestion && (
                                        <SelectOneOptionQuestion
                                            user={user}
                                            data={question}
                                            onAnswer={handleAnswerChange}
                                            initialValue={categoryAnswers[question.question]}
                                            handleContinue={handleStepContinue}
                                        />
                                    )}
                                    {question.type === QuestionType.selectManyQuestion && (
                                        <SelectManyOptionQuestion
                                            data={question}
                                            onAnswer={handleAnswerChange}
                                            initialValue={categoryAnswers[question.question]}
                                            categoryAnswers={categoryAnswers}
                                            handlePrev={handlePrev}
                                        />
                                    )}
                                    {question.type === QuestionType.selectOneTilesQuestion && (
                                        <SelectOneTilesQuestion
                                            data={question}
                                            onAnswer={handleAnswerChange}
                                            initialValue={categoryAnswers[question.question]}
                                            categoryAnswers={categoryAnswers}
                                            handlePrev={handlePrev}
                                        />
                                    )}
                                    {question.type === QuestionType.primaryIncomeQuestion && (
                                        <PrimaryIncomeQuestion
                                            data={question}
                                            onAnswer={handleAnswerChange}
                                            initialValue={categoryAnswers[question.question]}
                                            categoryAnswers={categoryAnswers}
                                            handlePrev={handlePrev}
                                            setContinueButtonText={setContinueButtonText}
                                        />
                                    )}
                                    {question.type === QuestionType.profileQuestion && (
                                        <ProfileQuestion
                                            data={question}
                                            onAnswer={handleAnswerChange}
                                            initialValue={categoryAnswers[question.question]}
                                            setHideContinue={setHideContinue}
                                            setHideTitle={setHideTitle}
                                            user={user}
                                            refetchTaxProfiles={refetchTaxProfiles}
                                            currentStepData={currentStepData}
                                            connectedBankAccounts={connectedBankAccounts}
                                            refetchConnectedBankAccounts={refetchConnectedBankAccounts}
                                        />
                                    )}
                                    {question.type === QuestionType.arrayQuestionV3 && (
                                        <ArrayQuestionV3
                                            data={question}
                                            onAnswer={handleAnswerChange}
                                            initialValue={categoryAnswers[question.question]}
                                            setHideContinue={setHideContinue}
                                            setHideTitle={setHideTitle}
                                            user={user}
                                            setContinueDisabled={setContinueDisabled}
                                        />
                                    )}
                                    {question.type === QuestionType.uploadFileQuestion && (
                                        <UploadFileQuestion
                                            data={question}
                                            onAnswer={handleAnswerChange}
                                            initialValue={categoryAnswers[question.question]}
                                            user={user}
                                            refetchTaxProfiles={refetchTaxProfiles}
                                            currentStepData={currentStepData}
                                            categoryAnswers={categoryAnswers}
                                            handlePrev={handlePrev}
                                        />
                                    )}
                                    {question.type === QuestionType.objectQuestion && (
                                        <ObjectQuestion
                                            data={question}
                                            onAnswer={handleAnswerChange}
                                            handlePrev={handlePrev}
                                            initialValue={categoryAnswers[question.question]}
                                            categoryAnswers={categoryAnswers}
                                        />
                                    )}
                                    {question.type === QuestionType.questionsList && (
                                        <QuestionnaireStepQuestionsList
                                            data={question}
                                            onAnswer={handleAnswerChange}
                                            answers={categoryAnswers}
                                        />
                                    )}
                                </div>
                            );
                        })}
                        {!hideContinue && (
                            <div className="mt120">
                                {currentStepData.parentProfileType
                                    ? <QuestionnaireButtonFooterV2>
                                        <div
                                            className={`new-questionnaire__form-continue ${(currentStepData.allowContinue || allProfilesCompleted) && !stepLoading && !continueDisabled ? "" : "disabled"}`}
                                            onClick={() => {
                                                if ((currentStepData.allowContinue || allProfilesCompleted) && !stepLoading && !continueDisabled) {
                                                    handleStepContinue();
                                                }
                                            }}
                                            role="button"
                                        >
                                            {stepLoading ? <ButtonLoading/>
                                                : <span>{"Continue"}</span>}
                                        </div>

                                    </QuestionnaireButtonFooterV2>
                                    : <QuestionnaireButtonFooterV2>
                                        <div
                                            className={`new-questionnaire__form-continue ${(currentStepData.allowContinue || allQuestionsAnswered) && !stepLoading && !continueDisabled ? "" : "disabled"}`}
                                            onClick={() => {
                                                if ((currentStepData.allowContinue || allQuestionsAnswered) && !stepLoading && !continueDisabled) {
                                                    handleStepContinue();
                                                    setContinueButtonText("Continue");
                                                }
                                            }}
                                            role="button"
                                        >
                                            {stepLoading ? <ButtonLoading/>
                                                : <span>{continueButtonText}</span>}
                                        </div>
                                    </QuestionnaireButtonFooterV2>
                                }
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="new-questionnaire__desktop-form-right">
            </div>
            {isStepHelpModalOpened && (
                <PromptModal
                    isOpen={isStepHelpModalOpened}
                    closeModal={handleStepHelpCloseModal}
                    handlePrev={handlePrev}
                    data={promptModalData}
                />
            )}
        </>
    );
};

export default QuestionnaireStepDesktop;
