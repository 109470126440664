import React from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const Spinner = ({
    children,
    isLoading,
    marginTop = 0,
}: {
    children?: React.ReactNode,
    isLoading: boolean,
    marginTop?: number,
}) => {
    return <Spin
        spinning={isLoading}
        wrapperClassName={"spinner"}
        style={{ width: "100%", marginTop }}
        indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
    >
        {children}
    </Spin>;
};

export default Spinner;
