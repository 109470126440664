import { FC, useEffect, useMemo, useRef } from "react";

import { ClockCircleOutlined, LockOutlined } from "@ant-design/icons";
import checkmark from "assets/images/new-questionnaire/checkmark.svg";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";

import { getRelevantNavigationItems, getStatus, StepStatus } from "./DesktopMenu";

type MobileMenuProps = {
    handleCategoryClick: (_categoryNumber: number) => void;
};

export const getIcon = (status: StepStatus) => {
    switch (status) {
    case StepStatus.done:
        return <img src={checkmark} alt="done" className="dblock ml3"/>;
    case StepStatus.inProgress:
        return <ClockCircleOutlined className="ml3"/>;
    case StepStatus.disallowed:
        return <LockOutlined className="ml3"/>;
    default:
        return null;
    }
};

const MobileMenu: FC<MobileMenuProps> = ({ handleCategoryClick }) => {
    const dispatch = useDispatch();
    const menuRef = useRef<HTMLDivElement>(null);
    const activeStepRef = useRef<HTMLDivElement>(null);
    const { navigationItems, currentCategory, completedCategories, inProgressCategories } = useAppSelector(state => state.navigation);

    const dynamicItems = useMemo(() => {
        return getRelevantNavigationItems(navigationItems, currentCategory, completedCategories, inProgressCategories, dispatch);
    }, [navigationItems, completedCategories, inProgressCategories, currentCategory, dispatch]);

    useEffect(() => {
        const menu = menuRef.current;
        const activeStep = activeStepRef.current;

        if (menu && activeStep) {
            const menuWidth = menu.offsetWidth;
            const stepWidth = activeStep.offsetWidth;
            const stepOffsetLeft = activeStep.offsetLeft;

            const offset = stepOffsetLeft - (menuWidth / 2 - stepWidth / 2 + 30);
            menu.scrollTo({
                left: offset,
                behavior: "smooth",
            });
        }
    }, [currentCategory]);

    return (
        <div className="new-questionnaire__mobile-menu" ref={menuRef}>
            {dynamicItems.map((item, index) => {
                if (index === dynamicItems.length) return null;
                const status = getStatus(item, currentCategory, completedCategories, inProgressCategories);
                return (
                    <div
                        key={index}
                        className={`new-questionnaire__mobile-step new-questionnaire__mobile-step--${status} ${item.disabled ? "new-questionnaire__mobile-step--disabled" : ""}`}
                        ref={status === "active" ? activeStepRef : null}
                        onClick={event => {
                            if (item.disabled) {
                                event?.preventDefault();
                                return;
                            }
                            handleCategoryClick(item.categoryIndex);
                        }}
                    >
                        <div className="new-questionnaire__mobile-menu-text">{item.label}</div>
                        <div className="new-questionnaire__mobile-icon">{getIcon(status)}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default MobileMenu;
