import "styles/selectCountry.less";

import { useContext, useEffect, useState } from "react";

import { Button, Drawer, Modal, Typography } from "antd";
import { AppContext } from "App";
import info from "assets/images/info.svg";
import uk from "assets/images/regions/britain_24x24.png";
import ie from "assets/images/regions/ireland_24x24.png";
import planet from "assets/images/regions/planet.svg";
import Spinner from "components/basic/Spinner/Spinner";
import useDebounce from "hooks/helpers/useDebounce";
import { useLocalStorage } from "hooks/helpers/useLocalStorage";
import { useAppDispatch } from "redux/hooks";
import { logout } from "redux/reducers/auth";
import { IClientState } from "types/client";

const CountrySelect = ({
    isCountrySelectorOpened,
    handleClose,
}: {
    isCountrySelectorOpened: boolean;
	handleClose: () => void
}) => {
    const dispatch = useAppDispatch();
    const { isMobile } = useContext(AppContext);

    const [, setLocalClientData] =
    useLocalStorage<IClientState | null>("clientData", null);

    const [selectedCountry, setSelectedCountry] = useState("");

    const debouncedAnswer = useDebounce(selectedCountry, 2000);

    const handleSelect = (value: string) => {
        if (selectedCountry) return;
        setSelectedCountry(value);
    };

    const handleModalClose = () => {
        if (selectedCountry) return;
        handleClose();
    };

    useEffect(() => {
        if (debouncedAnswer) {
            if (window.location.pathname !== `/${debouncedAnswer}`) {
                setLocalClientData(null);
                dispatch(logout());
            }
            handleClose();
            window.location.href = `${process.env.REACT_APP_BASE_URL}/${debouncedAnswer}`;
            setSelectedCountry("");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedAnswer]);

    const content = (
        <>
            <Spinner isLoading={!!selectedCountry}>
                <div className={"select-country__body"}>
                    <img
                        src={planet}
                        alt="planet"
                        height={60}
                    />
                    <Typography className={"select-country__title"}>
                        Choose the country you live in
                    </Typography>
                    <Typography className={"select-country__text"}>
                        <img
                            src={info}
                            alt="info"
                            height={16}
                            style={{ marginRight: "3px" }}
                        />
                        You can change the country settings anytime on the website.
                    </Typography>
                    <div className="select-country__buttons">
                        <Button
                            className={`select-country__button ${selectedCountry === "uk" ? "selected" : ""}`}
                            onClick={() => handleSelect("uk")}
                        >
                            <img
                                src={uk}
                                alt="UK"
                                width={22}
                                height={16}
                                style={{ marginRight: "8px" }}
                            />
                            UK
                        </Button>

                        <Button
                            className={`select-country__button ${selectedCountry === "ie" ? "selected" : ""}`}
                            onClick={() => handleSelect("ie")}
                        >
                            <img
                                src={ie}
                                alt="Ireland"
                                width={22}
                                height={16}
                                style={{ marginRight: "8px" }}
                            />
                            Ireland
                        </Button>
                    </div>
                </div>
            </Spinner>
        </>
    );

    return (
        <div className="">{
            isMobile
                ? <Drawer
                    placement="bottom"
                    closable={false}
                    onClose={handleModalClose}
                    open={isCountrySelectorOpened}
                    height={300}
                    footer={null}
                    styles={{
                        content: {
                            borderRadius: "30px 30px 0 0",
                        },
                        wrapper: {
                            boxShadow: "none",
                        },
                        body: {
                            padding: "40px 10px",
                        },
                    }}
                >
                    {content}
                </Drawer>
                : <Modal
                    open={isCountrySelectorOpened}
                    styles={{
                        mask: {
                            backgroundColor: "rgba(0, 0, 0, 0.2)",
                        },
                    }}
                    width={486}
                    destroyOnClose
                    onCancel={handleModalClose}
                    footer={null}
                >
                    {content}
                </Modal>
        }
        </div>
    );
};

export default CountrySelect;
