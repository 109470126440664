import "styles/profile/profile.less";

import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";

import { Layout, Col, message, Menu } from "antd";
import { AppContext } from "App";
import logoutDefaultIcon from "assets/images/new-questionnaire/logout.svg";
import bankDetailsDefaultIcon from "assets/images/profile/bank_detail_defaulted.svg";
import bankDetailsSelectedIcon from "assets/images/profile/bank_detail_selected.svg";
import supportDefaultIcon from "assets/images/profile/customer_support_defaulted.svg";
import supportSelectedIcon from "assets/images/profile/customer_support_selected.svg";
import dashboardDefaultIcon from "assets/images/profile/dashboard_defaulted.svg";
import dashboardSelectedIcon from "assets/images/profile/dashboard_selected.svg";
import personalDetailsDefaultIcon from "assets/images/profile/setting_defaulted.svg";
import personalDetailsSelectedIcon from "assets/images/profile/setting_selected.svg";
import taxzapLogo from "assets/images/taxzap-logo-R.svg";
import BankDetailsForm from "components/basic/BankDetailsForm/BankDetailsForm";
import LottieAnimation from "components/basic/Spinner/Lottie";
import NameCircle from "components/common/NameCircle";
import Logout from "components/inputs/Auth/Logout";
import InstallModal from "components/modals/InstalModal";
import { useNotifications } from "hooks/auth/useNotifications";
import { useUser } from "hooks/common/useUser";
import { useLocalStorage } from "hooks/helpers/useLocalStorage";
import { useSessionStorage } from "hooks/helpers/useSessionStorage";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetNotificationSeenMutation } from "services/auth";
import { IUser } from "types/auth";
import { IClientState } from "types/client";

import MobileHeader from "./MobileHeader";
import MobileTabMenu from "./MobileTabMenu";
import BankDetails from "./tabs/BankDetails";
import Homepage from "./tabs/ClientProgress/Homepage";
import CustomerSupport from "./tabs/CustomerSupport";
import Documents from "./tabs/Documents";
import History from "./tabs/History";
import DashboardMenu from "./tabs/Homepage/DashboardMenu";
import SelectBankAccount from "./tabs/Homepage/SelectBank";
import IDPal from "./tabs/IDPal/IDpalIframe";
import ThankyouPage from "./tabs/IDPal/ThankyouPage";
import Payment from "./tabs/PaymentDetails/Payment";
import PersonalDetails from "./tabs/PersonalDetails";
import ProfileTab from "./tabs/ProfileTab";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { isIdVerificationCompleted } from "../../../services/idpal";

const { Content, Sider } = Layout;

const components: {
    [key: string]: React.FC<{
        user: IUser;
        setSelectedTab: (_tab: string) => void;
        setPageLoading: Dispatch<SetStateAction<boolean>>
        isPaymentDetailsRequired?: boolean;
    }>;
} = {
    "Homepage": Homepage,
    "Payment": Payment,
    "Documents": Documents,
    "Submission History": History,
    "Profile": ProfileTab,
    "Personal Details": PersonalDetails,
    "Bank Details": BankDetails,
    "Profile Bank Details": BankDetailsForm,
    "ThankyouPage": ThankyouPage,
    "Customer Support": CustomerSupport,
    "IDPal": IDPal,
    "Search your Bank": SelectBankAccount,
};

type isIdpalCompleteT = boolean

const Profile: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isMobile } = useContext(AppContext);
    const { user } = useUser();
    const [setNotificationSeen] = useSetNotificationSeenMutation();

    const [selectedTab, setSelectedTab] = useSessionStorage("profileTab", "Homepage");
    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
    const [shouldInstall, setShouldInstall] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const { currentDocument } = useAppSelector(
        (state: RootState) => ({
            currentDocument: state.document.currentDocument,
        })
    );

    const [, setLocalClientData] = useLocalStorage<IClientState | null>(
        "clientData",
        null
    );
    const { userNotifications, unseenNotificationsIds } = useNotifications();
    const TabComponent = components[selectedTab];

    const isPaymentDetailsRequired = useMemo(() => {
        if (!user) return false;
        if (
            (currentDocument?.status.id === "bank-details-requested" ||
                currentDocument?.status.id === "awaiting-bank-details-confirm") &&
            currentDocument?.confirmed &&
            !user.paymentDetails?.confirmed
        ) {
            setSelectedTab("Payment");
            return true;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const handleMenuClick = (e: any) => {
        if (selectedTab !== e.key) {
            setPageLoading(true);
        }
        e.key !== "LogOut" && setSelectedTab(e.key);
    };

    const handleNotificationsClick = () => {
        isNotificationsOpen && unseenNotificationsIds.map(async id => {
            setNotificationSeen(id)
                .unwrap()
                .then(async () => {
                    console.log("Notification set seen successfully");
                })
                .catch(async error => {
                    await message.error(error.data.message);
                });
        });
        setIsNotificationsOpen(prev => !prev);
    };

    const { data: idpalComplete } =
        isIdVerificationCompleted.useQuery(
            { clientId: user?.id }
        );

    const [, setIsIdpalComplete] =
        useLocalStorage<isIdpalCompleteT>("idpalComplete", false);

    const setIdVerificationCompleted = async () => {
        try {
            setIsIdpalComplete(idpalComplete?.idpalComplete);
        } catch (error) {
            console.error("Error setting ID verification completed:", error);
        }
    };

    const updateIntercomUser = (userData: any) => {
        if (window.Intercom) window.Intercom("update", userData);
    };
    const selectedKeys = useMemo(() => {
        const paymentTabs = ["Payment", "IDPal"];
        if (paymentTabs.includes(selectedTab)) {
            return ["Payment"];
        }
        return [selectedTab];
    }, [selectedTab]);

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        const tab = (parsed.tab) as string;
        if (!tab) return;

        const urlToTabNameMapping: Record<string, string> = {
            "current-return": "Homepage",
            "payment-details": "Payment",
            "documents": "Documents",
            "account": "Personal Details",
            "customer-support": "Customer Support",
        };

        const selected = urlToTabNameMapping[tab.toLowerCase()];
        selected && setSelectedTab(selected);
        if (location.search) {
            navigate(location.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search, setSelectedTab]);

    useEffect(() => {
        if (user) {
            updateIntercomUser({
                name: user.info.firstName + " " + user.info.lastName,
                email: user.info.email,
                user_id: user.nin,
                PPSN: user.nin,
            });
            const clientDataToSave = {
                havePassword: !!user.info.password,
                clientId: user.id,
            };
            setLocalClientData(clientDataToSave);
            setIdVerificationCompleted();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setPageLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, [selectedTab]);

    if (!user) {
        return null;
    }

    return (
        <Layout className="profile__layout" data-testid={"profile"}>
            <LottieAnimation isLoading={pageLoading} backgroundColor={"white"}/>
            {isMobile
                ? <div>
                    <InstallModal shouldInstall={shouldInstall} setShouldInstall={setShouldInstall} />
                    <MobileHeader
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        handleNotificationsClick={handleNotificationsClick}
                    />
                    <Content
                        className="content"
                        style={{ width: "375px", padding: "56px 0px 0px", overflow: "visible" }}
                    >
                        <TabComponent
                            user={user}
                            setSelectedTab={setSelectedTab}
                            setPageLoading={setPageLoading}
                            isPaymentDetailsRequired={isPaymentDetailsRequired}
                        />
                        { <DashboardMenu
                            showNotifications={isNotificationsOpen}
                            showToggle={handleNotificationsClick}
                            userNotifications={userNotifications} firstName={user.info.firstName} lastName={user.info.lastName} email={user.info.email}
                            setPageLoading={setPageLoading}/> }
                    </Content>
                    <MobileTabMenu selectedTab={selectedTab} setSelectedTab={setSelectedTab} setPageLoading={setPageLoading} />
                </div>
                : <div className="profile__page">
                    <Col className="profile__menu-wrapper">
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <img className="logo" src={taxzapLogo} alt="TaxZap Logo" onClick={() => navigate("/")}/>
                            <Sider width={280} style={{ marginTop: "40px" }}>
                                <Menu
                                    selectedKeys={selectedKeys}
                                    onClick={handleMenuClick}
                                    mode="inline"
                                    style={{
                                        height: "100%",
                                        borderRight: 0,
                                    }}
                                >
                                    <Menu.Item
                                        key="Homepage"
                                        data-testid={"profile-sub-menu-profile"}
                                        className="profile__submenu profile__menu-item"
                                        icon={
                                            <img
                                                src={
                                                    selectedTab === "Homepage"
                                                        ? dashboardSelectedIcon
                                                        : dashboardDefaultIcon
                                                }
                                                alt="Returns Icon"
                                                width={32}
                                            />
                                        }
                                    >
                                        Dashboard
                                    </Menu.Item>
                                    <Menu.Item
                                        key="Bank Details"
                                        className="profile__submenu profile__menu-item"
                                        icon={
                                            <img
                                                src={
                                                    selectedTab === "Bank Details"
                                                        ? bankDetailsSelectedIcon
                                                        : bankDetailsDefaultIcon
                                                }
                                                alt="Bank Details Icon"
                                                width={32}
                                            />
                                        }
                                    >
                                        Bank Details
                                    </Menu.Item>
                                    <Menu.Item
                                        key="Customer Support"
                                        className="profile__submenu profile__menu-item"
                                        icon={
                                            <img
                                                src={
                                                    selectedTab === "Customer Support"
                                                        ? supportSelectedIcon
                                                        : supportDefaultIcon
                                                }
                                                alt="Customer Support Icon"
                                                width={32}
                                            />
                                        }
                                    >
                                        Support
                                    </Menu.Item>

                                </Menu>
                            </Sider>
                        </div>
                        <Menu
                            mode="inline"
                            selectedKeys={selectedKeys}
                            onClick={handleMenuClick}
                            style={{
                                borderRight: 0,
                                marginTop: "auto",
                            }}
                        >
                            <Menu.Item
                                key="Personal Details"
                                className="profile__submenu profile__menu-item"
                                icon={
                                    <img
                                        src={
                                            selectedTab === "Personal Details"
                                                ? personalDetailsSelectedIcon
                                                : personalDetailsDefaultIcon
                                        }
                                        alt="Personal Details Icon"
                                        width={32}
                                    />
                                }
                            >
                                        Settings
                            </Menu.Item>
                            <div className="profile__client">
                                <NameCircle firstName={user.info.firstName} lastName={user.info.lastName}/>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "15px" }}>
                                    <span className="profile__client-name">{`${user.info.firstName} ${user.info.lastName}`}</span>
                                    <span className="profile__client-name-email">{`${user.info.email}`}</span>
                                </div>
                            </div>
                            <Menu.Item
                                key="LogOut"
                                className="profile__submenu profile__menu-item"
                                icon={
                                    <img
                                        src={logoutDefaultIcon}
                                        alt="Logout Icon"
                                        width={32}
                                    />
                                }
                            >
                                <Logout/>
                            </Menu.Item>
                        </Menu>
                    </Col>
                    <Content className="content">
                        <TabComponent
                            user={user}
                            setSelectedTab={setSelectedTab}
                            setPageLoading={setPageLoading}
                            isPaymentDetailsRequired={isPaymentDetailsRequired}
                        />
                    </Content>
                </div>
            }
        </Layout>
    );
};

export default Profile;
