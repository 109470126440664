import { Dispatch, SetStateAction, useContext } from "react";

import { Typography } from "antd";
import { AppContext } from "App";
import support from "assets/images/profile/support.png";

type CustomerSupportProps = {
    setPageLoading: Dispatch<SetStateAction<boolean>>
};

export default function CustomerSupport({ setPageLoading }: CustomerSupportProps) {
    const { isMobile } = useContext(AppContext);
    return (
        <div className="profile__content">
            {!isMobile && (<div className="profile__content-header">
                <Typography className="profile__content-heading">
                    Customer Support
                </Typography>
            </div>)}
            <div className="profile__content-body">
                {!isMobile && (
                    <Typography className="profile__content-title">
                        Get Support Anytime
                    </Typography>
                )}
                <div className="profile__status-content">
                    <img
                        width={isMobile ? 200 : 337}
                        src={support}
                        alt="Support"
                        onLoad={() => {
                            const timer = setTimeout(() => {
                                setPageLoading(false);
                            }, 200);
                            return () => clearTimeout(timer);
                        }}
                        style={{
                            display: "block",
                            margin: "20px auto",
                        }}
                    />
                    <Typography
                        className="profile__content-text"
                        style={{
                            margin: "10px 0px",
                            padding: "10px",
                        }}
                    >
                        If you need help, contact TaxZap support via{" "}
                        <a
                            href="mailto:support@taxzap.co.uk"
                            className="profile__content-email-text"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            support@taxzap.co.uk
                        </a>
                        . We’re available 24/7 from anywhere in the world.
                    </Typography>
                </div>
            </div>
        </div>
    );
}
