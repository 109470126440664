import React, { useEffect } from "react";

import { useLocalStorage } from "hooks/helpers/useLocalStorage";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useGetPotentialClientByIdMutation } from "services/potential-clients";
import { IPotentialClientForm, IPotentialClientResponse } from "types/potential-clients";

const PotentialClient: React.FC = () => {
    const { potentialClientId } = useParams<{ potentialClientId: string }>();
    const location = useLocation();
    const navigate = useNavigate();
    const marketingSource = location.search.split("?")[1];

    const [ , setFormData] = useLocalStorage<IPotentialClientForm | null>("clientData", null);
    const [getPotentialClientById] = useGetPotentialClientByIdMutation();

    useEffect(() => {
        const fetchDataAndSetFormData = async () => {
            try {
                const response = await getPotentialClientById({ potentialClientId, marketingSource });
                const { data } = response as { data: IPotentialClientResponse };
                const formData: IPotentialClientForm = {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phone: data.phone,
                    email: data.email,
                    dateOfBirth: data.dateOfBirth,
                    nin: data.nin,
                    utr: data.utr,
                    address1: data.address?.street,
                    city: data.address?.city,
                    postcode: data.address?.postcode,
                };
                setFormData(formData);
                navigate("/");
            } catch (error) {
                console.error("Error setting potential client data", error);
            }
        };

        if (potentialClientId) {
            fetchDataAndSetFormData();
        }
        // eslint-disable-next-line
    }, [getPotentialClientById, navigate, potentialClientId, marketingSource]);

    return null;
};

export default PotentialClient;
