import "styles/buttonFooter.less";

import { FC, ReactNode, useContext } from "react";

import { AppContext } from "App";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

interface ButtonFooterProps {
    children?: ReactNode
}

const QuestionnaireButtonFooterV2: FC<ButtonFooterProps> = ({ children }) => {
    const isKeyboardOpen = useDetectKeyboardOpen();
    const { isMobile } = useContext(AppContext);
    return !isKeyboardOpen ? (
        <div className={isMobile ? "questionnaire-button-footer-v2" : "questionnaire-button-footer-v2--desktop"}>
            <div className="questionnaire-button-footer-content-v2">
                {children}
            </div>
        </div>
    ) : (
        <div>
            {children}
        </div>
    );
};

export default QuestionnaireButtonFooterV2;
