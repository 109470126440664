import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategoryTitle } from "components/pages/NewQuestionnaire/enums/enum";
import { RootState } from "redux/store";

interface NavigationState {
    currentCategory: number;
    currentStep: number;
    currentQuestion: number;
    lastCategory: number;
    lastStep: number;
    lastQuestion: number;
    currentProfileQuestion: number;
    currentArrayQuestionIndex: number;
    currentInnerArrayQuestionIndex: number;
    navigationItems: string[];
    completedCategories: CategoryTitle[];
    inProgressCategories: CategoryTitle[];
    stepLoading: boolean;
}

const initialState: NavigationState = {
    currentCategory: 0,
    currentStep: 0,
    currentQuestion: 0,
    lastCategory: 0,
    lastStep: 0,
    lastQuestion: 0,
    currentProfileQuestion: 0,
    currentArrayQuestionIndex: 0,
    currentInnerArrayQuestionIndex: 0,
    navigationItems: [],
    completedCategories: [CategoryTitle.BankAccountConnection],
    inProgressCategories: [CategoryTitle.PersonalInfo],
    stepLoading: false,
};

const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        next: (state, action: PayloadAction<{ nextCategory: number; nextStep: number; nextQuestion: number, nextProfileQuestion?: number }>) => {
            state.lastCategory = state.currentCategory;
            state.lastStep = state.currentStep;
            state.lastQuestion = state.currentQuestion;

            state.currentCategory = action.payload.nextCategory;
            state.currentStep = action.payload.nextStep;
            state.currentQuestion = action.payload.nextQuestion;
            state.currentProfileQuestion = action.payload.nextProfileQuestion ?? 0;
            state.stepLoading = false;
        },
        previous: (state, action: PayloadAction<{ prevCategory: number; prevStep: number; prevQuestion: number, prevProfileQuestion?: number, currentArrayQuestionIndex?: number }>) => {
            state.currentCategory = action.payload.prevCategory;
            state.currentStep = action.payload.prevStep;
            state.currentQuestion = action.payload.prevQuestion;
            state.currentProfileQuestion = action.payload.prevProfileQuestion ?? 0;
            state.currentArrayQuestionIndex = action.payload.currentArrayQuestionIndex ?? 0;
            state.stepLoading = false;
        },
        setNavigationItems: (state, action: PayloadAction<string[]>) => {
            state.navigationItems = action.payload;
        },
        markCategoryCompleted: (state, action: PayloadAction<CategoryTitle>) => {
            const completedSet = new Set(state.completedCategories);
            completedSet.add(action.payload);
            if (state.inProgressCategories?.includes(action.payload)) {
                const inProgressSet = new Set(state.inProgressCategories);
                inProgressSet.delete(action.payload);
                state.inProgressCategories = Array.from(inProgressSet); // Convert back to array
            }
            state.completedCategories = Array.from(completedSet); // Convert back to array
        },
        markCategoryInProgress: (state, action: PayloadAction<CategoryTitle>) => {
            if (state.completedCategories?.includes(action.payload)) {
                const completedSet = new Set(state.completedCategories);
                completedSet.delete(action.payload);
                state.completedCategories = Array.from(completedSet); // Convert back to array
            }
            const inProgressSet = new Set(state.inProgressCategories);
            inProgressSet.add(action.payload);
            state.inProgressCategories = Array.from(inProgressSet); // Convert back to array
        },
        setCompletedCategories: (state, action: PayloadAction<CategoryTitle[]>) => {
            state.completedCategories = action.payload;
        },
        setInProgressCategories: (state, action: PayloadAction<CategoryTitle[]>) => {
            state.inProgressCategories = action.payload;
        },
        setCurrentArrayQuestionIndex: (state, action: PayloadAction<number>) => {
            state.currentArrayQuestionIndex = action.payload;
        },
        setCurrentInnerArrayQuestionIndex: (state, action: PayloadAction<number>) => {
            state.currentInnerArrayQuestionIndex = action.payload;
        },
        setStepLoading: (state, action: PayloadAction<boolean>) => {
            state.stepLoading = action.payload;
        },
        resetNavigation: () => initialState,
    },
});

export const selectNavigationState = createSelector(
    (state: RootState) => state.navigation,
    navigation => navigation
);

export const selectCurrentNavigation = createSelector(
    (state: RootState) => state.navigation,
    navigation => ({
        currentCategory: navigation.currentCategory,
        currentStep: navigation.currentStep,
        currentQuestion: navigation.currentQuestion,
        currentProfileQuestion: navigation.currentProfileQuestion,
        currentArrayQuestionIndex: navigation.currentArrayQuestionIndex,
        currentInnerArrayQuestionIndex: navigation.currentInnerArrayQuestionIndex,
        completedCategories: navigation.completedCategories,
        inProgressCategories: navigation.inProgressCategories,
        stepLoading: navigation.stepLoading,
    })
);

export const selectLastNavigation = createSelector(
    (state: RootState) => state.navigation,
    navigation => ({
        lastCategory: navigation.lastCategory,
        lastStep: navigation.lastStep,
        lastQuestion: navigation.lastQuestion,
    })
);

export const selectNavigationItems = createSelector(
    (state: RootState) => state.navigation.navigationItems,
    navigationItems => navigationItems
);

export const { next, previous, setNavigationItems, setCurrentArrayQuestionIndex, setCurrentInnerArrayQuestionIndex, setInProgressCategories, setCompletedCategories, markCategoryInProgress, markCategoryCompleted, setStepLoading, resetNavigation } = navigationSlice.actions;

export default navigationSlice.reducer;
