import { FC, ChangeEvent, KeyboardEvent, useCallback, useEffect, useState, useMemo, useRef, useContext } from "react";

import { Input, InputRef } from "antd";
import { AppContext } from "App";
import errorIcon from "assets/images/new-questionnaire/exclamationmark-circle.svg";
import infoIcon from "assets/images/new-questionnaire/info_icon.svg";
import { isAnswerValid } from "utils/new-questionnaire";
import { isNINValid } from "utils/validation";

import { Question } from "../../constants/questions";

type TextListQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: string | undefined, _questionType?: string) => void;
    initialValue?: any;
    focus?: boolean;
};

const { TextArea } = Input;

const TextListQuestion: FC<TextListQuestionProps> = ({ data, onAnswer, initialValue, focus }) => {
    const isMobile = useContext(AppContext);
    const [inputValue, setInputValue] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const helpText = useMemo(() => data.infoText, [data]);
    const [isFocused, setIsFocused] = useState(false);

    const inputRef = useRef<InputRef>(null);

    const { minRows, maxRows } = useMemo(() => ({
        minRows: data.minRows ?? 1,
        maxRows: data.maxRows ?? 6,
    }), [data]);

    const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setInputValue(value);

        if (data.validation) {
            let isValid;
            if (data.validation.type === "nin") {
                isValid = isNINValid(value);
            } else {
                isValid = isAnswerValid(value, data.validation.regExp);
            }
            if (!isValid) {
                setError(data.validation.error);
            } else {
                setError(null);
            }
        } else {
            setError(null);
        }
    }, [data.validation]);

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.key === "Enter") {
            if (isMobile) {
                e.currentTarget.blur();
            } else {
                e.preventDefault();
            }
        }
    };
    const handleWrapperClick = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (error) {
            onAnswer(data.question, undefined, "textQuestion");
        } else if (inputValue !== initialValue) {
            onAnswer(data.question, inputValue, "textQuestion");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue, error]);

    useEffect(() => {
        if (focus) {
            const focusInput = () => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            };

            const timer = setTimeout(focusInput, 10);

            return () => clearTimeout(timer);
        }
    }, [focus]);

    useEffect(() => {
        setInputValue(initialValue || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.question]);

    return (
        <div className="new-questionnaire__list-question">
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                {data.question}
            </div>
            <div
                className="new-questionnaire__question-input-wrapper"
                style={{ borderBottom: isFocused ? "4px solid #7F43FF" : "4px solid #C4C4C4", marginTop: "10px" }}
                onClick={handleWrapperClick}
            >
                {data.inputType === "number" ? (
                    <>
                        {data.unit && <span style={{ width: "135px", height: "1px" }}>{}</span>}
                        <Input
                            className="new-questionnaire__text-list-question-input"
                            onChange={handleInputChange}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            value={inputValue}
                            type="number"
                            inputMode="decimal"
                            onKeyDown={handleKeyDown}
                            ref={inputRef}
                        />
                        {data.unit && <span className="new-questionnaire__text-question-unit">{data.unit}</span>}
                    </>
                ) : (
                    <TextArea
                        className="new-questionnaire__text-question-input"
                        onChange={handleInputChange}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        value={inputValue}
                        autoSize={{ minRows, maxRows }}
                        onKeyDown={handleKeyDown}
                    />
                )}
            </div>
            {helpText && (
                <div className="new-questionnaire__info-message">
                    <img src={infoIcon} width={20} alt="info" />
                    <div className="new-questionnaire__info-message-text ml4">{helpText}</div>
                </div>
            )}
            {error && (
                <div className="new-questionnaire__error-message">
                    <img src={errorIcon} width={20} alt="error" />
                    <div className="new-questionnaire__error-message-text ml4">{error}</div>
                </div>
            )}
        </div>
    );
};

export default TextListQuestion;
