import reviewStarEmptyIcon from "assets/images/g-star-empty.svg";
import reviewStarFullIcon from "assets/images/g-star.svg";

export const getStars = (stars: number, size: number = 20) => {
    const fullStars = Math.floor(stars);
    const partialStar = stars % 1;
    const starsArray = [];
    for (let i = 0; i < fullStars; i++) {
        starsArray.push(
            <img
                key={i}
                src={reviewStarFullIcon}
                alt="reviewStarFullIcon"
                width={size}
                height={size}
                style={{ marginRight: "3px" }}
            />
        );
    }

    if (partialStar > 0) {
        starsArray.push(
            <div
                key={fullStars}
                style={{
                    position: "relative",
                    width: `${size}px`,
                    height: `${size}px`,
                    overflow: "hidden",
                }}
            >
                <img
                    src={reviewStarEmptyIcon}
                    alt="reviewStarEmptyIcon"
                    width={size}
                    height={size}
                />
                <img
                    src={reviewStarFullIcon}
                    alt="reviewStarFullIcon"
                    width={size}
                    height={size}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        clipPath: `inset(0 ${100 - partialStar * 100}% 0 0)`,
                    }}
                />
            </div>
        );
    }
    return (
        <div style={{ display: "flex" }}>
            {starsArray}
        </div>
    );
};
