import React, { FC, useState } from "react";

import { Dropdown, Menu } from "antd";
import calendar from "assets/images/new-questionnaire/calendar.svg";
import taxzapLogo from "assets/images/taxzap-logo-R.svg";
import NewQuestionnaireNameCircle from "components/common/NewQuestionnaireNameCircle";
import DropDownDesktop from "components/pages/Profile/tabs/Homepage/DropDownDesktop";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { IUser } from "types/auth";

import { getTaxYears } from "../../../../constants/taxYears";
import { useAppSelector } from "../../../../redux/hooks";
import { setCurrentDocumentYear } from "../../../../redux/reducers/document";
import { RootState } from "../../../../redux/store";
import { AccountDetails } from "../../../../types/bankAccounts";
import { Step } from "../constants/questions";

type HeaderProps = {
    user: IUser;
    connectedBankAccounts: AccountDetails[];
    openConnectModal: () => void;
    handleContinue: () => void;
    playPigAnimationTrigger: string;
    currentStepData: Step;
};

const DesktopHeader: FC<HeaderProps> = ({ user, connectedBankAccounts }) => {
    const dispatch = useDispatch();
    const [showDropDown, setShowDropDown] = useState(false);

    const { currentDocumentYear, taxYears } = useAppSelector((state: RootState) => ({
        currentDocumentYear: state.document.currentDocumentYear,
        taxYears: state.taxYears.taxYears,
    }));

    const handleNotificationsClick = () => {
        setShowDropDown(prev => !prev);
    };

    const handleMenuClick = ({ key }: { key: string }) => {
        dispatch(setCurrentDocumentYear(key));
    };

    const taxYearMenu = (
        <Menu
            onClick={handleMenuClick}
            selectedKeys={[currentDocumentYear]}
            items={getTaxYears(taxYears).map(year => ({
                key: year,
                label: `${year} Tax Year`,
            }))}
        />
    );

    return (
        <div className="new-questionnaire__desktop-header">
            <Link to="/profile" className="new-questionnaire__profile-link">
                <img className="logo-questionnaire" src={taxzapLogo} alt="TaxZap Logo" />
            </Link>
            <div className="new-questionnaire__desktop-header-content">
                <div className="client-navbar__calendar">
                    <img src={calendar} alt="calendar" />
                    <Dropdown overlay={taxYearMenu}>
                        <div className="client-navbar__calendar-title">
                            {currentDocumentYear} Tax Year
                        </div>
                    </Dropdown>
                </div>
                <div>
                    <div className={`client__navbar ${showDropDown ? "active" : ""}`} onClick={handleNotificationsClick}>
                        <NewQuestionnaireNameCircle firstName={user.info.firstName} lastName={user.info.lastName} />
                        <div className="ml6">
                            <div className="new-questionnaire__client-name">{`${user.info.firstName} ${user.info.lastName}`}</div>
                            <div className="new-questionnaire__client-email">{user.info.email}</div>
                        </div>
                    </div>
                    <DropDownDesktop
                        showDropDown={showDropDown}
                        showToggle={handleNotificationsClick}
                        connectedBankAccounts={connectedBankAccounts}
                    />
                </div>
            </div>
        </div>
    );
};

export default DesktopHeader;
