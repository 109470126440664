import { useEffect, useState } from "react";

import { Button, Col, Form, Row, Typography } from "antd";
import LottieAnimation from "components/basic/Spinner/Lottie";
import AuthError from "components/common/AuthError";
import EmailInput from "components/inputs/Form/Inputs/EmailInput";
import PasswordInput from "components/inputs/Form/Inputs/PasswordInput";
import ForgotPasswordModal from "components/modals/ForgotPasswordModal/ForgotPasswordModal";
import { SharingPreferenceIdType } from "components/pages/NewQuestionnaire/components/OnBoarding/OnBoarding";
import { CategoryTitle } from "components/pages/NewQuestionnaire/enums/enum";
import { useLocalStorage } from "hooks/helpers/useLocalStorage";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { useSessionStorage } from "hooks/helpers/useSessionStorage";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setAnswers } from "redux/reducers/answers";
import { login } from "redux/reducers/auth";
import { next, setCompletedCategories, setInProgressCategories, setNavigationItems } from "redux/reducers/navigation";
import { RootState } from "redux/store";
import { useSignInMutation } from "services/auth";
import { useGetOrCreateClientDocumentMutation } from "services/client";
import { logError } from "utils/sentry";

import { getCurrentTaxYear } from "../../../../constants/taxYears";
import { setCurrentDocument, setCurrentDocumentYear, setSelectedSharingPreference } from "../../../../redux/reducers/document";

const initialValues = {
    email: "",
    password: "",
};

const SignIn = () => {
    const dispatch = useAppDispatch();
    const authData = useAppSelector((state: RootState) => state.auth.authData);
    const [, setSelectedTab] = useSessionStorage<string>("profileTab", "Homepage");
    const [getOrCreateClientDocument] = useGetOrCreateClientDocumentMutation();

    const [loginErrorStatus, setLoginErrorStatus] = useState<number | null>(null);

    const [form] = Form.useForm();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_rememberMe, setRememberMe] =
    useLocalStorage<boolean | null>("rememberMe", null);

    const {
        isOpened: forgotPasswordModalIsOpened,
        open: openForgotPasswordModal,
        close: closeForgotPasswordModal,
    } = useModalOpener();

    const [signIn, { isLoading }] = useSignInMutation();

    useEffect(() => {
        setSelectedTab("Homepage");
        setRememberMe(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFinish = (data: any) => {
        const trimmedData = {
            ...data,
            email: data.email.trim(),
        };
        signIn(trimmedData)
            .unwrap()
            .then(data => {
                setLoginErrorStatus(null);
                dispatch(login(data.token));
            })
            .catch(async error => {
                setLoginErrorStatus(error?.status);
            });
    };
    const { taxYears } = useAppSelector((state: RootState) => ({
        taxYears: state.taxYears.taxYears,
    }));

    useEffect(() => {
        if (authData) {
            let currentYear = getCurrentTaxYear(taxYears);
            if (!currentYear) { // TODO remove if we never see this sentry
                logError("Current Year wasn't present", {
                    function: "useEffectSignIn",
                    client: authData?._id,
                    currentDocumentYear: currentYear,
                });
                currentYear = "2024/2025";
            }
            const data = { clientId: authData._id, year: currentYear };
            getOrCreateClientDocument(data)
                .unwrap()
                .then(response => {
                    if (!response || !response.document) {
                        throw new Error("Invalid response format");
                    }

                    const { document } = response;
                    const answersOB = document.answersOB || {}; // ✅ Prevents undefined errors

                    dispatch(setCurrentDocument(document));
                    dispatch(setAnswers(answersOB.answers || {}));
                    dispatch(setSelectedSharingPreference(answersOB?.selectedSharingPreference || SharingPreferenceIdType.connect));

                    dispatch(
                        next({
                            nextCategory: answersOB.currentCategory || 0,
                            nextStep: answersOB.currentStep || 0,
                            nextQuestion: answersOB.currentQuestion || 0,
                            nextProfileQuestion: answersOB.currentProfileQuestion || 0,
                        })
                    );

                    dispatch(
                        setNavigationItems(
                            answersOB.navigationItems?.length
                                ? answersOB.navigationItems
                                : [
                                    CategoryTitle.BankAccountConnection,
                                    CategoryTitle.PersonalInfo,
                                    CategoryTitle.General,
                                    CategoryTitle.ReviewAndSubmit,
                                ]
                        )
                    );

                    dispatch(
                        setCompletedCategories(
                            answersOB.completedCategories?.length
                                ? answersOB.completedCategories
                                : [CategoryTitle.BankAccountConnection]
                        )
                    );

                    dispatch(
                        setInProgressCategories(
                            answersOB.inProgressCategories?.length
                                ? answersOB.inProgressCategories
                                : [CategoryTitle.PersonalInfo]
                        )
                    );
                    dispatch(setCurrentDocumentYear(currentYear));
                })
                .catch(e => {
                    logError(e, {
                        function: "getOrCreateClientDocument SignIn",
                        client: authData?._id,
                        currentDocumentYear: currentYear,
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authData]);

    return (
        <div className="auth__login-account-container">
            <LottieAnimation isLoading={isLoading}/>
            <Typography className="auth__login-account-header">
                Login Account
            </Typography>
            <div className="auth__transition-container">
                {loginErrorStatus
                    ? <AuthError code={loginErrorStatus} resetPassword={openForgotPasswordModal}/>
                    : <Typography className="auth__existing-user-text">
                        Used TaxZap before? Enter your previous email and choose a password to login.
                    </Typography>
                }
            </div>
            <Form
                form={form}
                onFinish={handleFinish}
                initialValues={initialValues}
                layout={"vertical"}
            >
                <Row>
                    <Col span={24}>
                        <EmailInput
                            checkExists
                            setLoginErrorStatus={setLoginErrorStatus}
                            required
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <PasswordInput
                            error={!!loginErrorStatus && [403, 409].includes(loginErrorStatus)}
                            setLoginErrorStatus={setLoginErrorStatus}
                            required
                        />
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                    <Col className={"horizontal-left"}>
                        <Typography className="auth__forgot-password-text">
                            Forgot your password?
                        </Typography>
                        <div
                            className="auth__reset-link"
                            onClick={openForgotPasswordModal}
                        >
                            Reset
                        </div>
                    </Col>
                </Row>
                <Row className="auth__row-margin-top">
                    <Col>
                        <Form.Item style={{ marginBottom: "0px" }}>
                            <Button
                                type="primary"
                                shape="round"
                                data-testid="sign-in-submit-button"
                                htmlType="submit"
                                className={"start-button auth__start-button"}
                            >
                                Log In
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Link
                            to={"/sign-up?step=personalInfo"}
                            className="auth__continue-as-guest-link"
                        >
                            Sign Up
                        </Link>
                    </Col>
                </Row>
            </Form>
            {forgotPasswordModalIsOpened && (
                <ForgotPasswordModal close={closeForgotPasswordModal} />
            )}
        </div>
    );
};

export default SignIn;
