import { useContext, useState } from "react";

import { Col, Row } from "antd";
import { AppContext } from "App";
import cross from "assets/images/cross.svg";
import checkmark from "assets/images/uk/checkmarkgreen.svg";

const WhyTaxzap = () => {
    const { isMobile, maxWidth } = useContext(AppContext);
    const taxzapInfo = [
        "Claim every deduction — don't miss a penny!",
        "No forms — just fast, easy filing.",
        "On mobile or desktop, file when it suits you.",
        "Perfect for CIS, freelancers, and small businesses.",
        "Bank-level encryption for safe, easy filing.",
        "File in minutes and get back to what you love.",
    ];

    const hmrcInfo = [
        "No guidance means that you might overpay.",
        "Technical jargon and long forms slow you down.",
        "Not designed for mobile — no on-the-go option.",
        "Self-employed? You're on your own with HMRC.",
        "No bank account connection — more work and room for mistakes.",
        "Expect a long filing time without shortcuts.",
    ];
    const [activeSection, setActiveSection] = useState("taxzap");

    const handleToggle = (section: string) => {
        setActiveSection(section);
    };

    const currentInfo = activeSection === "taxzap" ? taxzapInfo : hmrcInfo;
    const currentImage = activeSection === "taxzap" ? checkmark : cross;
    const backgroundClass = activeSection === "hmrc" ? "uk-why-taxzap-table-header-label" : "uk-why-taxzap-table-header-label-grey";
    return (
        <div className={"uk-why-taxzap-block horizontal-center"}>
            <div
                style={{
                    maxWidth: maxWidth,
                    minWidth: isMobile ? "auto" : "1300px",
                    padding: isMobile ? "24px" : "64px 100px",
                    overflow: "hidden",
                }}
            >
                <div className={"uk-sub-slogan"}>
                     Why use TaxZap?
                </div>
                <div className="mt30 mb40 self-employed__subtitle">
                TaxZap makes tax filing effortless — no more HMRC headaches or endless forms. Sign up and get started in minutes!
                </div>
                <Row style={{ justifyContent: "center" }}>
                    <Col>
                        <div className={isMobile ? "" : "m20"}>
                            <div className={"uk-why-taxzap-table"}>
                                <div className={"uk-why-taxzap-table-header-new mb40"}>
                                    <Row className={"p10"}>
                                        <Col onClick={() => handleToggle("taxzap")}>
                                            <div className={`${backgroundClass} ${activeSection === "taxzap" ? "active" : ""}`}>
                                            😊 TaxZap
                                            </div>
                                        </Col>
                                        <Col onClick={() => handleToggle("hmrc")}>
                                            <div className={`${backgroundClass} ${activeSection === "hmrc" ? "active" : ""}`}>
                                            😫 HMRC
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <div className={"uk-why-taxzap-table-row"}>
                                        {currentInfo.map((label, index) => (
                                            <Row key={index} className={isMobile ? "p5 uk-whytaxzap-taxzap-container-mobile" : "p10 uk-why-taxzap-container-desktop"}>
                                                <Col className="mr10">
                                                    <div>
                                                        <img src={currentImage} alt="Checkmark" width={42} />
                                                    </div>
                                                </Col>
                                                <Col xs={20} sm={12} md={13}>
                                                    <div className={"uk-why-taxzap-table-row-label"}>
                                                        {label}
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default WhyTaxzap;
