import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Question } from "components/pages/NewQuestionnaire/constants/questions";
import { RootState } from "redux/store";
import { ArrayAnswersType } from "types/questionnaire";

interface ArrayQuestionState {
    isEditingItem: boolean;
    isEditingInnerArrayItem: boolean;
    arrayItemAnswers: ArrayAnswersType["answers"];
    arrayItemQuestions: Question[];
    innerArrayItemAnswers: ArrayAnswersType["answers"];
    innerArrayItemQuestions: Question[];
}

const initialState: ArrayQuestionState = {
    isEditingItem: false,
    isEditingInnerArrayItem: false,
    arrayItemAnswers: [],
    arrayItemQuestions: [],
    innerArrayItemAnswers: [],
    innerArrayItemQuestions: [],
};

const arrayQuestionSlice = createSlice({
    name: "arrayQuestion",
    initialState,
    reducers: {
        setIsEditingItem(state, action: PayloadAction<boolean>) {
            state.isEditingItem = action.payload;
        },
        setIsEditingInnerArrayItem(state, action: PayloadAction<boolean>) {
            state.isEditingInnerArrayItem = action.payload;
        },
        setArrayItemAnswers(state, action: PayloadAction<ArrayAnswersType["answers"]>) {
            state.arrayItemAnswers = action.payload;
        },
        setArrayItemQuestions(state, action: PayloadAction<Question[]>) {
            state.arrayItemQuestions = action.payload;
        },
        setInnerArrayItemAnswers(state, action: PayloadAction<ArrayAnswersType["answers"]>) {
            state.innerArrayItemAnswers = action.payload;
        },
        setInnerArrayItemQuestions(state, action: PayloadAction<Question[]>) {
            state.innerArrayItemQuestions = action.payload;
        },
    },
});

export const selectArrayQuestionState = createSelector(
    (state: RootState) => state.arrayQuestion,
    arrayQuestion => ({
        isEditingItem: arrayQuestion.isEditingItem,
        isEditingInnerArrayItem: arrayQuestion.isEditingInnerArrayItem,
        arrayAnswers: arrayQuestion.arrayItemAnswers,
        arrayItemQuestions: arrayQuestion.arrayItemQuestions,
        innerArrayAnswers: arrayQuestion.innerArrayItemAnswers,
        innerArrayItemQuestions: arrayQuestion.innerArrayItemQuestions,
    })
);

export const {
    setIsEditingItem,
    setIsEditingInnerArrayItem,
    setArrayItemAnswers,
    setArrayItemQuestions,
    setInnerArrayItemAnswers,
    setInnerArrayItemQuestions,
} = arrayQuestionSlice.actions;

export default arrayQuestionSlice.reducer;
