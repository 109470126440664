import { FC, useCallback, useContext } from "react";

import { AppContext } from "App";
import arrowRight from "assets/images/right-arrow-white.svg";
import { useLocalStorage } from "hooks/helpers/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setAnswers } from "redux/reducers/answers";
import { next } from "redux/reducers/navigation";
import { IClientDocument } from "types/client";

export const ReturnToQuestionnaire: FC<{currentReturn?: IClientDocument | null
}> = ({ currentReturn }) => {
    const { isMobile } = useContext(AppContext);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { currentDocumentYear } = useAppSelector(state => state.document);

    const [, setTotalProgress] = useLocalStorage<number>("new_questionnaire_total_progress", 0);

    const handleReturnToQuestionnaire = useCallback(() => {
        if (currentReturn?.answersOB) {
            dispatch(setAnswers(currentReturn.answersOB.answers || {}));
            setTotalProgress(currentReturn.answersOB.totalProgress);
            dispatch(next({ nextCategory: currentReturn.answersOB.currentCategory, nextStep: currentReturn.answersOB.currentGroupId, nextQuestion: 0 }));
        }
        navigate("/questionnaire");
    }, [currentReturn, setTotalProgress, navigate, dispatch]);

    return (
        <div className="return-to-questionnaire__container mt20" onClick={isMobile ? handleReturnToQuestionnaire : () => {}}>
            <div className="return-to-questionnaire__content">
                <div className="return-to-questionnaire__title">
                    {`Complete ${currentDocumentYear} Tax Return`}
                </div>
                <div className="return-to-questionnaire__text mt12">
                    {isMobile
                        ? "Click here to complete the remaining tax questions to submit your return."
                        : "Please complete the remaining tax questions to submit your return."
                    }
                </div>
            </div>
            {!isMobile && (
                <div className="return-to-questionnaire__button" onClick={handleReturnToQuestionnaire}>
                    Continue
                    <img className="ml6" src={arrowRight} alt="arrow right" />
                </div>
            )}
        </div>
    );
};
