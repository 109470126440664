
import { UK_ROUTES_NEW } from "constants/routes-new";

import { useState } from "react";

import ButtonFooterNew from "components/basic/Footer/ButtonFooterNew";
import LayoutNew from "components/basic/LayoutNew";
import Navigation from "components/basic/Navigation/Navigation";
import SignUpSectionNew from "components/common/SignUpSectionNew";

import HeroCallNew from "./components/HeroCallNew";
import PriceTablesNew from "./components/PriceTablesNew";

const PricesPageNew = () => {
    const [showButtonFooter, setShowButtonFooter] = useState(false);

    return (
        <LayoutNew>
            <Navigation items={[{
                title: "Home",
                url: UK_ROUTES_NEW.home,
            }, {
                title: "Pricing",
                url: UK_ROUTES_NEW.pricing,
            }]} />
            <div style={{ display: "flex", justifyContent: "center" }}>
                <HeroCallNew setShowButtonFooter={setShowButtonFooter}/>
            </div>
            <div style={{ backgroundColor: "#22562C", width: "100%" }}>
                <PriceTablesNew />
            </div>
            <div>
                <SignUpSectionNew />
            </div>
            <ButtonFooterNew showButtonFooter={showButtonFooter}/>
        </LayoutNew>
    );
};

export default PricesPageNew;
