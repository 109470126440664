import ReactMetaPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";
// @ts-ignore
import ReactSnapchatPixel from "react-snapchat-pixel";
// @ts-ignore
import TwitterPixel from "react-twitter-pixel";

export const initialiseAnalytics = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);
    }

    if (process.env.REACT_APP_GOOGLE_GTM_TAG) {
        TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_GTM_TAG });
    }

    if (process.env.REACT_APP_META_PIXEL_ID) {
        ReactMetaPixel.init(process.env.REACT_APP_META_PIXEL_ID);
        ReactMetaPixel.pageView();
        ReactMetaPixel.fbq("track", "ViewContent");
    }

    if (process.env.REACT_APP_SNAPCHAT_PIXEL_ID) {
        ReactSnapchatPixel.init(process.env.REACT_APP_SNAPCHAT_PIXEL_ID);
        ReactSnapchatPixel.pageView();
    }

    if (process.env.REACT_APP_TWITTER_PIXEL_ID) {
        TwitterPixel.init({
            pixelId: process.env.REACT_APP_TWITTER_PIXEL_ID,
        });
        process.env.REACT_APP_TWITTER_PAGE_VIEW_EVENT_CODE && TwitterPixel.twq("event", process.env.REACT_APP_TWITTER_PAGE_VIEW_EVENT_CODE);
    }

    if (process.env.REACT_APP_HOTJAR_ID) {
        hotjar.initialize(Number(process.env.REACT_APP_HOTJAR_ID), 6);
    }
};
