import "../../../../../styles/questionnaire/taxSummary.less";
import { FC, useEffect, useState } from "react";

import { Button, Card, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import blueInfoIcon from "assets/images/new-questionnaire/blue-info.svg";
import headsetIcon from "assets/images/new-questionnaire/headset.svg";
import { useAppSelector } from "redux/hooks";
import { useGetTaxCalculationsMutation } from "services/client";
import { IUser } from "types/auth";
import { logError } from "utils/sentry";

import TaxSummaryContent from "./TaxSummaryContent";
import PromptModal from "../Shared/PromptModal";

type TaxSummaryProps = {
    user: IUser;
    handleSubmit: () => void;
}

export const TaxSummary: FC<TaxSummaryProps> = ({
    user, handleSubmit }) => {
    const { currentDocument } = useAppSelector(state => state.document);

    const [getGetTaxCalculations] = useGetTaxCalculationsMutation();
    const [checked, setChecked] = useState<boolean>(false);
    const [taxData, setTaxData] = useState<any | null>(null);

    const handleCheckboxChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleIconClick = () => {
        setIsModalOpen(true);
    };
    const handleModalClose = () => {
        setIsModalOpen(false);
    };
    const [isVisible, setIsVisible] = useState(false);

    const handleClick = () => {
        setIsVisible(prev => !prev);
    };
    useEffect(() => {
        const fetchTaxCalculations = async () => {
            if (!user?.id || !user.documents?.length) return;
            try {
                const response = await getGetTaxCalculations({
                    clientId: user.id,
                    documentId: currentDocument.id,
                }).unwrap();
                const breakdown = response.breakdown;
                setTaxData(breakdown);
            } catch (error) {
                const errorContext = {
                    clientId: user?.id,
                    message: "Failed to fetch tax calculations",
                    function: "fetchTaxCalculations",
                };
                logError(error, errorContext);
            }
        };

        fetchTaxCalculations();
    }, [getGetTaxCalculations, user, currentDocument]);

    return (
        <div>
            <TaxSummaryContent user={user} taxData={taxData} isVisible={isVisible} handleClick={handleClick}></TaxSummaryContent>
            <Card>
                <Checkbox checked={checked} onChange={handleCheckboxChange}>
                    <div className="tax-summary__confirm-box">
                        <div>I confirm that the information I have provided is accurate, up to date, and complete to the best of my knowledge. I understand that this information will be submitted to HMRC as part of my tax return.  <span className="tax-summary__purple_link" onClick={handleIconClick}>
                    Learn More
                        </span></div>
                    </div>
                </Checkbox>
                {!checked && <div className="tax-summary__blue-info"><img src={blueInfoIcon} alt="info box" style={{ cursor: "pointer" }}/>To edit your answers, click the relevant sections (e.g. Personal Info, CIS) in the left-side menu.</div>}
                <div className="payment-details__bank-details__button-container">
                    <Button
                        type="primary"
                        className="tax-summary__desktop-form-secondary-button"
                    >
                        <a href="https://taxzapuk.setmore.com/" target="_blank" rel="noopener noreferrer">
                            <img src={headsetIcon} alt="Speak with a Tax Advisor" /> Speak with a Tax Advisor
                        </a>
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="tax-calculator__desktop-form-primary-button"
                        onClick={handleSubmit}
                        disabled={!checked}
                        style={{
                            backgroundColor: !checked ? "#EFE8FF" : "",
                            borderColor: !checked ? "#EFE8FF" : "",
                            color: "white",
                        }}
                    > Confirm & Submit
                    </Button>
                </div>
            </Card>
            <PromptModal
                isOpen={isModalOpen}
                closeModal={handleModalClose}
                closeButtonStyle={{ display: "none" }}
                data={{
                    title: "",
                    text: "<div style='font-weight: 600; color: #000; font-size: 18px; text-align: center; margin-bottom: 10px; margin-top: 10px'>The Legal Disclaimer</div>" +
                        "<div style='text-align: left;color: #000;font-size: 16px;'>By checking this box, you acknowledge and agree that:\n\n</div>" +
                        "<div style='text-align: left;color: #000;font-size: 16px;'>    1.  The tax return will be prepared and submitted to HMRC based solely on the information you have provided.\n\n</div>" +
                        "<div style='text-align: left;color: #000;font-size: 16px;'>    2.  You are responsible for ensuring the accuracy and completeness of the information you submit.\n\n</div>" +
                        "<div style='text-align: left;color: #000;font-size: 16px;'>        3.  Any discrepancies or omissions in the provided information could result in penalties or legal action from HMRC, and you accept full responsibility for any such consequences\n\n</div>" +
                        "<div style='text-align: left;color: #000;font-size: 16px;'>    4.  Our role is limited to submitting the information on your behalf, and we are not responsible for verifying its accuracy.\n\n</div>",
                }}
                handlePrev={() => {
                }}
            />
        </div>
    );
};
