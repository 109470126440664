import { Button } from "antd";
import avatarPurple from "assets/images/header/login-avatar-purple.svg";
import { useNavigate } from "react-router-dom";

const LoginButtonNew = ({
    label = "Login",
}: {
    label?: string;
}) => {
    const navigate = useNavigate();
    return <div className={"horizontal-left mr15"} onClick={() => {
        navigate("/auth");
    }}>
        <Button
            shape={"round"}
            size="large"
            type="primary"
            className={"uk-rounded-button-login"}
            onClick={() => {
                navigate("/auth");
            }}
        >
            {label}
        </Button>

        <div className={"uk-rounded-button-login-arrow"}>
            <img src={avatarPurple} width={15} height={15} alt={"start button"}/>
        </div>
    </div>;
};

export default LoginButtonNew;
