import { useContext, useEffect, useState } from "react";

import { Steps } from "antd";
import { AppContext } from "App";
import hmrcCodeDefaulted from "assets/images/dashboard/hmrc-code-defaulted.svg";
import hmrcCodeExpired from "assets/images/dashboard/hmrc-code-expired.svg";
import hmrcCodeSelected from "assets/images/dashboard/hmrc-code-selected.svg";
import prepTaxDefaulted from "assets/images/dashboard/prep-tax-defaulted.svg";
import stage1Defaulted from "assets/images/dashboard/stage1-default.svg";
import stage1Selected from "assets/images/dashboard/stage1-select.svg";
import stage2Defaulted from "assets/images/dashboard/stage2-default.svg";
import stage2Selected from "assets/images/dashboard/stage2-select.svg";
import stage3Defaulted from "assets/images/dashboard/stage3-default.svg";
import stage3Selected from "assets/images/dashboard/stage3-select.svg";
import stage4Defaulted from "assets/images/dashboard/stage4-default.svg";
import taxReturnedSelected from "assets/images/dashboard/tax-returned-selected.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { next } from "redux/reducers/navigation";
import { IUser } from "types/auth";

import { categories } from "../../../NewQuestionnaire/constants/questions";

type DashboardProps = {
    user: IUser;
    clientProgress: string;
};

export default function Dashboard({ user, clientProgress }: DashboardProps) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [currentStage, setCurrentStage] = useState(0);
    const { isMobile } = useContext(AppContext);
    const { currentCategory } = useAppSelector(state => state.navigation);
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const getDescriptionForStep = (clientProgress: string, step: number) => {
        if (clientProgress === "submittedByClient" || clientProgress === "creationError") {
            if (step === 3) return "Enter the agent activation code sent by HMRC to your address via post.";
        }
        if (clientProgress === "readyForReview") {
            if (step === 3) return "Agent activation code successfully entered.";
        }
        if (clientProgress === "codeExpired") {
            if (step === 3) return "HMRC code has expired. Please request a new code to continue.";
        }
    };
    const getButtonTextForStep = (clientProgress: string, step: number) => {
        if (clientProgress === "taxReturnCreated") {
            if (step === 1) return "Select Option ";
            if (step === 2) return "Start";
            if (step === 3) return "Start";
            if (step === 4) return "Submit";
        }
        if (clientProgress === "selectedTaxDetailSource") {
            if (step === 1) return "Update";
            if (step === 2) return "Start";
            if (step === 3) return "Start";
            if (step === 4) return "Submit";
        }
        if (clientProgress === "personalDetailsStarted") {
            if (step === 1) return "Update";
            if (step === 2) return "Continue";
            if (step === 3) return "Start";
            if (step === 4) return "Submit";
        }
        if (clientProgress === "personalInfoCompleted") {
            if (step === 1) return "Update";
            if (step === 2) return "Update";
            if (step === 3) return "Continue";
            if (step === 4) return "Submit";
        }
        if (clientProgress === "readyForClientSubmission") {
            if (step === 1) return "Update";
            if (step === 2) return "Update";
            if (step === 3) return "Update";
            if (step === 4) return "Submit";
        }
        if (clientProgress === "submittedByClient") {
            if (step === 3) return "Update";
        }
        if (clientProgress === "creationError") {
            if (step === 3) return "Enter Code";
        }
        if (clientProgress === "codeExpired") {
            if (step === 3) return "Request New Code";
        }
    };

    const getButtonClassForStep = (clientProgress: string, step: number) => {
        let buttonClass = "";
        let textClass = "";
        if (clientProgress === "taxReturnCreated") {
            if (step === 1) {
                buttonClass = "dashboard__button-purple";
                textClass = "dashboard__button-text";
            }
            if (step === 2 || step === 3 || step === 4) {
                buttonClass = "dashboard__button-light-purple disabled";
                textClass = "dashboard__button-text";
            }
        }
        if (clientProgress === "selectedTaxDetailSource") {
            if (step === 1) {
                buttonClass = "dashboard__button-grey";
                textClass = "dashboard__button-text-black";
            }
            if (step === 2) {
                buttonClass = "dashboard__button-purple";
                textClass = "dashboard__button-text";
            }
            if (step === 3 || step === 4) {
                buttonClass = "dashboard__button-light-purple disabled";
                textClass = "dashboard__button-text";
            }
        }
        if (clientProgress === "personalDetailsStarted") {
            if (step === 1) {
                buttonClass = "dashboard__button-grey";
                textClass = "dashboard__button-text-black";
            }
            if (step === 2) {
                buttonClass = "dashboard__button-purple";
                textClass = "dashboard__button-text";
            }
            if (step === 3 || step === 4) {
                buttonClass = "dashboard__button-light-purple disabled";
                textClass = "dashboard__button-text";
            }
        }
        if (clientProgress === "personalInfoCompleted") {
            if (step === 1 || step === 2) {
                buttonClass = "dashboard__button-grey";
                textClass = "dashboard__button-text-black";
            }
            if (step === 3) {
                buttonClass = "dashboard__button-purple";
                textClass = "dashboard__button-text";
            }
            if (step === 4) {
                buttonClass = "dashboard__button-light-purple disabled";
                textClass = "dashboard__button-text";
            }
        }
        if (clientProgress === "readyForClientSubmission") {
            if (step === 1 || step === 2 || step === 3) {
                buttonClass = "dashboard__button-grey";
                textClass = "dashboard__button-text-black";
            }
            if (step === 4) {
                buttonClass = "dashboard__button-purple";
                textClass = "dashboard__button-text";
            }
        }
        if (clientProgress === "submittedByClient" || clientProgress === "creationError" || clientProgress === "codeExpired") {
            if (step === 3) return "dashboard__button-purple";
        }
        return `${buttonClass} ${textClass}`;
    };
    const isButtonDisabled = (clientProgress: string, step: number) => {
        const buttonClass = getButtonClassForStep(clientProgress, step);
        return buttonClass?.includes("dashboard__button-light-purple");
    };
    const bankingCategory = categories?.findIndex(category => category?.title === "Bank Account Connection");
    const personalInfoCategory = categories?.findIndex(category => category?.title === "Personal Info");
    const reviewCategory = categories?.findIndex(category => category?.title === "Review & Submit");
    const handleStepClick = (clientProgress: string, step: number) => {
        if (isButtonDisabled(clientProgress, step)) {
            return;
        } else {
            console.log("clientProgress", clientProgress);
            switch (clientProgress) {
            case "readyForClientSubmission":
                dispatch(next({ nextCategory: reviewCategory, nextStep: 0, nextQuestion: 0 }));
                break;
            case "personalInfoCompleted":
                dispatch(next({ nextCategory: currentCategory, nextStep: 0, nextQuestion: 0 }));
                break;
            case "personalDetailsStarted":
            case "selectedTaxDetailSource":
                dispatch(next({ nextCategory: personalInfoCategory, nextStep: 0, nextQuestion: 0 }));
                break;
            default:
                dispatch(next({ nextCategory: bankingCategory, nextStep: 1, nextQuestion: 0 }));
            }
            navigate("/questionnaire");
        }
    };

    const contentOne = [
        {
            title: "1. Choose How to Share Tax Details",
            description: (
                <div className="dashboard__content">
                    <div>Securely through your bank account or manually
                    </div>
                    <div onClick={() => handleStepClick(clientProgress, 1)}>
                        <div className={getButtonClassForStep(clientProgress, 1)}>
                            {getButtonTextForStep(clientProgress, 1)}
                        </div>
                    </div>
                </div>
            ),
            icon: <img className={`dashboard__image${isMobile ? "-mobile" : "-desktop"}`} src={clientProgress === "taxReturnCreated" ? stage1Defaulted : stage1Selected} alt="stage1 icon"/>,
        },
        {
            title: "2. Personal Info",
            description: (
                <div className="dashboard__content">
                    <div>Answer these introductory questions to help us personalise the rest.</div>
                    <div onClick={() => handleStepClick(clientProgress, 2)}>
                        <div className={getButtonClassForStep(clientProgress, 2)}>
                            {getButtonTextForStep(clientProgress, 2)}
                        </div>
                    </div>
                </div>
            ),
            icon: <img className={`dashboard__image${isMobile ? "-mobile" : "-desktop"}`} src={ clientProgress === "readyForClientSubmission" || clientProgress === "personalInfoCompleted" ? stage2Selected : stage2Defaulted } alt="stage2 icon"/>,
        },
        {
            title: "3. Tax Details",
            description: (
                <div className="dashboard__content">
                    <div>Confirm your income and expenses.</div>
                    <div onClick={() => handleStepClick(clientProgress, 3)}>
                        <div className={getButtonClassForStep(clientProgress, 3)}>
                            {getButtonTextForStep(clientProgress, 3)}
                        </div>
                    </div>
                </div>
            ),
            icon: <img className={`dashboard__image${isMobile ? "-mobile" : "-desktop"}`} src={clientProgress === "readyForClientSubmission" ? stage3Selected : stage3Defaulted} alt="stage3 icon"/>,
        },
        {
            title: "4. Review & Submit",
            description: (
                <div className="dashboard__content">
                    <div>Review and submit your tax return.
                    </div>
                    <div onClick={() => handleStepClick(clientProgress, 4)}>
                        <div className={getButtonClassForStep(clientProgress, 4)}>
                            {getButtonTextForStep(clientProgress, 4)}
                        </div>
                    </div>
                </div>
            ),
            icon: <img className={`dashboard__image${isMobile ? "-mobile" : "-desktop"}`} src={stage4Defaulted} alt="stage4 icon"/>,
        },
    ];

    const contentTwo = [
        {
            title: "1. Sign Up",
            description: (
                <div className="dashboard__content">
                    <div>Signed up with TaxZap successfully.
                    </div>
                </div>
            ),
            icon: <img className={`dashboard__image${isMobile ? "-mobile" : "-desktop"}`} src={stage2Selected} alt="stage1 icon"/>,
        },
        {
            title: "2. Submit Your Tax Return",
            description: (
                <div className="dashboard__content">
                    <div>Tax return successfully received.</div>
                </div>
            ),
            icon: <img className={`dashboard__image${isMobile ? "-mobile" : "-desktop"}`} src={taxReturnedSelected} alt="stage2 icon"/>,
        },
        {
            title: "3. Provide HMRC Code",
            description: (
                <div className="dashboard__content">
                    <div>{getDescriptionForStep(clientProgress, 3)}</div>
                    <a
                        href={clientProgress === "codeExpired" ? "mailto:support@taxzap.co.uk" : `${REACT_APP_BASE_URL}/uk/finish/code/${user.id.toString()}`}
                        className="dashboard__button-text ml6"
                    >
                        <div className={getButtonClassForStep(clientProgress, 3)}>
                            {getButtonTextForStep(clientProgress, 3)}
                        </div>
                    </a>
                </div>
            ),
            icon: <img className={`dashboard__image${isMobile ? "-mobile" : "-desktop"}`} src={clientProgress === "codeExpired" ? hmrcCodeExpired : clientProgress === "readyForReview" ? hmrcCodeSelected : hmrcCodeDefaulted} alt="stage3 icon"/>,
        },

        {
            title: "4. Wait for Us to Prep Your Tax Return",
            description: (
                <div className="dashboard__content">
                    <div>Please wait while we submit your tax return to HMRC. Please allow more time than usual in busy periods.
                    </div>
                </div>
            ),
            icon: <img className={`dashboard__image${isMobile ? "-mobile" : "-desktop"}`} src={prepTaxDefaulted} alt="stage4 icon"/>,
        },
    ];

    const getStepsForClient = (clientProgress: string) => {
        switch (clientProgress) {
        case "taxReturnCreated":
            return { items: contentOne, current: 0 };
        case "selectedTaxDetailSource":
            return { items: contentOne, current: 1 };
        case "personalDetailsStarted":
            return { items: contentOne, current: 0 };
        case "personalInfoCompleted":
            return { items: contentOne, current: 2 };
        case "readyForClientSubmission":
            return { items: contentOne, current: 3 };
        case "submittedByClient":
            return { items: contentTwo, current: 1 };
        case "creationError":
            return { items: contentTwo, current: 1 };
        case "codeExpired":
            return { items: contentTwo, current: 1 };
        case "readyForReview":
            return { items: contentTwo, current: 2 };
        default:
            return { items: contentOne, current: 1 };
        }
    };
    const { items, current } = getStepsForClient(clientProgress);

    useEffect(() => {
        setCurrentStage(current);
    }, [clientProgress, current]);

    return (
        <Steps className="dashboard__stepper" current={currentStage} labelPlacement="vertical" items={items} direction={isMobile ? "vertical" : "horizontal"}/>
    );
}
