import "styles/profile/paymentDetails.less";

import { useState, useEffect, useCallback, useContext } from "react";

import { Input, Form, Button, message, Modal } from "antd";
import { AppContext } from "App";
import cardInfo from "assets/images/new-questionnaire/card-explainer.svg";
import Spinner from "components/basic/Spinner/Spinner";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { IoIosInformationCircle } from "react-icons/io";
import { useUpdateUserPaymentDetailsMutation } from "services/auth";
import { IUser } from "types/auth";
import formatSortCode from "utils/format-sort-code";
import { logError } from "utils/sentry";
import { isNameValid } from "utils/validation";
import "styles/questionnaire/bankDetailsForm.less";

type valuesT = {
    fullName: string;
    bankAccountNumber: string;
    sortCode: string;
}

interface Account {
    fullName: string;
    bankAccountNumber?: string,
    sortCode?: string,
}

type BankDetailsFormPropsT = {
    user: IUser;
    advanceStep?: () => void;
    skipEnabled?: boolean;
};

const initialValues: valuesT = {
    fullName: "",
    bankAccountNumber: "",
    sortCode: "",
};

export default function BankDetailsForm({
    user,
    advanceStep,
    skipEnabled,
}: Readonly<BankDetailsFormPropsT>) {
    const [updateUserPaymentDetails, { isLoading: updatePaymentDetailsLoading }] =
    useUpdateUserPaymentDetailsMutation();

    const { isMobile } = useContext(AppContext);
    const {
        isOpened: isCardInfoOpened,
        close: handleCloseCardInfo,
        open: handleOpenCardInfo,
    } = useModalOpener();

    const [form] = Form.useForm<Account>();
    const [hasErrors, setHasErrors] = useState(false);

    const userHasBankDetails = user.paymentDetails?.bankAccountNumber && user.paymentDetails?.sortCode && user.paymentDetails?.fullName;

    const validateForeAndSureNameEntered = useCallback(async (name: string) => {
        const words = name.trim().split(/\s+/);
        return (words.length >= 2 && words[0] !== "" && words[1] !== "") ? Promise.resolve() : Promise.reject("Please enter your forename and surname");
    }, []);

    const validateNameForApostrophes = useCallback(async (value: string) => {
        if (!value) return Promise.resolve();
        if (isNameValid(value)) return Promise.resolve();
        return Promise.reject("Please enter your name without an apostrophe");
    }, []);

    useEffect(() => {
        form.setFieldsValue(user.paymentDetails || initialValues);
    }, [user.paymentDetails, form]);

    async function updateBankDetails(details: Account) {
        try {
            const response = await updateUserPaymentDetails({
                fullName: details.fullName!,
                bankAccountNumber: details.bankAccountNumber ?? "",
                sortCode: details.sortCode ?? "",
                address: user.paymentDetails?.address ?? "",
                bic: user.paymentDetails?.bic ?? "",
                iban: user.paymentDetails?.iban ?? "",
            });

            if ("data" in response && response.data.message === "Client Updated Successfully") {
                message.success("Payment details confirmed successfully");
                if (!advanceStep) return;
                advanceStep();
            } else {
                if ("error" in response && "status" in response.error) {
                    const contextInfo = {
                        response,
                        function: "updateBankDetails",
                        error: response.error,
                    };
                    logError(response.error.status, contextInfo);
                }
                message.error("Failed to update payment details. Please try again.");
            }
        } catch (error) {
            console.error("Error updating bank details:", error);
        }
    }

    async function handleContinueWithoutDetails() {
        try {
            await updateUserPaymentDetails({
                fullName: "",
                bankAccountNumber: "",
                sortCode: "",
                address: "",
                bic: "",
                iban: "",
            });
            if (!advanceStep) return;
            advanceStep();
        } catch (error) {
            console.error("Error updating bank details:", error);
        }
    }

    return (
        <div className="payment-details__form__container" style={isMobile ? { margin: "0 auto" } : {}}>
            <div className="mt0">
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={updateBankDetails}
                    onFieldsChange={(_, allFields) => {
                        const errorFields = allFields.filter(
                            field => !!field?.errors?.length
                        );
                        setHasErrors(errorFields.length > 0);
                    }}
                >
                    <div className="payment-details__form">
                        <Form.Item
                            className="payment-details__input-label no-required-mark"
                            name="fullName"
                            label="Account Holder’s Full Name"
                            validateTrigger="onBlur"
                            rules={[{ required: true, message: "Please input your full name" }, {
                                validator: (_, value) => validateNameForApostrophes(value),
                            }, {
                                validator: (_, value) => validateForeAndSureNameEntered(value),
                            }]}
                        >
                            <Input
                                className="payment-details__input payment-details__input-text"
                            />
                        </Form.Item>
                        <div />
                        <Form.Item
                            className="payment-details__input-label no-required-mark"
                            name="sortCode"
                            label="Sort Code"
                        >
                            <Form.Item
                                name="sortCode"
                                noStyle
                                validateTrigger="onBlur"
                                rules={[{
                                    required: true,
                                    message: "Please input your Sort Code",
                                    max: 8,
                                }]}
                            >
                                <Input
                                    className="payment-details__input payment-details__input-text"
                                    inputMode="numeric"
                                    maxLength={8}
                                    onChange={e => {
                                        const valueWithoutDashes = e.target.value.replace(/-/g, "");

                                        const newValue = formatSortCode(valueWithoutDashes);

                                        form.setFieldsValue({
                                            sortCode: newValue,
                                        });
                                    }}
                                />
                            </Form.Item>
                            <IoIosInformationCircle onClick={handleOpenCardInfo} size={24} color="#D7D7D7" className="payment-details__bank-details__info-button"/>
                        </Form.Item>
                        <Form.Item
                            className="payment-details__input-label no-required-mark"
                            name="bankAccountNumber"
                            label="Account Number"
                        >
                            <Form.Item
                                name="bankAccountNumber"
                                noStyle
                                validateTrigger="onBlur"
                                rules={[{
                                    required: true,
                                    message: "Please input your Account Number",
                                }]}
                            >
                                <Input
                                    className="payment-details__input payment-details__input-text"
                                    inputMode="numeric"
                                    maxLength={8}
                                />
                            </Form.Item>
                            <IoIosInformationCircle onClick={handleOpenCardInfo} size={24} color="#D7D7D7" className="payment-details__bank-details__info-button"/>
                        </Form.Item>
                    </div>
                    <div className="payment-details__bank-details__button-container" style={!skipEnabled ? { justifyContent: "center" } : {}}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="new-questionnaire__desktop-form-primary-button payment-details__desktop-form-button"
                            disabled={hasErrors}
                        >
                            {updatePaymentDetailsLoading ? <Spinner isLoading={updatePaymentDetailsLoading} /> : userHasBankDetails && skipEnabled ? "Continue" : "Save"}
                        </Button>
                        {!userHasBankDetails && skipEnabled && <Button
                            type="primary"
                            className="new-questionnaire__desktop-form-secondary-button payment-details__desktop-form-button"
                            onClick={handleContinueWithoutDetails}
                        >
                            Continue without Bank Details
                        </Button>}
                    </div>
                </Form>
            </div>
            <Modal
                open={isCardInfoOpened}
                destroyOnClose
                onCancel={handleCloseCardInfo}
                footer={null}
                centered
            >
                <div className={"p10 horizontal-center"}>
                    <div>
                        <div className="bold fs20 taCenter">
                            Sort Code & Account Number
                        </div>
                        <div className={"mb20 p20 horizontal-center bold grey-7-color"}>
                            You can find your sort code and account number on your debit card. If not there, check your
                            mobile banking app, bank statements, or cheques.
                        </div>
                        <div className={"horizontal-center"}>
                            <img
                                src={cardInfo}
                                alt="card"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
