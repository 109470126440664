import { useContext, useRef, useState, useEffect } from "react";

import { Row, Col, Typography } from "antd";
import { AppContext } from "App";
import clockIcon from "assets/images/blogs/clock 1.png";
import backIcon from "assets/images/blogs/g-chevron-left-grey.svg";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { IBlogUK } from "types/blogUK";
import { scrollToTop } from "utils/common";
import LazyLoadedImage from "utils/LazyLoadedImage";

const contentful = require("contentful");

const client = contentful.createClient({
    space: "w0hcz4e46cwo",
    accessToken: "OEsI882KvnNdRBQ5iQXclL8wRzJZHD65Ah99KvmsPLg",
});

const TaxzapBlog = () => {
    const { isMobile, maxWidth } = useContext(AppContext);
    const swiperRef = useRef<any>(null);

    const navigate = useNavigate();
    const handleMenuClick = (
        link: string
    ) => {
        if (link) {
            navigate(`/${link}`);
            scrollToTop();
        }
    };
    const handleNextSlide = () => {
        swiperRef.current?.slideNext();
    };

    const handlePrevSlide = () => {
        swiperRef.current?.slidePrev();
    };

    const [blogs, setBlogs] = useState<IBlogUK[]>([]);

    async function handleResponse(): Promise<IBlogUK[]> {
        try {
            const response = await client.getEntries({
                content_type: "newUkBlogPost",
            });
            const blogs = response.items;
            return blogs;
        } catch (error) {
            console.error("Error fetching blog posts:", error);
            return [];
        }
    }

    useEffect(() => {
        handleResponse().then(response => setBlogs(response));
    }, []);

    return (
        <>
            {isMobile
                ? <div style={{ maxWidth: maxWidth, padding: isMobile ? "24px 0px" : "24px" }}>
                    <Col>
                        <div style={{ padding: "0px 24px" }}>
                            <Row>
                                <span
                                    className="uk-blog-slogan-black"
                                >
                                    TaxZap&nbsp;Blog
                                </span>
                            </Row>
                            <div
                                className={"mt20 grey-6-color"} style={{ fontFamily: "Poppins", fontSize: "16px" }}>
                                    Check out the latest articles, helpful tax tips and more.
                            </div>
                        </div>
                        <div style={{ width: "375px", margin: "40px auto" }}>
                            <div className={"mt40"} style={{ width: "100%" }}>
                                <Swiper
                                    {...({ slidesPerView: 1.1 } as any)}
                                    onSwiper={(swiper: any) => { swiperRef.current = swiper; }}
                                >
                                    {blogs.map((blog, index) => (
                                        <SwiperSlide key={index} onClick={() => { handleMenuClick(blog.fields.link); }}>
                                            <div style={{ marginLeft: "20px" }}>
                                                <Row justify="space-between" style={{ width: "100%", alignItems: "center" }}>
                                                    <Col className="uk-blog-content-wrapper">
                                                        <Row className="uk-blog-description">
                                                            <LazyLoadedImage
                                                                className="uk-blog-image"
                                                                src={blog.fields.image.fields.file.url}
                                                                alt={blog.fields.alt}
                                                            />
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Typography.Paragraph className="uk-blog-content" ellipsis={{ rows: 4, expandable: true, symbol: "more" }}>
                                                            {blog.fields.content}
                                                        </Typography.Paragraph>
                                                    </Col>
                                                </Row>
                                                <Row justify="space-between" style={{ width: "100%", alignItems: "center" }}>
                                                    <Col className="blog-content-wrapper2" style={{ width: "100%", alignItems: "center" }}>
                                                        <Row className="uk-blog-description">
                                                            <LazyLoadedImage className="uk-blog-author-image" src={blog.fields.authorImage.fields.file.url} alt="author" width= "42x" height="42px" />
                                                            <Typography className="uk-blog-author-text">
                                                                {blog.fields.author}
                                                            </Typography>
                                                        </Row>
                                                        <Row>
                                                            <Typography className="uk-blog-author-subtext" style={{ marginLeft: "50px", marginTop: "-20px" }} >
                                                                {blog.fields.date}
                                                            </Typography>
                                                            <Typography className="uk-blog-author-subtext" style={{ marginLeft: "20px", marginTop: "-20px", display: "flex" }}>
                                                                <img src={clockIcon} alt="clock" width={16} height={16} style={{ marginRight: "6px", marginTop: "1px" }}/>
                                                                {blog.fields.time}
                                                            </Typography>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>

                        </div>
                    </Col>
                </div>
                : <div className="uk-blog-block horizontal-center" style={{ marginTop: -4 }}>
                    <div style={{ maxWidth: maxWidth, padding: "64px 100px" }}>
                        <div style={{ width: "1312px" }}>
                            <Row>
                                <span
                                    className="uk-blog-slogan-black"
                                >
                                        TaxZap&nbsp;Blog
                                </span>
                            </Row>
                            <Typography
                                className={"mt40 grey-6-color"} style={{ fontFamily: "Poppins", fontSize: "16px" }}
                            >
                                    Check out the latest articles, helpful tax tips, and more.
                            </Typography>
                        </div>
                        <Row justify="end" style={{ margin: "14px 125px 12px 0px" }}>
                            <div className="swiper-navigation-button" onClick={handlePrevSlide} style={{ backgroundColor: "#F7F7F7" }} >
                                <img src={backIcon} alt="back" width={20} height={20} />
                            </div>
                            <div className="swiper-navigation-button" onClick={handleNextSlide} style={{ marginLeft: "24px", backgroundColor: "#F7F7F7" }} >
                                <img src={backIcon} alt="back" width={20} height={20} style={{ transform: "rotate(180deg)" }}/>
                            </div>
                        </Row>
                        <Row className={"horizontal-center"} style={{ margin: "12px 0px 0px 0px" }}>
                            <div style={{ width: "1100px" }}>
                                <Swiper
                                    {...({ slidesPerView: 3 } as any)}
                                    onSwiper={(swiper: any) => { swiperRef.current = swiper; }}
                                >
                                    {blogs.map((blog, index) => (
                                        <SwiperSlide key={index}>
                                            <div style={{ width: "335px" }}>
                                                <Col onClick={() => { handleMenuClick(blog.fields.link); }} style={{ cursor: "pointer" }}>
                                                    <Col className="uk-blog-content-wrapper">
                                                        <Row className="uk-blog-description">
                                                            <LazyLoadedImage
                                                                className="uk-blog-image"
                                                                src={blog.fields.image.fields.file.url}
                                                                alt={blog.fields.alt}
                                                            />
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Typography.Paragraph className="uk-blog-content" ellipsis={{ rows: 4, expandable: true, symbol: "more" }}>
                                                            {blog.fields.content}
                                                        </Typography.Paragraph>
                                                    </Col>
                                                    <Row>
                                                        <Row className="uk-blog-description">
                                                            <LazyLoadedImage className="uk-blog-author-image" src={blog.fields.authorImage.fields.file.url} alt="author" width= "42px" height="42px" />
                                                            <Typography className="uk-blog-author-text">
                                                                {blog.fields.author}
                                                            </Typography>
                                                        </Row>
                                                        <Row>
                                                            <Typography className="uk-blog-author-subtext" style={{ marginLeft: "50px", marginTop: "-20px" }} >
                                                                {blog.fields.date}
                                                            </Typography>
                                                            <Typography className="uk-blog-author-subtext" style={{ marginLeft: "20px", marginTop: "-20px", display: "flex" }}>
                                                                <img src={clockIcon} alt="clock" width={16} height={16} style={{ marginRight: "6px" }}/>
                                                                {blog.fields.time}
                                                            </Typography>
                                                        </Row>
                                                    </Row>
                                                </Col>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>

                        </Row>
                    </div>
                </div>
            }
        </>
    );
};

export default TaxzapBlog;
