import { api } from "./connection/api";

const ID_PAL_ENDPOINT_PREFIX = "idpal";

interface IIdPalClientQuery {
    clientId?: string;
}

export const idPalApi = api.injectEndpoints({
    endpoints: builder => ({
        getUuid: builder.mutation({
            query: clientId => {
                const body: Object = {
                    "client_id": clientId,
                };

                return {
                    url: `${ID_PAL_ENDPOINT_PREFIX}/getUuid`,
                    method: "POST",
                    body: body,
                };
            },
        }),
        isIdVerificationCompleted: builder.query({
            query: (data: IIdPalClientQuery) => {
                return {
                    url: `${ID_PAL_ENDPOINT_PREFIX}/checkSubmissionCompleted?client_id=${data.clientId}`,
                };
            },
        }) }),
});

export const {
    useGetUuidMutation,
    endpoints: { isIdVerificationCompleted },
} = idPalApi;
