/* eslint-disable no-unused-vars */
import { convertArrayOfPrimitivesToDict } from "../utils/data";

export enum REGION {
    IE = "IE",
    UK = "UK",
}

export enum CLIENT_STATUSES {
    APPLICATION_SENT = "application-sent",
    ON_HOLD = "on-hold",
    PROCESSED_YOUR_RETURN= "processed-your-return",
    RETURN_OUTCOME = "return-outcome",
    APPLICATION_CLOSED = "application-closed",
}

export enum MAIN_STATUSES {
    ID_VERIFICATION = "id-verification",
}

export const ERROR_STATUSES = [
    "awaiting-questionnaire",
    "ready-for-transfer-failed",
    "creation-error",
    "owe-tax",
    "fee-requested",
    "info-requested",
    "info-requested-cis-deduction-statement",
    "id-verification-fraud-check",
    "id-verification-failed",
    "id-verification-time-expired",
    "info-requested-p60",
    "agent-link-removed",
    "owe-penalty",
    "agent-link-creation-error",
    "payment-link-expired",
] as const;

export const SECONDARY_STATUSES = [
    "agent-link-creation",
    "awaiting-agent-link-code",
    "awaiting-code-submission",
    "ready-for-review",
    "submitted-to-hmrc",
    "awaiting-id-verification",
    "bank-details-requested",
    "awaiting-bank-details-confirm",
    "ready-for-transfer-express",
    "ready-for-transfer-standard",
    "payment-complete",
    "other-income",
    "no-refund-due",
    "awaiting-agent-link-mature",
    "previously-submitted",
    "reduced-fee-submitted-to-hmrc",
    "client-not-converted",
    "custom-fee-submitted-to-hmrc",
    "appeal-fee-submitted-to-hmrc",
    ...ERROR_STATUSES,
] as const;

export const SECONDARY_STATUSES_DICT = convertArrayOfPrimitivesToDict(SECONDARY_STATUSES);

export const DETAILS_TYPE = ["account", "iban"] as const;

export const DETAILS_TYPE_DICT = convertArrayOfPrimitivesToDict(DETAILS_TYPE);
