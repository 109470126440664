import { PriceTableContent, RowContent, priceTableData } from "constants/pricesNew";

import { useContext } from "react";

import { Typography, Divider } from "antd";
import { AppContext } from "App";
import PricingCheckmark from "assets/images/checked_1.svg";
import StartButtonNew from "components/basic/StartButtonNew";

const PriceTableRow = ({ text }: RowContent) => {
    return <div style={{ display: "flex", gap: "10px" }}>
        <div><img src={PricingCheckmark} alt="checkmark"/></div>
        <Typography style={{ fontSize: "16px" }}>{text}</Typography>
    </div>;
};

const PriceTable = ({ category, price, priceSuperScript, priceSubScript, rows, isAvailable }: PriceTableContent) => {
    const { isMobile } = useContext(AppContext);

    return (
        <div style={{
            borderRadius: "32px",
            display: "flex",
            flexDirection: "column",
            width: isMobile ? undefined : "25%",
            backgroundColor: "white",
            height: "fit-content",
            minWidth: "320px",
        }}>
            {!isAvailable && <Typography style={{ fontSize: "16px", fontWeight: "bolder", marginLeft: "auto", padding: "20px", backgroundColor: "#fff600", borderRadius: "0 32px 0 32px" }}>Coming soon</Typography>}
            <div style={{
                padding: isMobile ? "20px" : "40px",
                gap: "40px",
                display: "flex",
                flexDirection: "column",
            }}>
                <div style={{ display: "flex", flexDirection: "column", maxWidth: "320px" }}>
                    <Typography style={{ fontSize: "16px", marginBottom: "10px" }}>{category}</Typography>
                    <Typography.Text style={{ fontSize: "51px", fontWeight: "bolder", color: "#22562C", marginBottom: 0, paddingBottom: 0 }}>
                        {price}
                        <span style={{ fontSize: "12px", verticalAlign: "super" }}>
                            {priceSuperScript}
                        </span>
                        <p style={{ fontSize: "14px", color: "#22562C", fontWeight: "bolder", marginTop: 0, paddingTop: 0 }}>{priceSubScript}</p>
                    </Typography.Text>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "16px", maxWidth: "320px" }}>
                    {rows.map((row, index) => (
                        <div key={index}><PriceTableRow text={row.text}/><Divider style={{ margin: 0 }}/></div>
                    ))}
                </div>
                <StartButtonNew label={ isAvailable ? "Start now" : "Join waitlist"}/>
            </div>
        </div>
    );
};

const PriceTables = () => {
    const { isMobile } = useContext(AppContext);

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "80px",
            padding: isMobile ? "80px 24px" : "40px",
            backgroundColor: "#22562C",
            flexDirection: isMobile ? "column" : "row",
        }}>
            {priceTableData.map((table, index) => <PriceTable key={index} {...table} />)}
        </div>
    );
};

export default PriceTables;
