import * as Sentry from "@sentry/react";

export const logError = (error: any, context?: Record<string, any>) => {
    let errorToCapture: Error;
    const extraData: Record<string, any> = { ...context, timestamp: new Date().toISOString() };

    if (error instanceof Error) {
        errorToCapture = error;
    } else if (typeof error === "object" && error !== null) {
        errorToCapture = new Error(`Error: ${error.status || "Unknown Status"} : ${JSON.stringify(error)}`);
        extraData.originalError = error;

        // Preserve stack trace if available
        if (error.stack) {
            errorToCapture.stack = error.stack;
        }
    } else if (typeof error === "string") {
        errorToCapture = new Error(error);
    } else {
        errorToCapture = new Error("Unknown error occurred");
        extraData.originalError = error;
    }

    Sentry.captureException(errorToCapture, {
        extra: extraData,
        tags: { type: "error" },
    });
};

export const logMessage = (message: string) => {
    Sentry.captureMessage(message);
};
