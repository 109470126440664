import { FC, useCallback, useEffect, useState, useMemo } from "react";

import deleteIcon from "assets/images/new-questionnaire/employment/employment-delete.svg";
import editPencil from "assets/images/new-questionnaire/employment/employment-edit-pencil.svg";
import QuestionnaireButtonFooterV2 from "components/basic/Footer/QuestionnaireButtonFooterV2";
import ButtonLoading from "components/basic/Spinner/ButtonLoading";
import { useAppSelector } from "redux/hooks";
import { selectCurrentNavigation } from "redux/reducers/navigation";
import { ArrayAnswersType } from "types/questionnaire";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { checkDepends, formatAmountWithCommas, formatAnswers, getAnswerForItem } from "utils/new-questionnaire";

import { Question } from "../../constants/questions";
import QuestionsList from "../QuestionsList/QuestionsList";
import SelectManyOptionQuestion from "../SelectManyOptionQuestion/SelectManyOptionQuestion";

type ExpensesListQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: any, _questionType?: string) => void;
    setHideProfileContinue: (_value: boolean) => void;
    arrayAnswers: ArrayAnswersType["answers"];
};

const ExpensesListQuestion: FC<ExpensesListQuestionProps> = ({
    data,
    onAnswer,
    setHideProfileContinue,
    arrayAnswers,
}) => {
    const isKeyboardOpen = useDetectKeyboardOpen();

    const { stepLoading } = useAppSelector(selectCurrentNavigation);

    const [adding, setAdding] = useState(false);
    const [expensesListItems, setExpensesListItems] = useState([]);
    const [isEdit, setEdit] = useState<Record<string, boolean>>({});
    const [currentQuestion, setCurrentQuestion] = useState<Question>();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const currentQuestionAnswered = useMemo(() => {
        if (currentQuestion?.type === "questionsList") {
            return currentQuestion.questions?.every(subQuestion => {
                if (subQuestion.depend?.length) {
                    const categoryAnswers = (arrayAnswers);
                    return !checkDepends(subQuestion.depend, categoryAnswers);
                }
                return arrayAnswers.some(answer => answer.question === subQuestion.question);
            });
        }

        return !!arrayAnswers.find(q => q.question === currentQuestion?.question)?.answer;
    }, [arrayAnswers, currentQuestion]);

    const handleAddButtonClick = useCallback(() => {
        setAdding(true);
        setHideProfileContinue(true);
    }, [setHideProfileContinue, setAdding]);

    const handleDeleteButtonClick = useCallback((item: string) => {
        const baseQuestion = data.questions?.find(q => q.baseForExpensesList);
        if (!baseQuestion) return;

        const baseQuestionAnswers = arrayAnswers.find(q => q.question === baseQuestion.question)?.answer || [];
        const updatedAnswers = baseQuestionAnswers.filter((answer: string) => answer !== item);

        onAnswer(baseQuestion.question, updatedAnswers, baseQuestion.type);

        data.questions?.forEach(question => {
            if (question.type === "questionsList" && question.questions) {
                question.questions.forEach(subQuestion => {
                    const hasDependencyOnDeletedItem = subQuestion.depend?.some(dep =>
                        dep.answer === item && dep.question === baseQuestion.question
                    );

                    if (hasDependencyOnDeletedItem) {
                        onAnswer(subQuestion.question, undefined, subQuestion.type);
                    }
                });
            }
        });

        setExpensesListItems(prev => prev.filter(expense => expense !== item));
    }, [data, arrayAnswers, onAnswer]);

    const handleExpensesListQuestionAnswer = useCallback((question: string, answer: any, questionType?: string) => {
        onAnswer(question, answer, questionType);
    }, [onAnswer]);

    const handleInputChange = useCallback((item: string, newValue: string) => {
        const baseQuestion = data.questions?.find(q => q.baseForExpensesList);
        if (!baseQuestion) return;

        let formattedValue = newValue.replace(/[^\d.]/g, "");
        if (formattedValue.includes(".")) {
            const [integer, decimal] = formattedValue.split(".");
            formattedValue = `${integer}.${decimal.slice(0, 2)}`;
        }

        formattedValue = formatAmountWithCommas(formattedValue);

        const subQuestion = data.questions
            ?.filter(q => q.type === "questionsList")
            .flatMap(q => q.questions || [])
            .find(sq => sq.depend?.some(dep => dep.answer === item && dep.question === baseQuestion.question));

        if (subQuestion) {
            onAnswer(subQuestion.question, formattedValue, subQuestion.type);
        }
    }, [data, onAnswer]);

    const handleExpensesListQuestionsContinue = useCallback(() => {
        const nextIndex = currentQuestionIndex + 1;
        const totalQuestions = data.questions?.length || 0;

        let foundValidQuestion = false;
        let currentIndex = nextIndex;

        const categoryAnswers = arrayAnswers;
        while (currentIndex < totalQuestions) {
            const nextQuestion = data.questions?.[currentIndex];
            if (nextQuestion && nextQuestion.depend) {
                if (!checkDepends(nextQuestion.depend, categoryAnswers)) {
                    currentIndex++;
                    continue;
                }
            }

            foundValidQuestion = true;
            break;
        }

        if (foundValidQuestion) {
            setCurrentQuestionIndex(currentIndex);
        } else {
            setCurrentQuestionIndex(0);
            setHideProfileContinue(false);
            setAdding(false);
        }
    }, [arrayAnswers, currentQuestionIndex, data.questions, setHideProfileContinue]);

    useEffect(() => {
        data.questions && setCurrentQuestion(data.questions[currentQuestionIndex]);
    }, [currentQuestionIndex, data.questions]);

    useEffect(() => {
        const baseQuestion = data.questions?.find(q => q.baseForExpensesList);
        const items = arrayAnswers?.find(q => q.question === baseQuestion?.question)?.answer || [];

        setExpensesListItems(items);
    }, [arrayAnswers, data]);

    // TODO, fix handlePrev on expenses list multi select question
    useEffect(() => {
        setHideProfileContinue(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="new-questionnaire__array-v2 mt10">
            {!adding && (<>
                {data.questionTitle && (
                    <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
                )}
                <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                    {data.question}
                </div>
                {data.infoText && (
                    <div className="new-questionnaire__array-v2-info-text">
                        {data.infoText}
                    </div>
                )}
                <div
                    className={"new-questionnaire__array-v2-add-button mt12"}
                    onClick={handleAddButtonClick}
                >
                    {data.addButtonText}
                </div>
                <div className="new-questionnaire__array-v2-items">
                    {expensesListItems?.map((item: string, index: number) => (
                        <div className="new-questionnaire__array-v2-item-wrapper" key={index}>
                            <div
                                key={index}
                                className={"new-questionnaire__array-v2-item"}
                            >
                                {item}
                                {isEdit[item] ? (
                                    <div className="new-questionnaire__amount-question-input-wrapper">
                                        <input
                                            className="new-questionnaire__edit-expense-input mr5"
                                            type="text"
                                            inputMode="decimal"
                                            pattern="[0-9]*"
                                            autoFocus={true}
                                            value={getAnswerForItem(item, data, arrayAnswers)}
                                            onChange={e => handleInputChange(item, e.target.value)}
                                            onBlur={() => setEdit(prev => ({ ...prev, [item]: false }))}
                                            style={{ backgroundColor: "transparent" }}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className="new-questionnaire__income-report-total-amount-wrapper"
                                        onClick={() => setEdit(prev => ({ ...prev, [item]: true }))}
                                    >
                                        <img width={24} src={editPencil} alt="edit" style={{ display: "block" }} />
                                        <div className="new-questionnaire__income-card-total-amount">
                                            £{getAnswerForItem(item, data, arrayAnswers) || "-"}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div style={{ padding: "10px" }} onClick={() => handleDeleteButtonClick(item)} className="pointer">
                                <img width={24} src={deleteIcon} alt="delete" style={{ display: "block" }}/>
                            </div>
                        </div>
                    ))}
                </div>
            </>)}
            {adding && currentQuestion && (
                <div>
                    {currentQuestion.type === "selectManyQuestion" && (
                        <SelectManyOptionQuestion
                            data={currentQuestion}
                            onAnswer={handleExpensesListQuestionAnswer}
                            initialValue={arrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                            categoryAnswers={arrayAnswers}
                            handlePrev={() => {}}
                        />
                    )}

                    {currentQuestion.type === "questionsList" && (
                        <QuestionsList
                            data={currentQuestion}
                            onAnswer={handleExpensesListQuestionAnswer}
                            answers={arrayAnswers}
                            categoryAnswers={formatAnswers(arrayAnswers)}
                        />
                    )}
                    <div className={`${isKeyboardOpen ? "" : "mt120"}`}>
                        <QuestionnaireButtonFooterV2>
                            <div
                                className={`new-questionnaire__form-continue ${currentQuestionAnswered && !stepLoading ? "" : "disabled"} mt25`}
                                onClick={() => {
                                    if (!currentQuestionAnswered || stepLoading) return;
                                    handleExpensesListQuestionsContinue();
                                }}
                            >
                                {stepLoading ? <ButtonLoading/>
                                    : <span>{"Confirm"}</span>}
                            </div>
                        </QuestionnaireButtonFooterV2>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExpensesListQuestion;
