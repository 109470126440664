import "styles/alert.less";

import { Alert, Typography } from "antd";
import { IoIosClose } from "react-icons/io";
import { MdError } from "react-icons/md";
import { useAppDispatch } from "redux/hooks";
import { setOpenBankingError } from "redux/reducers/countries";

export default function GoCardlessErrorModal() {
    const dispatch = useAppDispatch();
    return (
        <Alert
            closable={true}
            className={"alert_div"}
            type="error"
            showIcon={true}
            icon={
                <div className={"alert_icon"}>
                    <MdError size={40} />
                </div>}
            onClose={() => { dispatch(setOpenBankingError(false)); }}
            closeIcon={
                <IoIosClose size={40} className={"alert_close_icon"} />
            }
            description={
                <div>
                    <Typography className={"alert_text"}>
                        Couldn't connect your account. Please enter your bank details manually.
                    </Typography>
                </div>
            } />
    );
}
