import { FC, useCallback, useEffect, useState, useMemo } from "react";

import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import deleteIcon from "assets/images/new-questionnaire/employment/employment-delete.svg";
import editPencil from "assets/images/new-questionnaire/employment/employment-edit-pencil.svg";
import QuestionnaireButtonFooterV2 from "components/basic/Footer/QuestionnaireButtonFooterV2";
import ButtonLoading from "components/basic/Spinner/ButtonLoading";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { useAppDispatch } from "redux/hooks";
import { selectCurrentNavigation, setCurrentArrayQuestionIndex, setStepLoading } from "redux/reducers/navigation";
import { IUser } from "types/auth";
import { ArrayAnswersType } from "types/questionnaire";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { findAndSetArrayNext } from "utils/navigation";
import { checkDepends, formatAnswers, getNameFromQuestionAnswer, getOrdinalSuffix } from "utils/new-questionnaire";

import { useAppSelector } from "../../../../../redux/hooks";
import { setIsEditingItem, setArrayItemAnswers, setArrayItemQuestions, selectArrayQuestionState } from "../../../../../redux/reducers/arrayQuestion";
import { Question } from "../../constants/questions";
import { QuestionType } from "../../enums/enum";
import AmountQuestion from "../AmountQuestion/AmountQuestion";
import ArrayQuestion from "../ArrayQuestion/ArrayQuestion";
import CardQuestion from "../CardArrayQuestion/CardArrayQuestion";
import DateQuestion from "../DateQuestion/DateQuestion";
import ObjectQuestion from "../ObjectQuestion/ObjectQuestion";
import PercentageQuestion from "../PercentageQuestion/PercentageQuestion";
import QuestionsList from "../QuestionsList/QuestionsList";
import SelectManyOptionQuestion from "../SelectManyOptionQuestion/SelectManyOptionQuestion";
import SelectOneIconQuestion from "../SelectOneIconQuestion/SelectOneIconQuestion";
import SelectOneOptionQuestion from "../SelectOneOptionQuestion/SelectOneOptionQuestion";
import SelectOneOptionQuestionV2 from "../SelectOneOptionQuestionV2/SelectOneOptionQuestionV2";
import SelectOneTilesQuestion from "../SelectOneTilesQuestion/SelectOneTilesQuestion";
import DeleteModal from "../Shared/DeleteModal";
import TextQuestion from "../TextQuestion/TextQuestion";

type ArrayQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: any) => void;
    initialValue?: any;
    setHideContinue: (_value: boolean) => void;
    setHideTitle: (_value: boolean) => void;
    setContinueDisabled: (_value: boolean) => void;
    user: IUser;
};

const ArrayQuestionV3: FC<ArrayQuestionProps> = (
    {
        data,
        onAnswer,
        initialValue = [],
        setHideContinue,
        setHideTitle,
        setContinueDisabled,
        user,
    }) => {
    const isKeyboardOpen = useDetectKeyboardOpen();
    const dispatch = useAppDispatch();

    const [arrayItemsNames, setItemsNames] = useState<string[]>([]);
    const [currentItemName, setCurrentItemName] = useState<string>("");
    const [continueButtonText, setContinueButtonText] = useState<string>("Continue");
    const [subTitle, setSubTitle] = useState("");
    const [editingIndex, setEditingIndex] = useState<number>(0);
    const [deletionIndex, setDeletionIndex] = useState<number | null>(null);
    const [currentQuestion, setCurrentQuestion] = useState<Question>();
    const [hideItemContinue, setHideItemContinue] = useState(false);
    const [itemContinueDisabled, setItemContinueDisabled] = useState(false);

    const { isEditingItem, arrayAnswers } = useAppSelector(selectArrayQuestionState);
    const { currentArrayQuestionIndex, stepLoading } = useAppSelector(selectCurrentNavigation);

    const {
        isOpened: isDeletionModalOpened,
        open: handleDeletionModalOpen,
        close: handleDeletionModalClose,
    } = useModalOpener();

    const currentQuestionAnswered = useMemo(() => {
        if (!currentQuestion) return false;
        const { type, questions, question, answerRequired, isItemName } = currentQuestion;
        if (type === QuestionType.questionsList) {
            const everySubQuestionAnswered = questions?.every(subQuestion => {
                if (subQuestion.depend?.length) {
                    const categoryAnswers = formatAnswers(arrayAnswers);
                    const dependSatisfied = checkDepends(subQuestion.depend, categoryAnswers);
                    return dependSatisfied && categoryAnswers[subQuestion.question];
                }
                return arrayAnswers.some(answer => answer.question === subQuestion.question);
            });
            return everySubQuestionAnswered;
        }
        if (isItemName) {
            const isDuplicate = arrayItemsNames
                .filter(name => name !== currentItemName)
                .some(name => name.trim().toLowerCase() === currentItemName.trim().toLowerCase());
            if (isDuplicate) return false;
        }
        if (answerRequired) {
            const answer = arrayAnswers.find(q => q.question === question)?.answer;
            return !!answer?.length;
        }
        if (!answerRequired) return true;
    }, [currentQuestion, arrayAnswers, arrayItemsNames, currentItemName]);

    const updateAnswers = useCallback((editIndex: number, arrayAns: ArrayAnswersType["answers"]) => {
        const isEdit = initialValue.length > 0 && editIndex < initialValue.length;

        const itemName = getNameFromQuestionAnswer(
            arrayAns,
            data.questions,
            isEdit ? editIndex : initialValue.length,
            data.itemName
        );

        const existingItem = isEdit ? initialValue[editIndex] : undefined;

        const updatedItem = {
            item: itemName,
            answers: arrayAns,
            isCompleted: existingItem?.isCompleted ?? false,
        };

        const updatedArray = isEdit
            ? initialValue.map((item: ArrayAnswersType, index: number) =>
                (index === editIndex ? updatedItem : item)
            )
            : [...initialValue, updatedItem];

        setItemsNames(prevNames => {
            const newNames = [...prevNames];
            if (isEdit) {
                newNames[editIndex] = itemName;
            } else {
                newNames.push(itemName);
            }
            return newNames;
        });

        onAnswer(data.question, updatedArray);
    }, [initialValue, data, onAnswer]);

    const handleAddButtonClick = useCallback(async () => {
        setHideContinue(true);
        setEditingIndex(initialValue.length ? initialValue.length : 0);
        setCurrentItemName("");
        dispatch(setIsEditingItem(true));
    }, [setHideContinue, initialValue.length, dispatch]);

    const handleEditButtonClick = useCallback((index: number) => {
        const itemToEdit = initialValue[index];
        dispatch(setArrayItemAnswers(itemToEdit.answers));
        setEditingIndex(index);
        setHideContinue(true);
        setCurrentItemName(arrayItemsNames[index] || itemToEdit.item);
        dispatch(setIsEditingItem(true));
    }, [arrayItemsNames, dispatch, initialValue, setHideContinue]);

    const confirmDeleteClick = useCallback(async () => {
        if (deletionIndex !== null) {
            const updatedArray = initialValue.filter((_: ArrayAnswersType, i: number) => i !== deletionIndex);
            const updatedNames = arrayItemsNames.filter((_, i) => i !== deletionIndex);

            setItemsNames(updatedNames);
            onAnswer(data.question, updatedArray.length ? updatedArray : undefined);
            handleDeletionModalClose();
        }
    }, [deletionIndex, initialValue, arrayItemsNames, data.question, onAnswer, handleDeletionModalClose]);

    const handleDeleteButtonClick = useCallback((index: number) => {
        setDeletionIndex(index);
        handleDeletionModalOpen();
    }, [handleDeletionModalOpen]);

    const handleDeletionCancel = useCallback(() => {
        setDeletionIndex(null);
        handleDeletionModalClose();
    }, [handleDeletionModalClose]);

    const handleArrayQuestionAnswer = useCallback((question: string, answer: any, questionType?: string) => {
        const updatedAnswers = [...arrayAnswers];

        if (currentQuestion?.type === QuestionType.cardArrayQuestion) {
            answer?.forEach((newAnswer: { question: string; answer: any; type: string, id: string}) => {
                if (!updatedAnswers.some(q => q.question === newAnswer.question)) {
                    updatedAnswers.push(newAnswer);
                } else {
                    updatedAnswers.forEach(q => {
                        if (q.question === newAnswer.question) {
                            q.answer = newAnswer.answer;
                        }
                    });
                    const formattedArrayAnswers = formatAnswers(updatedAnswers);
                    data.questions?.forEach((question: Question) => {
                        // If any previously answered arrayv3 questions depends on the new answer, check if the dependencies are met. If not, remove the question
                        if (question.depend && question.depend.some((depend: any) => depend.question === newAnswer.question) && !checkDepends(question.depend, formattedArrayAnswers)) {
                            const itemToRemove = updatedAnswers.findIndex(q => q.question === question.question);
                            if (itemToRemove !== -1) {
                                updatedAnswers.splice(itemToRemove, 1);
                            }
                        }
                    });
                }
            });
        } else {
            const existingIndex = updatedAnswers.findIndex(q => q.question === question);
            const updatedAnswer = { question, answer, type: questionType || currentQuestion?.type || "", id: currentQuestion?.id || "" };

            if (existingIndex > -1) {
                updatedAnswers[existingIndex] = updatedAnswer;
            } else {
                updatedAnswers.push(updatedAnswer);
            }
        }

        if (currentQuestion?.isItemName) {
            setCurrentItemName(answer);
        }

        if (currentQuestion?.id) {
            updateAnswers(editingIndex, updatedAnswers);
        }

        dispatch(setArrayItemAnswers(updatedAnswers));
        setContinueButtonText("Continue");
    }, [arrayAnswers, currentQuestion?.type, currentQuestion?.isItemName, currentQuestion?.id, dispatch, updateAnswers, editingIndex, data.questions, setCurrentItemName]);

    const handleArrayQuestionsContinue = useCallback(async (answers?: { question: string, answer: string, type: string }[]) => {
        dispatch(setStepLoading(true));
        const timerValue = data.questions?.[currentArrayQuestionIndex]?.type === QuestionType.cardArrayQuestion ? 1 : 800;

        const timer = setTimeout(async () => {
            if (data.questions) {
                updateAnswers(editingIndex, arrayAnswers);

                const { isArrayEnd } = findAndSetArrayNext(
                    currentArrayQuestionIndex,
                    data.questions,
                    answers ? [...answers, ...arrayAnswers] : arrayAnswers,
                    handleArrayQuestionAnswer,
                    dispatch
                );

                if (isArrayEnd) {
                    const updatedArray = initialValue.map((item: ArrayAnswersType, index: number) =>
                        (index === editingIndex
                            ? { ...item, isCompleted: true }
                            : item)
                    );

                    onAnswer(data.question, updatedArray);
                    setHideContinue(false);
                    setHideTitle(false);
                    setContinueButtonText("Continue");
                }
            }

            dispatch(setStepLoading(false));
        }, timerValue);

        return () => clearTimeout(timer);
    }, [dispatch, data.questions, data.question, currentArrayQuestionIndex, updateAnswers, editingIndex, arrayAnswers, handleArrayQuestionAnswer, initialValue, onAnswer, setHideContinue, setHideTitle]);

    useEffect(() => {
        if (data?.questions?.[currentArrayQuestionIndex]) {
            dispatch(setArrayItemQuestions(data?.questions || []));
            data.questions && setCurrentQuestion(data?.questions?.[currentArrayQuestionIndex]);
        }
    }, [currentArrayQuestionIndex, data, dispatch]);

    useEffect(() => {
        const itemIndex = (editingIndex ?? initialValue.length) + 1;
        const formattedItemTitle = currentItemName || `${getOrdinalSuffix(itemIndex)} ${data.itemTitle}`;

        if (isEditingItem) {
            if (data.itemTitle) {
                setSubTitle(formattedItemTitle);
                setHideTitle(true);
            }
            setHideContinue(true);
        } else {
            setSubTitle("");
            setHideTitle(false);
            setHideContinue(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentItemName, data.itemTitle, editingIndex, initialValue.length, isEditingItem]);

    useEffect(() => {
        if (initialValue?.length > 0 && arrayItemsNames.length === 0) {
            const initialNames = initialValue.map((item: ArrayAnswersType, index: number) =>
                getNameFromQuestionAnswer(
                    item.answers,
                    data.questions,
                    index,
                    data.itemName
                )
            );
            setItemsNames(initialNames);
        }
    }, [initialValue, data.questions, data.itemName, arrayItemsNames.length]);

    useEffect(() => {
        const arraysIncompleteItems = initialValue.some((item: ArrayAnswersType) => !item.isCompleted);
        setContinueDisabled(arraysIncompleteItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useEffect(() => {
        return () => {
            setHideContinue(false);
            setHideTitle(false);
            dispatch(setIsEditingItem(false));
            dispatch(setCurrentArrayQuestionIndex(0));
            dispatch(setArrayItemAnswers([]));
            dispatch(setArrayItemQuestions([]));
            setContinueDisabled(false);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={"new-questionnaire__array-v2 mt10"}>
            {subTitle && (
                <div className={"new-questionnaire__profile-name mt0"}>{subTitle}</div>
            )}
            {!isEditingItem && (<>
                {data.questionTitle && (
                    <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
                )}
                <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                    {data.question}
                </div>
                {data.infoText && (
                    <div className="new-questionnaire__array-v2-info-text">
                        {data.infoText}
                    </div>
                )}
                <div
                    className={"new-questionnaire__array-v2-add-button mt12"}
                    onClick={handleAddButtonClick}
                    role="button"
                >
                    {data.addButtonText}
                </div>
                <div className="new-questionnaire__array-v2-items">
                    {initialValue?.map((item: ArrayAnswersType, index: number) => {
                        const isIncomplete = !item.isCompleted;
                        return (
                            <div className="new-questionnaire__array-v2-item-wrapper" key={index}>
                                <div
                                    key={index}
                                    className={"new-questionnaire__array-v2-item"}
                                >
                                    {isIncomplete ? (
                                        <Tooltip title={`${data.itemName} is incomplete`}>
                                            <ExclamationCircleOutlined className="mr10" style={{ color: "orange" }} />
                                        </Tooltip>
                                    ) : (
                                        <CheckCircleOutlined className="primary-color mr10" />
                                    )}
                                    {arrayItemsNames[index]}
                                    <span style={{ padding: "10px" }} onClick={() => handleEditButtonClick(index)} className="pointer">
                                        <img width={24} src={editPencil} alt="edit" style={{ display: "block" }}/>
                                    </span>
                                </div>
                                <div style={{ padding: "10px" }} onClick={() => handleDeleteButtonClick(index)} className="pointer">
                                    <img width={24} src={deleteIcon} alt="delete" style={{ display: "block" }}/>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>)}
            {isEditingItem && currentQuestion && (
                <div className="w100">
                    {currentQuestion.type === QuestionType.dateQuestion && (
                        <DateQuestion
                            data={currentQuestion}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={arrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                        />
                    )}

                    {currentQuestion.type === QuestionType.amountQuestion && (
                        <AmountQuestion
                            data={currentQuestion}
                            handlePrev={() => {}}
                            onAnswer={handleArrayQuestionAnswer}
                            setContinueButtonText={setContinueButtonText}
                            initialValue={arrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                            arrayAnswers={arrayAnswers}
                        />
                    )}

                    {currentQuestion.type === QuestionType.percentageQuestion && (
                        <PercentageQuestion
                            data={currentQuestion}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={arrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                        />
                    )}

                    {currentQuestion.type === QuestionType.textQuestion && (
                        <TextQuestion
                            data={currentQuestion}
                            arrayItemsNames={arrayItemsNames}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={arrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                        />
                    )}

                    {currentQuestion.type === QuestionType.selectOneOptionQuestion && (
                        <SelectOneOptionQuestion
                            user={user}
                            data={currentQuestion}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={arrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                            handleContinue={handleArrayQuestionsContinue}
                        />
                    )}

                    {currentQuestion.type === QuestionType.selectOneOptionQuestionV2 && (
                        <SelectOneOptionQuestionV2
                            data={currentQuestion}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={arrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                            handlePrev={() => {}}
                            arrayAnswers={arrayAnswers}
                            handleContinue={handleArrayQuestionsContinue}
                        />
                    )}

                    {currentQuestion.type === QuestionType.selectOneIconQuestion && (
                        <SelectOneIconQuestion
                            data={currentQuestion}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={arrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                            handlePrev={() => {}}
                            setHideContinue={setHideItemContinue}
                            handleContinue={handleArrayQuestionsContinue}
                        />
                    )}

                    {currentQuestion.type === QuestionType.selectManyQuestion && (
                        <SelectManyOptionQuestion
                            data={currentQuestion}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={arrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                            categoryAnswers={formatAnswers(arrayAnswers)}
                            handlePrev={() => {}}
                        />
                    )}

                    {currentQuestion.type === QuestionType.selectOneTilesQuestion && (
                        <SelectOneTilesQuestion
                            data={currentQuestion}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={arrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                            categoryAnswers={formatAnswers(arrayAnswers)}
                            handlePrev={() => {}}
                        />
                    )}

                    {currentQuestion.type === QuestionType.objectQuestion && (
                        <ObjectQuestion
                            data={currentQuestion}
                            onAnswer={handleArrayQuestionAnswer}
                            handlePrev={() => {}}
                            initialValue={arrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                            categoryAnswers={formatAnswers(arrayAnswers)}
                        />
                    )}

                    {currentQuestion.type === QuestionType.arrayQuestion && (
                        <ArrayQuestion
                            user={user}
                            data={currentQuestion}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={arrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                            setHideProfileContinue={setHideItemContinue}
                            isProfileQuestion={true}
                            setContinueDisabled={setItemContinueDisabled}
                        />
                    )}

                    {currentQuestion.type === QuestionType.cardArrayQuestion && (
                        <CardQuestion
                            data={currentQuestion}
                            onAnswer={handleArrayQuestionAnswer}
                            initialValue={arrayAnswers.find(q => q.question === currentQuestion.question)?.answer}
                            handleContinue={ans => handleArrayQuestionsContinue(ans)}
                            arrayAnswers={arrayAnswers}
                            handlePrev={() => {}}
                            insertItemName={!!data.itemNameFromQuestionAnswer}
                            itemName={subTitle}
                        />
                    )}

                    {currentQuestion.type === QuestionType.questionsList && (
                        <QuestionsList
                            data={currentQuestion}
                            onAnswer={handleArrayQuestionAnswer}
                            answers={arrayAnswers}
                            categoryAnswers={formatAnswers(arrayAnswers)}
                        />
                    )}

                    {(currentQuestion.type !== QuestionType.cardArrayQuestion && !hideItemContinue) && <>
                        <div className={`${isKeyboardOpen ? "" : "mt120"}`}>
                            <QuestionnaireButtonFooterV2>
                                <div
                                    className={`new-questionnaire__form-continue ${currentQuestionAnswered && !stepLoading && !itemContinueDisabled ? "" : "disabled"} mt25`}
                                    role="button"
                                    onClick={() => {if (currentQuestionAnswered && !stepLoading && !itemContinueDisabled) handleArrayQuestionsContinue();}}>
                                    {stepLoading ? <ButtonLoading/>
                                        : <span>{continueButtonText}</span>}
                                </div>
                            </QuestionnaireButtonFooterV2>
                        </div>
                    </>}
                </div>
            )}
            {isDeletionModalOpened && (
                <DeleteModal
                    isOpen={isDeletionModalOpened}
                    onCancel={handleDeletionCancel}
                    onConfirm={confirmDeleteClick}
                    closeable
                    data={{
                        title: `Deleting this ${data.itemName}?`,
                        text: `Are you sure you want to delete this ${data.itemName}? This action cannot be undone.`,
                        confirmButtonText: `Yes, delete this ${data.itemName}`,
                    }}
                />
            )}
        </div>
    );
};

export default ArrayQuestionV3;
