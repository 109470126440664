import "styles/profile/idpal.less";
import "swiper/css";

import { useContext, useState } from "react";

import { Typography } from "antd";
import { AppContext } from "App";
import clockIcon from "assets/images/clock.svg";
import ProfileButtonFooter from "components/basic/Footer/ProfileButtonFooter";

import IdPalComponent from "./IdPalComponent";

interface IIdPalStartPageProps {
    advanceStep: () => void;
}
function IdPalStartPage({ advanceStep } : IIdPalStartPageProps) {
    const { isMobile } = useContext(AppContext);
    const [startVerification, setStartVerification] = useState(false);

    return (
        <div className="content idpal__content">
            {startVerification ? (
                <IdPalComponent advanceStep={advanceStep}/>
            ) : (
                <div>
                    {isMobile ? (
                        <>
                            <div style={{
                                background: "white",
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: "10px 0px 0px 20px",
                            }}>
                                <div className="idpal__content-heading">
                                    <img width={20} src={clockIcon} alt="Clock Icon"/>
                                    2 mins
                                </div>
                            </div>
                            <div style={{ width: "345px", background: "white" }}>
                                <img className="idpal__content-image" alt="phone scan" src="https://taxzap-images.s3.eu-west-1.amazonaws.com/id-pal/Screenshot-2023-10-10-at-17.11-1.webp"
                                />
                                <Typography className="idpal__content-title">
                                    Let’s Verify You Quickly
                                </Typography>
                                <Typography className="idpal__content-text" style={{ padding: "0px 20px 0px 20px" }}>
                                    To protect your account and prevent fraud, we need to quickly verify your identity.
                                </Typography>
                                <div className="mt20">
                                    <Typography className="idpal__content-terms-text">
                                        By clicking start you agree {" "}
                                        <a href="/uk/terms-and-conditions" className="email-link" target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }}>
                                            Terms & Conditions
                                        </a>
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", alignContent: "center" }}>
                                    <ProfileButtonFooter>
                                        <div
                                            className="new-questionnaire__form-continue"
                                            style={{ width: "335px" }}
                                            onClick={() => setStartVerification(true)}
                                        >
                                            Start
                                            <div className="arrow-container">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect y="20" width="20" height="20" transform="rotate(-90 0 20)" fill="url(#pattern0)"/>
                                                    <defs>
                                                        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                            <use xlinkHref="#image0_2599_109816" transform="scale(0.00666667)"/>
                                                        </pattern>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </ProfileButtonFooter>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div style={{ width: "690px", marginTop: "5px" }}>
                            <div className="idpal__content">
                                <div className="idpal__header">
                                    <Typography className="profile__content-heading">
                                        ID Verification
                                    </Typography>
                                    <Typography className="idpal__content-heading">
                                        <img width={16} height={17} src={clockIcon} alt="Clock Icon"/>
                                        2 mins
                                    </Typography>
                                </div>
                                <div className="idpal__content-description">
                                    <img className="idpal__content-image" alt="phone scan" style={{ width: "433.548px", height: "260px" }} src="https://taxzap-images.s3.eu-west-1.amazonaws.com/id-pal/Screenshot-2023-10-10-at-17.11-1.webp"
                                    />
                                    <Typography className="idpal__content-title">
                                        Let’s Verify You Quickly
                                    </Typography>
                                    <Typography className="idpal__content-text">
                                        To protect your account and prevent fraud, we need to quickly verify your
                                        identity.
                                    </Typography>
                                    <div
                                        className="new-questionnaire__form-continue"
                                        style={{ width: "335px" }}
                                        onClick={() => setStartVerification(true)}
                                    >
                                        Start
                                        <div className="arrow-container">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect y="20" width="20" height="20" transform="rotate(-90 0 20)" fill="url(#pattern0)"/>
                                                <defs>
                                                    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                        <use xlinkHref="#image0_2599_109816" transform="scale(0.00666667)"/>
                                                    </pattern>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                    <Typography className="idpal__content-terms-text">
                                        By clicking start you agree {" "}
                                        <a href="/uk/terms-and-conditions" className="email-link" target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }}>
                                            Terms & Conditions
                                        </a>
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default IdPalStartPage;
