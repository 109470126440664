import "styles/approvedRejectedExpenses.less";
import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";

import { Button, Col, Divider, Drawer, Empty, message, Row, Spin, Switch, Tabs, TabsProps, Flex, List } from "antd";
import editOutline from "assets/images/new-questionnaire/expenses/edit-outline.svg";
import QuestionnaireButtonFooterV2 from "components/basic/Footer/QuestionnaireButtonFooterV2";
import Spinner from "components/basic/Spinner/Spinner";
import { useUser } from "hooks/common/useUser";
import VirtualList from "rc-virtual-list";
import { useLazyGetJobQuery } from "services/jobs";
import { useBulkApproveOrRejectTransactionMutation, usePatchClientTransactionAndTaxProfileMutation } from "services/paymentBank";
import { AccountDetails } from "types/bankAccounts";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { formatAmountWithCommas } from "utils/new-questionnaire";
import { logError } from "utils/sentry";

import TotalBusinessExpensesCard from "./TotalBusinessExpensesCard";
import BulkUpdateTransactions from "./Transactions/BulkUpdateTransactions";
import QuickFilterTags from "./Transactions/QuickFilterTags";
import TransactionListItem from "./Transactions/TransactionListItem";
import TransactionFilters from "./Transactions/TransactionsFilters";
import TransactionsSearch from "./Transactions/TransactionsSearch";
import TransactionsSort from "./Transactions/TransactionsSort";
import UpdateTransaction from "./Transactions/UpdateTransaction";
import { ProfileType } from "../../enums/enum";
import CustomCheckbox from "../CustomCheckbox";
import { ExpenseCard } from "../SwipeableTransactionsCards/SwipeableTransactionsCards";
const ITEM_HEIGHT = 80;
const PAGE_SIZE = 20;

/* eslint-disable */
export enum RefinementType {
    filter = "filter",
    sort = "sort",
    search = "search",
    tab = "tab",
    page = "page",
}

export enum ApprovalType {
    approved = "approved",
    rejected = "rejected",
}

export enum Action {
    update = "update",
    add = "add",
    bulkUpdate = "bulkUpdate",
}
/* eslint-enable */

export type BulkSwipedObject = {
    swiped: {
        approved: boolean;
        rejected: boolean;
        byTaxProfile: {
            [key: string]: {
                approved: boolean;
                rejected: boolean;
            };
        };
    };
};

type ApprovedRejectedExpensesNewProps = {
    taxProfileId: string;
    taxProfileType: ProfileType;
    transactionsData: ExpenseCard[];
    transactionsCount: number;
    totalAggregatedAmount: number;
    onApplyFiltersSortSearch: (_filters: Record<string, any>) => void;
    connectedBankAccounts: AccountDetails[];
    transactionsCategories: string[];
    refetchTransactions: () => void;
    refetchClaimedBusinessExpenses: () => void;
    setShowBulkActionToolbar: (_show: boolean) => void;
    showBulkActionToolbar: boolean;
};

const ApprovedRejectedExpensesNew: React.FC<ApprovedRejectedExpensesNewProps> = ({
    taxProfileId,
    transactionsData,
    taxProfileType,
    totalAggregatedAmount,
    onApplyFiltersSortSearch,
    connectedBankAccounts,
    transactionsCategories,
    refetchTransactions,
    refetchClaimedBusinessExpenses,
    setShowBulkActionToolbar,
    showBulkActionToolbar,
    transactionsCount,
}) => {
    const { user } = useUser();
    const isKeyboardOpen = useDetectKeyboardOpen();

    const [patchClientTransactionAndTaxProfile] = usePatchClientTransactionAndTaxProfileMutation();
    const [bulkApproveOrRejectTransaction] = useBulkApproveOrRejectTransactionMutation();
    const [fetchJob] = useLazyGetJobQuery();

    const [action, setAction] = useState<Action>();
    const [approval, setApproval] = useState<string>();
    const [patchingTransactionIds, setPatchingTransactionIds] = useState<Set<string>>(new Set());
    const [virtualizedData, setVirtualizedData] = useState<ExpenseCard[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const listRef = useRef(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [transactionsToUpdate, setTransactionsToUpdate] = useState<ExpenseCard[]>([]);
    const [showMultiselect, setShowMultiselect] = useState<boolean>(false);
    const [isSticky, setIsSticky] = useState(false);
    const [selectedTransactionsIds, setSelectedTransactionsIds] = useState<Set<string>>(new Set());
    const [loading, setLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<Record<string, any>>({
        viewFilter: "unreviewed",
        amountRange: null,
        dateRange: null,
        account: [],
        category: null,
        searchQuery: "",
        sortBy: -1,
        page: currentPage,
        limit: PAGE_SIZE,
    });

    const filtersRef = useRef<HTMLDivElement | null>(null);

    const items: TabsProps["items"] = [
        { key: "unreviewed", label: "To Review", children: null },
        { key: ApprovalType.approved, label: "Business", children: null },
        { key: ApprovalType.rejected, label: "Personal", children: null },
    ];

    const totalAmountSelected = useMemo(() => {
        const totalAmount = virtualizedData.reduce((acc, transaction) => {
            if (selectedTransactionsIds.has(transaction.id)) {
                acc += Math.abs(transaction.totalAmount);
            }
            return acc;
        }, 0);

        return `£${formatAmountWithCommas(totalAmount.toFixed(2))}`;

        // eslint-disable-next-line
    }, [selectedTransactionsIds]);

    const transactionsWithNoCategory = useMemo(() => {
        const transactionsWithNoCategory = Array.from(selectedTransactionsIds)
            .map(transactionId => {
                return virtualizedData.find(tx => tx.id === transactionId);
            })
            .filter(transaction => transaction?.tags[0] === "No Category") || [];
        return transactionsWithNoCategory;
        // eslint-disable-next-line
    }, [selectedTransactionsIds]);

    const updateFilters = useCallback(
        (updatedFields: Partial<Record<string, any>>, type: RefinementType) => {
            if (type !== RefinementType.page) {
                setCurrentPage(1);
                setVirtualizedData([]);
                setSelectedTransactionsIds(new Set());
                setLoading(true);
            }

            setFilters(prevFilters => {
                const newFilters = { ...prevFilters, ...updatedFields };
                onApplyFiltersSortSearch(newFilters);
                return newFilters;
            });
        },
        [onApplyFiltersSortSearch]
    );

    const onChangeTabFilter = useCallback((key: string) => {
        setCurrentPage(1);
        window.scrollTo(0, 0);

        setTimeout(() => {
            updateFilters({ viewFilter: key, page: 1 }, RefinementType.tab);
        }, 0); // Slight delay ensures the state resets before filters apply
    }, [updateFilters]);

    const openDrawer = useCallback((transactions: ExpenseCard[]) => {
        setTransactionsToUpdate(transactions);
        setIsDrawerOpen(true);
    }, []);

    const closeDrawer = useCallback(() => {
        setIsDrawerOpen(false);
        setTransactionsToUpdate([]);
    }, []);

    const updateTransaction = useCallback(
        async (id: string, updateData: any) => {
            try {
                const index = virtualizedData.findIndex(tx => tx.id === id);
                if (index > -1) {
                    const newVirtualizedData = [...virtualizedData];
                    newVirtualizedData[index] = {
                        ...virtualizedData[index],
                        ...updateData,
                        totalAmount: updateData.clientUpdatedAmount ?? updateData.amount,
                        tags: [
                            updateData?.category?.byTaxProfileType[taxProfileType]?.clientUpdatedCategory ||
                            updateData?.category?.byTaxProfileType[taxProfileType]?.derivedCategory || "No Category",
                        ],

                    };
                    setVirtualizedData(newVirtualizedData);
                }
                await patchClientTransactionAndTaxProfile({ transactionId: id, taxProfileId, taxProfileType, updateData });
            } catch (error) {
                message.error("Failed to update transaction");
            } finally {
                refetchTransactions();
                refetchClaimedBusinessExpenses();
            }
        },
        [patchClientTransactionAndTaxProfile, refetchTransactions, refetchClaimedBusinessExpenses, taxProfileId, taxProfileType, virtualizedData]
    );

    const approveOrReject = useCallback(
        async (action: string, transaction: ExpenseCard, checkAreAllCategoriesDefined = true) => {
            setApproval(action);
            if (transaction.tags[0] === "No Category" && action === ApprovalType.approved && checkAreAllCategoriesDefined) {
                setAction(Action.update);
                openDrawer([transaction]);
                return;
            }
            const updateData = {
                swiped: {
                    ...transaction?.swiped,
                    amount: action === ApprovalType.approved ? Number(`-${Math.abs(Number(transaction.totalAmount))}`) : 0,
                    category: transaction?.category?.byTaxProfileType[taxProfileType]?.clientUpdatedCategory || transaction?.category?.byTaxProfileType[taxProfileType]?.derivedCategory,
                    approved: action === ApprovalType.approved,
                    rejected: action === ApprovalType.rejected,
                    byTaxProfile: {
                        [taxProfileId]: {
                            approved: action === ApprovalType.approved,
                            rejected: action === ApprovalType.rejected,
                        },
                    },
                },
            };
            setPatchingTransactionIds(prevIds => new Set(prevIds).add(transaction.id));
            await updateTransaction(transaction.id, updateData);
            setTimeout(() => {
                // Remove it from virtualizedData
                const index = virtualizedData.findIndex(tx => tx.id === transaction.id);
                if (index > -1) {
                    const newVirtualizedData = [...virtualizedData];
                    newVirtualizedData.splice(index, 1);
                    setVirtualizedData(newVirtualizedData);
                }
                setPatchingTransactionIds(new Set());
            }, 500);

            setSelectedTransactionsIds(new Set());
            setApproval(undefined);
        },
        [taxProfileId, updateTransaction, taxProfileType, openDrawer, virtualizedData]
    );

    const pollJobStatus = useCallback(async (jobId: string, retries = 0, maxRetries = 30, transactionIds: string[]) => {
        const handleFinalization = (transactionIds: string[]) => {
            const newVirtualizedData = virtualizedData.filter(
                transaction => !transactionIds.includes(transaction.id)
            );
            setVirtualizedData(newVirtualizedData);
            const newPatchingIds = new Set(patchingTransactionIds);
            transactionIds.forEach(id => newPatchingIds.delete(id));
            setPatchingTransactionIds(newPatchingIds);
            const newSelectedIds = new Set(selectedTransactionsIds);
            transactionIds.forEach(id => newSelectedIds.delete(id));
            setSelectedTransactionsIds(newSelectedIds);

            refetchTransactions();
            refetchClaimedBusinessExpenses();
            if (newSelectedIds.size === 0) {
                setApproval(undefined);
            }
        };

        try {
            if (retries >= maxRetries) {
                console.warn("Polling stopped: Max retries reached");
                handleFinalization(transactionIds);
                return;
            }

            const response = await fetchJob({ jobId });

            if (response.data?.progress === 100) {
                handleFinalization(transactionIds);
            } else {
                // Retry after 1 second
                setTimeout(() => pollJobStatus(jobId, retries + 1, maxRetries, transactionIds), 1000);
            }
        } catch (error:any) {
            logError(error, { jobId, retries, maxRetries, function: "pollJobStatus" });
            handleFinalization(transactionIds);
        }
    }, [fetchJob, refetchClaimedBusinessExpenses, refetchTransactions, virtualizedData, patchingTransactionIds, selectedTransactionsIds]);

    const bulkApproveOrReject = useCallback(
        // eslint-disable-next-line
        async(approval: string, selectTransactions: Set<string>, checkAreAllCategoriesDefined=true) => {
            const transactionIds = Array.from(selectTransactions);
            setApproval(approval);

            if (transactionsWithNoCategory?.length > 0 && approval === ApprovalType.approved && checkAreAllCategoriesDefined) {
                setAction(Action.bulkUpdate);
                openDrawer(transactionsWithNoCategory.filter((transaction): transaction is ExpenseCard => transaction !== undefined));
                return;
            }

            try {
                setPatchingTransactionIds(selectTransactions);
                const updateData: BulkSwipedObject = {
                    // We dont include swiped.amount and swiped.category here because if differs for each transaction so we will update it in the backend
                    swiped: {
                        approved: approval === ApprovalType.approved,
                        rejected: approval === ApprovalType.rejected,
                        byTaxProfile: {
                            [taxProfileId]: {
                                approved: approval === ApprovalType.approved,
                                rejected: approval === ApprovalType.rejected,
                            },
                        },
                    },
                };
                const response = await bulkApproveOrRejectTransaction({ transactionIds, taxProfileId, taxProfileType, updateData });

                if ("data" in response) {
                    const jobId = response.data?.id;
                    if (!jobId) {
                        console.error("Job ID is missing from response");
                        return;
                    }

                    await pollJobStatus(jobId, 0, 30, transactionIds);
                }
            } catch (error) {
                message.error("Failed to update transactions");
                logError(error, {
                    function: "bulkApproveOrReject",
                    taxProfileId,
                    taxProfileType,
                    transactionIds,
                    clientId: user?.id,

                });
            }
        },
        [bulkApproveOrRejectTransaction, openDrawer, taxProfileId, taxProfileType, pollJobStatus, transactionsWithNoCategory, user]
    );

    const toggleSelectAll = (e: any) => {
        const newSelectedTransactions = new Set<string>();
        const newSelectAll = e.target.checked;

        if (newSelectAll) {
            virtualizedData.forEach(transaction => {
                newSelectedTransactions.add(transaction.id);
            });
        }
        setSelectedTransactionsIds(newSelectedTransactions);
    };

    const toggleTransactionSelection = useCallback((transactionId: string) => {
        setSelectedTransactionsIds(prevSelected => {
            const newSelectedTransactions = new Set(prevSelected);
            if (newSelectedTransactions.has(transactionId)) {
                newSelectedTransactions.delete(transactionId);
            } else {
                newSelectedTransactions.add(transactionId);
            }
            return newSelectedTransactions;
        });
    }, []);

    useEffect(() => {
        onApplyFiltersSortSearch(filters);
    }, [filters, onApplyFiltersSortSearch]);

    useEffect(() => {
        if (selectedTransactionsIds.size >= 1 && showMultiselect) {
            setShowBulkActionToolbar(true);
        } else {
            setShowBulkActionToolbar(false);
        }
    }, [selectedTransactionsIds.size, setShowBulkActionToolbar, showMultiselect]);

    useEffect(() => {
        if (!transactionsData) {
            return;
        }
        setVirtualizedData(prevData => {
            const prevDataMap = new Map(prevData.map(tx => [tx.id, tx]));
            const newDataMap = new Map(transactionsData.map(tx => [tx.id, tx]));

            // Merge previous transactions with new ones
            const mergedMap = new Map([...Array.from(prevDataMap), ...Array.from(newDataMap)]);

            return Array.from(mergedMap.values()); // Convert Map back to array
        });

        setLoading(false);

        // eslint-disable-next-line
    }, [transactionsData]);

    // Handle the sticky filter behavior
    useEffect(() => {
        const handleScrollSticky = () => {
            if (!filtersRef.current) return;

            const { top } = filtersRef.current.getBoundingClientRect();

            // Use a small buffer (5px) to prevent flickering
            setIsSticky(top <= 5);
        };

        window.addEventListener("scroll", handleScrollSticky);

        return () => {
            window.removeEventListener("scroll", handleScrollSticky);
        };
    }, []);

    useEffect(() => {
        const handleScrollInfinite = () => {
            const isAtBottom = window.innerHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight - 10;

            if (isAtBottom && currentPage * PAGE_SIZE < transactionsCount && transactionsCount > 20) {
                setCurrentPage(prevPage => {
                    const nextPage = prevPage + 1;
                    updateFilters({ page: nextPage }, RefinementType.page);
                    return nextPage;
                });
            }
        };

        window.addEventListener("scroll", handleScrollInfinite);

        return () => {
            window.removeEventListener("scroll", handleScrollInfinite);
        };
        // eslint-disable-next-line
    }, [transactionsCount]);

    return (
        <div className="approvedRejected">
            <Drawer
                className="approvedRejected__drawer"
                title={action === Action.update ? "Update Expense" : action === Action.bulkUpdate ? "Update Expenses" : "Add Expense"}
                placement={"right"}
                height={"80%"}
                onClose={closeDrawer}
                open={isDrawerOpen}
                style={{
                    borderRadius: "36px 36px 0 0",
                    overflow: "hidden",

                }}
            >
                {action === Action.update &&
                (<UpdateTransaction
                    transactionToUpdate={transactionsToUpdate[0]}
                    taxProfileType={taxProfileType}
                    taxProfileId={taxProfileId}
                    updateTransaction={updateTransaction}
                    handleClose={closeDrawer}
                    categories={transactionsCategories}
                    approval={approval}
                    approveOrReject={approveOrReject}
                />)}
                {action === Action.bulkUpdate &&
                (<BulkUpdateTransactions
                    transactionsToUpdate={transactionsWithNoCategory.filter((transaction): transaction is ExpenseCard => transaction !== undefined)}
                    selectedTransactionsIds={selectedTransactionsIds}
                    patchingTransactionIds={patchingTransactionIds}
                    taxProfileType={taxProfileType}
                    taxProfileId={taxProfileId}
                    updateTransaction={updateTransaction}
                    handleClose={closeDrawer}
                    categories={transactionsCategories}
                    approval={approval}
                    bulkApproveOrReject={bulkApproveOrReject}
                />)}
                {/* TO DO Add Create Transaction Component */}
            </Drawer>
            <TotalBusinessExpensesCard totalAggregatedAmount={totalAggregatedAmount} title={"Total Claimed Business Expenses"} infoText={"Claiming expenses reduces your tax liability"}></TotalBusinessExpensesCard>
            <div ref={filtersRef} className={`approvedRejected__filters ${isSticky ? "sticky" : ""} w335px`}>
                <Tabs
                    centered
                    defaultActiveKey="unreviewed"
                    items={items}
                    onChange={onChangeTabFilter}
                    className="w100"
                    tabPosition="top"
                    tabBarStyle={{ color: "#7F43FF", marginBottom: 4 }}
                />

                {/* Filters, Search, and Sort */}
                <Row gutter={[8, 10]} className="w100 mt10">
                    <Col span={15} className="taLeft">
                        <TransactionsSearch onApplyFiltersSortSearch={searchQuery => updateFilters({ ...searchQuery, page: 1 }, RefinementType.sort)} />
                    </Col>
                    <Col span={9} className="taRight">
                        <TransactionFilters
                            filters={filters}
                            onApplyFiltersSortSearch={filterUpdates => updateFilters({ ...filterUpdates, page: 1 }, RefinementType.filter)}
                            connectedBankAccounts={connectedBankAccounts}
                            transactionsCategories={transactionsCategories}
                            taxProfileType={taxProfileType}
                        />
                    </Col>
                </Row>
                <Row className="w100 mt10 mb10">
                    <Col span={24} className="approvedRejected__filters__tags">
                        <QuickFilterTags filters={filters} updateFilters={updateFilters} />
                    </Col>
                </Row>
                <Row gutter={[8, 8]} className="w100 mt6">
                    <Col span={12} className="taLeft">
                        <CustomCheckbox
                            checked={selectedTransactionsIds.size === virtualizedData?.length}
                            onChange={(e: any) => toggleSelectAll(e)}
                            label="Select All"
                            disabled={!showMultiselect}
                        />
                    </Col>

                    <Col span={12} className="taRight">
                        <span className="mr4">Multiselect</span>
                        <Switch className="approvedRejected__filters__multiselect" value={showMultiselect} onChange={() => {
                            setSelectedTransactionsIds(new Set());
                            setShowMultiselect(!showMultiselect);
                        }}/>
                    </Col>
                </Row>
            </div>

            <Row gutter={[8, 8]} className="w100 mt6">
                <Col span={12} className="taLeft">
                </Col>
                <Col span={12} className="taRight">
                    <TransactionsSort onApplyFiltersSortSearch={sort => updateFilters({ ...sort, page: 1 }, RefinementType.sort)} />
                </Col>
            </Row>

            <Spinner isLoading={loading}>
                {!loading && virtualizedData && virtualizedData.length ? (
                    <div className="w100">
                        <List>
                            <VirtualList data={virtualizedData} itemHeight={ITEM_HEIGHT} itemKey="id" ref={listRef}>
                                {(item, index) => {
                                    return (
                                        <div key={item.id} className="approvedRejected__list-item">
                                            {patchingTransactionIds.has(item.id) ? (
                                                <div className="w100 center" style={{ minHeight: "92px" }}><Spin size="small" /></div>) : <>
                                                <TransactionListItem
                                                    item={item}
                                                    showMultiselect={showMultiselect}
                                                    selectedTransactions={selectedTransactionsIds}
                                                    toggleTransactionSelection={toggleTransactionSelection}
                                                    openDrawer={openDrawer}
                                                />
                                                <Row className="mt8">
                                                    <Col span={3}></Col>
                                                    <Col span={16}>
                                                        <Flex align="flex-start" gap="small">
                                                            <Button ghost type="primary" disabled={filters.viewFilter === ApprovalType.approved || selectedTransactionsIds.size >= 1} onClick={() =>
                                                                approveOrReject(ApprovalType.approved, item)}>Business</Button>
                                                            <Button ghost type="primary" disabled={filters.viewFilter === ApprovalType.rejected || selectedTransactionsIds.size >= 1 } onClick={() =>
                                                                approveOrReject(ApprovalType.rejected, item)}>Personal</Button>
                                                        </Flex>
                                                    </Col>
                                                    <Col span={4} className="taRight">
                                                        <Button type="default" disabled={selectedTransactionsIds.size >= 1 } icon={<img src={editOutline} alt="edit outline" />} onClick={() => {
                                                            setAction(Action.update);
                                                            openDrawer([item]);
                                                        }} />
                                                    </Col>
                                                </Row>
                                            </>}
                                            {index < virtualizedData.length - 1 && <Divider/>}

                                        </div>
                                    );
                                }}
                            </VirtualList>
                        </List>

                    </div>
                ) : (
                    !loading && <Empty className="mt20" />
                )}
            </Spinner>

            {showBulkActionToolbar && <div className={`${isKeyboardOpen ? "" : "mt120"}`}>
                <QuestionnaireButtonFooterV2>
                    <Flex justify="center" align="center" vertical className="p10 w335px mt6">
                        <Row justify={"space-between"} className="w100">
                            <span className="approvedRejected__action-footer-amount">{totalAmountSelected}</span>
                            <span className="approvedRejected__action-footer-transactions">{selectedTransactionsIds.size} transactions</span>
                        </Row>
                        <Row className=" approvedRejected__action-footer-info taLeft w100">
                            <span>Select transaction type</span>
                        </Row>

                        <Flex align="flex-start" gap="small" className="mt8">
                            <Button type="primary" disabled={filters.viewFilter === ApprovalType.approved || patchingTransactionIds.size > 0} onClick={() => bulkApproveOrReject(ApprovalType.approved, selectedTransactionsIds)}>Business</Button>
                            <div className="mr6 ml6">or</div>
                            <Button type="primary" disabled={filters.viewFilter === ApprovalType.rejected || patchingTransactionIds.size > 0} onClick={() => bulkApproveOrReject(ApprovalType.rejected, selectedTransactionsIds)}>Personal</Button>
                        </Flex>
                    </Flex>

                </QuestionnaireButtonFooterV2>
            </div>}
        </div>
    );
};

export default ApprovedRejectedExpensesNew;
