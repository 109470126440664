import "../../../../../styles/questionnaire/documentParse.less";
import { ChangeEvent, FC, useCallback, useContext, useMemo, useState } from "react";

import { Button, Drawer, Flex, List, Modal, Typography } from "antd";
import { AppContext } from "App";
import close from "assets/images/new-questionnaire/close.svg";
import deleteIcon from "assets/images/new-questionnaire/documentParse/delete_icon.svg";
import pdfIcon from "assets/images/new-questionnaire/documentParse/pdf_icon.svg";
import pngIcon from "assets/images/new-questionnaire/documentParse/png_icon.svg";
import viewIcon from "assets/images/new-questionnaire/documentParse/view_icon.svg";
import editPencil from "assets/images/new-questionnaire/edit_pencil.svg";
import QuestionnaireButtonFooterV2 from "components/basic/Footer/QuestionnaireButtonFooterV2";
import { useAppSelector } from "redux/hooks";
import { RootState } from "redux/store";
import { useUpdateClientTaxProfileMutation } from "services/client";
import { useDeleteFileMutation, useUploadAndParseCisFileMutation, useUploadFileMutation } from "services/files";
import { IUser } from "types/auth";
import { TaxProfile } from "types/client";
import { IFile } from "types/files";
import { logError } from "utils/sentry";
import { v4 as uuidv4 } from "uuid";

import DocumentParsePromptScreen from "./DocumentParsePromptScreen";
import Parser from "./Parser";
import TotalCISExpensesCard from "./TotalCISExpensesCard";
import UploadDesktop from "./UploadDesktopView";
import { Question } from "../../constants/questions";
import { UploadType } from "../../enums/enum";
import InlineAmountInput from "../Shared/InlineEditableAmountInput";

export type Receipt = {
    originalName: string;
    url: string;
    name: string;
    _id: string;
    warning: string;
    metaData?: any;
    progress?: number;
};

type RunningTotalCis = {
    parsedCisGross: number;
    parsedCisTaxDeduction: number;
}

type DocumentParseQuestionProps = {
    user: IUser;
    data: Question;
    onAnswer: (_question: string, _answer: {category: string, docs: Receipt[]} | null) => void;
    initialValue?: {
        category: string;
        docs: Receipt[];
    }
    arrayAnswers: any;
    currentProfile: TaxProfile;
    handleContinue?: () => void;
};

const DocumentParseQuestion: FC<DocumentParseQuestionProps> = ({ data, onAnswer, initialValue, user, arrayAnswers, currentProfile, handleContinue }) => {
    const { isMobile } = useContext(AppContext);
    const [uploadFile] = useUploadFileMutation();
    const [isUploadOpen, setIsUploadOpen] = useState<boolean>(false);
    const [deleteFile] = useDeleteFileMutation();
    const [uploadedCategoryReceipts, setUploadedCategoryReceipts] = useState<Receipt[]>(initialValue?.docs || []);
    const [updateClientTaxProfile] = useUpdateClientTaxProfileMutation();
    const [uploadAndParseCisFile] = useUploadAndParseCisFileMutation();
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [activeUpload, setUploadReceipt] = useState<null | { id: string; url: string }>(null);
    const [uploadErrorMessage, setUploadErrorMessage] = useState<string>("");
    const [editableCategoryAmount, setEditableCategoryAmount] = useState<Record<string, Record<string, string>>>({});
    const [, setEdit] = useState<Record<string, boolean>>({});

    const handleAmountChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>, category: string, receiptId: string) => {
            const value = e.target.value.replace(",", ".");
            if (/^\d*\.?\d{0,2}$/.test(value)) {
                setEditableCategoryAmount(prev => ({
                    ...prev,
                    [receiptId]: {
                        ...prev[receiptId],
                        [category]: value,
                    },
                }));
            }
        },
        [setEditableCategoryAmount]
    );

    const inlineInputStyle = {
        border: "none",
        fontSize: "16px",
        color: "black",
        width: "100%",
        gap: "10px",
        padding: "0px",
        height: "30px",
    };

    const clientId = useMemo(() => (user?.id || ""), [user]);

    const handleOpenModal = (id: string, url: string) => {
        setUploadReceipt({ id, url });
    };

    const handleCloseModal = () => {
        setUploadReceipt(null);
    };

    const { currentDocument } = useAppSelector(
        (state: RootState) => ({
            currentDocument: state.document.currentDocument,
        })
    );

    const toggleUpload = () => {
        setIsUploadOpen(!isUploadOpen);
    };
    const closeDrawer = useCallback(() => {
        setIsDrawerOpen(false);
    }, []);

    const openDrawer = useCallback(() => {
        setIsDrawerOpen(true);
    }, []);

    const handleEditToggle = useCallback(
        (categoryName: string, isEditing: boolean) => {
            setEdit(prev => ({
                ...prev,
                [categoryName]: isEditing,
            }));
        },
        [setEdit]
    );

    const getRunningTotalCis = (uploadedFiles: Receipt[], editableCategoryAmount: Record<string, Record<string, string>>): RunningTotalCis => {
        return uploadedFiles.reduce(
            (totals, file) => {
                const fileId = file._id;

                // Extract values from editable inputs or fallback to metadata
                const grossAmount = parseFloat((editableCategoryAmount[fileId]?.["Gross Amount"] ?? file?.metaData?.gross_amount?.value ?? "0").replace(/,/g, "")) || 0;
                const cisAmount = parseFloat((editableCategoryAmount[fileId]?.["Tax Deducted"] ?? file?.metaData?.tax_deducted?.value ?? "0").replace(/,/g, "")) || 0;

                return {
                    parsedCisGross: totals.parsedCisGross + grossAmount,
                    parsedCisTaxDeduction: totals.parsedCisTaxDeduction + cisAmount,
                };
            },
            { parsedCisGross: 0, parsedCisTaxDeduction: 0 } // Initial accumulator values
        );
    };
    const runningTotalCis = useMemo(
        () => getRunningTotalCis(uploadedCategoryReceipts, editableCategoryAmount),
        [uploadedCategoryReceipts, editableCategoryAmount]
    );

    const { parsedCisGross: totalAggregatedGrossAmount, parsedCisTaxDeduction: totalAggregatedCisAmount } = runningTotalCis;
    const updateTaxReport = useCallback(async (uploadedFiles: Receipt[]) => {
        if (currentProfile) {
            const { parsedCisGross, parsedCisTaxDeduction } = getRunningTotalCis(uploadedFiles, editableCategoryAmount);
            try {
                await updateClientTaxProfile({
                    taxProfileId: currentProfile._id,
                    client: currentProfile.client,
                    revenue: {
                        ...currentProfile.revenue,
                        parsedCisGrossPay: parsedCisGross,
                        parsedCisTaxDeducted: parsedCisTaxDeduction,
                        confirmedTotalGross: parsedCisGross,
                        taxAlreadyPaidOnRevenue: parsedCisTaxDeduction,
                    },
                }).unwrap();
            } catch (error: any) {
                const contextInfo = {
                    client: currentProfile.client,
                    taxProfileId: currentProfile._id,
                    function: "updateTaxReport",
                    error: error.message,
                };
                logError(error, contextInfo);
            }
        }
    }, [currentProfile, updateClientTaxProfile, editableCategoryAmount]);

    const deleteReceipt = useCallback(async (id: string, uploadType: string | undefined) => {
        if (!uploadType) return false;
        const url = uploadedCategoryReceipts?.find(receipt => receipt?._id === id)?.url;
        if (!url) return false;

        const deleteFileResponse = await deleteFile({ url }).unwrap();
        if (!deleteFileResponse?.success) return false;
        const successfulReceiptUploads = uploadedCategoryReceipts.filter(receipt => receipt._id !== id);
        if (successfulReceiptUploads.length === 0) {
            if (currentProfile && data.uploadType && [UploadType.cisDeductionStatement].includes(data.uploadType)) {
                await updateTaxReport(successfulReceiptUploads);
            }
            onAnswer(
                data.question,
                null
            );
        } else {
            if (data.uploadType && [UploadType.cisDeductionStatement].includes(data.uploadType)) {
                await updateTaxReport(successfulReceiptUploads);
            }

            onAnswer(
                data.question,
                {
                    category: uploadType,
                    docs: successfulReceiptUploads,
                }
            );
        }
        setUploadedCategoryReceipts(successfulReceiptUploads);

        return true;
    }, [currentProfile, data.question, data.uploadType, deleteFile, onAnswer, updateTaxReport, uploadedCategoryReceipts]);

    const formatDate = (dateString: string) => {
        const [day, month, year] = dateString.split("/");
        const date = new Date(`${year}-${month}-${day}`);
        return new Intl.DateTimeFormat("en-GB", { month: "short", year: "2-digit" }).format(date);
    };

    const showDocumentParseScreen = useCallback(() => {
        const answerObject = arrayAnswers.find((answer: any) => answer.type === "documentParseQuestion");
        const docs = answerObject?.answer?.docs ?? [];
        return docs.length === 0;
    }, [arrayAnswers]);

    const getFileIcon = (fileName: string): string => {
        const extension = fileName?.split(".").pop()?.toLowerCase() ?? "";
        if (extension === "pdf") {
            return pdfIcon;
        } else if (["docx"].includes(extension)) {
            return pngIcon; //TODO - replace with actual icon
        } else if (["jpeg", "jpg", "png"].includes(extension)) {
            return pngIcon;
        }
        return pdfIcon;
    };

    const uploadQFile = useCallback(async (file: IFile) => {
        if (!currentDocument || !data.uploadType) return false;

        let uploadedFileResponse;
        let metaData = undefined;

        if ([UploadType.cisDeductionStatement].includes(data.uploadType)) {
            const newReceipt = {
                originalName: file.name,
                url: file.url,
                name: data.uploadType,
                _id: uuidv4(),
                warning: "",
                progress: 50,
            };
            setUploadedCategoryReceipts(prevReceipts => [...prevReceipts, newReceipt]);
            try {
                uploadedFileResponse = await uploadAndParseCisFile({
                    file: file,
                    clientId: clientId,
                    type: UploadType.cisDeductionStatement,
                }).unwrap();
            } catch (error: any) {
                console.error("Error during file upload:", error);
                if (file.name.endsWith(".pdf") && error?.data?.message && error.data.message.includes("Encrypted PDF cannot be processed.")) {
                    setUploadErrorMessage("Password protected PDFs cannot be processed. Please take a screenshot and try uploading again or provide a different document.");
                    setUploadedCategoryReceipts(prevReceipts =>
                        prevReceipts.filter(receipt => receipt._id !== newReceipt._id)
                    );
                }
                return false;
            }
            metaData = uploadedFileResponse?.data?.extractedData || undefined;
        } else {
            try {
                uploadedFileResponse = await uploadFile({
                    file: file,
                    path: `clients/${clientId}/receipts`,
                    type: data.uploadType!,
                }).unwrap();
            } catch (error) {
                console.error("Error during uploadFile:", error);
                return false;
            }
        }

        if (!uploadedFileResponse?.data?.url) {
            return false;
        }

        const receipt: Receipt = {
            originalName: file.name,
            url: uploadedFileResponse.data.url,
            name: data.uploadType!,
            _id: uuidv4(),
            warning: uploadedFileResponse.data.warning,
            metaData: metaData,
            progress: 100,
        };
        const uploadedFiles = uploadedCategoryReceipts.map(existingReceipt =>
            (existingReceipt.url === receipt.url ? receipt : existingReceipt)
        );
        const existingReceipt = uploadedFiles.find(r => r.url === receipt.url);
        if (!existingReceipt) {
            uploadedFiles.push(receipt);
        }

        if ([UploadType.cisDeductionStatement].includes(data.uploadType)) {
            await updateTaxReport(uploadedFiles);
        }

        setUploadedCategoryReceipts(uploadedFiles);

        onAnswer(
            data.question,
            {
                category: data.uploadType!,
                docs: uploadedFiles,
            }
        );
        return true;
    }, [currentDocument, data.uploadType, data.question, uploadedCategoryReceipts, updateTaxReport, onAnswer, clientId, uploadFile, uploadAndParseCisFile]);

    const handleConfirm = useCallback(async () => {
        setEdit({});
        const { parsedCisGross, parsedCisTaxDeduction } = runningTotalCis;
        const updatedReceipts = uploadedCategoryReceipts.map(receipt => {
            const grossAmount = editableCategoryAmount[receipt._id]?.["Gross Amount"] ?? receipt.metaData?.gross_amount?.value ?? "0.00";
            const taxDeducted = editableCategoryAmount[receipt._id]?.["Tax Deducted"] ?? receipt.metaData?.tax_deducted?.value ?? "0.00";
            return {
                ...receipt,
                metaData: {
                    ...receipt.metaData,
                    gross_amount: {
                        value: grossAmount,
                    },
                    tax_deducted: {
                        value: taxDeducted,
                    },
                },
            };
        });

        await updateClientTaxProfile({
            taxProfileId: currentProfile._id,
            client: currentProfile.client,
            revenue: {
                ...currentProfile.revenue,
                parsedCisGrossPay: parsedCisGross,
                parsedCisTaxDeducted: parsedCisTaxDeduction,
                confirmedTotalGross: parsedCisGross,
                taxAlreadyPaidOnRevenue: parsedCisTaxDeduction,
            },
        }).unwrap()
            .then(() => {
                onAnswer(data.question, {
                    category: "CIS Deduction",
                    docs: updatedReceipts,
                });
                handleContinue?.();
            })
            .catch(error => {
                logError(error);
            });
    }, [editableCategoryAmount, handleContinue, currentProfile, updateClientTaxProfile, onAnswer, data, uploadedCategoryReceipts, runningTotalCis]);

    return (
        <div className={isMobile ? "document_parse-mobile" : "document_parse-desktop"}>
            {isMobile && <Drawer className="document-parse-drawer" placement={"bottom"} height={"100%"} onClose={closeDrawer} open={isDrawerOpen} style={{
                borderRadius: "36px 36px 0 0",
                overflow: "hidden",
            }}closeIcon={<div className="document-parse-close"><img src={close} alt="close" className="dblock"/></div>}>
                <Parser uploadQFile={uploadQFile} uploadedCategoryReceipts={uploadedCategoryReceipts} getFileIcon={getFileIcon} closeDrawer={closeDrawer} uploadErrorMessage={uploadErrorMessage} />
            </Drawer>}
            {!showDocumentParseScreen() && <TotalCISExpensesCard totalAggregatedGrossAmount={totalAggregatedGrossAmount} totalAggregatedCisAmount={totalAggregatedCisAmount} title={"Total Gross Income"} subtitle={"CIS Tax Paid"} heading={"Uploaded Statements Summary"}></TotalCISExpensesCard> }
            {!showDocumentParseScreen() && <div className="w335px">
                <div className="mt30 mb10 fs14 fw500">
                    Uploaded Deduction Statements
                </div>
                <List>
                    {uploadedCategoryReceipts.map((receipt, index) => (
                        <List.Item key={index}>
                            <div>
                                <div className="document-parse__array-item-wrapper">
                                    <div>
                                        <img src={getFileIcon(receipt.originalName)} alt={receipt.originalName} />
                                    </div>
                                    <div>
                                        <div className={"document-parse__array-v2-item"}>
                                            <Typography.Text className="document-parse__ellipsis-text grey-6-color">
                                                {receipt.originalName}
                                            </Typography.Text>
                                            <span>
                                                {receipt?.metaData?.start_date?.value && receipt?.metaData?.end_date?.value && (
                                                    `${formatDate(receipt?.metaData?.start_date?.value)}-${formatDate(receipt?.metaData?.end_date?.value)}`
                                                )}
                                            </span>
                                        </div>
                                        <div>
                                            <div>

                                                {["Gross Amount", "Tax Deducted"].map(category => (
                                                    <InlineAmountInput
                                                        key={`${receipt._id}-${category.toLowerCase().replace(" ", "-")}`}
                                                        categoryName={category}
                                                        amount={editableCategoryAmount[receipt._id]?.[category] || receipt.metaData?.[category.toLowerCase().replace(" ", "_")]?.value || "0.00"}
                                                        onAmountChange={e => handleAmountChange(e, category, receipt._id)}
                                                        onEditToggle={handleEditToggle}
                                                        editIcon={editPencil}
                                                        style={inlineInputStyle}
                                                    />
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="document-parse__array-v2-item" style={{ width: "100%" }}>
                                    <div onClick={() => deleteReceipt(receipt._id, data.uploadType)}>
                                        <img src={deleteIcon} alt="delete icon" />
                                    </div>
                                    <div id="view-button" onClick={() => handleOpenModal(receipt._id, receipt.url)}>
                                        <img
                                            src={viewIcon}
                                            alt="View icon"
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                </div>
                                {activeUpload?.id === receipt._id && (
                                    <Modal
                                        visible={true}
                                        onCancel={handleCloseModal}
                                        footer={null}
                                        width="100%"
                                        bodyStyle={{ height: "80vh", overflow: "hidden" }}
                                    >
                                        <iframe
                                            title={`Receipt - ${receipt._id}`}
                                            src={activeUpload.url}
                                            width="100%"
                                            height="100%"
                                            style={{ border: "none" }}
                                        />
                                    </Modal>
                                )}
                            </div>
                        </List.Item>
                    ))}
                </List>
                <div className="document-parse-outer-container-mobile ">
                    <div className={!isMobile && !showDocumentParseScreen() ? "mt50" : ""}>
                        {isMobile ? (
                            <QuestionnaireButtonFooterV2>
                                <Flex vertical gap={10} className="ph10 pv20">
                                    <Button type="default" className="document-parse-continue-outline" onClick={openDrawer}>Upload More</Button>
                                    <Button type="primary" className="document-parse-continue" onClick={handleConfirm}>Continue to Final Income Review</Button>
                                </Flex>

                            </QuestionnaireButtonFooterV2>
                        ) : (
                            !showDocumentParseScreen() && (
                                <Flex vertical gap={10} className="ph10 pv20">
                                    <Button type="default" className="document-parse-continue-outline" onClick={toggleUpload}>Upload More</Button>
                                    <Button type="primary" className="document-parse-continue" onClick={handleConfirm}>Continue to Final Income Review</Button>
                                </Flex>
                            )
                        )}
                    </div>
                </div>
                <UploadDesktop
                    toggleUpload={toggleUpload}
                    isUploadOpen={isUploadOpen}
                    uploadQFile={uploadQFile}
                    uploadedCategoryReceipts={uploadedCategoryReceipts}
                    getFileIcon={getFileIcon} uploadErrorMessage={uploadErrorMessage} setUploadErrorMessage={setUploadErrorMessage}/>
            </div>}
            {showDocumentParseScreen() &&
                    <DocumentParsePromptScreen
                        openDrawer={openDrawer}
                        closeDrawer={closeDrawer}
                        isDrawerOpen={isDrawerOpen}
                        uploadQFile={uploadQFile}
                        uploadedCategoryReceipts={uploadedCategoryReceipts}
                        handleContinue={handleContinue}
                        getFileIcon={getFileIcon}
                        toggleUpload={toggleUpload}
                        isUploadOpen={isUploadOpen}
                        uploadErrorMessage={uploadErrorMessage} setUploadErrorMessage={setUploadErrorMessage} ></DocumentParsePromptScreen>}
        </div>

    );
};

export default DocumentParseQuestion;
