import "styles/privacy-policy.less";

import React, { useContext } from "react";

import { Breadcrumb, Col, Typography } from "antd";
import { AppContext } from "App";
import { PiCaretRightBold } from "react-icons/pi";
import { Link } from "react-router-dom";

import { UK_ROUTES } from "../../../constants/routes";
import Layout from "../../basic/Layout";

const PrivacyPolicy: React.FC = () => {
    const { isMobile } = useContext(AppContext);
    return (
        <Layout>
            <div className='horizontal-center'>
                <Breadcrumb
                    style={{ padding: isMobile ? "20px 20px" : "64px 100px", width: "1500px" }}
                    items={[
                        { title: <Link to={UK_ROUTES.home}>Homepage</Link> },
                        { title: "FAQs" },
                    ]}
                    separator={<div style={{ display: "flex", alignItems: "center", height: "24px" }}><PiCaretRightBold
                        fontSize={"22px"}/></div>}/>
            </div>
            <Col className="privacy-policy__content">
                <Typography className="privacy-policy__page_title">
                    Privacy and Cookies Policies
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>TaxZap UK Limited is committed to protecting the privacy and security of your personal information,
                        as part of our General Data Regulation (GDPR) obligations. This privacy notice for TaxZap UK
                        Limited, describes how and why we might collect, store, use, and/or share ('process') your
                        information when you use (”user”) our services. This relates to all activities concerning your
                        relationship with TaxZap UK Limited and is not limited to the use of our website.</p>
                    <p>
                        The Data Protection Legislation contains principles affecting not only personally identifiable
                        information held on computers but also certain manual records containing personal data, for
                        example associates, directors, employees, clients, or suppliers’ personnel files that form part
                        of a structured filing system.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                The Data Protection Principles
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>There are seven data protection principles that are central to the Data Protection Legislation. In
                    brief, the principles say that personal data must be:
                    <ol>
                        <li>Processed fairly and lawfully and must not be processed unless certain conditions are met in
                            relation to personal data and additional conditions are met in relation to sensitive
                            personal
                            data.
                        </li>
                        <li>Obtained only for one or more specified and lawful purposes and must not be processed in any
                            manner incompatible with those purposes.
                        </li>
                        <li>Adequate, relevant, and not excessive in relation to the purposes for which it is processed.
                            The
                            Company will review files on a regular basis to ensure they do not contain a backlog of
                            out-of-date or irrelevant information and to check there is a sound business reason
                            requiring
                            information to continue to be held.
                        </li>
                        <li>Accurate and, where necessary, kept up to date.</li>
                        <li>Not kept for longer than is necessary.</li>
                        <li>Appropriate technical and organisational measures must be taken against unauthorised or
                            unlawful
                            processing of personal data and against accidental loss or destruction of, or damage to,
                            data.
                        </li>
                        <li>Have appropriate measures and records in place to be able to demonstrate compliance with the
                            legislation.
                        </li>
                    </ol></p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    The Data Controller
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>TaxZap UK Limited is the Data Controller for any personal data that you give us. TaxZap UK Limited
                        decides how and why personal data is processed - ensures that appropriate technical and
                        operational measures are in place so that, by default, only personal data which are necessary
                        for each specific purpose of the processing are processed. This applies to the amount of
                        personal data collected, the extent of their processing, the period of their storage and their
                        accessibility. Through these measures, the controller ensures that, by default, personal data
                        are not made widely accessible without permission.</p>

                    <p>Our full details are:</p>
                    <p>
                        TaxZap UK Limited<br/>
                        90 Sedgwick St,<br/>
                        Cambridge, UK.<br/>
                        CB1 3AL<br/>
                        Email: <a href="mailto:support@taxzap.co.uk">support@taxzap.co.uk</a>
                    </p>

                </Typography>
                <Typography className="privacy-policy__subtitle">
                    The Data Protection Officer
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        The Data Protection Officer (DPO) is responsible for ensuring that appropriate Privacy Notices
                        exist
                        and are appropriately published to enable all data subjects to be aware of these notices and
                        their
                        contents before data is collected. All content will be in plain language.
                        Our nominated representative for the purpose of the regulations is Aaron Hickey.</p>

                </Typography>
                <Typography className="privacy-policy__subtitle">
                    The Information we Collect and When we Collect it
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>We only collect information that we know we will genuinely use and in accordance with the Data
                        Protection Legislation. This includes, but not limited to:
                    <ul>
                        <li>information about your computer and about your visits to and use of this website
                            (including
                            your
                            IP address, geographical location, browser type, referral source, length of visit and
                            number
                            of
                            page views).
                        </li>
                        <li>information relating to any transactions carried out between you and us on or in
                            relation to this website, including information relating to the supply of our services.
                        </li>
                        <li>information that you provide to us for the purpose of registering with us (including
                            name, address, telephone number and any financial information).
                        </li>
                        <li>information that you provide to us for the purpose of subscribing to our website
                            services, email notifications and/or newsletters).
                        </li>
                        <li>information required to complete our services that we will request directly from
                            you, including your personal circumstances and information about your financial
                            products and services.
                        </li>
                        <li>any other information that you choose to send to us
                            All personal data we hold is processed by our staff in the UK or Ireland or Ukraine
                            or Canada.
                        </li>
                    </ul></p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    How we use your Information
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        <ul>
                            <li>To contact you, following your enquiry.</li>
                            <li>To reply to any questions, suggestions, issues or complaints you have contacted us
                                about.
                            </li>
                            <li>Make available our services to you.</li>
                            <li>Take payment from you or give you a refund.</li>
                            <li>For statistical analysis and to get feedback from you. For example, occasionally we may
                                invite you to review a service you’ve used from us.
                            </li>
                            <li>To power our security measures and services so you can safely access our website.</li>
                            <li>Help us understand more about you as a customer, the services you use, so we can serve
                                you better.
                            </li>
                            <li>Contact you about services from us.</li>
                            <li>To improve customer service and support your needs more efficiently.</li>
                        </ul>
                    </p>
                </Typography>
                <Typography className="privacy-policy__page_title">
                    Data Usage for Service Improvement
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Document Parsing and Machine Learning
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>When you upload documents to our platform, we may parse text from these documents to improve the functionality and performance of our services, including training machine learning models. This processing is conducted under the following terms:
                        <ol>
                            <li><strong>Purpose:</strong> The extracted text may be used solely to enhance and optimise our services, ensuring they deliver more accurate and reliable results.
                            </li>
                            <li><strong>Data Minimisation: </strong>Only the necessary portions of document content are parsed and used for training purposes.
                            </li>
                            <li><strong>Anonymisation:</strong> Where feasible, data will be anonymised or aggregated to remove any personal or identifiable information before use in training our models.
                            </li>
                            <li><strong>Legal Basis:</strong> We rely on our legitimate interests to improve our services, as permitted under the UK GDPR (Article 6(1)(f)). However, we ensure that this processing does not override your data protection rights.</li>

                        </ol>

                    </p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Lawful Basis
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>We are required by law to always have a permitted reason or justification (called a “lawful
                        basis”) for processing your personal data. There are six such permitted lawful bases for
                        processing personal data.</p>

                    <p>The lawful basis for processing your data is based on your specific consent, performance of a
                        contract, compliance with a legal obligation or our legitimate interest that we will have stated
                        at the point the information was initially provided. Please note that where our processing of
                        your personal data is either necessary for us to comply with a legal obligation; or necessary
                        for us to take steps, at your request, to potentially enter into a contract with you, or to
                        perform it, and you choose not to provide the relevant personal data to us, we may not be able
                        to enter into or continue our contract with you.</p>

                    <p>In respect of our legitimate interests, we have a legitimate interest in keeping you updated
                        about any further services offered by us which may be of interest to you both during and after
                        the conclusion of your contract with us, including communication of any developments that may
                        have an impact on your original service (e.g. a change in the law that will make additional
                        services available to you). We will use the details you have provided to contact you including
                        by telephone, email and post. If you do not wish for us to contact you in this manner, or by a
                        specific method, you will be able to unsubscribe at any time to one or all contact methods and
                        this option will be easy for you to complete.</p>

                    <p>We also have a legitimate interest in using your data to help us to review our services and
                        obtain analytics in respect of our customer base.</p>

                    <p>We may also be required to use your data due to a legal requirement which is placed upon us; this
                        includes our regulatory requirements such as financial record keeping, staff training and
                        monitoring, in addition to complaint handling. In these circumstances, we may be required to
                        keep your data by law. We will always inform you if this is the case.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Special Category Personal Data (including Criminal Data)
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>We are required by law to treat certain categories of personal data with even more care than
                        usual. This includes information about your health, any disabilities you may have and your
                        criminal convictions (spent or unspent). These are called sensitive or special categories of
                        personal data and an additional condition set out in the Data Protection Legislation is required
                        to process them.</p>
                    <p>The additional condition(s) we rely upon for processing special category personal data are
                        either that the processing is undertaken by us and is not shared outside TaxZap UK Limited or
                        your explicit consent.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Who we might share your Information with
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>To allow us to provide our services to you, the following third parties provide critical
                        functions to our business and will process your personal information as directed by us and in
                        accordance with strict data security arrangements:</p>

                    <ul>
                        <li><strong>Our Advertising:</strong> We use third party digital advertising services who
                            provide analytical information about our advertising to help us improve this in the future.
                            In any event, the data held by analytics firms is on an anonymised basis meaning they cannot
                            identify you;
                        </li>
                        <li><strong>We may use third party service providers</strong> to help us operate our business
                            and the Site or administer activities on our behalf, such as sending out newsletters or
                            surveys.
                        </li>
                        <li><strong>Post and Printing:</strong> we use an outsourced print-house who manages our
                            printing; we transfer customer data via SFTP.
                        </li>
                        <li><strong>Government departments,</strong> such as HMRC, who we liaise with on your behalf and
                            for AML audits.
                        </li>
                        <li><strong>Our Regulators:</strong> we may be required to provide your data to our Regulators,
                            who include the Legal Ombudsman Service and the Information Commissioner’s Office; and
                        </li>
                        <li><strong>Our Professional Services:</strong> we use professional legal consultancy to help us
                            fulfil our obligations.
                        </li>
                    </ul>

                    <p>We may also share your personal data with other organisations in the following circumstances:</p>

                    <ul>
                        <li>If the law or a public authority says we must share the personal data.</li>
                        <li>If we transfer, purchase, reorganise, merge or sell any part of our business or the business
                            of a third party, and we disclose or transfer your personal data to the prospective seller,
                            buyer or other third party involved in a business transfer, reorganisation or merger
                            arrangement (and their advisors);
                        </li>
                        <li>If we need to share personal data to establish, exercise or defend our legal rights (this
                            includes providing personal data to others for the purposes of preventing fraud and reducing
                            credit risk); or
                        </li>
                        <li>From time to time, employ the services of other parties for dealing with certain processes
                            necessary for the operation of the Website. However, all the information we share will be
                            collected and anonymised, so neither you nor any of your devices can be identified from it.
                        </li>
                    </ul>

                    <p>We may also share your personal data with third parties, as directed by you.</p>

                    <p>We will not sell, assign, disclose or rent your personal data to any other external organisation
                        or individual except for where the law requires us to disclose it, or where it is necessary to
                        disclose the information to comply with a regulatory or legal process. We have carefully
                        selected our third parties due to their commitment to keeping your data safe, and all data is
                        processed within the European Union and subject to the same legislation. If you request for us
                        to stop processing your data, we will also communicate this to the relevant third parties if
                        they are processing this on our behalf. If you have any concerns about the above third parties,
                        please let us know and we can provide advice and support to help you manage your data
                        preferences.</p>

                </Typography>
                <Typography className="privacy-policy__subtitle">
                    How we keep you updated on our Products and Services
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>We will send you relevant offers and news about our services in several ways including by email,
                        but only if you have previously consented to receive these marketing communications. When you
                        register with us, we will ask if you would like to receive marketing communications, and you can
                        change your marketing choices online, over the phone, or in writing at any time.</p>

                    <p>If you wish to amend your marketing preferences, you can do so by contacting us on the details at
                        the bottom of this policy.</p>
                </Typography>
                <Typography className="privacy-policy__title">
                    Your Rights over your Information
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Rights to Access your Personal Information
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>Under the Data Protection Legislation, data subjects have the right to receive a copy of the
                        personal data that the Company holds about them, subject to certain conditions. Including:</p>

                    <ul>
                        <li>To be told by the Company whether and for what purpose personal data about them is being
                            processed.
                        </li>
                        <li>To be given a description of the personal data concerned and the recipients to whom it is or
                            may be disclosed.
                        </li>
                        <li>To have communicated in an intelligible form the personal data concerned, and any
                            information available to the Company as to the source of the data.
                        </li>
                    </ul>

                    <p>If you wish to access a copy of any personal data being held about you, you may submit a request
                        for this by contacting TaxZap UK Limited on the details provided below. This is sometimes termed
                        ‘Subject Access Request’. If we agree that we are obliged to provide personal information to you
                        (or someone else on your behalf where you consent for us to do so), we will provide it to you or
                        them free of charge and aim to do so within 30 days from when your identity has been confirmed
                        (subject to applicable exemptions).</p>

                    <p>We would ask for proof of identity and sufficient information about your interactions with us
                        that we can locate your personal information. If you would like to exercise this right, please
                        contact us as set out below.</p>

                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Rights to Correction of your Personal Information
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        If any of the personal information we hold about you is incomplete, inaccurate or out of date,
                        you may ask us to correct or complete it, as applicable.
                        If you would like to exercise this right, please contact us as set out below.
                    </p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Right to Object to or Restrict Our Processing of Your Data
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>You have the right to object to us processing of your personal information, subject to certain conditions. This right applies where the processing of your personal data is necessary for our legitimate interests. You can ask us to restrict the processing of your personal data in certain circumstances.</p>

                    <p>You can also object to our processing of your personal data for direct marketing purposes.</p>

                    <p>If you would like to exercise this right, please contact us as set out below.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Right to Erasure of Your Personal Information
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        Subject to certain conditions, you are entitled to have your personal data erased (also known as
                        the “right to be forgotten”), e.g., where your personal data is no longer needed for the
                        purposes it was collected for, or where the relevant processing is unlawful.
                    </p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Right to Withdrawal of Consent
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        Where our processing of your personal data is based on your consent you have the right to
                        withdraw your consent at any time.
                    </p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    For More Information about your Privacy Rights
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>The Information Commissioner’s Office (ICO) regulates data protection and privacy matters in the UK. They make a lot of information accessible to consumers on their website and they ensure that the registered details of all data controllers such as us are available publicly. You can access them <a href="https://ico.org.uk/for-the-public">here</a>.</p>

                    <p>You can make a complaint to the ICO at any time about the way we use your information. However, we hope that you would consider raising any issue or complaint you have with us first. Your satisfaction is extremely important to us, and we will always do our very best to solve any problems you may have.</p>

                    <p>If you wish to raise a complaint on how we have handled your personal data, you can contact our Complaints Team who will investigate your concerns.</p>

                    <p>
                        TaxZap UK Limited<br/>
                        Email: <a href="mailto:support@taxzap.co.uk">support@taxzap.co.uk</a>
                    </p>

                    <p>If you are dissatisfied with our response you can complain to the Information Commissioner's Office:</p>

                    <p>
                        Information Commissioner's Office<br/>
                        Wycliffe House<br/>
                        Water Lane<br/>
                        Wilmslow<br/>
                        Cheshire<br/>
                        SK9 5AF
                    </p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    How Long we keep your Information for
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>We retain a record of your personal information whilst we are providing you with our services and then, after we stop providing you with services, for as long as is necessary in connection with both our and your legal rights and obligations. This may mean that we keep some types of personal data for longer than others. We will always retain your personal information in accordance with the Data Protection Legislation and never retain your information for longer than is necessary.</p>

                    <p>Different categories of data will be retained for different periods of time, depending on legal, operational, and financial requirements.</p>

                    <p>If you have asked that we do not use your details for marketing purposes, we may still need to keep them to ensure our systems reflect your preferences.</p>

                    <p>We will generally keep records of any transactions you enter into on this website for a minimum of six years from the end of the relationship. This is so that we can respond to any complaints or disputes that arise in that period. Otherwise, we will keep the information where appropriate for legitimate business needs or as required to comply with the law.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Giving your Reviews and Sharing your Thoughts
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        You may be able to share information through social networks like Facebook and Twitter. For
                        example, when you ‘like’, ‘share’ or review our Services. When doing this, your personal
                        information may be visible to the providers of those social networks and/or their other users.
                        Please remember it is your responsibility to set appropriate privacy settings on your social
                        network accounts, so you are comfortable with how your information is used and shared on them.
                    </p>
                </Typography>
                <Typography className="privacy-policy__title">
                    Security
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>Data security is of great importance to TaxZap UK Limited and to protect your data we have put in
                        place suitable physical, electronic, and managerial procedures to safeguard and secure your
                        collected data.</p>

                    <p>We take security measures to protect your information including:</p>

                    <ul>
                        <li>Implementing access controls to our information technology</li>
                        <li>We use appropriate procedures and technical security measures (including strict encryption)
                            to safeguard your information across all our computer systems, website, and workstations.
                        </li>
                        <li>Data stored on memory sticks, portable hard drives or other removable storage media are kept
                            in locked filing cabinets when not in use.
                        </li>
                        <li>Data held on computers is also stored confidentially by means of password protection,
                            encryption or coding and again only approved associates, directors, employees, clients or
                            suppliers have access to that data. The Company has cloud back-up procedures to ensure that
                            data on computers cannot be accidentally lost or destroyed.
                        </li>
                        <li>Where we have given you (or where you have chosen) a password which enables you to access
                            certain parts of our site, you are responsible for keeping this password confidential. We
                            ask you not to share this password with anyone.
                        </li>
                    </ul>
                </Typography>
                <Typography className="privacy-policy__title">
                    Information about our Use of Cookies
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>Our website uses cookies to distinguish you from other users. This helps us to provide you with a
                        good experience when you browse our website and allows us to improve our site. Cookies are small
                        packets of information stored by your web browser when you visit certain websites, including our
                        website. Cookies are generally used by websites to improve your user experience by enabling that
                        website to ‘remember’ you, either strictly for the duration of your visit (using a “Session”
                        cookie which is erased when you close your browser) or for repeat visits (using a “Permanent”
                        cookie).</p>
                    <p>Cookies set by TaxZap UK Limited are called "first-party cookies". Cookies set by parties other
                        than TaxZap UK Limited are called "third-party cookies." Third-party cookies enable features or
                        functionality provided by third parties in connection with the Platform, for example,
                        advertising, interactive content, and analytics. The parties that set these third-party
                        cookies can recognise your device both when it visits the website in question and also when
                        it visits certain other websites. We do not use cookies to store any personally identifiable
                        information about you.</p>
                    <p>We use the following cookies:
                        <ul>
                            <li>Strictly necessary cookies. These are cookies that are required for the operation of our
                                website. They include, for example, cookies that enable you to log into secure areas of
                                our
                                website, use a shopping cart or make use of e-billing services.
                            </li>
                            <li>Analytical/performance cookies. They allow us to recognise and count the number of
                                visitors
                                and to see how visitors move around our website when they are using it. This helps
                                us to
                                improve the way our website works, for example, by ensuring that users are finding
                                what they
                                are looking for easily.
                            </li>
                            <li> Functionality cookies. These are used to recognise you when you return to our
                                website. This
                                enables us to personalise our content for you, greet you by name and remember your
                                preferences (for example, your choice of language or region).
                            </li>
                            <li>Targeting/Marketing cookies. These cookies record your visit to our website, the
                                pages you
                                have visited and the links you have followed. We will use this information to make
                                our
                                website and the advertising displayed on it more relevant to your interests. We may
                                also
                                share this information with third parties for this purpose.
                            </li>
                            <li>Third-Party Cookies. We work with analytics service providers, advertising partners,
                                and
                                advertising networks that may have access to your device information but does not
                                enable
                                them to collect your name, contact details or other personal information unless you
                                choose
                                to provide this information.
                            </li>
                        </ul>
                    </p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    How can I Control Cookies
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        You can amend or "opt out" of the collection of any information through cookies or other
                        tracking technology by actively managing the settings on your browser or mobile device. Please
                        note that turning off or deleting cookies will not prevent device identification and related
                        data collection from occurring. If you choose to reject cookies, your access to some
                        functionality and areas of our website may be restricted. As the means by which you can refuse
                        cookies through your web browser controls vary from browser-to-browser, you should visit your
                        browser's or mobile device's technical information for instructions on how to delete and disable
                        cookies, and other tracking/recording tools.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    How often will you update this Cookie Policy?
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        We may update this Cookie Policy from time to time in order to reflect, for example, changes to
                        the cookies we use or for other operational, legal or regulatory reasons. Please therefore
                        re-visit this Cookie Policy regularly to stay informed about our use of cookies and related
                        technologies.</p>
                </Typography>
                <Typography className="privacy-policy__title">
                    Other Policies
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Third Party Websites
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        Users may find advertising or other content on our Site that links to the sites and services of
                        our partners and other third parties. We do not control the content or links that appear on
                        these sites and are not responsible for the practices employed by websites linked to or from our
                        Site. In addition, these sites or services, including their content and links, may be constantly
                        changing. These sites and services may have their own privacy policies and customer service
                        policies. Browsing and interaction on any other website, including websites which have a link to
                        our Site, is subject to that website's own terms and policies.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Advertising
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        Adverts appearing on our site may be delivered to Users by advertising partners, who may set
                        cookies. These cookies allow the ad server to recognize your computer each time they send you an
                        online advertisement to compile non personal identification information about you or others who
                        use your computer. This information allows ad networks to, among other things, deliver targeted
                        advertisements that they believe will be of most interest to you. This privacy policy does not
                        cover the use of cookies by any advertisers.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    No Warranties
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        This Website is provided “as is,” with all faults, and TaxZap UK Limited express no representations
                        or warranties, of any kind related to this Website or the materials contained on this Website.
                        Also, nothing contained on this Website shall be interpreted as advising you.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Limitation of Liability
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        In no event shall TaxZap UK Limited, nor any of its officers, directors and employees, shall be held
                        liable for anything arising out of or in any way connected with your use of this Website whether
                        such liability is under contract. TaxZap UK Limited, including its officers, directors and employees
                        shall not be held liable for any indirect, consequential or special liability arising out of or
                        in any way related to your use of this Website.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Indemnification
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        You hereby indemnify to the fullest extent TaxZap UK Limited from and against any and/or all
                        liabilities, costs, demands, causes of action, damages and expenses arising in any way related
                        to your breach of any of the provisions of these Terms.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Severability
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        If any provision of these Terms is found to be invalid under any applicable law, such provisions
                        shall be deleted without affecting the remaining provisions herein.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Variation of Terms
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        TaxZap UK Limited is permitted to revise these Terms at any time as it sees fit, and by using this
                        Website you are expected to review these Terms on a regular basis.
                        When we do, we will revise the updated date at the bottom of this page. We encourage Users to
                        frequently check this page for any changes to stay informed about how we are helping to protect
                        the personal information we collect. You acknowledge and agree that it is your responsibility to
                        review this privacy policy periodically and become aware of modifications.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Assignment
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        TaxZap UK Limited is allowed to assign, transfer, and subcontract its rights and/or obligations
                        under these Terms without any notification. However, you are not allowed to assign, transfer, or
                        subcontract any of your rights and/or obligations under these Terms.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Entire Agreement
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>
                        These Terms constitute the entire agreement betweenTaxZap UK Limited and you in relation to your use
                        of this Website, and supersede all prior agreements and understandings.
                        By using this Site, you signify your acceptance of this policy. If you do not agree to this
                        policy, please do not use our Site. Your continued use of the Site following the posting of
                        changes to this policy will be deemed your acceptance of those changes.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    Governing Law & Jurisdiction
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>These Terms will be governed by and interpreted in accordance with the laws of England and Wales,
                        and you submit to the non-exclusive jurisdiction of the courts of England and Wales for the
                        resolution of any disputes.</p>
                </Typography>
                <Typography className="privacy-policy__subtitle">
                    How to Contact Us
                </Typography>
                <Typography className="privacy-policy__text">
                    <p>If you would like to exercise one of your rights as set out above, or you have a question or a
                        complaint about this policy, the way your personal information is processed, please contact us
                        by one of the following means:</p>

                    <ul>
                        <li>By email: <a href="mailto:support@taxzap.co.uk">support@taxzap.co.uk</a></li>
                    </ul>

                    <p>If you have any concerns about the way that we handle your personal data, you have the right to
                        make a complaint to the Information Commissioner’s Office by one of the following means:</p>

                    <ul>
                        <li>Online: <a href="https://www.ico.org.uk/">https://www.ico.org.uk/</a></li>
                        <li>By post: Information Commissioner’s Office, Wycliffe House, Water Lane, Wilmslow, Cheshire
                            SK9 5AF
                        </li>
                    </ul>

                </Typography>
            </Col></Layout>
    );
};

export default PrivacyPolicy;
