import { FC, useCallback, useEffect, useMemo, useState } from "react";

import questionIcon from "assets/images/new-questionnaire/question_icon.svg";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { useAppSelector } from "redux/hooks";
import { selectCurrentNavigation } from "redux/reducers/navigation";
import { checkDepends } from "utils/new-questionnaire";
import { removeEmojis } from "utils/strings";

import { Depend, Question } from "../../constants/questions";
import { PromptModalType } from "../../enums/enum";
import PromptModal from "../Shared/PromptModal";

type SelectOneTileQuestionProps = {
    data: Question;
    onAnswer: (_question: string, _answer: string | undefined) => void;
    initialValue?: string;
    categoryAnswers?: Record<string, any>;
    handlePrev: () => void;
};

const SelectOneTileQuestion: FC<SelectOneTileQuestionProps> = ({
    data,
    onAnswer,
    handlePrev,
    initialValue,
    categoryAnswers,
}) => {
    const { stepLoading } = useAppSelector(selectCurrentNavigation);

    const [selectedOption, setSelectedOption] = useState<string | undefined>(initialValue);
    const [showModalOpener, setShowModalOpener] = useState(false);

    const promptModalData = useMemo(() => ({
        type: data.promptModal?.type || PromptModalType.info,
        title: data.promptModal?.title || "",
        text: data.promptModal?.text || "",
        image: data.promptModal?.image || "",
        promptActionButtonText: data.promptModal?.promptActionButtonText || "",
        promptActionButtonUrl: data.promptModal?.promptActionButtonUrl || "",
    }), [data.promptModal]);

    const {
        isOpened: isQuestionHelpModalOpened,
        open: handleQuestionHelpOpenModal,
        close: handleQuestionHelpCloseModal,
    } = useModalOpener();

    const handleOptionChange = (value: string) => {
        if (stepLoading) return;
        const newSelectedOption = selectedOption === value ? undefined : value;
        setSelectedOption(newSelectedOption);
        onAnswer(data.question, newSelectedOption);
    };

    const checkModalDepends = useCallback((depend: Depend[], categoryAnswers?: Record<string, any>) => {
        return categoryAnswers ? checkDepends(depend, categoryAnswers) : false;
    }, []);

    useEffect(() => {
        setSelectedOption(initialValue);
    }, [initialValue]);

    useEffect(() => {
        if (data?.promptModal?.depend && categoryAnswers) {
            const shouldModalShow = checkModalDepends(data.promptModal?.depend, categoryAnswers);
            setShowModalOpener(shouldModalShow);
            if (shouldModalShow && !data.promptModal?.manualOpen) {
                handleQuestionHelpOpenModal();
            }
        }
    }, [categoryAnswers, checkModalDepends, data?.promptModal, handleQuestionHelpOpenModal]);

    return (
        <div className="new-questionnaire__question" style={{ maxWidth: "335px", margin: "0 auto" }}>
            {data.questionTitle && (
                <div className="new-questionnaire__list-question-title" >{data.questionTitle}</div>
            )}
            <div className="new-questionnaire__question-text" style={data.questionTitle ? { margin: "4px auto" } : {}}>
                {data.question}
                {showModalOpener && (
                    <span className="new-questionnaire__question-icon" onClick={handleQuestionHelpOpenModal}>
                        <img src={questionIcon} width={20} alt="help"/>
                    </span>
                )}
            </div>

            <div className="new-questionnaire__select-one-optionV2__question-options">
                {data?.options?.map((value, index) => (
                    <div
                        key={index}
                        className={`new-questionnaire__select-one-optionV2__question-option${selectedOption === value ? " selected" : stepLoading ? " disabled" : ""}`}
                        onClick={() => handleOptionChange(value)}
                    >
                        {data.iconMapping && (
                            <div>
                                <img width={30} height={30} src={data.iconMapping[value]} alt="icon" style={{ display: "block" }} />
                            </div>
                        )}
                        <div className="new-questionnaire__select-many-tiles__text">
                            {data.iconMapping ? removeEmojis(value) : value}
                        </div>
                    </div>
                ))}
            </div>
            {isQuestionHelpModalOpened && (
                <PromptModal
                    isOpen={isQuestionHelpModalOpened}
                    closeModal={handleQuestionHelpCloseModal}
                    handlePrev={handlePrev}
                    data={promptModalData}
                />
            )}
        </div>
    );
};

export default SelectOneTileQuestion;
