import "styles/buttonFooter.less";

import React, { useContext } from "react";

import { AppContext } from "App";
import { Link } from "react-router-dom";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

const AuthButtonFooter: React.FC = () => {
    const { isMobile } = useContext(AppContext);
    const isKeyboardOpen = useDetectKeyboardOpen();

    if (!isMobile || isKeyboardOpen) return null;
    return (
        <div className="button-footer">
            <div className="footer-auth__subtitle">
                First time using TaxZap?
                <span>
                    <Link
                        to="/sign-up?step=personalInfo"
                        className="footer-start__link"
                    >
                        Start Now!
                    </Link>
                </span>
            </div>
        </div>
    );
};

export default AuthButtonFooter;
