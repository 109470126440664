import { FC, useContext, useEffect, useState } from "react";

import { AppContext } from "App";
import backChevron from "assets/images/new-questionnaire/back-chevron.svg";
import Spinner from "components/basic/Spinner/Spinner";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { next } from "redux/reducers/navigation";
import { useGetPaymentStatusMutation } from "services/payments";
import { IUser } from "types/auth";
import { logError } from "utils/sentry";

import { Paywall } from "./components/Paywall";
import { Step } from "../../constants/questions";

type PaymentScreenProps = {
    currentStepData: Step;
    handlePrev: () => void;
    handleContinue: () => void;
    answers: Record<string, Record<string, any>>
    user: IUser;
};
const PaymentScreen: FC<PaymentScreenProps> = ({
    user,
    currentStepData,
    handlePrev,
}) => {
    const dispatch = useDispatch();
    const { currentCategory, currentStep } = useAppSelector(state => state.navigation);
    const { isMobile } = useContext(AppContext);
    const [getPaymentStatus] = useGetPaymentStatusMutation();
    const [isLoading, setIsLoading] = useState(true);

    const continueToSubmit = () => {
        dispatch(next({
            nextCategory: currentCategory,
            nextStep: currentStep + 1,
            nextQuestion: 0,
            nextProfileQuestion: 0,
        }));
    };

    useEffect(() => {
        const fetchPaymentStatus = async () => {
            try {
                const status = await getPaymentStatus().unwrap();
                if (status?.paymentStatus?.paid) {
                    continueToSubmit();
                }
            } catch (error) {
                const errorContext = {
                    clientId: user?.id,
                    message: "Error fetching payment status",
                    function: "fetchPaymentStatus",
                };
                logError(error, errorContext);
            } finally {
                setIsLoading(false);
            }
        };
        fetchPaymentStatus();
        // eslint-disable-next-line
    }, [getPaymentStatus]);

    if (isLoading) {
        return (
            <Spinner isLoading={isLoading} data-testid="spinner"/>
        );
    }

    return (
        <>
            {!isMobile && (
                <div className="new-questionnaire__desktop-form-left">
                    {currentStepData.hasBack && (
                        <div className="new-questionnaire__desktop-form-back" onClick={() => handlePrev()}>
                            <img src={backChevron} alt="back"/>
                            <div className="new-questionnaire__desktop-form-back-text">Back</div>
                        </div>
                    )}
                </div>
            )}
            <div className={`payment-screen-container${isMobile ? "-mobile" : "-desktop"}`} data-testid="payment-page">
                <Paywall showReturnToQuestionnaire={false} handleContinue={continueToSubmit}/>
            </div>
        </>
    );
};

export default PaymentScreen;
