import React, { useCallback, useMemo } from "react";

import { message } from "antd";
import mortgage from "assets/images/profile/documents/mortgage.svg";
import mortgageSelected from "assets/images/profile/documents/mortgage_selected.svg";
import { useAppDispatch } from "redux/hooks";
import { getCurrentUser } from "redux/reducers/auth";
import { useDeleteDocumentFileMutation, useUpdateUserDocumentMutation } from "services/auth";
import { fetchFile, useUploadFileMutation } from "services/files";
import { IUser } from "types/auth";
import { IFile } from "types/files";
import { v4 as uuidv4 } from "uuid";

import Category from "./Category";

type DocumentProps = {
    document: any;
    user: IUser;
};

const Document: React.FC<DocumentProps> = ({ document, user }) => {
    // const { isMobile } = useContext(AppContext);
    const dispatch = useAppDispatch();

    const [deleteDocumentFileAsync, { isLoading: isDeleting }] = useDeleteDocumentFileMutation();
    const [updateUserDocument, { isLoading: isUserUpdating }] = useUpdateUserDocumentMutation();
    const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation();

    const uploadQFile = useCallback(async (file: IFile, category: string, name: string) => {
        const uploadedFileResponse = await uploadFile({
            file: file,
            path: `clients/${user.id}/receipts`,
            type: category,
        }).unwrap();

        if (!uploadedFileResponse?.data?.url) return false;

        const uploadedFileUrl = uploadedFileResponse.data.url;
        const currentCategoryFiles = document.files[category]?.docs;

        const updatedFiles = [...currentCategoryFiles, {
            originalName: file.name,
            url: uploadedFileUrl,
            name: name,
            createdAt: new Date(),
            _id: uuidv4(),
            warning: uploadedFileResponse.data.warning,
        }];

        updateUserDocument({
            documentId: document._id,
            files: {
                requiredDocuments: document.files[category].requiredDocuments,
                docs: updatedFiles,
                allowAdditionalFiles: document.files[category].allowAdditionalFiles,
            },
            category,
        }).unwrap()
            .then(async () => {
                dispatch(getCurrentUser());
                await message.success("File uploaded successfully");
            })
            .catch(async error => {
                await message.error(error.data.message);
            });

        return true;
    }, [dispatch, document._id, document.files, updateUserDocument, uploadFile, user.id]);

    // const uploadReceipt = useCallback(async (file: IFile, category: string, name: string) => {
    //     const uploadedFileResponse = await uploadFile({
    //         file: file,
    //         path: `clients/${user.id}/receipts`,
    //     }).unwrap();

    //     if (!uploadedFileResponse?.data?.url) return false;

    //     const uploadedFileUrl = uploadedFileResponse.data.url;
    //     const currentCategoryReceipts = document.receipts[category];
    //     const updatedReceipts = [...currentCategoryReceipts, {
    //         originalName: file.name,
    //         url: uploadedFileUrl,
    //         name: name,
    //     }];

    //     updateUserDocument({
    //         documentId: document._id,
    //         receipts: updatedReceipts,
    //         category,
    //     }).unwrap()
    //         .then(async () => {
    //             dispatch(getCurrentUser());
    //             await message.success("File uploaded successfully");
    //         })
    //         .catch(async error => {
    //             await message.error(error.data.message);
    //         });

    //     return true;
    // }, [dispatch, document._id, document.receipts, updateUserDocument, uploadFile, user.id]);

    const deleteQFile = useCallback(async (id: string, category: string): Promise<boolean> => {
        try {
            await deleteDocumentFileAsync({
                documentId: document._id,
                data: {
                    fileId: id,
                    category,
                    fileType: "files",
                },
            }).unwrap();

            dispatch(getCurrentUser());
            await message.success("File deleted successfully");
            return true;
        } catch (error: any) {
            await message.error(error?.data?.message);
            return false;
        }
    }, [deleteDocumentFileAsync, dispatch, document._id]);

    // const deleteReceipt = useCallback(async (id: string, category: string): Promise<boolean> => {
    //     try {
    //         await deleteDocumentReceipt({
    //             documentId: document._id,
    //             receiptId: id,
    //             category,
    //         }).unwrap();

    //         dispatch(getCurrentUser());
    //         await message.success("File deleted successfully");
    //         return true;
    //     } catch (error: any) {
    //         await message.error(error?.data?.message);
    //         return false;
    //     }
    // }, [deleteDocumentReceipt, dispatch, document._id]);

    const getReceiptFile = useCallback(async (url: string, originalName: string) => {
        const fetchedFileBlob = await fetchFile(url);
        if (fetchedFileBlob) {
            const hiddenElement = window.document.createElement("a");
            const url = window.URL || window.webkitURL;
            hiddenElement.href = url.createObjectURL(fetchedFileBlob);
            hiddenElement.target = "_blank";
            hiddenElement.download = originalName;
            hiddenElement.click();
        }
    }, []);

    // const filesInCategories = useMemo(() => {
    //     const isFilesInCategories: Record<string, boolean> = {};
    //     if (document && document.receipts) {
    //         Object.keys(document.receipts).forEach(category => {
    //             isFilesInCategories[category] = document.receipts[category].length > 0;
    //         });
    //     }
    //     return isFilesInCategories;
    // }, [document]);

    const questionnaireFiles = useMemo(() => {
        return Object.keys(document?.files || {}).map(categoryKey => {
            const categoryData = document?.files[categoryKey];
            const filesInCategory = (categoryData?.docs || []).length;

            return (<div key={categoryKey}>
                <Category
                    requiredDocuments={categoryData?.requiredDocuments}
                    files={categoryData?.docs || []}
                    uploadFile={uploadQFile}
                    deleteFile={deleteQFile}
                    getFile={getReceiptFile}
                    allowAdditionalFiles={categoryData.allowAdditionalFiles}
                    isLoading={isUploading || isDeleting || isUserUpdating}
                    categoryKey={categoryKey}
                    categoryLabel={categoryKey}
                    filesInCategory={filesInCategory}
                    iconSelected={mortgageSelected}
                    icon={mortgage}
                />
            </div>
            );
        });
    }, [deleteQFile, document?.files, getReceiptFile, isDeleting, isUploading, isUserUpdating, uploadQFile]);

    return (
        <div className="documents__item">
            {questionnaireFiles}
            {/* {!isMobile && <Divider />}
            <Category
                requiredDocuments={[]}
                files={document.receipts ? document.receipts[RECEIPT_CATEGORY.WORKING_FROM_HOME] : []}
                uploadFile={uploadReceipt}
                deleteFile={deleteReceipt}
                getFile={getReceiptFile}
                allowAdditionalFiles={true}
                isLoading={isUploading || isUserUpdating || isReceiptDeleteLoading}
                categoryKey={RECEIPT_CATEGORY.WORKING_FROM_HOME}
                categoryLabel="Work from home expenses"
                filesInCategory={filesInCategories[RECEIPT_CATEGORY.WORKING_FROM_HOME]}
                iconSelected={workSelected}
                icon={work}
            />
            {!isMobile && <Divider />}
            <Category
                requiredDocuments={[]}
                files={document.receipts ? document.receipts[RECEIPT_CATEGORY.CARER] : []}
                uploadFile={uploadReceipt}
                deleteFile={deleteReceipt}
                getFile={getReceiptFile}
                allowAdditionalFiles={true}
                isLoading={isUploading || isUserUpdating || isReceiptDeleteLoading}
                categoryKey={RECEIPT_CATEGORY.CARER}
                categoryLabel="Home care expenses"
                filesInCategory={filesInCategories[RECEIPT_CATEGORY.CARER]}
                iconSelected={careSelected}
                icon={care}
            />
            {!isMobile && <Divider />}
            <Category
                requiredDocuments={[]}
                files={document.receipts ? document.receipts[RECEIPT_CATEGORY.MEDICAL] : []}
                uploadFile={uploadReceipt}
                deleteFile={deleteReceipt}
                getFile={getReceiptFile}
                allowAdditionalFiles={true}
                isLoading={isUploading || isUserUpdating || isReceiptDeleteLoading}
                categoryKey={RECEIPT_CATEGORY.MEDICAL}
                categoryLabel="Health expenses"
                filesInCategory={filesInCategories[RECEIPT_CATEGORY.MEDICAL]}
                iconSelected={healthSelected}
                icon={health}
            />
            {!isMobile && <Divider />}
            <Category
                requiredDocuments={[]}
                files={document.receipts ? document.receipts[RECEIPT_CATEGORY.THIRD_LEVEL_EDUCATION] : []}
                uploadFile={uploadReceipt}
                deleteFile={deleteReceipt}
                getFile={getReceiptFile}
                allowAdditionalFiles={true}
                isLoading={isUploading || isUserUpdating || isReceiptDeleteLoading}
                categoryKey={RECEIPT_CATEGORY.THIRD_LEVEL_EDUCATION}
                categoryLabel="Education expenses"
                filesInCategory={filesInCategories[RECEIPT_CATEGORY.THIRD_LEVEL_EDUCATION]}
                iconSelected={educationSelected}
                icon={education}
            /> */}
        </div>
    );
};

export default Document;
