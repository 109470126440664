import "styles/profile/documents.less";

import { Dispatch, SetStateAction, useContext, useEffect, useMemo } from "react";

import { Typography } from "antd";
import { AppContext } from "App";
import SmallStatus from "components/common/SmallStatus";
import { IUser } from "types/auth";
import { checkCurrentReturn, getCurrentReturn } from "utils/returns";
import { formatDate } from "utils/strings";

import Document from "./Document";
import NoCurrentReturn from "./NoCurrentReturn";

type DocumentsProps = {
    user: IUser;
    setPageLoading: Dispatch<SetStateAction<boolean>>
};

export default function Documents({ user, setPageLoading }: DocumentsProps) {
    const { isMobile } = useContext(AppContext);

    const currentReturn = useMemo(() => {
        return getCurrentReturn(user.documents);
    }, [user.documents]);

    const hasCurrentReturn = useMemo(() => checkCurrentReturn(currentReturn), [currentReturn]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setPageLoading(false);
        }, 200);
        return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="profile__content">
            {!isMobile && <div className="profile__content-header">
                <Typography className="profile__content-heading" style={{ width: "345px" }}>
                    Documents
                </Typography>
            </div>}
            <div className="documents__content">
                {currentReturn && hasCurrentReturn
                    ? <div className="documents__content-item">
                        <div className="documents__content-item-header">
                            {`Submission ${formatDate(currentReturn.createdAt)}`}
                            <SmallStatus status={currentReturn.status} />
                        </div>
                        <Document
                            document={currentReturn}
                            user={user}
                        />
                    </div>
                    : <NoCurrentReturn/>
                }
            </div>
        </div>
    );
}
