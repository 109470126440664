import "styles/header.less";

import { useMemo } from "react";

import { Layout } from "antd";
import avatar from "assets/images/new-questionnaire/questionnaire-avatar.svg";
import backMobile from "assets/images/profile/back_mobile.svg";
import taxzapLogo from "assets/images/taxzap-logo-R.svg";
import { useNavigate } from "react-router-dom";

const { Header } = Layout;

type MobileHeaderProps = {
    selectedTab: string;
    setSelectedTab: (_tab: string) => void;
    handleNotificationsClick: () => void;
};

const MobileHeader = ({
    selectedTab,
    setSelectedTab,
    handleNotificationsClick,
}: MobileHeaderProps) => {
    const navigate = useNavigate();

    const headerContent = useMemo(() => {
        switch (selectedTab) {
        case "Homepage":
            return (
                <Header className="header tab-header">
                    <img
                        className="header-burger-menu__logo"
                        src={taxzapLogo}
                        alt="TaxZap Logo"
                        onClick={() => navigate("/")}
                    />
                    <div
                        className="profile__notification"
                        onClick={handleNotificationsClick}
                    >
                        <img
                            width={30}
                            src={avatar}
                            alt="Notification Icon"
                            style={{
                                marginRight: "10px",
                            }}
                        />
                    </div>
                </Header>
            );
        case "Profile Bank Details":
            return (
                <Header className="header tab-header">
                    <img
                        src={backMobile}
                        alt="back"
                        onClick={() => setSelectedTab("Homepage")}
                    />
                    <h3 className="tab-header-title">Bank Details</h3>
                    <div style={{ width: "48px", height: "48px" }} />
                </Header>
            );
        case "Payment":
            return (
                <Header className="header tab-header">
                    <img
                        src={backMobile}
                        alt="back"
                        onClick={() => setSelectedTab("Homepage")}
                    />
                    <h3 className="tab-header-title">
                        {selectedTab === "Payment" ? "Payment" : "Search your bank"}
                    </h3>
                    <div style={{ width: "48px", height: "48px" }} />
                </Header>
            );
        default:
            return (
                <Header className="header tab-header">
                    <img
                        src={backMobile}
                        alt="back"
                        onClick={() => setSelectedTab("Homepage")}
                    />
                    <h3 className="tab-header-title">{selectedTab}</h3>
                    <div style={{ width: "48px", height: "48px" }} />
                </Header>
            );
        }
    }, [
        handleNotificationsClick,
        navigate,
        selectedTab,
        setSelectedTab,
    ]);

    return <div className="profile-mobile-header-wrapper">{headerContent}</div>;
};

export default MobileHeader;
