import { ProfileType } from "components/pages/NewQuestionnaire/enums/enum";

import { api } from "./connection/api";
import {
    GetAccountsByInstitutionBody,
    GetAllAccountsByInstitutionResponse,
    GetAllConnectedAccountsResponse,
    IUpdateCategoryBulkTransactionsGroupsBody,
    IUpdateCategoryTransactionsGroupsBody,
} from "../types/bankAccounts";

export const paymentBankApi = api.injectEndpoints({
    endpoints: builder => ({
        // -------------- Bank Accounts --------------
        fetchConnectedBankAccounts: builder.query<GetAllConnectedAccountsResponse, void>({
            query: () => ({
                url: "/client/bankAccounts?limit=100",
                method: "GET",
            }),
        }),
        getAllAccountsByInstitution: builder.mutation<GetAllAccountsByInstitutionResponse, GetAccountsByInstitutionBody>({
            query: ({ institution }) => ({
                url: `/client/bankAccounts?institution=${institution}`,
                method: "GET",
            }),
        }),
        getAccountById: builder.mutation<any, any>({
            query: ({ accountId }) => ({
                url: `client/bankAccounts/${accountId}`,
                method: "GET",
            }),
        }),
        // -------------- Transactions --------------
        getClientTransactions: builder.query<any, {query: string}>({
            query: ({ query }) => ({
                url: "client/transactions?" + query,
                method: "GET",
            }),
        }),

        getClientTotalClaimedBusinessExpenses: builder.query<any, {taxProfileId: string}>({
            query: ({ taxProfileId }) => {
                const encodedProfileId = encodeURIComponent(taxProfileId);
                return {
                    url: `client/transactions?amount[lte]=0&swiped.approved=true&swiped.rejected=false&swiped.byTaxProfile.${encodedProfileId}.approved=true&swiped.byTaxProfile.${encodedProfileId}.rejected=false&sort=-1`,
                    method: "GET",
                };
            },
        }),

        patchClientTransaction: builder.mutation<any, {transactionId: string; updateData: { [key: string]: any }}>({
            query: ({ transactionId, updateData }) => {
                return {
                    url: `client/transactions/${transactionId}`,
                    method: "PATCH",
                    body: updateData,
                };
            },
        }),

        patchClientTransactionAndTaxProfile: builder.mutation<any, {transactionId: string; taxProfileId: string, taxProfileType: ProfileType, updateData: { [key: string]: any }}>({
            query: ({ transactionId, taxProfileId, taxProfileType, updateData }) => {
                const encodedProfileId = encodeURIComponent(taxProfileId);
                const encodedProfileType = encodeURIComponent(taxProfileType);
                return {
                    url: `client/transactions/${transactionId}/update-transaction-tax-profile?taxProfileId=${encodedProfileId}&taxProfileType=${encodedProfileType}`,
                    method: "PATCH",
                    body: updateData,
                };
            },
        }),

        bulkApproveOrRejectTransaction: builder.mutation<{ message: string; id: string }, { transactionIds: string[]; taxProfileId: string; taxProfileType: ProfileType; updateData: { [key: string]: any } }>({
            query: ({ transactionIds, updateData, taxProfileId, taxProfileType }) => ({
                url: `client/transactions/bulk-approve-reject?taxProfileId=${taxProfileId}&taxProfileType=${taxProfileType}`,
                method: "PATCH",
                body: { transactionIds, updateData },
            }),
        }),
        // -------------- Transaction Categories --------------
        getTransactionsCategories: builder.query<any, {taxProfileType: ProfileType}>({
            query: ({ taxProfileType }) => {
                const encodedProfileType = encodeURIComponent(taxProfileType);

                return {
                    url: `client/transactions/categories?taxProfileType=${encodedProfileType}`,
                    method: "GET",
                };
            },
        }),
        // -------------- Transaction Groups --------------
        getAllCategoryTransactionsGroups: builder.query<any, {taxProfileType: ProfileType, }>({
            query: ({ taxProfileType }) => {
                const encodedProfileType = encodeURIComponent(taxProfileType);

                return {
                    url: `client/transactions/groups?taxProfileType=${encodedProfileType}`,
                    method: "GET",
                };
            },
        }),
        getCategoryTransactionsGroups: builder.query<any, {taxProfileId: string, taxProfileType: ProfileType, category: string}>({
            query: ({ taxProfileId, taxProfileType, category }) => {
                const encodedProfileId = encodeURIComponent(taxProfileId);
                const encodedProfileType = encodeURIComponent(taxProfileType);
                const encodedCategory = encodeURIComponent(category);
                const url = `client/transactions/groups?taxProfileType=${encodedProfileType}&category=${encodedCategory}&taxProfileId=${encodedProfileId}`;
                return {
                    url: url,
                    method: "GET",
                };
            },
            providesTags: ["CategoryTransactionsGroups"],
        }),
        updateCategoryTransactionsGroup: builder.mutation<any, IUpdateCategoryTransactionsGroupsBody>({
            query: ({ groupId, taxProfileId, ...data }: IUpdateCategoryTransactionsGroupsBody) => {
                const encodedProfileId = encodeURIComponent(taxProfileId);

                return {
                    url: `client/transactions/groups/${groupId}?taxProfileId=${encodedProfileId}`,
                    method: "PATCH",
                    body: data,
                };
            },
        }),
        bulkUpdateCategoryTransactionsGroup: builder.mutation<any, { bulkUpdateData: IUpdateCategoryBulkTransactionsGroupsBody[]; taxProfileId: string }>({
            query: ({ bulkUpdateData, taxProfileId }) => {
                const encodedProfileId = encodeURIComponent(taxProfileId);

                return {
                    url: `client/transactions/groups/bulk?taxProfileId=${encodedProfileId}`,
                    method: "PATCH",
                    body: { data: bulkUpdateData },
                };
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useFetchConnectedBankAccountsQuery,
    useGetClientTransactionsQuery,
    useGetClientTotalClaimedBusinessExpensesQuery,
    useGetTransactionsCategoriesQuery,
    useGetAllCategoryTransactionsGroupsQuery,
    useGetCategoryTransactionsGroupsQuery,
    useGetAllAccountsByInstitutionMutation,
    useGetAccountByIdMutation,
    useUpdateCategoryTransactionsGroupMutation,
    useBulkUpdateCategoryTransactionsGroupMutation,
    usePatchClientTransactionMutation,
    usePatchClientTransactionAndTaxProfileMutation,
    useBulkApproveOrRejectTransactionMutation,
    endpoints: { fetchConnectedBankAccounts, getAllAccountsByInstitution, getAccountById },
} = paymentBankApi;
