import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { markCategoryCompleted, markCategoryInProgress, selectCurrentNavigation, selectNavigationItems } from "redux/reducers/navigation";
import { findNextCategory, sortNavigationItems } from "utils/navigation";

import SectionDoneProgress from "./SectionDoneProgress";
import { categories, Step } from "../../constants/questions";
import { CategoryTitle } from "../../enums/enum";

type CategoryDoneProps = {
    currentStepData: Step;
    handleContinue: () => void;
};

const CategoryDoneMobile: React.FC<CategoryDoneProps> = ({
    currentStepData,
    handleContinue,
}) => {
    const dispatch = useDispatch();
    const { currentCategory } = useAppSelector(selectCurrentNavigation);
    const navigationItems = useAppSelector(selectNavigationItems);

    const [nextCategoryTitle, setNextCategoryTitle] = useState<string | null>(null);
    const [allSectionCount, setAllSectionCount] = useState(0);
    const [doneSectionsCount, setDoneSectionsCount] = useState(0);

    useEffect(() => {
        const categoryTitle: CategoryTitle = categories[currentCategory].title;
        dispatch(markCategoryCompleted(categoryTitle));

        const nextCategory = findNextCategory(currentCategory, navigationItems);
        if (nextCategory) {
            const nextTitle = categories[nextCategory].title;
            dispatch(markCategoryInProgress(nextTitle));
            setNextCategoryTitle(nextTitle);
        }

        const sortedNavigationItems = sortNavigationItems(navigationItems);

        setDoneSectionsCount(sortedNavigationItems.findIndex(item => item === categoryTitle));

        setAllSectionCount(navigationItems.length - 1);

        const timer = setTimeout(() => {
            handleContinue();
        }, 3500);
        return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="mt20">
                <div style={{ width: "100%" }}>
                    <div className="new-questionnaire__form-title taCenter">{currentStepData.title}</div>
                    {currentStepData.isAnimation && currentStepData.animation && (
                        <div className="new-questionnaire__desktop-form-image">
                            <SectionDoneProgress
                                allSectionCount={allSectionCount}
                                doneSectionsCount={doneSectionsCount}
                                nextCategoryTitle={nextCategoryTitle}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default CategoryDoneMobile;
