import { CSSProperties, FC, useContext, useMemo } from "react";

import { Modal, Image } from "antd";
import { AppContext } from "App";
import backPurp from "assets/images/new-questionnaire/back_purp.svg";
import boltWhiteImg from "assets/images/new-questionnaire/onboarding/bolt-white.svg";
import DOMPurify from "dompurify"; // To sanitize HTML input

import { PromptModalDataType } from "../../constants/questions";
import { PromptModalType } from "../../enums/enum";

type PromptModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    handlePrev: () => void;
    unselectOption?: (_value: string) => void;
    imageWidth?: number;
    closeButtonText?: string;
    closeButtonStyle?: CSSProperties;
    data: PromptModalDataType;
    closeable?: boolean;
    domPurifyAddAttribs?: string[];
};

const PromptModal: FC<PromptModalProps> = ({ isOpen, closeModal, handlePrev, unselectOption, data, imageWidth, closeButtonText, closeButtonStyle, closeable: propClosable, domPurifyAddAttribs }) => {
    const { isMobile } = useContext(AppContext);
    const { title, text, image, promptActionButtonText, promptActionButtonUrl, type, cancelButtonText, confirmButtonText, optionToConfirm, onCancelUnselect } = data;

    const isClosable = useMemo(() => {
        if (propClosable !== undefined) return propClosable;
        return !(type === PromptModalType.agreement || type === PromptModalType.confirmation);
    }, [propClosable, type]);

    const formattedText = text && DOMPurify.sanitize(text
        .replace(/\n/g, "<br />"), { ADD_ATTR: domPurifyAddAttribs || [] })
        .replace(/<a\s(.*?)>/g, "<a $1 target=\"_blank\" rel=\"noopener noreferrer\" class=\"modal-link-html\">");

    const renderFooterContent = () => {
        switch (type) {
        case PromptModalType.agreement:
            return (
                <div>
                    <div
                        className="new-questionnaire__onboarding-welcome-button-purple mt20 mAuto"
                        onClick={closeModal}
                    >
                        <img src={boltWhiteImg} alt="Accept & Continue" className="mr6" />
                        <div className="select-bank__continue-button-text">Accept & Continue</div>
                    </div>
                    <div
                        className="new-questionnaire__desktop-form-back-button"
                        onClick={() => handlePrev && handlePrev()}
                    >
                        <img src={backPurp} alt="Go Back" className="mr6" />
                        <div>Go Back</div>
                    </div>
                </div>
            );

        case PromptModalType.confirmation:
            return (
                <div className="new-questionnaire__shared-prompt-modal__button-container">
                    <div
                        className="new-questionnaire__desktop-form-primary-button mt20"
                        onClick={closeModal}
                    >
                        {confirmButtonText || "Confirm"}
                    </div>
                    <div
                        className="new-questionnaire__desktop-form-secondary-button mt20"
                        onClick={() => {
                            (onCancelUnselect
                                ? unselectOption && optionToConfirm && unselectOption(optionToConfirm)
                                : handlePrev()
                            );
                            closeModal();
                        }}
                    >
                        {cancelButtonText || "Cancel"}
                    </div>
                </div>
            );

        case PromptModalType.info:
        default:
            return (
                <div className="new-questionnaire__shared-prompt-modal__button-container">
                    {promptActionButtonText && promptActionButtonUrl && (
                        <a
                            href={promptActionButtonUrl}
                            target="_blank"
                            rel="noopener noreferrer" // Security best practice when using target="_blank"
                        >
                            <div className="new-questionnaire__desktop-form-primary-button mt20">
                                {promptActionButtonText}
                            </div>
                        </a>
                    )}
                    <div
                        className="new-questionnaire__desktop-form-secondary-button mt20"
                        onClick={closeModal}
                        style={closeButtonStyle ? closeButtonStyle : undefined}
                    >
                        {closeButtonText || "Close"}
                    </div>
                </div>
            );
        }
    };

    return (
        <Modal
            open={isOpen}
            onCancel={isClosable ? closeModal : undefined}
            maskClosable={isClosable}
            closable={isClosable}
            keyboard={isClosable}
            footer={null}
            className="new-questionnaire__shared-prompt-modal"
            centered
            styles={{
                content: {
                    borderRadius: "20px",
                    padding: "20px",
                },
            }}
        >
            {image && <div className="w100 center">
                <Image
                    preview={false}
                    width={imageWidth || 120}
                    src={image}
                />

            </div>}
            {title && <div className={isMobile ? "mb20 mt10 modal-header poppins center" : "p20 modal-header poppins center"}>
                {title}
            </div>}
            <div
                className={ "poppins grey-6-color text-align-left" }
                dangerouslySetInnerHTML={{ __html: formattedText }} // Safe rendering of HTML
            />

            {renderFooterContent()}
        </Modal>
    );
};

export default PromptModal;
