import "styles/floatInput.less";

import {
    useState,
    ChangeEvent,
    useMemo,
    FC,
    useEffect,
    useContext,
} from "react";

import { Input, Select } from "antd";
import { AppContext } from "App";
import britainFlag from "assets/images/form/gb-flag.svg";
import irelandFlag from "assets/images/form/ireland-flag.svg";

interface PhoneInputWithCountryCodeProps {
    label: string;
    value?: string;
    error?: string;
    className?: string;
    dataTestId?: string;
    disabled?: boolean;
    disableLabels?: boolean;
    index?: number;
    onChange?: (_fullPhoneNumber: string) => void;
}

const PhoneInputWithCountryCode: FC<PhoneInputWithCountryCodeProps> = ({
    label,
    value = "",
    error,
    onChange,
    className,
    disabled,
    disableLabels,
    index,
    dataTestId,
}) => {
    const { isMobile } = useContext(AppContext);

    const [inputFocus, setInputFocus] = useState(false);
    const [selectFocus, setSelectFocus] = useState(false);

    const firstSpaceIndex = value.indexOf(" ");

    const initialCountryCode = useMemo(() => {
        return firstSpaceIndex !== -1
            ? value.substring(0, firstSpaceIndex)
            : "+353";
    }, [value, firstSpaceIndex]);

    const initialPhoneNumber = useMemo(() => {
        return firstSpaceIndex !== -1
            ? value.substring(firstSpaceIndex + 1)
            : "";
    }, [value, firstSpaceIndex]);

    const [countryCode, setCountryCode] = useState(initialCountryCode);
    const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);

    const isOccupied = useMemo(
        () => inputFocus || phoneNumber,
        [inputFocus, phoneNumber]
    );
    const isSelectOccupied = useMemo(
        () => selectFocus || countryCode,
        [selectFocus, countryCode]
    );

    const InputLabelClass = useMemo(() => {
        const baseClass = "label";
        const isLongLabel = label.length > 40;
        const isVeryLongLabel = label.length > 65;

        let computedLabelClass = isOccupied
            ? `${baseClass} as-label`
            : `${baseClass} as-placeholder`;

        if (isOccupied && error) {
            computedLabelClass += " text-error";
        }

        if (isMobile) {
            if (!isOccupied && (isLongLabel || isVeryLongLabel)) {
                computedLabelClass += " two-line-placeholder";
            }

            if (isOccupied && (isLongLabel || isVeryLongLabel)) {
                computedLabelClass += " two-line-label";
            }
        } else {
            if (!isOccupied && isVeryLongLabel) {
                computedLabelClass += " two-line-placeholder";
            }

            if (isOccupied && isVeryLongLabel) {
                computedLabelClass += " two-line-label";
            }
        }

        return computedLabelClass;
    }, [isOccupied, error, label.length, isMobile]);

    const selectLabelClass = useMemo(() => {
        let computedLabelClass = isSelectOccupied
            ? "label as-label"
            : "label as-placeholder";
        if (isSelectOccupied && error) computedLabelClass += " text-error";
        return computedLabelClass;
    }, [isSelectOccupied, error]);

    const handleBlur = () => setInputFocus(false);
    const handleFocus = () => setInputFocus(true);

    const handleSelectBlur = () => setSelectFocus(false);
    const handleSelectFocus = () => setSelectFocus(true);

    const handleCountryCodeChange = (newCountryCode: string) => {
        setCountryCode(newCountryCode);
        if (onChange) {
            onChange(newCountryCode + " " + phoneNumber);
        }
    };

    const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newPhoneNumber = event.target.value;
        setPhoneNumber(newPhoneNumber);
        if (onChange) {
            onChange(countryCode + " " + newPhoneNumber);
        }
    };

    useEffect(() => {
        setCountryCode(initialCountryCode);
        setPhoneNumber(initialPhoneNumber);
    }, [initialCountryCode, initialPhoneNumber]);

    return (
        <div
            className={className}
            style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
                width: "100%",
            }}
        >
            <div
                className="float-label"
                onBlur={handleSelectBlur}
                onFocus={handleSelectFocus}
            >
                <Select
                    disabled={disabled}
                    value={countryCode}
                    onChange={handleCountryCodeChange}
                    className="phone-input-country-code"
                >
                    <Select.Option
                        className="phone-input-country-code-item"
                        value="+353"
                    >
                        <div className="phone-input-country-code-select-item">
                            <img src={irelandFlag} width={25} alt="ireland flag"/>
                            <p style={{ margin: "0px 0px 0px 10px" }}>
                                +353
                            </p>
                        </div>
                    </Select.Option>
                    <Select.Option
                        className="phone-input-country-code-item"
                        value="+44"
                    >
                        <div className="phone-input-country-code-select-item">
                            <img src={britainFlag} width={25} alt="ireland flag"/>
                            <p style={{ margin: "0px 0px 0px 10px" }}>
                                +44
                            </p>
                        </div>
                    </Select.Option>
                </Select>
                {!disableLabels && (
                    <label className={selectLabelClass}>Country</label>
                )}
            </div>
            <div
                className="float-label"
                onBlur={handleBlur}
                onFocus={handleFocus}
                style={{ width: "100%" }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Input
                        className="input-field"
                        value={phoneNumber}
                        data-testid={dataTestId}
                        onChange={handlePhoneNumberChange}
                        disabled={disabled}
                        style={{ marginLeft: 10 }}
                        autoFocus={index === 0}
                        inputMode='numeric'
                    />
                </div>
                {!disableLabels && (
                    <label
                        className={InputLabelClass}
                        style={{ marginLeft: 10 }}
                    >
                        {label}
                    </label>
                )}
            </div>
        </div>
    );
};

export default PhoneInputWithCountryCode;
