import ReactGA from "react-ga4";

const sendGaEvent = (action: string, category?: string) => {
    if (category) {
        ReactGA.event({
            action: action,
            category: category,
        });
    } else {
        ReactGA.event(action);
    }
};

export default sendGaEvent;
