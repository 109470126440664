import { api } from "./connection/api";
import { ApiPlaidResponse, ApiRegionResponse } from "../types/connectBank";

export interface Bank {
  message: string;
  count: number;
  institutions:[];
}

interface ConnectBankQuery {
  countryCode: string;
}

interface PushConnectBankPayload {
  Id: string;
}

interface GetLinkTokenPayload {
}

interface PushLinkTokenPayload {
   publicToken: string;
   linkToken: string;
}

interface PushConnectBankQuestionnairePayload {
    Id: string;
    bankDetailsOnly: boolean
}

interface CompleteConnectionPayload {
  ref: any;
  bankDetailsOnly: boolean
}

interface InstitutionConnection {
    id: string;
    name: string;
}

interface CompleteConnectionData {

        data: {
            institutionConnection: InstitutionConnection;

    }

}

type CompleteConnectionResponse = CompleteConnectionData

export const connectBankApi = api.injectEndpoints({
    endpoints: builder => ({
        getBanks: builder.mutation<Bank, ConnectBankQuery>({
            query: ({ countryCode }) => ({
                url: `/institutions/region?regions=${countryCode}`,
                method: "GET",
            }),
        }),

        pushBank: builder.mutation<ApiRegionResponse, PushConnectBankPayload>({
            query: ({ Id }) => ({
                url: `/institutions/${Id}/connect/profile`,
                method: "POST",
            }),
        }),

        pushBankQuestionnaire: builder.mutation<ApiRegionResponse, PushConnectBankQuestionnairePayload>({
            query: ({ Id, bankDetailsOnly }) => ({
                url: `/institutions/${Id}/connect/questionnaire`,
                method: "POST",
                params: { bankDetailsOnly },
            }),
        }),

        completeConnection: builder.mutation<CompleteConnectionResponse, CompleteConnectionPayload>({
            query: ({ ref, bankDetailsOnly }) => ({
                url: `/institutions/completeConnection?ref=${ref}`,
                method: "GET",
                params: { bankDetailsOnly },
            }),
        }),

        getPlaidLinkToken: builder.mutation<ApiPlaidResponse, GetLinkTokenPayload>({
            query: () => ({
                url: "/institutions/plaid/linkToken",
                method: "GET",
            }),
        }),

        connectPlaidLinkToken: builder.mutation<ApiPlaidResponse, PushLinkTokenPayload>({
            query(data : PushLinkTokenPayload) {
                return {
                    url: "/institutions/plaid/linkToken/connect",
                    method: "POST",
                    body: data,
                };
            },
        }),

    }),
    overrideExisting: false,
});

export const {
    useGetBanksMutation,
    usePushBankMutation,
    useCompleteConnectionMutation,
    usePushBankQuestionnaireMutation,
    useGetPlaidLinkTokenMutation,
    useConnectPlaidLinkTokenMutation,
    endpoints: { getBanks, pushBank, pushBankQuestionnaire, completeConnection },
} = connectBankApi;
