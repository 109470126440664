import "styles/approvedRejectedExpenses.less";
import { FC } from "react";

import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Row, Button, Tooltip, Flex } from "antd";
import wallet from "assets/images/new-questionnaire/expenses/wallet.svg";
import { formatAmountWithCommas } from "utils/new-questionnaire";

type TotalBusinessIncomeExpensesCardProps = {
    totalAggregatedAmount: number;
    title: string;
    addButtonAction?: () => void;
    infoText?: string;
};

const TotalBusinessExpensesCard: FC<TotalBusinessIncomeExpensesCardProps> = ({ totalAggregatedAmount, title, addButtonAction, infoText }) => {
    return (
        <div className="approvedRejected__totals-card">
            <Row className="w100" align="middle">
                <img src={wallet} alt="wallet" className="mr6" />
                <div className="approvedRejected__totals-card-title taLeft">{title}</div>
            </Row>
            <Row className="w100" align="middle" justify={"space-between"}>
                <div className="approvedRejected__totals-card-value">
                    {totalAggregatedAmount ? `£${formatAmountWithCommas(totalAggregatedAmount.toFixed(2))}` : "£0.00"}
                </div>
                <Tooltip title="Coming soon">
                    <Button onClick={addButtonAction} icon={<PlusOutlined />} />
                </Tooltip>
            </Row>
            {infoText && <Flex className="w100" justify="center" align="center" gap="small">
                <InfoCircleOutlined size={16} className="approvedRejected__totals-card-icon" />
                <span className="approvedRejected__totals-card-info">
                    {infoText}
                </span>
            </Flex>}
        </div>
    );
};

export default TotalBusinessExpensesCard;
