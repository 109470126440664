import { api } from "./connection/api";

const FILES_ENDPOINT_PREFIX = "files";

export const filesApi = api.injectEndpoints({
    endpoints: builder => ({
        uploadFile: builder.mutation({
            query: ({ file, path, type }) => {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("path", path);
                formData.append("type", type);

                return {
                    url: `${FILES_ENDPOINT_PREFIX}`,
                    method: "POST",
                    body: formData,
                };
            },
        }),
        uploadAndParseFile: builder.mutation({
            query: ({ file, clientId, type }) => {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("clientId", clientId);
                formData.append("type", type);

                return {
                    url: `${FILES_ENDPOINT_PREFIX}/parse`,
                    method: "POST",
                    body: formData,
                };
            },
        }),
        uploadAndParseCisFile: builder.mutation({
            query: ({ file, clientId, type }) => {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("clientId", clientId);
                formData.append("type", type);
                return {
                    url: `${FILES_ENDPOINT_PREFIX}/parseCis`,
                    method: "POST",
                    body: formData,
                };
            },
        }),
        deleteFile: builder.mutation({
            query: ({ url }) => {
                return {
                    url: `${FILES_ENDPOINT_PREFIX}?url=${encodeURIComponent(url)}`,
                    method: "DELETE",
                };
            },
        }),
    }),
});

export const fetchFile = async (url: string): Promise<Blob | undefined> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/${FILES_ENDPOINT_PREFIX}?url=${encodeURIComponent(url)}`);
        const fileBlob = await response.blob();
        return fileBlob;
    } catch (err) {
        console.error("Error fetching file:", err);
    }
};

export const {
    useUploadFileMutation,
    useUploadAndParseFileMutation,
    useDeleteFileMutation,
    useUploadAndParseCisFileMutation,
} = filesApi;
