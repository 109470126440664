import "styles/questionnaire/datepickerStyles.less";
import "react-datepicker/dist/react-datepicker.css";

import { FC, useCallback, useContext, useEffect, useState } from "react";

import { AppContext } from "App";
import { format, parse } from "date-fns";
import { PickerValue } from "react-mobile-picker";

import DatePickerDesktop from "./DatePickerDesktop";
import DatePickerMobile from "./DatePickerMobile";

interface CustomDatePickerProps {
    minDate?: string;
    maxDate?: string;
    value?: string;
    onChange: (_date: string) => void;
    disableBorder?: boolean;
}

const formatDatePart = (value: number, placeholder: string) => value.toString().padStart(2, "0") || placeholder;

const MobileDateDisplay = ({ selectedDate, onClick, disableBorder }: { selectedDate: Date | null, onClick: () => void, disableBorder?: boolean }) => (
    <div onClick={onClick} className={selectedDate ? "input-mobile custom-date-picker__selected" : "input-mobile"}>
        <div className={disableBorder ? "input-mobile-item" : "input-mobile-item-border"}>{selectedDate ? formatDatePart(selectedDate.getDate(), "D")[0] : "D"}</div>
        <div className={disableBorder ? "input-mobile-item" : "input-mobile-item-border"}>{selectedDate ? formatDatePart(selectedDate.getDate(), "D")[1] : "D"}</div>
        <div className="custom-date-picker__slash">/</div>
        <div className={disableBorder ? "input-mobile-item" : "input-mobile-item-border"}>{selectedDate ? formatDatePart(selectedDate.getMonth() + 1, "M")[0] : "M"}</div>
        <div className={disableBorder ? "input-mobile-item" : "input-mobile-item-border"}>{selectedDate ? formatDatePart(selectedDate.getMonth() + 1, "M")[1] : "M"}</div>
        <div className="custom-date-picker__slash">/</div>
        {(selectedDate ? selectedDate.getFullYear().toString() : "YYYY").split("").map((char, idx) => (
            <div key={idx} className={disableBorder ? "input-mobile-item" : "input-mobile-item-border"}>{char}</div>
        ))}
    </div>
);

const CustomDatePicker: FC<CustomDatePickerProps> = ({ minDate, maxDate, value, onChange, disableBorder }) => {
    const { isMobile } = useContext(AppContext);

    const [selectedDate, setSelectedDate] = useState<Date | null>(
        value ? parse(value, "dd/MM/yyyy", new Date()) : null
    );
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const handleDateChange = (date: Date | null) => {
        if (date) {
            setSelectedDate(date);
            onChange(format(date, "dd/MM/yyyy"));
        }
    };

    const handlePickerChange = useCallback((newValue: PickerValue) => {
        if (newValue.year && newValue.month && newValue.day) {
            const newDate = new Date(
                parseInt(String(newValue.year), 10),
                parseInt(String(newValue.month), 10) - 1,
                parseInt(String(newValue.day), 10)
            );
            setSelectedDate(newDate);
            onChange(format(newDate, "dd/MM/yyyy"));
        }
    }, [setSelectedDate, onChange]);

    useEffect(() => {
        if (value) {
            setSelectedDate(parse(value, "dd/MM/yyyy", new Date()));
        } else {
            setSelectedDate(null);
        }
    }, [value]);

    return (
        <div className="custom-date-picker__wrapper">
            {isMobile ? (
                <div className="custom-date-picker__mobile">
                    <MobileDateDisplay onClick={() => setIsDatePickerOpen(true)} selectedDate={selectedDate} disableBorder={disableBorder} />
                    <DatePickerMobile
                        isOpen={isDatePickerOpen}
                        onClose={() => setIsDatePickerOpen(false)}
                        handlePickerChange={handlePickerChange}
                        selectedDate={selectedDate}
                        minDate={minDate}
                        pickerTitle={"Select Date"}
                        maxDate={maxDate}/>
                </div>
            ) : (
                <DatePickerDesktop
                    selectedDate={selectedDate}
                    handleDateChange={handleDateChange}
                    dateFormat={"dd/MM/yyyy"}
                    minDate={minDate}
                    maxDate={maxDate}
                    className={disableBorder ? "custom-date-picker__input" : "custom-date-picker__input-border"}
                    calendarClassName={"custom-date-picker__calendar"}
                    placeholderText={"DD/MM/YYYY"}
                />
            )}
        </div>
    );
};

export default CustomDatePicker;
