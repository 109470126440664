import "styles/profile/idpal.less";
import "swiper/css";

import { useContext } from "react";

import { Typography } from "antd";
import { AppContext } from "App";
import clockIcon from "assets/images/clock.svg";
import ProfileButtonFooter from "components/basic/Footer/ProfileButtonFooter";

type StartPageProps = {
    setSelectedTab: (_tab: string) => void;
};

function StartPage({ setSelectedTab }: StartPageProps) {
    const { isMobile } = useContext(AppContext);
    return (
        <div className="content idpal__content">
            <div>
                {isMobile ? <>
                    <div style={{ background: "white", display: "flex", justifyContent: "flex-end", padding: "10px 0px 0px 20px" }}>
                        <div className="idpal__content-heading" style={{ marginBottom: "53px" }}>
                            <img width={20} src={clockIcon} alt="Clock Icon" />
                        2 mins
                        </div>
                    </div>
                    <div style={{ width: "345px", margin: "0 auto", background: "white" }}>
                        <img className="idpal__content-image" alt="phone scan"
                            src="https://taxzap-images.s3.eu-west-1.amazonaws.com/id-pal/Screenshot-2023-10-10-at-17.11-1.webp"
                        />
                        <Typography className="idpal__content-title">
                    Let’s Verify You Quickly
                        </Typography>
                        <Typography className="idpal__content-text" style={{ padding: "0px 20px 0px 20px" }}>
                    To protect your account and prevent fraud, we need to quickly verify your identity.
                        </Typography>
                        <div className="mt20">
                            <Typography className="idpal__content-terms-text">
                            By clicking start you agree{" "}
                                <a href="/uk/terms-and-conditions"
                                    className="email-link"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ textDecoration: "underline" }}>
                                    {" "}Terms & Conditions
                                </a>
                            </Typography>
                        </div>
                        <div style={{ display: "flex", alignContent: "center" }}>
                            <ProfileButtonFooter>
                                <div
                                    className="new-questionnaire__form-continue"
                                    style={{ width: "335px" }}
                                    onClick={() =>
                                        setSelectedTab(
                                            "IDPal"
                                        )
                                    }
                                >
                            Start
                                    <div className="arrow-container">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                            <rect y="20" width="20" height="20" transform="rotate(-90 0 20)" fill="url(#pattern0)"/>
                                            <defs>
                                                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                    <use xlinkHref="#image0_2599_109816" transform="scale(0.00666667)"/>
                                                </pattern>
                                                <image id="image0_2599_109816" width="150" height="150" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACWCAYAAAA8AXHiAAAAAXNSR0IArs4c6QAABE5JREFUeF7t2t1S21AMRlHy/g9Nh1Km/IV8Aosc66zeViPG28t2Yrg8+KdAQ4FLw04rFXgAC4KWAmC1ZLUULAZaCoDVktVSsBhoKQBWS1ZLwWKgpQBYLVktBYuBlgJgtWS1FCwGWgqA1ZLVUrAYaCkAVktWS8FioKUAWC1ZLQWLgZYCYLVktRQsBloKgNWS1VKwGGgpAFZLVkvBYqClAFgtWS0Fi4GWAmC1ZLUULAZaCoDVktVSsBhoKQBWS1ZLwWKgpQBYLVktBYuBlgJgtWS1FCwGWgqA1ZLVUrAYaCkAVktWS8FioKUAWC1ZLQWLgZYCYLVktRQsBloKgNWS1VKwGGgpAFZLVkvBYqClAFgtWS09BazHx8fHa6fqcrmc4hh2o7b0SfkK1OsTNR3XGS+sEbCekE3FddaLa1lYadDJd65Kg9UurFGwJt25KqheLq6VcI2DNQHXd1CtdtxLwvpu2AmPxZ8e+yp3rSVhPQH5aeDVruDkdcOkYx4N60y4JqH62z25ku4xc0ToFT/UftbyqGNd5TG4NKyjHoer4zoK1Wp352XvWC8gpoaffuEsD2vqCZh8wSz/KHz9eWTSiZh0LFf/OOAeH8y/+zMnnJAJx5Ccv1M8CqfcuXZBdapH4dlx7YTqtLDO9oF+N1SnhnUWXDuiOj2s1XHtimoErFVx7YxqDKzVcO2OahSsVXBB9fz9/XTvsW69nLvnib3nz77V5bf/fxyse925oHpLdySs38YF1cf74VhYv4ULqs8fsqNhdeOC6vont/GwunBB9fXXgS1gHY3ryG9YK/2d+qHHdeSy1XcdeZc54linohr5HuvWCV8F12RUW8Ja4bE4HdW2sO6JawdUW8O6B65dUG0P6zdx7YQKrH+f9Ls/0O+GCqxXXyG7cO2ICqx37yaOxrUrKrA+eel1FK6dUYF15W3qT3HtjgqsL17TfxcXVM9Rt/kl9K1f9Xz2/1VcUP2vCNYNcSkuqN6GBCu4ld3CBdXHiGAFsF5G3gMD6no8sAqwjOYFwMpbmSwUAKsQy2heAKy8lclCAbAKsYzmBcDKW5ksFACrEMtoXgCsvJXJQgGwCrGM5gXAyluZLBQAqxDLaF4ArLyVyUIBsAqxjOYFwMpbmSwUAKsQy2heAKy8lclCAbAKsYzmBcDKW5ksFACrEMtoXgCsvJXJQgGwCrGM5gXAyluZLBQAqxDLaF4ArLyVyUIBsAqxjOYFwMpbmSwUAKsQy2heAKy8lclCAbAKsYzmBcDKW5ksFACrEMtoXgCsvJXJQgGwCrGM5gXAyluZLBQAqxDLaF4ArLyVyUIBsAqxjOYFwMpbmSwUAKsQy2heAKy8lclCAbAKsYzmBcDKW5ksFACrEMtoXgCsvJXJQgGwCrGM5gXAyluZLBQAqxDLaF4ArLyVyUIBsAqxjOYFwMpbmSwUAKsQy2heAKy8lclCAbAKsYzmBcDKW5ksFPgDMckopt3rfM4AAAAASUVORK5CYII="/>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                            </ProfileButtonFooter>
                        </div>
                    </div>
                </> : <>
                    <div style={{ width: "690px" }}>
                        <div className="idpal__content">
                            <div className= "idpal__header">
                                <Typography className="profile__content-heading">
                        ID Verification
                                </Typography>
                                <Typography className="idpal__content-heading">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                        <path d="M9.80887 0.814376C9.30575 0.673364 8.61739 0.514531 7.33131 0.500289C4.6303 0.470473 1.35204 2.75397 0.325371 6.23426C-0.351413 8.52846 0.0402078 11.1259 1.38262 13.0976C2.72501 15.0693 4.9961 16.368 7.35926 16.4899C9.60154 16.6056 12.2626 15.7233 13.783 13.8568C16.044 11.7176 16.7278 8.08689 15.1211 4.69346C14.0902 2.51717 12.1302 1.19085 9.80887 0.814376ZM14.7512 4.87274C15.2296 5.88258 15.5071 6.95937 15.5731 8.02267H14.9994C14.8862 8.02267 14.7944 8.11555 14.7944 8.23003C14.7944 8.34452 14.8862 8.4374 14.9994 8.4374H15.5825C15.607 10.9213 14.4497 12.9817 12.8104 14.1181C11.7102 14.881 10.1625 15.3384 8.65195 15.4115C8.67369 15.3299 8.65609 15.4834 8.66246 14.2145C8.66246 14.1 8.57066 14.0071 8.45752 14.0071C8.34437 14.0071 8.25258 14.1 8.25258 14.2145C8.25886 15.4335 8.24053 15.3445 8.2651 15.4215C7.94253 15.4229 7.62381 15.4068 7.31334 15.3709C4.06293 14.9724 1.68452 12.2411 1.20076 9.28604H2.85098C2.96413 9.28604 3.05592 9.19315 3.05592 9.07867C3.05592 8.96419 2.96413 8.8713 2.85098 8.8713H1.14769C0.785292 5.07388 3.60346 1.56745 7.7493 1.16269V2.09111C7.7493 2.2056 7.84109 2.29848 7.95424 2.29848C8.06738 2.29848 8.15918 2.2056 8.15918 2.09111C8.15755 1.62395 8.16237 1.21032 8.15519 1.13802C8.27146 1.13278 8.38727 1.12286 8.50395 1.12286C11.1365 1.12286 13.5847 2.40937 14.7512 4.87274Z" fill="#540087"/>
                                        <path d="M9.4241 8.49516C9.49109 7.8463 9.09224 7.34386 8.49439 7.20847V3.53375C8.49439 3.41927 8.40259 3.32639 8.28945 3.32639C8.1763 3.32639 8.08451 3.41927 8.08451 3.53375V7.18218C7.49761 7.23494 7.08399 7.65326 7.05847 8.30049C7.0329 8.94299 7.58826 9.56089 8.24008 9.56089C8.56537 9.56089 8.87462 9.40847 9.09771 9.17397C11.6114 10.9312 11.4996 10.9101 11.6216 10.9101C11.8205 10.9101 11.9042 10.6494 11.739 10.5327L9.32833 8.83119C9.37577 8.72494 9.41182 8.61329 9.4241 8.49516Z" fill="#540087"/>
                                    </svg>
                        2 mins
                                </Typography>
                            </div>
                            <div className="idpal__content-description">
                                <img className="idpal__content-image" alt="phone scan"
                                    style={{ width: "433.548px", height: "280px" }}
                                    src="https://taxzap-images.s3.eu-west-1.amazonaws.com/id-pal/Screenshot-2023-10-10-at-17.11-1.webp"
                                />
                                <Typography className="idpal__content-title">
                        Let’s Verify You Quickly
                                </Typography>
                                <Typography className="idpal__content-text" style={{ marginBottom: "0px%" }}>
                        To protect your account and prevent fraud, we need to quickly verify your identity.
                                </Typography>
                                <div
                                    className="new-questionnaire__form-continue"
                                    style={{ width: "335px" }}
                                    onClick={() =>
                                        setSelectedTab(
                                            "IDPal"
                                        )
                                    }

                                >
                    Start
                                    <div className="arrow-container">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                            <rect y="20" width="20" height="20" transform="rotate(-90 0 20)" fill="url(#pattern0)"/>
                                            <defs>
                                                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                    <use xlinkHref="#image0_2599_109816" transform="scale(0.00666667)"/>
                                                </pattern>
                                                <image id="image0_2599_109816" width="150" height="150" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACWCAYAAAA8AXHiAAAAAXNSR0IArs4c6QAABE5JREFUeF7t2t1S21AMRlHy/g9Nh1Km/IV8Aosc66zeViPG28t2Yrg8+KdAQ4FLw04rFXgAC4KWAmC1ZLUULAZaCoDVktVSsBhoKQBWS1ZLwWKgpQBYLVktBYuBlgJgtWS1FCwGWgqA1ZLVUrAYaCkAVktWS8FioKUAWC1ZLQWLgZYCYLVktRQsBloKgNWS1VKwGGgpAFZLVkvBYqClAFgtWS0Fi4GWAmC1ZLUULAZaCoDVktVSsBhoKQBWS1ZLwWKgpQBYLVktBYuBlgJgtWS1FCwGWgqA1ZLVUrAYaCkAVktWS8FioKUAWC1ZLQWLgZYCYLVktRQsBloKgNWS1VKwGGgpAFZLVkvBYqClAFgtWS09BazHx8fHa6fqcrmc4hh2o7b0SfkK1OsTNR3XGS+sEbCekE3FddaLa1lYadDJd65Kg9UurFGwJt25KqheLq6VcI2DNQHXd1CtdtxLwvpu2AmPxZ8e+yp3rSVhPQH5aeDVruDkdcOkYx4N60y4JqH62z25ku4xc0ToFT/UftbyqGNd5TG4NKyjHoer4zoK1Wp352XvWC8gpoaffuEsD2vqCZh8wSz/KHz9eWTSiZh0LFf/OOAeH8y/+zMnnJAJx5Ccv1M8CqfcuXZBdapH4dlx7YTqtLDO9oF+N1SnhnUWXDuiOj2s1XHtimoErFVx7YxqDKzVcO2OahSsVXBB9fz9/XTvsW69nLvnib3nz77V5bf/fxyse925oHpLdySs38YF1cf74VhYv4ULqs8fsqNhdeOC6vont/GwunBB9fXXgS1gHY3ryG9YK/2d+qHHdeSy1XcdeZc54linohr5HuvWCV8F12RUW8Ja4bE4HdW2sO6JawdUW8O6B65dUG0P6zdx7YQKrH+f9Ls/0O+GCqxXXyG7cO2ICqx37yaOxrUrKrA+eel1FK6dUYF15W3qT3HtjgqsL17TfxcXVM9Rt/kl9K1f9Xz2/1VcUP2vCNYNcSkuqN6GBCu4ld3CBdXHiGAFsF5G3gMD6no8sAqwjOYFwMpbmSwUAKsQy2heAKy8lclCAbAKsYzmBcDKW5ksFACrEMtoXgCsvJXJQgGwCrGM5gXAyluZLBQAqxDLaF4ArLyVyUIBsAqxjOYFwMpbmSwUAKsQy2heAKy8lclCAbAKsYzmBcDKW5ksFACrEMtoXgCsvJXJQgGwCrGM5gXAyluZLBQAqxDLaF4ArLyVyUIBsAqxjOYFwMpbmSwUAKsQy2heAKy8lclCAbAKsYzmBcDKW5ksFACrEMtoXgCsvJXJQgGwCrGM5gXAyluZLBQAqxDLaF4ArLyVyUIBsAqxjOYFwMpbmSwUAKsQy2heAKy8lclCAbAKsYzmBcDKW5ksFACrEMtoXgCsvJXJQgGwCrGM5gXAyluZLBQAqxDLaF4ArLyVyUIBsAqxjOYFwMpbmSwUAKsQy2heAKy8lclCAbAKsYzmBcDKW5ksFPgDMckopt3rfM4AAAAASUVORK5CYII="/>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                                <Typography className="idpal__content-terms-text" style={{ marginTop: "13px" }}>
                            By clicking start you agree {" "}
                                    <a href="/uk/terms-and-conditions"
                                        className="email-link"
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ textDecoration: "underline" }}>
                            Terms & Conditions
                                    </a>
                                </Typography>
                            </div>
                        </div>
                    </div>
                </>
                }
            </div>
        </div>
    );
}
export default StartPage;
