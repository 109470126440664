
import { useContext, useRef, useState } from "react";

import { AppContext } from "App";
import cookie from "assets/images/home/cookie.svg";
import FAQsNew from "components/basic/FAQs/FAQsNew";
import ButtonFooterNew from "components/basic/Footer/ButtonFooterNew";
import CookieConsent from "react-cookie-consent";

import HowItWorks from "./components/HowItWorks";
import MainNew from "./components/MainNew";
import OpenBankingSlider from "./components/OpenBankingSlider";
import TaxzapBlog from "./components/TaxzapBlogUK";
import WeHelped from "./components/WeHelped";
import WhyTaxzap from "./components/WhyTaxzap";
import Footer from "../../basic/Footer/Footer";
import HeaderNew from "../../basic/Header/HeaderNew";
import SubmissionBannerNew from "../../common/SubmissionBannerNew";
import WhatCustomersSayingNew from "../Reviews/ReviewsNew";

const HomeNew = () => {
    const [showButtonFooter, setShowButtonFooter] = useState(false);
    const { isMobile } = useContext(AppContext);
    const cookieConsentRef = useRef<CookieConsent | null>(null);

    const onAccept = () => {
        cookieConsentRef.current?.accept();
    };

    const onDecline = () => {
        cookieConsentRef.current?.decline();
    };

    return <div>
        <div>
            <div>
                <HeaderNew/>
            </div>

            <div>
                <MainNew setShowButtonFooter={setShowButtonFooter}/>
            </div>

            <div>
                <OpenBankingSlider/>
            </div>
            <div id={"how-it-works"} style={{ scrollMarginTop: "50px" }}>
                <HowItWorks/>
            </div>
            {/*<div>*/}
            {/*    <SelfEmployed/>*/}
            {/*</div>*/}
            <div id={"why-taxzap"} style={{ scrollMarginTop: "50px" }}>
                <WhyTaxzap/>
            </div>
            <div>
                <SubmissionBannerNew/>
            </div>
            <div>
                <WeHelped/>
            </div>
            <div>
                <WhatCustomersSayingNew/>
            </div>
            <div>
                <TaxzapBlog/>
            </div>

            <div id={"faqs"}>
                <FAQsNew limiter={4}/>
            </div>

            <div id={"contact"}>
                <Footer/>
            </div>
        </div>
        <CookieConsent
            ref={cookieConsentRef}
            location="bottom"
            cookieName="TaxZapCookieConsent"
            style={{ background: "#fff", color: "#4e503b", boxShadow: "0 0 10px rgba(0,0,0,0.1)", borderRadius: "10px" }}
            expires={150}
            overlay={true}
            buttonStyle={{ display: "none" }}
            acceptOnScroll={true}
            acceptOnScrollPercentage={10}
        >
            <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }} className="space-between">
                <div className="flex-row ">
                    <img src={cookie} alt="cookie" className="mr6" width={40}></img>
                    <div>
                        <strong>We use cookies to ensure that we give you the best experience on our website.</strong>
                        <span> By clicking 'Accept' you agree to our use of cookies. Please see our <a href="https://taxzap.com/uk/privacy-policy" target="_blank" rel="noreferrer">Cookie Policy</a> for more information.</span>
                    </div>

                </div>
                <div className={`uk-cookie__button-wrapper ${isMobile ? "mt20" : ""}`}>
                    <div className="uk-cookie__button--decline" onClick={() => onDecline()}>Decline</div>
                    <div className="uk-cookie__button--accept" onClick={() => onAccept()}>Accept</div>
                </div>

            </div>
        </CookieConsent>

        <ButtonFooterNew showButtonFooter={showButtonFooter}/>
    </div>;
};

export default HomeNew;
