import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SharingPreferenceIdType } from "components/pages/NewQuestionnaire/components/OnBoarding/OnBoarding";
import { RootState } from "redux/store";

import { CLIENT_STATUSES } from "../../constants/common";
import { IClientDocument } from "../../types/client";

interface DocumentState {
    currentDocumentYear: string;
    isEditingDocument: boolean;
    currentDocument: IClientDocument;
    selectedSharingPreference: SharingPreferenceIdType;
    expensesExplainerShown: boolean;
}

const initialState: DocumentState = {
    currentDocumentYear: "",
    isEditingDocument: false,
    currentDocument: {
        _id: "",
        id: "",
        year: "",
        sol: "",
        additionalInfo: {
            utr: "",
            nin: "",
            postcode: "",
            iban: "",
            bankAccountNumber: "",
            sortCode: "",
        },
        preliminaryResult: "",
        incomeTax: "",
        receipts: {
            workingFromHome: [],
            carer: [],
            medical: [],
            nursingHome: [],
            thirdLevelEducation: [],
        },
        plan: "",
        status: {
            id: "",
            main: "",
            secondary: "",
            tertiary: null,
            document: "",
            client: CLIENT_STATUSES.APPLICATION_SENT,
        },
        statusesHistory: [],
        answers: {
            convertedAnswers: "",
            constructorAnswers: "",
            constructorVersion: 0,
            dataSections: "",
        },
        answersOB: null,
        nin: "",
        files: {},
        refund: {
            amount: 0,
            received: 0,
            fee: 0,
            paid: 0,
            vat: 0,
            transactionsCount: 0,
            availablePlans: [],
            customFeeOptions: {
                enabled: false,
                penaltyReduction: false,
                penaltyReductionValue: 0,
                reducedSubmissionFee: false,
                submissionFee: false,
                repaymentFee: false,
                customisedFee: false,
                customisedFeeValue: 0,
                customisedFeeDescription: "",
            },
            feeRequested: {},
        },
        confirmed: false,
        createdAt: new Date().toDateString(),
        updatedAt: new Date().toDateString(),
        employmentTypes: [],
        additionalIncomeSources: [],
        isCompanyDirector: false,
    },
    selectedSharingPreference: SharingPreferenceIdType?.connect,
    expensesExplainerShown: false,
};

const documentSlice = createSlice({
    name: "document",
    initialState,
    reducers: {
        setCurrentDocumentYear(state, action: PayloadAction<string>) {
            state.currentDocumentYear = action.payload;
        },
        setIsEditingDocument(state, action: PayloadAction<boolean>) {
            state.isEditingDocument = action.payload;
        },
        setExpensesExplainerShown(state, action: PayloadAction<boolean>) {
            state.expensesExplainerShown = action.payload;
        },
        setSelectedSharingPreference(state, action: PayloadAction<SharingPreferenceIdType>) {
            state.selectedSharingPreference = action.payload;
        },
        setCurrentDocument(state, action: PayloadAction<IClientDocument>) {
            state.currentDocument = JSON.parse(JSON.stringify(action.payload));
        },
        resetDocument(state) {
            state.currentDocument = initialState.currentDocument;
            state.currentDocumentYear = initialState.currentDocumentYear;
            state.isEditingDocument = initialState.isEditingDocument;
        },
    },
});

export const selectDocument = createSelector(
    (state: RootState) => state.document,
    document => ({
        currentDocument: document.currentDocument,
        currentDocumentYear: document.currentDocumentYear,
        isEditingDocument: document.isEditingDocument,
        selectedSharingPreference: document.selectedSharingPreference,
        expensesExplainerShown: document.expensesExplainerShown,
    })
);

export const {
    setCurrentDocumentYear,
    setIsEditingDocument,
    setExpensesExplainerShown,
    setSelectedSharingPreference,
    setCurrentDocument,
    resetDocument,
} = documentSlice.actions;

export default documentSlice.reducer;
