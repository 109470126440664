/*global google*/
import { Dispatch, SetStateAction } from "react";

export const placesAutocomplete = async (input: string, sessionToken: any) => {
    try {
        const service = new google.maps.places.AutocompleteService();
        const placeAutocomplete = await service.getPlacePredictions({ input, sessionToken, componentRestrictions: { country: ["gb", "ie"] } });

        return placeAutocomplete.predictions.map(prediction => {
            return {
                value: prediction.place_id,
                label: prediction.description,
            };
        });
    } catch (error) {
        console.error(error);
    }
};

export const generateSessionToken = () => {
    return new google.maps.places.AutocompleteSessionToken();
};

export const getPlaceDetails = (placeId: string, setAddress: Dispatch<SetStateAction<any>>, uk?:boolean) => {
    const service = new google.maps.places.PlacesService(document.createElement("div"));

    const request = {
        placeId,
        fields: ["address_components"],
    };

    service.getDetails(request, (place, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
            console.error(status);
            setAddress(undefined);
            return;
        }
        const addressComponents = {
            number: "",
            streetAddress: "",
            locality: "",
            neighborhood: "",
            city: "",
            county: "",
            country: "",
            postalCode: "",
        };
        for (const component of place?.address_components as google.maps.GeocoderAddressComponent[]) {
            const componentType = component.types[0];

            switch (componentType) {
            case "street_number":
                addressComponents.number = component.long_name;
                break;
            case "route":
                addressComponents.streetAddress = component.long_name;
                break;
            case "locality":
                addressComponents.locality = component.long_name;
                break;
            case "neighborhood":
                addressComponents.neighborhood = component.long_name;
                break;
            case "postal_town":
                addressComponents.city = component.long_name;
                break;
            case "administrative_area_level_1":
                addressComponents.country = component.long_name;
                break;
            case "postal_code":
                addressComponents.postalCode = component.long_name;
                break;
            }
        }

        const formattedAddress = uk ? {
            address1: `${addressComponents.number ? `${addressComponents.number.trim()},` : ""} ${addressComponents.streetAddress ? `${addressComponents.streetAddress.trim()},` : ""}`,
            address2: `${addressComponents.neighborhood ? `${addressComponents.neighborhood.trim()},` : ""} ${addressComponents.locality?.trim()}`,
            city: addressComponents.city?.trim(),
            country: addressComponents.country,
            postcode: addressComponents.postalCode?.trim(),
        } : {
            streetAddress: `${addressComponents.number ? `${addressComponents.number},` : ""} ${addressComponents.streetAddress ? `${addressComponents.streetAddress},` : ""} ${addressComponents.neighborhood ? `${addressComponents.neighborhood},` : ""} ${addressComponents.locality}`,
            city: addressComponents.city,
            county: addressComponents.county,
            postalCode: addressComponents.postalCode,
        };
        setAddress(formattedAddress);
        return;
    });
    setAddress(undefined);
};
