import "styles/index.less";
import { createContext, Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react";

import Clarity from "@microsoft/clarity";
import { Breakpoint, Grid } from "antd";
import PrivateRoute from "components/basic/PrivateRoute/PrivateRoute";
import LottieAnimation from "components/basic/Spinner/Lottie";
import CustomErrorFallback from "components/common/CustomErrorFallback";
import Auth from "components/pages/Auth/Auth";
import BlogPage from "components/pages/Blog/BlogPage";
import FaqsPageNew from "components/pages/FAQs/FAQsPageNew";
import HomeUKNew from "components/pages/HomeNew/HomeNew";
import Marketing from "components/pages/Marketing/Marketing";
import Questionnaire from "components/pages/NewQuestionnaire/Questionnaire";
import PotentialClient from "components/pages/PotentialClients/PotentialClients";
import PricesPageNew from "components/pages/Prices/PricesPageNew";
import PrivacyPolicy from "components/pages/PrivacyPolicy/PrivacyPolicy";
import Profile from "components/pages/Profile/Profile";
import ResetPassword from "components/pages/ResetPassword/ResetPassword";
import SignUpMobileNew from "components/pages/SignUpMobileNew";
import TermsConditions from "components/pages/Terms/TermsConditions";
import TermsEngagement from "components/pages/Terms/TermsEngagement";
import { useAuth } from "hooks/auth/useAuth";
import { useLoading } from "hooks/common/useLoading";
import { useAppSize } from "hooks/helpers/useAppSize";
import useScale from "hooks/helpers/useScale";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { useDispatch } from "react-redux";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { IAuthData } from "types/auth";
import { initialiseAnalytics } from "utils/initialiseAnalytics";
import UtmHandler from "utils/utmHandler";

import PrivateQuestionnaireRoute from "./components/basic/PrivateRoute/PrivateQuestionnaireRoute";
import Code from "./components/pages/Code/Code";
import HMRCErrorPage from "./components/pages/Error/HMRCErrorPage";
import { useUser } from "./hooks/common/useUser";
import { setFeatureFlags } from "./redux/reducers/featureFlags";
import { setTaxYears } from "./redux/reducers/taxYears";
import { useGetTaxYearsMutation } from "./services/tax-years";
import { logError } from "./utils/sentry";
import packageJson from "../package.json";

const { useBreakpoint } = Grid;

const MAX_WIDTH = 1300;

export const AppContext = createContext<{
    authData: IAuthData | undefined;
    isAuth: boolean;
    breakpoint?: Partial<Record<Breakpoint, boolean>>;
    maxWidth: number;
    isMobile: boolean;
    formScale: number;
    toggleFormScale: Dispatch<SetStateAction<boolean>>
}>({
    authData: undefined,
    isAuth: false,
    isMobile: window.innerWidth < 1110,
    breakpoint: undefined,
    maxWidth: MAX_WIDTH,
    formScale: 1,
    toggleFormScale: () => {},
});

const App = ({ flags }: any) => {
    const isMobile = useAppSize();
    const { authData, isAuth } = useAuth();
    const { loading } = useLoading();
    const [pageLoading, setPageLoading] = useState(true);
    const breakpoint = useBreakpoint();
    const { user } = useUser();
    const dispatch = useDispatch();
    const [getTaxYears] = useGetTaxYearsMutation();

    const { scale: formScale, toggleScale: toggleFormScale } = useScale();

    const createRoute = (path: string, element: ReactElement) => ({
        path,
        element,
        errorElement: <CustomErrorFallback />,
    });

    const router = createBrowserRouter([
        createRoute("/", <HomeUKNew />),
        createRoute("/construction-worker", <HomeUKNew />),
        createRoute("/landlord", <HomeUKNew />),
        createRoute("/delivery-drivers", <HomeUKNew />),
        createRoute("/profile", <PrivateRoute component={Profile} />),
        createRoute("/finish/code/:clientId", <Code />),
        createRoute("/:blogpostName", <BlogPage />),
        createRoute("/auth", <Auth />),
        createRoute("/faqs", <FaqsPageNew />),
        createRoute("/pricing", <PricesPageNew />),
        createRoute("/reset-password", <ResetPassword />),
        createRoute("/privacy-policy", <PrivacyPolicy />),
        createRoute("/terms-and-conditions", <TermsConditions />),
        createRoute("/terms-of-engagement", <TermsEngagement />),
        createRoute("/sign-up", <SignUpMobileNew />),
        createRoute("/questionnaire", <PrivateQuestionnaireRoute component={Questionnaire}/>),
        createRoute("/finish/:potentialClientId", <PotentialClient />),
        createRoute("hmrc-error/:clientId", <HMRCErrorPage />),
        createRoute("/marketing", <Marketing />),
        { path: "*", element: <Navigate to="/" replace={true} /> },
    ], {
        basename: "/uk",
    });

    useEffect(() => {
        initialiseAnalytics();
        console.log(`Frontend package version: ${packageJson.version} for environment: ${process.env.REACT_APP_ENV}`);

        const timer = setTimeout(() => {
            setPageLoading(false);
        }, 500);
        return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const flagsPlainObject = { ...flags };
        dispatch(setFeatureFlags(flagsPlainObject));
    }, [flags, dispatch]);

    useEffect(() => {
        if (process.env.REACT_APP_ENV !== "development") {
            const script = document.createElement("script");
            script.src = "https://static.klaviyo.com/onsite/js/X4ienZ/klaviyo.js";
            script.type = "text/javascript";
            script.async = true;

            document.head.appendChild(script);
            return () => {
                document.head.removeChild(script);
            };
        }
    }, []); // Empty dependency array ensures this effect runs once

    useEffect(() => {
        try {
            if (user && user.id) {
                Clarity.identify(user?.id);
            }
        } catch (e: any) {
            const contextInfo = {
                userId: user?.id,
                function: "clarityIdentify",
                error: e.message,
            };
            logError(e, contextInfo);
        }
    }, [user]);

    useEffect(() => {
        const fetchTaxYears = async () => {
            try {
                const response = await getTaxYears({}).unwrap();
                dispatch(setTaxYears(response?.data?.years));
            } catch (error) {
                logError(error, {
                    function: "fetchTaxYears",
                    message: "Error fetching tax years",
                    clientId: user?.id,
                });
            }
        };

        fetchTaxYears();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, getTaxYears]);

    return (<>
        <UtmHandler />
        <LottieAnimation isLoading={loading || pageLoading} backgroundColor={"white"}/>
        <AppContext.Provider
            value={{
                authData,
                isAuth,
                isMobile,
                formScale,
                toggleFormScale,
                maxWidth: MAX_WIDTH,
                breakpoint,
            }}
        >
            <RouterProvider router={router} />
        </AppContext.Provider>
    </>
    );
};

export default withLDConsumer()(App);
