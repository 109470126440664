import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { AppContext } from "App";
import QuestionnaireButtonFooterV2 from "components/basic/Footer/QuestionnaireButtonFooterV2";
import ButtonLoading from "components/basic/Spinner/ButtonLoading";
import { useModalOpener } from "hooks/helpers/useModalOpener";
import { debounce } from "lodash";
import { useAppSelector } from "redux/hooks";
import { selectDocument } from "redux/reducers/document";
import { selectCurrentNavigation } from "redux/reducers/navigation";
import { useGetClientTotalClaimedBusinessExpensesQuery, useGetClientTransactionsQuery, useGetTransactionsCategoriesQuery } from "services/paymentBank";
import { IUser } from "types/auth";
import { AccountDetails } from "types/bankAccounts";
import { TaxProfile } from "types/client";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { applyFiltersSortSearchTransactions } from "utils/filter";

import ApprovedRejectedExpensesNew from "./ApprovedRejectedExpenseNew";
import { Question, Step } from "../../constants/questions";
import { ProfileType } from "../../enums/enum";
import ExplainerModal from "../Shared/ExplainerModal";
import { ExpenseCard } from "../SwipeableTransactionsCards/SwipeableTransactionsCards";

type ProfileOBExpensesNewProps = {
    user: IUser;
    data: Question;
    currentStepData: Step;
    taxProfileType: ProfileType;
    taxProfile: TaxProfile;
    handleContinue: () => void;
    connectedBankAccounts: AccountDetails[];
};

const ProfileOBExpensesNew: FC<ProfileOBExpensesNewProps> = ({
    currentStepData,
    taxProfile,
    handleContinue,
    taxProfileType,
    connectedBankAccounts,
}) => {
    const { isMobile } = useContext(AppContext);

    const { stepLoading } = useAppSelector(selectCurrentNavigation);
    const { expensesExplainerShown, currentDocumentYear } = useAppSelector(selectDocument);

    const isKeyboardOpen = useDetectKeyboardOpen();
    const [query, setQuery] = useState("");
    const [showBulkActionToolbar, setShowBulkActionToolbar] = useState<boolean>(false);

    const {
        isOpened: isExplainerModalOpened,
        open: handleExplainerOpenModal,
        close: handleExplainerCloseModal,
    } = useModalOpener();

    const taxProfileId = useMemo(() => {
        return taxProfile?._id;
    }, [taxProfile]);

    const { data: transactionsCategories } = useGetTransactionsCategoriesQuery({
        taxProfileType,
    }, {
        skip: !taxProfileType, refetchOnMountOrArgChange: true, refetchOnFocus: true, refetchOnReconnect: true,
    });

    const {
        data: transactionsData,
        refetch: refetchTransactions,
    } = useGetClientTransactionsQuery(
        { query }, { skip: !query, refetchOnMountOrArgChange: true, refetchOnFocus: true, refetchOnReconnect: true }
    );

    const {
        data: totalClaimedBusinessExpensesData,
        refetch: refetchClaimedBusinessExpenses,
    } = useGetClientTotalClaimedBusinessExpensesQuery(
        { taxProfileId }, { refetchOnMountOrArgChange: true, refetchOnFocus: true, refetchOnReconnect: true }
    );

    const transactionCards = useMemo<ExpenseCard[]>(() => transactionsData?.transactions.map((transaction: any) => ({
        id: transaction?._id,
        totalAmount: Math.abs(Number(transaction?.clientUpdatedAmount)) || Math.abs(Number(transaction?.amount)) || null,
        merchant: {
            name: transaction?.merchant?.derivedMerchant || "",
            logo: transaction?.merchant?.merchantLogo || "",
        },
        bookingDateTime: transaction?.bookingDateTime,
        description: transaction?.additionalDetails || transaction?.reference,
        currencyCode: transaction.currencyCode || "GBP",
        tags: [
            transaction?.category?.byTaxProfileType[taxProfileType]?.clientUpdatedCategory ||
            transaction?.category?.byTaxProfileType[taxProfileType]?.derivedCategory || "No Category",
        ],
        swiped: transaction.swiped,
        category: transaction.category,
    })), [transactionsData, taxProfileType]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const applyFiltersSortSearchHandler = useCallback(
        debounce((filters: Record<string, any>) => {
            const newQuery = applyFiltersSortSearchTransactions(filters, taxProfileId, taxProfileType, currentDocumentYear);
            setQuery(prevQuery => (prevQuery === newQuery ? prevQuery : newQuery));
        }, 300),
        [taxProfileId, taxProfileType]
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!expensesExplainerShown) {
                handleExplainerOpenModal();
            }
        }, 500);
        return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={isMobile ? "new-questionnaire__mobile-form" : "new-questionnaire__desktop-form"}>
            <div className="new-questionnaire__expenses-container">
                <ApprovedRejectedExpensesNew
                    taxProfileId={taxProfileId}
                    taxProfileType={taxProfileType}
                    totalAggregatedAmount={totalClaimedBusinessExpensesData?.totalAggregatedAmount || 0}
                    transactionsData={transactionCards}
                    onApplyFiltersSortSearch={applyFiltersSortSearchHandler}
                    connectedBankAccounts={connectedBankAccounts}
                    transactionsCategories={transactionsCategories?.categories || []}
                    transactionsCount={transactionsData?.totalTransactions || 0}
                    refetchTransactions={refetchTransactions}
                    refetchClaimedBusinessExpenses={refetchClaimedBusinessExpenses}
                    setShowBulkActionToolbar={setShowBulkActionToolbar}
                    showBulkActionToolbar={showBulkActionToolbar}
                ></ApprovedRejectedExpensesNew>

                {!showBulkActionToolbar && <div className={`${isKeyboardOpen ? "" : "mt120"}`}>
                    <QuestionnaireButtonFooterV2>
                        <div
                            className={`new-questionnaire__form-continue ${!stepLoading ? "" : "disabled"} mt25`}
                            role="button"
                            onClick={() => {
                                if (!stepLoading) {
                                    handleContinue();
                                }
                            }}
                        >
                            {stepLoading ? <ButtonLoading/>
                                : <span>{"Continue"}</span>}
                        </div>
                    </QuestionnaireButtonFooterV2>
                </div>}
            </div>
            {isExplainerModalOpened && (
                <ExplainerModal
                    isOpen={isExplainerModalOpened}
                    closeModal={handleExplainerCloseModal}
                    subTitle={`${currentStepData.title} - ${taxProfile.name}`}
                />
            )}
        </div>
    );
};

export default ProfileOBExpensesNew;
