import { useEffect, useRef } from "react";

import buttonLoadingAnimation from "assets/animation/button-loading.json";
import Lottie, { LottieRefCurrentProps } from "lottie-react";

const ButtonLoadingAnimation = () => {
    const lottieRef = useRef<LottieRefCurrentProps | null>(null);

    useEffect(() => {
        if (lottieRef.current) {
            lottieRef.current.setSpeed(2);
        }
    }, []);

    return (
        <Lottie
            animationData={buttonLoadingAnimation}
            lottieRef={lottieRef}
            loop
            style={{ height: "90px", margin: "6px auto 0" }}
        />
    );
};

export default ButtonLoadingAnimation;
