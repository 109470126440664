import { ProfileType } from "components/pages/NewQuestionnaire/enums/enum";

export const applyFiltersSortSearchTransactions = (
    filters: Record<string, any>,
    taxProfileId: string,
    taxProfileType: ProfileType,
    currentTaxYear: string
): string => {
    const params = new URLSearchParams();

    // Ensure only negative amounts are considered by default as we are only considering expenses
    params.set("amount[lte]", "0");

    if (filters.viewFilter === "unreviewed") {
        params.set("swiped.approved", "false");
        params.set("swiped.rejected", "false");
    } else if (filters.viewFilter === "approved") {
        params.set("swiped.approved", "true");
        params.set("swiped.rejected", "false");
        params.set(`swiped.byTaxProfile.${taxProfileId}.approved`, "true");
        params.set(`swiped.byTaxProfile.${taxProfileId}.rejected`, "false");
    } else if (filters.viewFilter === "rejected") {
        params.set("swiped.approved", "false");
        params.set("swiped.rejected", "true");
        params.set(`swiped.byTaxProfile.${taxProfileId}.approved`, "false");
        params.set(`swiped.byTaxProfile.${taxProfileId}.rejected`, "true");
    }

    // Amount Range
    if (filters.amountRange) {
        const [min, max] = filters.amountRange;
        if (min !== null && !isNaN(min)) {
            params.set("amount[lte]", `-${min}`); // Convert min to negative
        }
        if (max !== null && !isNaN(max)) {
            params.set("amount[gte]", `-${max}`); // Convert max to negative
        }
    }

    // Date Range
    if (filters.dateRange) {
        params.set("bookingDateTime[gte]", filters.dateRange[0]);
        params.set("bookingDateTime[lte]", filters.dateRange[1]);
    } else {
        const startYear = currentTaxYear.split("/")[0];
        const endYear = currentTaxYear.split("/")[1];

        // Create Date objects
        const startDate = new Date(`${startYear}-04-06T00:00:00Z`).toISOString();
        const endDate = new Date(`${endYear}-04-05T23:59:59Z`).toISOString();

        params.set("bookingDateTime[gte]", startDate);
        params.set("bookingDateTime[lte]", endDate);
    }

    // Other Filters
    if (filters.account?.length) {
        params.set("account[in]", filters.account);
    }

    if (filters.category?.length) {
        params.set(`category.byTaxProfileType.${taxProfileType}.derivedCategory[in]`, filters.category);
    }

    if (filters.searchQuery) {
        params.set("search", filters.searchQuery);
    }

    if (filters.sortBy) {
        params.set("sort", filters.sortBy);
    }

    if (filters.page) {
        params.set("page", filters.page);
    }

    if (filters.limit) {
        params.set("limit", filters.limit);
    }

    return params.toString();
};
