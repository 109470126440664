import { FC, useMemo, useRef } from "react";

import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import activeStepBankAccount from "assets/images/new-questionnaire/navigation/active-step-bank-account.svg";
import activeStepCapitalGains from "assets/images/new-questionnaire/navigation/active-step-capital-gains.svg";
import activeStepCIS from "assets/images/new-questionnaire/navigation/active-step-cis.svg";
import activeStepDividends from "assets/images/new-questionnaire/navigation/active-step-dividends.svg";
import activeStepEmployment from "assets/images/new-questionnaire/navigation/active-step-employment.svg";
import activeStepGeneral from "assets/images/new-questionnaire/navigation/active-step-general.svg";
import activeStepInterestIncome from "assets/images/new-questionnaire/navigation/active-step-interest-income.svg";
import activeStepPensionIncome from "assets/images/new-questionnaire/navigation/active-step-pension-income.svg";
import activeStepPersonalInfo from "assets/images/new-questionnaire/navigation/active-step-personal-info.svg";
import activeStepRental from "assets/images/new-questionnaire/navigation/active-step-rental.svg";
import activeStepReviewSubmit from "assets/images/new-questionnaire/navigation/active-step-review-submit.svg";
import activeStepSelfEmployment from "assets/images/new-questionnaire/navigation/active-step-self-employment.svg";
import activeStepStateBenefits from "assets/images/new-questionnaire/navigation/active-step-state-benefits.svg";
import disallowedStepCapitalGains from "assets/images/new-questionnaire/navigation/disallowed-step-capital-gains.svg";
import disallowedStepCis from "assets/images/new-questionnaire/navigation/disallowed-step-cis.svg";
import disallowedStepDividends from "assets/images/new-questionnaire/navigation/disallowed-step-dividends.svg";
import disallowedStepEmployment from "assets/images/new-questionnaire/navigation/disallowed-step-employment.svg";
import disallowedStepGeneral from "assets/images/new-questionnaire/navigation/disallowed-step-general.svg";
import disallowedStepInterestIncome from "assets/images/new-questionnaire/navigation/disallowed-step-interest-income.svg";
import disallowedStepPensionIncome from "assets/images/new-questionnaire/navigation/disallowed-step-pension-income.svg";
import disallowedStepPersonalInfo from "assets/images/new-questionnaire/navigation/disallowed-step-personal-info.svg";
import disallowedStepRental from "assets/images/new-questionnaire/navigation/disallowed-step-rental.svg";
import disallowedStepReviewSubmit from "assets/images/new-questionnaire/navigation/disallowed-step-review-submit.svg";
import disallowedStepSelfEmployment from "assets/images/new-questionnaire/navigation/disallowed-step-self-employment.svg";
import disallowedStepStateBenefits from "assets/images/new-questionnaire/navigation/disallowed-step-state-benefits.svg";
import doneStepBankAccount from "assets/images/new-questionnaire/navigation/done-step-bank-account.svg";
import doneStepCapitalGains from "assets/images/new-questionnaire/navigation/done-step-capital-gains.svg";
import doneStepCIS from "assets/images/new-questionnaire/navigation/done-step-cis.svg";
import doneStepDividends from "assets/images/new-questionnaire/navigation/done-step-dividends.svg";
import doneStepEmployment from "assets/images/new-questionnaire/navigation/done-step-employment.svg";
import doneStepGeneral from "assets/images/new-questionnaire/navigation/done-step-general.svg";
import doneStepInterestIncome from "assets/images/new-questionnaire/navigation/done-step-interest-income.svg";
import doneStepPensionIncome from "assets/images/new-questionnaire/navigation/done-step-pension-income.svg";
import doneStepPersonalInfo from "assets/images/new-questionnaire/navigation/done-step-personal-info.svg";
import doneStepRental from "assets/images/new-questionnaire/navigation/done-step-rental.svg";
import doneStepReviewSubmit from "assets/images/new-questionnaire/navigation/done-step-review-submit.svg";
import doneStepSelfEmployment from "assets/images/new-questionnaire/navigation/done-step-self-employment.svg";
import doneStepStateBenefits from "assets/images/new-questionnaire/navigation/done-step-state-benefits.svg";
import undoneStepBankAccount from "assets/images/new-questionnaire/navigation/undone-step-bank-account.svg";
import undoneStepCapitalGains from "assets/images/new-questionnaire/navigation/undone-step-capital-gains.svg";
import undoneStepCIS from "assets/images/new-questionnaire/navigation/undone-step-cis.svg";
import undoneStepDividends from "assets/images/new-questionnaire/navigation/undone-step-dividends.svg";
import undoneStepEmployment from "assets/images/new-questionnaire/navigation/undone-step-employment.svg";
import undoneStepGeneral from "assets/images/new-questionnaire/navigation/undone-step-general.svg";
import undoneStepInterestIncome from "assets/images/new-questionnaire/navigation/undone-step-interest-income.svg";
import undoneStepPensionIncome from "assets/images/new-questionnaire/navigation/undone-step-pension-income.svg";
import undoneStepPersonalInfo from "assets/images/new-questionnaire/navigation/undone-step-personal-info.svg";
import undoneStepRental from "assets/images/new-questionnaire/navigation/undone-step-rental.svg";
import undoneStepReviewSubmit from "assets/images/new-questionnaire/navigation/undone-step-review-submit.svg";
import undoneStepSelfEmployement from "assets/images/new-questionnaire/navigation/undone-step-self-employment.svg";
import undoneStepStateBenefits from "assets/images/new-questionnaire/navigation/undone-step-state-benefits.svg";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { markCategoryInProgress } from "redux/reducers/navigation";
import { RootState } from "redux/store";

import { getIcon } from "./MobileMenu";
import { categories } from "../constants/questions";
import { CategoryTitle } from "../enums/enum";

/* eslint-disable */
export enum StepStatus {
    undone = "undone",
    active = "active",
    done = "done",
    disallowed = "disallowed",
    inProgress = "inProgress",
}
/* eslint-enable */

type INavigationStep = {
    label: string;
    done: string;
    active: string;
    undone: string;
    inProgress: string;
    disallowed: string;
    categoryIndex: number;
    disabled: boolean;
}

type DesktopMenuProps = {
    handleCategoryClick: (_categoryIndex: number) => void;
};

export const getStatus = (item: INavigationStep, currentCategory: number, completedCategories: CategoryTitle[], inProgressCategories: CategoryTitle[]): StepStatus => {
    if (item.categoryIndex === currentCategory) return StepStatus.active;
    if (item.disabled) return StepStatus.disallowed;
    if (completedCategories?.includes(categories[item.categoryIndex].title)) return StepStatus.done;
    if (inProgressCategories?.includes(categories[item.categoryIndex].title)) return StepStatus.inProgress;
    if (!completedCategories?.includes(categories[item.categoryIndex].title) && !inProgressCategories?.includes(categories[item.categoryIndex].title)) return StepStatus.disallowed;

    return StepStatus.undone;
};

export const allCategories: INavigationStep[] = [
    {
        label: CategoryTitle.BankAccountConnection,
        done: doneStepBankAccount,
        active: activeStepBankAccount,
        undone: undoneStepBankAccount,
        inProgress: undoneStepBankAccount,
        disallowed: disallowedStepCis,
        categoryIndex: categories?.findIndex(category => category?.title === CategoryTitle.BankAccountConnection),
        disabled: false,
    },
    {
        label: CategoryTitle.PersonalInfo,
        done: doneStepPersonalInfo,
        active: activeStepPersonalInfo,
        undone: undoneStepPersonalInfo,
        inProgress: undoneStepPersonalInfo,
        disallowed: disallowedStepPersonalInfo,
        categoryIndex: categories?.findIndex(category => category?.title === CategoryTitle.PersonalInfo),
        disabled: true,
    },
    {
        label: CategoryTitle.SelfEmployment,
        done: doneStepSelfEmployment,
        active: activeStepSelfEmployment,
        undone: undoneStepSelfEmployement, // Will become deprecated
        inProgress: undoneStepSelfEmployement,
        disallowed: disallowedStepSelfEmployment,
        categoryIndex: categories?.findIndex(category => category?.title === CategoryTitle.SelfEmployment),
        disabled: true,
    },
    {
        label: CategoryTitle.Employment,
        done: doneStepEmployment,
        active: activeStepEmployment,
        undone: undoneStepEmployment, // Will become deprecated
        inProgress: undoneStepEmployment,
        disallowed: disallowedStepEmployment,
        categoryIndex: categories?.findIndex(category => category?.title === CategoryTitle.Employment),
        disabled: true,
    },
    {
        label: CategoryTitle.CIS,
        done: doneStepCIS,
        active: activeStepCIS,
        undone: undoneStepCIS, // Will become deprecated
        inProgress: undoneStepCIS,
        disallowed: disallowedStepCis,
        categoryIndex: categories?.findIndex(category => category?.title === CategoryTitle.CIS),
        disabled: true,
    },
    {
        label: CategoryTitle.Rental,
        done: doneStepRental,
        active: activeStepRental,
        undone: undoneStepRental, // Will become deprecated
        inProgress: undoneStepRental,
        disallowed: disallowedStepRental,
        categoryIndex: categories?.findIndex(category => category?.title === CategoryTitle.Rental),
        disabled: true,
    },
    {
        label: CategoryTitle.General,
        done: doneStepGeneral,
        active: activeStepGeneral,
        undone: undoneStepGeneral, // Will become deprecated
        inProgress: undoneStepGeneral,
        disallowed: disallowedStepGeneral,
        categoryIndex: categories?.findIndex(category => category?.title === CategoryTitle.General),
        disabled: true,
    },
    {
        label: CategoryTitle.PensionIncome,
        done: doneStepPensionIncome,
        active: activeStepPensionIncome,
        undone: undoneStepPensionIncome, // Will become deprecated
        inProgress: undoneStepPensionIncome,
        disallowed: disallowedStepPensionIncome,
        categoryIndex: categories?.findIndex(category => category?.title === CategoryTitle.PensionIncome),
        disabled: true,
    },
    {
        label: CategoryTitle.StateBenefits,
        done: doneStepStateBenefits,
        active: activeStepStateBenefits,
        undone: undoneStepStateBenefits,
        inProgress: undoneStepStateBenefits, // Will become deprecated
        disallowed: disallowedStepStateBenefits,
        categoryIndex: categories?.findIndex(category => category?.title === CategoryTitle.StateBenefits),
        disabled: true,
    },
    {
        label: CategoryTitle.CapitalGains,
        done: doneStepCapitalGains,
        active: activeStepCapitalGains,
        undone: undoneStepCapitalGains, // Will become deprecated
        inProgress: undoneStepCapitalGains,
        disallowed: disallowedStepCapitalGains,
        categoryIndex: categories?.findIndex(category => category?.title === CategoryTitle.CapitalGains),
        disabled: true,
    },
    {
        label: CategoryTitle.Dividends,
        done: doneStepDividends,
        active: activeStepDividends,
        undone: undoneStepDividends, // Will become deprecated
        inProgress: undoneStepDividends,
        disallowed: disallowedStepDividends,
        categoryIndex: categories?.findIndex(category => category?.title === CategoryTitle.Dividends),
        disabled: true,
    },
    {
        label: CategoryTitle.InterestIncome,
        done: doneStepInterestIncome,
        active: activeStepInterestIncome,
        undone: undoneStepInterestIncome, // Will become deprecated
        inProgress: undoneStepInterestIncome,
        disallowed: disallowedStepInterestIncome,
        categoryIndex: categories?.findIndex(category => category?.title === CategoryTitle.InterestIncome),
        disabled: true,
    },
    {
        label: CategoryTitle.ReviewAndSubmit,
        done: doneStepReviewSubmit,
        active: activeStepReviewSubmit,
        undone: undoneStepReviewSubmit, // Will become deprecated
        inProgress: undoneStepReviewSubmit,
        disallowed: disallowedStepReviewSubmit,
        categoryIndex: categories?.findIndex(category => category?.title === CategoryTitle.ReviewAndSubmit),
        disabled: true,
    },
];

export const getRelevantNavigationItems = (
    navTabs: string[],
    currentCategory: number,
    completedCategories: CategoryTitle[],
    inProgressCategories: CategoryTitle[],
    dispatch: Dispatch<AnyAction>
) => {
    // Sort and filter categories based on provided navTabs
    const navigationItems = allCategories
        .filter(category => navTabs?.includes(category.label))
        .sort((a, b) => (a.categoryIndex || 0) - (b.categoryIndex || 0));

    // Find the earliest in-progress category index
    const earliestInProgressIndex = navigationItems.findIndex(item =>
        inProgressCategories.includes(item.label as CategoryTitle)
    );

    navigationItems.forEach((item, index) => {
        const isActiveCategory =
            completedCategories.includes(item.label as CategoryTitle) ||
            inProgressCategories.includes(item.label as CategoryTitle) ||
            categories[currentCategory]?.title === item.label;

        // Everything after the earliest in-progress category is disabled
        if (earliestInProgressIndex !== -1 && index > earliestInProgressIndex) {
            item.disabled = true;
        } else {
            item.disabled = false;
        }

        // If the category is before the earliest in-progress one and isn't active, mark it in progress
        if (index < earliestInProgressIndex && !isActiveCategory) {
            dispatch(markCategoryInProgress(item.label as CategoryTitle));
        }
    });

    return navigationItems;
};

const DesktopMenu: FC<DesktopMenuProps> = ({ handleCategoryClick }) => {
    const dispatch = useDispatch();
    const menuRef = useRef<HTMLDivElement>(null);
    const activeStepRef = useRef<HTMLDivElement>(null);
    const { navigationItems, currentCategory, completedCategories, inProgressCategories } = useAppSelector((state: RootState) => state.navigation);

    const dynamicItems = useMemo(() => {
        return getRelevantNavigationItems(navigationItems, currentCategory, completedCategories, inProgressCategories, dispatch);
    }, [navigationItems, completedCategories, inProgressCategories, currentCategory, dispatch]);

    return (
        <div className="new-questionnaire__mobile-menu mt10" ref={menuRef}>
            {dynamicItems.map((item, index) => {
                if (index === dynamicItems.length) return null;
                const status = getStatus(item, currentCategory, completedCategories, inProgressCategories);
                return (
                    <div
                        key={index}
                        className={`new-questionnaire__mobile-step new-questionnaire__mobile-step--${status} ${item.disabled ? "new-questionnaire__mobile-step--disabled" : ""}`}
                        ref={status === "active" ? activeStepRef : null}
                        onClick={event => {
                            if (item.disabled) {
                                event?.preventDefault();
                                return;
                            }
                            handleCategoryClick(item.categoryIndex);
                        }}
                    >
                        <div className="new-questionnaire__mobile-menu-text">{item.label}</div>
                        <div className="new-questionnaire__mobile-icon">{getIcon(status)}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default DesktopMenu;
