import { useState, useEffect } from "react";

const useDebounce = <T>(value: T, delay: number, resetTrigger?: any): T => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timeoutId); // Clear timeout if value, delay, or resetTrigger changes
        };
    }, [value, delay, resetTrigger]); // Include resetTrigger in the dependency array

    return debouncedValue;
};

export default useDebounce;
