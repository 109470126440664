import "styles/profile/profileTab.less";

import { Dispatch, SetStateAction, useEffect } from "react";

import { Typography } from "antd";
import logoutDefaultIcon from "assets/images/profile/logout.svg";
import bankDetails from "assets/images/profile/profile_tab/bank-details.svg";
import personalDetails from "assets/images/profile/profile_tab/personal-details.svg";
import chevron from "assets/images/profile/profile_tab/right-chevron.svg";
import support from "assets/images/profile/profile_tab/support.svg";
import terms from "assets/images/profile/profile_tab/terms.svg";
import ProfileTabNameCircle from "components/common/ProfileTabNameCircle";
import { useLocalStorage } from "hooks/helpers/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import { resetAnswers } from "redux/reducers/answers";
import { logout } from "redux/reducers/auth";
import { resetNavigation } from "redux/reducers/navigation";
import { resetTaxProfile } from "redux/reducers/taxProfile";
import { IUser } from "types/auth";
import { IClientState } from "types/client";
import { ProgressT } from "types/questionnaire";

import { resetDocument } from "../../../../redux/reducers/document";

type ProfileTabProps = {
    user: IUser;
    setSelectedTab: (_tab: string) => void;
    setPageLoading: Dispatch<SetStateAction<boolean>>
};

export default function ProfileTab({ user, setSelectedTab, setPageLoading }: ProfileTabProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [, setLocalClientData] = useLocalStorage<IClientState | null>("clientData", null);
    const [, setTotalProgress] = useLocalStorage<ProgressT>("new_questionnaire_total_progress", {});

    useEffect(() => {
        const timer = setTimeout(() => {
            setPageLoading(false);
        }, 200);
        return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="profile__content">
            <div className="profile-tab__content">
                <div >
                    <ProfileTabNameCircle firstName={user?.info?.firstName} lastName={user?.info?.lastName} />
                </div>
                <Typography className="profile-tab__name">
                    {user?.info?.firstName} {user?.info?.lastName}
                </Typography>
                <Typography className="profile-tab__email">
                    {user?.info?.email}
                </Typography>
                <div className="profile-tab__menu">
                    <Typography className="profile-tab__menu-group-title">
                        Account Settings
                    </Typography>
                    <div className="profile-tab__menu-item" onClick={() => setSelectedTab("Personal Details")}>
                        <div>
                            <img src={personalDetails} alt="Personal Details" />
                            <Typography className="profile-tab__menu-item-title">
                                Personal Details
                            </Typography>
                        </div>
                        <img src={chevron} alt="chevron" />
                    </div>
                    <div className="profile-tab__menu-item" onClick={() => setSelectedTab("Profile Bank Details")}>
                        <div>
                            <img src={bankDetails} alt="Bank Details" />
                            <Typography className="profile-tab__menu-item-title">
                                Bank Details
                            </Typography>
                        </div>
                        <img src={chevron} alt="chevron" />
                    </div>
                    <Typography className="profile-tab__menu-group-title">
                        Support
                    </Typography>
                    <div className="profile-tab__menu-item" onClick={() => setSelectedTab("Customer Support")}>
                        <div>
                            <img src={support} alt="Customer Support" />
                            <Typography className="profile-tab__menu-item-title">
                                Customer Support
                            </Typography>
                        </div>
                        <img src={chevron} alt="chevron" />
                    </div>
                    <Typography className="profile-tab__menu-group-title">
                        Legal
                    </Typography>
                    <div className="profile-tab__menu-item" onClick={() => navigate("/terms-and-conditions")}>
                        <div>
                            <img src={terms} alt="Customer Support" />
                            <Typography className="profile-tab__menu-item-title">
                                Terms and Conditions
                            </Typography>
                        </div>
                        <img src={chevron} alt="chevron" />
                    </div>
                    <div className="profile-tab__menu-item" onClick={() => {
                        dispatch(resetAnswers());
                        dispatch(resetTaxProfile());
                        setTotalProgress({});
                        setLocalClientData(null);
                        dispatch(resetNavigation());
                        dispatch(resetDocument());
                        dispatch(logout());
                    }}>
                        <div>
                            <img src={logoutDefaultIcon} alt="Customer Support" />
                            <Typography className="profile-tab__menu-item-title">
                                Logout
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
