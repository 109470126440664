import "styles/profile/paymentPlans.less";

import React, { useEffect } from "react";

import { IClientDocument, IRefund } from "types/client";

import { PaymentOption } from "./Payment";
import { PaymentPlanOptions } from "./PaymentPlanOptions";

export const PaymentPlan: React.FC<{
    allowedPaymentOptions: PaymentOption[]
	shownAmount: number
	currentDocument?: IClientDocument
    selectedPaymentOption: PaymentOption | null
    handlePlanConfirm: ()=>void
    setSelectedPaymentOption: (_value: PaymentOption | null) => void
    refund?: IRefund

}> = ({ allowedPaymentOptions, shownAmount, currentDocument, selectedPaymentOption, handlePlanConfirm, setSelectedPaymentOption, refund }) => {
    useEffect(() => {
        if (allowedPaymentOptions.length === 1) {
            setSelectedPaymentOption(allowedPaymentOptions[0]);
        } else if (allowedPaymentOptions.length > 1) {
            setSelectedPaymentOption(allowedPaymentOptions.find(p => {
                return p.id === (currentDocument?.plan || "express-cis");
            }) || null);
        }
    }, [allowedPaymentOptions, currentDocument?.plan, setSelectedPaymentOption]);

    return (<>
        <div className="payment-plans__header">
            <div className="payment-plans__header-text">
                {selectedPaymentOption?.id === "express-cis" &&
                    "Receive your refund quickly deposited into your bank account."
                }
                {/* {selectedPaymentOption?.id === "one4all" &&
                    "Receive your refund as a digital one4all card."
                } */}
                {selectedPaymentOption?.id === "standard-cis" &&
                    "Receive your refund in your bank account"
                }
                {/* {selectedPaymentOption?.id === "basic" &&
                    "Receive your refund via a check sent to your address"
                } */}
            </div>
        </div>
        <PaymentPlanOptions
            shownAmount={shownAmount}
            selectedPaymentOption={selectedPaymentOption}
            setSelectedPaymentOption={setSelectedPaymentOption}
            handlePlanConfirm={handlePlanConfirm}
            paymentOptions={allowedPaymentOptions}
            refund={refund}
        />
    </>
    );
};
