import { useCallback, useContext } from "react";

import { Button } from "antd";
import { AppContext } from "App";
import rightPurpleArrow from "assets/images/uk/right-arrow-purple.svg";
import rightWhiteArrow from "assets/images/uk/right-arrow-white.svg";
import { useNavigate } from "react-router-dom";
import { scrollToElement, scrollToTop } from "utils/common";

const StartButtonNew = ({
    label = "Start now",
    purple,
    width,
}: {
    label?: string;
    purple? :boolean;
    width?: string;
}) => {
    const navigate = useNavigate();
    const { isMobile, toggleFormScale, isAuth } = useContext(AppContext);

    const handleStart = useCallback(
        () => {
            if (isMobile) {
                scrollToTop();
                navigate("/sign-up?step=personalInfo");
            } else if (isAuth) {
                scrollToTop();
                navigate("/auth");
            } else {
                toggleFormScale(true);
                scrollToElement("sign-up");
            }
        },
        [isMobile, navigate, toggleFormScale, isAuth]
    );
    return <div className={"horizontal-left"}>
        <Button
            shape={"round"}
            size="large"
            type="primary"
            className={`uk-rounded-button-start-new ${purple ? "purple" : ""}`}
            onClick={handleStart}
            style={{ width }}
        >
            {isAuth ? "Profile" : label}
        </Button>

        <div className={"uk-rounded-button-start-arrow-new"} onClick={handleStart}>
            <img src={purple ? rightPurpleArrow : rightWhiteArrow} width={20} height={20} alt={"start button"}/>
        </div>
    </div>;
};

export default StartButtonNew;
