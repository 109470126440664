import React, { useContext, useState, SetStateAction, Dispatch, useMemo } from "react";

import { SearchOutlined, LeftOutlined } from "@ant-design/icons";
import { Typography, Input, Image, List } from "antd";
import BankAccountMode from "components/modals/Payment/BankAccountMode";

import { useHandleRegionalBank } from "./handleRegionalBank";
import { AppContext } from "../../../../../App";
import done from "../../../../../assets/images/checkmark.svg";
import { useAppSelector } from "../../../../../redux/hooks";
import { RootState } from "../../../../../redux/store";
import { extractCountryCodeAndRefParams } from "../../../../../utils/urlUtils";

interface Bank {
    _id: string;
    name: string;
    logo: string;
}

type SelectBankAccountProps = {
    setSelectedTab: (_tab: string) => void;
    setPageLoading: Dispatch<SetStateAction<boolean>>;
};

const SelectBankAccount: React.FC<SelectBankAccountProps> = ({ setPageLoading, setSelectedTab }) => {
    const { isMobile } = useContext(AppContext);
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [selectedBank, setSelectedBank] = useState<string | null>(null);
    const [paymentModeModal, setPaymentModeModal] = useState<boolean>(false);
    const [countryCodePrefix] = extractCountryCodeAndRefParams(window.location.href);

    const countryRegion = useAppSelector((state: RootState) => state.bank.countryRegion);

    const filteredBanks = countryRegion.filter((bank: Bank) =>
        bank.name.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    const mostPopularBanks = useMemo(() => countryRegion.slice(0, 5), [countryRegion]);
    const { handleRegionalBank } = useHandleRegionalBank(isMobile, setPageLoading, setPaymentModeModal);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(e.target.value);
        setSelectedBank(null);
    };

    const handleFinish = (id: string) => {
        setSelectedBank(id);
        handleRegionalBank(id);
    };

    const searchBankLength = filteredBanks.length;
    return countryRegion.length > 0 ? (
        <div className="profile__content">
            {!isMobile && (
                <div className="searchBank_heading">
                    <div className="profile__content-header">
                        <LeftOutlined />
                        <Typography className="profile__content-heading">Search Your Bank</Typography>
                    </div>
                </div>
            )}
            <div className="profile__content-body">
                <Typography className="selectbank_heading">
                    {`Please select your bank to receive your tax refund. Make sure your account’s currency is ${countryCodePrefix === "IE" ? "€ euro" : "£ pound"
                    }.`}
                </Typography>
                <Input
                    size="large"
                    placeholder="Search your bank"
                    prefix={<SearchOutlined style={{ width: "24px" }} />}
                    className="bank_search"
                    onChange={handleInputChange}
                />
                <div className="scrollable-banklist">
                    {!searchKeyword && (
                        <div>
                            <Typography
                                className="payment-details__content-heading"
                                style={{ fontSize: "18px", marginLeft: !isMobile ? "4%" : "6%" }}
                            >
                                Most Popular Banks
                            </Typography>
                            <List
                                itemLayout="horizontal"
                                dataSource={mostPopularBanks}
                                className="scrollable-banklist"
                                renderItem={item => (
                                    <List.Item
                                        className={`bankOption_list ${selectedBank === item._id ? "selected" : ""}`}
                                        onClick={() => handleFinish(item._id)}
                                    >
                                        <List.Item.Meta
                                            className="banklist_meta"
                                            avatar={<Image className="bankOption_logo" src={item.logo} preview={false} />}
                                            title={<span className="bankOption_title">{item.name}</span>}
                                        />
                                        {selectedBank === item._id && <Image src={done} className="check_icon" preview={false} />}
                                    </List.Item>
                                )}
                            />
                        </div>
                    )}
                    <Typography
                        className="payment-details__content-heading"
                        style={{ fontSize: "18px", marginLeft: !isMobile ? "4%" : "6%" }}
                    >
                        {!searchKeyword
                            ? "All Banks (A-Z)"
                            : filteredBanks.length > 0
                                ? `${searchBankLength} results`
                                : ""}
                    </Typography>
                    <List
                        itemLayout="horizontal"
                        dataSource={filteredBanks}
                        renderItem={item => (
                            <List.Item
                                className={`bankOption_list ${selectedBank === item._id ? "selected" : ""}`}
                                onClick={() => handleFinish(item._id)}
                            >
                                <List.Item.Meta
                                    className="banklist_meta"
                                    avatar={<Image className="bankOption_logo" src={item.logo} preview={false} />}
                                    title={<span className="bankOption_title">{item.name}</span>}
                                />
                                {selectedBank === item._id && <Image src={done} className="check_icon" preview={false} />}
                            </List.Item>
                        )}
                    />
                </div>
            </div>
            <BankAccountMode
                paymentModeModal={paymentModeModal}
                setPaymentModeModal={setPaymentModeModal}
            />
        </div>
    ) : (
        setSelectedTab("Payment"), null
    );
};

export default SelectBankAccount;
