import React, { useContext } from "react";

import { Alert, List, Progress, Typography } from "antd";
import { AppContext } from "App";
import greenTick from "assets/images/new-questionnaire/documentParse/green_tick.svg";

import { Receipt } from "./DocumentParseQuestion";

interface DocumentUploadListProps {
    uploadedCategoryReceipts: Receipt[];
    getFileIcon: (_fileName: string) => string;
    uploadErrorMessage: string;
}

const DocumentUploadList: React.FC<DocumentUploadListProps> = ({ uploadedCategoryReceipts, getFileIcon, uploadErrorMessage }) => {
    const { isMobile } = useContext(AppContext);
    return (
        <div className={isMobile ? "mt12" : ""}>
            {uploadErrorMessage && (
                <Alert
                    message={uploadErrorMessage}
                    type="error"
                    showIcon
                />
            )}
            <List>
                {uploadedCategoryReceipts.map((receipt, index) => (
                    <List.Item key={index}>
                        <div className={"document-parse__array-upload"}>
                            <div className="document-parse__array-item-wrapper">
                                <div>
                                    <img src={getFileIcon(receipt.originalName)} alt={receipt.originalName} />
                                </div>
                                <div>
                                    <Typography.Text
                                        ellipsis
                                        className={"document-parse__ellipsis-text document-parse__array-v2-item fs13"}
                                    >
                                        {receipt.originalName}
                                    </Typography.Text>
                                    <div className="document-parse__array-v2-item">
                                        <div className="document-parse__upload">
                                            {receipt.progress === 50 ? "Uploading..." : <><img src={greenTick} alt="green tick"/>Completed</>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Progress
                                percent={receipt.progress}
                                strokeColor={receipt.progress === 100 ? "#2ebb49" : "#7F43FF"}
                                showInfo={false}
                                size="small"
                                className="mb20"
                            />
                        </div>

                    </List.Item>
                ))}
            </List>
        </div>
    );
};

export default DocumentUploadList;
