import "styles/profile/paymentPlans.less";
import "swiper/css";
import "swiper/css/effect-coverflow";

import React, { useCallback, useContext, useMemo } from "react";

import { Button, Col, Divider, Row, Typography } from "antd";
import { AppContext } from "App";
import checkIcon from "assets/images/profile/check.svg";
import arrowForward from "assets/images/profile/forward-arrow.svg";
import mostPopular from "assets/images/profile/most-popular.svg";
import ProfileButtonFooter from "components/basic/Footer/ProfileButtonFooter";
import { EffectCoverflow } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { IRefund } from "types/client";
import { round2Decimals } from "utils/numbers";

import { PaymentOption } from "./Payment";

export const PaymentPlanOptions: React.FC<{
    selectedPaymentOption: PaymentOption | null;
    shownAmount: number
    setSelectedPaymentOption: (_value: PaymentOption | null) => void;
    handlePlanConfirm: () => void;
    paymentOptions: PaymentOption[];
    refund?: IRefund

}> = ({ selectedPaymentOption, setSelectedPaymentOption, paymentOptions, handlePlanConfirm, shownAmount, refund }) => {
    const { isMobile } = useContext(AppContext);

    const handleSlideChange = (swiper: any) => {
        setSelectedPaymentOption(paymentOptions[swiper.activeIndex]);
    };

    const initialSlideIndex = useMemo(() => {
        if (selectedPaymentOption === null) return null;
        return selectedPaymentOption
            ? paymentOptions.findIndex(option => option.id === selectedPaymentOption.id)
            : 0;
    }, [paymentOptions, selectedPaymentOption]);

    const optionAmount = useCallback((amount: number, option: PaymentOption) => {
        const baseFee = refund?.customFeeOptions?.enabled ? refund.fee : option.fee.minimum;
        const fee = round2Decimals((baseFee + option.fee.fixedCost));
        const vat = round2Decimals(fee * option.vat);
        const netRefund = round2Decimals(amount - baseFee - option.fee.fixedCost - vat);
        return ("£" + netRefund.toFixed(2));
    }, [refund]);

    return (<>
        <div className="payment-plans__options">
            {isMobile ? (<>
                {initialSlideIndex !== null &&
                <div style={{ width: "100%" }}>
                    <Swiper
                        {...(paymentOptions?.length > 1 && { slidesPerView: 1.2 } as any)}
                        {...({ spaceBetween: 16 } as any)}
                        centeredSlides={true}
                        effect={"coverflow"}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            modifier: 1,
                            slideShadows: false,
                            scale: 0.9,
                        }}
                        initialSlide={initialSlideIndex}
                        onSlideChange={swiper => handleSlideChange(swiper)}
                        modules={[EffectCoverflow]}
                    >
                        {paymentOptions.map((option, index) => {
                            const isPlanSelected = selectedPaymentOption?.title === option.title;
                            return (
                                <SwiperSlide key={index}>
                                    <Col
                                        className={`payment-plans__card ${
                                            isPlanSelected ? "selected" : ""
                                        }`}
                                    >
                                        <div>
                                            <Typography className="payment-plans__option-title">
                                                {option.title}
                                            </Typography>
                                            <Typography className="payment-plans__option-subtitle">
                                                {option.subtitle}
                                            </Typography>
                                            <Typography className="payment-plans__receive_prompt">
                                                You will receive:
                                            </Typography>
                                            <div className="payment-plans__option-refund-container">
                                                <img
                                                    src={option.icon || ""}
                                                    alt="option icon"
                                                    height={36}
                                                />
                                                <div className="payment-plans__option-refund">
                                                    {optionAmount(shownAmount, option)}
                                                </div>
                                            </div>
                                            <Divider style={{ margin: "15px 0" }} />
                                            <Typography
                                                className="payment-plans__option-description"
                                                style={{ marginTop: "20px" }}
                                            >
                                                <img
                                                    src={checkIcon}
                                                    alt="check icon"
                                                    height={24}
                                                    style={{ marginRight: "5px" }}
                                                />
                                                {option.processingTime}
                                            </Typography>
                                            {option.additionalInfo && (
                                                <Typography className="payment-plans__option-description">
                                                    <img
                                                        src={checkIcon}
                                                        alt="check icon"
                                                        height={24}
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                    {option.additionalInfo}
                                                </Typography>
                                            )}
                                            <Typography className="payment-plans__option-description">
                                                <img
                                                    src={checkIcon}
                                                    alt="check icon"
                                                    height={24}
                                                    style={{ marginRight: "5px" }}
                                                />
                                                {option.feeInfo}
                                            </Typography>
                                        </div>
                                        {option.mostPopular && <img
                                            src={mostPopular}
                                            alt="most popular"
                                            height={80}
                                            className="payment-plans__most-popular"
                                        />}
                                    </Col>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>}
            </>
            ) : (
                <Row className="payment-plans__row">
                    {paymentOptions.map((option, index) => {
                        const isPlanSelected = selectedPaymentOption?.title === option.title;
                        return (
                            <Col
                                key={index}
                                className={`payment-plans__card ${
                                    isPlanSelected ? "selected" : ""
                                }`}
                                data-testid={`payment-select-plan-${option.id}`}
                                onClick={() => setSelectedPaymentOption(option)}
                            >
                                <div>
                                    <Typography className="payment-plans__option-title">
                                        {option.title}
                                    </Typography>
                                    <Typography className="payment-plans__option-subtitle">
                                        {option.subtitle}
                                    </Typography>
                                    <Typography className="payment-plans__receive_prompt">
                                        You will receive:
                                    </Typography>
                                    <div className="payment-plans__option-refund-container">
                                        <img
                                            src={option.icon || ""}
                                            alt="option icon"
                                            height={36}
                                        />
                                        <div className="payment-plans__option-refund">
                                            {optionAmount(shownAmount, option)}
                                        </div>
                                    </div>
                                    <Divider style={{ margin: "20px 0" }} />
                                    <Typography
                                        className="payment-plans__option-description"
                                        style={{ marginTop: "20px" }}
                                    >
                                        <img
                                            src={checkIcon}
                                            alt="check icon"
                                            height={24}
                                            style={{ marginRight: "5px" }}
                                        />
                                        {option.processingTime}
                                    </Typography>
                                    {option.additionalInfo && (
                                        <Typography className="payment-plans__option-description">
                                            <img
                                                src={checkIcon}
                                                alt="check icon"
                                                height={24}
                                                style={{ marginRight: "5px" }}
                                            />
                                            {option.additionalInfo}
                                        </Typography>
                                    )}
                                    <Typography className="payment-plans__option-description">
                                        <img
                                            src={checkIcon}
                                            alt="check icon"
                                            height={24}
                                            style={{ marginRight: "5px" }}
                                        />
                                        {option.feeInfo}
                                    </Typography>
                                    {/* <Typography className="payment-plans__option-vat">
                                        {option.vat}
                                    </Typography> */}
                                </div>
                                <div
                                    className="payment-select-plan-button"
                                    data-testid={`payment-select-plan-button-${option.id}`}
                                    onClick={() => {
                                        isPlanSelected
                                            ? handlePlanConfirm()
                                            : setSelectedPaymentOption(option);
                                    }}
                                >
                                    {isPlanSelected ? "Confirm Plan" : "Select Plan"}
                                    <img
                                        src={arrowForward}
                                        alt="arrow forward"
                                        height={24}
                                    />
                                </div>
                                {option.mostPopular && <img
                                    src={mostPopular}
                                    alt="most popular"
                                    height={80}
                                    className="payment-plans__most-popular"
                                />}
                            </Col>
                        );
                    })}
                </Row>
            )}
        </div>
        {isMobile && <>
            <div className="payment-plans__dots">
                {paymentOptions.map(o => (
                    <span
                        className={`payment-plans__dot ${o.id === selectedPaymentOption?.id ? "active" : ""}`}
                        key={o.id}
                    ></span>
                ))}
            </div>
            {selectedPaymentOption &&
                <ProfileButtonFooter>
                    <Button
                        type="primary"
                        className="payment-select-plan-button profile__select-tab-button"
                        style={{ width: "335px" }}
                        onClick={() => {
                            handlePlanConfirm();
                        }}
                    >
                        Select Plan
                        <img
                            src={arrowForward}
                            alt="arrow forward"
                            height={24}
                        />
                    </Button>
                </ProfileButtonFooter>
            }
        </>
        }
    </>
    );
};
