import { UK_ROUTES_NEW } from "constants/routes-new";

import { useContext, useEffect } from "react";

import { Col, Collapse, CollapseProps, Row } from "antd";
import { AppContext } from "App";
import chevron from "assets/images/chevron-down-uk.svg";
import StartButtonNew from "components/basic/StartButtonNew";
import { Link } from "react-router-dom";

const FAQS = [{
    title: "How do I reset my password?",
    text: ["If you still have access to the email address you used to register with our service, you will need to follow the below steps in order to access your TaxZap profile."],
    bulletPoints: [<li key={"1.1"}>Go to the main login screen on our website <a className = "purple-link" href={"https://taxzap.co.uk"}>here.</a></li>,
        <li key={"1.2"}>Next to ‘Forgot your password?’ select ‘Reset’.</li>,
        <li key={"1.3"}>Enter the email address as well as your National Insurance Number and click Reset.</li>,
        <li key={"1.4"}>You will then receive an automated email from us with instructions on how to create a new password.</li>,
    ],
}, {
    title: "How do I change my email address?",
    text:  [
        <>
          In order to update your email address on our database, we need to verify
          your identity to ensure the security of your account. Please email us at{" "}
            <a className = "purple-link" href="mailto:support@taxzap.co.uk">support@taxzap.co.uk</a> and
          provide us with the following:
        </>,
    ],
    bulletPoints: [
        <li key={"2.1"}>A photograph of a government issued ID (Driver’s licence/Passport) matching your name & date of birth.</li>,
        <li key={"2.2"}>Your National Insurance Number</li>,
        <li key={"2.3"}>Email address you would like to update onto your TaxZap profile.</li>,
    ],
}, {
    title: "How much does it cost?",
    text: ["We charge one of the lowest fees in the UK of £179 (including VAT) for Self Assessment!"],
}, {
    title: "What is the difference between a tax refund and a tax return?",
    text: [ <>
    Simply put, you must file a tax return to get a tax refund from HMRC. If you are unsure how to do this, simply fill out our 2 minute form <a className = "purple-link" href={"https://taxzap.co.uk"}>here</a> and we will take care of the rest!
    </>],
}, {
    title: "What are your opening hours?",
    text: [<>We are open 9am-6pm Monday to Friday. We do not offer phone support, but we do offer email support at <a className = "purple-link" href="mailto:support@taxzap.co.uk">support@taxzap.co.uk</a>. We aim to reply to you within 12 hours.</>],
}, {
    title: "How long does it take?",
    text: ["As a recognised HMRC software provider, our integration allows us to file your tax return instantly. There’s no unnecessary delay — once your return is submitted, it goes directly to HMRC for processing."],
}, {
    title: "How long will it take to receive my refund?",
    text: ["If you’re due a refund, HMRC typically takes up to 8 weeks to issue it. While we can’t speed up their processing time, we’ll be here to follow up on your behalf and keep you informed every step of the way."],
}, {
    title: "After filing, do I pay my taxes straight away?",
    text: ["No, you don’t have to pay immediately. One of the benefits of filing early is that it gives you more time before your payment is due. HMRC will confirm how much you owe, and typically, payments aren’t due until 31st January following the end of the tax year. By filing early, you’ll know what you owe well in advance, giving you plenty of time to plan and pay."],
}, {
    title: "What documents do I need to file my tax return?",
    text: ["What you need to file your Self Assessment tax return really depends on your situation, but some common documents include your UTR, National Insurance number, and details of your income and expenses. If you're employed, this may include a P60 or P45, and if you're a CIS subcontractor, you'll need your CIS deduction statements. You should also have records of any tax reliefs or allowances you're eligible for, such as pension contributions or Gift Aid. If you're due a refund, have your bank details ready. Our platform will guide you through exactly what’s needed based on your circumstances."],
}, {
    title: "Do I need to register every year?",
    text: ["If you’ve filed a return with us before, we’ll securely save some of your personal information to make the process quicker and easier. However, you’ll still need to file your self-assessment every year. Don’t worry — we’ll send you a reminder via email or text to ensure you never miss a deadline."],
}, {
    title: "How to claim tax back?",
    text: ["Many construction workers are confused about how to get a tax refund. You can claim a refund either by filling HMRC tax return forms yourself, or easily online with TaxZap here. You can go back four years to claim your entitlements.",
        "We can claim back all additional credits or deductions you are entitled to. If you are not due any tax back, we will still submit your return as this will ensure that you do not incur any penalty charges from HMRC."],
}, {
    title: "When is the last day to file taxes?",
    text: ["The last day to file a self-assessed CIS tax return is the 31st of January. However, we recommend immediately filing for the previous year so you can get your tax return as soon as possible. This can be done after April 6th for CIS workers."],
}, {
    title: "How to file your income tax return?",
    text: ["If you are unsure how to file a tax return on your own, you can trust our experienced tax agents to deal with all the paperwork and ensure you avail of every relief you’re entitled to. We have helped over 125,000 clients to claim back over £15 million."],
}];

interface FAQsProps {
    limiter?: number;
    setShowButtonFooter?: (_value: boolean) => void;
}

const FAQsNew = ({ limiter, setShowButtonFooter }: FAQsProps) => {
    const { isMobile, maxWidth } = useContext(AppContext);

    useEffect(() => {
        if (setShowButtonFooter) {
            const handleScroll = () => {
                const startButton = document.getElementById("faqsStartButton");
                if (startButton) {
                    const rect = startButton.getBoundingClientRect();
                    const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
                    setShowButtonFooter(!isInView);
                }
            };

            window.addEventListener("scroll", handleScroll);
            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const items: CollapseProps["items"] = FAQS.slice(0, limiter ?? -1).map(
        (item, i) => {
            return {
                key: i.toString(),
                label: <div className={"uk-faqs-title"}>{item.title}</div>,
                children: (
                    <>
                        <div>
                            {item.text.map(child => {
                                return <p key={i + "_child"}>{child}</p>;
                            })}
                        </div>
                        {item.bulletPoints && <ul>{item.bulletPoints}</ul>}
                    </>
                ),
            };
        }
    );

    return <div className={"uk-faqs-block horizontal-center"}>
        <div style={{
            maxWidth: maxWidth,
            padding: isMobile ? "41px 0 0" : "64px 100px",
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
        }}>
            <Row gutter={[64, 32]} style={{ display: "flex", justifyContent: "center", maxWidth: "100vw" }}>
                <Col sm={24} xs={24} md={24} lg={24} xl={10} style={{ maxWidth: isMobile ? "100vw" : undefined }}>
                    <div>
                        <div className={"uk-sub-slogan"}>
                            Frequently asked questions
                        </div>

                        <div className={"mt40 uk-why-taxzap-description"}>
                        Our tax experts are ready to help you with any questions.
                        You can email our team at <a href="mailto:support@taxzap.co.uk"
                                style={{ color: "#0C0B0A" }}
                                target="_blank"
                                rel="noopener noreferrer"
                            >support@taxzap.co.uk</a>
                        </div>

                        <div className={"mt40"} id="faqsStartButton">
                            <StartButtonNew/>
                        </div>
                    </div>
                </Col>
                <Col sm={24} xs={24} md={24} lg={24} xl={14} style={{ maxWidth: isMobile ? "100vw" : undefined }}>
                    <div className={isMobile ? "" : "m20"}>
                        <Collapse
                            className="uk-faqs-collapse-new"
                            ghost
                            items={items}
                            expandIcon={({ isActive }) => (
                                <img
                                    src={chevron}
                                    alt={"chevron"}
                                    style={{
                                        transform: !isActive ? "rotate(0)" : "rotate(-180deg)",
                                        transition: "transform 0.2s ease",
                                    }}
                                />
                            )}

                            expandIconPosition={"end"}
                            size={"large"}
                        />
                        {limiter && <Link to={UK_ROUTES_NEW.faqs}>
                            <Collapse className='uk-faqs-collapse-new'
                                ghost
                                items={[{
                                    key: "show-all",
                                    label: <div className={"uk-faqs-title"}>Other questions...</div>,
                                }]}
                                expandIconPosition={"end"}
                                size={"large"}

                                expandIcon={() => (
                                    <img
                                        src={chevron}
                                        alt={"chevron"}
                                    />
                                )}
                            />
                        </Link>}
                    </div>
                </Col>
            </Row>
        </div>

    </div>;
};

export default FAQsNew;
